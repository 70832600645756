import React from 'react';
import { connect } from 'react-redux';

import CustomerTimeline from '../../../customer/CustomerTimeline';

const Timeline = ({ home }) => {
  const { contact } = home;

  return (
    <div className="mx-3">
      <CustomerTimeline customerId={contact.id} reservationActivitiesOnly />
    </div>
  );
};

Timeline.propTypes = {};

Timeline.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home,
}))(Timeline);
