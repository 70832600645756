import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

const CustomDialog = ({
  title, message, buttonText, onConfirm, onClose, afterConfirm
}) => {
  const onClick = () => {
    if (afterConfirm) {
      onConfirm()
        .then(afterConfirm)
        .finally(onClose);
    } else {
      onConfirm();
      onClose();
    }
  };

  return (
    <div>
      {!v.isBlank(title) && (
        <h1>{title}</h1>
      )}
      {!v.isBlank(message) && (
        <div dangerouslySetInnerHTML={{ __html: message }} />
      )}

      <div className="d-flex justify-content-between mt-4">
        <Button outline color="secondary" onClick={onClose}>{i18n.t('buttons.cancel')}</Button>
        <Button outline color="danger" onClick={onClick}>{buttonText || i18n.t('buttons.yes')}</Button>
      </div>
    </div>
  );
};

CustomDialog.propypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  afterConfirm: PropTypes.func
};

CustomDialog.defaultProps = {
  title: null,
  message: null,
  buttonText: null,
  afterConfirm: undefined,
  onConfirm: () => {},
  onClose: () => {}
};

export default CustomDialog;
