import React, { Component } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Form, FormGroup, Label, Input, Col, Row, Button, Spinner
} from 'reactstrap';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import v from 'voca';
import base64 from 'base-64';

import { saveCompany, getCompany } from '../../../../store/actions/companyActions';
import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';
import { getError } from '../../../../utils/requestUtils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.integrations.customCode.index' };

function encodeScript(text) {
  if (v.isBlank(text)) return null;
  return base64.encode(text);
}

class CustomCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      saving: false,
      hasChanges: false
    };

    this.loadCompany = this.loadCompany.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this.loadCompany();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;

    this.setState({ form, hasChanges: true });
  }

  onSubmit(event) {
    event.preventDefault();

    const { props } = this;
    const { form } = this.state;

    const input = {
      id: form.id,
      headerScripts: encodeScript(form.headerScripts),
      footerScripts: encodeScript(form.footerScripts)
    };

    const variables = { input };

    this.setState({ saving: true });

    props.saveCompany(variables)
      .then(() => {
        navigate('/admin/integrations');
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ saving: false });
        if (v.isString(error)) toast.error(error);
      });
  }

  onCancel() {
    const { company } = this.props;
    const { form } = this.state;
    const { headerScripts, footerScripts } = company;

    this.setState({ form: { ...form, headerScripts, footerScripts }, hasChanges: false });
  }

  loadCompany() {
    const { props } = this;
    const { currentCompany } = this.props;
    props.getCompany({ id: currentCompany.id })
      .then(() => {
        const { company } = this.props;
        this.setState({ form: { ...company } });
      })
      .catch(() => navigate('/admin/companies'));
  }

  render() {
    const { form, saving, hasChanges } = this.state;
    const { loading } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

        <Loading loading={loading} fullScreen />

        <Form onSubmit={this.onSubmit}>
          <Row form className="mb-4">
            <Col>
              <FormGroup>
                <Label for="headerScripts">{i18n.t('headerScripts', i18nOpts)}</Label>
                <Input
                  type="textarea"
                  name="headerScripts"
                  id="headerScripts"
                  rows="10"
                  value={form.headerScripts || ''}
                  onChange={this.onTextChange}
                />
                <div className="mt-2">{i18n.t('headerScriptsDescription', i18nOpts)}</div>
              </FormGroup>
            </Col>
          </Row>

          <Row form className="mb-4">
            <Col>
              <FormGroup>
                <Label for="footerScripts">{i18n.t('footerScripts', i18nOpts)}</Label>
                <Input
                  type="textarea"
                  name="footerScripts"
                  id="footerScripts"
                  rows="10"
                  value={form.footerScripts || ''}
                  onChange={this.onTextChange}
                />
                <div className="mt-2">{i18n.t('footerScriptsDescription', i18nOpts)}</div>
              </FormGroup>
            </Col>
          </Row>

          { hasChanges && (
            <>
              <Button color="primary" className="mr-3" disabled={saving}>
                {saving && (<Spinner size="sm" className="mr-2" />)}
                {i18n.t('buttons.save')}
              </Button>
              <Button outline color="secondary" onClick={this.onCancel} className="mr-3">
                {i18n.t('buttons.cancel')}
              </Button>
            </>
          )}
        </Form>
      </div>
    );
  }
}
export default connect((store) => ({
  company: store.companies.company,
  loading: store.companies.getCompany.loading,
  currentCompany: store.companies.currentCompany
}), {
  saveCompany,
  getCompany
})(CustomCode);
