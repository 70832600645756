import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, CardHeader, CardTitle, Badge
} from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { NavigationMainNames, PriceableTypes } from '../../../../../../constants';
import { isAdmin } from '../../../../../../utils/authUtils';
import { deleteModelPackage, saveModelPackage, reloadModelPackages } from '../../../../../../store/actions/modelPackageActions';
import PackageActionsMenu from '../../../../../common/PackageActionsMenu';
import PriceRuleList from '../../../../../global/priceRules/PriceRuleList';
import AddPriceRuleButton from '../../../../../global/priceRules/AddPriceRuleButton';
import NavigationService from '../../../../../../services/NavigationService';

const i18nOpts = { scope: 'components.admin.models.show.packages.listItem.index' };

const ListItem = ({ modelPackage, ...props }) => {
  const onToggleAsDefault = (isDefault) => {
    const input = { id: modelPackage.id, isDefault };
    props.saveModelPackage({ input })
      .then(() => props.reloadModelPackages(true))
      .catch(() => {});
  };

  const onDelete = () => {
    props.deleteModelPackage(modelPackage.id);
  };

  const packageUrl = useMemo(() => NavigationService.getNavigationShowUrl(
    modelPackage.package, NavigationMainNames.PACKAGES
  ), [modelPackage]);

  return (
    <Card>
      <CardHeader>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>
              <Link to={packageUrl}>
                {modelPackage.package.name}
              </Link>

              {modelPackage.isDefault && (
                <Badge color="info" pill className="ml-3">{i18n.t('default', i18nOpts)}</Badge>
              )}
            </CardTitle>
          </div>
          {isAdmin() && (
            <div>
              <PackageActionsMenu
                packageItem={modelPackage}
                onToggleAsDefault={onToggleAsDefault}
                onDelete={onDelete}
              />
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody>
        {isAdmin() && (
          <div className="text-right">
            <AddPriceRuleButton
              priceableId={modelPackage.package.id}
              priceableType={PriceableTypes.PACKAGE}
            />
          </div>
        )}

        <PriceRuleList
          priceableId={modelPackage.package.id}
          priceableType={PriceableTypes.PACKAGE}
          className={classNames({ 'mt-3': isAdmin })}
        />
      </CardBody>
    </Card>
  );
};

ListItem.propTypes = {
  modelPackage: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({}), {
  deleteModelPackage,
  saveModelPackage,
  reloadModelPackages
})(ListItem);
