import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import { getProjects, cleanProjects, setProjectsViewMode } from '../../../../store/actions/projectActions';
import { SortOrders, ViewModes } from '../../../../constants';
import ListEmpty from '../../../common/ListEmpty';
import { isAdmin } from '../../../../utils/authUtils';
import ListPagination from '../../../common/ListPagination';
import Loading from '../../../ui/Loading';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import GridView from './GridView';
import ListView from './ListView';

const i18nOpts = { scope: 'components.admin.projects.list.index' };

const List = ({
  loading, projects, viewMode, currentCompany, pagination, ...props
}) => {
  const [mounted, setMounted] = useState(false);

  const onChangePage = (page) => {
    loadProjects(page);
  };

  const onChangeViewMode = (newViewMode) => {
    props.setProjectsViewMode(newViewMode);
  };

  const loadProjects = (page = 1) => {
    const direction = SortOrders.ASC;
    const variables = {
      filter: { companyId: currentCompany.id },
      sort: { field: 'name', direction },
      page
    };

    props.getProjects(variables)
      .finally(() => setMounted(true));
  };

  useEffect(() => {
    props.cleanProjects()
      .then(() => loadProjects());
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div className="d-flex justify-content-end align-items-center gap-3">
          <ViewModesDropdown viewMode={viewMode} onChange={onChangeViewMode} />

          {isAdmin() && (
            <Link to="/admin/projects/form" className="btn btn-primary">
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Link>
          )}
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={projects} />

          {
            projects.length > 0 && (
              <div className="mb-4">
                {viewMode === ViewModes.GRID ? (
                  <GridView />
                ) : (
                  <ListView />
                )}
              </div>
            )
          }

          <div className="text-right mt-4">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  projects: store.projects.projects,
  loading: store.projects.getProjects.loading,
  pagination: store.projects.pagination,
  viewMode: store.projects.viewMode,
  currentCompany: store.companies.currentCompany
}), {
  getProjects,
  cleanProjects,
  setProjectsViewMode
})(List);
