import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Table } from 'reactstrap';

import InstanceModificationsInfo from './InstanceModificationsInfo';
import { formatTimestamp } from '../../../utils/dateUtils';
import { getModifierDisplayName } from '../../../utils/auditUtils';
import './styles.scss';
import ListEmpty from '../../common/ListEmpty';

const i18nOpts = { scope: 'components.global.modifications.index' };

const Modifications = ({ instance }) => {
  const hasLogModifications = instance?.logModifications?.length > 0;
  if (!instance) return null;

  if (!hasLogModifications) return <ListEmpty items={instance?.logModifications} />;

  return (
    <Table bordered className="instance-modifications-table" hover responsive>
      <thead>
        <tr>
          <th>{i18n.t('prevValues', i18nOpts)}</th>
          <th>{i18n.t('newValues', i18nOpts)}</th>
          <th>{i18n.t('updatedBy', i18nOpts)}</th>
          <th>{i18n.t('modificationDate', i18nOpts)}</th>
        </tr>
      </thead>
      <tbody>
        {instance.logModifications.map((modification) => (
          <tr key={`modification-${modification.id}`}>
            <td>
              <InstanceModificationsInfo modification={modification} />
            </td>
            <td>
              <InstanceModificationsInfo
                modification={modification}
                displayChanges
              />
            </td>
            <td className="mt-auto">{getModifierDisplayName(modification?.maker)}</td>
            <td>{formatTimestamp(modification.actionDate)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

Modifications.propTypes = {
  instance: PropTypes.objectOf(PropTypes.any)
};

Modifications.defaultProps = {
  instance: null
};

export default Modifications;
