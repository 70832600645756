import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { MultiSelect } from '../../../ui';
import { getSpecificationPrice } from '../../../../utils/priceRuleUtils';

const i18nOpts = { scope: 'components.global.quickPossessionForm.specificationsMultiSelect.index' };

const SpecificationsMultiSelect = ({ form, onChange, specifications }) => {
  const { community, model, specifications: selectedSpecifications } = form;

  const options = useMemo(() => {
    if (!specifications?.length) return [];

    return specifications.map((s) => {
      const price = getSpecificationPrice(community, model, s);
      return { name: `${s.name} $${price}`, id: s.id, price };
    });
  }, [specifications]);

  const getSelectedOptions = () => {
    if (!selectedSpecifications?.length) return [];

    const selectedSpecificationIds = selectedSpecifications.map((s) => s.id);
    return options.filter((o) => selectedSpecificationIds.includes(o.id));
  };

  const onSelect = (newSelectedSpecifications) => {
    onChange({ specifications: newSelectedSpecifications });
  };

  const selectedOptions = getSelectedOptions();

  return (
    <MultiSelect
      id="elevation-multiselect"
      options={options}
      selectedValues={selectedOptions}
      onSelect={onSelect}
      onRemove={onSelect}
      displayValue="name"
      closeIcon="cancel"
      emptyRecordMsg={i18n.t('noMoreSpecifications', i18nOpts)}
      placeholder=""
    />
  );
};

SpecificationsMultiSelect.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  specifications: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

SpecificationsMultiSelect.defaultProps = {
  onChange: () => {}
};

export default SpecificationsMultiSelect;
