import React from 'react';
import PropTypes from 'prop-types';

import {
  getCustomerInterestsFromHomes,
} from '../../../../../utils/customerUtils';
import CustomerInterests from '../../../../global/customer/CustomerInterests';

const Interests = ({ customer }) => {
  const interests = getCustomerInterestsFromHomes(customer.homes);

  if (interests.length === 0) return null;

  return (
    <div>
      <CustomerInterests interests={interests} />
    </div>
  );
};

Interests.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

Interests.defaultProps = {};

export default Interests;
