import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import useFetchModels from '../../../../../../../hooks/useFetchModels';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';
import ShowMoreContent from '../../../../../../common/ShowMoreContent';
import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.components.quickPossessionFiltersModal.modelFilter.index' };

const ModelFilter = ({ filters, onChange }) => {
  const models = filters.models || [];
  const { models: availableModels, loading } = useFetchModels();

  const onToggle = (selectedItem) => {
    onChange({ models: _.xorWith(models, [selectedItem], _.isEqual) });
  };

  const filtersCount = useMemo(() => models.length, [filters]);

  if (loading || !availableModels.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableModels}
        renderItem={(model) => (
          <ListItem
            key={`model-${model.id}`}
            item={model}
            selectedItems={models}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

ModelFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

ModelFilter.defaultProps = {
  onChange: () => {}
};

export default ModelFilter;
