import i18n from 'i18n-js';

import { VerificationTypes } from '../../../../../../constants';
import { hasCustomerPreApproval } from '../../../../../../utils/customerUtils';

const i18nOpts = { scope: 'components.admin.customers.show.details.verification.utils' };

export const CO_BUYER_ADDED_STEP = 'coBuyerAdded';
export const ADDITIONAL_INFO_STEP = 'additionalInfo';

export function getVerifiedJourneySteps(verifiedWorkflow, customer, currentCompany) {
  const steps = [];

  const isIdVerificationEnabled = verifiedWorkflow.some((verifiedStep) => verifiedStep.type
    === VerificationTypes.ID_VERIFICATION);
  if (isIdVerificationEnabled) {
    steps.push({
      type: VerificationTypes.ID_VERIFICATION,
      title: i18n.t('idVerified', i18nOpts),
      isCompleted: isVerifiedStepCompleted(VerificationTypes.ID_VERIFICATION, customer)
    });
  }

  const isFinancialVerificationEnabled = verifiedWorkflow.some((verifiedStep) => verifiedStep.type
    === VerificationTypes.FINANCIAL_VERIFICATION);
  if (isFinancialVerificationEnabled) {
    steps.push({
      type: VerificationTypes.FINANCIAL_VERIFICATION,
      title: i18n.t('financesVerified', i18nOpts),
      isCompleted: isVerifiedStepCompleted(VerificationTypes.FINANCIAL_VERIFICATION, customer)
    });
  }

  const { coBuyersCount } = customer;
  const hasCoBuyer = coBuyersCount > 1;

  steps.push({
    type: CO_BUYER_ADDED_STEP,
    title: i18n.t('coBuyerAdded', i18nOpts),
    isCompleted: hasCoBuyer
  });

  const { enablePreApprovalVerification } = currentCompany;
  const isHomeEvaluationEnabled = verifiedWorkflow.some((verifiedStep) => verifiedStep.type
    === VerificationTypes.HOME_EVALUATION);
  if (isHomeEvaluationEnabled || enablePreApprovalVerification) {
    const hasPreApproval = hasCustomerPreApproval(customer);
    steps.push({
      type: ADDITIONAL_INFO_STEP,
      title: i18n.t('additionalInfo', i18nOpts),
      isCompleted: isVerifiedStepCompleted(VerificationTypes.HOME_EVALUATION, customer)
        || hasPreApproval
    });
  }
  return steps;
}

export function isVerifiedStepCompleted(verifiedStepType, customer) {
  const { isIdVerified, isFinancialVerified, isHomeVerified } = customer;
  switch (verifiedStepType) {
    case VerificationTypes.ID_VERIFICATION:
      return isIdVerified;
    case VerificationTypes.FINANCIAL_VERIFICATION:
      return isFinancialVerified;
    case VerificationTypes.HOME_EVALUATION:
      return isHomeVerified;
    default:
      return false;
  }
}

export function getVerifiedStepLabel(verifiedStepType, customer) {
  const { isIdVerified, isFinancialVerified, isHomeVerified } = customer;

  switch (verifiedStepType) {
    case VerificationTypes.ID_VERIFICATION:
      return isIdVerified ? i18n.t('idVerified', i18nOpts) : null;
    case VerificationTypes.FINANCIAL_VERIFICATION:
      return isFinancialVerified ? i18n.t('financesVerified', i18nOpts) : null;
    case VerificationTypes.HOME_EVALUATION:
      return isHomeVerified ? i18n.t('homeValuated', i18nOpts) : null;
    default:
      return null;
  }
}
