import v from 'voca';

export function formatCustomerHomeAddress(address) {
  if (!address) return null;

  const {
    street, city, state, zip
  } = address;

  const addressLine1 = street;
  const addressLine2 = [city, state, zip].filter((a) => !v.isBlank(a)).join(', ');

  return [addressLine1, addressLine2].filter((a) => !v.isBlank(a)).join('<br />');
}
