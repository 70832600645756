import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { fetchSendGridContactListsAsync } from '../../../Integrations/SendGrid/utils';

const i18nOpts = { scope: 'components.admin.communities.form.mailingLists.index' };

const MailingLists = ({ form, onTextChange, currentCompany }) => {
  const [contactLists, setContactLists] = useState([]);
  const [currentContactLists, setCurrentContactLists] = useState([]);
  const [MultiSelect, setMultiSelect] = useState(null);

  const onSelectOrRemove = (selectedContactLists) => {
    const contactListIds = selectedContactLists.map((contactList) => contactList.id);
    onTextChange({ target: { name: 'sendGridContactLists', value: contactListIds } });
  };

  const loadContactLists = () => {
    const variables = { companyId: currentCompany.id };
    fetchSendGridContactListsAsync(variables)
      .then((fetchedContactLists) => setContactLists(fetchedContactLists))
      .catch(() => setContactLists([]));
  };

  useEffect(() => {
    const sendGridContactLists = form.sendGridContactLists || [];
    const filteredContactLists = contactLists.filter(
      (c) => sendGridContactLists.includes(c.id)
    );
    setCurrentContactLists(filteredContactLists);
  }, [contactLists, form.sendGridContactLists]);

  useEffect(() => {
    const multiSelect = require('multiselect-react-dropdown');
    setMultiSelect(multiSelect);

    loadContactLists();
  }, []);

  return (
    <div>
      <div className="multi-select-container">
        <Label>{i18n.t('mailingLists', i18nOpts)}</Label>
        {MultiSelect && (
          <MultiSelect.Multiselect
            id="user-projects-multiselect"
            options={contactLists}
            selectedValues={currentContactLists}
            onSelect={onSelectOrRemove}
            onRemove={onSelectOrRemove}
            displayValue="name"
            closeIcon="cancel"
            emptyRecordMsg={i18n.t('empty', i18nOpts)}
            placeholder=""
          />
        )}
      </div>
    </div>
  );
};

MailingLists.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func
};

MailingLists.defaultProps = {
  onTextChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(MailingLists);
