import React, { useEffect, useState } from 'react';
import ImagesManager from '../../../../global/ImagesManager';
import {
  deleteQuickPossessionImageAsync,
  fetchQuickPossessionImages,
  saveQuickPossessionImageAsync, sortQuickPossessionImagesAsync
} from './utils';

const Images = ({ quickPossession }) => {
  const [quickPossessionImages, setQuickPossessionImages] = useState([]);

  useEffect(() => {
    loadImages();
  }, []);

  const onDrop = (images) => {
    const promises = images.map((i) => saveQuickPossessionImageAsync(quickPossession.id, i));
    return Promise.all(promises).then(() => loadImages());
  };

  const loadImages = () => {
    fetchQuickPossessionImages(quickPossession.id)
      .then((items) => setQuickPossessionImages(items));
  };

  const onDelete = (image) => {
    deleteQuickPossessionImageAsync(image.id)
      .then(() => loadImages());
  };

  const onSetQuickPossessionImages = (images) => {
    setQuickPossessionImages(images);
  };

  const onSortQuickPossessionImages = () => {
    const ids = quickPossessionImages.map((qi) => qi.id);
    const variables = { quickPossessionId: quickPossession.id, ids };

    sortQuickPossessionImagesAsync(variables);
  };

  return (
    <ImagesManager
      images={quickPossessionImages}
      onSetImages={onSetQuickPossessionImages}
      onSortImages={onSortQuickPossessionImages}
      onDrop={onDrop}
      onDeleteImage={onDelete}
    />
  );
};

export default Images;
