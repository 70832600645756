import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency, isValidAmount } from '../../../../utils/currencyUtils';

const Price = ({ amount, className }) => {
  if (!isValidAmount(amount)) return null;

  return (<span className={className}>{formatCurrency(amount)}</span>);
};

Price.propTypes = {
  amount: PropTypes.number
};

Price.defaultProps = {
  amount: null
};

export default Price;
