import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './TextPlaceholder.module.scss';
import { Colors } from '../../../../../../../../constants';

const TextPlaceholder = ({ color, width, containerClassName }) => {
  const customStyles = {
    backgroundColor: color === 'dark' ? Colors.GRAY_400 : Colors.GRAY_300,
    ...(width ? { width: `${width}px` } : {})
  };

  return (
    <div className={classNames(styles.container, containerClassName)} style={customStyles} />
  );
};

TextPlaceholder.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
  width: PropTypes.number,
  containerClassName: PropTypes.string
};

TextPlaceholder.defaultProps = {
  color: 'light',
  width: null,
  containerClassName: null,
};

export default TextPlaceholder;
