import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, ModalBody, ModalFooter, Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import HomeInfo from './HomeInfo';

const i18nOpts = { scope: 'components.admin.quickPossessions.components.quickPossessionFormModal.confirm.index' };

const Confirm = ({
  form, submitting, onBack, onSave
}) => (
  <>
    <ModalBody>
      <div className="mb-4">
        <div className="font-18 font-weight-600">{i18n.t('title', i18nOpts)}</div>
        <div className="font-14 mt-1">{i18n.t('description', i18nOpts)}</div>
      </div>

      <HomeInfo home={form} />
    </ModalBody>

    <ModalFooter className="gap-2">
      <div className="flex-even">
        <Button outline color="secondary" block onClick={onBack}>
          {i18n.t('buttons.edit')}
        </Button>
      </div>

      <div className="flex-even">
        <Button color="primary" onClick={onSave} block disabled={submitting}>
          {submitting && (<Spinner size="sm" className="mr-2 align-middle" />)}
          {i18n.t('buttons.save', i18nOpts)}
        </Button>
      </div>
    </ModalFooter>
  </>
);

Confirm.propTypes = {
  onBack: PropTypes.func,
  onSave: PropTypes.func
};

Confirm.defaultProps = {
  onBack: () => {},
  onSave: () => {}
};

export default Confirm;
