import React, { useEffect, useMemo, useState } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { hasFormChanges } from '../../../../utils/formUtils';
import { ModalNames, PRICE_RULE_DEFAULT_SELECTIONS } from '../../../../constants';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import {
  fetchCommunitiesAsync,
  getCommunitiesAndPhases,
  getCommunityAndPhaseId, getFormInput, isFloorOptionPriceRule, onCloseModel, savePriceRuleAsync
} from './utils';
import { addPriceRules } from '../../../../store/actions/priceRuleActions';

const i18nOpts = { scope: 'components.global.priceRules.priceRuleFormModal.index' };

const PriceRuleFormModal = ({
  opened, params, model, priceRules, currentCompany, ...props
}) => {
  const [initialForm, setInitialForm] = useState({});
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [communities, setCommunities] = useState([]);
  const { priceableId, priceableType } = params;

  useEffect(() => {
    if (!opened) return;

    loadPriceRule();
  }, [opened]);

  useEffect(() => {
    loadCommunities();
  }, []);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSave = () => {
    const input = getFormInput(form, model, priceableId, priceableType);

    setSaving(true);

    savePriceRuleAsync(input)
      .then(({ data: { item } }) => {
        props.addPriceRules([item]);
        onCloseModel();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const loadPriceRule = () => {
    const { priceRule } = params;
    let formData = {};
    if (priceRule) {
      const {
        id, price, community, communityPhase, externalId, defaultSelection
      } = priceRule;
      const communityAndPhaseId = getCommunityAndPhaseId(community, communityPhase);
      formData = {
        id, price, communityAndPhaseId, externalId, defaultSelection
      };
    }
    setInitialForm(formData);
    setForm(formData);
    setError(null);
  };

  const loadCommunities = () => {
    fetchCommunitiesAsync(currentCompany.id)
      .then((items) => setCommunities(items))
      .catch((e) => toast.error(e.message));
  };

  const isNew = !form.id;
  const hasChanges = hasFormChanges(initialForm, form);
  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);
  const isForFloorOptions = isFloorOptionPriceRule(priceableType);

  const communityAndPhases = useMemo(() => getCommunitiesAndPhases(communities, priceRules,
    priceableId, priceableType, !isNew),
  [communities, priceRules, priceableId, priceableType, isNew]);

  if (!opened) return null;

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label for="communityAndPhaseId">{i18n.t('communityAndPhaseId', i18nOpts)}</Label>
          <Input
            type="select"
            name="communityAndPhaseId"
            id="communityAndPhaseId"
            value={form.communityAndPhaseId || ''}
            onChange={onTextChange}
            disabled={!isNew}
            required
            invalid={isInputInvalid(error, 'communityId')}
          >
            <option value="">{i18n.t('select.select')}</option>
            {
              communityAndPhases.map((cp) => (
                <option key={`community-and-phase-${cp.value}`} value={cp.value}>
                  {cp.name}
                </option>
              ))
            }
          </Input>
          <InputError name="communityId" error={error} />
        </FormGroup>

        <Row form>
          <Col lg="4" md="4" sm="6" xs="8">
            <FormGroup>
              <Label for="price">{i18n.t('price', i18nOpts)}</Label>
              <Input
                type="number"
                name="price"
                id="price"
                value={form.price || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'price')}
              />
              <InputError name="price" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="4" md="4" sm="6" xs="8">
            <FormGroup>
              <Label for="externalId">{i18n.t('externalId', i18nOpts)}</Label>
              <Input
                type="text"
                name="externalId"
                id="externalId"
                value={form.externalId || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'externalId')}
              />
              <InputError name="externalId" error={error} />
            </FormGroup>
          </Col>
        </Row>

        { isForFloorOptions && (
          <FormGroup>
            <Label for="externalId">{i18n.t('defaultSelection', i18nOpts)}</Label>
            <Input
              type="select"
              name="defaultSelection"
              id="defaultSelection"
              value={form.defaultSelection || ''}
              onChange={onTextChange}
              required
              invalid={isInputInvalid(error, 'defaultSelection')}
            >
              {
                Object.values(PRICE_RULE_DEFAULT_SELECTIONS).map((pr) => (
                  <option key={`community-and-phase-${pr}`} value={pr}>
                    {i18n.t(`priceRuleDefaultSelections.${v.camelCase(pr)}`, i18nOpts)}
                  </option>
                ))
              }
            </Input>
            <InputError name="externalId" error={error} />
          </FormGroup>
        )}

      </ModalBody>

      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSave} disabled={saving || !hasChanges}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onCloseModel} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  model: store.models.model,
  priceRules: store.priceRules.priceRules,
  opened: store.modals[ModalNames.PRICE_RULE_FORM]?.opened || false,
  params: store.modals[ModalNames.PRICE_RULE_FORM]?.params || {},
  currentCompany: store.companies.currentCompany
}), {
  addPriceRules
})(PriceRuleFormModal);
