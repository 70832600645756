import api from '../../../../api';
import { sendGridListContactListsQuery } from '../../../../graphql';

export const Tabs = Object.freeze({
  GENERAL_SETTINGS: 'generalSettings',
  MAILING_LIST: 'mailingList'
});

export function fetchSendGridContactListsAsync(variables = {}) {
  return api.graphql(sendGridListContactListsQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}
