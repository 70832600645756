import React from 'react';
import i18n from 'i18n-js';
import { Button, Card } from 'reactstrap';

import CopyLink from '../../../../../common/CopyLink';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.buildHomeSetup.reserveHome.index' };

const RESERVE_HOME_CODE = `<button class="ownly-reserve-home">
  Reserve Home
</button>`;

const ReserveHome = () => (
  <div>
    {/* Reserve Home */}
    <h2>{i18n.t('reserveHome', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('reserveHomeDesc', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>

    <Card body className="d-block">
      <pre>
        &lt;button class=&quot;ownly-reserve-home&quot;&gt;
        <br />
        &nbsp;&nbsp;Reserve Home
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={RESERVE_HOME_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    <p>{i18n.t('similarityDesc', i18nOpts)}</p>
  </div>
);

ReserveHome.propTypes = {};

ReserveHome.defaultProps = {};

export default ReserveHome;
