import { useSelector } from 'react-redux';

const useCoBuyers = () => {
  const { customer, partyContacts } = useSelector((store) => ({
    customer: store.customers.customer,
    partyContacts: store.partyContacts.partyContacts
  }));

  let pendingCoBuyer = null;
  let acceptedCoBuyer = null;
  const acceptedCoBuyers = partyContacts.filter((pc) => !!pc.inviteAcceptedAt);
  if (acceptedCoBuyers.length) {
    const inviterCoBuyer = partyContacts.find((pc) => pc.isPrimary);
    if (inviterCoBuyer) acceptedCoBuyers.push(inviterCoBuyer);
    acceptedCoBuyer = acceptedCoBuyers.find((i) => i.contact?.id !== customer.id);
  } else pendingCoBuyer = partyContacts.find((i) => i.contact?.id !== customer.id);

  return { acceptedCoBuyer, pendingCoBuyer };
};

export default useCoBuyers;
