import React, { useEffect, useState } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Form, Row, Col
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import v from 'voca';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { hasFormValues } from '../../../../utils/formUtils';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { stripToEmpty } from '../../../../utils/stringUtils';
import {
  fetchProjectBuildingAsync,
  fetchProjectsAsync,
  goToProjectBuildings,
  saveProjectBuildingAsync
} from './utils';
import DeleteButton from '../../../global/DeleteButton';
import api from '../../../../api';
import { deleteProjectBuildingQuery } from '../../../../graphql';
import {
  ProjectBuildingAvailabilities,
  ProjectBuildingStatuses
} from '../../../../constants';
import {
  getProjectBuildingAvailabilityName,
  getProjectBuildingStatusName
} from '../../../../utils/projectBuildingUtils';

const i18nOpts = { scope: 'components.admin.projectBuildings.form.index' };

const ProjectBuildingForm = ({ id, currentCompany }) => {
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const isNew = !form.id;

  useEffect(() => {
    loadProjects();

    if (!id) return;
    loadForm();
  }, []);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const input = {
      name: stripToEmpty(form.name),
      description: stripToEmpty(form.description),
      projectId: parseInt(form.projectId, 10) || 0,
      availability: form.availability,
      status: form.status
    };

    if (!isNew) input.id = form.id;

    setSaving(true);

    const variables = { input };
    saveProjectBuildingAsync(variables)
      .then(() => goToProjectBuildings())
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const loadProjects = () => {
    fetchProjectsAsync(currentCompany.id)
      .then((items) => setProjects(items))
      .catch(() => goToProjectBuildings());
  };

  const loadForm = () => {
    fetchProjectBuildingAsync(parseInt(id, 10))
      .then((projectBuilding) => {
        const { project } = projectBuilding;
        setForm({
          id: projectBuilding.id,
          name: projectBuilding.name,
          description: projectBuilding.description,
          projectId: project?.id,
          availability: projectBuilding.availability,
          status: projectBuilding.status
        });
      })
      .catch(() => goToProjectBuildings());
  };

  const onCancel = () => goToProjectBuildings();

  const onDelete = () => {
    const variables = { id: form.id };
    api.graphql(deleteProjectBuildingQuery, variables)
      .then(() => goToProjectBuildings())
      .catch((e) => toast.error(e.message));
  };

  const hasValues = hasFormValues(form);
  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

  return (
    <div>
      <Helmet title={title} />

      <h2 className="mb-4">{title}</h2>

      <Form onSubmit={onSubmit}>
        {isNew && (
          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="projectId">{i18n.t('projectId', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="projectId"
                  id="projectId"
                  value={form.projectId || ''}
                  onChange={onTextChange}
                  invalid={isInputInvalid(error, 'projectId')}
                >
                  <option value="">{i18n.t('select.select')}</option>
                  {
                    projects.map((p) => (
                      <option key={`project-option-${p.id}`} value={p.id}>
                        {p.name}
                      </option>
                    ))
                  }
                </Input>
                <InputError error={error} name="projectId" />
              </FormGroup>
            </Col>
          </Row>
        )}

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="availability">{i18n.t('availability', i18nOpts)}</Label>
              <Input
                type="select"
                name="availability"
                id="availability"
                value={form.availability || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'availability')}
              >
                {
              Object.values(ProjectBuildingAvailabilities).map((availability) => (
                <option key={`availability-option-${availability}`} value={availability}>
                  {getProjectBuildingAvailabilityName(availability)}
                </option>
              ))
            }
              </Input>
              <InputError name="availability" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="name">{i18n.t('name', i18nOpts)}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={form.name || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'name')}
              />
              <InputError name="name" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="description">{i18n.t('description', i18nOpts)}</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                value={form.description || ''}
                onChange={onTextChange}
                rows={5}
                invalid={isInputInvalid(error, 'description')}
              />
              <InputError name="description" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form className="mb-4">
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="status">{i18n.t('status', i18nOpts)}</Label>
              <Input
                type="select"
                name="status"
                id="status"
                value={form.status || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'status')}
              >
                {
                  Object.values(ProjectBuildingStatuses).map((status) => (
                    <option key={`status-option-${status}`} value={status}>
                      {getProjectBuildingStatusName(status)}
                    </option>
                  ))
                }
              </Input>
              <InputError name="status" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Button color="primary" className="mr-3" disabled={saving || !hasValues}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onCancel} className="mr-3">
          {i18n.t('buttons.cancel')}
        </Button>
        {!isNew && (
          <DeleteButton onDelete={onDelete} />
        )}
      </Form>
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(ProjectBuildingForm);
