import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Table } from 'reactstrap';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';

import { isAdmin } from '../../../../../utils/authUtils';
import CreatedByTableItem from '../../../../global/CreatedByTableItem';
import LastModifiedByTableItem from '../../../../global/LastModifiedByTableItem';
import ActionsMenu from '../../../../global/ActionsMenu';
import { deleteBuildingModel } from '../../../../../store/actions/buildingModelActions';

const i18nOpts = { scope: 'components.admin.buildingModels.list.listView.index' };

const ListView = ({ buildingModels, ...props }) => {
  const onEdit = (buildingModel) => {
    navigate(`/admin/buildingModels/form/${buildingModel.id}`);
  };

  const onDelete = (buildingModelId) => {
    props.deleteBuildingModel(buildingModelId);
  };

  return (
    <Table responsive id="models-table" bordered>
      <thead>
        <tr>
          <th>{i18n.t('name', i18nOpts)}</th>
          <th>{i18n.t('size', i18nOpts)}</th>
          <th>{i18n.t('bedrooms', i18nOpts)}</th>
          <th>{i18n.t('bathrooms', i18nOpts)}</th>
          {isAdmin() && (
            <>
              <th>{i18n.t('createdBy', i18nOpts)}</th>
              <th>{i18n.t('lastModifiedBy', i18nOpts)}</th>
              <th>&nbsp;</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {
          buildingModels.map((buildingModel) => (
            <tr key={`building-model-${buildingModel.id}`}>
              <td>
                <Link to={`/admin/buildingModels/${buildingModel.id}`}>
                  {buildingModel.name}
                </Link>
              </td>
              <td>{buildingModel.size}</td>
              <td>{buildingModel.bedrooms}</td>
              <td>{buildingModel.bathrooms}</td>
              {isAdmin() && (
                <>
                  <CreatedByTableItem instance={buildingModel} />
                  <LastModifiedByTableItem instance={buildingModel} />
                  <td className="text-right">
                    <ActionsMenu
                      item={buildingModel}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  </td>
                </>
              )}
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propsTypes = {
  buildingModels: PropTypes.arrayOf(PropTypes.any).isRequired
};

ListView.defaultProps = {};

export default connect(() => ({}), {
  deleteBuildingModel
})(ListView);
