import React, { Component } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';

import { getPalettes, cleanPalettes, deletePalette } from '../../../../store/actions/paletteActions';
import ListEmpty from '../../../common/ListEmpty';
import ListItem from './ListItem';
import {
  buildPackagesPath,
  buildPalettesPath,
  isMultiFamilyPage
} from '../../../../utils/multiFamilyUtils';
import { isAdmin } from '../../../../utils/authUtils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.palettes.list.index' };

class Palettes extends Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };

    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    props.cleanPalettes()
      .then(() => this.loadPalettes());
  }

  onDelete(paletteId) {
    const { props } = this;

    props.deletePalette(paletteId)
      .then(() => {
        navigate(buildPackagesPath());
      })
      .catch(() => {});
  }

  loadPalettes() {
    const { props } = this;
    const { currentCompany } = this.props;

    const variables = {
      filter: {
        companyId: currentCompany.id,
        isMultifamily: isMultiFamilyPage()
      }
    };
    props.getPalettes(variables)
      .finally(() => this.setState({ mounted: true }));
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return <Loading loading fullScreen />;

    const { palettes, loading } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{i18n.t('title', i18nOpts)}</h2>
          </div>
          {isAdmin() && (
            <div>
              <Link to={buildPalettesPath('/form')} className="btn btn-primary">
                <i className="fas fa-plus mr-2" />
                {i18n.t('buttons.add', i18nOpts)}
              </Link>
            </div>
          )}
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={palettes} />

            <div>
              <Row>
                {
                  palettes.map((p) => (
                    <Col key={`palette-${p.id}`} xs="12" sm="6" md="6" lg="4" className="mb-4">
                      <ListItem palette={p} />
                    </Col>
                  ))
                }
              </Row>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  palettes: store.palettes.palettes,
  loading: store.palettes.getPalettes.loading,
  currentCompany: store.companies.currentCompany
}), {
  getPalettes,
  cleanPalettes,
  deletePalette
})(Palettes);
