import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import StatusItem from './StatusItem';
import useReserveNowWorkflowSteps from '../../../../../../../hooks/useReserveNowWorkflowSteps';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';

const i18nOpts = { scope: 'components.admin.sales.components.dealList.filtersModal.completedDealStatusFilter.index' };

const CompletedDealStatusFilter = ({ filters, onChange }) => {
  const completedDealStatuses = filters.completedDealStatuses || [];
  const reserveNowSteps = useReserveNowWorkflowSteps();

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ completedDealStatuses: _.xor(completedDealStatuses, [value]) });
  };

  const filtersCount = useMemo(() => completedDealStatuses.length, [completedDealStatuses]);

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      {
        reserveNowSteps.map((reserveStep) => (
          <StatusItem
            key={`deal-completed-status-${reserveStep.type}`}
            reserveStep={reserveStep}
            selectedValues={completedDealStatuses}
            onChange={onToggle}
          />
        ))
      }
    </CollapsibleFilterGroup>
  );
};

CompletedDealStatusFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

CompletedDealStatusFilter.defaultProps = {
  onChange: () => {}
};

export default CompletedDealStatusFilter;
