import React from 'react';
import PropTypes from 'prop-types';
import { RiRefund2Fill } from 'react-icons/ri';
import i18n from 'i18n-js';

import TimeLineElement from '../../../../common/TimeLineElement';
import ActivityDate from '../components/ActivityDate';
import { formatCurrency } from '../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.refundIssued.index' };

const RefundIssued = ({ activity, innerKey }) => {
  const { refund, contact } = activity;
  const { refundedByUser } = refund;
  const refundedByUserName = refundedByUser ? refundedByUser.name : i18n.t('support', i18nOpts);

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<RiRefund2Fill />}
    >
      <div className="d-flex align-items-start gap-3">
        <div className="activity-timeline-title">{i18n.t('title', i18nOpts)}</div>
        <ActivityDate date={activity.createdAt} />
      </div>
      <div>
        {i18n.t('description', {
          // eslint-disable-next-line max-len
          ...i18nOpts, amount: formatCurrency(refund.amount), customerName: contact?.name, userName: refundedByUserName
        })}
      </div>
    </TimeLineElement>
  );
};

RefundIssued.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

RefundIssued.defaultProps = {};

export default RefundIssued;
