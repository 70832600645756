import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import ListItem from './ListItem';
import ShowMoreContent from '../../../../../../common/ShowMoreContent';
import { getUnionTypeObjectKey } from '../../../../../../../utils/objectUtils';
import useFetchCommunitiesAndProjects from '../../../../../../../hooks/useFetchCommunitiesAndProjects';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';

const i18nOpts = { scope: 'components.admin.sales.components.dealList.filtersModal.communityProjectFilter.index' };

const CommunityProjectFilter = ({ filters, onChange }) => {
  const communitiesProjects = filters.communitiesProjects || [];
  const {
    communitiesProjects: availableCommunitiesProjects,
    loading
  } = useFetchCommunitiesAndProjects();

  const onToggle = (selectedItem) => {
    onChange({ communitiesProjects: _.xorWith(communitiesProjects, [selectedItem], _.isEqual) });
  };

  const filtersCount = useMemo(() => communitiesProjects.length, [communitiesProjects]);

  if (loading || !availableCommunitiesProjects.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableCommunitiesProjects}
        renderItem={(item) => (
          <ListItem
            key={getUnionTypeObjectKey(item)}
            item={item}
            selectedItems={communitiesProjects}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

CommunityProjectFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

CommunityProjectFilter.defaultProps = {
  onChange: () => {}
};

export default CommunityProjectFilter;
