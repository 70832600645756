import {
  GET_MODELS, GET_MODEL, CLEAN_MODELS, SAVE_MODEL, DELETE_MODEL, SET_MODEL_PALETTES,
  SET_MODEL_COMMUNITIES, SET_MODELS_SEARCH_FILTERS, SET_MODELS_VIEW_MODE
} from './types';
import {
  createModelQuery, deleteModelQuery, getModelQuery,
  listModelsQuery, setModelCommunitiesQuery, setModelPalettesQuery, updateModelQuery
} from '../../graphql';
import api from '../../api';

export function getModels(variables = {}) {
  return {
    type: GET_MODELS,
    payload: api.graphql(listModelsQuery, variables)
  };
}

export function getModel(id) {
  return {
    type: GET_MODEL,
    payload: api.graphql(getModelQuery, { id })
  };
}

export function cleanModels(extraProps = {}) {
  return {
    type: CLEAN_MODELS,
    payload: Promise.resolve(extraProps)
  };
}

export function saveModel(variables = {}) {
  const query = variables.input.id ? updateModelQuery : createModelQuery;
  return {
    type: SAVE_MODEL,
    payload: api.graphql(query, variables)
  };
}

export function deleteModel(id) {
  return {
    type: DELETE_MODEL,
    payload: api.graphql(deleteModelQuery, { id })
  };
}

export function setModelPalettes(variables = {}) {
  return {
    type: SET_MODEL_PALETTES,
    payload: api.graphql(setModelPalettesQuery, variables)
  };
}

export function setModelCommunities(variables = {}) {
  return {
    type: SET_MODEL_COMMUNITIES,
    payload: api.graphql(setModelCommunitiesQuery, variables)
  };
}

export function setModelsSearchFilters(filters) {
  return {
    type: SET_MODELS_SEARCH_FILTERS,
    payload: { filters }
  };
}

export function setModelsViewMode(viewMode) {
  return {
    type: SET_MODELS_VIEW_MODE,
    payload: { viewMode }
  };
}
