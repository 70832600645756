import React, { useState } from 'react';
import { BiGridVertical } from 'react-icons/bi';
import Switch from 'rc-switch';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './WorkflowItem.module.scss';

const WorkflowItem = ({
  initialTitle, onChangeTitle, enabled, onToggle, isTitleEditable, children
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const onChange = (e) => {
    const { value } = e.target;
    onChangeTitle(value);
  };

  const onClick = () => {
    if (isTitleEditable) setIsEditing(true);
  };

  return (
    <div className={styles.workflowItemContainer}>
      <div className="d-flex align-items-center">
        <div className={styles.moveContainer}>
          <BiGridVertical size="1.25rem" />
        </div>
        <div className={styles.bodyContainer}>
          <div className="pr-2">
            {isEditing ? (
              <Input
                type="text"
                value={initialTitle}
                onChange={onChange}
                onBlur={() => setIsEditing(false)}
                autoFocus
              />
            ) : (
              <div
                role={isTitleEditable ? 'button' : null}
                aria-hidden
                className={classNames(styles.titleContainer,
                  { [styles.titleEditable]: isTitleEditable })}
                onClick={onClick}
              >
                {initialTitle}
              </div>
            )}
          </div>
        </div>
        <div>
          <Switch
            checked={enabled}
            onChange={(checked) => onToggle(checked)}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

WorkflowItem.propTypes = {
  initialTitle: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
  onChangeTitle: PropTypes.func,
  isTitleEditable: PropTypes.bool
};

WorkflowItem.defaultProps = {
  enabled: false,
  onToggle: () => {},
  onChangeTitle: () => {},
  isTitleEditable: false
};

export default WorkflowItem;
