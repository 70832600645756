import i18n from 'i18n-js';

import { Roles } from '../constants';

const RoleLabels = {
  [Roles.MASTER_ADMIN]: i18n.t('roles.masterAdmin'),
  [Roles.TEC_SUPPORT]: i18n.t('roles.tecSupport'),
  [Roles.COMPANY_ADMIN]: i18n.t('roles.companyAdmin'),
  [Roles.COMPANY_USER]: i18n.t('roles.companyUser'),
  [Roles.SALES_LEAD]: i18n.t('roles.salesLead'),
  [Roles.SALES_USER]: i18n.t('roles.salesUser')
};

export function getRoleName(role) {
  return RoleLabels[role];
}
