import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';
import i18n from 'i18n-js';
import { AiOutlineUser } from 'react-icons/ai';
import {
  MdOutlineCheckCircle,
  MdOutlineCheck
} from 'react-icons/md';

import { Colors } from '../../../../constants';

const i18nOpts = { scope: 'components.global.customer.customerVerifiedStatusIcon.index' };

const CustomerVerifiedStatusIcon = ({ customer, size }) => {
  const [showPopover, setShowPopover] = useState(false);
  const { isIdVerified, isHomeVerified, isFinancialVerified } = customer;

  let Icon = AiOutlineUser;
  let popoverText = i18n.t('prospect', i18nOpts);
  let color;

  if (isIdVerified && isFinancialVerified) {
    Icon = MdOutlineCheckCircle;
    popoverText = i18n.t('fullyVerified', i18nOpts);
    color = Colors.PRIMARY_700;
  } else if (isFinancialVerified || isHomeVerified || isIdVerified) {
    Icon = MdOutlineCheck;
    popoverText = isFinancialVerified ? i18n.t('preApproval', i18nOpts)
      : i18n.t('idVerified', i18nOpts);
    color = Colors.INFO;
  }

  return (
    <div>
      <div
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        id={`customer-type-icon-${customer.id}`}
      >
        <Icon size={size} color={color} />
      </div>
      <Popover placement="bottom" isOpen={showPopover} target={`customer-type-icon-${customer.id}`}>
        <PopoverBody>
          {popoverText}
        </PopoverBody>
      </Popover>
    </div>
  );
};

CustomerVerifiedStatusIcon.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

CustomerVerifiedStatusIcon.defaultProps = {
  size: 24
};

export default CustomerVerifiedStatusIcon;
