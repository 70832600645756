import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import v from 'voca';

import { getSignJourneyLabel } from '../../../../utils/reserveNowUtils';
import { OfferSignStatuses } from '../../../../constants';

const SignStatusBadge = ({ offer }) => {
  const signStatus = offer?.signStatus;

  if (v.isBlank(signStatus)) return null;

  const getColor = () => {
    if (signStatus === OfferSignStatuses.SUBMITTED) return 'primary';
    if (signStatus === OfferSignStatuses.ACCEPTED) return 'primary';
    if (signStatus === OfferSignStatuses.REJECTED) return 'danger';
    if (signStatus === OfferSignStatuses.DECLINED) return 'danger';
    return 'light';
  };

  const color = useMemo(getColor, [signStatus]);

  return (
    <Badge color={color} pill>
      {getSignJourneyLabel(signStatus)}
    </Badge>
  );
};

SignStatusBadge.propTypes = {
  offer: PropTypes.objectOf(PropTypes.any)
};

SignStatusBadge.defaultProps = {
  offer: null,
};

export default SignStatusBadge;
