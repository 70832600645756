import React from 'react';
import { CardHeader, Card, CardBody } from 'reactstrap';
import i18n from 'i18n-js';
import classNames from 'classnames';
import { connect } from 'react-redux';

import styles from './Timeline.module.scss';
import CustomerTimeline from '../../../../../../global/customer/CustomerTimeline';

const i18nOpts = { scope: 'components.admin.customers.show.details.activity.timeline.index' };

const Timeline = ({ customer }) => (
  <Card className={styles.card}>
    <CardHeader className="py-4">
      <h4>{i18n.t('title', i18nOpts)}</h4>
      <div dangerouslySetInnerHTML={{ __html: i18n.t('description', i18nOpts) }} />
    </CardHeader>

    <CardBody className={classNames(styles.cardBody, 'py-0')}>
      <CustomerTimeline customerId={customer.id} />
    </CardBody>
  </Card>
);

Timeline.propTypes = {};

Timeline.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
}))(Timeline);
