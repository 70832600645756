import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SalableTypes } from '../../../constants';
import { fetchSellerSpacesAsync } from './utils';
import AddSellerDropdown from './AddSellerDropdown';
import SellersList from './SellersList';
import { isAdmin } from '../../../utils/authUtils';

const SellersManagement = ({ salableId, salableType }) => {
  const [sellerSpaces, setSellerSpaces] = useState([]);

  const loadSellerSpaces = () => {
    fetchSellerSpacesAsync(salableId, salableType)
      .then((items) => setSellerSpaces(items))
      .catch(() => {});
  };

  const reloadSellerSpaces = () => {
    loadSellerSpaces();
  };

  useEffect(() => {
    loadSellerSpaces();
  }, []);

  return (
    <div>
      {isAdmin() && (
        <div className="mb-4 text-right">
          <AddSellerDropdown
            salableId={salableId}
            salableType={salableType}
            sellerSpaces={sellerSpaces}
            onAfterAdd={reloadSellerSpaces}
          />
        </div>
      )}

      <SellersList sellerSpaces={sellerSpaces} onAfterDelete={reloadSellerSpaces} />
    </div>
  );
};

SellersManagement.propTypes = {
  salableId: PropTypes.number.isRequired,
  salableType: PropTypes.oneOf(Object.values(SalableTypes)).isRequired
};

SellersManagement.defaultProps = {};

export default SellersManagement;
