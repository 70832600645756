import {
  CLEAN_WEBHOOKS, GET_WEBHOOK, SAVE_WEBHOOK, DELETE_WEBHOOK, LIST_WEBHOOKS
} from './types';
import api from '../../api';
import {
  createWebhookQuery, deleteWebhookQuery,
  getWebhookQuery,
  listWebhooksQuery,
  updateWebhookQuery
} from '../../graphql';

export function listWebhooks(variables = {}) {
  return {
    type: LIST_WEBHOOKS,
    payload: api.graphql(listWebhooksQuery, variables)
  };
}

export function getWebhook(id) {
  return {
    type: GET_WEBHOOK,
    payload: api.graphql(getWebhookQuery, { id })
  };
}

export function cleanWebhooks(extraProps = {}) {
  return {
    type: CLEAN_WEBHOOKS,
    payload: Promise.resolve(extraProps)
  };
}

export function saveWebhook(variables = {}) {
  const query = variables.input.id ? updateWebhookQuery : createWebhookQuery;
  return {
    type: SAVE_WEBHOOK,
    payload: api.graphql(query, variables)
  };
}

export function deleteWebhook(id) {
  return {
    type: DELETE_WEBHOOK,
    payload: api.graphql(deleteWebhookQuery, { id })
  };
}
