import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import v from 'voca';
import i18n from 'i18n-js';

import { DepositStatuses } from '../../../../../../../constants';

const i18nOpts = { scope: 'components.admin.deposits.list.listView.listItem.status.index' };

const Status = ({ deposit }) => {
  const {
    partialRefund, fullRefund, status, errorMessage
  } = deposit;

  if (fullRefund) {
    return <Badge color="info">{i18n.t('refunded', i18nOpts)}</Badge>;
  } if (partialRefund) {
    return <Badge color="light">{i18n.t('partialRefund', i18nOpts)}</Badge>;
  }

  switch (status) {
    case DepositStatuses.SUBMITTED:
      return <Badge color="light">{i18n.t('pending', i18nOpts)}</Badge>;

    case DepositStatuses.ACCEPTED:
    case DepositStatuses.SUCCEEDED:
      return <Badge color="primary">{i18n.t('success', i18nOpts)}</Badge>;

    case DepositStatuses.FAILED:
      return (
        <>
          <Badge color="danger">{i18n.t('failed', i18nOpts)}</Badge>
          {!v.isBlank(errorMessage) && (
            <div className="mt-1">
              <small className="text-danger white-space-pre-wrap">{errorMessage}</small>
            </div>
          )}
        </>
      );

    case DepositStatuses.REJECTED:
      return <Badge color="warning">{i18n.t('rejected', i18nOpts)}</Badge>;

    default:
      return null;
  }
};

Status.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.any).isRequired
};

Status.defaultProps = {};

export default Status;
