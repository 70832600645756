import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import CollapsibleFilterGroup from '../../../../../common/CollapsibleFilterGroup';
import InterestItem from './InterestItem';
import {
  getInterestKey
} from '../../utils';
import ShowMoreContent from '../../../../../common/ShowMoreContent';
import useFetchCommunitiesAndProjects from '../../../../../../hooks/useFetchCommunitiesAndProjects';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.interestsFilter.index' };

const InterestsFilter = ({ filters, onChange }) => {
  const interests = filters.interests || [];
  const { communitiesProjects: availableInterests, loading } = useFetchCommunitiesAndProjects();

  const onToggle = (selectedItem) => {
    onChange({ interests: _.xorWith(interests, [selectedItem], _.isEqual) });
  };

  const filtersCount = useMemo(() => interests.length, [filters]);

  if (loading || !availableInterests.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableInterests}
        renderItem={(item) => (
          <InterestItem
            key={getInterestKey(item)}
            item={item}
            selectedItems={interests}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

InterestsFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

InterestsFilter.defaultProps = {
  onChange: () => {}
};

export default InterestsFilter;
