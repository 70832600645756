import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import CollapsibleFilterGroup from '../../../../../common/CollapsibleFilterGroup';
import StatusItem from './StatusItem';
import { CustomerStatuses } from '../utils';
import useIsReserveNowEnabled from '../../../../../../hooks/useIsReserveNowEnabled';
import useIsVerifiedEnabled from '../../../../../../hooks/useIsVerifiedEnabled';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.statusFilter.index' };

const StatusFilter = ({ filters, onChange }) => {
  const statuses = filters.statuses || [];
  const availableStatuses = Object.values(CustomerStatuses);
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ statuses: _.xor(statuses, [value]) });
  };

  const filtersCount = useMemo(() => statuses.length, [filters]);

  if (!isReserveNowEnabled || !isVerifiedEnabled) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      {
        availableStatuses.map((status) => (
          <StatusItem
            key={`status-${status}`}
            value={status}
            selectedValues={statuses}
            onChange={onToggle}
          />
        ))
      }
    </CollapsibleFilterGroup>
  );
};

StatusFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

StatusFilter.defaultProps = {
  onChange: () => {}
};

export default StatusFilter;
