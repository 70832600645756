import React, { Component } from 'react';

class SVGRenderer extends Component {
  constructor(props) {
    super(props);

    this.renderObject = this.renderObject.bind(this);
  }

  getObjectComponent(type) {
    const { objectTypes } = this.props;
    return objectTypes[type];
  }

  renderObject(object, index) {
    const { objectRefs, onMouseOver } = this.props;
    const Renderer = this.getObjectComponent(object.type);
    return (
      <Renderer
        onRender={(ref) => objectRefs[index] = ref}
        onMouseOver={() => onMouseOver(index)}
        object={object}
        key={index}
        index={index}
      />
    );
  }

  render() {
    const {
      background, objects, svgStyle, canvas,
      onMouseDown, onRender
    } = this.props;
    const {
      width, height, canvasOffsetX, canvasOffsetY
    } = canvas;

    const style = {
      ...styles.canvas,
      ...background ? {
        backgroundImage: `url(${background})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      } : styles.grid,
      ...{
        ...svgStyle,
        marginTop: canvasOffsetY,
        marginLeft: canvasOffsetX
      }
    };

    return (
      <svg
        onMouseDown={onMouseDown}
        ref={onRender}
        width={width}
        height={height}
        style={style}
      >
        {objects.map(this.renderObject)}
      </svg>
    );
  }
}

export const styles = {
  canvas: {
    backgroundSize: 400
  },
};

SVGRenderer.defaultProps = {
  onMouseOver: () => {}
};

export default SVGRenderer;
