import {
  LIST_MODEL_PACKAGES, SAVE_MODEL_PACKAGE, DELETE_MODEL_PACKAGE, RELOAD_MODEL_PACKAGES
} from './types';
import {
  createModelPackageQuery, deleteModelPackageQuery, listModelPackagesQuery,
  updateModelPackageQuery
} from '../../graphql';
import api from '../../api';

export function listModelPackages(variables = {}) {
  return {
    type: LIST_MODEL_PACKAGES,
    payload: api.graphql(listModelPackagesQuery, variables)
  };
}

export function saveModelPackage(variables = {}) {
  const query = variables.input.id ? updateModelPackageQuery : createModelPackageQuery;
  return {
    type: SAVE_MODEL_PACKAGE,
    payload: api.graphql(query, variables)
  };
}

export function deleteModelPackage(id) {
  return {
    type: DELETE_MODEL_PACKAGE,
    payload: api.graphql(deleteModelPackageQuery, { id })
  };
}

export function reloadModelPackages(reload) {
  return {
    type: RELOAD_MODEL_PACKAGES,
    payload: { reload }
  };
}
