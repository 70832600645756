import v from 'voca';
import _ from 'lodash';

import api from '../../../../api';
import { listPriceRulesQuery } from '../../../../graphql';

export function fetchPriceRulesAsync(model, priceableId, priceableType) {
  const filter = { modelId: model.id, priceableId, priceableType };
  return api.graphql(listPriceRulesQuery, { filter })
    .then(({ data: { items } }) => items);
}

export function filterAndSortPriceRules(priceRules, priceableId, priceableType) {
  const filteredPriceRules = priceRules.filter((priceRule) => priceRule.priceableId === priceableId
    && priceRule.priceableType === priceableType);

  const sortablePriceRules = filteredPriceRules.map((pr) => {
    const name = [pr.community.name, pr.communityPhase?.phase].filter((n) => !v.isBlank(n)).join(' > ');
    return { name, ...pr };
  });

  return _.sortBy(sortablePriceRules, (pr) => pr.name);
}
