import i18n from 'i18n-js';

import { OfferStatuses } from '../../../../../../constants';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.dealInfo.utils' };

export function getOfferPriceColor(offer) {
  const status = offer?.status;
  if (status === OfferStatuses.SUBMITTED) return 'warning';
  if (status === OfferStatuses.ACCEPTED) return 'success';
  if (status === OfferStatuses.COUNTERED) return 'warning';
  if (status === OfferStatuses.RECOUNTERED) return 'warning';
  if (status === OfferStatuses.REJECTED) return 'warning';
  if (status === OfferStatuses.DECLINED) return 'danger';
  return null;
}

export function getOfferPriceLabel(offer) {
  const status = offer?.status;
  if (status === OfferStatuses.SUBMITTED) return i18n.t('customerOffer', i18nOpts);
  if (status === OfferStatuses.ACCEPTED) return i18n.t('offerAccepted', i18nOpts);
  if (status === OfferStatuses.COUNTERED) return i18n.t('offerCountered', i18nOpts);
  if (status === OfferStatuses.RECOUNTERED) return i18n.t('recounterPending', i18nOpts);
  if (status === OfferStatuses.REJECTED) return i18n.t('offerRejected', i18nOpts);
  if (status === OfferStatuses.DECLINED) return i18n.t('offerDeclined', i18nOpts);
  return i18n.t('customerOffer', i18nOpts);
}
