import React from 'react';
import { Table } from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import ListItem from './ListItem';
import useHomeRental from '../../../../../../hooks/useHomeRental';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.existingCustomer.listView.index' };

const ListView = ({ customers, selectedCustomers, onSelect }) => {
  const { typeI18nKey } = useHomeRental();

  if (!customers.length) return null;

  return (
    <Table borderless>
      <thead>
        <tr>
          <th style={{ width: '95px' }}>&nbsp;</th>
          <th>{i18n.t(`${typeI18nKey}.customer`, i18nOpts)}</th>
          <th className="text-nowrap">{i18n.t('signUpDate', i18nOpts)}</th>
        </tr>
      </thead>
      <tbody>
        {
          customers.map((customer) => (
            <ListItem key={`customer-${customer.id}`} customer={customer} selectedCustomers={selectedCustomers} onSelect={onSelect} />
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  selectedCustomers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onSelect: PropTypes.func
};

ListView.defaultProps = {
  onSelect: () => {}
};

export default ListView;
