import { useSelector } from 'react-redux';

const useValidSelectedColumns = () => {
  const { columns } = useSelector((store) => ({
    columns: store.units.columns
  }));

  return columns || [];
};

export default useValidSelectedColumns;
