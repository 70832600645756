import i18n from 'i18n-js';

import { ProjectAvailabilities, ProjectStatuses } from '../constants';

const ProjectAvailabilityLabels = {
  [ProjectAvailabilities.AVAILABLE]: i18n.t('projectAvailabilities.available'),
  [ProjectAvailabilities.UNDER_DEVELOPMENT]: i18n.t('projectAvailabilities.underDevelopment'),
  [ProjectAvailabilities.COMING_SOON]: i18n.t('projectAvailabilities.comingSoon')
};

const ProjectStatusLabels = {
  [ProjectStatuses.DRAFT]: i18n.t('statuses.draft'),
  [ProjectStatuses.PUBLISHED]: i18n.t('statuses.published'),
};

export function getProjectAvailabilityName(availability) {
  return ProjectAvailabilityLabels[availability];
}

export function getProjectStatusName(status) {
  return ProjectStatusLabels[status];
}
