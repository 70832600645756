import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { Table } from 'reactstrap';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import './styles.scss';
import { isAdmin } from '../../../../../utils/authUtils';
import ActionsMenu from '../../../../global/ActionsMenu';
import CreatedByTableItem from '../../../../global/CreatedByTableItem';
import LastModifiedByTableItem from '../../../../global/LastModifiedByTableItem';
import {
  getProjectBuildingAvailabilityName,
  getProjectBuildingStatusName
} from '../../../../../utils/projectBuildingUtils';
import { deleteProjectBuildingAsync } from '../utils';

const i18nOpts = { scope: 'components.admin.projectBuildings.list.listView.index' };

const ListView = ({ projectBuildings }) => {
  const [filteredProjectBuildings, setFilteredProjectBuildings] = useState(projectBuildings);

  const onEdit = (projectBuilding) => {
    navigate(`/admin/projectBuildings/form/${projectBuilding.id}`);
  };

  const onDelete = (projectBuildingId) => {
    deleteProjectBuildingAsync(projectBuildingId)
      .then(() => setFilteredProjectBuildings(
        filteredProjectBuildings.filter((pb) => pb.id !== projectBuildingId)
      ))
      .catch((e) => toast.error(e.message));
  };

  useEffect(() => {
    setFilteredProjectBuildings(projectBuildings);
  }, [projectBuildings]);

  return (
    <Table responsive bordered id="project-buildings-table">
      <thead>
        <tr>
          <th>{i18n.t('name', i18nOpts)}</th>
          <th>{i18n.t('project', i18nOpts)}</th>
          <th>{i18n.t('availability', i18nOpts)}</th>
          <th>{i18n.t('status', i18nOpts)}</th>
          {isAdmin() && (
            <>
              <th>{i18n.t('createdBy', i18nOpts)}</th>
              <th>{i18n.t('lastModifiedBy', i18nOpts)}</th>
              <th>&nbsp;</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {
          filteredProjectBuildings.map((projectBuilding) => (
            <tr key={`project-building-${projectBuilding.id}`}>
              <td>
                <Link to={`/admin/projectBuildings/${projectBuilding.id}`}>
                  {projectBuilding.name}
                </Link>
              </td>
              <td>{projectBuilding.project?.name || ''}</td>
              <td>
                {getProjectBuildingAvailabilityName(projectBuilding.availability)}
              </td>
              <td>
                <span className={`badge text-white badge-${projectBuilding.status.toLowerCase()}`}>
                  {getProjectBuildingStatusName(projectBuilding.status)}
                </span>
              </td>
              {isAdmin() && (
                <>
                  <CreatedByTableItem instance={projectBuilding} />
                  <LastModifiedByTableItem instance={projectBuilding} />
                  <td className="text-right">
                    <ActionsMenu
                      item={projectBuilding}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  </td>
                </>
              )}
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propsTypes = {
  projectBuildings: PropTypes.arrayOf(PropTypes.any).isRequired
};

ListView.defaultProps = {};

export default ListView;
