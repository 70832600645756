import React, { Component } from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import {
  Form, FormGroup, Label, Input, Spinner, Button, FormText
} from 'reactstrap';

import { setImportedData } from '../../../../../store/actions/importExportActions';
import api from '../../../../../api';
import { importDataFromSpreadsheetQuery } from '../../../../../graphql/importExport';
import { SocketContext } from '../../../../../providers/Socket';
import { WebSocketMessages } from '../../../../../constants';

const i18nOpts = { scope: 'components.admin.integrations.importData.form.index' };

class ImportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      importing: false
    };

    this.version = null;

    this.onSubmit = this.onSubmit.bind(this);
    this.onChoseFile = this.onChoseFile.bind(this);
    this.addSocketEventListener = this.addSocketEventListener.bind(this);
  }

  componentDidMount() {
    this.addSocketEventListener();
  }

  componentWillUnmount() {
    const { context: socket } = this;
    socket.disconnect();
  }

  onSubmit(event) {
    event.preventDefault();
    this.setState({ importing: true });

    const { props } = this;
    const { file } = this.state;
    const { currentCompany } = this.props;

    const input = { companyId: currentCompany.id };
    const variables = { input, file };

    api.graphql(importDataFromSpreadsheetQuery, variables)
      .then(({ data: { item: { version } } }) => {
        this.version = version;

        props.setImportedData(null);

        this.onStartImport();
      });
  }

  onChoseFile(e) {
    const file = e.target.files[0];
    this.setState({ file });
  }

  onStartImport() {
    const { context: socket } = this;
    socket.connect();
  }

  endImport() {
    const { context: socket } = this;
    this.setState({ importing: false });
    socket.disconnect();
  }

  addSocketEventListener() {
    const { props, context: socket } = this;
    socket.on(WebSocketMessages.IMPORT_TEMPLATE, (data) => {
      const { version, importsDto: importedData } = data;
      if (this.version !== version) return;

      props.setImportedData(importedData);

      this.endImport();
    });
  }

  render() {
    const { file, importing } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <FormGroup>
          <Label for="importFile">{i18n.t('file', i18nOpts)}</Label>
          <Input type="file" name="importFile" id="importFile" onChange={this.onChoseFile} />
          <FormText color="muted">
            {i18n.t('acceptedFiles', i18nOpts)}
          </FormText>
        </FormGroup>

        {file && (
          <Button color="primary" disabled={importing}>
            {importing ? (
              <>
                <Spinner size="sm" className="mr-2" />
                {i18n.t('buttons.importing', i18nOpts)}
              </>
            ) : (i18n.t('buttons.startImport', i18nOpts))}
          </Button>
        )}
      </Form>
    );
  }
}

ImportForm.contextType = SocketContext;

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  setImportedData
})(ImportForm);
