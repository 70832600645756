import React from 'react';
import {
  Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';

const i18nOpts = { scope: 'components.admin.integrations.hubSpot.generalSettings.index' };

const LeadStatuses = Object.freeze([
  { value: 'NEW', label: i18n.t('leadStatuses.new', i18nOpts) },
  { value: 'OPEN', label: i18n.t('leadStatuses.open', i18nOpts) },
  { value: 'IN_PROGRESS', label: i18n.t('leadStatuses.inProgress', i18nOpts) },
  { value: 'OPEN_DEAL', label: i18n.t('leadStatuses.openDeal', i18nOpts) },
  { value: 'UNQUALIFIED', label: i18n.t('leadStatuses.unqualified', i18nOpts) },
  { value: 'ATTEMPTED_TO_CONTACT', label: i18n.t('leadStatuses.attemptedToContact', i18nOpts) },
  { value: 'CONNECTED', label: i18n.t('leadStatuses.connected', i18nOpts) },
  { value: 'BAD_TIMING', label: i18n.t('leadStatuses.badTiming', i18nOpts) },
]);

const LifecycleStages = Object.freeze([
  { value: 'subscriber', label: i18n.t('lifecycleStages.subscriber', i18nOpts) },
  { value: 'lead', label: i18n.t('lifecycleStages.lead', i18nOpts) },
  { value: 'marketingqualifiedlead', label: i18n.t('lifecycleStages.marketingQualifiedLead', i18nOpts) },
  { value: 'salesqualifiedlead', label: i18n.t('lifecycleStages.salesQualifiedLead', i18nOpts) },
  { value: 'opportunity', label: i18n.t('lifecycleStages.opportunity', i18nOpts) },
  { value: 'customer', label: i18n.t('lifecycleStages.customer', i18nOpts) },
  { value: 'evangelist', label: i18n.t('lifecycleStages.evangelist', i18nOpts) },
  { value: 'other', label: i18n.t('lifecycleStages.other', i18nOpts) },
]);

const GeneralSettings = ({ form, onTextChange }) => (
  <>
    <Row form>
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="contactListId">{i18n.t('contactListId', i18nOpts)}</Label>
          <Input
            type="text"
            name="contactListId"
            id="contactListId"
            value={form.contactListId || ''}
            onChange={onTextChange}
            className="w-25"
          />
          <small className="text-muted">{i18n.t('contactListIdHelp', i18nOpts)}</small>
        </FormGroup>
      </Col>
    </Row>

    <Row form>
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="lifecycleStage">{i18n.t('lifecycleStage', i18nOpts)}</Label>
          <Input
            type="select"
            name="lifecycleStage"
            id="lifecycleStage"
            value={form.lifecycleStage || ''}
            onChange={onTextChange}
          >
            <option value="">{i18n.t('select.select')}</option>
            {
                LifecycleStages.map((ls) => (
                  <option key={`lifecycle-stage-option-${ls.value}`} value={ls.value}>
                    {ls.label}
                  </option>
                ))
              }
          </Input>
        </FormGroup>
      </Col>
    </Row>

    <Row form className="mb-4">
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="leadStatus">{i18n.t('leadStatus', i18nOpts)}</Label>
          <Input
            type="select"
            name="leadStatus"
            id="leadStatus"
            value={form.leadStatus || ''}
            onChange={onTextChange}
          >
            <option value="">{i18n.t('select.select')}</option>
            {
                LeadStatuses.map((ls) => (
                  <option key={`lead-status-option-${ls.value}`} value={ls.value}>
                    {ls.label}
                  </option>
                ))
              }
          </Input>
        </FormGroup>
      </Col>
    </Row>
  </>
);

GeneralSettings.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func.isRequired
};

GeneralSettings.defaultProps = {};

export default GeneralSettings;
