import {
  LIST_MODEL_PALETTES,
  SAVE_MODEL_PALETTE,
  DELETE_MODEL_PALETTE,
  SET_RELOAD_MODEL_PALETTES
} from './types';
import {
  createModelPaletteQuery, deleteModelPaletteQuery, listModelPalettesQuery,
  updateModelPaletteQuery
} from '../../graphql';
import api from '../../api';

export function listModelPalettes(variables = {}) {
  return {
    type: LIST_MODEL_PALETTES,
    payload: api.graphql(listModelPalettesQuery, variables)
  };
}

export function saveModelPalette(variables = {}) {
  const query = variables.input.id ? updateModelPaletteQuery : createModelPaletteQuery;
  return {
    type: SAVE_MODEL_PALETTE,
    payload: api.graphql(query, variables)
  };
}

export function deleteModelPalette(id) {
  return {
    type: DELETE_MODEL_PALETTE,
    payload: api.graphql(deleteModelPaletteQuery, { id })
  };
}

export function setReloadModelPalettes(reload) {
  return {
    type: SET_RELOAD_MODEL_PALETTES,
    payload: { reload }
  };
}
