import { CLEAN_PARTY_CONTACTS, LIST_PARTY_CONTACTS } from './types';
import api from '../../api';
import { listPartyContactsQuery } from '../../graphql';

export function listPartyContacts(variables = {}) {
  return {
    type: LIST_PARTY_CONTACTS,
    payload: api.graphql(listPartyContactsQuery, variables)
  };
}

export function cleanPartyContacts(extraProps = {}) {
  return {
    type: CLEAN_PARTY_CONTACTS,
    payload: Promise.resolve(extraProps)
  };
}
