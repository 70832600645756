import {
  GET_OPTION, GET_OPTIONS, SAVE_OPTION, DELETE_OPTION
} from './types';
import {
  listOptionsQuery, deleteOptionQuery, getOptionQuery, updateOptionQuery, createOptionQuery
} from '../../graphql';
import api from '../../api';

export function getOption(id) {
  return {
    type: GET_OPTION,
    payload: api.graphql(getOptionQuery, { id })
  };
}

export function getOptions(variables = {}) {
  return {
    type: GET_OPTIONS,
    payload: api.graphql(listOptionsQuery, variables)
  };
}

export function saveOption(variables = {}) {
  const query = variables.input.id ? updateOptionQuery : createOptionQuery;

  return {
    type: SAVE_OPTION,
    payload: api.graphql(query, variables)
  };
}

export function deleteOption(id) {
  return {
    type: DELETE_OPTION,
    payload: api.graphql(deleteOptionQuery, { id })
  };
}
