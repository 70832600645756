import {
  createTaxRateQuery,
  deleteTaxRateQuery,
  getTaxRateQuery,
  listTaxRatesQuery,
  updateTaxRateQuery
} from '../../graphql';
import {
  DELETE_TAX_RATE, GET_TAX_RATE, GET_TAX_RATES, SAVE_TAX_RATE
} from './types';
import api from '../../api';

export function saveTaxRate(variables) {
  const query = variables.input.id ? updateTaxRateQuery : createTaxRateQuery;
  return {
    type: SAVE_TAX_RATE,
    payload: api.graphql(query, variables)
  };
}

export function getTaxRate(id) {
  return {
    type: GET_TAX_RATE,
    payload: api.graphql(getTaxRateQuery, { id })
  };
}

export function getTaxRates(variables) {
  return {
    type: GET_TAX_RATES,
    payload: api.graphql(listTaxRatesQuery, variables)
  };
}

export function deleteTaxRate(id) {
  return {
    type: DELETE_TAX_RATE,
    payload: api.graphql(deleteTaxRateQuery, { id })
  };
}
