import React from 'react';
import PropTypes from 'prop-types';

import FilterCheckbox from '../../../../common/FilterCheckbox';

const ListValue = ({
  label, value, selectedValues, onChange
}) => (
  <FilterCheckbox
    label={label}
    value={value}
    checked={selectedValues.includes(value)}
    onChange={onChange}
  />
);

ListValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func
};

ListValue.defaultProps = {
  selectedValues: [],
  onChange: () => {}
};

export default ListValue;
