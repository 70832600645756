import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';
import { FormFeedback } from 'reactstrap';

function getInputError(error, name) {
  if (!error) return null;
  if (typeof error !== 'object') return null;
  const errors = error[name] || [];
  return v.capitalize(errors[0]);
}

export function isInputInvalid(error, name) {
  const message = getInputError(error, name);
  return !v.isBlank(message);
}

function InputError({ error, name, type = 'input' }) {
  const message = getInputError(error, name);
  if (v.isBlank(message)) return null;

  return type === 'input'
    ? <FormFeedback>{message}</FormFeedback> : <span className="error-message">{message}</span>;
}

InputError.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any)
  ]),
};

InputError.defaultProps = {
  error: null
};

export default InputError;
