import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { navigate } from '@reach/router';
import { Link } from 'gatsby';
import i18n from 'i18n-js';
import classnames from 'classnames';

import { getOptionType } from '../../../../store/actions/optionTypeActions';
import { isAdmin } from '../../../../utils/authUtils';
import Overview from './Overview';
import Modifications from '../../../global/Modifications';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.optionTypes.show.index' };

const Tabs = Object.freeze({
  OVERVIEW: 'overview',
  MODIFICATIONS: 'modifications'
});

const Show = ({
  id, optionType, loading, ...props
}) => {
  const [activeTab, setActiveTab] = useState(Tabs.OVERVIEW);

  useEffect(() => {
    props.getOptionType(parseInt(id, 10))
      .catch(() => navigate('/admin/optionTypes'));
  }, []);

  if (!optionType || loading) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={optionType.name} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{optionType.name}</h2>
        </div>
        <div>
          {isAdmin() && (
            <Link to={`/admin/optionTypes/form?id=${optionType.id}`} className="btn btn-primary ml-3">
              <i className="far fa-edit mr-2" />
              {i18n.t('buttons.edit')}
            </Link>
          )}
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === Tabs.OVERVIEW })}
            onClick={() => setActiveTab(Tabs.OVERVIEW)}
          >
            {i18n.t('tabs.overview', i18nOpts)}
          </NavLink>
        </NavItem>
        {isAdmin() && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.MODIFICATIONS })}
              onClick={() => setActiveTab(Tabs.MODIFICATIONS)}
            >
              {i18n.t('tabs.modifications', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.OVERVIEW}>
          <Overview />
        </TabPane>
        {isAdmin() && (
          <TabPane tabId={Tabs.MODIFICATIONS}>
            <Modifications instance={optionType} />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

export default connect((store) => ({
  optionType: store.optionTypes.optionType,
  loading: store.optionTypes.getOptionType.loading
}), {
  getOptionType
})(Show);
