import React from 'react';
import { connect } from 'react-redux';
import {
  CardHeader, CardTitle, Card, CardBody
} from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ModalNames, PriceableTypes } from '../../../../../../constants';
import { deleteSpecification } from '../../../../../../store/actions/specificationActions';
import ModalService from '../../../../../../services/ModalService';
import ActionsMenu from '../../../../../global/ActionsMenu';
import { isAdmin } from '../../../../../../utils/authUtils';
import AddPriceRuleButton from '../../../../../global/priceRules/AddPriceRuleButton';
import PriceRuleList from '../../../../../global/priceRules/PriceRuleList';

const ListItem = ({ specification, ...props }) => {
  const onDelete = (specificationId) => {
    props.deleteSpecification(specificationId);
  };

  const onEdit = (selectedSpecification) => {
    ModalService.open(ModalNames.SPECIFICATION_FORM, { specification: selectedSpecification });
  };

  if (!specification) return null;

  return (
    <Card>
      <CardHeader>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{specification.name}</CardTitle>
          </div>
          {isAdmin() && (
            <div>
              <ActionsMenu item={specification} onEdit={onEdit} onDelete={onDelete} />
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody>
        {isAdmin() && (
          <div className="text-right">
            <AddPriceRuleButton
              priceableId={specification.id}
              priceableType={PriceableTypes.SPECIFICATION}
            />
          </div>
        )}

        <PriceRuleList
          priceableId={specification.id}
          priceableType={PriceableTypes.SPECIFICATION}
          className={classNames({ 'mt-3': isAdmin() })}
        />
      </CardBody>
    </Card>
  );
};

ListItem.propTypes = {
  specification: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
}), {
  deleteSpecification
})(ListItem);
