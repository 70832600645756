import {
  createCommunityImageQuery, deleteCommunityImageQuery,
  listCommunityImagesQuery, sortCommunityImagesQuery,
  updateCommunityImageQuery
} from '../../../../../graphql';
import api from '../../../../../api';

export function fetchCommunityImagesAsync(variables = {}) {
  return api.graphql(listCommunityImagesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items))
    .catch(() => Promise.reject());
}

export function saveCommunityImageAsync(variables = {}) {
  const query = variables?.input?.id ? updateCommunityImageQuery : createCommunityImageQuery;

  return api.graphql(query, variables);
}

export function deleteCommunityImageAsync(id) {
  return api.graphql(deleteCommunityImageQuery, { id });
}

export function sortCommunityImagesAsync(variables = {}) {
  return api.graphql(sortCommunityImagesQuery, variables);
}
