import React from 'react';
import * as moment from 'moment';
import i18n from 'i18n-js';

import { formatTimestamp } from '../../../../../utils/dateUtils';

const i18nOpts = { scope: 'components.admin.externalDataSources.syncLogs.index' };

const DATE_FORMAT_FOR_KEY = 'YYYY-MM-DDTHH:mm:ss[Z]';

const SyncLogs = ({ syncLogs, lastSyncDate }) => {
  if (!syncLogs || !lastSyncDate) return null;
  const sanitizedKey = moment.utc(lastSyncDate).format(DATE_FORMAT_FOR_KEY);

  const lastSyncLog = syncLogs[sanitizedKey];
  const {
    createdProductTypeIds = [],
    updatedProductTypeIds = [],
    createdModelIds = [],
    updatedModelIds = [],
    createdCommunityIds = [],
    updatedCommunityIds = [],
    createdCommunityModelIds = [],
    updatedCommunityModelIds = [],
    createdCommunityProductTypeIds = [],
    updatedCommunityProductTypeIds = [],
    createdElevationIds = [],
    updatedElevationIds = [],
    createdCommunityElevationIds = [],
    updatedCommunityElevationIds = [],
    createdFloorOptionIds = [],
    updatedFloorOptionIds = [],
    updatedPriceRuleIds = [],
    errors = [],
  } = lastSyncLog;

  return (
    <div>
      <h2>{i18n.t('lastReport', { ...i18nOpts, lastSyncDate: formatTimestamp(lastSyncDate) })}</h2>
      <div className="table-responsive">
        <table className="my-3">
          <tbody>
            <tr>
              <td><span className="px-4">{i18n.t('createdProductTypes', i18nOpts)}</span></td>
              <td><span className="px-4 text-success">{createdProductTypeIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('updatedProductTypes', i18nOpts)}</span></td>
              <td><span className="px-4 text-warning">{updatedProductTypeIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('createdModels', i18nOpts)}</span></td>
              <td><span className="px-4 text-success">{createdModelIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('updatedModels', i18nOpts)}</span></td>
              <td><span className="px-4 text-warning">{updatedModelIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('createdCommunities', i18nOpts)}</span></td>
              <td><span className=" px-4 text-success">{createdCommunityIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('updatedCommunities', i18nOpts)}</span></td>
              <td><span className=" px-4 text-warning">{updatedCommunityIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('createdCommunityModels', i18nOpts)}</span></td>
              <td><span className=" px-4 text-success">{createdCommunityModelIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('updatedCommunityModels', i18nOpts)}</span></td>
              <td><span className=" px-4 text-warning">{updatedCommunityModelIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('createdCommunityProductTypes', i18nOpts)}</span></td>
              <td><span className=" px-4 text-success">{createdCommunityProductTypeIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('updatedCommunityProductTypes', i18nOpts)}</span></td>
              <td><span className=" px-4 text-warning">{updatedCommunityProductTypeIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('createdElevations', i18nOpts)}</span></td>
              <td><span className=" px-4 text-success">{createdElevationIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('updatedElevations', i18nOpts)}</span></td>
              <td><span className=" px-4 text-warning">{updatedElevationIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('createdCommunityElevations', i18nOpts)}</span></td>
              <td><span className=" px-4 text-success">{createdCommunityElevationIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('updatedCommunityElevations', i18nOpts)}</span></td>
              <td><span className=" px-4 text-warning">{updatedCommunityElevationIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('createdFloorOptions', i18nOpts)}</span></td>
              <td><span className=" px-4 text-success">{createdFloorOptionIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('updatedFloorOptions', i18nOpts)}</span></td>
              <td><span className=" px-4 text-warning">{updatedFloorOptionIds.length}</span></td>
            </tr>
            <tr>
              <td><span className="px-4">{i18n.t('updatedPriceRuleIds', i18nOpts)}</span></td>
              <td><span className=" px-4 text-warning">{updatedPriceRuleIds.length}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      { errors.length > 0 && (
        <div className="font-22 text-danger mt-2">
          <span>{i18n.t('errors', i18nOpts)}</span>
          &nbsp;
          <span className="text-danger">{errors.join(', ')}</span>
        </div>
      )}
    </div>
  );
};

export default SyncLogs;
