import React from 'react';

const ExistingCustomerIcon = ({ ...props }) => (
  <svg width="125" height="125" viewBox="0 0 125 125" fill="none" color="#667085" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M115.118 39.4744C116.445 39.4744 117.521 38.398 117.521 37.0706V21.8939C117.521 20.5665 116.445 19.4901 115.118 19.4901H108.073V13.8949C108.073 8.88406 103.996 4.80762 98.9856 4.80762C76.0435 4.80762 56.9685 4.80762 21.8337 4.80762C13.9178 4.80762 7.47864 11.2468 7.47864 19.1627V105.836C7.47864 113.752 13.9178 120.192 21.8337 120.192C44.1584 120.192 64.0169 120.192 98.9856 120.192C103.996 120.192 108.073 116.116 108.073 111.104V105.509H115.118C116.445 105.509 117.522 104.432 117.522 103.105V87.928C117.522 86.6006 116.445 85.5242 115.118 85.5242H108.073V83.497H115.118C116.445 83.497 117.522 82.4206 117.522 81.0932V65.9165C117.522 64.5891 116.445 63.5127 115.118 63.5127H108.073V61.4855H115.118C116.445 61.4855 117.522 60.4091 117.522 59.0816V43.905C117.522 42.5776 116.445 41.5011 115.118 41.5011H108.073V39.474L115.118 39.4744ZM112.714 24.2978V34.6667H108.073V24.2978H112.714ZM21.8337 115.385C16.5695 115.385 12.2863 111.102 12.2863 105.836V19.1627C12.2863 13.8982 16.5693 9.61531 21.8337 9.61531H25.4008V115.385H21.8337ZM103.265 111.104C103.265 113.464 101.345 115.385 98.9856 115.385H30.2084V9.61531H98.9856C101.345 9.61531 103.265 11.5355 103.265 13.8949V21.8939V37.0706V43.9055V59.0821V65.917V81.0937V87.9285V103.105V111.104ZM112.714 90.3319V100.701H108.073V90.3319H112.714ZM112.714 68.3206V78.6896H108.073V68.3206H112.714ZM112.714 46.3091V56.678H108.073V46.3091H112.714Z" fill="currentColor" />
    <path d="M64.2719 66.5621C77.8433 66.5621 89.1825 55.4893 89.1825 41.6503C89.1825 27.915 78.0072 16.7397 64.2719 16.7397C50.5366 16.7397 39.3613 27.915 39.3613 41.6503C39.3613 55.5244 50.7005 66.5621 64.2719 66.5621ZM58.3137 38.8405C58.3137 35.5551 60.9863 32.8813 64.2717 32.8813C67.557 32.8813 70.2308 35.5551 70.2308 38.8405C70.2308 42.1128 67.557 44.776 64.2717 44.776C60.9863 44.776 58.3137 42.1128 58.3137 38.8405ZM64.2719 49.584C69.7659 49.584 74.9072 51.7768 78.6616 55.6599C75.0075 59.4128 69.9113 61.7546 64.2719 61.7546C58.6322 61.7546 53.5358 59.4126 49.8818 55.6595C53.6361 51.777 58.7774 49.584 64.2719 49.584ZM64.2719 21.5477C75.3568 21.5477 84.3748 30.5657 84.3748 41.6506C84.3748 45.359 83.3476 48.8244 81.5892 51.8099C78.9039 49.19 75.6955 47.2419 72.1818 46.0705C73.9387 44.1578 75.0385 41.6335 75.0385 38.8405C75.0385 32.9037 70.2084 28.0736 64.2717 28.0736C58.3361 28.0736 53.506 32.9037 53.506 38.8405C53.506 41.6333 54.6058 44.1578 56.3625 46.0703C52.8486 47.2414 49.6399 49.1897 46.9544 51.8097C45.1962 48.8241 44.1688 45.3587 44.1688 41.6503C44.169 30.5655 53.1868 21.5477 64.2719 21.5477ZM89.1308 86.8025H41.7652C40.4378 86.8025 39.3613 87.8789 39.3613 89.2063C39.3613 90.5337 40.4378 91.6102 41.7652 91.6102H89.1308C90.4582 91.6102 91.5346 90.5337 91.5346 89.2063C91.5346 87.8789 90.4582 86.8025 89.1308 86.8025ZM89.1308 99.4801H41.7652C40.4378 99.4801 39.3613 100.557 39.3613 101.884C39.3613 103.211 40.4378 104.288 41.7652 104.288H89.1308C90.4582 104.288 91.5346 103.211 91.5346 101.884C91.5346 100.557 90.4582 99.4801 89.1308 99.4801Z" fill="currentColor" />
  </svg>
);

ExistingCustomerIcon.propTypes = {};

ExistingCustomerIcon.defaultProps = {};

export default ExistingCustomerIcon;
