import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { BiTrash } from 'react-icons/bi';

import PhoneNumberInput from '../../../../../global/PhoneNumberInput';

const PhoneNumberField = ({
  value, onChange, index, onDelete, phonesCount
}) => (
  <FormGroup className="d-flex align-items-center">
    <PhoneNumberInput
      value={value}
      onChange={(e) => onChange(index, e)}
    />
    {phonesCount > 1 && (
      <div className="ml-2 d-block text-danger" role="button" aria-hidden onClick={() => onDelete(index)}>
        <BiTrash size="1.2rem" />
      </div>
    )}
  </FormGroup>
);

PhoneNumberField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  phonesCount: PropTypes.number,
  index: PropTypes.number.isRequired
};
PhoneNumberField.defaultProps = {
  value: null,
  onChange: () => {},
  onDelete: () => {},
  phonesCount: 0
};
export default PhoneNumberField;
