import React from 'react';
import PropTypes from 'prop-types';

import TimeLineElement from '../../../../common/TimeLineElement';
import Interests from './Interests';
import TrafficSource from './TrafficSource';
import { getVisitDescription } from './utils';
import Referer from './Referer';
import ActivityDate from '../components/ActivityDate';
import VisitIcon from '../../../../../assets/icons/customer-activities/VisitIcon';

const Visit = ({
  activity,
  innerKey
}) => {
  const { visitorSession } = activity;

  const description = getVisitDescription(visitorSession);

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<VisitIcon />}
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-start gap-3">
          <div className="activity-timeline-title">{description}</div>
          <ActivityDate date={activity.createdAt} />
        </div>

        <div className="d-flex flex-column gap-2">
          <Interests activity={activity} />
          <TrafficSource visitorSession={visitorSession} />
          <Referer visitorSession={visitorSession} />
        </div>
      </div>
    </TimeLineElement>
  );
};

Visit.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

export default Visit;
