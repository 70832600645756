import api from '../../../../api';
import { getVerifiedAffordabilitiesQuery } from '../../../../graphql';
import { fakeValue } from '../../../../utils/sampleDataUtils';

export function fetchVerifiedAffordabilitiesAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getVerifiedAffordabilitiesQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  const data = [
    { range: '500k', customersCount: fakeValue(100) },
    { range: '600k', customersCount: fakeValue(100) },
    { range: '700k', customersCount: fakeValue(100) },
    { range: '1M', customersCount: fakeValue(100) },
    { range: '1.5M', customersCount: fakeValue(100) },
    { range: '2M', customersCount: fakeValue(100) },
    { range: '2.5M+', customersCount: fakeValue(100) },
  ];
  const totalCustomers = data.reduce((total, item) => total + item.customersCount, 0);

  const dataWithPercentages = data.map((item) => {
    const percentage = (100 * item.customersCount) / totalCustomers;
    const customersPercentage = Math.round(percentage * 100) / 100;
    return { ...item, customersPercentage };
  });
  return Promise.resolve(dataWithPercentages);
}
