import React from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Row, Col, Card } from 'reactstrap';

const i18nOpts = { scope: 'components.admin.externalDataSources.marondaHomes.details.index' };

const Details = ({ externalDataSource }) => (
  <Row>
    <Col xs={12} sm={6}>
      <div className="mb-4">
        <div className="label">{i18n.t('apiKey', i18nOpts)}</div>
        <div><strong>{externalDataSource.headers.ApiKey}</strong></div>
      </div>

      <>
        <div className="mb-3">{i18n.t('proxy', i18nOpts)}</div>

        <Card body>
          <div className="mb-4">
            <div className="label">{i18n.t('proxyHost', i18nOpts)}</div>
            <div><strong>{externalDataSource.headers.ProxyHost}</strong></div>
          </div>

          <div className="mb-4">
            <div className="label">{i18n.t('proxyPort', i18nOpts)}</div>
            <div><strong>{externalDataSource.headers.ProxyPort}</strong></div>
          </div>

          <div className="mb-4">
            <div className="label">{i18n.t('proxyUsername', i18nOpts)}</div>
            <div><strong>{externalDataSource.headers.ProxyUsername}</strong></div>
          </div>

          <div>
            <div className="label">{i18n.t('proxyPassword', i18nOpts)}</div>
            <div><strong>{externalDataSource.headers.ProxyPassword}</strong></div>
          </div>
        </Card>
      </>
    </Col>
  </Row>
);

Details.propTypes = {};

Details.defaultProps = {};

export default connect((store) => ({
  externalDataSource: store.externalDataSources.externalDataSource
}), {})(Details);
