import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

const NavigationBreadcrumbs = ({ items }) => {
  if (items.length < 2) return null;

  const { pathname } = useLocation();

  const isClickable = (item) => !pathname.includes(item.url);

  const onClick = (item) => () => {
    navigate(item.url);
  };

  const getExtraProps = (item) => {
    if (!isClickable(item)) return {};
    return {
      'aria-hidden': true,
      role: 'button',
      onClick: onClick(item)
    };
  };

  return (
    <Breadcrumb>
      {items.map((item) => (
        <BreadcrumbItem key={`breadcrumb-${item.name}`}>
          <span
            className={classNames({ 'text-primary': isClickable(item) })}
            {...getExtraProps(item)}
          >
            {item.name}
          </span>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

NavigationBreadcrumbs.propTypes = {};

NavigationBreadcrumbs.defaultProps = {};

export default connect((store) => ({
  items: store.navigation.children
}))(NavigationBreadcrumbs);
