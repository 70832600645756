import v from 'voca';

import { TeamUserPermissions } from '../constants';
import { isAdmin } from './authUtils';

export function isTeamManager(teamPermission) {
  return teamPermission === TeamUserPermissions.MANAGER;
}

export function isTeamLead(teamPermission) {
  return teamPermission === TeamUserPermissions.LEAD;
}

export function isTeamMember(teamPermission) {
  return teamPermission === TeamUserPermissions.MEMBER;
}

export function canManageTeam(teamPermission) {
  if (v.isBlank(teamPermission) && isAdmin()) return true;

  return isTeamManager(teamPermission);
}

export function canManageTeamMembers(teamPermission) {
  if (v.isBlank(teamPermission) && isAdmin()) return true;

  return [TeamUserPermissions.MANAGER, TeamUserPermissions.LEAD].includes(teamPermission);
}
