import React, { useState } from 'react';
import {
  FormGroup, Input, Label, Spinner, Button, ButtonGroup
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { saveCompany } from '../../../../../store/actions/companyActions';
import { hasFormValues } from '../../../../../utils/formUtils';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.settings.index' };

function getInitialSettings(widgetSettings) {
  return {
    windowWidth: widgetSettings.windowWidth || 70,
    windowWidthUnit: widgetSettings.windowWidthUnit || '%',
  };
}

const Settings = ({ currentCompany, ...props }) => {
  const [form, setForm] = useState(getInitialSettings(currentCompany.widgetSettings));
  const [saving, setSaving] = useState(false);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSave = () => {
    setSaving(true);

    const widgetSettings = {
      windowWidth: parseFloat(form.windowWidth?.toString()) || null,
      windowWidthUnit: form.windowWidthUnit
    };
    const variables = { input: { id: currentCompany.id, widgetSettings } };
    props.saveCompany(variables)
      .then(() => {
        toast.info(i18n.t('messages.saved', i18nOpts));
      })
      .catch(() => {})
      .finally(() => {
        setSaving(false);
      });
  };

  const onChangeUnit = (windowWidthUnit) => {
    setForm((prevForm) => ({ ...prevForm, windowWidthUnit }));
  };

  const hasValues = hasFormValues(form);

  return (
    <div>
      <div className="mb-4">
        <FormGroup className="mb-2">
          <Label for="windowWidth">{i18n.t('windowWidth', i18nOpts)}</Label>
          <div className="d-flex">
            <Input
              type="number"
              name="windowWidth"
              id="windowWidth"
              className="mr-3"
              style={{ width: 120 }}
              value={form.windowWidth || ''}
              onChange={onTextChange}
            />

            <ButtonGroup size="sm">
              <Button color="dark" outline={form.windowWidthUnit !== 'px'} active={form.windowWidthUnit === 'px'} className="text-capitalize" onClick={() => onChangeUnit('px')}>Px</Button>
              <Button color="dark" outline={form.windowWidthUnit !== '%'} active={form.windowWidthUnit === '%'} onClick={() => onChangeUnit('%')}>&nbsp;%&nbsp;</Button>
            </ButtonGroup>
          </div>
        </FormGroup>

        <small id="emailHelp" className="form-text text-muted">
          {i18n.t('windowWidthDesc', i18nOpts)}
        </small>
      </div>

      <Button color="primary" onClick={onSave} className="mr-3" disabled={saving || !hasValues}>
        {saving && (<Spinner size="sm" className="mr-2" />)}
        {i18n.t('buttons.save')}
      </Button>
    </div>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveCompany
})(Settings);
