import React from 'react';

const AffordabilityIcon = ({ ...props }) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.999 21.8101C12.999 23.6514 14.4916 25.144 16.3329 25.144H19.4286C21.4013 25.144 23.0005 23.5448 23.0005 21.572C23.0005 19.5993 21.4013 18 19.4286 18H16.571C14.5983 18 12.999 16.4008 12.999 14.4281C12.999 12.4553 14.5983 10.8561 16.571 10.8561H19.6667C21.5079 10.8561 23.0005 12.3487 23.0005 14.1899M17.9998 8.71292V10.8561M17.9998 25.144V27.2872M32.2877 18C32.2877 25.891 25.8908 32.2879 17.9998 32.2879C10.1088 32.2879 3.71191 25.891 3.71191 18C3.71191 10.1091 10.1088 3.71216 17.9998 3.71216C25.8908 3.71216 32.2877 10.1091 32.2877 18Z" stroke="#00A572" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

AffordabilityIcon.propTypes = {};

AffordabilityIcon.defaultProps = {};

export default AffordabilityIcon;
