import React from 'react';
import { connect } from 'react-redux';

import Placeholder from './Placeholder';
import { hasCustomerPreApprovalAmount } from '../../../../Overview/VerificationSection/utils';
import InfoCard from './InfoCard';

const PreApproval = ({ customer, currentCompany }) => {
  const hasPreApprovalAmount = hasCustomerPreApprovalAmount(customer, currentCompany);

  return (
    <div>
      {hasPreApprovalAmount ? (
        <InfoCard />
      ) : (
        <Placeholder />
      )}
    </div>
  );
};

PreApproval.propTypes = {};

PreApproval.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany,
}))(PreApproval);
