import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import TimeLineElement from '../../../../common/TimeLineElement';
import SignSubmittedIcon
  from '../../../../../assets/icons/customer-activities/SignSubmittedIcon';
import ActivityDate from '../components/ActivityDate';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.signSubmitted.index' };

const SignSubmitted = ({ activity, innerKey }) => (
  <TimeLineElement
    innerKey={innerKey}
    showDate={activity.showDate}
    date={activity.createdAt}
    Icon={<SignSubmittedIcon />}
  >
    <div className="d-flex align-items-start gap-3">
      <div className="activity-timeline-title">{i18n.t('title', i18nOpts)}</div>
      <ActivityDate date={activity.createdAt} />
    </div>
    <div>{i18n.t('description', i18nOpts)}</div>
  </TimeLineElement>
);

SignSubmitted.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

SignSubmitted.defaultProps = {};

export default SignSubmitted;
