import React from 'react';
import PropTypes from 'prop-types';

import CustomColumnValue from './CustomColumnValue';
import useValidSelectedColumns from '../../hooks/useValidSelectedColumns';
import CustomerVerificationProgress
  from '../../../../../global/customer/CustomerVerificationProgress';
import useIsVerifiedEnabled from '../../../../../../hooks/useIsVerifiedEnabled';
import useIsReserveNowEnabled from '../../../../../../hooks/useIsReserveNowEnabled';
import CustomerReservationProgress
  from '../../../../../global/customer/CustomerReservationProgress';
import CustomerSignUpDate from '../../../../../global/customer/CustomerSignUpDate';
import CustomerLastActivityDate from '../../../../../global/customer/CustomerLastActivityDate';
import CustomerPriceRange from '../../../../../global/customer/CustomerPriceRange';
import CustomerFinancialAmount from '../../../../../global/customer/CustomerFinancialAmount';
import CustomerName from './CustomerName';

const ListItem = ({ customer }) => {
  const columns = useValidSelectedColumns();
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const isReserveNowEnabled = useIsReserveNowEnabled();

  return (
    <tr>
      <CustomerName customer={customer} />
      {isVerifiedEnabled && (
        <CustomerVerificationProgress customer={customer} wrapInTd />
      )}
      {isReserveNowEnabled && (
        <CustomerReservationProgress customer={customer} wrapInTd />
      )}
      {isVerifiedEnabled && (
        <td>
          <CustomerFinancialAmount customer={customer} />
        </td>
      )}
      <td>
        <CustomerPriceRange customer={customer} />
      </td>
      <td>
        <CustomerSignUpDate customer={customer} />
      </td>
      <td>
        <CustomerLastActivityDate customer={customer} />
      </td>
      {
        columns.map((column) => (
          <td key={`customer-cell-${customer.id}-${column}`}>
            <CustomColumnValue column={column} customer={customer} />
          </td>
        ))
      }
    </tr>
  );
};

ListItem.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
