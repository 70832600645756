import React from 'react';
import PropTypes from 'prop-types';

import FilterCheckbox from '../../../../../../common/FilterCheckbox';
import { SellerTypes } from '../../../../../../../constants';

const SalesRepItem = ({ item, selectedItems, onChange }) => {
  const isSelected = selectedItems.some((salesRep) => {
    const { id, __typename: typename } = salesRep;
    return id === item?.id && typename === SellerTypes.SALES_REP;
  });

  return (
    <FilterCheckbox
      label={item.name}
      value={item.id}
      checked={isSelected}
      onChange={() => onChange(item, SellerTypes.SALES_REP)}
    />
  );
};

SalesRepItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChange: PropTypes.func,
};

SalesRepItem.defaultProps = {
  selectedItems: [],
  onChange: () => {}
};

export default SalesRepItem;
