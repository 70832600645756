import {
  RELOAD_PROJECT_BUILDINGS,
  SET_PROJECT_BUILDINGS_VIEW_MODE
} from './types';

export function reloadProjectBuildings(reload) {
  return {
    type: RELOAD_PROJECT_BUILDINGS,
    payload: { reload }
  };
}

export function setProjectBuildingsViewMode(viewMode) {
  return {
    type: SET_PROJECT_BUILDINGS_VIEW_MODE,
    payload: { viewMode }
  };
}
