import React from 'react';
import PropTypes from 'prop-types';

const Project = ({ unit }) => {
  const { buildingModel } = unit;
  const { project } = buildingModel || {};

  if (!project) return null;

  return (
    <div className="font-weight-500 col-gray-900">
      {project.name}
    </div>
  );
};

Project.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

Project.defaultProps = {};

export default Project;
