import React from 'react';

const ChevronDownIcon = ({ ...props }) => (
  <svg width="52" height="24" viewBox="0 0 52 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_542_4)">
      <path d="M-1.5 1.5L26 21.5L53.5 1.5" stroke="white" strokeWidth="4" />
    </g>
    <defs>
      <clipPath id="clip0_542_4">
        <rect width="52" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ChevronDownIcon;
