import React from 'react';
import { IoBarChartSharp } from 'react-icons/io5';
import { UncontrolledTooltip } from 'reactstrap';

const ReportIcon = ({
  onPress, size, id, tooltip
}) => (
  <div id={`report-icon-${id}`}>
    <IoBarChartSharp
      onClick={() => onPress()}
      className="report-icon"
      size={size}
      role="button"
      color="#BAC639"
    />

    <UncontrolledTooltip target={`report-icon-${id}`}>
      {tooltip}
    </UncontrolledTooltip>
  </div>
);

export default ReportIcon;
