import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import styles from './LeadLocation.module.scss';
import api from '../../../../api';
import { getLeadLocationQuery } from '../../../../graphql';
import GoogleHeatmap from '../../../global/GoogleHeatmap';

const i18nOpts = { scope: 'components.admin.overview.leadLocation.index' };

function fetchLeadLocationsAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getLeadLocationQuery, variables)
    .then(({ data: { results } }) => Promise.resolve(results));
}

function generateSampleDataAsync() {
  const data = [
    { lat: 30.288372, lng: -97.732500 },
    { lat: 30.288372, lng: -97.732500 },
    { lat: 30.339882, lng: -97.68500 },
    { lat: 30.339882, lng: -97.68500 },
    { lat: 30.335582, lng: -97.66565 },
    { lat: 30.335582, lng: -97.66565 },
    { lat: 30.335582, lng: -97.65565 },
    { lat: 30.335582, lng: -97.65565 },
    { lat: 30.332582, lng: -97.64065 },
    { lat: 30.328582, lng: -97.63565 },
    { lat: 30.332582, lng: -97.67565 },
    { lat: 30.212582, lng: -97.67565 },
    { lat: 30.212582, lng: -97.67565 },
    { lat: 30.212582, lng: -97.67565 }
  ];
  return Promise.resolve(data);
}

function fetchLeadLocationDataAsync(company, filter) {
  return company.enableSampleData ? generateSampleDataAsync()
    : fetchLeadLocationsAsync(filter);
}

const LeadLocation = ({ currentCompany, filter }) => {
  const [loading, setLoading] = useState(false);
  const [leadLocations, setLeadLocations] = useState([]);

  useEffect(() => {
    if (!filter) return;

    setLoading(true);
    fetchLeadLocationDataAsync(
      currentCompany, filter
    )
      .then((items) => setLeadLocations(items))
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [filter]);

  return (
    <Card body className="h-100">
      <h4>{i18n.t('title', i18nOpts)}</h4>

      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <div className={styles.mapContainer}>
          <GoogleHeatmap positions={leadLocations} />
        </div>
      )}
    </Card>
  );
};

LeadLocation.propTypes = {};

LeadLocation.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  currentCommunity: store.overview.community,
  currentModel: store.overview.model
}), {})(LeadLocation);
