import api from '../../../../../../../api';

import { listUniqueQuickPossessionsTotalBathsQuery } from '../../../../../../../graphql/quickPossessions';

export function fetchQuickPossessionsBathsAsync(companyId) {
  const variables = { companyId };

  return api.graphql(listUniqueQuickPossessionsTotalBathsQuery, variables)
    .then(({ data: { result = [] } }) => result);
}
