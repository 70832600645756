import React from 'react';
import { Card } from 'reactstrap';
import i18n from 'i18n-js';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { formatCurrency } from '../../../../../utils/currencyUtils';
import styles from './AverageMonthlyRecurring.module.scss';

const i18nOpts = { scope: 'components.admin.customers.financialReport.averageMonthlyRecurring.index' };

const AverageMonthlyRecurring = ({ financialReport }) => {
  const { accountAnalysis } = financialReport;
  const { averageMonthlyRecurringDeposits, averageMonthlyRecurringPayments } = accountAnalysis;

  const balance = (averageMonthlyRecurringDeposits || 0) - (averageMonthlyRecurringPayments || 0);
  const maxValue = Math.max(averageMonthlyRecurringDeposits, averageMonthlyRecurringPayments, 0);

  let depositPercentage = 0;
  let paymentPercentage = 0;
  if (maxValue > 0) {
    depositPercentage = Math.round((averageMonthlyRecurringDeposits * 100) / maxValue) * 2;
    paymentPercentage = Math.round((averageMonthlyRecurringPayments * 100) / maxValue) * 2;
  }

  return (
    <Card body className="text-dark h-100">
      <h4>{i18n.t('title', i18nOpts)}</h4>
      <div className={styles.amountText}>
        {formatCurrency(balance)}
      </div>

      <div className="position-relative mt-auto">
        <div>
          <div className="font-weight-500">{i18n.t('deposits', i18nOpts)}</div>
          <div className="font-weight-600 font-20">{formatCurrency(averageMonthlyRecurringDeposits)}</div>
          <div className={styles.bar} style={{ height: `${depositPercentage}px` }} />
        </div>

        <div className={styles.spentContainer}>
          <div className="font-weight-500">{i18n.t('payments', i18nOpts)}</div>
          <div className="font-weight-600 font-20">{formatCurrency(averageMonthlyRecurringPayments)}</div>
          <div className={classNames(styles.bar, styles.spentBar)} style={{ height: `${paymentPercentage}px` }} />
        </div>
      </div>
    </Card>
  );
};

AverageMonthlyRecurring.propTypes = {};

AverageMonthlyRecurring.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
}))(AverageMonthlyRecurring);
