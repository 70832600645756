import React from 'react';
import i18n from 'i18n-js';
import { Button, Card, Table } from 'reactstrap';

import CopyLink from '../../../../../common/CopyLink';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.buildHomeSetup.quickPossessions.index' };

const LINK_TO_QUICK_POSSESSION_CODE = `<button class="ownly-build-home"
 data-listing-id="OWNLY_LISTING_ID">
  Add Home to Cart
</button>`;

const LINK_TO_SHOW_QUICK_POSSESSIONS_CODE = `<button class="ownly-build-home"
 data-listing-community-id="OWNLY_COMMUNITY_ID"
 data-listing-model-id="OWNLY_MODEL_ID">
  Add Home to Cart
</button>`;

const BUILD_HOME_FOR_NEW_QUICK_POSSESSION_CODE = `<button class="ownly-build-home"
 data-listing-external-id="quickpossession1"
 data-listing-community-id="community1"
 data-listing-community-external-id="my-custom-community1"
 data-listing-community-name="Savanna"
 data-listing-model-id="model1"
 data-listing-model-external-id="my-custom-model1"
 data-listing-model-name="Cascade"
 data-listing-model-floor1-name="Main Floor"
 data-listing-model-floor1-image="/assets/images/floor-101.jpg"
 data-listing-override-floors="true"
 data-listing-address="Test address"
 data-listing-description="Test description"
 data-listing-price="650000"
 data-listing-size="930"
 data-listing-beds="4"
 data-listing-baths="3"
 data-listing-image="/assets/images/model-101.jpg"
 data-listing-lot="bmap-chatterson-746"
 data-listing-lot-external-id="my-custom-lot-id"
 data-listing-agent-email="sales.agent1@gmail.com"
 data-listing-agent-name="Sales Agent1">
  Build Home
</button>`;

const QuickPossessionFields = [
  {
    name: 'External ID', attribute: 'data-listing-external-id', type: 'string', required: 'true'
  },
  {
    name: 'Community ID', attribute: 'data-listing-community-id', type: 'string', required: 'true'
  },
  {
    name: 'Community External ID', attribute: 'data-listing-community-external-id', type: 'string', required: 'false'
  },
  {
    name: 'Community Name', attribute: 'data-listing-community-name', type: 'string', required: 'false'
  },
  {
    name: 'Model ID', attribute: 'data-listing-model-id', type: 'string', required: 'true'
  },
  {
    name: 'Model External ID', attribute: 'data-listing-model-external-id', type: 'string', required: 'false'
  },
  {
    name: 'Model Name', attribute: 'data-listing-model-name', type: 'string', required: 'false'
  },
  {
    name: 'Floor Name', attribute: 'data-listing-model-floor1-name', type: 'string', required: 'false'
  },
  {
    name: 'Floor Image', attribute: 'data-listing-model-floor1-image', type: 'string', required: 'false'
  },
  {
    name: 'Override Floors', attribute: 'data-listing-override-floors', type: 'boolean', required: 'false'
  },
  {
    name: 'Address', attribute: 'data-listing-address', type: 'string', required: 'false'
  },
  {
    name: 'Description', attribute: 'data-listing-description', type: 'string', required: 'false'
  },
  {
    name: 'Price', attribute: 'data-listing-price', type: 'number', required: 'false'
  },
  {
    name: 'Size', attribute: 'data-listing-size', type: 'number', required: 'false'
  },
  {
    name: 'Beds', attribute: 'data-listing-beds', type: 'number', required: 'false'
  },
  {
    name: 'Baths', attribute: 'data-listing-baths', type: 'number', required: 'false'
  },
  {
    name: 'Url', attribute: 'data-listing-url', type: 'url', required: 'false'
  },
  {
    name: 'Image', attribute: 'data-listing-image', type: 'string', required: 'false'
  },
  {
    name: 'Lot Information', attribute: 'data-listing-lot', type: 'string', required: 'false'
  },
  {
    name: 'Lot External ID', attribute: 'data-listing-lot-external-id', type: 'string', required: 'false'
  },
  {
    name: 'Sales Agent ID', attribute: 'data-listing-agent-id', type: 'string', required: 'false'
  },
  {
    name: 'Sales Agent Email', attribute: 'data-listing-agent-email', type: 'string', required: 'false'
  },
  {
    name: 'Sales Agent Name', attribute: 'data-listing-agent-name', type: 'string', required: 'false'
  },
];

const QuickPossessions = () => (
  <div>
    <h2>{i18n.t('buildQuickPossessionHome', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('quickPossessionsDesc', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>

    <Card body className="d-inline-block">
      <pre>
        &lt;button class=&quot;ownly-build-home&quot;
        <br />
        &nbsp;data-listing-id=&quot;OWNLY_LISTING_ID&quot;&gt;
        <br />
        &nbsp;&nbsp;Add Home to Cart
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={LINK_TO_QUICK_POSSESSION_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    <p dangerouslySetInnerHTML={{ __html: i18n.t('showQuickPossessions', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>

    <Card body className="d-inline-block">
      <pre>
        &lt;button class=&quot;ownly-build-home&quot;
        <br />
        &nbsp;data-listing-community-id=&quot;OWNLY_COMMUNITY_ID&quot;&gt;
        <br />
        &nbsp;data-listing-model-id=&quot;OWNLY_MODEL_ID&quot;&gt;
        <br />
        &nbsp;&nbsp;Build Home
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={LINK_TO_SHOW_QUICK_POSSESSIONS_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    <p>{i18n.t('addNewQuickPossession', i18nOpts)}</p>
    <div className="table-responsive">
      <Table bordered>
        <thead>
          <tr>
            <th>{i18n.t('list.name', i18nOpts)}</th>
            <th>{i18n.t('list.attribute', i18nOpts)}</th>
            <th>{i18n.t('list.type', i18nOpts)}</th>
            <th>{i18n.t('list.required', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          { QuickPossessionFields.map((field) => (
            <tr key={field.attribute}>
              <td>{field.name}</td>
              <td><code className="text-nowrap">{field.attribute}</code></td>
              <td><code className="text-nowrap">{field.type}</code></td>
              <td><code>{field.required}</code></td>
            </tr>
          )) }
        </tbody>
      </Table>
    </div>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('addNewModelFloor', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>
    <Card body className="d-block">
      <pre>
        &lt;button class=&quot;ownly-build-home&quot;
        <br />
        &nbsp;data-listing-external-id=&quot;quickpossession1&quot;
        <br />
        &nbsp;data-listing-community-id=&quot;community1&quot;
        <br />
        &nbsp;data-listing-community-external-id=&quot;my-custom-community1&quot;
        <br />
        &nbsp;data-listing-community-name=&quot;Savanna&quot;
        <br />
        &nbsp;data-listing-model-id=&quot;model1&quot;
        <br />
        &nbsp;data-listing-model-external-id=&quot;my-custom-model1&quot;
        <br />
        &nbsp;data-listing-model-name=&quot;Cascade&quot;
        <br />
        &nbsp;data-listing-model-floor1-name=&quot;Main Floor&quot;
        <br />
        &nbsp;data-listing-model-floor1-image=&quot;/assets/images/floor-101.jpg&quot;
        <br />
        &nbsp;data-listing-override-floors=&quot;true&quot;
        <br />
        &nbsp;data-listing-address=&quot;Test address&quot;
        <br />
        &nbsp;data-listing-description=&quot;Test description&quot;
        <br />
        &nbsp;data-listing-price=&quot;650000&quot;
        <br />
        &nbsp;data-listing-size=&quot;930&quot;
        <br />
        &nbsp;data-listing-beds=&quot;4&quot;
        <br />
        &nbsp;data-listing-baths=&quot;3&quot;
        <br />
        &nbsp;data-listing-image=&quot;/assets/images/model-101.jpg&quot;
        <br />
        &nbsp;data-listing-lot=&quot;bmap-chatterson-746&quot;
        <br />
        &nbsp;data-listing-lot-external-id=&quot;my-custom-lot-id&quot;
        <br />
        &nbsp;data-listing-agent-email=&quot;sales.agent1@gmail.com&quot;
        <br />
        &nbsp;data-listing-agent-name=&quot;Sales Agent1&quot;&gt;
        <br />
        &nbsp;&nbsp;Build Home
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={BUILD_HOME_FOR_NEW_QUICK_POSSESSION_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>
  </div>
);

QuickPossessions.propTypes = {};

QuickPossessions.defaultPros = {};

export default QuickPossessions;
