import api from '../../../api';
import { listPriceRulesQuery } from '../../../graphql';

export async function fetchPriceRulesAsync(modelId) {
  if (!modelId) return Promise.resolve([]);

  const variables = { filter: { modelId } };
  return api.graphql(listPriceRulesQuery, variables)
    .then(({ data: { items } }) => items);
}

export async function fetchItemsAsync(query, companyId, filter = null, extraVariables = null) {
  const variables = { filter: { companyId, ...filter }, ...extraVariables };
  return api.graphql(query, variables)
    .then(({ data: { result: { items } } }) => Promise.resolve(items));
}
