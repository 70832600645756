import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';
import Switch from 'rc-switch';
import i18n from 'i18n-js';
import _ from 'lodash';

import StageItem from './StageItem';
import { ContactStages, HomePriceDisplayModes } from '../../../../../../constants';
import { getContactStageName, getHomePriceDisplayModeName } from '../../../../../../utils/enumUtils';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';
import { parseBoolean } from '../../../../../../utils/parserUtils';
import LeadNotifications from './LeadNotifications';
import ExportSettings from './ExportSettings';
import ReportSettings from './ReportSettings';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.advancedSettings.index' };

function getStages(stages) {
  return stages.map((stageKey) => {
    const stage = ContactStages[stageKey];
    return { id: stage, name: getContactStageName(stage) };
  });
}

const AdvancedSettings = ({ form, error, onTextChange }) => {
  const [selectedStages, setSelectedStages] = useState([]);
  const [unselectedStages, setUnselectedStages] = useState([]);

  const onChangeSwitch = (name, checked) => {
    onTextChange({ target: { name, value: checked } });
  };

  const onUpdate = () => {
    const newContactStages = selectedStages.map((selectedStage) => selectedStage.id);
    onTextChange({ target: { name: 'contactStages', value: newContactStages } });
  };

  useEffect(() => {
    const allStages = Object.keys(ContactStages);
    const defaultSelectedStages = form.contactStages || allStages;
    const defaultUnselectedStages = _.without(allStages, ...defaultSelectedStages);

    setSelectedStages(getStages(defaultSelectedStages));
    setUnselectedStages(getStages(defaultUnselectedStages));
  }, []);

  useEffect(() => {
    const isValidDefaultStage = selectedStages.some((stage) => stage.id
      === form.defaultContactStage);
    if (!isValidDefaultStage) onTextChange({ target: { name: 'defaultContactStage', value: null } });
  }, [selectedStages]);

  const isMasterAdminRole = isMasterAdmin();
  const isTecSupportRole = isTecSupport();
  const canViewMasterSettings = isMasterAdminRole || isTecSupportRole;

  return (
    <>
      {canViewMasterSettings && (
        <>
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enableDemoModeForReserveNowContainer">
                <Label sm={5} className="py-0">{i18n.t('demoModeReserveNow', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableDemoModeForReserveNow)}
                    onChange={(checked) => onChangeSwitch('enableDemoModeForReserveNow', checked)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row id="enableDemoModeForVerifiedContainer">
                <Label sm={5} className="py-0">{i18n.t('demoModeVerified', i18nOpts)}</Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableDemoModeForVerified)}
                    onChange={(checked) => onChangeSwitch('enableDemoModeForVerified', checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />
        </>
      )}

      <Row id="contactStagesContainer">
        <Col xs={12}>
          <h4 className="font-weight-500">{i18n.t('contactStages', i18nOpts)}</h4>
          <p>{i18n.t('contactStagesDescription', i18nOpts)}</p>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              {i18n.t('selectedStages', i18nOpts)}
            </CardHeader>
            <CardBody>
              <ReactSortable
                className="sortable-contact-stages"
                group="contact-stages"
                list={selectedStages}
                setList={setSelectedStages}
                onEnd={onUpdate}
              >
                {selectedStages.map((stage) => (
                  <StageItem key={`stage-${stage.id}`} stage={stage} />
                ))}
              </ReactSortable>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card>
            <CardHeader>
              {i18n.t('unselectedStages', i18nOpts)}
            </CardHeader>

            <CardBody>
              <ReactSortable
                className="sortable-contact-stages"
                group="contact-stages"
                list={unselectedStages}
                setList={setUnselectedStages}
              >
                {unselectedStages.map((stage) => (
                  <StageItem key={`stage-${stage.id}`} stage={stage} />
                ))}
              </ReactSortable>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="defaultStageContainer">
            <Label sm={5}>{i18n.t('defaultStage', i18nOpts)}</Label>
            <Col sm={7}>
              <Input
                type="select"
                name="defaultContactStage"
                id="defaultContactStage"
                value={form.defaultContactStage || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'defaultContactStage')}
              >
                <option value="">{i18n.t('select.none')}</option>
                {
                  selectedStages.map((s) => (
                    <option key={`default-stage-${s.id}`} value={s.id}>
                      {s.name}
                    </option>
                  ))
                }
              </Input>
              <InputError name="defaultContactStage" error={error} />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      {isMasterAdminRole && (
        <ExportSettings form={form} error={error} onTextChange={onTextChange} />
      )}
      <LeadNotifications form={form} error={error} onTextChange={onTextChange} />
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="homePriceDisplayModeContainer">
            <Label sm={5}>{i18n.t('homePriceDisplayMode', i18nOpts)}</Label>
            <Col sm={7}>
              <Input
                type="select"
                name="homePriceDisplayMode"
                id="homePriceDisplayMode"
                value={form.homePriceDisplayMode || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'homePriceDisplayMode')}
              >
                {
                  Object.values(HomePriceDisplayModes).map((dm) => (
                    <option key={`home-price-display-mode-option-${dm}`} value={dm}>
                      {getHomePriceDisplayModeName(dm)}
                    </option>
                  ))
                }
              </Input>
              <InputError name="homePriceDisplayMode" error={error} />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      {isMasterAdminRole && (
        <ReportSettings form={form} error={error} onTextChange={onTextChange} />
      )}
    </>
  );
};

AdvancedSettings.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

AdvancedSettings.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default AdvancedSettings;
