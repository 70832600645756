import React from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'reactstrap';
import { Link } from 'gatsby';

import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';
import { ExternalDataSourceProviders } from '../../../../constants';

const i18nOpts = { scope: 'components.admin.externalDataSources.index' };

const ExternalDataSources = () => (
  <div>
    <Helmet title={i18n.t('title', i18nOpts)} />

    <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

    <Row>
      <Col xs="12" sm="9" md="7" lg="6" xl="5">
        <Link to={`/admin/integrations/externalDataSources/${ExternalDataSourceProviders.SALES_SIMPLICITY}`} className="btn btn-lg btn-block btn-outline-secondary py-3 h-auto">
          <span className="display-7 align-middle">{i18n.t('buttons.salesSimplicity', i18nOpts)}</span>
        </Link>

        <br />
        <br />
        <Link to={`/admin/integrations/externalDataSources/${ExternalDataSourceProviders.MARONDA_HOMES}`} className="btn btn-lg btn-block btn-outline-secondary py-3 h-auto">
          <span className="display-7 align-middle">{i18n.t('buttons.marondaHomes', i18nOpts)}</span>
        </Link>
      </Col>
    </Row>
  </div>
);

export default ExternalDataSources;
