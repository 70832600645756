import React from 'react';
import PropTypes from 'prop-types';
import SortIcons from '../SortIcons';

const SortableColumnHeader = ({
  label, onPress, field, sort, extraClass
}) => (
  <th onClick={onPress} role="button" className={extraClass}>
    {label}
    <SortIcons field={field} sort={sort} />
  </th>
);

SortableColumnHeader.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  field: PropTypes.string.isRequired,
  sort: PropTypes.objectOf(PropTypes.any),
  extraClass: PropTypes.string
};

SortableColumnHeader.defaultProps = {
  onPress: () => {},
  sort: {},
  extraClass: ''
};

export default SortableColumnHeader;
