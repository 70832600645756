import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import v from 'voca';

import { Roles } from '../constants';
import api from '../api';
import { listSalesRepsQuery } from '../graphql';

export function fetchSalesRepsAsync(companyId) {
  const variables = {
    filter: {
      companyId,
      includedRoles: [Roles.COMPANY_ADMIN, Roles.COMPANY_USER, Roles.SALES_LEAD, Roles.SALES_USER]
    },
    pageSize: null
  };

  return api.graphql(listSalesRepsQuery, variables)
    .then(({ data: { result: { items } } }) => items);
}

const useFetchSalesReps = (companyId) => {
  const [loading, setLoading] = useState(false);
  const [salesReps, setSalesReps] = useState([]);

  useEffect(() => {
    if (v.isBlank(companyId)) return;
    setLoading(true);

    fetchSalesRepsAsync(companyId)
      .then((items) => setSalesReps(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return [salesReps, loading];
};

export default useFetchSalesReps;
