import React from 'react';
import PropTypes from 'prop-types';
import { getCreatorDisplayName } from '../../../utils/auditUtils';

const CreatedByTableItem = ({ instance }) => {
  const creatorName = getCreatorDisplayName(instance);
  return (
    <td>
      {creatorName}
    </td>
  );
};

CreatedByTableItem.propTypes = {
  instance: PropTypes.objectOf(PropTypes.any).isRequired
};

CreatedByTableItem.defaultProps = {};

export default CreatedByTableItem;
