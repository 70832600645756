import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  deleteCityImageAsync,
  fetchCityImagesAsync,
  saveCityImageAsync,
  sortCityImagesAsync
} from './utils';
import ImagesManager from '../../../../global/ImagesManager';

const Images = ({ city }) => {
  const [cityImages, setCityImages] = useState([]);

  const onDrop = (images) => {
    const promises = images.map((file) => uploadImage(file));
    return Promise.all(promises)
      .finally(() => loadCityImages());
  };

  const uploadImage = (image) => {
    const input = { cityId: city.id };
    const variables = { input, image };
    return saveCityImageAsync(variables);
  };

  const loadCityImages = () => {
    const variables = { filter: { cityId: city.id } };

    fetchCityImagesAsync(variables)
      .then((items) => setCityImages(items))
      .catch(() => setCityImages([]));
  };

  const onSetCityImages = (images) => {
    setCityImages(images);
  };

  const onSortCityImages = () => {
    const ids = cityImages.map((ci) => ci.id);
    const variables = { cityId: city.id, ids };

    sortCityImagesAsync(variables);
  };

  const onDeleteCityImage = (cityImage) => {
    deleteCityImageAsync(cityImage.id)
      .then(() => {
        const filteredImages = cityImages.filter((ci) => ci.id !== cityImage.id);
        setCityImages(filteredImages);
      });
  };

  useEffect(() => {
    loadCityImages();
  }, []);

  return (
    <ImagesManager
      images={cityImages}
      onDrop={onDrop}
      onSortImages={onSortCityImages}
      onSetImages={onSetCityImages}
      onDeleteImage={onDeleteCityImage}
    />
  );
};

export default connect((store) => ({
  city: store.cities.city,
}))(Images);
