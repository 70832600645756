import React, { Component } from 'react';
import { navigate } from '@reach/router';
import { Card, CardBody, CardImg } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import { DEFAULT_IMAGE } from '../../../../../constants';
import { deleteOptionType } from '../../../../../store/actions/optionTypeActions';
import ActionsMenu from '../../../../global/ActionsMenu';
import { isAdmin } from '../../../../../utils/authUtils';

class ListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };

    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onToggleFocused = this.onToggleFocused.bind(this);
  }

  onToggleFocused(focused) {
    this.setState({ focused });
  }

  onDelete(optionTypeId) {
    const { props } = this;

    props.deleteOptionType(optionTypeId);
  }

  onEdit(optionType) {
    navigate(`/admin/optionTypes/form?id=${optionType.id}`);
  }

  render() {
    const { optionType } = this.props;
    const { focused } = this.state;

    if (!optionType) return null;

    return (
      <Card
        onMouseEnter={() => this.onToggleFocused(true)}
        onMouseLeave={() => this.onToggleFocused(false)}
      >
        <CardImg
          top
          width="100%"
          src={optionType.imageUrl || DEFAULT_IMAGE}
          alt=""
          className="mb-0 option-type-image"
        />

        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1">
              <Link to={`/admin/optionTypes/${optionType.id}`}>{optionType.name}</Link>
            </div>
            <div className="d-flex">
              {focused && isAdmin() && (
                <ActionsMenu
                  item={optionType}
                  onEdit={this.onEdit}
                  onDelete={this.onDelete}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default connect((store) => ({}), {
  deleteOptionType
})(ListItem);
