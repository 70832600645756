import React from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import v from 'voca';

const i18nOpts = { scope: 'components.admin.externalDataSources.salesSimplicity.details.index' };

const Details = ({ externalDataSource }) => (
  <Row>
    <Col xs={12}>
      {!v.isBlank(externalDataSource.headers.Username) && (
        <div className="mb-4">
          <div className="label">{i18n.t('username', i18nOpts)}</div>
          <div><strong>{externalDataSource.headers.Username}</strong></div>
        </div>
      )}

      {!v.isBlank(externalDataSource.headers.Password) && (
        <div className="mb-4">
          <div className="label">{i18n.t('password', i18nOpts)}</div>
          <div><strong>{externalDataSource.headers.Password}</strong></div>
        </div>
      )}

      {!v.isBlank(externalDataSource.headers.Token) && (
        <div className="mb-4">
          <div className="label">{i18n.t('token', i18nOpts)}</div>
          <div><strong>{externalDataSource.headers.Token}</strong></div>
        </div>
      )}
    </Col>
  </Row>
);

Details.propTypes = {};

Details.defaultProps = {};

export default connect((store) => ({
  externalDataSource: store.externalDataSources.externalDataSource
}), {})(Details);
