import v from 'voca';

import api from '../../../../api';
import { QuickPossessionStages } from '../../../../constants';
import { listQuickPossessionsQuery } from '../../../../graphql/quickPossessions';

export const Tabs = Object.freeze({
  ACTIVE_LISTINGS: 'activeListings',
  INACTIVE_LISTINGS: 'inactiveListings'
});

export function getQuickPossessionFilters(
  currentCompany, filters, query, showOnlyScheduledToArchive
) {
  const filter = { companyId: currentCompany.id };

  filter.stage = showOnlyScheduledToArchive
    ? QuickPossessionStages.SCHEDULED_TO_ARCHIVE
    : QuickPossessionStages.ACTIVE;

  if (!v.isBlank(query)) filter.query = query;

  if (filters.communities?.length) {
    filter.communityIds = filters.communities.map((community) => community.id);
  }

  if (filters.models?.length) {
    filter.modelIds = filters.models.map((model) => model.id);
  }

  if (filters.productTypes?.length) {
    filter.productTypeIds = filters.productTypes.map((productType) => productType.id);
  }

  if (filters.beds?.length) {
    filter.totalBedsValues = filters.beds;
  }

  if (filters.baths?.length) {
    filter.totalBathsValues = filters.baths;
  }

  return filter;
}

export function fetchQuickPossessionsAsync(variables) {
  return api.graphql(listQuickPossessionsQuery, variables)
    .then(({ data: { result } }) => result);
}
