import React, { useEffect, useState } from 'react';
import {
  Button,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import v from 'voca';

import { setHomeAttributes } from '../../../../store/actions/homeActions';
import { addOrUpdateDeposit } from '../../../../store/actions/depositActions';
import { ModalNames, RefundReasons, RefundStatuses } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import ModalCloseButton from '../../../common/ModalCloseButton';
import DollarIcon from '../../../../assets/icons/DollarIcon';
import { isInputInvalid } from '../../../ui/InputError';
import { InputError } from '../../../ui';
import { getRefundReasonName } from '../../../../utils/enumUtils';
import useForm from '../../../../hooks/useForm';
import { stripToEmpty } from '../../../../utils/stringUtils';
import { createRefundAsync, getDepositAsync, markHomeAsSold } from './utils';
import { getError } from '../../../../utils/requestUtils';
import { getHomeInfo } from '../../../../utils/homeUtils';
import { confirmAlert } from '../../confirmAlert';

const i18nOpts = { scope: 'components.global.deposit.depositRefundFormModal.index' };

function onClose() {
  ModalService.close(ModalNames.DEPOSIT_REFUND_FORM);
}

const DepositRefundFormModal = ({ opened, params, ...props }) => {
  const {
    form, setForm, onTextChange, submitting, setSubmitting, error, setError
  } = useForm();
  const [homeId, setHomeId] = useState(null);
  const [deposit, setDeposit] = useState(null);
  const { depositId, from } = params;

  const onSubmit = () => {
    setSubmitting(true);

    const input = {
      amount: parseFloat(form.amount) || 0,
      reason: stripToEmpty(form.reason),
      customReason: stripToEmpty(form.customReason),
      status: RefundStatuses.PENDING,
      depositId: deposit.id
    };
    const variables = { input };

    createRefundAsync(variables)
      .then((refund) => {
        confirmMarkHomeAsSold(refund.reason);
        reloadDeposit();
        onClose();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSubmitting(false));
  };

  const reloadDeposit = () => {
    getDepositAsync(depositId)
      .then((item) => {
        if (from === 'depositList') props.addOrUpdateDeposit(item);
        else {
          const { home, ...depositWithoutHome } = item;
          props.setHomeAttributes(homeId, { deposit: depositWithoutHome });
        }
      })
      .catch(() => {});
  };

  const loadDeposit = () => {
    getDepositAsync(depositId)
      .then((item) => {
        setDeposit(item);
        setHomeId(item.home.id);
        setForm({ amount: item.creditAllowed });
      })
      .catch(() => {});
  };

  const onMarkHomeAsSold = () => {
    markHomeAsSold(homeId).then();
  };

  const confirmMarkHomeAsSold = (refundReason) => {
    if (refundReason !== RefundReasons.DEAL_COMPLETED) return;

    const { address } = getHomeInfo(deposit.home);

    const options = {
      title: null,
      message: `<div>
        <div class="font-18">${i18n.t('confirmAlert.message', i18nOpts)}</div>
        <div class="mt-2 font-16">${address}</div>
      </div>`,
      closeOnClickOutside: false
    };
    const dialogProps = {
      buttonText: i18n.t('confirmAlert.buttons.markAsSold', i18nOpts)
    };
    confirmAlert(onMarkHomeAsSold, options, dialogProps);
  };

  useEffect(() => {
    if (!opened) return;

    loadDeposit();
  }, [opened]);

  if (!deposit) return null;

  return (
    <Modal isOpen={opened} centered modalClassName="custom-md">
      <ModalHeader className="pb-0 border-bottom-0" close={<ModalCloseButton onClick={onClose} />}>{i18n.t('title', i18nOpts)}</ModalHeader>

      <ModalBody>
        <div className="mb-3">{i18n.t('description', i18nOpts)}</div>

        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label for="amount">{i18n.t('amount', i18nOpts)}</Label>
            <div className="input-icon-prepend" style={{ bottom: 'unset' }}>
              <DollarIcon />
            </div>
            <Input
              type="number"
              name="amount"
              id="amount"
              value={form.amount || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'amount')}
            />
            <InputError name="amount" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="reason">{i18n.t('reason', i18nOpts)}</Label>
            <Input
              type="select"
              name="reason"
              id="reason"
              value={form.reason || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'reason')}
            >
              <option value="">{i18n.t('select.select')}</option>
              {Object.values(RefundReasons).map((reason) => (
                <option value={reason} key={`refund-reason-${reason}`}>
                  {getRefundReasonName(reason)}
                </option>
              ))}
            </Input>
            <InputError name="reason" error={error} />
          </FormGroup>

          <Collapse isOpen={form.reason === RefundReasons.OTHER}>
            <FormGroup>
              <Input
                type="textarea"
                name="customReason"
                id="customReason"
                value={form.customReason || ''}
                onChange={onTextChange}
                rows={3}
                invalid={isInputInvalid(error, 'customReason')}
              />
              <InputError name="customReason" error={error} />
            </FormGroup>
          </Collapse>
        </Form>
      </ModalBody>

      <ModalFooter className="pt-0 border-top-0 gap-3">
        <div className="flex-even m-0">
          <Button block outline color="secondary" onClick={onClose} disabled={submitting}>
            {i18n.t('buttons.cancel')}
          </Button>
        </div>
        <div className="flex-even m-0">
          <Button block color="primary" onClick={onSubmit} disabled={submitting}>
            {submitting && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.continue')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DepositRefundFormModal.propTypes = {};

DepositRefundFormModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.DEPOSIT_REFUND_FORM]?.opened || false,
  params: store.modals[ModalNames.DEPOSIT_REFUND_FORM]?.params || {}
}), {
  addOrUpdateDeposit,
  setHomeAttributes
})(DepositRefundFormModal);
