import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createProjectPackageQuery } from '../../../../../../graphql';
import api from '../../../../../../api';
import { reloadProjectPackages } from '../../../../../../store/actions/projectPackageActions';
import AddItemDropdown from '../../../../../common/AddItemDropdown';

const AddDropdown = ({ availablePackages, project, ...props }) => {
  const [isDropdownOpen, onToggleDropdown] = useState(false);

  const addPackage = (packageId) => {
    const input = {
      projectId: project.id,
      packageId
    };
    api.graphql(createProjectPackageQuery, { input })
      .then(() => props.reloadProjectPackages(true));
  };

  return (
    <AddItemDropdown
      open={isDropdownOpen}
      items={availablePackages}
      toggleDropdown={onToggleDropdown}
      onSelect={addPackage}
    />
  );
};

AddDropdown.propTypes = {
  availablePackages: PropTypes.arrayOf(PropTypes.any)
};

AddDropdown.defaultProps = {
  availablePackages: []
};

export default connect(() => ({}), {
  reloadProjectPackages
})(AddDropdown);
