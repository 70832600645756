import React from 'react';
import {
  DropdownItem
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import BaseActionsMenu from '../../../../../../global/ActionsMenu';
import ModalService from '../../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../../constants';
import {
  deleteBuildingModelPalette,
  saveBuildingModelPalette,
  reloadBuildingModelPalettes
} from '../../../../../../../store/actions/buildingModelPalettesActions';

const ActionsMenu = ({ buildingModelPalette, ...props }) => {
  const onEdit = () => {
    ModalService.open(ModalNames.BUILDING_MODEL_PALETTE_FORM, { buildingModelPalette });
  };

  const onDelete = () => {
    const variables = { id: buildingModelPalette.id };
    props.deleteBuildingModelPalette(variables);
  };

  const onToggleAsDefault = (isDefault) => {
    const input = { id: buildingModelPalette.id, isDefault };
    props.saveBuildingModelPalette({ input })
      .then(() => props.reloadBuildingModelPalettes(true))
      .catch((e) => toast.error(e.message));
  };

  return (
    <BaseActionsMenu item={buildingModelPalette} onEdit={onEdit} onDelete={onDelete}>
      {buildingModelPalette.isDefault ? (
        <DropdownItem onClick={() => onToggleAsDefault(false)}>
          {i18n.t('buttons.setAsNotDefault')}
        </DropdownItem>
      ) : (
        <DropdownItem onClick={() => onToggleAsDefault(true)}>
          {i18n.t('buttons.setAsDefault')}
        </DropdownItem>
      )}
    </BaseActionsMenu>
  );
};

ActionsMenu.propTypes = {
  buildingModelPalette: PropTypes.objectOf(PropTypes.any).isRequired,
};

ActionsMenu.defaultProps = {};

export default connect(() => ({
}), {
  saveBuildingModelPalette,
  deleteBuildingModelPalette,
  reloadBuildingModelPalettes
})(ActionsMenu);
