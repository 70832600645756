import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import './styles.scss';

const ColorPicker = ({ initialColor, onChange }) => {
  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState(initialColor);

  const handleClick = () => setVisible(!visible);

  const handleClose = () => setVisible(false);

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    onChange(newColor);
  };

  return (
    <div>
      <div className="swatch" onClick={handleClick} role="button" aria-hidden>
        <div className="color" style={{ backgroundColor: color }} />
      </div>
      { visible && (
        <div className="popover">
          <div className="cover" onClick={handleClose} role="button" aria-hidden />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) }

    </div>
  );
};
export default ColorPicker;
