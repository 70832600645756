import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import ListItem from './ListItem';
import ListHeader from './ListHeader';

const InterestList = ({ interests }) => (
  <Table bordered responsive className="mb-0 table-borderless-in-card">
    <thead>
      <ListHeader />
    </thead>
    <tbody>
      {
        interests.map((interest) => (
          <ListItem key={interest.localId} interest={interest} />
        ))
      }
    </tbody>
  </Table>
);

InterestList.propTypes = {
  interests: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

InterestList.defaultProps = {
  interests: []
};

export default InterestList;
