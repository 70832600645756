import api from '../../../../api';
import { createRefundQuery } from '../../../../graphql/refunds';
import { getDepositQuery, markHomeAsSoldQuery } from '../../../../graphql';

export function createRefundAsync(variables = {}) {
  return api.graphql(createRefundQuery, variables)
    .then(({ data: { item } }) => item);
}

export function markHomeAsSold(homeId) {
  return api.graphql(markHomeAsSoldQuery, { id: homeId })
    .then(({ data: { sold } }) => sold);
}

export function getDepositAsync(id) {
  return api.graphql(getDepositQuery, { id, withHome: true })
    .then(({ data: { item } }) => item);
}
