import {
  GET_MODEL_IMAGES, SAVE_MODEL_IMAGE, DELETE_MODEL_IMAGE, SET_MODEL_IMAGES, SORT_MODEL_IMAGES
} from './types';
import {
  createModelImageQuery, deleteModelImageQuery, listModelImagesQuery, sortModelImagesQuery,
  updateModelImageQuery
} from '../../graphql';
import api from '../../api';

export function getModelImages(variables = {}) {
  return {
    type: GET_MODEL_IMAGES,
    payload: api.graphql(listModelImagesQuery, variables)
  };
}

export function saveModelImage(variables = {}) {
  const query = variables.input.id ? updateModelImageQuery : createModelImageQuery;
  return {
    type: SAVE_MODEL_IMAGE,
    payload: api.graphql(query, variables)
  };
}

export function deleteModelImage(id) {
  return {
    type: DELETE_MODEL_IMAGE,
    payload: api.graphql(deleteModelImageQuery, { id })
  };
}

export function setModelImages(modelImages) {
  return {
    type: SET_MODEL_IMAGES,
    payload: { modelImages }
  };
}

export function sortModelImages(variables = {}) {
  return {
    type: SORT_MODEL_IMAGES,
    payload: api.graphql(sortModelImagesQuery, variables)
  };
}
