import React, { Component } from 'react';
import {
  Button,
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { HiShare } from 'react-icons/hi';

import { isAdmin } from '../../../../utils/authUtils';
import Overview from './Overview';
import { getProject } from '../../../../store/actions/projectActions';
import Palettes from './Palettes';
import Packages from './Packages';
import SalesRepresentatives from './SalesRepresentatives';
import { ModalNames, NavigationMainNames, Statuses } from '../../../../constants';
import { hasCompanyDomain, isDocuSignConnected, isPreApprovalEnabled } from '../../../../utils/companyUtils';
import ModalService from '../../../../services/ModalService';
import DirectLinkModal from '../../../global/DirectLinkModal';
import QRCodeModal from '../../../global/QRCodeModal';
import Modifications from '../../../global/Modifications';
import NavigationService from '../../../../services/NavigationService';
import NavigationBreadcrumbs from '../../../global/NavigationBreadcrumbs';
import Loading from '../../../ui/Loading';
import MortgageProviders from './MortgageProviders';
import ReservationDocument from './ReservationDocument';

const i18nOpts = { scope: 'components.admin.projects.show.index' };

const Tabs = Object.freeze({
  OVERVIEW: 'overview',
  PALETTES: 'palettes',
  PACKAGES: 'packages',
  SALES_REPRESENTATIVES: 'salesRepresentatives',
  MODIFICATIONS: 'modifications',
  MORTGAGE_PROVIDERS: 'mortgageProviders',
  RESERVATION_DOCUMENT: 'reservationDocument'
});

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      activeTab: Tabs.OVERVIEW
    };

    this.onChangeTab = this.onChangeTab.bind(this);
    this.onOpenDirectLinkModal = this.onOpenDirectLinkModal.bind(this);
  }

  componentDidMount() {
    const { id } = this.props;
    const parsedId = parseInt(id, 10);
    this.setState({ id: parsedId }, this.loadProject);
  }

  onChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  onOpenDirectLinkModal() {
    const { project } = this.props;
    const { hashId } = project;

    const params = {
      path: `/homes/build/projects/${hashId}`,
      label: project.name
    };
    ModalService.open(ModalNames.DIRECT_LINK_MODAL, params);
  }

  loadProject() {
    const { props } = this;
    const { id } = this.state;

    if (!id) return;

    props.getProject(id)
      .then(() => {
        const { project } = this.props;
        NavigationService.addItem(project, NavigationMainNames.PROJECTS);
      })
      .catch(() => navigate('/admin/projects'));
  }

  render() {
    const { activeTab } = this.state;
    const { project, loading, currentCompany } = this.props;

    if (loading || !project) return <Loading loading fullScreen />;

    return (
      <div>
        <Helmet title={project.name} />

        <NavigationBreadcrumbs />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{project.name}</h2>
          </div>
          <div>

            {isAdmin() && (
              <>
                {project.status === Statuses.PUBLISHED && hasCompanyDomain(currentCompany) && (
                  <Button color="secondary" className="mr-3" onClick={this.onOpenDirectLinkModal}>
                    <HiShare size="1rem" className="col-light mr-2" />
                    <span>{i18n.t('buttons.shareLink')}</span>
                  </Button>
                )}

                <Link to={`/admin/projects/form?id=${project.id}`} className="btn btn-primary">
                  <i className="far fa-edit mr-2" />
                  {i18n.t('buttons.edit')}
                </Link>
              </>
            )}
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.OVERVIEW })}
              onClick={() => this.onChangeTab(Tabs.OVERVIEW)}
            >
              {i18n.t('tabs.overview', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.PACKAGES })}
              onClick={() => this.onChangeTab(Tabs.PACKAGES)}
            >
              {i18n.t('tabs.packages', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.PALETTES })}
              onClick={() => this.onChangeTab(Tabs.PALETTES)}
            >
              {i18n.t('tabs.palettes', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.SALES_REPRESENTATIVES })}
              onClick={() => this.onChangeTab(Tabs.SALES_REPRESENTATIVES)}
            >
              {i18n.t('tabs.salesRepresentatives', i18nOpts)}
            </NavLink>
          </NavItem>
          {isPreApprovalEnabled() && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === Tabs.MORTGAGE_PROVIDERS })}
                onClick={() => this.onChangeTab(Tabs.MORTGAGE_PROVIDERS)}
              >
                {i18n.t('tabs.mortgageProviders', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
          {isDocuSignConnected() && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === Tabs.RESERVATION_DOCUMENT })}
                onClick={() => this.onChangeTab(Tabs.RESERVATION_DOCUMENT)}
              >
                {i18n.t('tabs.reservationDocument', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
          {isAdmin() && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === Tabs.MODIFICATIONS })}
                onClick={() => this.onChangeTab(Tabs.MODIFICATIONS)}
              >
                {i18n.t('tabs.modifications', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab} className="py-4">
          <TabPane tabId={Tabs.OVERVIEW}>
            <Overview />
          </TabPane>
          <TabPane tabId={Tabs.PALETTES}>
            <Palettes />
          </TabPane>
          <TabPane tabId={Tabs.PACKAGES}>
            <Packages />
          </TabPane>
          <TabPane tabId={Tabs.SALES_REPRESENTATIVES}>
            <SalesRepresentatives />
          </TabPane>
          {isPreApprovalEnabled() && (
            <TabPane tabId={Tabs.MORTGAGE_PROVIDERS}>
              <MortgageProviders />
            </TabPane>
          )}
          {isDocuSignConnected() && (
            <TabPane tabId={Tabs.RESERVATION_DOCUMENT}>
              <ReservationDocument />
            </TabPane>
          )}
          {isAdmin() && (
            <TabPane tabId={Tabs.MODIFICATIONS}>
              <Modifications instance={project} />
            </TabPane>
          )}
        </TabContent>

        <DirectLinkModal />
        <QRCodeModal />
      </div>
    );
  }
}

export default connect((store) => ({
  project: store.projects.project,
  currentCompany: store.companies.currentCompany,
  loading: store.projects.getProject.loading
}), {
  getProject
})(Show);
