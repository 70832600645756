import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from '../api';

const listUsersQuery = `
  query ListUsers(
    $filter: UserFilterInput
    $sort: SortInput
  ) {
    result: listUsers(filter: $filter, pageSize: null, sort: $sort) {
      items {
        id
        name
      }
    }
  }
`;

export function fetchCompanyUsersAsync(companyId) {
  const variables = {
    filter: {
      companyId
    },
  };

  return api.graphql(listUsersQuery, variables)
    .then(({ data: { result: { items } } }) => items);
}

const useFetchCompanyUsers = (companyId) => {
  const [loading, setLoading] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);

  useEffect(() => {
    setLoading(true);

    fetchCompanyUsersAsync(companyId)
      .then((items) => setCompanyUsers(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { companyUsers, loading };
};

export default useFetchCompanyUsers;
