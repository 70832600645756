import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './CollapseButton.module.scss';
import CollapseIcon from '../../../assets/icons/CollapseIcon';

const CollapseButton = ({ opened, size, orientation }) => (
  <div className={styles.container}>
    <div className={classNames(styles.icon, { [styles.opened]: opened }, styles[orientation])}>
      <CollapseIcon width={size} height={size} />
    </div>
  </div>
);

CollapseButton.propTypes = {
  opened: PropTypes.bool.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orientation: PropTypes.oneOf(['topToBottom', 'rightToBottom'])
};

CollapseButton.defaultProps = {
  size: 36,
  orientation: 'topToBottom'
};

export default CollapseButton;
