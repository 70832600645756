import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import TimeLineElement from '../../../../common/TimeLineElement';
import useHomeRental from '../../../../../hooks/useHomeRental';
import AffordabilityIcon
  from '../../../../../assets/icons/customer-activities/AffordabilityIcon';
import ActivityDate from '../components/ActivityDate';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.affordability.index' };

const Affordability = ({ activity, innerKey }) => {
  const { typeI18nKey } = useHomeRental();

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<AffordabilityIcon />}
    >
      <div className="d-flex align-items-start gap-3">
        <div className="activity-timeline-title">{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</div>
        <ActivityDate date={activity.createdAt} />
      </div>
      <div>{i18n.t(`${typeI18nKey}.description`, i18nOpts)}</div>
    </TimeLineElement>
  );
};

Affordability.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

export default Affordability;
