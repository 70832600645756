import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './SegmentedControl.module.scss';

const SegmentedControl = ({
  selectedValue, items, onChange
}) => {
  const onClick = (value, index) => {
    onChange(value, index);
  };

  return (
    <div className={styles.container}>
      {
        items.map((item, index) => (
          <button
            key={`segmented-item-${item.value}`}
            type="button"
            className={classNames(styles.item, {
              [styles.activeItem]: selectedValue === item.value
            })}
            onClick={() => onClick(item.value, index)}
          >
            {item.label}
          </button>
        ))
      }
    </div>
  );
};
SegmentedControl.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string
  })),
  onChange: PropTypes.func
};

SegmentedControl.defaultProps = {
  items: [],
  onChange: () => {}
};

export default SegmentedControl;
