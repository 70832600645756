import { ReserveStepTypes, WorkflowTypes } from '../../../../../../../../../constants';
import api from '../../../../../../../../../api';
import { validateCompanyWorkflowMutation } from '../../../../../../../../../graphql/companyWorkflows';

export function downloadConfigFile(workflowType, downloadableFileUrl) {
  const filename = getWorkflowFilename(workflowType, false);

  const element = document.createElement('a');
  element.href = downloadableFileUrl;
  element.download = filename;
  document.body.appendChild(element);
  element.click();
}

function sanitizeVerifiedWorkflowToDownload(workflow) {
  return workflow
    .filter((workflowStep) => workflowStep.enabled)
    .map(({ type, optional }) => ({ type, optional }));
}

function sanitizeReserveNowWorkflowToDownload(workflow) {
  return workflow
    .filter((workflowStep) => workflowStep.enabled)
    .map((workflowStep) => {
      const result = { type: workflowStep.type };

      // eslint-disable-next-line max-len
      if ([ReserveStepTypes.OFFER, ReserveStepTypes.SIGN, ReserveStepTypes.DEPOSIT].includes(workflowStep.type)) {
        result.label = workflowStep.label;
      }

      if ([ReserveStepTypes.SIGN, ReserveStepTypes.DEPOSIT].includes(workflowStep.type)) {
        result.autocomplete = workflowStep.autocomplete;
      }

      // eslint-disable-next-line max-len
      if (workflowStep.type === ReserveStepTypes.OFFER && Array.isArray(workflowStep.verifications)) {
        result.verifications = workflowStep.verifications
          .filter((verification) => verification.enabled)
          .map((verification) => ({ type: verification.type, optional: verification.optional }));
      }

      return result;
    });
}

export function sanitizeWorkflowToDownload(workflowType, workflow) {
  switch (workflowType) {
    case WorkflowTypes.BUYER_VERIFIED:
    case WorkflowTypes.RENTAL_VERIFIED:
      return sanitizeVerifiedWorkflowToDownload(workflow);
    case WorkflowTypes.BUYER_RESERVE:
    case WorkflowTypes.RENTAL_RESERVE:
      return sanitizeReserveNowWorkflowToDownload(workflow);
    default:
      return '';
  }
}

export function getWorkflowFilename(workflowType, isSample) {
  const fileSuffix = isSample ? '-sample' : '';

  switch (workflowType) {
    case WorkflowTypes.BUYER_RESERVE:
      return `buyer-reserve-workflow${fileSuffix}.json`;
    case WorkflowTypes.BUYER_VERIFIED:
      return `buyer-verified-workflow${fileSuffix}.json`;
    case WorkflowTypes.RENTAL_RESERVE:
      return `rental-reserve-workflow${fileSuffix}.json`;
    case WorkflowTypes.RENTAL_VERIFIED:
      return `rental-verified-workflow${fileSuffix}.json`;
    default:
      return '';
  }
}

export function validateCompanyWorkflowAsync(variables = {}) {
  return api.graphql(validateCompanyWorkflowMutation, variables)
    .then(({ data: { workflow } }) => workflow);
}
