import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getOfferFilters } from './utils';
import List from './List';
import { getOffers } from '../../../../../store/actions/offerActions';
import { cleanHomes, listHomes, setReloadHomes } from '../../../../../store/actions/homeActions';
import FiltersButton from './FiltersButton';
import SearchBar from './SearchBar';
import FiltersModal from './FiltersModal';
import AssignedToFilter from './AssignedToFilter';
import {
  isAdmin,
} from '../../../../../utils/authUtils';
import Loading from '../../../../ui/Loading';

const ActiveOffers = ({
  homes, loading, pagination, reloadHomes, currentCompany, filters, title, contractsOnly, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState(null);

  const onChangePage = (page) => {
    loadHomes(page);
  };

  const onSearch = (e, q) => {
    e.preventDefault();

    setQuery(q);
  };

  const loadHomes = (page = 1) => {
    const filter = getOfferFilters(currentCompany, filters, query, contractsOnly);
    const variables = {
      filter,
      page,
      withContact: true,
      withPreApproval: true,
      companyId: currentCompany.id,
      withParty: true,
      withAffordability: true
    };

    props.listHomes(variables)
      .finally(() => {
        setMounted(true);
        props.setReloadHomes(false);
      });
  };

  useEffect(() => {
    props.cleanHomes()
      .then(() => loadHomes());
  }, []);

  useEffect(() => {
    if (!mounted) return;

    loadHomes();
  }, [filters, query]);

  useEffect(() => {
    if (!reloadHomes) return;

    loadHomes();
  }, [reloadHomes]);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <div className="d-flex gap-3 align-items-center flex-wrap justify-content-end mb-4">
        <div>
          <h2 className="mb-0">{title}</h2>
        </div>

        {isAdmin() && (
          <AssignedToFilter />
        )}

        <div className="mr-auto" />

        <SearchBar onSearch={onSearch} />
        <FiltersButton />
      </div>

      <List
        homes={homes}
        loading={loading}
        pagination={pagination}
        onChangePage={onChangePage}
      />

      <FiltersModal />
    </div>
  );
};

ActiveOffers.propTypes = {
  title: PropTypes.string.isRequired,
  contractsOnly: PropTypes.bool
};

ActiveOffers.defaultProps = {
  contractsOnly: false
};

export default connect((store) => ({
  homes: store.homes.homes,
  loading: store.homes.listHomes.loading,
  pagination: store.homes.pagination,
  reloadHomes: store.homes.reloadHomes,
  currentCompany: store.companies.currentCompany,
  filters: store.offers.filters
}), {
  getOffers,
  listHomes,
  cleanHomes,
  setReloadHomes
})(ActiveOffers);
