import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import v from 'voca';

import styles from './DealInfo.module.scss';
import { formatCurrency, isValidAmount } from '../../../../../../utils/currencyUtils';
import { isNotEnoughFinancing } from '../../../../../../utils/offerUtils';
import { getOfferPriceColor, getOfferPriceLabel } from './utils';
import { DepositStatuses, OfferStatuses } from '../../../../../../constants';
import DealStatusBadge from '../../../DealStatusBadge';
import DealActivityDate from '../../../DealActivityDate';
import OpenWindowIcon from '../../../../../../assets/icons/OpenWindowIcon';
import { getHomeInfo } from '../../../../../../utils/homeUtils';
import SalesRepDropdown from './SalesRepDropdown';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.dealInfo.index' };

const DealInfo = ({ home }) => {
  const { totalPrice, offer, deposit } = home;
  const hasHomePrice = isValidAmount(totalPrice);
  const isFinancingAccepted = !isNotEnoughFinancing(home, offer);

  const offerPrice = offer.status === OfferStatuses.COUNTERED ? offer.counterOfferPrice
    : offer.offerPrice;
  const offerPriceFormatted = formatCurrency(offerPrice);
  const showSalePrice = hasHomePrice;
  const offerPriceLabel = getOfferPriceLabel(offer);
  const offerPriceColor = getOfferPriceColor(offer);
  const dealPriceClassName = v.isBlank(offerPriceColor) ? '' : styles[`dealPrice${offerPriceColor}`];
  const { homeUrl } = home;
  const { name, communityProjectName } = getHomeInfo(home);
  const depositAmount = deposit?.status === DepositStatuses.REJECTED ? null : deposit?.amount;

  return (
    <div className="d-flex flex-column gap-3">
      <div>
        <div className="col-gray-500 font-weight-500">{i18n.t('status', i18nOpts)}</div>
        <div className="d-flex flex-column gap-1">
          <DealStatusBadge home={home} />
          <DealActivityDate home={home} className="col-gray-500 small font-weight-500" />
        </div>
      </div>

      <div>
        <div className="col-gray-500 font-weight-500">{i18n.t('assignedTo', i18nOpts)}</div>
        <div className="d-flex flex-column gap-1">
          <SalesRepDropdown />
        </div>
      </div>

      <div>
        <div className="col-gray-500 font-weight-500">{i18n.t('model', i18nOpts)}</div>
        <div>
          <a href={homeUrl} target="_blank" rel="noreferrer" className="d-inline-flex align-items-center gap-2 col-gray-600">
            <span className="font-weight-600 font-16">{name}</span>
            <OpenWindowIcon />
          </a>
        </div>
      </div>

      <div>
        <div className="col-gray-500 font-weight-500">{i18n.t('communityProject', i18nOpts)}</div>
        <div className="font-weight-600 font-16">{communityProjectName}</div>
      </div>

      {showSalePrice && (
        <div>
          <div className="col-gray-500 font-weight-500">{i18n.t('sellingPrice', i18nOpts)}</div>
          <div className={classNames('font-weight-600 font-16', { 'text-danger': isFinancingAccepted })}>
            {formatCurrency(totalPrice)}
          </div>
        </div>
      )}

      <div>
        <div className="col-gray-500 font-weight-500">{offerPriceLabel}</div>
        <div className={classNames('font-weight-600 font-16', dealPriceClassName)}>{offerPriceFormatted}</div>
      </div>

      {isValidAmount(depositAmount) && (
        <div>
          <div className="col-gray-500 font-weight-500">{i18n.t('deposit', i18nOpts)}</div>
          <div className="font-weight-600 font-16">
            {formatCurrency(depositAmount)}
          </div>
        </div>
      )}
    </div>
  );
};

DealInfo.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired
};

DealInfo.defaultProps = {};

export default DealInfo;
