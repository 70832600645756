import { navigate } from 'gatsby';

import api from '../../../../api';

const listProjectsQuery = `
  query ListProjects($filter: ProjectFilterInput) {
    result: listProjects(filter: $filter) {
      items {
        id
        name
      }
    }
  }
`;

export function fetchProjectsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(listProjectsQuery, variables)
    .then(({ data: { result: { items } } }) => Promise.resolve(items));
}

export function goToBuildingModels() {
  navigate('/admin/buildingModels');
}
