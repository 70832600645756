import { navigate } from '@reach/router';

import api from '../../../../api';
import {
  createProjectBuildingQuery,
  getProjectBuildingQuery,
  updateProjectBuildingQuery
} from '../../../../graphql';

const listProjectsQuery = `
  query ListProjects($filter: ProjectFilterInput) {
    result: listProjects(filter: $filter) {
      items {
        id
        name
      }
    }
  }
`;

export function fetchProjectBuildingAsync(id) {
  const variables = { id };

  return api.graphql(getProjectBuildingQuery, variables)
    .then(({ data: { item } }) => Promise.resolve(item));
}

export function fetchProjectsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(listProjectsQuery, variables)
    .then(({ data: { result: { items } } }) => Promise.resolve(items));
}

export function saveProjectBuildingAsync(variables) {
  const query = variables?.input.id ? updateProjectBuildingQuery : createProjectBuildingQuery;
  return api.graphql(query, variables);
}

export function goToProjectBuildings() {
  navigate('/admin/projectBuildings');
}
