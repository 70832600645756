import i18n from 'i18n-js';

import { confirmAlert } from '../../global/confirmAlert';

const i18nOpts = { scope: 'components.common.confirmClone.index' };

function confirmClone(title, message, onConfirm) {
  const extraOptions = {
    title,
    message
  };
  const dialogProps = {
    buttonText: i18n.t('clone', i18nOpts)
  };

  confirmAlert(onConfirm, extraOptions, dialogProps);
}

export default confirmClone;
