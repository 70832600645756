import {
  ADD_PRICE_RULES, REMOVE_PRICE_RULE, CLEAN_PRICE_RULES
} from './types';

export function cleanPriceRules() {
  return {
    type: CLEAN_PRICE_RULES,
    payload: Promise.resolve({})
  };
}

export function addPriceRules(priceRules) {
  return {
    type: ADD_PRICE_RULES,
    payload: { priceRules }
  };
}

export function removePriceRule(priceRule) {
  return {
    type: REMOVE_PRICE_RULE,
    payload: { priceRule }
  };
}
