import React from 'react';
import PropTypes from 'prop-types';

import { CustomerDocumentTypes } from '../../utils';
import PreApproval from './PreApproval';
import OnfidoDocument from './OnfidoDocument';
import { IdVerificationProviders } from '../../../../../../../../constants';
import PlaidDocument from './PlaidDocument';

const DownloadButton = ({ document }) => {
  const { documentType, idVerificationSource } = document;

  switch (documentType) {
    case CustomerDocumentTypes.PRE_APPROVAL:
      return <PreApproval document={document} />;

    case CustomerDocumentTypes.DOCUMENT_SELFIE:
    case CustomerDocumentTypes.DOCUMENT_FRONT_PHOTO:
    case CustomerDocumentTypes.DOCUMENT_BACK_PHOTO:
    case CustomerDocumentTypes.DOCUMENT_REPORT:
      if (idVerificationSource === IdVerificationProviders.PLAID) {
        return <PlaidDocument document={document} />;
      }
      if (idVerificationSource === IdVerificationProviders.ONFIDO) {
        return <OnfidoDocument document={document} />;
      }
      return null;

    default:
      return null;
  }
};

DownloadButton.propTypes = {
  document: PropTypes.objectOf(PropTypes.any).isRequired
};

DownloadButton.defaultProps = {};

export default DownloadButton;
