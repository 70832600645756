import React from 'react';
import i18n from 'i18n-js';
import { Button, Card } from 'reactstrap';
import { connect } from 'react-redux';

import CopyLink from '../../../../../common/CopyLink';
import SendTrackingCodeModal from './SendTrackingCodeModal';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';

const i18nOpts = { scope: 'components.admin.integrations.tracking.trackingCode.installation.index' };

const Installation = ({ currentCompany }) => {
  const appEnv = process.env.GATSBY_APP_ENV || 'local';
  const isProd = appEnv === 'prod';
  const domain = `https://js${isProd ? '' : `-${appEnv}`}.ownly.store`;
  const path = `/${currentCompany.hashId}.js`;
  const scriptUrl = new URL(path, domain).toString();

  const onSendInstallationCode = () => {
    ModalService.open(ModalNames.SEND_TRACKING_CODE);
  };

  const scriptContent = `<script src="${scriptUrl}"></script>`;

  return (
    <div>
      <h2>{i18n.t('installation', i18nOpts)}</h2>
      <p dangerouslySetInnerHTML={{ __html: i18n.t('installationDesc', i18nOpts) }} />

      <Card body className="d-block code-block-container">
        <pre>
          &lt;script src=&quot;
          {scriptUrl}
          &quot;&gt;&lt;/script&gt;
        </pre>

        <div className="actions-container">
          <CopyLink text={scriptContent}>
            <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
          </CopyLink>
        </div>
      </Card>

      <Button color="secondary" onClick={onSendInstallationCode} size="sm">
        {i18n.t('buttons.emailInstallationCode', i18nOpts)}
      </Button>

      <SendTrackingCodeModal />
    </div>
  );
};

Installation.propTypes = {};

Installation.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(Installation);
