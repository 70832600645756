import React, { useEffect, useState } from 'react';
import {
  FormGroup, Input, Label, Col
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import v from 'voca';
import classNames from 'classnames';

import { FloorableTypes, FloorPlanProviders } from '../../../../../constants';
import FloorsManager from '../../../../global/FloorsManager';
import Focus360Settings from './Focus360Settings';
import { saveModel } from '../../../../../store/actions/modelActions';

const i18nOpts = { scope: 'components.admin.models.show.floors.index' };

const Floors = ({ model, ...props }) => {
  const [floorPlanProvider, setFloorPlanProvider] = useState(model.floorPlanProvider);

  const onToggle = (event) => {
    const { value } = event.target;
    setFloorPlanProvider(value);
  };

  const saveFloorPlanProvider = () => {
    const input = { id: model.id, floorPlanProvider };
    props.saveModel({ input })
      .catch((e) => toast.error(e.message));
  };

  useEffect(() => {
    if (model.floorPlanProvider === floorPlanProvider) return;

    saveFloorPlanProvider();
  }, [floorPlanProvider]);

  return (
    <div>
      <FormGroup row>
        <Label for="floorPlanProvider" className="col-form-label" style={{ paddingLeft: '15px' }}>
          {i18n.t('floorPlanProvider', i18nOpts)}
        </Label>

        <Col>
          <Input
            type="select"
            name="floorPlanProvider"
            id="floorPlanProvider"
            value={floorPlanProvider}
            onChange={onToggle}
            className="w-auto"
          >
            {
              Object.values(FloorPlanProviders).map((fpp) => (
                <option key={`floor-plan-provider-${fpp}`} value={fpp}>
                  {i18n.t(`floorPlanProviders.${v.camelCase(fpp)}`)}
                </option>
              ))
            }
          </Input>
        </Col>
      </FormGroup>

      <div className={classNames({ 'd-none': floorPlanProvider !== FloorPlanProviders.FOCUS_360 })}>
        <Focus360Settings />
      </div>

      <div className={classNames({ 'd-none': floorPlanProvider !== FloorPlanProviders.OWNLY })}>
        <FloorsManager floorableId={model.id} floorableType={FloorableTypes.MODEL} />
      </div>
    </div>
  );
};

Floors.propTypes = {};

Floors.defaultProps = {};

export default connect((store) => ({
  model: store.models.model,
}), {
  saveModel
})(Floors);
