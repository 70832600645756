import React from 'react';
import PropTypes from 'prop-types';

import { CustomerColumns } from '../../../utils';
import HomeValuation from './HomeValuation';
import Interests from '../../../Interests';
import Phone from './Phone';
import Region from './Region';
import AssignedTo from './AssignedTo';
import PreApproval from './PreApproval';
import Income from './Income';
import DealStatusBadge from '../../../../../../global/home/DealStatusBadge';

const CustomColumnValue = ({ column, customer }) => {
  switch (column) {
    case CustomerColumns.STATUS:
      return <DealStatusBadge home={customer.activeHome} />;
    case CustomerColumns.INCOME:
      return <Income customer={customer} />;
    case CustomerColumns.HOME_VALUATION:
      return <HomeValuation customer={customer} />;
    case CustomerColumns.INTERESTS:
      return <Interests customer={customer} />;
    case CustomerColumns.PHONE:
      return <Phone customer={customer} />;
    case CustomerColumns.REGION:
      return <Region customer={customer} />;
    case CustomerColumns.ASSIGNED_TO:
      return <AssignedTo customer={customer} />;
    case CustomerColumns.PRE_APPROVAL:
      return <PreApproval customer={customer} />;
    default:
      return null;
  }
};

CustomColumnValue.propTypes = {
  column: PropTypes.oneOf(Object.values(CustomerColumns)).isRequired,
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomColumnValue.defaultProps = {};

export default CustomColumnValue;
