import { SET_LEVEL_TWO_SUB_MENU, SET_SUB_MENU } from './types';

export function setSubMenu(subMenu) {
  return {
    type: SET_SUB_MENU,
    payload: { subMenu }
  };
}

export function setLevelTwoSubMenu(levelTwoSubMenu) {
  return {
    type: SET_LEVEL_TWO_SUB_MENU,
    payload: { levelTwoSubMenu }
  };
}
