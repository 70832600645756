import React, { useEffect, useState } from 'react';
import {
  ModalHeader,
  Modal,
  ModalBody,
  Label,
  Input,
  FormGroup,
  Button,
  Spinner, ModalFooter
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { ModalNames } from '../../../../../../../constants';
import InputError, { isInputInvalid } from '../../../../../../ui/InputError';
import ModalService from '../../../../../../../services/ModalService';
import api from '../../../../../../../api';
import { sendTrackingCodeEmailMutation } from '../../../../../../../graphql';
import { hasFormValues } from '../../../../../../../utils/formUtils';
import { getError } from '../../../../../../../utils/requestUtils';

const i18nOpts = { scope: 'components.admin.integrations.tracking.trackingCode.installation.sendTrackingCodeModal.index' };

function closeModal() {
  ModalService.close(ModalNames.SEND_TRACKING_CODE);
}

const SendTrackingCodeModal = ({ opened, currentCompany }) => {
  const [sending, setSending] = useState(false);
  const [form, setForm] = useState({ email: '' });
  const [error, setError] = useState(null);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSend = () => {
    setSending(true);

    const variables = {
      id: currentCompany.id,
      email: form.email
    };

    api.graphql(sendTrackingCodeEmailMutation, variables)
      .then(() => closeModal())
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSending(false));
  };

  const onCancel = () => {
    closeModal();
  };

  const init = () => {
    setForm({ email: '' });
    setError(null);
    setSending(false);
  };

  useEffect(() => {
    if (!opened) return;

    init();
  }, [opened]);

  const hasValues = hasFormValues(form);

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{i18n.t('title', i18nOpts)}</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label for="email">{i18n.t('email', i18nOpts)}</Label>
          <Input
            type="text"
            name="email"
            id="email"
            value={form.email || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'email')}
          />
          <InputError error={error} name="email" />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSend} disabled={sending || !hasValues}>
          {sending && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.sendEmail', i18nOpts)}
        </Button>
        <Button outline color="secondary" onClick={onCancel} disabled={sending}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SendTrackingCodeModal.propTypes = {};

SendTrackingCodeModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.SEND_TRACKING_CODE]?.opened || false,
  currentCompany: store.companies.currentCompany
}), {})(SendTrackingCodeModal);
