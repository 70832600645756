import React from 'react';

const WebhookIcon = ({ ...props }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" color="black" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M24 26C24.5505 26.0022 25.091 25.8529 25.5623 25.5684C26.0336 25.2839 26.4175 24.8753 26.6721 24.3871C26.9266 23.899 27.0419 23.3503 27.0054 22.801C26.9689 22.2517 26.7819 21.723 26.465 21.2729C26.148 20.8228 25.7134 20.4685 25.2085 20.249C24.7037 20.0294 24.1482 19.953 23.6029 20.0281C23.0575 20.1031 22.5433 20.3268 22.1166 20.6746C21.6899 21.0225 21.3671 21.481 21.1836 22H13V23C13 23.9889 12.7068 24.9556 12.1573 25.7779C11.6079 26.6001 10.827 27.241 9.91342 27.6194C8.99979 27.9978 7.99445 28.0969 7.02455 27.9039C6.05464 27.711 5.16373 27.2348 4.46447 26.5355C3.7652 25.8363 3.289 24.9454 3.09607 23.9755C2.90315 23.0055 3.00216 22.0002 3.3806 21.0866C3.75904 20.173 4.3999 19.3921 5.22215 18.8427C6.04439 18.2932 7.01109 18 8 18V16C6.65825 16.0001 5.3448 16.3858 4.21605 17.1112C3.08729 17.8366 2.1908 18.8711 1.63332 20.0916C1.07583 21.312 0.880849 22.667 1.07159 23.9951C1.26232 25.3232 1.83074 26.5685 2.70916 27.5828C3.58757 28.597 4.73897 29.3374 6.02625 29.7158C7.31352 30.0942 8.68244 30.0947 9.96998 29.7172C11.2575 29.3397 12.4095 28.6001 13.2886 27.5865C14.1677 26.5729 14.737 25.328 14.9287 24H21.1836C21.3886 24.5837 21.7696 25.0894 22.2739 25.4476C22.7783 25.8058 23.3814 25.9988 24 26V26Z" fill="currentColor" />
    <path d="M24 16C23.1204 16.0001 22.2486 16.1654 21.43 16.4873L18.2644 10.9478C18.7358 10.4033 18.9991 9.7095 19.0077 8.98937C19.0162 8.26923 18.7693 7.56936 18.3109 7.01391C17.8525 6.45846 17.2121 6.08335 16.5035 5.95513C15.7948 5.8269 15.0636 5.95385 14.4396 6.31346C13.8156 6.67306 13.3392 7.24206 13.0948 7.91953C12.8505 8.59699 12.854 9.33911 13.1047 10.0142C13.3555 10.6894 13.8373 11.2538 14.4646 11.6075C15.092 11.9612 15.8244 12.0812 16.5318 11.9463L20.6507 19.1548L21.5193 18.6572C22.3773 18.1672 23.3612 17.9423 24.3469 18.0109C25.3326 18.0796 26.2758 18.4387 27.0576 19.043C27.8393 19.6473 28.4246 20.4696 28.7394 21.4062C29.0542 22.3428 29.0845 23.3516 28.8264 24.3054C28.5684 25.2592 28.0336 26.1151 27.2895 26.7652C26.5454 27.4153 25.6254 27.8304 24.6457 27.9581C23.6659 28.0858 22.6702 27.9204 21.7844 27.4827C20.8985 27.0451 20.1622 26.3548 19.6683 25.499L17.937 26.501C18.5514 27.5652 19.4352 28.4488 20.4994 29.0632C21.5636 29.6776 22.7707 30.001 23.9995 30.001C25.2284 30.001 26.4355 29.6775 27.4997 29.0631C28.5639 28.4487 29.4476 27.565 30.062 26.5009C30.6764 25.4367 30.9999 24.2295 31 23.0007C31 21.7719 30.6766 20.5647 30.0622 19.5005C29.4479 18.4363 28.5642 17.5526 27.5001 16.9381C26.4359 16.3236 25.2288 16.0001 24 16V16Z" fill="currentColor" />
    <path d="M8.532 20.0537C7.82873 19.921 7.10118 20.0415 6.47825 20.3939C5.85532 20.7462 5.37724 21.3077 5.12869 21.9789C4.88015 22.65 4.8772 23.3875 5.12036 24.0606C5.36352 24.7337 5.83709 25.299 6.45718 25.6564C7.07726 26.0137 7.80383 26.1401 8.50814 26.013C9.21246 25.8859 9.84905 25.5136 10.3051 24.9621C10.7612 24.4106 11.0074 23.7154 11 22.9997C10.9925 22.2841 10.732 21.5942 10.2646 21.0522C11.74 18.47 13.86 14.7607 13.89 14.708L14.3876 13.8398L13.5199 13.3428C12.6605 12.8518 11.9662 12.1168 11.5249 11.2309C11.0836 10.345 10.9151 9.348 11.0409 8.36628C11.1666 7.38455 11.5809 6.46222 12.2312 5.71612C12.8815 4.97002 13.7387 4.4337 14.694 4.17512C15.6494 3.91653 16.66 3.94729 17.5979 4.26352C18.5357 4.57975 19.3587 5.16721 19.9624 5.95149C20.5661 6.73577 20.9235 7.68159 20.9893 8.66915C21.0551 9.65671 20.8263 10.6416 20.3319 11.499L22.0634 12.501C22.7358 11.3368 23.0588 10.0035 22.9937 8.66058C22.9286 7.31767 22.4782 6.0219 21.6963 4.92815C20.9144 3.8344 19.8341 2.98894 18.5845 2.49281C17.3349 1.99668 15.9689 1.87087 14.6497 2.13043C13.3305 2.38998 12.1139 3.02391 11.1454 3.95645C10.1769 4.88899 9.49736 6.08069 9.18808 7.38913C8.87881 8.69757 8.95284 10.0674 9.40134 11.3349C9.84983 12.6023 10.6538 13.7139 11.7172 14.5366C11.2602 15.2793 10.6151 16.4082 9.6435 18.1094L8.532 20.0537Z" fill="currentColor" />
  </svg>
);

WebhookIcon.propTypes = {};

WebhookIcon.defaultProps = {};

export default WebhookIcon;
