import React from 'react';
import PropTypes from 'prop-types';

import styles from './StatCardValue.module.scss';

const StatCardValue = ({ title, showSeparator, children }) => (
  <>
    {showSeparator && (
      <div className={styles.separator} />
    )}
    <div>
      <div className="font-12 text-center">{title}</div>
      <div className="text-dark font-weight-600 font-20 text-center">
        {children}
      </div>
    </div>
  </>
);

StatCardValue.propTypes = {
  title: PropTypes.string.isRequired,
  showSeparator: PropTypes.bool
};

StatCardValue.defaultProps = {
  showSeparator: false
};

export default StatCardValue;
