import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './styles.scss';
import ListHeader from './ListHeader';
import ListItem from './ListItem';

const ListView = ({ deposits, sort, onSortChange }) => {
  if (!deposits.length) return null;

  return (
    <Table bordered responsive id="deposits-table" style={{ height: '1px' }}>
      <thead>
        <ListHeader sort={sort} onSortChange={onSortChange} />
      </thead>
      <tbody>
        {
          deposits.map((deposit) => (
            <ListItem key={`deposit-${deposit.id}`} deposit={deposit} />
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propTypes = {
  sort: PropTypes.objectOf(PropTypes.any),
  onSortChange: PropTypes.func
};

ListView.defaultProps = {
  sort: {},
  onSortChange: () => {}
};

export default connect((store) => ({
  deposits: store.deposits.deposits
}))(ListView);
