import React from 'react';
import Dropzone from 'react-dropzone';
import { Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import './styles.scss';

const i18nOpts = { scope: 'components.common.fileUploader.index' };

const FileUploader = ({
  onUpload, uploading, label, ...props
}) => {
  const uploadLabel = label || i18n.t('dragAndDrop', i18nOpts);

  return (
    <Dropzone
      onDrop={onUpload}
      disabled={uploading}
      accept={['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/x-icon', 'image/vnd.microsoft.icon']}
      {...props}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="image-uploader">
            <div className="mb-4">
              {uploading ? (
                <Spinner size="lg" className="upload-spinner" />
              ) : (
                <i className="fa fa-cloud-upload-alt upload-icon" />
              )}
            </div>

            <div className="upload-text">{uploadLabel}</div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

FileUploader.propTypes = {
  onUpload: PropTypes.func,
  uploading: PropTypes.bool,
  label: PropTypes.string
};

FileUploader.defaultProps = {
  onUpload: () => {},
  uploading: false,
  label: null
};

export default FileUploader;
