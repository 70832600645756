async function getIpLocation(ip) {
  return fetch(`https://ipapi.co/${ip}/json`)
    .then((data) => data.json())
    .then((location) => Promise.resolve(location))
    .catch((error) => Promise.reject(error));
}

export default {
  getIpLocation
};
