import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { Card } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy, FaRegCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import v from 'voca';

import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';

const i18nOpts = { scope: 'components.admin.integrations.googleSitemap.index' };

const GoogleSitemap = ({ currentCompany }) => {
  const [copied, setCopied] = useState(false);

  const companyDomain = currentCompany.domains[0];
  const sitemapUrl = v.isBlank(companyDomain) ? null : new URL('/sitemap', companyDomain).toString();

  const onCopy = () => {
    setCopied(true);
    toast.info(i18n.t('messages.copied', i18nOpts));

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

      {v.isBlank(sitemapUrl) ? (
        <div className="text-muted">
          {i18n.t('noDomain', i18nOpts)}
        </div>
      ) : (
        <>
          <div className="mb-2">{i18n.t('sitemapUrl', i18nOpts)}</div>

          <Card body className="d-inline-block">
            <div className="d-flex align-items-center">
              <div>
                <a href={sitemapUrl} target="_blank" rel="noreferrer">{sitemapUrl}</a>
              </div>
              <div className="ml-4">
                {copied ? (
                  <FaRegCheckCircle size="1.2rem" className="text-success" />
                ) : (
                  <CopyToClipboard text={sitemapUrl} onCopy={onCopy}>
                    <FaCopy size="1.2rem" role="button" title={i18n.t('buttons.copy')} />
                  </CopyToClipboard>
                )}
              </div>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {})(GoogleSitemap);
