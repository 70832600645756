import React, { useEffect, useState } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Button
} from 'reactstrap';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import { HiShare } from 'react-icons/all';

import Details from './Details';
import { getModel } from '../../../../store/actions/modelActions';
import Elevations from './Elevations';
import Specifications from './Specifications';
import Packages from './Packages';
import Palettes from './Palettes';
import Images from './Images';
import ModalService from '../../../../services/ModalService';
import { ModalNames, NavigationMainNames, Statuses } from '../../../../constants';
import DirectLinkModal from './DirectLinkModal';
import { hasCompanyDomain } from '../../../../utils/companyUtils';
import LotWorks from './LotWorks';
import { isAdmin } from '../../../../utils/authUtils';
import api from '../../../../api';
import { cloneModelQuery } from '../../../../graphql';
import QRCodeModal from '../../../global/QRCodeModal';
import Modifications from '../../../global/Modifications';
import PriceRuleFormModal from '../../../global/priceRules/PriceRuleFormModal';
import { cleanPriceRules } from '../../../../store/actions/priceRuleActions';
import PriceRules from './PriceRules';
import Floors from './Floors';
import NavigationBreadcrumbs from '../../../global/NavigationBreadcrumbs';
import NavigationService from '../../../../services/NavigationService';
import confirmClone from '../../../common/ConfirmClone';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.models.show.index' };

const Tabs = Object.freeze({
  DETAILS: 'details',
  FLOORS: 'floors',
  ELEVATIONS: 'elevations',
  PACKAGES: 'packages',
  SPECIFICATIONS: 'specifications',
  PALETTES: 'palettes',
  IMAGES: 'images',
  LOT_WORKS: 'lotWorks',
  MODIFICATIONS: 'modifications',
  PRICE_RULES: 'priceRules',
});

function openDirectLinkModal(isPreview = false) {
  ModalService.open(ModalNames.MODEL_DIRECT_LINK_MODAL, { isPreview });
}

function cloneModel(id) {
  const variables = { id };
  return api.graphql(cloneModelQuery, variables)
    .then(({ data: { item } }) => Promise.resolve(item));
}

const Show = ({
  id, currentCompany, model, ...props
}) => {
  const [activeTab, setActiveTab] = useState(Tabs.DETAILS);
  const [loading, setLoading] = useState(true);
  const [cloning, setCloning] = useState(false);

  const onChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const loadModel = () => {
    setLoading(true);

    props.cleanPriceRules()
      .then(() => props.getModel(parseInt(id, 10)))
      .then(() => setLoading(false))
      .catch(() => navigate('/admin/models'));
  };

  const onClone = () => {
    setCloning(true);

    cloneModel(parseInt(id, 10))
      .then((item) => {
        const url = `/admin/models/${item.id}`;
        navigate(url);
      }).finally(() => {
        setCloning(false);
      });
  };

  useEffect(() => {
    loadModel();
  }, [id]);

  useEffect(() => {
    if (model) NavigationService.addItem(model, NavigationMainNames.MODELS);
  }, [model]);

  if (!model || loading) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={model.name} />

      <NavigationBreadcrumbs />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{model.name}</h2>
        </div>
        <div>
          <Button
            color="secondary"
            onClick={() => confirmClone(
              i18n.t('clone.title', { ...i18nOpts, modelName: model.name }),
              i18n.t('clone.message', i18nOpts),
              onClone
            )}
            className="mr-3"
            disabled={cloning}
          >
            {cloning && (<Spinner size="sm" className="mr-2" />)}
            <span>{i18n.t('buttons.clone', i18nOpts)}</span>
          </Button>
          { model.status === Statuses.DRAFT && hasCompanyDomain(currentCompany) && (
          <Button color="secondary" onClick={() => openDirectLinkModal(true)}>
            {i18n.t('buttons.preview', i18nOpts)}
          </Button>
          )}
          { model.status === Statuses.PUBLISHED && hasCompanyDomain(currentCompany) && (
          <Button color="secondary" onClick={() => openDirectLinkModal(false)}>
            <HiShare size="1rem" className="col-light mr-2" />
            <span>{i18n.t('buttons.shareLink')}</span>
          </Button>
          )}
          {isAdmin() && (
          <Link to={`/admin/models/form?id=${model.id}`} className="btn btn-primary ml-3">
            <i className="far fa-edit mr-2" />
            {i18n.t('buttons.edit')}
          </Link>
          )}
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.DETAILS ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.DETAILS)}
          >
            {i18n.t('tabs.details', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.FLOORS ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.FLOORS)}
          >
            {i18n.t('tabs.floors', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.PRICE_RULES ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.PRICE_RULES)}
          >
            {i18n.t('tabs.priceRules', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.ELEVATIONS ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.ELEVATIONS)}
          >
            {i18n.t('tabs.elevations', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.PACKAGES ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.PACKAGES)}
          >
            {i18n.t('tabs.packages', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.SPECIFICATIONS ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.SPECIFICATIONS)}
          >
            {i18n.t('tabs.specifications', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.PALETTES ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.PALETTES)}
          >
            {i18n.t('tabs.palettes', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.IMAGES ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.IMAGES)}
          >
            {i18n.t('tabs.images', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.LOT_WORKS ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.LOT_WORKS)}
          >
            {i18n.t('tabs.lotWorks', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.MODIFICATIONS ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.MODIFICATIONS)}
          >
            {i18n.t('tabs.modifications', i18nOpts)}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.DETAILS}>
          <Details />
        </TabPane>
        <TabPane tabId={Tabs.FLOORS}>
          <Floors />
        </TabPane>
        <TabPane tabId={Tabs.PRICE_RULES}>
          <PriceRules />
        </TabPane>
        <TabPane tabId={Tabs.ELEVATIONS}>
          <Elevations />
        </TabPane>
        <TabPane tabId={Tabs.PACKAGES}>
          <Packages />
        </TabPane>
        <TabPane tabId={Tabs.SPECIFICATIONS}>
          <Specifications />
        </TabPane>
        <TabPane tabId={Tabs.PALETTES}>
          <Palettes />
        </TabPane>
        <TabPane tabId={Tabs.IMAGES}>
          <Images />
        </TabPane>
        <TabPane tabId={Tabs.LOT_WORKS}>
          <LotWorks />
        </TabPane>
        <TabPane tabId={Tabs.MODIFICATIONS}>
          <Modifications instance={model} />
        </TabPane>
      </TabContent>

      <DirectLinkModal />
      <QRCodeModal />
      <PriceRuleFormModal />
    </div>
  );
};

export default connect((store) => ({
  model: store.models.model,
  currentCompany: store.companies.currentCompany,
}), {
  getModel,
  cleanPriceRules
})(Show);
