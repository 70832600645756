import React, { useEffect } from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';

import styles from './SalesInvitationModal.module.scss';
import { ModalNames, SalesInvitationStartTypes, ViewModes } from '../../../constants';
import InvitationWizard from './InvitationWizard';
import {
  setCustomersInviteMethod,
  setInvitedHome,
  setInvitationType,
  setHomesViewMode
} from '../../../store/actions/salesInvitationActions';
import { SalesInvitationTypes } from './InvitationWizard/constants';
import { addInvitedCustomers } from './InvitationWizard/utils';

const SalesInvitationModal = ({ opened, params, ...props }) => {
  const { startType } = params;

  const loadInitialState = () => {
    const isFromHome = startType === SalesInvitationStartTypes.FROM_HOME;
    const isFromCustomer = startType === SalesInvitationStartTypes.FROM_CUSTOMER;
    let initialHome = null;
    let initialInvitedCustomers = [];
    if (isFromHome) {
      initialHome = params.home;
      props.setInvitationType(SalesInvitationTypes.RESERVE_NOW);
    }
    if (isFromCustomer) initialInvitedCustomers = [params.customer];

    props.setCustomersInviteMethod(null);
    addInvitedCustomers(...initialInvitedCustomers);
    props.setInvitedHome(initialHome);
    props.setHomesViewMode(ViewModes.LIST);
  };

  useEffect(() => {
    if (!opened) return;

    loadInitialState();
  }, [opened]);

  return (
    <Modal isOpen={opened} centered scrollable className={styles.modalDialog}>
      <InvitationWizard startType={startType} />
    </Modal>
  );
};

SalesInvitationModal.propTypes = {};

SalesInvitationModal.defaultProps = {};

export default connect((store) => ({
  params: store.modals[ModalNames.SALES_INVITATION]?.params || {},
  opened: store.modals[ModalNames.SALES_INVITATION]?.opened || false,
}), {
  setCustomersInviteMethod,
  setInvitedHome,
  setInvitationType,
  setHomesViewMode
})(SalesInvitationModal);
