import React, { Component } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import classnames from 'classnames';

import List from './List';

const i18nOpts = { scope: 'components.admin.integrations.importData.details.index' };

const Tabs = Object.freeze({
  IMPORTED_OBJECTS: 'importedObjects',
  NOT_IMPORTED_OBJECTS: 'notImportedObjects'
});

const ITEM_TYPES = Object.freeze([
  'cities',
  'sfCommunities',
  'sfProductTypes',
  'sfCommunityProductTypes',
  'sfModels',
  'sfCommunityModels',
  'sfElevations',
  'sfFloors',
  'sfFloorplans',
  'sfOptionalUpgrades',
  'sfPackages',
  'sfModelPackages',
  'sfPriceRules',
  'sfQuickPossessions',
  'mfProjects',
  'mfBuildings',
  'mfModels',
  'mfBuildingModels',
  'mfUnits',
  'mfPackages',
  'mfPalettes',
  'mfFloors',
  'mfFloorplans',
  'mfModelPackages',
  'mfModelPalettes',
  'mfOptionTypes',
  'mfUnitOptions'
]);

class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: Tabs.IMPORTED_OBJECTS
    };
  }

  onChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    const { activeTab } = this.state;
    const { importedData } = this.props;

    if (!importedData) return null;

    return (
      <div className="py-5">
        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{i18n.t('title', i18nOpts)}</h2>
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.IMPORTED_OBJECTS })}
              onClick={() => this.onChangeTab(Tabs.IMPORTED_OBJECTS)}
            >
              {i18n.t('tabs.importedObjects', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.NOT_IMPORTED_OBJECTS })}
              onClick={() => this.onChangeTab(Tabs.NOT_IMPORTED_OBJECTS)}
            >
              {i18n.t('tabs.notImportedObjects', i18nOpts)}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="py-4">
          <TabPane tabId={Tabs.IMPORTED_OBJECTS}>
            {
              ITEM_TYPES.map((modelName) => (
                <List
                  key={`imported-items-${modelName}`}
                  title={i18n.t(modelName, i18nOpts)}
                  objects={importedData[modelName]?.processedInstances}
                />
              ))
            }
          </TabPane>

          <TabPane tabId={Tabs.NOT_IMPORTED_OBJECTS}>
            {
              ITEM_TYPES.map((modelName) => (
                <List
                  key={`non-imported-items-${modelName}`}
                  title={i18n.t(modelName, i18nOpts)}
                  objects={importedData[modelName]?.unprocessedInstances}
                  nonImportedTable
                />
              ))
            }
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default connect((store) => ({
  importedData: store.importExports.importedData,
}), {})(Details);
