export function downloadOnfidoFile(buffer, fileName) {
  if (!buffer) return;

  const document = window?.document;

  const arrayBuffer = [new Uint8Array(buffer?.data)];
  const blob = new Blob(arrayBuffer);

  const imageUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = imageUrl;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
