import {
  SAVE_SPECIFICATION, DELETE_SPECIFICATION, GET_SPECIFICATIONS
} from './types';
import {
  createSpecificationQuery, deleteSpecificationQuery, listSpecificationsQuery,
  updateSpecificationQuery
} from '../../graphql';
import api from '../../api';

export function getSpecifications(variables = {}) {
  return {
    type: GET_SPECIFICATIONS,
    payload: api.graphql(listSpecificationsQuery, variables)
  };
}

export function saveSpecification(variables = {}) {
  const query = variables.input.id ? updateSpecificationQuery : createSpecificationQuery;
  return {
    type: SAVE_SPECIFICATION,
    payload: api.graphql(query, variables)
  };
}

export function deleteSpecification(id) {
  return {
    type: DELETE_SPECIFICATION,
    payload: api.graphql(deleteSpecificationQuery, { id })
  };
}
