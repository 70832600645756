import React, { Component } from 'react';
import _ from 'lodash';

import UnitsPanel from '../../../../../../common/Designer/panels/UnitsPanel';
import Designer from '../../../../../../common/Designer';
import { updateUnitQuery } from '../../../../../../../graphql';
import api from '../../../../../../../api';

const PRIMARY_COLOR = '#00AF8C';

class FloorDesign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objects: [],
      units: []
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.onAssignUnit = this.onAssignUnit.bind(this);
    this.onRemoveObject = this.onRemoveObject.bind(this);
    this.updateUnits = this.updateUnits.bind(this);
  }

  componentDidMount() {
    const { buildingFloor: { units = null } } = this.props;

    if (units && units?.length) {
      const mappedObjects = [];
      units.forEach((u) => {
        const currentUnit = { ...u };
        delete currentUnit.designedObject;
        if (!_.isEmpty(u.designedObject)) {
          mappedObjects.push({
            ...u.designedObject, unit: currentUnit, fill: PRIMARY_COLOR
          });
        }
      });

      this.setState({ objects: mappedObjects, units });
    }
  }

  // eslint-disable-next-line consistent-return
  onRemoveObject(object) {
    if (object?.unit?.id) {
      const input = { id: object.unit.id, designedObject: {} };
      const variables = { input };
      this.updateUnits(object.unit, {});
      return api.graphql(updateUnitQuery, variables);
    }
    return Promise.resolve();
  }

  onAssignUnit(object, unit) {
    const designedObject = { ...object };

    delete designedObject.unit;

    const input = { id: unit.id, designedObject };
    const variables = { input };
    return api.graphql(updateUnitQuery, variables);
  }

  handleUpdate(objects) {
    this.setState({ objects });
  }

  updateUnits(unit, object) {
    const { buildingFloor } = this.props;
    if (!unit) return;

    const { units } = buildingFloor;
    const index = units.findIndex((u) => unit.id === u.id);
    units[index].designedObject = {};
    units[index] = { ...units[index], designedObject: object };

    this.setState({ units: units.filter((u) => Object.keys(u.designedObject).length === 0) });
  }

  render() {
    const { imageUrl, buildingFloor } = this.props;
    const { objects, units } = this.state;
    return (
      <Designer
        width={800}
        height={700}
        objects={objects}
        onUpdate={this.handleUpdate}
        background={imageUrl}
        ExtraPanel={
          (props) => (
            <UnitsPanel
              key={`building-floor-${buildingFloor.id}-unit-panel`}
              buildingFloor={buildingFloor}
              units={units}
              updateUnits={this.updateUnits}
              {...props}
            />
          )
        }
        onAssignObject={(object, unit) => this.onAssignUnit(object, unit)}
        onRemoveObject={(object) => this.onRemoveObject(object)}
      />
    );
  }
}

export default FloorDesign;
