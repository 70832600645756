import React, { useMemo } from 'react';
import {
  Row, Col, Card, CardHeader, CardBody
} from 'reactstrap';
import i18n from 'i18n-js';

import TextPlaceholder from '../../components/TextPlaceholder';
import useHomeRental from '../../../../../../../../hooks/useHomeRental';
import useIsMobile from '../../../../../../../../hooks/useIsMobile';

const i18nOpts = { scope: 'components.admin.customers.show.details.verification.coBuyerAdded.placeholder.index' };

const Placeholder = () => {
  const isMobile = useIsMobile();

  const cols = useMemo(() => {
    const size = isMobile ? 4 : 8;
    return Array.from(Array(size), (a, i) => i);
  }, [isMobile]);

  const { typeI18nKey } = useHomeRental();

  return (
    <Card className="mb-0">
      <CardHeader>
        <div className="d-flex align-items-center">
          <h4 className="mb-0 mr-3 col-gray-400">{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</h4>
          <TextPlaceholder />
          <div className="ml-auto">
            <TextPlaceholder width={109} />
          </div>
        </div>
      </CardHeader>
      <CardBody className="pb-0">
        <Row>
          {
            cols.map((i) => (
              <Col key={`col-placeholder-${i}`} xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
                <TextPlaceholder containerClassName="mb-1" />
                <TextPlaceholder width={55} color="dark" />
              </Col>
            ))
          }
        </Row>
      </CardBody>
    </Card>
  );
};

Placeholder.propTypes = {};

Placeholder.defaultProps = {};

export default Placeholder;
