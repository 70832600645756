import {
  GET_EXTERNAL_DATA_SOURCE,
  GET_EXTERNAL_DATA_SOURCES,
  SAVE_EXTERNAL_DATA_SOURCE,
  DELETE_EXTERNAL_DATA_SOURCE,
  SYNC_EXTERNAL_DATA_SOURCE,
  GET_BUILT_OPTIONS_REPORT,
} from './types';
import {
  getExternalDataSourceQuery, listExternalDataSourcesQuery, createExternalDataSourceQuery,
  updateExternalDataSourceQuery, deleteExternalDataSourceQuery, startSyncExternalDataSourceQuery,
  getBuiltOptionsReportQuery
} from '../../graphql';
import api from '../../api';

export function getExternalDataSource(variables = {}) {
  return {
    type: GET_EXTERNAL_DATA_SOURCE,
    payload: api.graphql(getExternalDataSourceQuery, variables)
  };
}

export function getExternalDataSources(variables = {}) {
  return {
    type: GET_EXTERNAL_DATA_SOURCES,
    payload: api.graphql(listExternalDataSourcesQuery, variables)
  };
}

export function saveExternalDataSource(variables = {}) {
  const query = variables.input.id ? updateExternalDataSourceQuery : createExternalDataSourceQuery;
  return {
    type: SAVE_EXTERNAL_DATA_SOURCE,
    payload: api.graphql(query, variables)
  };
}

export function deleteExternalDataSource(variables = {}) {
  return {
    type: DELETE_EXTERNAL_DATA_SOURCE,
    payload: api.graphql(deleteExternalDataSourceQuery, variables)
  };
}

export function syncExternalDataSource(variables = {}) {
  return {
    type: SYNC_EXTERNAL_DATA_SOURCE,
    payload: api.graphql(startSyncExternalDataSourceQuery, variables)
  };
}

export function getBuiltOptionsReport(variables = {}) {
  return {
    type: GET_BUILT_OPTIONS_REPORT,
    payload: api.graphql(getBuiltOptionsReportQuery, variables)
  };
}
