import v from 'voca';
import i18n from 'i18n-js';

import store from '../../../../store';
import { setInvitedCustomers } from '../../../../store/actions/salesInvitationActions';
import { SalesInviteMethods, Steps, SalesInvitationTypes } from './constants';
import { SalesInvitationStartTypes } from '../../../../constants';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.utils' };

export function addInvitedCustomers(...customers) {
  // Set default invite method
  const newInvitedCustomers = customers.map((customer) => {
    const hasEmail = !v.isBlank(customer.email);
    const hasPhone = !v.isBlank(customer.phone);

    if (hasEmail && hasPhone) return customer;

    const defaultInviteMethod = hasPhone ? SalesInviteMethods.SMS : SalesInviteMethods.EMAIL;

    return {
      ...customer,
      inviteMethod: defaultInviteMethod,
    };
  });

  store.dispatch(setInvitedCustomers(newInvitedCustomers));
}

export function getInitialStep(startType) {
  if (startType === SalesInvitationStartTypes.FROM_HOME) return Steps.CUSTOMER_SELECTION_TYPE;

  return Steps.INVITATION_TYPE;
}

export function getInvitationTypeName(invitationType) {
  if (invitationType === SalesInvitationTypes.VERIFIED) return i18n.t('invitationType.verified', i18nOpts);
  return i18n.t('invitationType.reserveNow', i18nOpts);
}
