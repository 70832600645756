import { IoIosNotifications } from 'react-icons/io';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const RequestIcon = ({ quickPossession, tooltip }) => (
  <div id={`request-icon-${quickPossession?.id}`}>
    <IoIosNotifications size="2.2em" className="options requests-icon" />

    <UncontrolledTooltip target={`request-icon-${quickPossession?.id}`}>
      {tooltip}
    </UncontrolledTooltip>
  </div>
);

export default RequestIcon;
