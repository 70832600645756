import {
  GET_PROJECT, SAVE_PROJECT, CLEAN_PROJECTS, DELETE_PROJECT, GET_PROJECTS, SET_PROJECTS_VIEW_MODE
} from './types';
import {
  createProjectQuery,
  updateProjectQuery,
  deleteProjectQuery,
  getProjectQuery,
  listProjectsQuery
} from '../../graphql';
import api from '../../api';

export function getProjects(variables = {}) {
  return {
    type: GET_PROJECTS,
    payload: api.graphql(listProjectsQuery, variables)
  };
}

export function getProject(id) {
  return {
    type: GET_PROJECT,
    payload: api.graphql(getProjectQuery, { id })
  };
}

export function cleanProjects(extraProps = {}) {
  return {
    type: CLEAN_PROJECTS,
    payload: Promise.resolve(extraProps)
  };
}

export function saveProject(variables = {}) {
  const query = variables.input.id ? updateProjectQuery : createProjectQuery;
  return {
    type: SAVE_PROJECT,
    payload: api.graphql(query, variables)
  };
}

export function deleteProject(id) {
  return {
    type: DELETE_PROJECT,
    payload: api.graphql(deleteProjectQuery, { id })
  };
}

export function setProjectsViewMode(viewMode) {
  return {
    type: SET_PROJECTS_VIEW_MODE,
    payload: { viewMode }
  };
}
