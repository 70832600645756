import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import MortgageProviderAssignmentItem from './MortgageProviderAssignmentItem';

const MortgageProviderAssignmentsList = ({ mortgageProviderAssignments, onAfterDelete }) => (
  <Row>
    {
      mortgageProviderAssignments.map((mortgageProviderAssignment) => (
        <Col key={`mortgage-provider-assignment-${mortgageProviderAssignment.id}`} xs="12" sm="6" md="6" lg="4">
          <MortgageProviderAssignmentItem
            mortgageProviderAssignment={mortgageProviderAssignment}
            onAfterDelete={onAfterDelete}
          />
        </Col>
      ))
    }
  </Row>
);

MortgageProviderAssignmentsList.propTypes = {
  mortgageProviderAssignments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onAfterDelete: PropTypes.func
};

MortgageProviderAssignmentsList.defaultProps = {
  onAfterDelete: () => {}
};

export default MortgageProviderAssignmentsList;
