import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { MdContentCopy } from 'react-icons/md';

import styles from './VerificationDirectLinkModal.module.scss';
import { ModalNames } from '../../../constants';
import SocialShare from '../SocialShare';
import ModalService from '../../../services/ModalService';
import useHomeRental from '../../../hooks/useHomeRental';
import { buildVerifiedInvitationUrl } from '../../../utils/salesRepUtils';

const i18nOpts = { scope: 'components.global.verificationDirectLinkModal.index' };

function onClose() {
  ModalService.close(ModalNames.VERIFICATION_DIRECT_LINK);
}

const VerificationDirectLinkModal = ({ params, opened }) => {
  const { isRental } = useHomeRental();

  const url = useMemo(() => {
    const { hashId } = params;
    return buildVerifiedInvitationUrl(hashId, isRental);
  }, [params]);

  const onCopy = () => {
    toast.info(i18n.t('messages.copied', i18nOpts));
    onClose();
  };

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{i18n.t('title', i18nOpts)}</ModalHeader>
      <ModalBody>
        <div className={styles.linkContainer}>
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        </div>
        <SocialShare itemName={i18n.t('name', i18nOpts)} url={url} />
      </ModalBody>
      <ModalFooter>
        <CopyToClipboard text={url} onCopy={onCopy} className="mr-3">
          <Button outline color="primary" className="text-uppercase">
            <MdContentCopy size="1rem" className="mr-2 align-middle" />
            {i18n.t('buttons.copy', i18nOpts)}
          </Button>
        </CopyToClipboard>
        <Button outline color="secondary" onClick={onClose}>
          {i18n.t('buttons.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VerificationDirectLinkModal.propTypes = {};

VerificationDirectLinkModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.VERIFICATION_DIRECT_LINK]?.opened || false,
  params: store.modals[ModalNames.VERIFICATION_DIRECT_LINK]?.params || {},
}))(VerificationDirectLinkModal);
