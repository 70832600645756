import React from 'react';
import i18n from 'i18n-js';
import { Card } from 'reactstrap';

import signatureFieldImage from '../../../../../../assets/images/docusign/signature-field.png';
import customFieldsImage from '../../../../../../assets/images/docusign/custom-fields.png';

const i18nOpts = { scope: 'components.admin.integrations.docuSign.userGuides.documentTemplate.index' };

const DocumentTemplate = () => (
  <div>
    <h2 className="mb-3">{i18n.t('title', i18nOpts)}</h2>

    <ol className="pl-3">
      <li>
        <p>{i18n.t('step1', i18nOpts)}</p>
      </li>
      <li>
        <p>{i18n.t('step2', i18nOpts)}</p>
      </li>
      <li>
        <p>{i18n.t('step3', i18nOpts)}</p>
        <figure><img src={signatureFieldImage} className="mw-100" alt="" /></figure>
      </li>
      <li>
        <p>{i18n.t('step4', i18nOpts)}</p>
      </li>
      <li>
        <p>{i18n.t('step5', i18nOpts)}</p>
        <figure><img src={customFieldsImage} className="mw-100" alt="" /></figure>
        <Card body>
          <p className="mb-0">{i18n.t('step5Note', i18nOpts)}</p>
        </Card>
      </li>
      <li>
        <p>{i18n.t('step6', i18nOpts)}</p>
      </li>
    </ol>
  </div>
);

DocumentTemplate.propTypes = {};

DocumentTemplate.defaultProps = {};

export default DocumentTemplate;
