import {
  SAVE_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  CLEAN_CUSTOMERS,
  SYNC_CUSTOMER_PROPERTY_INFO,
  RESET_CUSTOMER_VERIFICATIONS,
  SET_CUSTOMER_ATTRIBUTES,
  SET_CUSTOMERS_SORT_FIELD,
  SET_CUSTOMERS_SORT_ORDER,
  SET_CUSTOMER_COLUMNS,
  SET_INVITED_CUSTOMERS,
  GET_CUSTOMER_FINANCIAL_REPORT,
  SHOW_CUSTOMER_FORM,
  SET_CUSTOMER_FILTERS,
  SET_CUSTOMER_INTEREST_FILTERS, SET_ACTIVE_CUSTOMER_TAB
} from './types';
import {
  updateCustomerQuery,
  getCustomerQuery,
  listCustomersQuery,
  createCustomerQuery,
  syncCustomerPropertyInfoMutation,
  resetCustomerVerification, getCustomerFinancialReportQuery
} from '../../graphql';
import api from '../../api';

export function saveCustomer(variables = {}) {
  const query = variables?.input?.id ? updateCustomerQuery : createCustomerQuery;
  return {
    type: SAVE_CUSTOMER,
    payload: api.graphql(query, variables)
  };
}

export function getCustomer(variables = {}) {
  return {
    type: GET_CUSTOMER,
    payload: api.graphql(getCustomerQuery, variables)
  };
}

export function getCustomers(variables = {}) {
  return {
    type: GET_CUSTOMERS,
    payload: api.graphql(listCustomersQuery, variables)
  };
}

export function cleanCustomers() {
  return {
    type: CLEAN_CUSTOMERS,
    payload: Promise.resolve()
  };
}

export function syncCustomerPropertyInfo(variables = {}) {
  return {
    type: SYNC_CUSTOMER_PROPERTY_INFO,
    payload: api.graphql(syncCustomerPropertyInfoMutation, variables)
  };
}

export function resetCustomerVerifications(variables = {}) {
  return {
    type: RESET_CUSTOMER_VERIFICATIONS,
    payload: api.graphql(resetCustomerVerification, variables)
  };
}

export function setCustomerAttributes(id, attributes = {}) {
  return {
    type: SET_CUSTOMER_ATTRIBUTES,
    payload: { id, attributes },
  };
}

export function setCustomersSortField(sortField) {
  return {
    type: SET_CUSTOMERS_SORT_FIELD,
    payload: { sortField }
  };
}

export function setCustomersSortOrder(sortOrder) {
  return {
    type: SET_CUSTOMERS_SORT_ORDER,
    payload: { sortOrder }
  };
}

export function setCustomerFilters(filters) {
  return {
    type: SET_CUSTOMER_FILTERS,
    payload: { filters }
  };
}

export function setCustomerColumns(columns) {
  return {
    type: SET_CUSTOMER_COLUMNS,
    payload: { columns }
  };
}

export function getCustomerFinancialReport(variables) {
  return {
    type: GET_CUSTOMER_FINANCIAL_REPORT,
    payload: api.graphql(getCustomerFinancialReportQuery, variables)
  };
}

export function showCustomerForm(showForm) {
  return {
    type: SHOW_CUSTOMER_FORM,
    payload: { showForm }
  };
}

export function setCustomerInterestFilters(interestFilters) {
  return {
    type: SET_CUSTOMER_INTEREST_FILTERS,
    payload: { interestFilters }
  };
}

export function setActiveCustomerTab(activeTab) {
  return {
    type: SET_ACTIVE_CUSTOMER_TAB,
    payload: { activeTab }
  };
}
