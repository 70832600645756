import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import ListItem from './ListItem';

const GridView = ({ mortgageProviders }) => (
  <Row>
    {
      mortgageProviders.map((mortgageProvider) => (
        <Col key={`mortgage-provider-${mortgageProvider.id}`} xs="12" sm="6" md="6" lg="4">
          <ListItem mortgageProvider={mortgageProvider} />
        </Col>
      ))
    }
  </Row>
);

GridView.propTypes = {};

GridView.defaultProps = {};

export default connect((store) => ({
  mortgageProviders: store.mortgageProviders.mortgageProviders,
}))(GridView);
