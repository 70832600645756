import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';

import BaseActionsMenu from '../../../../../../global/ActionsMenu';
import ModalService from '../../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../../constants';
import { deleteBuildingFloor, sortBuildingFloors } from '../../../../../../../store/actions/buildingFloorActions';
import { sortBuildingFloorIds } from './utils';

const i18nOpts = { scope: 'components.admin.projectBuildings.show.buildingFloors.buildingFloor.actionsMenu.index' };

const ActionsMenu = ({
  buildingFloors, buildingFloor, ...props
}) => {
  const totalFloors = buildingFloors.length;
  const index = buildingFloors.findIndex((bf) => bf.id === buildingFloor.id);

  const onEdit = () => {
    ModalService.open(ModalNames.BUILDING_FLOOR_FORM, { buildingFloor });
  };

  const onDelete = (buildingFloorId) => {
    props.deleteBuildingFloor(buildingFloorId);
  };

  const onSort = (extraIndex) => {
    const buildingFloorIds = sortBuildingFloorIds(buildingFloors, index, extraIndex);

    const variables = { ids: buildingFloorIds };
    props.sortBuildingFloors(variables)
      .catch((e) => toast.error(e.message));
  };

  return (
    <div>
      <BaseActionsMenu item={buildingFloor} onEdit={onEdit} onDelete={onDelete}>
        {totalFloors > 1 && (
          <>
            {index > 0 && (
              <DropdownItem onClick={() => onSort(-1)}>
                {i18n.t('moveUp', i18nOpts)}
              </DropdownItem>
            )}
            {index < totalFloors - 1 && (
              <DropdownItem onClick={() => onSort(1)}>
                {i18n.t('moveDown', i18nOpts)}
              </DropdownItem>
            )}
          </>
        )}
      </BaseActionsMenu>

    </div>
  );
};

ActionsMenu.propTypes = {
  buildingFloor: PropTypes.objectOf(PropTypes.any).isRequired
};

ActionsMenu.defaultProps = {};

export default connect((store) => ({
  buildingFloors: store.buildingFloors.buildingFloors,
}), {
  deleteBuildingFloor,
  sortBuildingFloors
})(ActionsMenu);
