import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, FormGroup, Input, Label, Spinner, Card, Form, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import v from 'voca';

import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { saveModel } from '../../../../../../store/actions/modelActions';
import { stripToNull } from '../../../../../../utils/stringUtils';
import { getError } from '../../../../../../utils/requestUtils';
import { hasFormChanges } from '../../../../../../utils/formUtils';

const i18nOpts = { scope: 'components.admin.models.show.floors.focus360Settings.index' };

const Focus360Settings = ({ model, ...props }) => {
  const [form, setForm] = useState({});
  const [initialForm, setInitialForm] = useState({});
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSave = () => {
    setSaving(true);

    const focus360Settings = {
      floorPlanCode: stripToNull(form.floorPlanCode)
    };
    const input = { id: model.id, focus360Settings };
    props.saveModel({ input })
      .then(() => {
        toast.success(i18n.t('messages.saved', i18nOpts));
        setInitialForm(form);
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    const formData = {
      floorPlanCode: model.focus360Settings?.floorPlanCode || '',
    };
    setForm(formData);
    setInitialForm(formData);
  }, []);

  const hasChanges = hasFormChanges(initialForm, form);

  return (
    <Card body>
      <Form onSubmit={onSave}>
        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="floorPlanCode">{i18n.t('floorPlanCode', i18nOpts)}</Label>

              <Input
                type="text"
                name="floorPlanCode"
                id="floorPlanCode"
                value={form.floorPlanCode || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'focus360Settings')}
              />
              <InputError name="focus360Settings" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Button color="primary" className="mr-3" disabled={saving || !hasChanges} onClick={onSave}>
          {saving && <Spinner size="sm" className="mr-2" />}
          {i18n.t('buttons.save')}
        </Button>
      </Form>
    </Card>
  );
};

Focus360Settings.propTypes = {};

Focus360Settings.defaultProps = {};

export default connect((store) => ({
  model: store.models.model,
}), {
  saveModel
})(Focus360Settings);
