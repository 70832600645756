import i18n from 'i18n-js';

import { Tabs } from '../../utils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.companyAndBranding.index' };

export function getSettings() {
  const settings = [
    {
      id: 'name',
      label: i18n.t('companyName', i18nOpts),
      containerId: 'nameContainer'
    },
    {
      id: 'countryCode',
      label: i18n.t('country', i18nOpts),
      containerId: 'countryCodeContainer'
    },
    {
      id: 'stateCode',
      label: i18n.t('state', i18nOpts),
      containerId: 'stateCodeContainer'
    },
    {
      id: 'city',
      label: i18n.t('city', i18nOpts),
      containerId: 'cityContainer'
    },
    {
      id: 'logo',
      label: i18n.t('companyLogo', i18nOpts),
      containerId: 'logoContainer'
    },
    {
      id: 'favicon',
      label: i18n.t('favicon', i18nOpts),
      containerId: 'faviconContainer'
    },
    {
      id: 'mainColor',
      label: i18n.t('textColor', i18nOpts),
      containerId: 'mainColorContainer'
    },
    {
      id: 'accentColor',
      label: i18n.t('accentColor', i18nOpts),
      containerId: 'accentColorContainer'
    },
    {
      id: 'floorPlanHighlightColor',
      label: i18n.t('floorPlanHighlightColor', i18nOpts),
      containerId: 'floorPlanHighlightColorContainer'
    },
    {
      id: 'ipToExclude',
      label: i18n.t('excludeIpAddresses', i18nOpts),
      containerId: 'ipToExcludeContainer'
    }
  ];

  if (isMasterAdmin() || isTecSupport()) {
    settings.push(...[
      {
        id: 'defaultHomePage',
        label: i18n.t('defaultHomePage', i18nOpts),
        containerId: 'defaultHomePageContainer'
      },
      {
        id: 'domains',
        label: i18n.t('domains', i18nOpts),
        containerId: 'domainsContainer'
      }
    ]);

    if (isMasterAdmin()) {
      settings.push(
        {
          id: 'enableCORSForJavaScriptSDK',
          label: i18n.t('enableCORSForJavaScriptSDK', i18nOpts),
          containerId: 'enableCORSForJavaScriptSDKContainer'
        }
      );
    }
  }

  return settings.map((setting) => ({
    ...setting,
    tabId: Tabs.COMPANY_AND_BRANDING
  }));
}
