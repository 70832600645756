import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

const GridView = ({ quickPossessions }) => {
  if (!quickPossessions) return null;

  return (
    <Row>
      {
        quickPossessions.map((q) => (
          <Col lg="4" key={`quickPossession-${q.id}`}>
            <ListItem quickPossession={q} />
          </Col>
        ))
      }
    </Row>
  );
};

GridView.propTypes = {
  quickPossessions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

GridView.defaultProps = {
  quickPossessions: []
};

export default GridView;
