import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';

import styles from './ExportProgress.module.scss';

const ExportProgress = ({ progress }) => (
  <Progress
    animated={progress !== 100}
    value={progress}
    className={styles.progress}
    barClassName={styles.progressBar}
  >
    <b>{`${progress}%`}</b>
  </Progress>
);

ExportProgress.propTypes = {
  progress: PropTypes.number.isRequired
};

ExportProgress.defaultProps = {};

export default ExportProgress;
