import React, { Component } from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Card,
  CardHeader, CardBody, CardTitle
} from 'reactstrap';
import * as PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { BiGridVertical } from 'react-icons/bi';

import './styles.scss';
import ModalService from '../../../../../services/ModalService';
import { FloorableTypes, ModalNames } from '../../../../../constants';
import { deleteFloorGroup } from '../../../../../store/actions/floorGroupActions';
import { isAdmin } from '../../../../../utils/authUtils';
import { confirmDelete } from '../../../confirmAlert';
import { setReloadFloors } from '../../../../../store/actions/floorActions';

const i18nOpts = { scope: 'components.global.floorsManager.floor.floorGroup.index' };

class FloorGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };

    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onToggleFocused = this.onToggleFocused.bind(this);
    this.onAddOption = this.onAddOption.bind(this);
  }

  onEdit() {
    const { floor, floorGroup } = this.props;
    ModalService.open(ModalNames.FLOOR_GROUP_FORM, { floor, floorGroup });
  }

  onDelete() {
    const { props } = this;
    const { floorGroup } = this.props;

    props.deleteFloorGroup(floorGroup.id)
      .then(() => props.setReloadFloors(true));
  }

  onToggleFocused(focused) {
    this.setState({ focused });
  }

  onAddOption(e) {
    const { floorGroup, floorableType } = this.props;
    ModalService.open(ModalNames.FLOOR_OPTION_FORM, { floorGroup, floorableType });
    e.preventDefault();
  }

  render() {
    const { floorGroup, children, floor } = this.props;
    const { focused } = this.state;

    if (!floorGroup) return null;

    return (
      <Card
        onMouseEnter={() => this.onToggleFocused(true)}
        onMouseLeave={() => this.onToggleFocused(false)}
        className="floor-group-container sortable-floor-group-item"
      >
        <CardHeader>
          <div className="d-flex">
            <div className="flex-grow-1">
              <CardTitle>{floorGroup.name}</CardTitle>
            </div>
            {isAdmin() && focused && (
              <>
                <div className="pr-4 pl-1">
                  <div className="text-right">
                    <Link to="/" onClick={this.onAddOption}>
                      <i className="fas fa-plus-circle mr-2" />
                      {i18n.t('buttons.addOption', i18nOpts)}
                    </Link>
                  </div>
                </div>
                <div className="pr-4">
                  <BiGridVertical size="1.2rem" className="sortable-floor-group-handler" title={i18n.t('buttons.sort')} />
                </div>
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle tag="a" className="px-2">
                      <i className="fas fa-ellipsis-v text-primary" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={this.onEdit}>
                        {i18n.t('buttons.edit')}
                      </DropdownItem>
                      <DropdownItem className="text-danger" onClick={() => confirmDelete(this.onDelete)}>
                        {i18n.t('buttons.delete')}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </>
            )}
          </div>
        </CardHeader>
        <CardBody>
          {children}
        </CardBody>
      </Card>
    );
  }
}

FloorGroup.propTypes = {
  floor: PropTypes.objectOf(PropTypes.any).isRequired,
  floorGroup: PropTypes.objectOf(PropTypes.any).isRequired,
  floorableType: PropTypes.oneOf(Object.values(FloorableTypes)).isRequired
};

export default connect((store) => ({
}), {
  deleteFloorGroup,
  setReloadFloors
})(FloorGroup);
