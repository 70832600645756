import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import api from '../api';

function fetchProjectsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListProjects($filter: ProjectFilterInput) {
      result: listProjects(filter: $filter, pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `, variables)
    .then(({ data: { result: { items } } }) => items);
}

const useFetchProjects = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany,
  }));

  useEffect(() => {
    setLoading(true);

    fetchProjectsAsync(currentCompany.id)
      .then((items) => setProjects(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { projects, loading };
};

export default useFetchProjects;
