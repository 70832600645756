import api from '../../../../../api';
import {
  createBuildingModelModelQuery, deleteBuildingModelModelQuery,
  listBuildingModelModelsQuery, updateBuildingModelModelQuery
} from '../../../../../graphql';

const listBuildingModelsQuery = `
  query ListBuildingModels(
    $filter: BuildingModelFilterInput
  ) {
    items: listBuildingModels(filter: $filter) {
      id
      name
    }
  }
`;

export function fetchBuildingModelsAsync(projectId) {
  const variables = { filter: { projectId } };

  return api.graphql(listBuildingModelsQuery, variables)
    .then(({ data: { items } }) => items);
}

export function fetchBuildingModelModelsAsync(projectBuildingId) {
  const variables = { filter: { projectBuildingId } };

  return api.graphql(listBuildingModelModelsQuery, variables)
    .then(({ data: { items } }) => items);
}

export function saveBuildingModelModelsAsync(variables) {
  const query = variables?.input.id ? updateBuildingModelModelQuery
    : createBuildingModelModelQuery;

  return api.graphql(query, variables)
    .then(({ data: { item } }) => item);
}

export function removeBuildingModelModelsAsync(id) {
  return api.graphql(deleteBuildingModelModelQuery, { id })
    .then(({ data: { item } }) => item);
}
