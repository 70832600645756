import React from 'react';

const SignDeclinedIcon = ({ ...props }) => (
  <svg width="25" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_654_2991)">
      <path d="M16.5042 18.56C16.42 18.5601 16.3366 18.5436 16.2588 18.5114C16.1811 18.4792 16.1104 18.432 16.051 18.3725L8.35866 10.6925C8.24189 10.5718 8.17727 10.4102 8.17874 10.2423C8.1802 10.0745 8.24761 9.91402 8.36646 9.79536C8.48531 9.6767 8.64609 9.60939 8.81417 9.60793C8.98224 9.60648 9.14417 9.67098 9.26507 9.78757L16.9574 17.4676C17.047 17.5571 17.108 17.6711 17.1327 17.7952C17.1575 17.9194 17.1448 18.048 17.0963 18.1649C17.0478 18.2819 16.9656 18.3818 16.8602 18.4521C16.7548 18.5225 16.6309 18.56 16.5042 18.56Z" fill="#D92D20" />
      <path d="M8.81179 18.56C8.68502 18.56 8.56112 18.5225 8.45572 18.4521C8.35033 18.3818 8.26819 18.2819 8.21969 18.1649C8.17118 18.048 8.15849 17.9194 8.18321 17.7952C8.20793 17.6711 8.26896 17.5571 8.35858 17.4676L16.0509 9.78757C16.1718 9.67098 16.3337 9.60648 16.5018 9.60793C16.6699 9.60939 16.8306 9.6767 16.9495 9.79536C17.0683 9.91402 17.1358 10.0745 17.1372 10.2423C17.1387 10.4102 17.0741 10.5718 16.9573 10.6925L9.26499 18.3725C9.20555 18.432 9.1349 18.4792 9.05713 18.5114C8.97935 18.5436 8.89598 18.5601 8.81179 18.56Z" fill="#D92D20" />
      <path d="M15.5689 31.3736H4.50929C3.56774 31.3725 2.66505 30.9987 1.99928 30.334C1.3335 29.6693 0.959026 28.768 0.958008 27.828V4.28876C0.959026 3.34872 1.3335 2.44748 1.99928 1.78277C2.66505 1.11806 3.56774 0.744181 4.50929 0.743164H20.808C21.7496 0.744181 22.6522 1.11806 23.318 1.78277C23.9838 2.44748 24.3583 3.34872 24.3593 4.28876V22.8865C24.3593 22.9725 24.3419 23.0577 24.3082 23.1369C24.2745 23.216 24.2252 23.2876 24.1631 23.3473L16.0138 31.1937C15.8943 31.3086 15.7348 31.3735 15.5689 31.3736ZM4.50929 2.02316C3.90766 2.02384 3.33087 2.26276 2.90545 2.68749C2.48003 3.11223 2.24074 3.6881 2.24006 4.28876V27.828C2.24074 28.4286 2.48003 29.0045 2.90545 29.4292C3.33087 29.854 3.90766 30.0929 4.50929 30.0936H15.3106L23.0772 22.6139V4.28876C23.0766 3.6881 22.8373 3.11223 22.4118 2.68749C21.9864 2.26276 21.4096 2.02384 20.808 2.02316H4.50929Z" fill="#D92D20" />
      <path d="M15.5688 25.8496C15.3987 25.8496 15.2357 25.7821 15.1155 25.6621C14.9953 25.5421 14.9277 25.3793 14.9277 25.2096C14.9288 24.4236 15.242 23.6701 15.7988 23.1145C16.3556 22.5588 17.1104 22.2464 17.8976 22.2457H23.7181C23.8881 22.2457 24.0512 22.3132 24.1714 22.4332C24.2916 22.5532 24.3591 22.716 24.3591 22.8857C24.3591 23.0555 24.2916 23.2183 24.1714 23.3383C24.0512 23.4583 23.8881 23.5257 23.7181 23.5257H17.8976C17.4502 23.5261 17.0212 23.7036 16.7047 24.0194C16.3883 24.3352 16.2103 24.7635 16.2098 25.2102C16.2098 25.3799 16.1422 25.5427 16.022 25.6628C15.9018 25.7828 15.7388 25.8496 15.5688 25.8496ZM15.5707 28.1728C15.5276 28.1749 15.4843 28.1705 15.4425 28.16C15.4014 28.1514 15.3607 28.1407 15.3207 28.128C15.283 28.109 15.2466 28.0876 15.2117 28.064C15.1766 28.0424 15.1443 28.0166 15.1156 27.9872C15.0561 27.9284 15.0082 27.8589 14.9745 27.7824C14.9442 27.7027 14.929 27.618 14.9297 27.5328C14.9285 27.4495 14.9437 27.3669 14.9745 27.2896C15.0082 27.213 15.056 27.1435 15.1156 27.0848C15.146 27.0553 15.1781 27.0275 15.2117 27.0016C15.2502 26.9824 15.2822 26.9632 15.3207 26.944C15.3656 26.9248 15.404 26.9184 15.4425 26.9056C15.5253 26.8927 15.6096 26.8927 15.6925 26.9056C15.7342 26.9146 15.7749 26.9275 15.8143 26.944C15.8527 26.9632 15.8912 26.9824 15.9232 27.0016C15.9593 27.0243 15.9918 27.0523 16.0194 27.0848C16.0811 27.1417 16.1293 27.2116 16.1604 27.2896C16.2109 27.4063 16.2245 27.5357 16.1993 27.6604C16.1741 27.7851 16.1113 27.8991 16.0194 27.9872C15.9933 28.0194 15.9605 28.0456 15.9232 28.064C15.8893 28.089 15.8527 28.1105 15.8143 28.128C15.7747 28.1423 15.734 28.153 15.6925 28.16C15.6528 28.1706 15.6117 28.1749 15.5707 28.1728Z" fill="#D92D20" />
    </g>
    <defs>
      <clipPath id="clip0_654_2991">
        <rect width="25" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

SignDeclinedIcon.propTypes = {};

SignDeclinedIcon.defaultProps = {};

export default SignDeclinedIcon;
