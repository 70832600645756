import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import v from 'voca';

import './styles.scss';
import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';
import Settings from './Settings';
import NavTabs from './NavTabs';
import { Tabs } from './utils';
import BuildHomeSetup from './BuildHomeSetup';
import { getClientDomain } from '../../../../utils/companyUtils';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.index' };

const EmbedWidget = ({ currentCompany }) => {
  const [activeTab, setActiveTab] = useState(Tabs.BUILD_HOME_SETUP);
  const clientDomain = getClientDomain(currentCompany);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div id="embed-widget-container">
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts), url: '' }]} />

      {v.isBlank(clientDomain) ? (
        <div className="text-muted">
          {i18n.t('noDomain', i18nOpts)}
        </div>
      ) : (
        <>
          <NavTabs activeTab={activeTab} onChange={onTabChange} />

          <TabContent activeTab={activeTab} className="py-4">
            <TabPane tabId={Tabs.BUILD_HOME_SETUP}>
              <BuildHomeSetup />
            </TabPane>
            <TabPane tabId={Tabs.SETTINGS}>
              <Settings />
            </TabPane>
          </TabContent>
        </>
      )}
    </div>
  );
};

EmbedWidget.propTypes = {};

EmbedWidget.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(EmbedWidget);
