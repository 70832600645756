import React from 'react';

const ProfileIcon = ({ ...props }) => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.9999 24.957C24.427 24.957 28.0159 21.3735 28.0159 16.953C28.0159 12.5325 24.427 8.94897 19.9999 8.94897C15.5728 8.94897 11.9839 12.5325 11.9839 16.953C11.9839 21.3735 15.5728 24.957 19.9999 24.957Z" fill="white" />
    <path d="M25.9639 22.2556C25.2184 23.1023 24.3011 23.781 23.2729 24.2467C22.2447 24.7125 21.1291 24.9546 20 24.957C18.8713 24.9543 17.756 24.712 16.7282 24.2463C15.7003 23.7806 14.7834 23.102 14.0381 22.2556C11.6461 23.2114 9.52008 24.7289 7.84034 26.6796C6.16061 28.6302 4.97664 30.9565 4.38879 33.4612C6.26292 35.7985 8.63845 37.686 11.3403 38.9847C14.0421 40.2834 17.0014 40.9601 20 40.965C22.9986 40.9601 25.9579 40.2834 28.6597 38.9847C31.3616 37.686 33.7371 35.7985 35.6112 33.4612C35.0253 30.956 33.8423 28.629 32.1627 26.6781C30.4831 24.7272 28.3566 23.2101 25.9639 22.2556Z" fill="#ACEAD8" />
    <path d="M14.0381 22.2556C12.7215 20.8017 11.9899 18.9131 11.984 16.953C11.984 14.8302 12.8285 12.7943 14.3318 11.2933C15.8351 9.79224 17.874 8.94897 20 8.94897C22.126 8.94897 24.1649 9.79224 25.6682 11.2933C27.1715 12.7943 28.016 14.8302 28.016 16.953C28.0107 18.9128 27.2798 20.8014 25.9639 22.2556C28.3489 23.2076 30.4693 24.7188 32.146 26.6615C33.8227 28.6042 35.0065 30.9214 35.5972 33.4172C38.4498 29.8809 40.0036 25.4758 40 20.935C40 9.90545 31.0461 0.964966 20 0.964966C8.95391 0.964966 0 9.90545 0 20.935C0 25.6613 1.65331 29.9975 4.40281 33.4172C4.99545 30.9219 6.18012 28.6054 7.85698 26.6629C9.53383 24.7205 11.6537 23.2089 14.0381 22.2556Z" fill="#00CEA6" />
  </svg>
);

ProfileIcon.propTypes = {};

ProfileIcon.defaultProps = {};

export default ProfileIcon;
