import React from 'react';
import PropTypes from 'prop-types';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import i18n from 'i18n-js';

import { StatusBadgeProps } from '../../../utils';

const i18nOpts = { scope: 'components.admin.integrations.webhooks.webhookDeliveries.list.listItem.status.index' };

const Status = ({ webhookDelivery }) => {
  const badgeProps = StatusBadgeProps[webhookDelivery.status];
  const htmlId = `webhook-delivery-status-${webhookDelivery.id}`;

  return (
    <>
      <Badge color={badgeProps.color} className="p-0 align-text-top" id={htmlId}>
        <badgeProps.Icon size="16" color="white" />
      </Badge>

      <UncontrolledTooltip placement="top" target={htmlId}>
        {i18n.t(webhookDelivery.status, i18nOpts)}
      </UncontrolledTooltip>
    </>
  );
};

Status.propTypes = {
  webhookDelivery: PropTypes.objectOf(PropTypes.any).isRequired
};

Status.defaultProps = {};

export default Status;
