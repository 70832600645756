import React from 'react';
import { Pagination, PaginationItem } from 'reactstrap';
import * as PropTypes from 'prop-types';

const NUM_ITEMS = 2;

const ListPagination = ({
  pagination, onPress
}) => {
  if (!pagination || pagination.totalPages < 2) return null;

  const { page, totalPages } = pagination;

  const pages = [page];
  const maxItems = NUM_ITEMS * 2;
  for (let i = 1; i <= maxItems; i += 1) {
    if (page - i >= 1) pages.unshift(page - i);
    if (page + i <= totalPages) pages.push(page + i);

    if (pages.length === maxItems + 1) break;
  }

  return (
    <Pagination className="d-inline-block">
      <PaginationItem disabled={page <= 1}>
        <div className="page-link" onClick={() => onPress(1)} role="button" aria-hidden>
          <span>«</span>
        </div>
      </PaginationItem>
      <PaginationItem disabled={page <= 1}>
        <div className="page-link" onClick={() => onPress(page - 1)} role="button" aria-hidden>
          <span>‹</span>
        </div>
      </PaginationItem>

      {
        pages.map((item) => (
          <PaginationItem key={`page-${item}`} active={item === page}>
            <div className="page-link" onClick={() => onPress(item)} role="button" aria-hidden>
              {item}
            </div>
          </PaginationItem>
        ))
      }

      <PaginationItem disabled={page >= totalPages}>
        <div className="page-link" onClick={() => onPress(page + 1)} role="button" aria-hidden>
          <span>›</span>
        </div>
      </PaginationItem>
      <PaginationItem disabled={page >= totalPages}>
        <div className="page-link" onClick={() => onPress(totalPages)} role="button" aria-hidden>
          <span>»</span>
        </div>
      </PaginationItem>
    </Pagination>
  );
};

ListPagination.propTypes = {
  pagination: PropTypes.objectOf(PropTypes.any),
  onPress: PropTypes.func
};

ListPagination.defaultProps = {
  pagination: null,
  onPress: () => {}
};

export default ListPagination;
