import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

const i18nOpts = { scope: 'components.admin.customers.show.details.verification.additionalInfo.preApproval.infoCard.downloadButton.index' };

const DownloadButton = ({ preApproval }) => {
  const { documentUrl } = preApproval;

  return (
    <a href={documentUrl} target="_blank" rel="noreferrer" className="btn btn-outline-secondary">
      {i18n.t('title', i18nOpts)}
    </a>
  );
};

DownloadButton.propTypes = {
  preApproval: PropTypes.objectOf(PropTypes.any).isRequired
};

DownloadButton.defaultProps = {};

export default DownloadButton;
