import React from 'react';

import AcceptOfferCard from './AcceptOfferCard';
import CounterOfferCard from './CounterOfferCard';
import RejectOfferCard from './RejectOfferCard';
import DeclineOfferCard from './DeclineOfferCard';
import DocuSignCard from './AcceptedOfferCard';

const OfferSection = () => (
  <div>
    <AcceptOfferCard />

    <CounterOfferCard />

    <RejectOfferCard />

    <DeclineOfferCard />

    <DocuSignCard />
  </div>
);

OfferSection.propTypes = {};

OfferSection.defaultProps = {};

export default OfferSection;
