import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import api from '../api';

function fetchProductTypesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListProductTypes($filter: ProductTypeFilterInput) {
      items: listProductTypes(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => items);
}

const useFetchProductTypes = () => {
  const [loading, setLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);

  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany,
  }));

  useEffect(() => {
    setLoading(true);

    fetchProductTypesAsync(currentCompany.id)
      .then((items) => setProductTypes(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { productTypes, loading };
};

export default useFetchProductTypes;
