import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import { formatCurrency } from '../../../../../../../../utils/currencyUtils';

const Income = ({ customer }) => {
  const formattedAnnualIncome = useMemo(() => {
    const { annualIncome } = customer.companyContact.affordability || {};
    if (!annualIncome) return null;

    return formatCurrency(annualIncome);
  }, [customer]);

  if (v.isBlank(formattedAnnualIncome)) return null;

  return (
    <div>
      <span className="text-nowrap">{formattedAnnualIncome}</span>
    </div>
  );
};

Income.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

Income.defaultProps = {};

export default Income;
