import _ from 'lodash';

import api from '../../../../../../api';
import { isUnionTypeObjectEqual } from '../../../../../../utils/objectUtils';
import { listContactTrackingEventsQuery } from '../../../../../../graphql/trackingEvents';
import { TrackingEventTypes } from '../../../../../../constants';

export const InterestTypes = Object.freeze({
  MODEL: 'MODEL',
  UNIT: 'UNIT',
  QUICK_POSSESSION: 'QUICK_POSSESSION',
  LOT: 'LOT'
});

export const InterestInteractionTypes = Object.freeze({
  SAVED: 'SAVED',
  VIEWED: 'VIEWED',
});

export async function fetchContactTrackingEventsAsync(contactId, companyId) {
  const variables = {
    contactId,
    companyId,
  };
  return api.graphql(listContactTrackingEventsQuery, variables)
    .then(({ data: { items } }) => items);
}

function addCommunityProject(items, item) {
  const isItemFound = items.some((i) => isUnionTypeObjectEqual(i, item));
  if (!isItemFound) items.push(item);
}

export function getCommunitiesProjectsFromInterests(interests) {
  const communitiesProjects = [];
  for (const interest of interests) {
    const { singleFamilyHome, multiFamilyHome } = interest;
    if (multiFamilyHome) {
      const { project } = multiFamilyHome;
      addCommunityProject(communitiesProjects, { id: project.id, name: project.name, __typename: 'Project' });
    } else {
      const { community } = singleFamilyHome;
      addCommunityProject(communitiesProjects, { id: community.id, name: community.name, __typename: 'Community' });
    }
  }
  return _.sortBy(communitiesProjects, ['name']);
}

export function filterInterests(interests, selectedCommunitiesProjects) {
  if (!selectedCommunitiesProjects.length) return interests;

  return interests.filter((interest) => {
    let communityProject = {};
    const { singleFamilyHome, multiFamilyHome } = interest;
    if (multiFamilyHome) communityProject = { id: multiFamilyHome.project.id, __typename: 'Project' };
    else communityProject = { id: singleFamilyHome.community.id, __typename: 'Community' };

    return selectedCommunitiesProjects.some((cp) => isUnionTypeObjectEqual(cp, communityProject));
  });
}

function getObjectFromDataObjects(dataObjects, typename) {
  // eslint-disable-next-line no-underscore-dangle
  return dataObjects.find((o) => o.__typename === typename);
}

export function getSavedHomesFromHomes(homes) {
  return homes.filter((home) => !(home.offer || home.deposit))
    .map((home) => ({
      ...home,
      interactionType: InterestInteractionTypes.SAVED,
      localId: `saved-home-${home.id}`,
      lastActive: home.createdAt
    }));
}

export function getViewedHomesFromTrackingEvents(trackingEvents) {
  const viewedHomes = trackingEvents.map((trackingEvent) => {
    let beds;
    let baths;
    let sizeSqft;
    let totalPrice;
    let singleFamilyHome;
    let multiFamilyHome;

    const { eventType, dataObjects, eventDate } = trackingEvent;
    let community;
    let model;
    let project;
    let unit;
    let quickPossession;

    if (!dataObjects?.length) return null;

    switch (eventType) {
      case TrackingEventTypes.SELECT_MODEL:
        community = getObjectFromDataObjects(dataObjects, 'Community');
        model = getObjectFromDataObjects(dataObjects, 'Model');

        break;
      case TrackingEventTypes.SELECT_UNIT:
        project = getObjectFromDataObjects(dataObjects, 'Project');
        unit = getObjectFromDataObjects(dataObjects, 'Unit');
        break;
      case TrackingEventTypes.SELECT_QUICK_POSSESSION:
        quickPossession = getObjectFromDataObjects(dataObjects, 'QuickPossession');
        community = quickPossession.community;
        model = quickPossession.model;
        break;
      default:
        break;
    }

    if (community) {
      totalPrice = model?.basePrice;
      beds = model?.beds;
      baths = model?.baths;
      sizeSqft = model?.sizeSqft;

      const isQuickPossession = !!quickPossession;
      singleFamilyHome = {
        community,
        model,
        isQuickPossession,
        quickPossession
      };
    } else if (unit) {
      totalPrice = unit.price;
      const { buildingModel } = unit;
      sizeSqft = buildingModel?.size;
      beds = buildingModel?.bedrooms;
      baths = buildingModel?.bathrooms;
      multiFamilyHome = {
        project,
        unit
      };
    }

    return {
      localId: `viewed-home-${trackingEvent.id}`,
      beds,
      baths,
      sizeSqft,
      totalPrice,
      singleFamilyHome,
      multiFamilyHome,
      interactionType: InterestInteractionTypes.VIEWED,
      lastActive: eventDate
    };
  });

  return viewedHomes.filter((vh) => !!vh);
}

export function sortInterests(interests) {
  return _.sortBy(interests, 'lastActive').reverse();
}
