import React, { useMemo } from 'react';
import { Progress } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './CustomerReservationProgress.module.scss';
import ModalService from '../../../../services/ModalService';
import { CustomerProgressTypes, ModalNames } from '../../../../constants';

const CustomerReservationProgress = ({ customer, wrapInTd }) => {
  const calculateProgress = () => {
    const { isOfferConfirmed, isSignConfirmed, isDepositConfirmed } = customer;
    let value = 0;
    if (isOfferConfirmed) value += 20;
    if (isSignConfirmed) value += 40;
    if (isDepositConfirmed) value += 40;
    return value;
  };

  const onOpenPopover = () => {
    ModalService.open(ModalNames.CUSTOMER_PROGRESS, {
      customerId: customer.id,
      type: CustomerProgressTypes.RESERVATION
    });
  };

  const progress = useMemo(calculateProgress, [customer]);

  const WrapperComponent = wrapInTd ? 'td' : 'div';

  return (
    <WrapperComponent onClick={onOpenPopover} role="button" className="clickable-column">
      <div
        id={`customer-reservation-progress-${customer.id}`}
        aria-hidden
      >
        <Progress color="success" value={progress} className={styles.progress} barClassName={styles.progressBar} />
        <div className="col-gray-700 font-weight-500 mt-1">{`${progress}%`}</div>
      </div>
    </WrapperComponent>
  );
};

CustomerReservationProgress.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  wrapInTd: PropTypes.bool
};

CustomerReservationProgress.defaultProps = {
  wrapInTd: false
};

export default CustomerReservationProgress;
