const fragment = `
  id
`;

export const markCustomerAsViewedMutation = `
  mutation MarkCustomerAsViewed(
    $input: MarkCustomerAsViewedInput!
  ) {
    item: markCustomerAsViewed(input: $input) {
      ${fragment}
    }
  }
`;
