import {
  createDocumentTemplateQuery,
  findDocumentTemplateQuery,
  updateDocumentTemplateQuery
} from '../../../graphql/documentTemplates';
import api from '../../../api';

export function findDocumentTemplateAsync(variables = {}) {
  return api.graphql(findDocumentTemplateQuery, variables)
    .then(({ data: { item } }) => item);
}

export function saveDocumentTemplateAsync(variables = {}) {
  const query = variables.input?.id ? updateDocumentTemplateQuery : createDocumentTemplateQuery;

  return api.graphql(query, variables)
    .then(({ data: { item } }) => item);
}
