import React from 'react';

const PackagesIcon = ({ ...props }) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.9996 6C14.9996 7.06087 14.5782 8.07828 13.828 8.82843C13.0779 9.57857 12.0605 10 10.9996 10C9.93872 10 8.92131 9.57857 8.17116 8.82843C7.42102 8.07828 6.99959 7.06087 6.99959 6M2.63281 5.40138L1.93281 13.8014C1.78243 15.6059 1.70724 16.5082 2.01227 17.2042C2.28027 17.8157 2.74462 18.3204 3.33177 18.6382C4.00006 19 4.90545 19 6.71623 19H15.283C17.0937 19 17.9991 19 18.6674 18.6382C19.2546 18.3204 19.7189 17.8157 19.9869 17.2042C20.2919 16.5082 20.2167 15.6059 20.0664 13.8014L19.3664 5.40138C19.237 3.84875 19.1723 3.07243 18.8285 2.48486C18.5257 1.96744 18.0748 1.5526 17.5341 1.29385C16.92 1 16.141 1 14.583 1L7.41623 1C5.85821 1 5.07921 1 4.4651 1.29384C3.92433 1.5526 3.47349 1.96744 3.17071 2.48486C2.82689 3.07243 2.76219 3.84875 2.63281 5.40138Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

PackagesIcon.propTypes = {};

PackagesIcon.defaultProps = {};

export default PackagesIcon;
