import api from '../../../../../api';
import {
  createBuildingModelImageQuery, listBuildingModelImagesQuery, sortBuildingModelImagesQuery,
  updateBuildingModelImageQuery, deleteBuildingModelImageQuery, updateBuildingModelQuery
} from '../../../../../graphql';

export function createBuildingModelImageAsync(variables) {
  return api.graphql(createBuildingModelImageQuery, variables);
}

export function updateBuildingModelImageAsync(variables) {
  return api.graphql(updateBuildingModelImageQuery, variables);
}

export function updateBuildingModelAsync(variables) {
  return api.graphql(updateBuildingModelQuery, variables);
}

export function listBuildingModelImagesAsync(variables) {
  return api.graphql(listBuildingModelImagesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items))
    .catch(() => Promise.reject());
}

export function sortBuildingModelImagesAsync(variables) {
  return api.graphql(sortBuildingModelImagesQuery, variables);
}

export function deleteBuildingModelImageAsync(variables) {
  return api.graphql(deleteBuildingModelImageQuery, variables);
}
