import moment from 'moment';

import { DateFormats } from '../../../../constants';
import api from '../../../../api';
import { listActivitiesQuery } from '../../../../graphql/activities';

export function groupActivities(activities) {
  const activityDates = [];
  return activities.map((activity) => {
    const formattedDate = moment(activity.createdAt).format(DateFormats.YYYY_MM_DD);
    const hasDate = activityDates.some((activityDate) => activityDate === formattedDate);
    if (!hasDate) activityDates.push(formattedDate);
    return { showDate: !hasDate, ...activity };
  });
}

export async function fetchActivitiesAsync(variables) {
  return api.graphql(listActivitiesQuery, variables)
    .then(({ data: { result } }) => result);
}
