import { uuidv4 } from './randomUtils';

export function generateImageName(image) {
  const extension = image.name.split('.').pop();
  const filename = uuidv4();
  return `${filename}.${extension}`;
}

export function getCommunityImageKey(community) {
  return `communities/${community.id}/${community.image}`;
}

export function getFloorImageKey(floor) {
  return `floors/${floor.id}/${floor.image}`;
}

export function getFloorOptionImageKey(floorOption) {
  return `floorOptions/${floorOption.id}/${floorOption.image}`;
}

export function getFloorOptionAlternateImageKey(floorOption) {
  return `floorOptions/${floorOption.id}/${floorOption.alternateImage}`;
}

export function getElevationImageKey(elevation) {
  return `elevations/${elevation.id}/${elevation.image}`;
}

export function getProductTypeImageKey(productType) {
  return `productTypes/${productType.id}/${productType.image}`;
}

export function getLocalImageUrl(image) {
  return image ? URL.createObjectURL(image) : null;
}
