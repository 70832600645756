import React, { Component } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { getCity } from '../../../../store/actions/cityActions';
import Overview from './Overview';
import Images from './Images';
import { isAdmin } from '../../../../utils/authUtils';
import Modifications from '../../../global/Modifications';
import Loading from '../../../ui/Loading';
import { isDocuSignConnected } from '../../../../utils/companyUtils';
import ReservationDocument from './ReservationDocument';

const i18nOpts = { scope: 'components.admin.cities.show.index' };

const Tabs = Object.freeze({
  OVERVIEW: 'overview',
  IMAGES: 'images',
  MODIFICATIONS: 'modifications',
  RESERVATION_DOCUMENT: 'reservationDocument'
});

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: Tabs.OVERVIEW
    };

    this.onChangeTab = this.onChangeTab.bind(this);
  }

  componentDidMount() {
    this.loadCity();
  }

  onChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  loadCity() {
    const { props } = this;
    const { id: cityId } = this.props;
    const id = parseInt(cityId, 10);

    if (!id) return;

    props.getCity(id)
      .then(() => {})
      .catch(() => navigate('/admin/cities'));
  }

  render() {
    const { activeTab } = this.state;
    const { city, loading } = this.props;

    if (!city || loading) return <Loading loading fullScreen />;

    return (
      <div>
        <Helmet title={city.name} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{city.name}</h2>
          </div>
          <div>
            {isAdmin() && (
              <Link to={`/admin/cities/form?id=${city.id}`} className="btn btn-primary">
                <i className="far fa-edit mr-2" />
                {i18n.t('buttons.edit')}
              </Link>
            )}
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.OVERVIEW ? 'active' : ''}`}
              onClick={() => this.onChangeTab(Tabs.OVERVIEW)}
            >
              {i18n.t('tabs.overview', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.IMAGES ? 'active' : ''}`}
              onClick={() => this.onChangeTab(Tabs.IMAGES)}
            >
              {i18n.t('tabs.images', i18nOpts)}
            </NavLink>
          </NavItem>
          {isDocuSignConnected() && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === Tabs.RESERVATION_DOCUMENT })}
                onClick={() => this.onChangeTab(Tabs.RESERVATION_DOCUMENT)}
              >
                {i18n.t('tabs.reservationDocument', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
          {isAdmin() && (
            <NavItem>
              <NavLink
                className={`${activeTab === Tabs.MODIFICATIONS ? 'active' : ''}`}
                onClick={() => this.onChangeTab(Tabs.MODIFICATIONS)}
              >
                {i18n.t('tabs.modifications', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab} className="py-4">
          <TabPane tabId={Tabs.OVERVIEW}>
            <Overview />
          </TabPane>
          <TabPane tabId={Tabs.IMAGES}>
            <Images />
          </TabPane>
          {isDocuSignConnected() && (
            <TabPane tabId={Tabs.RESERVATION_DOCUMENT}>
              <ReservationDocument />
            </TabPane>
          )}
          {isAdmin() && (
            <TabPane tabId={Tabs.MODIFICATIONS}>
              <Modifications instance={city} />
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  }
}

export default connect((store) => ({
  city: store.cities.city,
  loading: store.cities.getCity.loading
}), {
  getCity
})(Show);
