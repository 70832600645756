import { fakeValue } from '../../../../utils/sampleDataUtils';
import api from '../../../../api';
import { getVerifiedFunnelQuery } from '../../../../graphql';

let width; let height; let gradient;

export function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    gradient.addColorStop(0, '#722B9EB2');
    gradient.addColorStop(0.8, '#00C396B2');
    gradient.addColorStop(0.89, '#00B887B2');
    gradient.addColorStop(1, '#00B37FB2');
  }

  return gradient;
}

export function fetchVerifiedFunnelAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getVerifiedFunnelQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  const invitedCount = fakeValue(100);
  const financialVerifiedCount = fakeValue(invitedCount);
  const data = [
    { name: 'invited', customersCount: invitedCount },
    { name: 'idVerified', customersCount: fakeValue(invitedCount) },
    { name: 'homeVerified', customersCount: financialVerifiedCount },
    { name: 'buyingPower', customersCount: fakeValue(financialVerifiedCount) },
  ];

  return Promise.resolve(data);
}
