import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, FormGroup, Input, Label, Card
} from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

import { parseBoolean } from '../../../../../utils/parserUtils';
import { LotMapProviders } from '../../../../../constants';
import { TagInput } from '../../../../ui';
import InputError, { isInputInvalid } from '../../../../ui/InputError';
import { stripToNull } from '../../../../../utils/stringUtils';

const i18nOpts = { scope: 'components.admin.communities.form.lotMapSettings.index' };

const LotMapSettings = ({ form, onTextChange, error }) => {
  const onSettingsTextChange = (event, group = null, parseToBoolean = false) => {
    const { name, value: val } = event.target;
    let value;
    if (parseToBoolean) value = parseBoolean(val);
    else if (typeof val === 'string') value = stripToNull(val);
    else value = val;

    let { lotMapSettings = {} } = form || {};
    let values = { [name]: value };
    if (!v.isBlank(group)) {
      const groupValues = lotMapSettings[group] || {};
      values = { [group]: { ...groupValues, ...values } };
    }

    lotMapSettings = { ...lotMapSettings, ...values };

    onTextChange({ target: { name: 'lotMapSettings', value: lotMapSettings } });
  };

  const onTagsChanged = (tags) => {
    const values = tags.map((t) => t.displayValue);
    const event = { target: { name: 'mapKeys', value: values } };
    onSettingsTextChange(event, 'streetscape');
  };

  const mapKeyTags = (form.lotMapSettings?.streetscape?.mapKeys || [])
    .map((mk, index) => ({ index, displayValue: mk }));

  const isParameterInvalid = (fieldName) => {
    if (!(error && error?.lotMapSettings
      && Array.isArray(error?.lotMapSettings))) return false;
    const lotMapError = (error.lotMapSettings[0] || '').toLocaleLowerCase();

    return lotMapError.includes(fieldName.toLocaleLowerCase());
  };

  return (
    <>
      <FormGroup check className="mb-3">
        <Label check>
          <Input
            type="checkbox"
            name="enableLotMapIntegration"
            id="enableLotMapIntegration"
            value={!parseBoolean(form.enableLotMapIntegration)}
            checked={parseBoolean(form.enableLotMapIntegration)}
            onChange={onTextChange}
          />
          {i18n.t('enableLotMapIntegration', i18nOpts)}
        </Label>
      </FormGroup>

      <Collapse isOpen={parseBoolean(form.enableLotMapIntegration)}>
        <Card body className="mb-0">
          <FormGroup>
            <Label for="provider">{i18n.t('provider', i18nOpts)}</Label>
            <Input
              type="select"
              name="provider"
              id="provider"
              value={form.lotMapSettings?.provider || ''}
              onChange={onSettingsTextChange}
            >
              <option value="">{i18n.t('select.select')}</option>
              {
                Object.values(LotMapProviders).map((p) => (
                  <option key={`lot-map-provider-${p}`} value={p}>
                    {i18n.t(`lotMapProviders.${v.camelCase(p)}`)}
                  </option>
                ))
              }
            </Input>
          </FormGroup>

          {form.lotMapSettings?.provider === LotMapProviders.LOT_WORKS && (
            <>
              <FormGroup>
                <Label for="mapUrl">{i18n.t('mapUrl', i18nOpts)}</Label>
                <Input
                  type="url"
                  name="mapUrl"
                  id="mapUrl"
                  value={form.lotMapSettings?.lotWorks?.mapUrl || ''}
                  placeholder="https://"
                  onChange={(event) => onSettingsTextChange(event, 'lotWorks')}
                  invalid={isParameterInvalid(i18n.t('mapUrl', i18nOpts), error)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="lotWorksCommunityId">{i18n.t('lotWorksCommunityId', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="communityId"
                  id="lotWorksCommunityId"
                  value={form.lotMapSettings?.lotWorks?.communityId || ''}
                  onChange={(event) => onSettingsTextChange(event, 'lotWorks')}
                  invalid={isParameterInvalid(i18n.t('communityId', i18nOpts), error)}
                />
              </FormGroup>

              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="displaySpecHomes"
                    id="displaySpecHomes"
                    value={!parseBoolean(form.lotMapSettings?.lotWorks?.displaySpecHomes)}
                    checked={parseBoolean(form.lotMapSettings?.lotWorks?.displaySpecHomes)}
                    onChange={(event) => onSettingsTextChange(event, 'lotWorks', true)}
                  />
                  {i18n.t('displaySpecHomes', i18nOpts)}
                </Label>
              </FormGroup>
            </>
          )}

          {form.lotMapSettings?.provider === LotMapProviders.FOCUS_360 && (
            <FormGroup className="mb-0">
              <Label for="communityId">{i18n.t('communityId', i18nOpts)}</Label>
              <Input
                type="text"
                name="communityId"
                id="communityId"
                value={form.lotMapSettings?.focus360?.communityId || ''}
                onChange={(event) => onSettingsTextChange(event, 'focus360')}
                invalid={isParameterInvalid(i18n.t('communityId', i18nOpts), error)}
              />
            </FormGroup>
          )}

          {form.lotMapSettings?.provider === LotMapProviders.STREETSCAPE && (
            <>
              <FormGroup>
                <Label for="propertyId">{i18n.t('propertyId', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="propertyId"
                  id="propertyId"
                  value={form.lotMapSettings?.streetscape?.propertyId || ''}
                  onChange={(event) => onSettingsTextChange(event, 'streetscape')}
                  invalid={isParameterInvalid(i18n.t('propertyId', i18nOpts), error)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="builderId">{i18n.t('builderId', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="builderId"
                  id="builderId"
                  value={form.lotMapSettings?.streetscape?.builderId || ''}
                  onChange={(event) => onSettingsTextChange(event, 'streetscape')}
                  invalid={isParameterInvalid(i18n.t('builderId', i18nOpts), error)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="mapKeys">{i18n.t('mapKeys', i18nOpts)}</Label>
                <TagInput
                  class="tag-input-map-keys"
                  tags={mapKeyTags}
                  onTagsChanged={onTagsChanged}
                  placeholder={i18n.t('mapKeysPlaceholder', i18nOpts)}
                  invalid={isParameterInvalid(i18n.t('mapKeys', i18nOpts), error)}
                />
              </FormGroup>

              <FormGroup check className="mb-2">
                <Label check>
                  <Input
                    type="checkbox"
                    name="hideLotPrice"
                    id="hideLotPrice"
                    value={!parseBoolean(form.lotMapSettings?.streetscape?.hideLotPrice)}
                    checked={parseBoolean(form.lotMapSettings?.streetscape?.hideLotPrice)}
                    onChange={(event) => onSettingsTextChange(event, 'streetscape', true)}
                  />
                  {i18n.t('hideLotPrice', i18nOpts)}
                </Label>
              </FormGroup>

              <FormGroup check className="mb-0">
                <Label check>
                  <Input
                    type="checkbox"
                    name="displaySpecHomes"
                    id="displaySpecHomes"
                    value={!parseBoolean(form.lotMapSettings?.streetscape?.displaySpecHomes)}
                    checked={parseBoolean(form.lotMapSettings?.streetscape?.displaySpecHomes)}
                    onChange={(event) => onSettingsTextChange(event, 'streetscape', true)}
                  />
                  {i18n.t('displaySpecHomes', i18nOpts)}
                </Label>
              </FormGroup>
            </>
          )}
        </Card>
      </Collapse>

      <FormGroup>
        <Input
          hidden
          type="text"
          name="lotMapSettings"
          id="lotMapSettings"
          invalid={isInputInvalid(error, 'lotMapSettings')}
        />

        <InputError error={error} name="lotMapSettings" />
      </FormGroup>
    </>
  );
};

LotMapSettings.propTypes = {
  form: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func,
  error: PropTypes.objectOf(PropTypes.any)
};

LotMapSettings.defaultProps = {
  form: {},
  onTextChange: () => {},
  error: null
};

export default LotMapSettings;
