import React from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import SortableColumnHeader from '../../../../../common/SortableColumnHeader';
import { CustomerSortFields, SortOrders } from '../../../../../../constants';
import { setCustomersSortOrder, setCustomersSortField } from '../../../../../../store/actions/customerActions';
import useSortOptions from '../hooks/useSortOptions';
import useHomeRental from '../../../../../../hooks/useHomeRental';
import CustomColumnName from './CustomColumnName';
import useValidSelectedColumns from '../../hooks/useValidSelectedColumns';
import useIsVerifiedEnabled from '../../../../../../hooks/useIsVerifiedEnabled';
import useIsReserveNowEnabled from '../../../../../../hooks/useIsReserveNowEnabled';

const i18nOpts = { scope: 'components.admin.customers.list.listView.listHeader.index' };

const ListHeader = ({
  sortField, sortOrder, currentCompany, ...props
}) => {
  const sort = useSortOptions();
  const { isRental, typeI18nKey } = useHomeRental();
  const columns = useValidSelectedColumns();
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const onSortChange = (newSortField) => {
    const nextSortOrder = sortOrder === SortOrders.ASC ? SortOrders.DESC : SortOrders.ASC;
    props.setCustomersSortField(newSortField);
    props.setCustomersSortOrder(nextSortOrder);
  };

  return (
    <tr>
      <SortableColumnHeader
        field={CustomerSortFields.NAME}
        label={i18n.t(`${typeI18nKey}.customer`, i18nOpts)}
        sort={sort}
        onPress={() => onSortChange(CustomerSortFields.NAME)}
        extraClass="fixed-column"
      />
      {isVerifiedEnabled && (
        <SortableColumnHeader
          field={CustomerSortFields.VERIFICATION_PROGRESS}
          label={i18n.t('verificationProgress', i18nOpts)}
          sort={sort}
          onPress={() => onSortChange(CustomerSortFields.VERIFICATION_PROGRESS)}
        />
      )}
      {isReserveNowEnabled && (
        <SortableColumnHeader
          field={CustomerSortFields.RESERVATION_PROGRESS}
          label={i18n.t('reservationProgress', i18nOpts)}
          sort={sort}
          onPress={() => onSortChange(CustomerSortFields.RESERVATION_PROGRESS)}
        />
      )}
      {isVerifiedEnabled && (
        <>
          {isRental ? (
            <SortableColumnHeader
              field={CustomerSortFields.RENTAL_POWER}
              label={i18n.t('rentingPower', i18nOpts)}
              sort={sort}
              onPress={() => onSortChange(CustomerSortFields.RENTAL_POWER)}
            />
          ) : (
            <SortableColumnHeader
              field={CustomerSortFields.BUYING_POWER}
              label={i18n.t('buyingPower', i18nOpts)}
              sort={sort}
              onPress={() => onSortChange(CustomerSortFields.BUYING_POWER)}
            />
          )}
        </>
      )}
      <SortableColumnHeader
        field={CustomerSortFields.BROWSING_PRICE}
        label={i18n.t('browsingPrice', i18nOpts)}
        sort={sort}
        onPress={() => onSortChange(CustomerSortFields.BROWSING_PRICE)}
      />
      <SortableColumnHeader
        field={CustomerSortFields.SIGN_UP_DATE}
        label={i18n.t('signUpDate', i18nOpts)}
        sort={sort}
        onPress={() => onSortChange(CustomerSortFields.SIGN_UP_DATE)}
      />
      <SortableColumnHeader
        field={CustomerSortFields.LAST_ACTIVE}
        label={i18n.t('lastActive', i18nOpts)}
        sort={sort}
        onPress={() => onSortChange(CustomerSortFields.LAST_ACTIVE)}
      />
      {
        columns.map((column) => (
          <CustomColumnName
            key={`customer-header-${column}`}
            column={column}
            sort={sort}
            onSortChange={onSortChange}
          />
        ))
      }
    </tr>
  );
};

ListHeader.propTypes = {};

ListHeader.defaultProps = {};

export default connect((store) => ({
  sortField: store.customers.sortField,
  sortOrder: store.customers.sortOrder,
  currentCompany: store.companies.currentCompany
}), {
  setCustomersSortOrder,
  setCustomersSortField
})(ListHeader);
