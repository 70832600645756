import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import i18n from 'i18n-js';

import './styles.scss';
import { Tabs } from '../utils';

const i18nOpts = { scope: 'components.admin.integrations.tracking.trackingCode.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <Nav vertical pills className="tracking-code-nav-tabs">
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.INSTALLATION })}
        onClick={() => onChange(Tabs.INSTALLATION)}
      >
        {i18n.t('installation', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.JAVASCRIPT_SDK })}
        onClick={() => onChange(Tabs.JAVASCRIPT_SDK)}
      >
        {i18n.t('javaScriptSdk', i18nOpts)}
      </NavLink>
    </NavItem>
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
