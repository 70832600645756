import {
  CLEAN_MORTGAGE_PROVIDERS,
  DELETE_MORTGAGE_PROVIDER,
  GET_MORTGAGE_PROVIDER,
  LIST_MORTGAGE_PROVIDERS,
  SAVE_MORTGAGE_PROVIDER,
  SET_RELOAD_MORTGAGE_PROVIDERS
} from './types';
import api from '../../api';
import {
  createMortgageProviderQuery,
  deleteMortgageProviderQuery,
  getMortgageProviderQuery,
  listMortgageProvidersQuery,
  updateMortgageProviderQuery
} from '../../graphql/mortgageProviders';

export function listMortgageProviders(variables = {}) {
  return {
    type: LIST_MORTGAGE_PROVIDERS,
    payload: api.graphql(listMortgageProvidersQuery, variables)
  };
}

export function getMortgageProvider(id) {
  return {
    type: GET_MORTGAGE_PROVIDER,
    payload: api.graphql(getMortgageProviderQuery, { id })
  };
}

export function cleanMortgageProviders(extraProps = {}) {
  return {
    type: CLEAN_MORTGAGE_PROVIDERS,
    payload: Promise.resolve(extraProps)
  };
}

export function saveMortgageProvider(variables = {}) {
  const query = variables.input.id ? updateMortgageProviderQuery : createMortgageProviderQuery;
  return {
    type: SAVE_MORTGAGE_PROVIDER,
    payload: api.graphql(query, variables)
  };
}

export function deleteMortgageProvider(id) {
  return {
    type: DELETE_MORTGAGE_PROVIDER,
    payload: api.graphql(deleteMortgageProviderQuery, { id })
  };
}

export function setReloadMortgageProviders(reload) {
  return {
    type: SET_RELOAD_MORTGAGE_PROVIDERS,
    payload: { reload }
  };
}
