import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

const Address = ({ quickPossession }) => {
  const { address } = quickPossession;

  if (v.isBlank(address)) return null;

  return (
    <div className="text-nowrap">
      {address}
    </div>
  );
};

Address.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Address.defaultProps = {};

export default Address;
