import React from 'react';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import { IoMdStats } from 'react-icons/io';

const i18nOpts = { scope: 'components.common.reportEmpty.index' };

const ReportEmpty = ({ loading, items }) => {
  if (loading || items.length > 0) return null;

  return (
    <div className="text-center">
      <IoMdStats size="36" className="text-muted op-3 mb-2" />
      <div className="text-muted">
        {i18n.t('noData', i18nOpts)}
      </div>
    </div>
  );
};

ReportEmpty.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object)
};

ReportEmpty.defaultProps = {
  loading: false,
  items: []
};

export default ReportEmpty;
