import React from 'react';
import { connect } from 'react-redux';

import Placeholder from './Placeholder';
import InfoCard from './InfoCard';

const HomeValuationCard = ({ customer }) => {
  const { isHomeVerified } = customer;

  return (
    <div>
      {isHomeVerified ? (
        <InfoCard />
      ) : (
        <Placeholder />
      )}
    </div>
  );
};

HomeValuationCard.propTypes = {};

HomeValuationCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
}))(HomeValuationCard);
