import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import api from '../../../../../../api';
import { saveModelPalette } from '../../../../../../store/actions/modelPaletteActions';
import AddItemDropdown from '../../../../../common/AddItemDropdown';

const i18nOpts = { scope: 'components.admin.models.show.palettes.addDropDown.index' };

function fetchPalettesAsync(companyId) {
  const variables = { filter: { companyId, isMultifamily: false } };

  return api.graphql(`
    query ListPalettes($filter: PaletteFilterInput) {
      items: listPalettes(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

const AddDropDown = ({
  model, modelPalettes, currentCompany, ...props
}) => {
  const [packages, setPalettes] = useState([]);
  const [isDropdownOpen, onToggleDropdown] = useState(false);

  const loadPalettes = () => {
    fetchPalettesAsync(currentCompany.id)
      .then((items) => setPalettes(items))
      .catch(() => {});
  };

  const onSelect = (paletteId) => {
    const input = { modelId: model.id, paletteId };
    props.saveModelPalette({ input });
  };

  useEffect(() => {
    loadPalettes();
  }, []);

  const availablePalettes = packages.filter(
    (p) => modelPalettes.findIndex((mp) => mp.palette.id === p.id) === -1
  );

  return (
    <AddItemDropdown
      open={isDropdownOpen}
      items={availablePalettes}
      toggleDropdown={onToggleDropdown}
      onSelect={onSelect}
      label={i18n.t('title', i18nOpts)}
    />
  );
};

AddDropDown.propTypes = {};

AddDropDown.defaultProps = {};

export default connect((store) => ({
  model: store.models.model,
  modelPalettes: store.modelPalettes.modelPalettes,
  currentCompany: store.companies.currentCompany
}), {
  saveModelPalette
})(AddDropDown);
