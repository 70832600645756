import React, { useEffect, useState } from 'react';
import {
  Button, Col, Collapse, FormGroup, Input, Label, Row, Spinner
} from 'reactstrap';
import { BiLinkExternal, BiTrash } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import v from 'voca';

import { DocumentTemplateAssignableEntities, DocumentTemplateTypes } from '../../../constants';
import { findDocumentTemplateAsync, saveDocumentTemplateAsync } from './utils';
import useForm from '../../../hooks/useForm';
import { parseBoolean } from '../../../utils/parserUtils';
import InputError, { isInputInvalid } from '../../ui/InputError';
import FileTypeIcon from '../../common/FileTypeIcon';
import DocumentFile from '../../common/DocumentUploader/DocumentFile';
import { uuidv4 } from '../../../utils/randomUtils';
import { createTemplateViewAsync, fetchDocuSignTemplatesAsync } from '../../../utils/docuSignUtils';
import { getError } from '../../../utils/requestUtils';
import { stripToNull } from '../../../utils/stringUtils';

const i18nOpts = { scope: 'components.global.reservationDocumentManagement.index' };

const DocumentTemplate = ({ templateUrl }) => {
  if (v.isBlank(templateUrl)) return null;

  return (
    <a
      href={templateUrl}
      target="_blank"
      rel="noreferrer"
      className="d-flex align-items-center mb-2"
    >
      <span className="mr-1">{i18n.t('buttons.editTemplate', i18nOpts)}</span>
      <BiLinkExternal size="1.1rem" />
    </a>
  );
};

const ReservationDocumentManagement = ({ entityId, entityType, currentCompany }) => {
  const {
    form, setForm, onTextChange: formOnTextChange, error, setError, submitting, setSubmitting
  } = useForm();
  const [documentToSign, setDocumentToSign] = useState(null);
  const [newDocumentToSign, setNewDocumentToSign] = useState(null);
  const [documentTemplateUrl, setDocumentTemplateUrl] = useState(null);
  const [templates, setTemplates] = useState([]);

  const onTextChange = (event) => {
    const { name } = event.target;
    if (name === 'type') onDeleteDocumentToSign();
    formOnTextChange(event);
  };

  const loadDocumentTemplate = () => {
    const variables = { entityId, entityType };
    findDocumentTemplateAsync(variables)
      .then((item) => {
        setForm(item);
        setDocumentToSign(item.document);
      })
      .catch(() => {
        setForm({
          enabled: false,
          type: DocumentTemplateTypes.UPLOAD_DOCUMENT,
          templateName: i18n.t('defaults.templateName', i18nOpts),
          emailSubject: i18n.t('defaults.emailSubject', i18nOpts)
        });
      });
  };

  const loadTemplates = () => {
    const variables = { companyId: currentCompany.id };
    fetchDocuSignTemplatesAsync(variables)
      .then((items) => setTemplates(items))
      .catch(() => setTemplates([]));
  };

  const onNewDocumentToSignChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setNewDocumentToSign({
      id: uuidv4(),
      file
    });
  };

  const onDocumentToSignUploaded = (document) => {
    setDocumentToSign(document);
  };

  const onDeleteDocumentToSign = () => {
    setDocumentToSign(null);
    setNewDocumentToSign(null);
    setForm((prevForm) => ({ ...prevForm, documentId: null, templateId: null }));
  };

  const createTemplateView = (templateId) => {
    setDocumentTemplateUrl(null);
    if (v.isBlank(templateId)) return;

    const variables = { companyId: currentCompany.id, templateId };
    createTemplateViewAsync(variables)
      .then((templateUrl) => setDocumentTemplateUrl(templateUrl));
  };

  const onSave = () => {
    setSubmitting(true);

    const isNew = !form.id;
    const enabled = parseBoolean(form.enabled);
    const input = {
      ...(isNew) ? { entityId, entityType } : { id: form.id },
      enabled,
      type: form.type,
      templateName: stripToNull(form.templateName),
      emailSubject: stripToNull(form.emailSubject),
      ...(enabled ? {
        ...(form.type === DocumentTemplateTypes.UPLOAD_DOCUMENT ? {
          documentId: documentToSign?.id,
          templateId: null
        } : {
          templateId: stripToNull(form.templateId),
          documentId: null
        })
      } : {})
    };
    const variables = { companyId: currentCompany.id, input };
    saveDocumentTemplateAsync(variables)
      .then((item) => {
        setForm(item);
        setError(null);
        toast.success(i18n.t('messages.saved', i18nOpts));
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    createTemplateView(form.templateId);
  }, [form.templateId]);

  useEffect(() => {
    loadDocumentTemplate();
    loadTemplates();
  }, []);

  return (
    <Row>
      <Col xs="12" sm="10" md="8" lg="6">
        <FormGroup check className="mb-3">
          <Label check>
            <Input
              type="checkbox"
              name="enabled"
              id="enabled"
              value={!parseBoolean(form.enabled)}
              checked={parseBoolean(form.enabled)}
              onChange={onTextChange}
            />
            {i18n.t('overrideReservationDocument', i18nOpts)}
          </Label>
        </FormGroup>

        <Collapse isOpen={parseBoolean(form.enabled)} className="pl-4">
          <div className="d-flex mb-3">
            <FormGroup check>
              <Label for="uploadDocument">
                <Input
                  type="radio"
                  name="type"
                  id="uploadDocument"
                  checked={form.type === DocumentTemplateTypes.UPLOAD_DOCUMENT}
                  value={DocumentTemplateTypes.UPLOAD_DOCUMENT}
                  onChange={onTextChange}
                />
                {i18n.t('uploadDocument', i18nOpts)}
              </Label>
            </FormGroup>

            <FormGroup check className="ml-3">
              <Label for="selectTemplate">
                <Input
                  type="radio"
                  name="type"
                  id="selectTemplate"
                  checked={form.type === DocumentTemplateTypes.SELECT_TEMPLATE}
                  value={DocumentTemplateTypes.SELECT_TEMPLATE}
                  onChange={onTextChange}
                />
                {i18n.t('selectTemplate', i18nOpts)}
              </Label>
            </FormGroup>
          </div>

          {form.type === DocumentTemplateTypes.UPLOAD_DOCUMENT && (
            <>
              <FormGroup>
                <Label for="templateName">{i18n.t('templateName', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="templateName"
                  id="templateName"
                  value={form.templateName || ''}
                  onChange={onTextChange}
                  invalid={isInputInvalid(error, 'templateName')}
                />
                <InputError name="templateName" error={error} />
              </FormGroup>

              <FormGroup>
                <Label for="emailSubject">{i18n.t('emailSubject', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="emailSubject"
                  id="emailSubject"
                  value={form.emailSubject || ''}
                  onChange={onTextChange}
                  invalid={isInputInvalid(error, 'emailSubject')}
                />
                <InputError name="emailSubject" error={error} />
              </FormGroup>

              <FormGroup>
                <Label className="d-block">{i18n.t('documentToSign', i18nOpts)}</Label>
                {documentToSign && (
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <div className="d-flex align-items-center">
                        <FileTypeIcon filename={documentToSign.name} size="sm" />
                        <a href={documentToSign.fileUrl} target="_blank" rel="noreferrer">
                          {documentToSign.name}
                        </a>
                      </div>

                      <BiTrash
                        size="1.1rem"
                        role="button"
                        className="text-danger ml-1"
                        title={i18n.t('buttons.delete')}
                        onClick={onDeleteDocumentToSign}
                      />
                    </div>

                    <DocumentTemplate templateUrl={documentTemplateUrl} />
                  </>
                )}

                <Label for="documentId" className="btn btn-sm btn-primary">
                  <i className="fas fa-plus mr-2" />
                  {i18n.t('buttons.uploadFile', i18nOpts)}
                </Label>

                <Input
                  type="file"
                  name="documentId"
                  id="documentId"
                  onChange={onNewDocumentToSignChange}
                  className="d-none"
                  invalid={isInputInvalid(error, 'documentId')}
                />
                <InputError name="documentId" error={error} />
              </FormGroup>

              {newDocumentToSign && (
                <DocumentFile
                  documentFile={newDocumentToSign}
                  key={`document-file-${newDocumentToSign.id}`}
                  completeDelay={1000}
                  excluded
                  onUploaded={onDocumentToSignUploaded}
                />
              )}
            </>
          )}

          {form.type === DocumentTemplateTypes.SELECT_TEMPLATE && (
            <>
              <FormGroup>
                <Label for="templateId">{i18n.t('templateId', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="templateId"
                  id="templateId"
                  value={form.templateId || ''}
                  onChange={onTextChange}
                  invalid={isInputInvalid(error, 'templateId')}
                >
                  <option value="">{i18n.t('select.select')}</option>
                  {
                    templates.map((template) => (
                      <option key={`template-option-${template.templateId}`} value={template.templateId}>
                        {template.name}
                      </option>
                    ))
                  }
                </Input>
                <InputError name="templateId" error={error} />
              </FormGroup>

              <DocumentTemplate templateUrl={documentTemplateUrl} />
            </>
          )}
        </Collapse>

        <Button color="primary" onClick={onSave} disabled={submitting} className="mt-4">
          {submitting && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
      </Col>
    </Row>
  );
};

ReservationDocumentManagement.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.oneOf(Object.values(DocumentTemplateAssignableEntities)).isRequired
};

ReservationDocumentManagement.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {})(ReservationDocumentManagement);
