import React, { Component } from 'react';

class Vector extends Component {
  getStyle() {
    const { object } = this.props;
    return {
      mixblendmode: object.blendmode
    };
  }

  getTransformMatrix({
    rotate, x, y, width, height
  }) {
    if (rotate) {
      const centerX = width / 2 + x;
      const centerY = height / 2 + y;
      return `rotate(${rotate} ${centerX} ${centerY})`;
    }
  }

  getObjectAttributes() {
    const { object, onRender, ...rest } = this.props;
    const parsedObject = { ...object };

    delete parsedObject.unit;

    return {
      ...parsedObject,
      transform: this.getTransformMatrix(object),
      ref: onRender,
      ...rest
    };
  }
}

export default Vector;
