import {
  SAVE_FLOOR_OPTION, DELETE_FLOOR_OPTION
} from './types';
import {
  createFloorOptionQuery, deleteFloorOptionQuery, updateFloorOptionQuery
} from '../../graphql';
import api from '../../api';

export function saveFloorOption(variables = {}) {
  const query = variables.input.id ? updateFloorOptionQuery : createFloorOptionQuery;
  return {
    type: SAVE_FLOOR_OPTION,
    payload: api.graphql(query, variables)
  };
}

export function deleteFloorOption(id) {
  return {
    type: DELETE_FLOOR_OPTION,
    payload: api.graphql(deleteFloorOptionQuery, { id })
  };
}
