import React, { useEffect } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import Details from './Details';
import Form from './Form';
import DownloadTemplate from './DownloadTemplate';
import { cleanImportedData } from '../../../../store/actions/importExportActions';
import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';

const i18nOpts = { scope: 'components.admin.integrations.importData.index' };

const ImportData = ({ ...props }) => {
  useEffect(() => {
    props.cleanImportedData();
  }, []);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

      <Row>
        <Col xs="12" sm="12" md="6" lg="6">
          <Form />
        </Col>
        <Col xs="12" sm="12" md="6" lg="6" className="pt-5 pt-md-0">
          <DownloadTemplate />
        </Col>
      </Row>

      <Details />
    </div>
  );
};

export default connect((store) => ({
}), {
  cleanImportedData
})(ImportData);
