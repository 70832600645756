import React from 'react';

import HomeValuation from './HomeValuation';
import PreApproval from './PreApproval';

const AdditionalInfo = () => (
  <div className="mt-4">
    <HomeValuation />
    <PreApproval />
  </div>
);

AdditionalInfo.propTypes = {};

AdditionalInfo.defaultProps = {};

export default AdditionalInfo;
