import React from 'react';

const HomeSavedIcon = ({ ...props }) => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.14404 11.1446C7.14404 8.74397 7.14404 7.54368 7.61123 6.62677C8.02218 5.82024 8.67791 5.16451 9.48444 4.75356C10.4013 4.28638 11.6016 4.28638 14.0022 4.28638H20.2889C22.6895 4.28638 23.8898 4.28638 24.8067 4.75356C25.6132 5.16451 26.2689 5.82024 26.6799 6.62677C27.1471 7.54368 27.1471 8.74397 27.1471 11.1446V30.0046L17.1456 24.2894L7.14404 30.0046V11.1446Z" stroke="#00A572" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

HomeSavedIcon.propTypes = {};

HomeSavedIcon.defaultProps = {};

export default HomeSavedIcon;
