export const Menus = Object.freeze({
  SETTINGS: 'settings',
  SINGLE_FAMILY: 'singleFamily',
  MULTI_FAMILY: 'multiFamily',
  SALES: 'sales',
  HOMES: 'homes',
});
export const MAPPED_SUBMENUS = Object.freeze({
  [Menus.SETTINGS]: ['/admin/companies', '/admin/users', '/admin/mortgageRate', '/admin/taxRates', '/admin/design', '/admin/cities', '/admin/salesRepresentatives', '/admin/propertyTaxRates'],
  [Menus.SINGLE_FAMILY]: ['/admin/communities', '/admin/productTypes', '/admin/models', '/admin/palettes', '/admin/packages', '/admin/quickPossessions'],
  [Menus.MULTI_FAMILY]: ['/admin/projects', '/admin/projectBuildings', '/admin/buildingModels', '/admin/units', '/admin/optionTypes', '/admin/multifamily/palettes', '/admin/multifamily/packages'],
  [Menus.SALES]: ['/admin/sales/deals', '/admin/sales/contracts', '/admin/deposits']
});

export function getCurrentMenu(pathname) {
  for (const [menu, pathNames] of Object.entries(MAPPED_SUBMENUS)) {
    const found = pathNames.some((sm) => pathname.startsWith(sm));
    if (found) return menu;
  }

  return null;
}

export function getAPIDocUrl() {
  return new URL('/docs/api', process.env.GATSBY_SERVER_URL).toString();
}
