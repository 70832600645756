import React from 'react';
import PropTypes from 'prop-types';

import ListItem from './ListItem';
import { getUnionTypeObjectKey } from '../../../../../../utils/objectUtils';

const ListView = ({ homes, onSelect }) => {
  if (!homes.length) return null;

  return (
    <div className="d-flex flex-column gap-3 p-3">
      {
        homes.map((home) => (
          <ListItem
            key={`home-${getUnionTypeObjectKey(home)}`}
            home={home}
            onSelect={onSelect}
          />
        ))
      }
    </div>
  );
};

ListView.propTypes = {
  homes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onSelect: PropTypes.func
};

ListView.defaultProps = {
  onSelect: () => {}
};

export default ListView;
