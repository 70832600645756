import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import v from 'voca';

import FilterCheckbox from '../../../../../../common/FilterCheckbox';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';

const i18nOpts = { scope: 'components.admin.sales.components.dealList.filtersModal.realtorFilter.index' };

const RealtorFilter = ({ filters, onChange }) => {
  const { isUsingRealtor } = filters;

  const onToggle = (event) => {
    const { value } = event.target;
    let newValue = value;
    if (!v.isBlank(value) && value === isUsingRealtor) newValue = null;
    onChange({ isUsingRealtor: newValue });
  };

  const filtersCount = useMemo(() => (['true', 'false'].includes(isUsingRealtor) ? 1 : 0),
    [isUsingRealtor]);

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <FilterCheckbox
        label={i18n.t('buttons.yes')}
        value="true"
        checked={isUsingRealtor === 'true'}
        onChange={onToggle}
      />
      <FilterCheckbox
        label={i18n.t('buttons.no')}
        value="false"
        checked={isUsingRealtor === 'false'}
        onChange={onToggle}
      />
    </CollapsibleFilterGroup>
  );
};

RealtorFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

RealtorFilter.defaultProps = {
  onChange: () => {}
};

export default RealtorFilter;
