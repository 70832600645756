import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import { isAdmin } from '../../../../utils/authUtils';
import { getBuildingModels, setBuildingModelsViewMode } from '../../../../store/actions/buildingModelActions';
import ListEmpty from '../../../common/ListEmpty';
import ProjectBuildingFilter from './ProjectBuildingFilter';
import { fetchBuildingModelModelsAsync, filterBuildingModels } from './utils';
import Loading from '../../../ui/Loading';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import GridView from './GridView';
import ListView from './ListView';
import { ViewModes } from '../../../../constants';

const i18nOpts = { scope: 'components.admin.buildingModels.list.index' };

const List = ({
  buildingModels, loading, viewMode, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [filters, setFilters] = useState({});
  const [buildingModelModels, setBuildingModelModels] = useState([]);

  const loadBuildingModels = () => {
    const variables = { filter: { companyId: currentCompany.id, ...filters } };
    props.getBuildingModels(variables)
      .catch(() => {})
      .finally(() => setMounted(true));
  };

  const loadBuildingModelModels = () => {
    fetchBuildingModelModelsAsync(filters)
      .then((items) => setBuildingModelModels(items))
      .catch((e) => toast.error(e.message));
  };

  const getAvailableBuildingModels = () => filterBuildingModels(filters, buildingModels, buildingModelModels);

  const onFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const onChangeViewMode = (newViewMode) => {
    props.setBuildingModelsViewMode(newViewMode);
  };

  useEffect(() => {
    loadBuildingModelModels();
    loadBuildingModels();
  }, [filters]);

  const filteredBuildingModels = getAvailableBuildingModels();

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div className="d-flex justify-content-end align-items-center gap-3">
          <ProjectBuildingFilter onChange={onFilterChange} />

          <ViewModesDropdown viewMode={viewMode} onChange={onChangeViewMode} />

          {isAdmin() && (
            <Link to="/admin/buildingModels/form" className="btn btn-primary">
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Link>
          )}
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={filteredBuildingModels} />

          {filteredBuildingModels.length > 0 && (
            <div className="mb-4">
              {viewMode === ViewModes.GRID ? (
                <GridView buildingModels={filteredBuildingModels} />
              ) : (
                <ListView buildingModels={filteredBuildingModels} />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  buildingModels: store.buildingModels.buildingModels,
  loading: store.buildingModels.getBuildingModels.loading || false,
  viewMode: store.buildingModels.viewMode,
  currentCompany: store.companies.currentCompany
}), {
  getBuildingModels,
  setBuildingModelsViewMode
})(List);
