import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Country, State, City } from 'country-state-city';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import v from 'voca';
import _ from 'lodash';

import InputError, { isInputInvalid } from '../InputError';

const i18nOpts = { scope: 'components.ui.locationSelector.index' };

const LocationSelector = ({
  defaultCountryCode, defaultStateCode, defaultCity, onLocationChange, errors
}) => {
  const [countryCode, setCountryCode] = useState(defaultCountryCode || '');
  const [stateCode, setStateCode] = useState(defaultStateCode || '');
  const [city, setCity] = useState(defaultCity || '');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [location, setLocation] = useState({});

  const onCountryChange = (event) => {
    const { value, selectedOptions } = event.target;

    setCountryCode(value);
    setCities([]);
    setStateCode('');
    setCity('');

    setLocation((prevLocation) => (
      {
        ...prevLocation,
        countryCode: value,
        country: !v.isBlank(value) ? selectedOptions[0].text : '',
        stateCode: '',
        state: '',
        city: ''
      }
    ));
  };

  const onStateChange = (event) => {
    const { value, selectedOptions } = event.target;

    setStateCode(value);
    setCity('');

    setLocation((prevLocation) => (
      {
        ...prevLocation,
        stateCode: value,
        state: !v.isBlank(value) ? selectedOptions[0].text : '',
        city: ''
      }
    ));
  };

  const onCityChange = (event) => {
    const { value } = event.target;
    setCity(value);
    setLocation((prevLocation) => (
      { ...prevLocation, city: value }
    ));
  };

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    setStates(State.getStatesOfCountry(countryCode));
  }, [countryCode]);

  useEffect(() => {
    setCities(City.getCitiesOfState(countryCode, stateCode));
  }, [stateCode]);

  useEffect(() => {
    if (!_.isEmpty(location)) onLocationChange(location);
  }, [location]);

  return (
    <>
      <FormGroup>
        <Label for="country">{i18n.t('country', i18nOpts)}</Label>
        <Input
          type="select"
          name="country"
          id="country"
          value={countryCode}
          onChange={onCountryChange}
          invalid={isInputInvalid(errors, 'country')}
        >
          <option value="">{i18n.t('select.select')}</option>
          {
            countries.map((country) => (
              <option key={`country-${country.isoCode}`} value={country.isoCode}>{country.name}</option>
            ))
          }
        </Input>
        <InputError name="country" error={errors} />
      </FormGroup>

      <FormGroup>
        <Label for="state">{i18n.t('state', i18nOpts)}</Label>
        <Input
          type="select"
          name="state"
          id="state"
          value={stateCode}
          onChange={onStateChange}
          invalid={isInputInvalid(errors, 'state')}
        >
          <option value="">{i18n.t('select.select')}</option>
          {
            states.map((state) => (
              <option key={`state-${state.isoCode}`} value={state.isoCode}>{state.name}</option>
            ))
          }
        </Input>
        <InputError name="state" error={errors} />
      </FormGroup>

      <FormGroup>
        <Label for="city">{i18n.t('city', i18nOpts)}</Label>
        <Input
          type="select"
          name="city"
          id="city"
          value={city}
          onChange={onCityChange}
          invalid={isInputInvalid(errors, 'city')}
        >
          <option value="">{i18n.t('select.select')}</option>
          {
            cities.map((c) => (
              <option key={`city-${c.name}`} value={c.name}>{c.name}</option>
            ))
          }
        </Input>
        <InputError name="city" error={errors} />
      </FormGroup>
    </>
  );
};

LocationSelector.propTypes = {
  defaultCountryCode: PropTypes.string,
  defaultStateCode: PropTypes.string,
  defaultCity: PropTypes.string,
  onLocationChange: PropTypes.func
};

LocationSelector.defaultProps = {
  defaultCountryCode: '',
  defaultStateCode: '',
  defaultCity: '',
  onLocationChange: () => {}
};

export default LocationSelector;
