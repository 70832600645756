import React, { useState } from 'react';
import { connect } from 'react-redux';

import { saveBuildingModelPackage } from '../../../../../../store/actions/buildingModelPackagesActions';
import AddItemDropdown from '../../../../../common/AddItemDropdown';

const PackagesDropdown = ({
  buildingModel, buildingModelPackages, loading, ...props
}) => {
  const [open, toggleDropdown] = useState(false);
  if (!buildingModel) return null;

  const { project: { packages = [] } } = buildingModel;

  const onSelect = (packageId) => {
    const variables = { input: { packageId, buildingModelId: buildingModel.id } };
    props.saveBuildingModelPackage(variables);
  };

  const filteredPackages = packages.filter(
    (p) => !buildingModelPackages.find((fpp) => p.id === fpp.package.id)
  );

  return (
    <AddItemDropdown
      open={open}
      items={filteredPackages}
      toggleDropdown={toggleDropdown}
      onSelect={onSelect}
    />
  );
};

export default connect((store) => ({
  buildingModel: store.buildingModels.buildingModel,
  buildingModelPackages: store.buildingModelPackages.buildingModelPackages,
  loading: store.buildingModelPackages.saveBuildingModelPackage?.loading || false
}), {
  saveBuildingModelPackage
})(PackagesDropdown);
