import React from 'react';
import { connect } from 'react-redux';

import MortgageProvidersManagement from '../../../../global/MortgageProvidersManagement';
import { MortgageProviderAssignableTypes } from '../../../../../constants';

const MortgageProviders = ({ project }) => (
  <MortgageProvidersManagement
    assignableId={project.id}
    assignableType={MortgageProviderAssignableTypes.PROJECT}
  />
);

MortgageProviders.propTypes = {};

MortgageProviders.defaultProps = {};

export default connect((store) => ({
  project: store.projects.project,
}))(MortgageProviders);
