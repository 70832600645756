import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, ModalBody, ModalFooter, Row, Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

import QuickPossessionForm from '../../../../../global/QuickPossessionForm';

const i18nOpts = { scope: 'components.admin.quickPossessions.components.quickPossessionFormModal.form.index' };

const Form = ({
  form, setForm, error, onTextChange, isValidForm, submitting, onContinue, onSave
}) => {
  const isNew = !form.id;

  const onContinueOrSave = () => {
    if (isNew) onContinue();
    else onSave();
  };

  const title = isNew ? i18n.t('titleNew', i18nOpts) : i18n.t('titleEdit', i18nOpts);
  const description = isNew ? i18n.t('descriptionNew', i18nOpts) : null;

  return (
    <>
      <ModalBody>
        <div className="mb-4">
          <div className="font-18 font-weight-600">{title}</div>
          {!v.isBlank(description) && (
            <div className="font-14 mt-1">{description}</div>
          )}
        </div>

        <QuickPossessionForm
          form={form}
          setForm={setForm}
          error={error}
          onTextChange={onTextChange}
        />
      </ModalBody>

      <ModalFooter>
        <Row className="flex-even">
          <Col xs="12" sm="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <Button color="primary" onClick={onContinueOrSave} block disabled={!isValidForm || submitting}>
              {submitting && (<Spinner size="sm" className="mr-2 align-middle" />)}
              {isNew ? i18n.t('buttons.continue') : i18n.t('buttons.update')}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  );
};

Form.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  setForm: PropTypes.func,
  error: PropTypes.objectOf(PropTypes.any),
  isValidForm: PropTypes.bool,
  submitting: PropTypes.bool,
  onTextChange: PropTypes.func,
  onContinue: PropTypes.func,
  onSave: PropTypes.func
};

Form.defaultProps = {
  setForm: () => {},
  error: null,
  isValidForm: false,
  submitting: false,
  onTextChange: () => {},
  onContinue: () => {},
  onSave: () => {}
};

export default Form;
