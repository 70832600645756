import React from 'react';
import PropTypes from 'prop-types';

import OfferSteps from '../../../../../../global/home/OfferSteps';
import { getOfferRealtorText } from '../../../../../../../utils/offerUtils';
import { getDealHomeInfo } from '../../../../../../../utils/dealUtils';
import { OfferStatuses } from '../../../../../../../constants';
import { formatCurrency } from '../../../../../../../utils/currencyUtils';
import DealLastActivityDate from '../../../../../../global/Deal/DealLastActivityDate';
import DealHomeInfo from '../../../../../../global/Deal/DealHomeInfo';

const ListItem = ({ deal }) => {
  const { home: savedHome } = deal;
  const home = savedHome || {};
  const { offer } = home;

  if (!offer) {
    home.offer = {
      status: OfferStatuses.INVITED,
      statusChangeDates: {
        invitedAt: deal.createdAt
      }
    };
  }

  const { communityProjectName } = getDealHomeInfo(deal);

  return (
    <tr>
      <td>
        <DealHomeInfo deal={deal} />
      </td>
      <td>
        <div className="font-weight-500 col-gray-900">{formatCurrency(offer?.offerPrice)}</div>
      </td>
      <td>
        <OfferSteps home={home} clickableSteps />
      </td>
      <td>
        <span className="font-weight-500 col-gray-900">
          {communityProjectName}
        </span>
      </td>
      <td>
        {getOfferRealtorText(offer)}
      </td>
      <td>
        <DealLastActivityDate deal={deal} />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  deal: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
