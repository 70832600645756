export const CALENDAR_LOCALE = Object.freeze({
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  weekDays: [
    {
      name: 'Monday',
      short: 'Mo',
    },
    {
      name: 'Tuesday',
      short: 'Tu',
    },
    {
      name: 'Wednesday',
      short: 'We',
    },
    {
      name: 'Thursday',
      short: 'Th',
    },
    {
      name: 'Friday',
      short: 'Fr',
    },
    {
      name: 'Saturday',
      short: 'Sa',
      isWeekend: true,
    },
    {
      name: 'Sunday',
      short: 'Su',
      isWeekend: true,
    },
  ],

  weekStartingIndex: 6,

  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  transformDigit(digit) {
    return digit;
  },

  nextMonth: 'Next Month',
  previousMonth: 'Previous Month',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Select...',

  from: 'from',
  to: 'to',
  digitSeparator: ',',
  yearLetterSkip: 0,

  isRtl: false,
});
