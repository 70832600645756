import React, { useEffect, useState, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Nav, NavbarBrand } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import {
  MdReport
} from 'react-icons/md';

import {
  isCompanyAdmin, isTecSupport, isMasterAdmin, isSalesLead, isAdmin
} from '../../../utils/authUtils';
import {
  fetchCountNotReadMessagesAsync,
  fetchCountPurchasableCustomersAsync
} from '../../../components/admin/Customers/utils';
import { SocketContext } from '../../../providers/Socket';
import { WebSocketMessages, WorkflowTypes } from '../../../constants';
import SalesMenu from './SalesMenu';
import DataDogService from '../../../services/DataDogService';
import OverviewIcon from '../../../assets/icons/sidebar-nav/OverviewIcon';
import CustomersIcon from '../../../assets/icons/sidebar-nav/CustomersIcon';
import MessagesIcon from '../../../assets/icons/sidebar-nav/MessagesIcon';
import IntegrationsIcon from '../../../assets/icons/sidebar-nav/IntegrationsIcon';
import DocumentsIcon from '../../../assets/icons/sidebar-nav/DocumentsIcon';
import AccountMenuItem from './AccountMenuItem';
import FooterMenu from './FooterMenu';
import SidebarMenuItem from './components/SidebarMenuItem';
import CloseButton from './CloseButton';
import OwnlyIcon from '../../../assets/icons/OwnlyIcon';
import OwnlyDarkText from '../../../assets/images/OwnlyDarkText';
import { setSubMenu } from '../../../store/actions/menuActions';
import { isMobile } from '../../../utils/windowUtils';
import InsightsIcon from '../../../assets/icons/sidebar-nav/InsightsIcon';
import HomesMenu from './HomesMenu';
import './styles.scss';
import {
  getReserveNowWorkflow,
  getVerifiedWorkflow
} from '../../../store/actions/companyWorkflowActions';

const i18nOpts = { scope: 'layout.admin.sidebar.index' };

const Sidebar = ({ currentCompany, currentUser, ...props }) => {
  const [purchasableCustomers, setPurchasableCustomers] = useState(0);
  const [notReadMessages, setNotReadMessages] = useState(0);
  const socket = useContext(SocketContext);

  const fetchCountPurchasableCustomers = () => {
    fetchCountPurchasableCustomersAsync(currentCompany.id)
      .then((count) => setPurchasableCustomers(count))
      .catch(() => setPurchasableCustomers(0));
  };

  const fetchCountNotReadMessages = () => {
    fetchCountNotReadMessagesAsync(currentCompany.id)
      .then((count) => setNotReadMessages(count))
      .catch(() => setNotReadMessages(0));
  };

  const addSocketEventListener = () => {
    socket.on(WebSocketMessages.VERIFIED_CONTACT_REVIEWED, () => {
      fetchCountPurchasableCustomers();
    });

    socket.on(WebSocketMessages.CLIENT_MESSAGE_READ, ({ companyId }) => {
      const isSuperAdmin = isMasterAdmin() || isTecSupport();
      if (!isSuperAdmin && companyId) {
        const parsedCompanyId = parseInt(companyId, 10);
        if (parsedCompanyId !== currentCompany.id) return;
      }

      fetchCountNotReadMessages();
    });
  };

  const loadReserveWorkflow = () => {
    const isRental = currentCompany.enableHomeRental;
    const type = isRental ? WorkflowTypes.RENTAL_RESERVE : WorkflowTypes.BUYER_RESERVE;
    props.getReserveNowWorkflow(currentCompany.id, type)
      .catch(() => {});
  };

  const loadVerifiedWorkflow = () => {
    const isRental = currentCompany.enableHomeRental;
    const type = isRental ? WorkflowTypes.RENTAL_VERIFIED : WorkflowTypes.BUYER_VERIFIED;
    props.getVerifiedWorkflow(currentCompany.id, type)
      .catch(() => {});
  };

  useEffect(() => {
    socket.connect();
    addSocketEventListener();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (currentCompany) {
      fetchCountPurchasableCustomers();
      fetchCountNotReadMessages();
      DataDogService.setCompany(currentCompany);
      loadReserveWorkflow();
      loadVerifiedWorkflow();
    }
  }, [currentCompany]);

  const hideMainSidebar = (e) => {
    if (isMobile()) return;

    const isSubMenu = e.target.closest('.sub-menu');
    const isSubMenuOpened = document.querySelector('.sub-menu .first-level.opened');
    if (!isSubMenu && isSubMenuOpened) props.setSubMenu(null);
  };

  useEffect(() => {
    window.addEventListener('click', hideMainSidebar.bind(null));
    return () => {
      window.removeEventListener('click', hideMainSidebar.bind(null));
    };
  }, []);

  const enabledIdVerification = currentCompany?.enableIdVerification;
  const customersLabel = currentCompany?.enableHomeRental ? i18n.t('menus.applicants', i18nOpts)
    : i18n.t('menus.customers', i18nOpts);

  if (!currentUser) return null;

  return (
    <div>
      <div className="sidebar-backdrop">
        <CloseButton />
      </div>

      <aside id="main-sidebar" className="main-sidebar">
        <div className="sidebar-logo-container d-flex d-md-none">
          <NavbarBrand href="/" className="d-flex">
            <b className="logo-icon pl-0 pl-md-3">
              <OwnlyIcon />
            </b>
            <div className="logo-text ml-2">
              <OwnlyDarkText />
            </div>
          </NavbarBrand>
        </div>

        <div className="sidebar-nav-container">
          <PerfectScrollbar className="sidebar-nav">
            <Nav id="sidebar-nav">
              {isAdmin() && (
                <>
                  {(isMasterAdmin() || isTecSupport()) && (
                    <SidebarMenuItem
                      to="/admin/dashboard"
                      Icon={<MdReport size="22" />}
                      title={i18n.t('menus.dashboard', i18nOpts)}
                    />
                  )}

                  <SidebarMenuItem
                    to="/admin/overview"
                    Icon={<OverviewIcon />}
                    title={i18n.t('menus.overview', i18nOpts)}
                  />
                </>
              )}

              <HomesMenu />

              <SidebarMenuItem
                to="/admin/customers"
                Icon={<CustomersIcon />}
                title={customersLabel}
              >
                { purchasableCustomers > 0 && enabledIdVerification && (
                  <span className="ml-2 text-light rounded-circle bg-danger unreviewed-items-count">
                    {purchasableCustomers}
                  </span>
                )}
              </SidebarMenuItem>

              {(isMasterAdmin() || isTecSupport() || isCompanyAdmin()) && (
                <SidebarMenuItem to="/admin/insights" Icon={<InsightsIcon />} title={i18n.t('menus.insights', i18nOpts)} />
              )}

              <SidebarMenuItem
                to="/admin/messages"
                Icon={<MessagesIcon />}
                title={i18n.t('menus.messages', i18nOpts)}
              >
                { notReadMessages > 0 && (
                  <span className="ml-2 text-light rounded-circle bg-danger unreviewed-items-count">
                    {notReadMessages}
                  </span>
                )}
              </SidebarMenuItem>

              {(isMasterAdmin() || isTecSupport() || isCompanyAdmin() || isSalesLead()) && (
                <>
                  <SalesMenu />

                  <SidebarMenuItem
                    to="/admin/integrations"
                    Icon={<IntegrationsIcon />}
                    title={i18n.t('menus.integrations', i18nOpts)}
                  />
                  {(isMasterAdmin() || isCompanyAdmin() || isSalesLead()) && (
                    <SidebarMenuItem
                      to="/admin/documents"
                      Icon={<DocumentsIcon />}
                      title={i18n.t('menus.documents', i18nOpts)}
                    />
                  )}
                </>
              )}
            </Nav>

          </PerfectScrollbar>
        </div>
        <div>
          <FooterMenu />
        </div>
        <div className="d-block d-md-none">
          <AccountMenuItem />
        </div>
      </aside>
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  currentUser: store.users.currentUser
}), {
  setSubMenu,
  getReserveNowWorkflow,
  getVerifiedWorkflow
})(Sidebar);
