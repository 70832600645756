import { Currencies, PaymentMethods } from '../../../../../constants';

export function getPaymentMethods(currency, currentCompany) {
  const { enableACHPayments } = currentCompany;

  const paymentMethods = [PaymentMethods.CARD];
  if (currency === Currencies.USD && enableACHPayments) {
    paymentMethods.push(PaymentMethods.ACH_DIRECT_DEBIT);
  }
  return paymentMethods;
}
