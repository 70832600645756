import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import useIsVerifiedEnabled from '../../../../../../hooks/useIsVerifiedEnabled';
import { setActiveCustomerTab } from '../../../../../../store/actions/customerActions';
import { CustomerTabs } from '../../../../../../constants';

const i18nOpts = { scope: 'components.admin.customers.show.details.navTabs.index' };

const NavTabs = ({
  activeTab, ...props
}) => {
  const isVerifiedEnabled = useIsVerifiedEnabled();

  const onChange = (tab) => {
    props.setActiveCustomerTab(tab);
  };

  return (
    <Nav tabs>
      <NavItem>
        <NavLink
          active={activeTab === CustomerTabs.ACTIVITY}
          onClick={() => onChange(CustomerTabs.ACTIVITY)}
        >
          {i18n.t('activity', i18nOpts)}
        </NavLink>
      </NavItem>
      {isVerifiedEnabled && (
        <NavItem>
          <NavLink
            active={activeTab === CustomerTabs.VERIFICATION}
            onClick={() => onChange(CustomerTabs.VERIFICATION)}
          >
            {i18n.t('verification', i18nOpts)}
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink
          active={activeTab === CustomerTabs.DEALS}
          onClick={() => onChange(CustomerTabs.DEALS)}
        >
          {i18n.t('deals', i18nOpts)}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          active={activeTab === CustomerTabs.INTERESTS}
          onClick={() => onChange(CustomerTabs.INTERESTS)}
        >
          {i18n.t('interests', i18nOpts)}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          active={activeTab === CustomerTabs.MESSAGES}
          onClick={() => onChange(CustomerTabs.MESSAGES)}
        >
          {i18n.t('messages', i18nOpts)}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          active={activeTab === CustomerTabs.DOCUMENTS}
          onClick={() => onChange(CustomerTabs.DOCUMENTS)}
        >
          {i18n.t('documents', i18nOpts)}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

NavTabs.propTypes = {};

NavTabs.defaultProps = {};

export default connect((store) => ({
  activeTab: store.customers.activeTab
}), {
  setActiveCustomerTab
})(NavTabs);
