import React from 'react';
import { Link, navigate } from 'gatsby';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './styles.scss';
import { DEFAULT_IMAGE } from '../../../../../../constants';
import StatusIndicator from '../../../../../global/StatusIndicator';
import ReportIcon from '../../../../../global/ReportIcon';
import { setReportCommunity } from '../../../../../../store/actions/overviewActions';

const ListItem = ({ community, ...props }) => {
  if (!community) return null;

  const tooltip = 'Community Report';
  const pressReport = async () => {
    await props.setReportCommunity(community);
    navigate('/admin/overview');
  };

  return (
    <div className="community-card">
      <Link to={`/admin/communities/${community.id}`} className="d-inline-block">
        <div className="community-image">
          <img src={community.imageUrl || DEFAULT_IMAGE} alt="" className="mb-0" />
          <div className="backdrop" />
        </div>
        <div className="d-flex align-items-center justify-content-center title-container">
          <h4 className="title">{community.name}</h4>
        </div>
      </Link>

      <ReportIcon onPress={pressReport} size="1.8em" id={community.id} tooltip={tooltip} />
      <StatusIndicator status={community.status} id={community.id} />
    </div>
  );
};

ListItem.propTypes = {
  community: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect(() => ({
}), {
  setReportCommunity
})(ListItem);
