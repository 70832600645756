import React, { Component } from 'react';
import { parseBoolean } from '../../../../utils/parserUtils';

class BezierEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'source'
    };

    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.moveVertex = this.moveVertex.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    const { object } = props;
    if (!object.path.length) {
      props.onUpdate({
        path: [
          { x1: object.x, y1: object.y }
        ],
        movex: object.x,
        movey: object.y
      });
    } else {
      this.setState({
        mode: 'edit'
      });
    }
  }

  onMouseMove(event) {
    const { mode } = this.state;
    const mouse = this.getMouseCoords(event);
    const { props } = this;
    const { object } = this.props;
    const { movex, movey } = object;
    let { x, y } = mouse;

    const snapToInitialVertex = (
      this.isCollides(movex, movey, x, y)
    );
    if (snapToInitialVertex) {
      x = movex;
      y = movey;
    }

    if (mode === 'source') {
      this.updateCurrentPath({
        x1: mouse.x,
        y1: mouse.y
      });
    }

    if (mode === 'target') {
      this.updateCurrentPath({
        x2: x,
        y2: y,
        x,
        y
      });
    }

    if (mode === 'connect') {
      this.updateCurrentPath({ x, y });
    }

    if (mode === 'target' || mode === 'connect') {
      this.setState({
        closePath: snapToInitialVertex
      });
    }

    if (mode === 'move') {
      const {
        movedPathIndex,
        movedTargetX,
        movedTargetY
      } = this.state;
      this.updatePath({
        [movedTargetX]: x,
        [movedTargetY]: y
      }, movedPathIndex);
    }

    if (mode === 'moveInitial') {
      props.onUpdate({
        movex: x,
        movey: y
      });
    }
  }

  onMouseDown(event) {
    const { closePath } = this.state;
    const { props } = this;
    if (closePath) {
      return this.closePath();
    }

    if (event.target.tagName === 'svg') {
      return props.onClose();
    }

    const { mode } = this.state;

    if (mode === 'target') {
      this.setState({
        mode: 'connect'
      });
    }
  }

  onMouseUp(event) {
    const { mode, closePath } = this.state;
    const { props } = this;
    const { path } = props.object;
    const mouse = this.getMouseCoords(event);
    const currentPath = this.getCurrentPath();

    if (closePath) {
      return this.closePath();
    }

    if (mode === 'source') {
      this.setState({
        mode: 'target'
      });
    }

    if (mode === 'connect') {
      this.setState({
        mode: 'target'
      });
      props.onUpdate({
        path: [
          ...path,
          {
            x1: currentPath.x + (currentPath.x - currentPath.x2),
            y1: currentPath.y + (currentPath.y - currentPath.y2),
            x2: mouse.x,
            y2: mouse.y,
            x: mouse.x,
            y: mouse.y
          }
        ]
      });
    }

    if (mode === 'move' || mode === 'moveInitial') {
      this.setState({
        mode: 'edit'
      });
    }
  }

  getMouseCoords(event) {
    const { object, offset } = this.props;
    return {
      x: event.clientX - offset.x - (object.x - object.movex),
      y: event.clientY - offset.y - (object.y - object.movey)
    };
  }

  getCurrentPath() {
    const { props } = this;
    const { path } = props.object;
    return path[path.length - 1];
  }

  getCurrentPoint(pathIndex) {
    const { state } = this;
    const { object } = this.props;
    if (pathIndex === 0) {
      return { x: object.movex, y: object.movey };
    }
    const path = state.path[pathIndex - 1];
    return { x: path.x, y: path.y };
  }

  updateCurrentPath(updates, close = undefined) {
    const { props } = this;
    const { object } = this.props;
    const { path } = object;
    const current = this.getCurrentPath();

    const objectToUpdate = {
      closed: close,
      path: [
        ...path.slice(0, path.length - 1),
        {
          ...current,
          ...updates
        }
      ]
    };

    if (parseBoolean(close)) objectToUpdate.closed = close.toString();

    props.onUpdate(objectToUpdate);
  }

  isCollides(x1, y1, x2, y2, radius = 5) {
    const xd = x1 - x2;
    const yd = y1 - y2;
    const wt = radius * 2;
    return (xd * xd + yd * yd <= wt * wt);
  }

  updatePath(updates, index) {
    const { props } = this;
    const { path } = props.object;
    const current = path[index];

    props.onUpdate({
      path: [
        ...path.slice(0, index),
        {
          ...current,
          ...updates
        },
        ...path.slice(index + 1)
      ]
    });
  }

  closePath() {
    const { props } = this;
    this.setState({
      mode: null
    });

    props.onClose();

    this.updateCurrentPath({
      x: props.object.movex,
      y: props.object.movey
    }, true);
  }

  moveVertex(pathIndex, targetX, targetY, event) {
    event.preventDefault();
    const { mode } = this.state;

    if (mode !== 'edit') {
      return;
    }

    this.setState({
      mode: 'move',
      movedPathIndex: pathIndex,
      movedTargetX: targetX,
      movedTargetY: targetY
    });
  }

  render() {
    const { object, width, height } = this.props;
    const { path } = object;

    const {
      movex, movey, x: oX, y: oY
    } = object;

    const offsetX = oX - movex;
    const offsetY = oY - movey;

    return (
      <div
        style={styles.canvas}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
        onMouseDown={this.onMouseDown}
      >
        <svg style={{ width, height }}>
          <g transform={`translate(${offsetX} ${offsetY})
                         rotate(${object.rotate} ${object.x} ${object.y})`}
          >
            {path.map(({
              x1, y1, x2, y2, x, y
            }, i) => (
              <g key={i}>
                {x2 && y2 && (
                <g>
                  <circle
                    r={7}
                    cx={x}
                    cy={y}
                    style={styles.vertex}
                    onMouseDown={(e) => this.moveVertex(i, 'x', 'y', e)}
                  />
                </g>
                )}
                {i === 0 && (
                <g>
                  <line
                    x1={movex}
                    y1={movey}
                    style={styles.edge}
                    onMouseDown={(e) => this.moveVertex(i, 'x1', 'y1', e)}
                    x2={x1}
                    y2={y1}
                    stroke="black"
                  />

                  <circle
                    style={styles.vertex}
                    r={7}
                    cx={x1}
                    cy={y1}
                    onMouseDown={(e) => this.moveVertex(i, 'x1', 'y1', e)}
                  />

                  <circle
                    r={7}
                    cx={movex}
                    cy={movey}
                    style={{ ...styles.vertex, ...styles.initialVertex }}
                  />
                </g>
                )}
              </g>
            ))}
          </g>
        </svg>
      </div>
    );
  }
}

const styles = {
  vertex: {
    fill: '#3381ff',
    strokeWidth: 0
  },
  initialVertex: {
    fill: '#ffd760'
  },
  edge: {
    stroke: '#b9b9b9'
  },
  canvas: {
    position: 'absolute'
  }
};

export default BezierEditor;
