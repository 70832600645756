import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

const Community = ({ quickPossession }) => {
  const { community } = quickPossession;

  if (v.isBlank(community)) return null;

  return (
    <div className="text-nowrap col-gray-600 font-weight-600">
      {community.name}
    </div>
  );
};

Community.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Community.defaultProps = {};

export default Community;
