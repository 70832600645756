import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';
import PropTypes from 'prop-types';

import StatusIndicator from '../../../../global/StatusIndicator';
import { getProjectBuildingStatusName, getProjectBuildingAvailabilityName } from '../../../../../utils/projectBuildingUtils';
import { isAdmin } from '../../../../../utils/authUtils';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';

const i18nOpts = { scope: 'components.admin.projectBuildings.show.overview.index' };

const Overview = ({ projectBuilding }) => {
  const creatorName = getCreatorDisplayName(projectBuilding);
  const lastModifierName = getLastModifierDisplayName(projectBuilding);

  return (
    <div>
      {!v.isBlank(projectBuilding.hashId) && (
        <div className="mb-4">
          <div className="label">{i18n.t('projectBuildingId', i18nOpts)}</div>
          <div><strong>{projectBuilding.hashId}</strong></div>
        </div>
      )}

      <div className="mb-4">
        <div className="label">{i18n.t('project', i18nOpts)}</div>
        <div><strong>{projectBuilding.project.name}</strong></div>
      </div>

      <div className="mb-4">
        <div className="label">{i18n.t('availability', i18nOpts)}</div>
        <div>
          <strong>{getProjectBuildingAvailabilityName(projectBuilding.availability)}</strong>
        </div>
      </div>

      {!v.isBlank(projectBuilding.description) && (
        <div className="mb-4">
          <div className="label">{i18n.t('description', i18nOpts)}</div>
          <div className="white-space-pre-line">{projectBuilding.description}</div>
        </div>
      )}

      { isAdmin() && (
        <>
          {!v.isBlank(creatorName) && (
            <div className="mb-4">
              <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
              <div className="white-space-pre-line">{creatorName}</div>
            </div>
          )}
          {!v.isBlank(lastModifierName) && (
            <div className="mb-4">
              <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
              <div className="white-space-pre-line">{lastModifierName}</div>
            </div>
          )}
        </>
      )}
      <div className="mb-4">
        <div className="label">{i18n.t('status', i18nOpts)}</div>
        <div>
          <div className="d-inline-block mr-2">
            <StatusIndicator status={projectBuilding.status} displayTooltip={false} />
          </div>
          <strong>{getProjectBuildingStatusName(projectBuilding.status)}</strong>
        </div>
      </div>
    </div>
  );
};

Overview.propTypes = {
  projectBuilding: PropTypes.objectOf(PropTypes.any).isRequired
};

Overview.defaultProps = {};

export default connect((store) => ({
  palette: store.palettes.palette
}), {})(Overview);
