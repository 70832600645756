import React, { useEffect, useState } from 'react';
import {
  TabContent, TabPane, Button
} from 'reactstrap';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';

import { getTeam } from '../../../../store/actions/teamActions';
import Overview from './Overview';
import { Tabs } from './utils';
import NavTabs from './NavTabs';
import Users from './Users';
import VerificationDirectLinkModal from '../../../global/VerificationDirectLinkModal';
import QRCodeModal from '../../../global/QRCodeModal';
import { getPublicTeamHashId } from '../../../../utils/salesRepUtils';
import ModalService from '../../../../services/ModalService';
import { ModalNames } from '../../../../constants';
import FormModal from '../components/FormModal';
import { canManageTeam } from '../../../../utils/teamUtils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.teams.show.index' };

const Show = ({
  id, team, loading, ...props
}) => {
  const [activeTab, setActiveTab] = useState(Tabs.OVERVIEW);

  const onChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const onGetVerifiedLink = () => {
    const hashId = getPublicTeamHashId(team);
    ModalService.open(ModalNames.VERIFICATION_DIRECT_LINK, { hashId });
  };

  const onEdit = () => {
    ModalService.open(ModalNames.TEAM_FORM, { team });
  };

  const onAfterDelete = () => {
    navigate('/admin/teams');
  };

  const loadTeam = () => {
    props.getTeam(parseInt(id, 10))
      .catch(() => navigate('/admin/teams'));
  };

  useEffect(() => {
    loadTeam();
  }, []);

  if (!team || loading) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={team.name} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{team.name}</h2>
        </div>
        <div className="d-flex gap-3">
          <Button color="secondary" outline onClick={onGetVerifiedLink}>
            {i18n.t('buttons.getVerifiedLink', i18nOpts)}
          </Button>
          {canManageTeam(team.userPermission) && (
            <Button color="primary" onClick={onEdit}>
              <i className="far fa-edit mr-2" />
              {i18n.t('buttons.edit')}
            </Button>
          )}
        </div>
      </div>

      <NavTabs activeTab={activeTab} onChange={onChangeTab} />

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.OVERVIEW}>
          <Overview />
        </TabPane>
        <TabPane tabId={Tabs.USERS}>
          <Users />
        </TabPane>
      </TabContent>

      <VerificationDirectLinkModal />
      <QRCodeModal />
      <FormModal onAfterDelete={onAfterDelete} />
    </div>
  );
};

export default connect((store) => ({
  team: store.teams.team,
  loading: store.teams.getTeam.loading
}), {
  getTeam
})(Show);
