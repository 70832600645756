import i18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from '../../common/Breadcrumbs';

const i18nOpts = { scope: 'components.global.integrationsBreadcrumbs.index' };

const IntegrationsBreadcrumbs = ({ items }) => {
  const integrationItems = [{ text: i18n.t('integrations', i18nOpts), url: '/admin/integrations' }, ...items];

  return (
    <div className="mb-4">
      <Breadcrumbs items={integrationItems} />
    </div>
  );
};

IntegrationsBreadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string
  }))
};

IntegrationsBreadcrumbs.defaultProps = {
  items: []
};

export default IntegrationsBreadcrumbs;
