import React from 'react';
import i18n from 'i18n-js';

import './styles.scss';
import { isAdmin, isMasterAdmin, isTecSupport } from '../../../../utils/authUtils';
import { Menus } from '../utils';
import SidebarMenuItem from '../components/SidebarMenuItem';
import SubMenu from '../components/SubMenu';
import SettingsIcon from '../../../../assets/icons/sidebar-nav/SettingsIcon';
import UsersIcon from '../../../../assets/icons/sidebar-nav/UsersIcon';
import TeamsIcon from '../../../../assets/icons/sidebar-nav/TeamsIcon';
import CompaniesIcon from '../../../../assets/icons/sidebar-nav/CompaniesIcon';
import CitiesIcon from '../../../../assets/icons/sidebar-nav/CitiesIcon';
import TaxRatesIcon from '../../../../assets/icons/sidebar-nav/TaxRatesIcon';
import PropertyTaxRatesIcon from '../../../../assets/icons/sidebar-nav/PropertyTaxRatesIcon';
import MortgageRateIcon from '../../../../assets/icons/sidebar-nav/MortgageRateIcon';
import { isPreApprovalEnabled } from '../../../../utils/companyUtils';

const i18nOpts = { scope: 'layout.admin.sidebar.index' };

const FooterMenu = () => {
  const companyPath = (isMasterAdmin() || isTecSupport()) ? 'companies' : 'myCompany';

  return (
    <ul className="sidebar-nav footer-menu">
      <SubMenu
        title={i18n.t('menus.settings.title', i18nOpts)}
        menu={Menus.SETTINGS}
        IconElement={<SettingsIcon />}
      >
        <SidebarMenuItem to="/admin/users" title={i18n.t('menus.settings.subMenus.users', i18nOpts)} Icon={<UsersIcon />} />
        <SidebarMenuItem to="/admin/teams" title={i18n.t('menus.settings.subMenus.teams', i18nOpts)} Icon={<TeamsIcon />} />

        {isAdmin() && (
          <>
            <SidebarMenuItem to={`/admin/${companyPath}`} title={i18n.t(`menus.settings.subMenus.${companyPath}`, i18nOpts)} Icon={<CompaniesIcon />} />
            {isPreApprovalEnabled() && (
              <SidebarMenuItem to="/admin/mortgageRate" title={i18n.t('menus.settings.subMenus.mortgageRate', i18nOpts)} Icon={<MortgageRateIcon />} />
            )}
            <SidebarMenuItem to="/admin/cities" title={i18n.t('menus.settings.subMenus.cities', i18nOpts)} Icon={<CitiesIcon />} />
            <SidebarMenuItem to="/admin/taxRates" title={i18n.t('menus.settings.subMenus.taxRates', i18nOpts)} Icon={<TaxRatesIcon />} />
            <SidebarMenuItem to="/admin/propertyTaxRates" title={i18n.t('menus.settings.subMenus.propertyTaxRates', i18nOpts)} Icon={<PropertyTaxRatesIcon />} />
          </>
        )}
      </SubMenu>
    </ul>
  );
};

FooterMenu.propTypes = {};

FooterMenu.defaultProps = {};

export default FooterMenu;
