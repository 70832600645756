import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { Tabs } from '../utils';
import { isMasterAdmin, isTecSupport } from '../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => {
  const isMasterAdminRole = isMasterAdmin();
  const isTecSupportRole = isTecSupport();
  const canViewMasterSettings = isMasterAdminRole || isTecSupportRole;

  return (
    <Nav tabs>
      <NavItem>
        <NavLink
          active={activeTab === Tabs.COMPANY_AND_BRANDING}
          onClick={() => onChange(Tabs.COMPANY_AND_BRANDING)}
        >
          {i18n.t('companyAndBranding', i18nOpts)}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          active={activeTab === Tabs.SITE_AND_UX}
          onClick={() => onChange(Tabs.SITE_AND_UX)}
        >
          {i18n.t('siteAndUX', i18nOpts)}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          active={activeTab === Tabs.SALES_CONFIG}
          onClick={() => onChange(Tabs.SALES_CONFIG)}
        >
          {i18n.t('salesConfig', i18nOpts)}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          active={activeTab === Tabs.WORKFLOW}
          onClick={() => onChange(Tabs.WORKFLOW)}
        >
          {i18n.t('workflow', i18nOpts)}
        </NavLink>
      </NavItem>
      {canViewMasterSettings && (
        <NavItem>
          <NavLink
            active={activeTab === Tabs.COMMUNICATIONS}
            onClick={() => onChange(Tabs.COMMUNICATIONS)}
          >
            {i18n.t('communications', i18nOpts)}
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink
          active={activeTab === Tabs.ADVANCED_SETTINGS}
          onClick={() => onChange(Tabs.ADVANCED_SETTINGS)}
        >
          {i18n.t('advancedSettings', i18nOpts)}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
