import React from 'react';

const SearchIcon = ({ ...props }) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18.4687 17.5L15.5522 14.5833M17.6354 9.58333C17.6354 13.4954 14.4641 16.6667 10.5521 16.6667C6.64007 16.6667 3.46875 13.4954 3.46875 9.58333C3.46875 5.67132 6.64007 2.5 10.5521 2.5C14.4641 2.5 17.6354 5.67132 17.6354 9.58333Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

SearchIcon.propTypes = {};

SearchIcon.defaultProps = {};

export default SearchIcon;
