import v from 'voca';

export function parseObject(keys, values) {
  const parsedObject = {};
  keys.forEach((k, index) => {
    if (!v.isBlank(k)) {
      const relatedValue = values[index];
      if (!v.isBlank(relatedValue)) parsedObject[k] = relatedValue;
    }
  });

  return parsedObject;
}

export function getArrayOfPropertiesWithSamePrefix(obj, prefix) {
  const properties = [];
  const keys = Object.keys(obj);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (key.startsWith(prefix)) properties.push(obj[key]);
  }

  return properties;
}

export function isUnionTypeObjectEqual(o1, o2) {
  return o1?.id === o2?.id && o1?.__typename === o2?.__typename;
}

export function getUnionTypeObjectKey(o) {
  const { id, __typename: typename } = o;
  return `${typename}-${id}`;
}

export function compareUnionTypeObjectTypename(o, type) {
  const { __typename: typename } = o;
  return typename === type;
}
