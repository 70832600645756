import React from 'react';
import {
  Card, CardBody, CardHeader, Col, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { formatCurrency, isValidAmount } from '../../../../../../../../../utils/currencyUtils';
import { DateFormats } from '../../../../../../../../../constants';
import { getCustomerHomeEquityAmount } from '../../../../../../../../../utils/customerUtils';
import humanizeBoolean from '../../../../../../../../../utils/boolUtils';
import { formatCustomerHomeAddress } from './utils';

const i18nOpts = { scope: 'components.admin.customers.show.details.verification.additionalInfo.homeValuation.infoCard.index' };

const InfoCard = ({ customer }) => {
  const {
    propertyInfo,
    homeAddress,
    isOwnHome,
    isSellingHome,
    outstandingMortgageBalance
  } = customer;

  const { homeValue, homeUrl, estimatedAt } = propertyInfo || {};
  const hasHomeValue = isValidAmount(homeValue);
  const hasHomeUrl = !v.isBlank(homeUrl);
  const address = formatCustomerHomeAddress(homeAddress);
  const homeEquityAmount = getCustomerHomeEquityAmount(customer);

  return (
    <Card className="mb-2 card-without-body">
      <CardHeader className={classNames({ 'py-2': hasHomeUrl })}>
        <div className="d-flex align-items-center">
          <h4 className="mb-0 mr-3 col-gray-900">{i18n.t('title', i18nOpts)}</h4>

          {hasHomeUrl && (
            <div className="ml-auto">
              <a href={homeUrl} target="_blank" rel="noreferrer" className="btn btn-outline-secondary">
                {i18n.t('buttons.viewValuation', i18nOpts)}
              </a>
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody className="pb-0">
        <Row>
          {!v.isBlank(address) && (
            <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('address', i18nOpts)}</div>
              <div className="font-weight-600 font-16" dangerouslySetInnerHTML={{ __html: address }} />
            </Col>
          )}

          <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
            <div className="font-weight-500 col-gray-500">{i18n.t('ownership', i18nOpts)}</div>
            <div className="font-weight-600 font-16">{i18n.t(`ownershipOptions.${humanizeBoolean(isOwnHome)}`, i18nOpts)}</div>
          </Col>

          {isOwnHome && (
            <>
              {!v.isBlank(estimatedAt) && (
                <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
                  <div className="font-weight-500 col-gray-500">{i18n.t('valuationDate', i18nOpts)}</div>
                  <div className="font-weight-600 font-16">{moment(estimatedAt).format(DateFormats.YYYY_MM_DD)}</div>
                </Col>
              )}

              {hasHomeValue && (
                <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
                  <div className="font-weight-500 col-gray-500">{i18n.t('homeValue', i18nOpts)}</div>
                  <div className="font-weight-600 font-16">{formatCurrency(homeValue)}</div>
                </Col>
              )}

              {isSellingHome && (
                <>
                  {isValidAmount(outstandingMortgageBalance) && (
                    <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
                      <div className="font-weight-500 col-gray-500">{i18n.t('approxMortgageBalance', i18nOpts)}</div>
                      <div className="font-weight-600 font-16">{formatCurrency(outstandingMortgageBalance)}</div>
                    </Col>
                  )}

                  {isValidAmount(homeEquityAmount) && (
                    <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
                      <div className="font-weight-500 col-gray-500">{i18n.t('approxMortgageBalance', i18nOpts)}</div>
                      <div className="font-weight-600 font-16">{formatCurrency(homeEquityAmount)}</div>
                    </Col>
                  )}
                </>
              )}
            </>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

InfoCard.propTypes = {};

InfoCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
}))(InfoCard);
