import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

import styles from '../../../../HomeCard/HomeCard.module.scss';
import { buildInvitedHomeReserveUrl, getInvitedHomeInfo } from '../../../../../../utils/invitedHomeUtils';

const i18nOpts = { scope: 'components.global.homeCard.index' };

const InvitedHomeCard = ({ home }) => {
  if (!home) return null;

  const {
    name, imageUrl, isQuickPossession, address
  } = getInvitedHomeInfo(home);
  const homeUrl = buildInvitedHomeReserveUrl(home);

  return (
    <a href={homeUrl} target="_blank" rel="noreferrer" className="d-flex gap-3 col-gray-600">
      {!v.isBlank(imageUrl) && (
        <div>
          <div className={styles.imageContainer}>
            <img src={imageUrl} alt={name} className={styles.image} />
          </div>
        </div>
      )}

      <div>
        <div className="mb-1">
          <div className="d-flex align-items-center gap-2">
            <div className="col-gray-900 font-weight-500">{name}</div>
            {isQuickPossession && (
              <Badge color="warning" pill>{i18n.t('quickPossession', i18nOpts)}</Badge>
            )}
          </div>

          {!v.isBlank(address) && (
            <div>
              <small>{address}</small>
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

InvitedHomeCard.propTypes = {
  home: PropTypes.objectOf(PropTypes.any)
};

InvitedHomeCard.defaultProps = {
  home: null
};

export default InvitedHomeCard;
