import api from '../../../../api';
import { getProjectBuildingQuery } from '../../../../graphql';

export const Tabs = Object.freeze({
  OVERVIEW: 'overview',
  MODELS: 'models',
  FLOORS: 'floors',
  IMAGES: 'images',
  MODIFICATIONS: 'modifications'
});

export function fetchProjectBuildingAsync(id) {
  const variables = { id };

  return api.graphql(getProjectBuildingQuery, variables)
    .then(({ data: { item } }) => Promise.resolve(item));
}
