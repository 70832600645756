import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

const ResetButton = ({ onClick }) => (
  <div onClick={onClick} role="button" aria-hidden className="font-weight-600 text-primary">
    {i18n.t('buttons.reset')}
  </div>
);

ResetButton.propTypes = {
  onClick: PropTypes.func
};

ResetButton.defaultProps = {
  onClick: () => {}
};

export default ResetButton;
