import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

const ProductType = ({ quickPossession }) => {
  const { model } = quickPossession;
  const { productType } = model || {};

  if (v.isBlank(productType)) return null;

  return (
    <div className="text-nowrap col-gray-600 font-weight-600">
      {productType.name}
    </div>
  );
};

ProductType.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

ProductType.defaultProps = {};

export default ProductType;
