import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { DEFAULT_IMAGE } from '../../../../constants';
import FormDropdown from '../components/FormDropdown';
import FormDropdownItem from '../components/FormDropdownItem';
import { getModelPackagePrice } from '../../../../utils/priceRuleUtils';

const i18nOpts = { scope: 'components.global.quickPossessionForm.packageDropdown.index' };

const PackageDropdown = ({ form, onChange, packages }) => {
  const { package: selectedPackage, community, model } = form;

  const onSelect = (newSelectedPackage) => {
    onChange({ package: newSelectedPackage });
  };

  return (
    <FormDropdown value={selectedPackage?.name} placeholder={i18n.t('label', i18nOpts)}>
      {
        packages.map((mp) => {
          const { package: specPackage } = mp;
          const imageUrl = specPackage.imageUrl || DEFAULT_IMAGE;
          const price = getModelPackagePrice(community, model, specPackage);

          return (
            <FormDropdownItem
              key={`package-option-${specPackage.id}`}
              onClick={() => onSelect(specPackage)}
              imageUrl={imageUrl}
              name={specPackage.name}
              price={price}
            />
          );
        })
      }
    </FormDropdown>
  );
};

PackageDropdown.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  packages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

PackageDropdown.defaultProps = {
  onChange: () => {}
};

export default PackageDropdown;
