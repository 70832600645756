import React from 'react';
import { connect } from 'react-redux';
import {
  Nav, NavItem, NavLink, Table
} from 'reactstrap';
import i18n from 'i18n-js';

import { formatCurrency } from '../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.financialReport.overallGovernmentTable.index' };

const OverallGovernmentTable = ({
  financialReport
}) => {
  const { governmentIncome } = financialReport;
  const {
    averageDeposit, frequency, last2Paydates, totalIncome
  } = governmentIncome;

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink active className="font-16">
            {i18n.t('tabs.overall', i18nOpts)}
          </NavLink>
        </NavItem>
      </Nav>

      <Table bordered responsive className="mt-4">
        <thead>
          <tr>
            <th>{i18n.t('accountAttribute', i18nOpts)}</th>
            <th>{i18n.t('value', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{i18n.t('averageDeposit', i18nOpts)}</td>
            <td>{formatCurrency(averageDeposit)}</td>
          </tr>
          <tr>
            <td>{i18n.t('frequency', i18nOpts)}</td>
            <td>{frequency}</td>
          </tr>
          <tr>
            <td>{i18n.t('last2DepositDates', i18nOpts)}</td>
            <td>{last2Paydates}</td>
          </tr>
          <tr>
            <td>{i18n.t('totalDetected', i18nOpts)}</td>
            <td>{formatCurrency(totalIncome)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

OverallGovernmentTable.propTypes = {};

OverallGovernmentTable.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
}))(OverallGovernmentTable);
