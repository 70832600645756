const fragment = `
  id
  type
  code
  name
  description
  workflow
`;

export const getCompanyWorkflowQuery = `
  query GetCompanyWorkflow(
    $companyId: Int!
    $type: CompanyWorkflowType!
  ) {
    item: getCompanyWorkflow(companyId: $companyId, type: $type) {
      ${fragment}
    }
  }
`;

export const saveCompanyWorkflowMutation = `
  mutation SaveCompanyWorkflow(
    $companyId: Int!
    $type: CompanyWorkflowType!
    $workflow: JSON!
  ) {
    item: saveCompanyWorkflow(companyId: $companyId, type: $type, workflow: $workflow) {
      ${fragment}
    }
  }
`;

export const validateCompanyWorkflowMutation = `
  mutation ValidateCompanyWorkflow(
    $type: CompanyWorkflowType!
    $file: Upload
  ) {
    workflow: validateCompanyWorkflow(type: $type, file: $file)
  }
`;
