import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  FormGroup, Input, Label,
} from 'reactstrap';
import i18n from 'i18n-js';

import { isAdmin } from '../../../../../utils/authUtils';
import { parseBoolean } from '../../../../../utils/parserUtils';
import ImagesManager from '../../../../global/ImagesManager';
import {
  createBuildingModelImageAsync, deleteBuildingModelImageAsync,
  listBuildingModelImagesAsync, sortBuildingModelImagesAsync,
  updateBuildingModelAsync, updateBuildingModelImageAsync
} from './utils';

const i18nOpts = { scope: 'components.admin.buildingModels.show.images.index' };

const Images = ({ buildingModel }) => {
  const [buildingModelImages, setBuildingModelImages] = useState([]);
  const [form, setForm] = useState({ useImageGallery: buildingModel.useImageGallery });

  const onDrop = (images) => {
    const promises = images.map((file) => uploadImage(file));
    return Promise.all(promises)
      .finally(() => loadBuildingModelImages());
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));

    updateBuildingModel(value);
  };

  const onMarkAsDefault = (buildingModelImage) => {
    const input = {
      id: buildingModelImage.id,
      isDefault: true
    };

    const variables = { input };
    updateBuildingModelImageAsync(variables)
      .then(() => afterMarkAsDefault(buildingModelImage))
      .catch(() => {});
  };

  const afterMarkAsDefault = (buildingModelImage) => {
    const updatedBuildingModelImages = buildingModelImages.map((bmi) => ({
      ...bmi,
      isDefault: bmi.id === buildingModelImage.id
    }));
    setBuildingModelImages(updatedBuildingModelImages);
  };

  const onDeleteBuildingModelImage = (buildingModelImage) => {
    const variables = { id: buildingModelImage.id };
    deleteBuildingModelImageAsync(variables)
      .then(() => afterDelete(buildingModelImage))
      .catch(() => {});
  };

  const afterDelete = (buildingModelImage) => {
    const filteredBuildingModelImages = buildingModelImages.filter((bmi) => bmi.id
      !== buildingModelImage.id);
    setBuildingModelImages(filteredBuildingModelImages);
  };

  const uploadImage = (image) => {
    const input = {
      buildingModelId: buildingModel.id
    };

    const variables = { input, image };
    return createBuildingModelImageAsync(variables);
  };

  const updateBuildingModel = (isChecked) => {
    const input = {
      id: buildingModel.id,
      useImageGallery: parseBoolean(isChecked)
    };

    const variables = { input };
    updateBuildingModelAsync(variables)
      .then(() => {})
      .catch(() => {});
  };

  const loadBuildingModelImages = () => {
    const variables = { filter: { buildingModelId: buildingModel.id } };

    listBuildingModelImagesAsync(variables)
      .then((items) => {
        setBuildingModelImages(items);
      })
      .catch(() => {
        setBuildingModelImages([]);
      });
  };

  const onSetBuildingModelImages = (images) => {
    setBuildingModelImages(images);
  };

  const onSortBuildingModelImages = () => {
    const ids = buildingModelImages.map((bmi) => bmi.id);
    const variables = { buildingModelId: buildingModel.id, ids };

    sortBuildingModelImagesAsync(variables);
  };

  useEffect(() => {
    loadBuildingModelImages();
  }, []);

  return (
    <div>
      <ImagesManager
        images={buildingModelImages}
        onDrop={onDrop}
        onSetImages={onSetBuildingModelImages}
        onSortImages={onSortBuildingModelImages}
        onDeleteImage={onDeleteBuildingModelImage}
        onMarkAsDefault={onMarkAsDefault}
      />

      {isAdmin() && (
      <FormGroup check className="mt-3">
        <Label check className="pointer">
          <Input
            type="checkbox"
            name="useImageGallery"
            id="useImageGallery"
            value={!parseBoolean(form.useImageGallery)}
            checked={parseBoolean(form.useImageGallery)}
            onChange={onTextChange}
          />
          {i18n.t('useImageGallery', i18nOpts)}
        </Label>
      </FormGroup>
      )}

    </div>
  );
};

export default connect((store) => ({
  buildingModel: store.buildingModels.buildingModel,
}))(Images);
