import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getBuildingModelPalettes, reloadBuildingModelPalettes } from '../../../../../store/actions/buildingModelPalettesActions';
import Dropdown from './Dropdown';
import ListItem from './ListItem';
import { isAdmin } from '../../../../../utils/authUtils';
import BuildingModelPaletteFormModal from './BuildingModelPaletteFormModal';

const Palettes = ({
  buildingModel, buildingModelPalettes, reload, ...props
}) => {
  useEffect(() => {
    loadBuildingModelPalettes();
  }, []);

  useEffect(() => {
    if (!reload) return;

    loadBuildingModelPalettes();
  }, [reload]);

  const loadBuildingModelPalettes = () => {
    const variables = { filter: { buildingModelId: buildingModel.id } };
    props.getBuildingModelPalettes(variables)
      .finally(() => props.reloadBuildingModelPalettes(false));
  };

  return (
    <>
      {isAdmin() && (
        <div className="d-flex justify-content-end mb-3">
          <Dropdown />
        </div>
      )}

      {buildingModelPalettes.map((buildingModelPalette) => (
        <ListItem key={`building-model-palette-${buildingModelPalette.id}`} buildingModelPalette={buildingModelPalette} />
      ))}

      <BuildingModelPaletteFormModal />
    </>
  );
};

export default connect((store) => ({
  buildingModel: store.buildingModels.buildingModel,
  buildingModelPalettes: store.buildingModelPalettes.buildingModelPalettes,
  reload: store.buildingModelPalettes.reload
}), {
  getBuildingModelPalettes,
  reloadBuildingModelPalettes
})(Palettes);
