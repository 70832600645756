import React, { useEffect, useState } from 'react';
import {
  Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Table
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { ModalNames } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import { saveForm } from '../../../../../../store/actions/formActions';
import { getError } from '../../../../../../utils/requestUtils';
import ListItem from './ListItem';
import { parseBoolean } from '../../../../../../utils/parserUtils';

const i18nOpts = { scope: 'components.admin.integrations.tracking.forms.formModal.index' };

function onClose() {
  ModalService.close(ModalNames.FORM_FIELDS_MAPPING);
}

const FormModal = ({ opened, params, ...props }) => {
  const [mappedFields, setMappedFields] = useState([]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const onSave = () => {
    setSaving(true);

    const { form } = params;

    const input = {
      id: form.id,
      mappedFields
    };

    props.saveForm({ input })
      .then(() => {
        onClose();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const loadForm = () => {
    const { form } = params;
    setMappedFields(form.mappedFields || []);
    setError(null);
  };

  const onMapped = (name, value) => {
    setMappedFields((prevMappedFields) => prevMappedFields.map((mf) => {
      const mappedField = { ...mf };
      if (mf.mapped === value) mappedField.mapped = null;
      if (mf.name === name) mappedField.mapped = value;
      return mappedField;
    }));
  };

  const onExcluded = (name, value) => {
    setMappedFields((prevMappedFields) => prevMappedFields.map((mf) => {
      const mappedField = { ...mf };
      if (mf.name === name) mappedField.excluded = parseBoolean(value);
      return mappedField;
    }));
  };

  useEffect(() => {
    if (!opened) return;

    loadForm();
  }, [opened]);

  return (
    <Modal isOpen={opened} size="lg">
      <ModalHeader>{i18n.t('title', i18nOpts)}</ModalHeader>

      <ModalBody>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>{i18n.t('name', i18nOpts)}</th>
              <th>{i18n.t('type', i18nOpts)}</th>
              <th>{i18n.t('mapped', i18nOpts)}</th>
              <th className="text-center">{i18n.t('exclude', i18nOpts)}</th>
            </tr>
          </thead>
          <tbody>
            {
              mappedFields.map((mf) => (
                <ListItem
                  key={`mapped-field-${mf.name}`}
                  mappedField={mf}
                  onMapped={onMapped}
                  onExcluded={onExcluded}
                />
              ))
            }
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSave} disabled={saving}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.FORM_FIELDS_MAPPING]?.opened || false,
  params: store.modals[ModalNames.FORM_FIELDS_MAPPING]?.params || {},
}), {
  saveForm,
})(FormModal);
