import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

import InputError, { isInputInvalid } from '../../../ui/InputError';
import { saveUser, getProfile } from '../../../../store/actions/userActions';
import PhoneNumberInput from '../../../global/PhoneNumberInput';
import { getValidPhoneNumber } from '../../../global/PhoneNumberInput/utils';
import { isAdmin } from '../../../../utils/authUtils';
import { Tabs } from '../utils';

const i18nOpts = { scope: 'components.admin.myProfile.form.index' };

const UserForm = ({
  currentUser, error, loading, currentCompany, ...props
}) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    initForm();
  }, []);

  const getRedirectUrl = () => (isAdmin() ? '/admin/overview' : '/admin/communities');

  const onSave = (e) => {
    e.preventDefault();
    const input = {
      id: form.id,
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      phone: getValidPhoneNumber(form.phone),
      companyId: currentCompany.id
    };
    const variables = { input };
    props.saveUser(variables)
      .then(() => {
        toast.success(i18n.t('messages.successfullyUpdated', i18nOpts));
        props.getProfile().then();
        navigate(getRedirectUrl());
      });
  };

  const onCancel = () => {
    navigate(getRedirectUrl());
  };

  const initForm = () => {
    const initData = {
      ...currentUser,
      partialPhone: currentUser?.phone
    };
    setForm(initData);
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onPhoneNumberChange = (value) => {
    setForm((prevForm) => ({ ...prevForm, phone: value }));
  };

  const onChangePassword = () => {
    navigate(`/admin/myProfile?tab=${Tabs.SECURITY}`);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <h2 className="mb-4">{i18n.t('title', i18nOpts)}</h2>

      <Form onSubmit={onSave}>
        <Row form className="mb-5">
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="firstName">{i18n.t('firstName', i18nOpts)}</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={form.firstName || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'firstName')}
              />
              <InputError name="firstName" error={error} />
            </FormGroup>

            <FormGroup>
              <Label for="lastName">{i18n.t('lastName', i18nOpts)}</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={form.lastName || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'lastName')}
              />
              <InputError name="lastName" error={error} />
            </FormGroup>

            <FormGroup>
              <Label for="email">{i18n.t('email', i18nOpts)}</Label>
              <Input
                type="text"
                name="email"
                id="email"
                value={form.email || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'email')}
              />
              <InputError name="email" error={error} />
            </FormGroup>

            <FormGroup>
              <Label for="phone">{i18n.t('phone', i18nOpts)}</Label>

              <PhoneNumberInput
                value={form.phone || ''}
                onChange={onPhoneNumberChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="password">{i18n.t('password', i18nOpts)}</Label>
              <InputGroup>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value="********"
                  className="bg-white"
                  readOnly
                />
                <InputGroupText role="button" onClick={onChangePassword}>
                  {i18n.t('changePassword', i18nOpts)}
                </InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <Button color="primary" className="mr-3" disabled={loading}>
          {loading && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onCancel} className="mr-3">
          {i18n.t('buttons.cancel')}
        </Button>
      </Form>
    </div>
  );
};

export default connect((store) => ({
  currentUser: store.users.currentUser,
  loading: store.users.saveUser.loading || false,
  error: store.users.saveUser.error || {},
  currentCompany: store.companies.currentCompany
}), {
  saveUser,
  getProfile
})(UserForm);
