import React from 'react';
import classNames from 'classnames';

import styles from './Overview.module.scss';
import PersonalSection from './PersonalSection';
import SalesSection from './SalesSection';
import FinancialReportModal from './FinancialReportModal';
import VerificationSection from './VerificationSection';
import BackLink from '../components/BackLink';
import ActionsMenu from '../components/ActionsMenu';
import BuildersSection from './BuildersSection';

const Overview = () => (
  <div className={classNames(styles.container)}>
    <div className={classNames('mb-3 d-flex align-items-center justify-content-between', styles.actionsBar)}>
      <BackLink />

      <div>
        <ActionsMenu />
      </div>
    </div>

    <PersonalSection />
    <VerificationSection />
    <SalesSection />
    <BuildersSection />

    <FinancialReportModal />
  </div>
);

Overview.propTypes = {};

Overview.defaultProps = {};

export default Overview;
