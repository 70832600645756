import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import v from 'voca';
import PropTypes from 'prop-types';

import useForm from '../../../../../hooks/useForm';
import { stripToEmpty } from '../../../../../utils/stringUtils';
import { getError } from '../../../../../utils/requestUtils';
import { isFormValid } from '../../../../../utils/formUtils';
import ModalCloseButton from '../../../../common/ModalCloseButton';
import InputError, { isInputInvalid } from '../../../../ui/InputError';
import DeleteButton from '../../../../global/DeleteButton';
import { ModalNames } from '../../../../../constants';
import { deleteMortgageProvider, saveMortgageProvider, setReloadMortgageProviders } from '../../../../../store/actions/mortgageProviderActions';
import ModalService from '../../../../../services/ModalService';
import ImagesUploader from '../../../../common/ImagesUploader';

const i18nOpts = { scope: 'components.admin.mortgageProviders.components.formModal.index' };

function onCloseModal() {
  ModalService.close(ModalNames.MORTGAGE_PROVIDER_FORM);
}

const FormModal = ({
  opened, params, currentCompany, ...props
}) => {
  const {
    form, submitting, setSubmitting, onTextChange, error, setError, setForm
  } = useForm();
  const [image, setImage] = useState(undefined);
  const { mortgageProvider } = params;
  const isNew = useMemo(() => !mortgageProvider, [mortgageProvider]);

  const onImageChange = (fieldName, newImages) => {
    setImage(newImages[0]);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setSubmitting(true);

    const input = {
      name: stripToEmpty(form.name),
      url: stripToEmpty(form.url)
    };

    if (isNew) input.companyId = parseInt(form.companyId, 10) || 0;
    else input.id = form.id;

    const updatedImage = form.initialImages?.length > 0 && image === undefined ? null : image?.file;

    const variables = { input, image: updatedImage };
    props.saveMortgageProvider(variables)
      .then(() => {
        props.setReloadMortgageProviders(true);
        onCloseModal();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSubmitting(false));
  };

  const onDelete = () => {
    props.deleteMortgageProvider(mortgageProvider.id)
      .then(() => {
        props.setReloadMortgageProviders(true);
        props.onAfterDelete();
        onCloseModal();
      });
  };

  const loadMortgageProvider = () => {
    let formData = {};

    if (isNew) formData = { companyId: currentCompany.id };
    else {
      const { imageUrl, company } = mortgageProvider;
      const initialImages = !v.isBlank(imageUrl) ? [{ id: mortgageProvider.id, imageUrl }] : [];
      formData = { ...mortgageProvider, companyId: company.id, initialImages };
    }

    setForm(formData);
  };

  useEffect(() => {
    loadMortgageProvider();
  }, [opened]);

  const isValid = isFormValid(form, ['name', 'url', 'companyId']);

  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

  return (
    <Modal size="sm" isOpen={opened} modalClassName="right" scrollable>
      <ModalHeader close={<ModalCloseButton onClick={onCloseModal} />}>{title}</ModalHeader>

      <ModalBody className="pt-0">
        <hr className="mt-0 mb-4" />

        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label for="name">
              {i18n.t('form.name', i18nOpts)}
              <span title={i18n.t('labels.required')}> *</span>
            </Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={form.name || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'name')}
              placeholder={i18n.t('placeholders.name', i18nOpts)}
            />
            <InputError name="name" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="url">
              {i18n.t('form.url', i18nOpts)}
              <span title={i18n.t('labels.required')}> *</span>
            </Label>
            <Input
              type="text"
              name="url"
              id="url"
              value={form.url || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'url')}
              placeholder={i18n.t('placeholders.url', i18nOpts)}
            />
            <InputError name="url" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="image">{i18n.t('form.image', i18nOpts)}</Label>
            <ImagesUploader fieldName="image" multiple={false} onChange={onImageChange} initialImages={form.initialImages} />
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter className="justify-content-between gap-2">
        {!isNew && (
          <div className="flex-fill">
            <DeleteButton onDelete={onDelete} block />
          </div>
        )}
        <div className="flex-fill">
          <Button outline color="secondary" block onClick={onCloseModal}>
            {i18n.t('buttons.cancel')}
          </Button>
        </div>
        <div className="flex-fill">
          <Button onClick={onSubmit} block color="primary" disabled={submitting || !isValid}>
            {submitting && (<Spinner size="sm" className="mr-2" />)}
            {isNew ? i18n.t('buttons.create') : i18n.t('buttons.save')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

FormModal.propTypes = {
  onAfterDelete: PropTypes.func
};

FormModal.defaultProps = {
  onAfterDelete: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  opened: store.modals[ModalNames.MORTGAGE_PROVIDER_FORM]?.opened || false,
  params: store.modals[ModalNames.MORTGAGE_PROVIDER_FORM]?.params || {},
}), {
  saveMortgageProvider,
  deleteMortgageProvider,
  setReloadMortgageProviders,
})(FormModal);
