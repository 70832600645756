import React from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import { isCompanyUser, isSalesUser } from '../../../utils/authUtils';

const i18nOpts = { scope: 'components.common.addItemDropdown.index' };

const AddItemDropdown = ({
  items, open, toggleDropdown, onSelect, label
}) => (
  <Dropdown
    isOpen={open}
    toggle={() => toggleDropdown(!open)}
    disabled={isCompanyUser() || isSalesUser()}
  >
    <DropdownToggle color="outline-secondary" caret disabled={!items.length}>
      {label || i18n.t('add', i18nOpts)}
    </DropdownToggle>
    <DropdownMenu right>
      {
        items.map((item) => (
          <DropdownItem
            key={`available-palette-${item.id}`}
            onClick={() => onSelect(item.id)}
          >
            {item.name}
          </DropdownItem>
        ))
      }
    </DropdownMenu>
  </Dropdown>
);

AddItemDropdown.propTypes = {
  label: PropTypes.string
};

AddItemDropdown.defaultProps = {
  label: null
};

export default AddItemDropdown;
