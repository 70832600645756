import React from 'react';
import { IoClose } from 'react-icons/io5';

import styles from './CloseButton.module.scss';
import { toggleSidebar } from '../../utils';

const CloseButton = () => {
  const onClose = () => {
    toggleSidebar();
  };

  return (
    <div className={styles.container}>
      <IoClose size={26} onClick={onClose} />
    </div>
  );
};

CloseButton.propTypes = {};

CloseButton.defaultProps = {};

export default CloseButton;
