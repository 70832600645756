import {
  CLEAN_TEAM_USERS,
  GET_TEAM_USER,
  SAVE_TEAM_USER,
  DELETE_TEAM_USER,
  LIST_TEAM_USERS,
  SET_RELOAD_TEAM_USERS
} from './types';
import api from '../../api';
import {
  createTeamUserQuery, deleteTeamUserQuery,
  getTeamUserQuery,
  listTeamUsersQuery,
  updateTeamUserQuery
} from '../../graphql';

export function listTeamUsers(variables = {}) {
  return {
    type: LIST_TEAM_USERS,
    payload: api.graphql(listTeamUsersQuery, variables)
  };
}

export function getTeamUser(id) {
  return {
    type: GET_TEAM_USER,
    payload: api.graphql(getTeamUserQuery, { id })
  };
}

export function cleanTeamUsers(extraProps = {}) {
  return {
    type: CLEAN_TEAM_USERS,
    payload: Promise.resolve(extraProps)
  };
}

export function saveTeamUser(variables = {}) {
  const query = variables.input.id ? updateTeamUserQuery : createTeamUserQuery;
  return {
    type: SAVE_TEAM_USER,
    payload: api.graphql(query, variables)
  };
}

export function deleteTeamUser(id) {
  return {
    type: DELETE_TEAM_USER,
    payload: api.graphql(deleteTeamUserQuery, { id })
  };
}

export function setReloadTeamUsers(reload) {
  return {
    type: SET_RELOAD_TEAM_USERS,
    payload: { reload }
  };
}
