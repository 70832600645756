import {
  GET_SELLER_SPACE, GET_SELLER_SPACES, SAVE_SELLER_SPACE, DELETE_SELLER_SPACE
} from './types';
import {
  createSellerSpaceQuery, deleteSellerSpaceQuery,
  getSellerSpaceQuery,
  listSellersSpacesQuery,
  updateSellerSpaceQuery
} from '../../graphql';
import api from '../../api';

export function getSellerSpaces(variables = {}) {
  return {
    type: GET_SELLER_SPACES,
    payload: api.graphql(listSellersSpacesQuery, variables)
  };
}

export function getSellerSpace(id) {
  return {
    type: GET_SELLER_SPACE,
    payload: api.graphql(getSellerSpaceQuery, { id })
  };
}

export function saveSellerSpace(variables = {}) {
  const query = variables?.input?.id ? updateSellerSpaceQuery : createSellerSpaceQuery;
  return {
    type: SAVE_SELLER_SPACE,
    payload: api.graphql(query, variables)
  };
}

export function deleteSellerSpace(id) {
  return {
    type: DELETE_SELLER_SPACE,
    payload: api.graphql(deleteSellerSpaceQuery, { id })
  };
}
