import React, { useState } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';

import './styles.scss';
import { Colors } from '../../../constants';
import { CALENDAR_LOCALE } from './utils';

const CalendarPicker = ({ ...props }) => {
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });

  return (
    <Calendar
      value={selectedDayRange}
      onChange={setSelectedDayRange}
      colorPrimary={Colors.PRIMARY_600}
      colorPrimaryLight={Colors.GRAY_100}
      calendarClassName="calendar-container"
      calendarRangeStartClassName="calendar-range-start"
      calendarRangeEndClassName="calendar-range-end"
      calendarTodayClassName="calendar-today"
      locale={CALENDAR_LOCALE}
      {...props}
    />
  );
};

CalendarPicker.propTypes = {};

CalendarPicker.defaultProps = {};

export default CalendarPicker;
