import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import i18n from 'i18n-js';
import classNames from 'classnames';

import { Tabs } from '../utils';

const i18nOpts = { scope: 'components.admin.integrations.sendGrid.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <Nav tabs>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.GENERAL_SETTINGS })}
        onClick={() => onChange(Tabs.GENERAL_SETTINGS)}
      >
        {i18n.t('generalSettings', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.MAILING_LIST })}
        onClick={() => onChange(Tabs.MAILING_LIST)}
      >
        {i18n.t('mailingList', i18nOpts)}
      </NavLink>
    </NavItem>
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
