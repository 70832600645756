import React from 'react';
import PropTypes from 'prop-types';
import { BsArrowLeftShort } from 'react-icons/bs';
import i18n from 'i18n-js';

const BackLink = ({ onClick }) => (
  <div className="d-inline-flex align-items-center" role="button" aria-hidden onClick={onClick}>
    <BsArrowLeftShort size="1.5rem" />
    <span className="ml-1 font-weight-600">{i18n.t('buttons.back')}</span>
  </div>
);

BackLink.propTypes = {
  onClick: PropTypes.func
};

BackLink.defaultProps = {
  onClick: () => {}
};

export default BackLink;
