import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, FormGroup, Label, Input, Row, Col, Button, Spinner, Card
} from 'reactstrap';
import { navigate, Link } from 'gatsby';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import v from 'voca';
import { BiTrash } from 'react-icons/bi';

import './styles.scss';
import { saveModel, getModel, deleteModel } from '../../../../store/actions/modelActions';
import { getParam } from '../../../../utils/paramsUtils';
import { hasFormValues } from '../../../../utils/formUtils';
import api from '../../../../api';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import { parseBoolean, parseToFloat } from '../../../../utils/parserUtils';
import { listProductTypesQuery } from '../../../../graphql';
import { stripToEmpty, stripToNull } from '../../../../utils/stringUtils';
import DeleteButton from '../../../global/DeleteButton';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';

const i18nOpts = { scope: 'components.admin.models.form.index' };

const ModelStatuses = Object.freeze([
  { value: 'DRAFT', label: i18n.t('statuses.draft') },
  { value: 'PUBLISHED', label: i18n.t('statuses.published') },
]);

function fetchProductTypesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(listProductTypesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

class ModelForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      form: {},
      saving: false,
      image: undefined,
      productTypes: [],
      error: null,
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
  }

  componentDidMount() {
    this.loadProductTypes();

    const id = parseInt(getParam('id'), 10);
    this.setState({ id }, this.loadModel);
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;
    form[name] = value;
    this.setState({ form });
  }

  onSave() {
    const { props } = this;
    const { image } = this.state;

    const input = this.getFormInput();

    this.setState({ saving: true });

    const variables = { input, image };
    props.saveModel(variables)
      .then(() => {
        navigate('/admin/models').then();
      })
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);

        this.setState({ saving: false, error });
      });
  }

  onDelete() {
    const { props } = this;
    const { model } = this.props;

    props.deleteModel(model.id)
      .then(() => navigate('/admin/models'));
  }

  onImageChange(event) {
    const { name } = event.target;
    const image = event.target.files[0];
    this.setState({ [name]: image });
  }

  onDeleteImage() {
    this.setState({ image: null });
  }

  getFormInput() {
    const { form } = this.state;
    const {
      id, name, intro, tourUrl, status, uTourUrl, externalId
    } = form;
    const { currentCompany } = this.props;

    const input = {
      name: stripToEmpty(name),
      intro: stripToNull(intro),
      tourUrl: stripToNull(tourUrl),
      uTourUrl: stripToNull(uTourUrl),
      productTypeId: parseInt(form.productTypeId, 10) || 0,
      openTourUrlInOverlayWindow: parseBoolean(form.openTourUrlInOverlayWindow),
      externalId: stripToNull(externalId)
    };

    if (this.isNew()) input.companyId = currentCompany.id;
    else input.id = id;

    const beds = parseToFloat(form.beds);
    if (!Number.isNaN(beds) || beds === null) input.beds = beds;

    const baths = parseToFloat(form.baths);
    if (!Number.isNaN(baths) || baths === null) input.baths = baths;

    const sizeSqft = parseToFloat(form.sizeSqft);
    if (!Number.isNaN(sizeSqft) || sizeSqft === null) input.sizeSqft = sizeSqft;

    const basePrice = parseToFloat(form.basePrice);
    if (!Number.isNaN(basePrice) || basePrice === null) input.basePrice = basePrice;

    if (!v.isBlank(status)) input.status = status;

    return input;
  }

  getPreviewImageUrl() {
    const { image } = this.state;
    if (image === null) return null;
    return getLocalImageUrl(image) || this.getImageUrl();
  }

  getImageUrl() {
    const { model } = this.props;
    const { id } = this.state;
    if (!model) return null;

    return model.id === id ? model.imageUrl : null;
  }

  loadProductTypes() {
    const { currentCompany } = this.props;
    fetchProductTypesAsync(currentCompany.id)
      .then((productTypes) => {
        this.setState({ productTypes });
      })
      .catch(() => {});
  }

  loadModel() {
    const { props } = this;
    const { id } = this.state;

    if (this.isNew()) {
      this.setState({ form: {} });
      return;
    }

    props.getModel(id)
      .then(() => {
        const { model } = this.props;

        if (model) {
          const {
            name, intro, beds, baths, sizeSqft, basePrice, productType,
            tourUrl, status, openTourUrlInOverlayWindow,
            uTourUrl, externalId
          } = model;
          const form = {
            id,
            name,
            intro,
            beds,
            baths,
            sizeSqft,
            basePrice,
            tourUrl,
            productTypeId: productType?.id,
            status,
            openTourUrlInOverlayWindow,
            uTourUrl,
            externalId
          };
          this.setState({ form });
        } else navigate('/admin/models').then();
      })
      .catch(() => navigate('/admin/models'));
  }

  isNew() {
    const { id } = this.state;
    return !id;
  }

  render() {
    const {
      form, saving, productTypes, error
    } = this.state;

    const isNew = this.isNew();
    const hasValues = hasFormValues(form);
    const previewImageUrl = this.getPreviewImageUrl();

    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    return (
      <div>
        <Helmet title={title} />

        <h2 className="mb-4">{title}</h2>

        <Card body>
          <Form>
            <Row className="mb-4">
              <Col xs="12" sm="12" md="6" lg="4">
                <FormGroup>
                  <Label for="productTypeId">{i18n.t('productTypeId', i18nOpts)}</Label>
                  <Input
                    type="select"
                    name="productTypeId"
                    id="productTypeId"
                    value={form.productTypeId || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'productTypeId')}
                  >
                    <option value="">{i18n.t('select.select')}</option>
                    {
                      productTypes.map((pt) => (
                        <option key={`product-type-option-${pt.id}`} value={pt.id}>
                          {pt.name}
                        </option>
                      ))
                    }
                  </Input>
                  <InputError error={error} name="productTypeId" />
                </FormGroup>

                <FormGroup>
                  <Label for="name">{i18n.t('name', i18nOpts)}</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={form.name || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'name')}
                  />
                  <InputError error={error} name="name" />
                </FormGroup>

                <FormGroup>
                  <Label for="intro">{i18n.t('intro', i18nOpts)}</Label>
                  <Input
                    type="textarea"
                    name="intro"
                    id="intro"
                    value={form.intro || ''}
                    onChange={this.onTextChange}
                    rows={5}
                    invalid={isInputInvalid(error, 'intro')}
                  />
                  <InputError error={error} name="intro" />
                </FormGroup>

                <FormGroup>
                  <Label for="beds">{i18n.t('beds', i18nOpts)}</Label>
                  <Input
                    type="number"
                    name="beds"
                    id="beds"
                    value={form.beds || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'beds')}
                  />
                  <InputError error={error} name="beds" />
                </FormGroup>

                <FormGroup>
                  <Label for="baths">{i18n.t('baths', i18nOpts)}</Label>
                  <Input
                    type="number"
                    name="baths"
                    id="baths"
                    value={form.baths || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'baths')}
                  />
                  <InputError error={error} name="baths" />
                </FormGroup>

                <FormGroup>
                  <Label for="sizeSqft">{i18n.t('sizeSqft', i18nOpts)}</Label>
                  <Input
                    type="number"
                    name="sizeSqft"
                    id="sizeSqft"
                    value={form.sizeSqft || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'sizeSqft')}
                  />
                  <InputError error={error} name="sizeSqft" />
                </FormGroup>

                <FormGroup>
                  <Label for="basePrice">{i18n.t('basePrice', i18nOpts)}</Label>
                  <Input
                    type="number"
                    name="basePrice"
                    id="basePrice"
                    value={form.basePrice || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'basePrice')}
                  />
                  <InputError error={error} name="basePrice" />
                </FormGroup>
              </Col>
              <Col xs="12" sm="12" md="6" lg={{ offset: 2, size: 4 }}>
                <FormGroup>
                  <Label for="status">{i18n.t('status', i18nOpts)}</Label>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    value={form.status || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'status')}
                  >
                    {
                      ModelStatuses.map((s) => (
                        <option key={`status-option-${s.value}`} value={s.value}>
                          {s.label}
                        </option>
                      ))
                    }
                  </Input>
                  <InputError error={error} name="status" />
                </FormGroup>

                <FormGroup>
                  <Label for="image">{i18n.t('image', i18nOpts)}</Label>
                  {!v.isBlank(previewImageUrl) && (
                    <div className="mb-2 position-relative">
                      <img src={previewImageUrl} alt="" className="w-100" />
                      <button
                        type="button"
                        className="text-danger rounded-circle bg-dark delete-icon"
                        onClick={this.onDeleteImage}
                        title={i18n.t('buttons.delete')}
                      >
                        <BiTrash size="1.1rem" />
                      </button>
                    </div>
                  )}
                  <Input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/*"
                    onChange={this.onImageChange}
                  />
                </FormGroup>

                <FormGroup className="mb-1">
                  <Label for="tourUrl">{i18n.t('tourUrl', i18nOpts)}</Label>
                  <Input
                    type="text"
                    name="tourUrl"
                    id="tourUrl"
                    value={form.tourUrl || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'tourUrl')}
                  />
                  <InputError error={error} name="tourUrl" />
                </FormGroup>

                <FormGroup check className="mb-3">
                  <Label check className="text-muted">
                    <Input
                      type="checkbox"
                      name="openTourUrlInOverlayWindow"
                      id="openTourUrlInOverlayWindow"
                      value={!parseBoolean(form.openTourUrlInOverlayWindow)}
                      checked={parseBoolean(form.openTourUrlInOverlayWindow)}
                      onChange={this.onTextChange}
                    />
                    <small>{i18n.t('openTourUrlInOverlayWindow', i18nOpts)}</small>
                  </Label>
                </FormGroup>

                <FormGroup className="mt-3">
                  <Label for="uTourUrl">{i18n.t('uTourUrl', i18nOpts)}</Label>
                  <Input
                    type="text"
                    name="uTourUrl"
                    id="uTourUrl"
                    value={form.uTourUrl || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'uTourUrl')}
                  />
                  <InputError error={error} name="uTourUrl" />
                </FormGroup>

                <FormGroup className="mt-3">
                  <Label for="uTourUrl">{i18n.t('externalId', i18nOpts)}</Label>
                  <Input
                    type="text"
                    name="externalId"
                    id="externalId"
                    value={form.externalId || ''}
                    onChange={this.onTextChange}
                    invalid={isInputInvalid(error, 'externalId')}
                  />
                  <InputError error={error} name="externalId" />
                </FormGroup>
              </Col>
            </Row>

            <Button color="primary" onClick={this.onSave} className="mr-3" disabled={saving || !hasValues}>
              {saving && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.save')}
            </Button>
            <Link to="/admin/models" className="btn btn-outline-secondary mr-3">
              {i18n.t('buttons.cancel')}
            </Link>
            {!isNew && (
              <DeleteButton onDelete={this.onDelete} />
            )}
          </Form>
        </Card>
      </div>
    );
  }
}

export default connect((store) => ({
  model: store.models.model,
  currentCompany: store.companies.currentCompany
}), {
  saveModel,
  getModel,
  deleteModel
})(ModelForm);
