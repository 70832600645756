import { useSelector } from 'react-redux';

import { ModalNames, SalesInvitationStartTypes } from '../../../../../constants';

const useInvitationStartType = () => {
  const { params } = useSelector((store) => ({
    params: store.modals[ModalNames.SALES_INVITATION]?.params || {}
  }));

  const { startType } = params;

  const isFromHome = startType === SalesInvitationStartTypes.FROM_HOME;
  const isFromCustomer = startType === SalesInvitationStartTypes.FROM_CUSTOMER;

  return { isFromHome, isFromCustomer };
};

export default useInvitationStartType;
