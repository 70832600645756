import React from 'react';

const IncreasingTrendIcon = ({ ...props }) => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.375 11.875H2.49167C2.1883 11.875 2.03662 11.875 1.92076 11.816C1.81883 11.764 1.73597 11.6812 1.68404 11.5792C1.625 11.4634 1.625 11.3117 1.625 11.0083V2.125M10.8333 4.83333L8.71061 7.09894C8.63017 7.1848 8.58994 7.22773 8.54141 7.24992C8.49858 7.26951 8.45139 7.27759 8.40448 7.27336C8.35134 7.26857 8.29913 7.24146 8.1947 7.18724L6.4303 6.27109C6.32588 6.21687 6.27366 6.18976 6.22052 6.18497C6.17361 6.18075 6.12642 6.18883 6.08359 6.20841C6.03506 6.2306 5.99484 6.27353 5.91439 6.35939L3.79167 8.625" stroke="#009462" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IncreasingTrendIcon.propTypes = {};

IncreasingTrendIcon.defaultProps = {};

export default IncreasingTrendIcon;
