import React from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import classNames from 'classnames';

import useFetchSalesReps from '../../../hooks/useFetchSalesReps';
import useFetchTeams from '../../../hooks/useFetchTeams';
import { SellerTypes } from '../../../constants';
import { isSalesRep, isSalesTeam } from '../../../utils/salesRepUtils';

const i18nOpts = { scope: 'components.global.salesRepDropdown.index' };

const SalesRepDropdown = ({
  selectedSalesRep, currentCompany, onChange, defaultLabel, defaultLabelClassName, size
}) => {
  const [salesReps] = useFetchSalesReps(currentCompany.id);
  const { teams: salesTeams } = useFetchTeams(currentCompany.id);
  const hasSalesRepsAndTeams = salesReps.length > 0 && salesTeams.length > 0;

  const onSelect = (salesRepOrTeam, type) => {
    const newSalesRep = salesRepOrTeam ? { ...salesRepOrTeam, __typename: type } : null;

    onChange(newSalesRep);
  };

  const label = defaultLabel || i18n.t('select.select');

  return (
    <UncontrolledDropdown>
      <DropdownToggle color="outline-secondary" size={size} caret>
        {selectedSalesRep ? (
          <span>{selectedSalesRep.name}</span>
        ) : (
          <span className={classNames(defaultLabelClassName)}>{label}</span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem className="text-secondary" onClick={() => onSelect(null)}>
          {i18n.t('select.select')}
        </DropdownItem>

        {salesTeams.length > 0 && (
          <>
            {hasSalesRepsAndTeams && (
              <h6 className="dropdown-header bg-light">{i18n.t('salesTeams', i18nOpts)}</h6>
            )}

            {
              salesTeams.map((salesTeam) => (
                <DropdownItem
                  key={`sales-team-option-${salesTeam.id}`}
                  onClick={() => onSelect(salesTeam, SellerTypes.SALES_TEAM)}
                  active={salesTeam.id === selectedSalesRep?.id && isSalesTeam(selectedSalesRep)}
                >
                  {salesTeam.name}
                </DropdownItem>
              ))
            }
          </>
        )}

        {salesReps.length > 0 && (
          <>
            {hasSalesRepsAndTeams && (
              <h6 className="dropdown-header bg-light">{i18n.t('salesReps', i18nOpts)}</h6>
            )}

            {
              salesReps.map((salesRep) => (
                <DropdownItem
                  key={`sales-rep-option-${salesRep.id}`}
                  onClick={() => onSelect(salesRep, SellerTypes.SALES_REP)}
                  active={salesRep.id === selectedSalesRep?.id && isSalesRep(selectedSalesRep)}
                >
                  {salesRep.name}
                </DropdownItem>
              ))
            }
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

SalesRepDropdown.propTypes = {
  onChange: PropTypes.func,
  selectedSalesRep: PropTypes.objectOf(PropTypes.any),
  defaultLabel: PropTypes.string,
  defaultLabelClassName: PropTypes.string,
  size: PropTypes.string
};

SalesRepDropdown.defaultProps = {
  onChange: () => {},
  selectedSalesRep: null,
  defaultLabel: null,
  defaultLabelClassName: null,
  size: null
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(SalesRepDropdown);
