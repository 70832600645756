import React from 'react';
import v from 'voca';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import { getHomeInfo } from '../../../../utils/homeUtils';

const i18nOpts = { scope: 'components.global.home.communityProject.index' };

const CommunityProject = ({ home, className }) => {
  const { communityProjectName, cityName } = getHomeInfo(home);

  return (
    <div className={className}>
      {communityProjectName}
      {!v.isBlank(cityName) && i18n.t('city', { ...i18nOpts, city: cityName })}
    </div>
  );
};

CommunityProject.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired
};

CommunityProject.defaultProps = {};

export default CommunityProject;
