import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';

import { setBuildingFloorUnits } from '../../../../../../../store/actions/buildingFloorActions';
import { isCompanyUser, isSalesUser } from '../../../../../../../utils/authUtils';
import MultiSelect from '../../../../../../ui/MultiSelect';

const i18nOpts = { scope: 'components.admin.projectBuildings.show.buildingFloors.unitsMultiSelect.index' };

const UnitsMultiSelect = ({ buildingFloor, units, ...props }) => {
  const onChange = (selectedUnits) => {
    const unitIds = selectedUnits.map((u) => u.id);

    const input = {
      id: buildingFloor.id,
      unitIds
    };

    const variables = { input };

    props.setBuildingFloorUnits(variables);
  };

  const availableUnits = units.filter((u) => !u.buildingFloor);

  return (
    <div>
      <div className="multi-select-container">
        <Label>{i18n.t('selectUnits', i18nOpts)}</Label>
        <MultiSelect
          id={`building-floor-${buildingFloor.id}-units-multiselect`}
          options={availableUnits}
          selectedValues={buildingFloor.units}
          onSelect={onChange}
          onRemove={onChange}
          displayValue="name"
          closeIcon="cancel"
          emptyRecordMsg={i18n.t('empty', i18nOpts)}
          placeholder=""
          disable={isCompanyUser() || isSalesUser()}
        />
      </div>
    </div>
  );
};

UnitsMultiSelect.propTypes = {
  buildingFloor: PropTypes.objectOf(PropTypes.any).isRequired,
  units: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default connect(() => ({}), {
  setBuildingFloorUnits
})(UnitsMultiSelect);
