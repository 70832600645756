import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';
import i18n from 'i18n-js';

import { TrafficSourceTypes } from '../../../../../../constants';
import { getTrafficSourceName, getUtmParams, sortUtmParams } from './utils';
import { getTrafficSourceTypeName } from '../../../../../../utils/enumUtils';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.visit.trafficSource.index' };

const TrafficSource = ({ visitorSession }) => {
  const {
    trafficSourceType,
    trafficSourceData
  } = visitorSession;

  if (v.isBlank(trafficSourceType)
    || [TrafficSourceTypes.DIRECT, TrafficSourceTypes.REFERRAL].includes(trafficSourceType)) {
    return null;
  }

  const utmParams = getUtmParams(trafficSourceData);
  const hasUtmParams = utmParams.length > 0;
  const trafficSourceTypeName = getTrafficSourceTypeName(trafficSourceType);
  const trafficSourceName = getTrafficSourceName(visitorSession);
  const sortedUtmParams = sortUtmParams(utmParams);

  return (
    <div>
      <div>
        <span className="font-weight-500 col-gray-700 mr-1">
          {i18n.t('title', i18nOpts)}
        </span>
        <span>{trafficSourceTypeName}</span>
        {!v.isBlank(trafficSourceName) && (
          <span className="text-success ml-1">{`(${trafficSourceName})`}</span>
        )}
      </div>

      {hasUtmParams && (
        <div>
          {sortedUtmParams.map((utmParam) => (
            <div key={`utm-param-${visitorSession.id}-${utmParam.name}`}>
              <span className="font-weight-500 col-gray-700">
                {i18n.t(utmParam.name, i18nOpts)}
              </span>
              <span>{utmParam.value}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

TrafficSource.propTypes = {
  visitorSession: PropTypes.objectOf(PropTypes.any).isRequired,
};

TrafficSource.defaultProps = {};

export default TrafficSource;
