import React from 'react';
import { connect } from 'react-redux';

import { hasCustomerIdentity } from '../../utils';
import Placeholder from './Placeholder';
import InfoCard from './InfoCard';

const IdVerified = ({ customer }) => {
  const hasIdentity = hasCustomerIdentity(customer);

  return (
    <div className="mb-0">
      {hasIdentity ? (
        <InfoCard />
      ) : (
        <Placeholder />
      )}
    </div>
  );
};

IdVerified.propTypes = {};

IdVerified.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
}))(IdVerified);
