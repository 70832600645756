import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from '../api';
import { listTeamsQuery } from '../graphql';

export function fetchTeamsAsync(companyId) {
  const variables = {
    filter: {
      companyId,
    }
  };

  return api.graphql(listTeamsQuery, variables)
    .then(({ data: { items } }) => items);
}

const useFetchTeams = (companyId) => {
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    setLoading(true);

    fetchTeamsAsync(companyId)
      .then((items) => setTeams(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { teams, loading };
};

export default useFetchTeams;
