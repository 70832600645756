import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Col, Collapse, Row
} from 'reactstrap';
import { MdOpenInNew } from 'react-icons/md';
import i18n from 'i18n-js';

import TimeLineElement from '../../../../common/TimeLineElement';
import { MappableFields } from '../../../../../constants';
import { getFormattedStringArray } from '../../../../../utils/arrayUtils';
import { removeDomainFromURL } from '../../../../../utils/urlUtils';
import { filterFormFields, getMappedValue, truncateMessage } from './utils';
import FormSubmissionIcon
  from '../../../../../assets/icons/customer-activities/FormSubmissionIcon';
import ActivityDate from '../components/ActivityDate';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.formSubmission.index' };

const FormSubmission = ({ activity, innerKey }) => {
  const [expanded, setExpanded] = useState(false);
  const { formSubmission } = activity;
  if (!formSubmission) return null;

  const { form } = formSubmission;
  const mappedFields = form?.mappedFields || [];
  const mappedValues = formSubmission?.formData || [];

  const messageValue = getMappedValue(
    mappedFields,
    mappedValues,
    MappableFields.MESSAGE
  );
  const filteredFormFields = filterFormFields(mappedFields, mappedValues);
  const [isTruncatedMessage, message] = truncateMessage(messageValue, !expanded);

  const toggleFields = () => {
    setExpanded(!expanded);
  };

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<FormSubmissionIcon />}
    >
      <div className="d-flex align-items-start gap-3">
        <div className="activity-timeline-title">{i18n.t('title', i18nOpts)}</div>
        <ActivityDate date={activity.createdAt} />
      </div>
      <div>{i18n.t('description', i18nOpts)}</div>

      <Card body className="mt-2">
        <Row>
          <Col className="col-auto mw-50">{form.name}</Col>
          <Col>
            <a href={form.url} target="_blank" rel="noreferrer" className="word-break-break-all">
              {removeDomainFromURL(form?.url)}
              <MdOpenInNew className="ml-1" />
            </a>
          </Col>
        </Row>

        <Row>
          <Col className="pt-4 px-4 pb-0">
            <span>{message}</span>

            <Collapse isOpen={expanded}>
              {filteredFormFields.length > 0 && (
                <>
                  <hr />

                  {
                    filteredFormFields.map((field) => (
                      <div className="mb-3" key={`field-${field.name}`}>
                        <div className="label">{field.name}</div>
                        <div>
                          <strong>
                            {
                              Array.isArray(field.value)
                                ? getFormattedStringArray(field.value)
                                : field.value
                            }
                          </strong>
                        </div>
                      </div>
                    ))
                  }
                </>
              )}
            </Collapse>
          </Col>
        </Row>

        {(isTruncatedMessage || filteredFormFields.length > 0) && (
          <Row>
            <Col className="text-right">
              <span
                role="button"
                aria-hidden
                className="small col-primary-1 text-decoration-underline"
                onClick={toggleFields}
              >
                {i18n.t(`${expanded ? 'seeLess' : 'seeMore'}`, i18nOpts)}
              </span>
            </Col>
          </Row>
        )}
      </Card>

    </TimeLineElement>
  );
};

FormSubmission.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

FormSubmission.defaultProps = {};

export default FormSubmission;
