import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import ListItem from './ListItem';

const GridView = ({ projects }) => (
  <Row>
    {
      projects.map((p) => (
        <Col key={`project-${p.id}`} xs="12" sm="6" md="6" lg="4" className="mb-4">
          <ListItem project={p} />
        </Col>
      ))
    }
  </Row>
);

export default connect((store) => ({
  projects: store.projects.projects
}))(GridView);
