import React, { Component } from 'react';
import PropTypes from 'prop-types';

const CONFIG = {
  toolbar: ['heading', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', 'blockQuote', '|', 'insertTable'],
};

class CKEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    this.CKEditor = require('@ckeditor/ckeditor5-react');
    this.ClassicEditor = require('@ckeditor/ckeditor5-build-classic');
    this.setState({ loaded: true });
  }

  render() {
    const { loaded } = this.state;
    if (!loaded) return null;

    const { name, onTextChange, ...extraProps } = this.props;

    return (
      <this.CKEditor
        editor={this.ClassicEditor}
        config={CONFIG}
        onInit={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle('min-height', '180px', editor.editing.view.document.getRoot());
          });
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          const target = { name, value: data };
          onTextChange({ target });
        }}
        {...extraProps}
      />
    );
  }
}

CKEditor.propTypes = {
  name: PropTypes.string.isRequired,
  onTextChange: PropTypes.func
};

CKEditor.defaultProps = {
  onTextChange: () => {}
};

export default CKEditor;
