import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import i18n from 'i18n-js';

import { getTeamUserPermissionName } from '../../../../../../../utils/enumUtils';
import ActionsMenu from './ActionsMenu';
import { canManageTeamMembers } from '../../../../../../../utils/teamUtils';

const ListItem = ({ teamUser }) => {
  const { team } = teamUser;

  return (
    <tr>
      <td>
        <Link to={`/admin/teams/${team.id}`} className="font-weight-600">{i18n.t('buttons.view')}</Link>
      </td>
      <td className="font-weight-500">
        {team.name}
      </td>
      <td>{getTeamUserPermissionName(teamUser.permission)}</td>
      <td>
        {canManageTeamMembers(teamUser.permission) && (
          <ActionsMenu teamUser={teamUser} />
        )}
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  teamUser: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
