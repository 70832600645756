import React from 'react';

const PalettesIcon = ({ ...props }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 11C1 16.5228 5.47715 21 11 21C12.6569 21 14 19.6569 14 18V17.5C14 17.0356 14 16.8034 14.0257 16.6084C14.2029 15.2622 15.2622 14.2029 16.6084 14.0257C16.8034 14 17.0356 14 17.5 14H18C19.6569 14 21 12.6569 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 12C6.55228 12 7 11.5523 7 11C7 10.4477 6.55228 10 6 10C5.44772 10 5 10.4477 5 11C5 11.5523 5.44772 12 6 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 8C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6C14.4477 6 14 6.44772 14 7C14 7.55228 14.4477 8 15 8Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 7C9.55228 7 10 6.55228 10 6C10 5.44772 9.55228 5 9 5C8.44772 5 8 5.44772 8 6C8 6.55228 8.44772 7 9 7Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

PalettesIcon.propTypes = {};

PalettesIcon.defaultProps = {};

export default PalettesIcon;
