import React from 'react';
import { Badge, ListGroupItem } from 'reactstrap';
import i18n from 'i18n-js';
import { Link } from 'gatsby';

import { isAdmin } from '../../../../../../utils/authUtils';
import { DEFAULT_IMAGE, NavigationMainNames } from '../../../../../../constants';
import ActionsMenu from './ActionsMenu';
import { formatCurrency, isValidAmount } from '../../../../../../utils/currencyUtils';
import NavigationService from '../../../../../../services/NavigationService';
import './styles.scss';

const i18nOpts = { scope: 'components.admin.buildingModels.show.palettes.listItem.index' };

const ListItem = ({ buildingModelPalette }) => {
  if (!buildingModelPalette) return null;

  const imageUrl = buildingModelPalette.palette?.defaultImageUrl || DEFAULT_IMAGE;
  const paletteUrl = NavigationService.getNavigationShowUrl(
    buildingModelPalette.palette, NavigationMainNames.PALETTES
  );

  return (
    <ListGroupItem>
      <div className="d-flex">
        <div className="pr-3">
          <Link to={paletteUrl}>
            <img
              src={imageUrl}
              alt={buildingModelPalette.palette.name}
              className="building-model-palette-image"
            />
          </Link>
        </div>

        <div className="flex-grow-1">
          <div>
            <Link to={paletteUrl}>
              {buildingModelPalette.palette.name}
            </Link>
            {buildingModelPalette.isDefault && (
              <Badge color="info" pill className="ml-3">{i18n.t('default', i18nOpts)}</Badge>
            )}
          </div>

          {isValidAmount(buildingModelPalette.price) && (
            <div>{formatCurrency(buildingModelPalette.price)}</div>
          )}
        </div>
        {isAdmin() && (
          <div>
            <ActionsMenu buildingModelPalette={buildingModelPalette} />
          </div>
        )}
      </div>
    </ListGroupItem>
  );
};

export default ListItem;
