import React from 'react';

import styles from './StatCardRowSeparator.module.scss';

const StatCardRowSeparator = () => (
  <div className={styles.separator} />
);

StatCardRowSeparator.propTypes = {};

StatCardRowSeparator.defaultProps = {};

export default StatCardRowSeparator;
