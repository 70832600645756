import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SingleFamilyMenu from '../SingleFamilyMenu';
import MultiFamilyMenu from '../MultiFamilyMenu';
import './styles.scss';

const HomesSideBar = ({ enableSingleFamily, enableMultiFamily }) => {
  if (enableSingleFamily && enableMultiFamily) {
    return (
      <>
        <SingleFamilyMenu />

        <MultiFamilyMenu />
      </>
    );
  }

  return enableMultiFamily ? <MultiFamilyMenu /> : <SingleFamilyMenu />;
};

HomesSideBar.propTypes = {
  enableSingleFamily: PropTypes.bool.isRequired,
  enableMultiFamily: PropTypes.bool.isRequired,
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(HomesSideBar);
