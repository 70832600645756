import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ModalHeader, Modal, ModalBody, ModalFooter, Button, Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';

import styles from './ExistingCustomerModal.module.scss';
import ModalCloseButton from '../../../../../common/ModalCloseButton';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';
import ExistingCustomerImage from '../../../../../../assets/images/ExistingCustomerImage';
import { findContactAsync } from './utils';
import useHomeRental from '../../../../../../hooks/useHomeRental';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.newCustomer.existingCustomerModal.index' };

function onCloseModal() {
  ModalService.close(ModalNames.EXISTING_CUSTOMER);
}

const ExistingCustomerModal = ({
  opened, onAdd, params, currentCompany
}) => {
  const [adding, setAdding] = useState(false);
  const { typeI18nKey } = useHomeRental();

  const onAddExisting = () => {
    setAdding(true);

    const { email } = params;
    findContactAsync(email, currentCompany.id)
      .then((contact) => {
        onCloseModal();
        onAdd(contact);
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setAdding(false));
  };

  useEffect(() => {
    if (!opened) return;

    setAdding(false);
  }, [opened]);

  return (
    <Modal isOpen={opened} centered size="sm">
      <ModalHeader close={<ModalCloseButton onClick={onCloseModal} />} />
      <ModalBody>
        <div className={styles.imageContainer}>
          <ExistingCustomerImage className="w-100" />
        </div>

        <div className="font-18 font-weight-600 mb-1 text-center mt-3">{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</div>
        <div className="font-14 text-center">{i18n.t(`${typeI18nKey}.description`, i18nOpts)}</div>
      </ModalBody>
      <ModalFooter className="justify-content-between gap-2">
        <div className="flex-grow-1">
          <Button color="secondary" outline block onClick={onCloseModal}>
            {i18n.t('buttons.back')}
          </Button>
        </div>

        <div className="flex-grow-1">
          <Button color="primary" onClick={onAddExisting} block disabled={adding}>
            {adding && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.yes')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ExistingCustomerModal.propTypes = {
  onAdd: PropTypes.func,
};

ExistingCustomerModal.defaultProps = {
  onAdd: () => {}
};

export default connect((store) => ({
  opened: store.modals[ModalNames.EXISTING_CUSTOMER]?.opened || false,
  params: store.modals[ModalNames.EXISTING_CUSTOMER]?.params || {},
  currentCompany: store.companies.currentCompany
}))(ExistingCustomerModal);
