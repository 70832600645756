import { useSelector } from 'react-redux';

const useIsVerifiedEnabled = () => {
  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany
  }));

  const { enableVerifiedBuyingPower } = currentCompany;

  return enableVerifiedBuyingPower;
};

export default useIsVerifiedEnabled;
