import api from '../api';
import {
  docuSignCreateTemplateViewQuery,
  docuSignListTemplatesQuery
} from '../graphql';

export function fetchDocuSignTemplatesAsync(variables = {}) {
  return api.graphql(docuSignListTemplatesQuery, variables)
    .then(({ data: { items } }) => items);
}

export function createTemplateViewAsync(variables = {}) {
  return api.graphql(docuSignCreateTemplateViewQuery, variables)
    .then(({ data: { result: { templateUrl } } }) => templateUrl);
}
