import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import CustomerSignUpDate from '../../../../../customer/CustomerSignUpDate';
import SelectLink from './SelectLink';

const ListItem = ({
  customer, selectedCustomers, onSelect
}) => {
  const isSelected = selectedCustomers.some((selectedCustomer) => selectedCustomer.id
    === customer.id);

  return (
    <tr>
      <td className="text-center">
        <SelectLink onClick={() => onSelect(customer)} selected={isSelected} />
      </td>
      <td>
        <div className="font-weight-600">{customer.name}</div>

        {!v.isBlank(customer.phone) && (
          <div>{customer.phone}</div>
        )}

        {!v.isBlank(customer.email) && (
          <div className="word-break-break-all">{customer.email}</div>
        )}
      </td>
      <td>
        <CustomerSignUpDate customer={customer} />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedCustomers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onSelect: PropTypes.func
};

ListItem.defaultProps = {
  onSelect: () => {}
};

export default ListItem;
