import React from 'react';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import { BiTrash } from 'react-icons/bi';

const ImageViewer = ({ url, onDelete, size }) => {
  if (!url) return null;

  return (
    <div className={`mb-2 position-relative ${size}`}>
      <img src={url} alt="" className="w-100" />
      <button
        type="button"
        className="text-danger rounded-circle bg-dark delete-icon"
        onClick={onDelete}
        title={i18n.t('buttons.delete')}
      >
        <BiTrash size="1.1rem" />
      </button>
    </div>
  );
};

ImageViewer.defaultProps = {
  onDelete: {},
  size: 'w-100'
};

ImageViewer.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.string,
  onDelete: PropTypes.func
};

export default ImageViewer;
