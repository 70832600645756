import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import v from 'voca';
import PropTypes from 'prop-types';

import { isFormValid } from '../../../../../utils/formUtils';
import DeleteButton from '../../../../global/DeleteButton';
import { getError } from '../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../ui/InputError';
import { stripToEmpty } from '../../../../../utils/stringUtils';
import {
  saveTeam, deleteTeam, setReloadTeams
} from '../../../../../store/actions/teamActions';
import useForm from '../../../../../hooks/useForm';
import useFetchCompanies from '../../../../../hooks/useFetchCompanies';
import { ModalNames } from '../../../../../constants';
import ModalService from '../../../../../services/ModalService';
import ModalCloseButton from '../../../../common/ModalCloseButton';
import UserFormImage from '../../../../../assets/images/users/UserFormImage';

const i18nOpts = { scope: 'components.admin.teams.components.formModal.index' };

function onCloseModal() {
  ModalService.close(ModalNames.TEAM_FORM);
}

const FormModal = ({
  currentCompany, opened, params, ...props
}) => {
  const {
    form, submitting, setSubmitting, onTextChange, error, setError, setForm
  } = useForm();
  const { companies } = useFetchCompanies();
  const { team } = params;
  const isNew = useMemo(() => !team, [team]);

  const onSubmit = (event) => {
    event.preventDefault();

    setSubmitting(true);

    const input = {
      name: stripToEmpty(form.name),
      description: stripToEmpty(form.description),
    };

    if (isNew) input.companyId = parseInt(form.companyId, 10) || 0;
    else input.id = form.id;

    const variables = { input };
    props.saveTeam(variables)
      .then(() => {
        props.setReloadTeams(true);
        onCloseModal();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSubmitting(false));
  };

  const onDelete = () => {
    props.deleteTeam(team.id)
      .then(() => {
        props.setReloadTeams(true);
        props.onAfterDelete();
        onCloseModal();
      });
  };

  const loadTeam = () => {
    let formData = {};

    if (isNew) formData = { companyId: currentCompany.id };
    else formData = { ...team, companyId: team.company.id };

    setForm(formData);
  };

  useEffect(() => {
    loadTeam();
  }, [opened]);

  const isValid = isFormValid(form, ['name', 'companyId']);

  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

  return (
    <Modal size="sm" isOpen={opened} modalClassName="right" scrollable>
      <ModalHeader close={<ModalCloseButton onClick={onCloseModal} />}>{title}</ModalHeader>

      <ModalBody>
        <div className="text-center">
          <UserFormImage />
        </div>
        <hr className="mb-4" />

        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label for="name">{i18n.t('form.name', i18nOpts)}</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={form.name || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'name')}
              placeholder={i18n.t('placeholders.name', i18nOpts)}
            />
            <InputError name="name" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="description">{i18n.t('form.description', i18nOpts)}</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              value={form.description || ''}
              onChange={onTextChange}
              rows={5}
              invalid={isInputInvalid(error, 'description')}
              placeholder={i18n.t('placeholders.description', i18nOpts)}
            />
            <InputError name="description" error={error} />
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter className="justify-content-between gap-2">
        {!isNew && (
          <div className="flex-fill">
            <DeleteButton onDelete={onDelete} block />
          </div>
        )}
        <div className="flex-fill">
          <Button outline color="secondary" block onClick={onCloseModal}>
            {i18n.t('buttons.cancel')}
          </Button>
        </div>
        <div className="flex-fill">
          <Button onClick={onSubmit} block color="primary" disabled={submitting || !isValid}>
            {submitting && (<Spinner size="sm" className="mr-2" />)}
            {isNew ? i18n.t('buttons.create') : i18n.t('buttons.save')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

FormModal.propTypes = {
  onAfterDelete: PropTypes.func
};

FormModal.defaultProps = {
  onAfterDelete: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  opened: store.modals[ModalNames.TEAM_FORM]?.opened || false,
  params: store.modals[ModalNames.TEAM_FORM]?.params || {},
}), {
  saveTeam,
  deleteTeam,
  setReloadTeams,
})(FormModal);
