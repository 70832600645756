import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import v from 'voca';

import styles from './Beds.module.scss';
import BedIcon from '../../../../assets/icons/home/BedIcon';

const Beds = ({
  firstIcon, children, className, size
}) => {
  const sizeStyle = v.isBlank(size) ? null : styles[size];

  return (
    <div className={classNames(styles.container, 'text-nowrap gap-1', sizeStyle, className)}>
      {firstIcon && (<BedIcon />)}
      <span>{children || 0}</span>
      {!firstIcon && (<BedIcon />)}
    </div>
  );
};

Beds.propTypes = {
  firstIcon: PropTypes.bool,
  size: PropTypes.oneOf(['lg'])
};

Beds.defaultProps = {
  firstIcon: false,
  size: null
};

export default Beds;
