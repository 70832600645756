import React, { Component } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Row, Col, Form
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import v from 'voca';
import { toast } from 'react-toastify';

import { hasFormValues } from '../../../../utils/formUtils';
import { saveProductType, getProductType } from '../../../../store/actions/productTypeActions';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import ImageViewer from '../../../common/ImageViewer';
import { getParam } from '../../../../utils/paramsUtils';
import { stripToEmpty } from '../../../../utils/stringUtils';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';

const i18nOpts = { scope: 'components.admin.productTypes.form.index' };

function onCancel() {
  navigate('/admin/productTypes');
}

class ProductTypeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      form: {},
      image: undefined,
      saving: false,
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
  }

  componentDidMount() {
    const id = parseInt(getParam('id'), 10);
    this.setState({ id }, this.loadProductType);
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onSave(event) {
    event?.preventDefault();

    const { props } = this;
    const { form, image } = this.state;
    const { currentCompany } = this.props;

    delete form.imageUrl;

    const input = {
      ...form,
      name: stripToEmpty(form.name)
    };

    if (this.isNew()) input.companyId = currentCompany.id;

    this.setState({ saving: true });

    const variables = { input, image };
    props.saveProductType(variables)
      .then(() => {
        navigate('/admin/productTypes');
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  onImageChange(event) {
    const { name } = event.target;
    const image = event.target.files[0];
    this.setState({ [name]: image });
  }

  onDeleteImage(imageType) {
    this.setState({ [imageType]: null });
  }

  getPreviewImageUrl() {
    const { form } = this.state;
    const { image } = this.state;
    return image === null ? null : (getLocalImageUrl(image) || form.imageUrl);
  }

  loadProductType() {
    const { props } = this;
    const { id } = this.state;

    if (this.isNew()) return;

    props.getProductType(id)
      .then(() => {
        const { productType } = this.props;

        if (productType) {
          const { name, imageUrl } = productType;
          const form = {
            id,
            name,
            imageUrl
          };
          this.setState({
            form, image: undefined, saving: false, error: null
          });
        } else navigate('/admin/productTypes');
      })
      .catch(() => {
        navigate('/admin/productTypes');
      });
  }

  isNew() {
    const { id } = this.state;
    return !id;
  }

  render() {
    const { form, saving, error } = this.state;
    const hasValues = hasFormValues(form);
    const title = this.isNew() ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    const imageUrl = this.getPreviewImageUrl();

    return (
      <div>
        <Helmet title={title} />

        <h2 className="mb-4">{title}</h2>

        <Form onSubmit={this.onSave}>
          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="productTypeName">{i18n.t('name', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="name"
                  id="productTypeName"
                  value={form.name || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'name')}
                />
                <InputError name="name" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form className="mb-4">
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="image">{i18n.t('image', i18nOpts)}</Label>
                {imageUrl && (
                  <ImageViewer url={imageUrl} onDelete={() => this.onDeleteImage('image')} />
                )}
                <Input
                  type="file"
                  name="image"
                  id="image"
                  accept="image/*"
                  onChange={this.onImageChange}
                  invalid={isInputInvalid(error, 'image')}
                />
                <InputError name="image" error={error} />
              </FormGroup>
            </Col>
          </Row>
        </Form>

        <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving || !hasValues}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onCancel} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </div>
    );
  }
}

export default connect((store) => ({
  productType: store.productTypes.productType,
  currentCompany: store.companies.currentCompany
}), {
  saveProductType,
  getProductType
})(ProductTypeForm);
