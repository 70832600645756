import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, Col, Row, Spinner
} from 'reactstrap';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import moment from 'moment';
import _ from 'lodash';

import api from '../../../../../api';
import { docuSignGetAuthUrlQuery } from '../../../../../graphql';
import { saveCompany } from '../../../../../store/actions/companyActions';
import { DateFormats } from '../../../../../constants';
import { confirmAlert } from '../../../../global/confirmAlert';

const i18nOpts = { scope: 'components.admin.integrations.docuSign.connection.index' };

const Connection = ({ currentCompany, ...props }) => {
  const [connecting, setConnecting] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);

  const docuSignSettings = currentCompany.docuSignSettings || {};
  const isConnected = !_.isEmpty(docuSignSettings.userInfo);
  const isEnabled = docuSignSettings.enabled || false;
  const { userInfo } = docuSignSettings;

  const onConnect = () => {
    setConnecting(true);

    const variables = { companyId: currentCompany.id };
    api.graphql(docuSignGetAuthUrlQuery, variables)
      .then(({ data: { result: { authUrl } } }) => {
        window.location = authUrl;
      })
      .catch(() => {
        toast.error(i18n.t('messages.notConnected', i18nOpts));
        setConnecting(false);
      });
  };

  const onDisconnect = () => {
    setDisconnecting(true);
    const variables = { input: { id: currentCompany.id, docuSignSettings: {} } };
    props.saveCompany(variables)
      .then(() => toast.success(i18n.t('messages.disconnected', i18nOpts)))
      .catch((e) => toast.error(e.message))
      .finally(() => setDisconnecting(false));
  };

  const onConfirmDisconnect = () => {
    const options = {
      title: null,
      message: `<div class="font-18">${i18n.t('confirmAlert.message', i18nOpts)}</div>`
    };
    const dialogProps = {
      buttonText: i18n.t('buttons.confirm')
    };
    confirmAlert(onDisconnect, options, dialogProps);
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          {isConnected ? (
            <div>
              <div className="font-18 mb-3">{i18n.t('connected', i18nOpts)}</div>

              <div className="mb-4">
                <div className="label">{i18n.t('details.name', i18nOpts)}</div>
                <div><strong>{`${userInfo?.firstName} ${userInfo?.lastName}`}</strong></div>
              </div>

              <div className="mb-4">
                <div className="label">{i18n.t('details.email', i18nOpts)}</div>
                <div><strong>{userInfo?.email}</strong></div>
              </div>

              <div className="mb-4">
                <div className="label">{i18n.t('details.date', i18nOpts)}</div>
                <div>
                  <strong>
                    {moment(docuSignSettings.connectedAt).format(DateFormats.MMM_DD_YYYY)}
                  </strong>
                </div>
              </div>

              {!isEnabled && (
                <div>
                  <Button color="primary" className="mr-3" onClick={onConnect} disabled={connecting}>
                    {connecting && (<Spinner size="sm" className="mr-2" />)}
                    {i18n.t('buttons.reconnect', i18nOpts)}
                  </Button>

                  <p className="small text-warning mt-1">{i18n.t('messages.reconnect', i18nOpts)}</p>
                </div>
              )}

              <Button outline color="danger" onClick={onConfirmDisconnect} disabled={disconnecting}>
                {disconnecting && (<Spinner size="sm" className="mr-2" />)}
                {i18n.t('buttons.disconnect', i18nOpts)}
              </Button>
            </div>
          ) : (
            <Button color="primary" onClick={onConnect} disabled={connecting}>
              {connecting && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.connect', i18nOpts)}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

Connection.propTypes = {};

Connection.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveCompany
})(Connection);
