import React from 'react';

const SaveChangesImage = ({ ...props }) => (
  <svg width="352" height="236" viewBox="0 0 352 236" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M236.386 35.5L95.3282 43.5767L60 156.354L121.863 225.5L266.831 210.606L291 117.407L236.386 35.5Z" fill="#DDF7F0" />
    <path d="M293.343 114.5H257.657C256.19 114.5 255 115.619 255 117C255 118.381 256.19 119.5 257.657 119.5H293.343C294.81 119.5 296 118.381 296 117C296 115.619 294.81 114.5 293.343 114.5Z" fill="#344054" stroke="#344054" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M84.346 76.5H50.654C49.1882 76.5 48 77.6193 48 79C48 80.3807 49.1882 81.5 50.654 81.5H84.346C85.8118 81.5 87 80.3807 87 79C87 77.6193 85.8118 76.5 84.346 76.5Z" fill="#344054" stroke="#344054" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M48 135.5C48 135.5 49.1836 122.18 57.4106 122.18C65.0942 122.18 67.1074 135.5 63.8751 135.5C60.6428 135.5 61.631 127.849 66.3224 127.849C71.0139 127.849 71.5557 135.5 71.5557 135.5H104.89M304 80.8201C304 80.8201 302.776 67.8417 294.89 67.8417C287.003 67.8417 282.379 80.8201 289.178 80.8201C295.976 80.8201 293.598 67.5 285.371 67.5C277.688 67.5 275.682 80.8201 278.907 80.8201C282.131 80.8201 281.151 73.1689 276.459 73.1689C271.768 73.1689 271.226 80.8201 271.226 80.8201H237.887" stroke="#344054" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M98 117.5C99.6569 117.5 101 116.157 101 114.5C101 112.843 99.6569 111.5 98 111.5C96.3431 111.5 95 112.843 95 114.5C95 116.157 96.3431 117.5 98 117.5Z" fill="#344054" stroke="#344054" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M188.661 45.5H251.339C253.371 45.5 255.319 46.315 256.756 47.7658C258.193 49.2166 259 51.1842 259 53.2359V191.5H181V53.2359C181 51.1842 181.807 49.2166 183.244 47.7658C184.681 46.315 186.629 45.5 188.661 45.5Z" stroke="#344054" strokeWidth="3" strokeLinejoin="round" />
    <path d="M167 76.5H97" stroke="#344054" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M100.661 64.5H163.339C165.371 64.5 167.319 65.3122 168.756 66.7579C170.193 68.2036 171 70.1644 171 72.2089V154.5H93V72.2089C93 70.1644 93.8072 68.2036 95.244 66.7579C96.6807 65.3122 98.6294 64.5 100.661 64.5Z" stroke="#344054" strokeWidth="3" strokeLinejoin="round" />
    <path d="M255 57.5H185" stroke="#344054" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M203.36 53.5H138.64C124.48 53.5 113 64.9167 113 79C113 93.0833 124.48 104.5 138.64 104.5H203.36C217.52 104.5 229 93.0833 229 79C229 64.9167 217.52 53.5 203.36 53.5Z" fill="#009462" />
    <path d="M227 78.5C227 65.2452 216.255 54.5 203 54.5C189.745 54.5 179 65.2452 179 78.5C179 91.7548 189.745 102.5 203 102.5C216.255 102.5 227 91.7548 227 78.5Z" fill="white" />
    <path d="M138.64 108H203.36C217.238 108 228.5 119.41 228.5 133.5C228.5 147.59 217.238 159 203.36 159H138.64C124.762 159 113.5 147.59 113.5 133.5C113.5 119.41 124.762 108 138.64 108Z" fill="white" stroke="#D0D5DD" />
    <path d="M162 133.5C162 120.245 151.479 109.5 138.5 109.5C125.521 109.5 115 120.245 115 133.5C115 146.755 125.521 157.5 138.5 157.5C151.479 157.5 162 146.755 162 133.5Z" fill="#009462" />
    <path d="M254 53.5L250 49.5" stroke="#344054" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M250 53.5L254 49.5" stroke="#344054" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

SaveChangesImage.propTypes = {};

SaveChangesImage.defaultProps = {};

export default SaveChangesImage;
