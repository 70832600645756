import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';

import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';
import { isMultiFamilyPage } from '../../../../../utils/multiFamilyUtils';
import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../../utils/authUtils';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';

const i18nOpts = { scope: 'components.admin.palettes.show.overview.index' };

const Overview = ({ palette }) => {
  if (!palette) return null;

  const isMultiFamily = isMultiFamilyPage();
  const isAdmin = isMasterAdmin() || isCompanyAdmin() || isSalesLead();
  const creatorName = getCreatorDisplayName(palette);
  const lastModifierName = getLastModifierDisplayName(palette);

  return (
    <div>
      {isMultiFamily && isValidAmount(palette.basePrice) && (
        <div className="mb-4">
          <div className="label">{i18n.t('basePrice', i18nOpts)}</div>
          <div>{formatCurrency(palette.basePrice)}</div>
        </div>
      )}

      {!v.isBlank(palette.description) && (
        <div className="mb-4">
          <div className="label">{i18n.t('description', i18nOpts)}</div>
          <div className="white-space-pre-line">{palette.description}</div>
        </div>
      )}

      { isAdmin && (
        <>
          {!v.isBlank(creatorName) && (
            <div className="mb-4">
              <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
              <div className="white-space-pre-line">{creatorName}</div>
            </div>
          )}

          {!v.isBlank(lastModifierName) && (
            <div className="mb-4">
              <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
              <div className="white-space-pre-line">{lastModifierName}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  palette: store.palettes.palette
}), {})(Overview);
