import api from '../../../../../api';
import {
  createPaletteImageQuery, deletePaletteImageQuery,
  listPaletteImagesQuery, sortPaletteImagesQuery,
  updatePaletteImageQuery
} from '../../../../../graphql';

export function fetchPaletteImagesAsync(variables = {}) {
  return api.graphql(listPaletteImagesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items))
    .catch(() => Promise.reject());
}

export function savePaletteImageAsync(variables = {}) {
  const query = variables?.input?.id ? updatePaletteImageQuery : createPaletteImageQuery;

  return api.graphql(query, variables);
}

export function deletePaletteImageAsync(id) {
  return api.graphql(deletePaletteImageQuery, { id });
}

export function sortPaletteImagesAsync(variables = {}) {
  return api.graphql(sortPaletteImagesQuery, variables);
}
