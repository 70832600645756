import React from 'react';
import PropTypes from 'prop-types';
import { MdOpenInNew } from 'react-icons/md';
import classNames from 'classnames';
import moment from 'moment';
import v from 'voca';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import { isRecently } from '../../../../../../../utils/dateUtils';
import { DateFormats, MappableTypes, ModalNames } from '../../../../../../../constants';
import ActionsMenu from '../../../../../../global/ActionsMenu';
import ModalService from '../../../../../../../services/ModalService';
import { getError } from '../../../../../../../utils/requestUtils';
import { deleteTrackingPage } from '../../../../../../../store/actions/trackingPageActions';
import { removeDomainFromURL } from '../../../../../../../utils/urlUtils';

const i18nOpts = { scope: 'components.admin.integrations.tracking.pages.list.listItem.index' };

const ListItem = ({ trackingPage, ...props }) => {
  const onEdit = () => {
    ModalService.open(ModalNames.TRACKING_PAGE_FORM, { trackingPage });
  };

  const onDelete = (trackingPageId) => {
    props.deleteTrackingPage(trackingPageId)
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
      });
  };

  if (!trackingPage) return null;

  return (
    <tr>
      <td>
        <a href={trackingPage.url} target="_blank" rel="noreferrer" className="word-break-break-all">
          {removeDomainFromURL(trackingPage.url)}
          <MdOpenInNew size="16" className="ml-1" />
        </a>
      </td>
      <td>
        {trackingPage.mappableType === MappableTypes.COMMUNITY && (
          <span>{i18n.t('community', i18nOpts)}</span>
        )}
        {trackingPage.mappableType === MappableTypes.MODEL && (
          <span>{i18n.t('model', i18nOpts)}</span>
        )}
      </td>
      <td>
        {trackingPage.community && (
          <span>{trackingPage.community.name}</span>
        )}
        {trackingPage.model && (
          <span>{trackingPage.model.name}</span>
        )}
      </td>
      <td>
        <span className={classNames({ 'text-success': isRecently(trackingPage.createdAt) })}>
          {moment(trackingPage.createdAt).format(DateFormats.MMM_DD_YYYY)}
        </span>
      </td>
      <td>
        {!v.isBlank(trackingPage.lastVisitedAt) && (
          <span className={classNames({ 'text-success': isRecently(trackingPage.lastVisitedAt) })}>
            {moment(trackingPage.lastVisitedAt).format(DateFormats.MMM_DD_YYYY_HH_MM)}
          </span>
        )}
      </td>
      <td>
        {trackingPage.visitsCount > 0 && (
          <span>{trackingPage.visitsCount}</span>
        )}
      </td>
      <td className="text-right">
        <ActionsMenu
          item={trackingPage}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  trackingPage: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
}), {
  deleteTrackingPage
})(ListItem);
