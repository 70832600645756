import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ModalBody, Row, Col, Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import ListView from './ListView';
import { addInvitedCustomers } from '../utils';
import { listCustomersAsync } from './utils';
import { SortOrders } from '../../../../../constants';
import ListEmpty from '../../../../common/ListEmpty';
import SearchBar from '../../../../common/SearchBar';
import { stripToEmpty } from '../../../../../utils/stringUtils';
import useHomeRental from '../../../../../hooks/useHomeRental';
import { Steps } from '../constants';
import useInvitationStartType from '../hooks/useInvitationStartType';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.existingCustomer.index' };

const ExistingCustomer = ({ currentCompany, onContinue }) => {
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const { typeI18nKey } = useHomeRental();

  const { isFromHome } = useInvitationStartType();

  const onSelect = (selectedCustomer) => {
    setSelectedCustomers((prevSelectedCustomers) => _.xorBy(prevSelectedCustomers, [selectedCustomer], 'id'));

    const nextStep = isFromHome ? Steps.CONFIRM_INVITATION : Steps.INVITATION_LIST;

    addInvitedCustomers(selectedCustomer);

    setTimeout(() => {
      onContinue(nextStep);
    }, 500);
  };

  const loadCustomers = (page = 1) => {
    setLoading(true);

    const variables = {
      filter: {
        companyId: currentCompany.id,
        query: stripToEmpty(filters.query)
      },
      sort: {
        field: 'updatedDate',
        direction: SortOrders.DESC
      },
      page,
      pageSize: 10,
      companyId: currentCompany.id
    };
    listCustomersAsync(variables)
      .then((result) => {
        setPagination(result.pagination);
        if (page === 1) setCustomers(result.items);
        else setCustomers(customers.concat(result.items));
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const onSearch = (event, query) => {
    event.preventDefault();
    setFilters((prevFilters) => ({ ...prevFilters, query }));
  };

  useEffect(() => {
    loadCustomers();
  }, [filters]);

  const selectedCustomersCount = selectedCustomers.length;

  return (
    <ModalBody className="not-scrollable-container d-flex flex-column p-0">
      <div className="pt-0 px-3 pb-3">
        <div className="font-18 font-weight-600 mb-3">{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</div>

        <Row className="align-items-end">
          <Col xs="4" sm="5">
            {selectedCustomersCount > 0 && (
              <span dangerouslySetInnerHTML={{ __html: i18n.t(`${typeI18nKey}.selectedCustomers`, { ...i18nOpts, count: selectedCustomersCount }) }} />
            )}
          </Col>
          <Col xs="8" sm="7">
            <SearchBar
              onSubmit={onSearch}
              inputProps={{ bsSize: 'md', placeholder: i18n.t(`${typeI18nKey}.searchBarPlaceholder`, i18nOpts) }}
            />
          </Col>
        </Row>
      </div>

      <div className="scrollable-container" id="customers-list">
        {loading && (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        )}

        {customers.length > 0 ? (
          <ListView
            customers={customers}
            selectedCustomers={selectedCustomers}
            onSelect={onSelect}
          />
        ) : (
          <div className="p-3">
            <ListEmpty loading={loading} items={customers} />
          </div>
        )}
      </div>
    </ModalBody>
  );
};

ExistingCustomer.propTypes = {
  onContinue: PropTypes.func
};

ExistingCustomer.defaultProps = {
  onContinue: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(ExistingCustomer);
