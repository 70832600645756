import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import v from 'voca';

import SocialMediaLoginType from '../../../SocialMediaLoginType';
import { getValidCustomerCompaniesCount } from '../../../../utils';
import OwnlyLogo from '../../../../../../../assets/icons/OwnlyLogo';
import { isMasterAdmin, isTecSupport } from '../../../../../../../utils/authUtils';
import { isDeletedCustomersPage } from '../../../utils';
import CustomerCoBuyersCountBadge
  from '../../../../../../global/customer/CustomerCoBuyersCountBadge';
import CustomerFormSubmissionIndicator from '../../../../../../global/customer/CustomerFormSubmissionIndicator';

const CustomerName = ({ customer, currentCompany }) => {
  const isSuperAdmin = isMasterAdmin() || isTecSupport();
  const isDeletedPage = isDeletedCustomersPage();
  const linkRef = useRef(null);

  const customerContent = (
    <>
      <div className="d-flex align-items-start gap-2">
        <span className="font-weight-600">{customer.name}</span>
        <SocialMediaLoginType customer={customer} />
        <CustomerCoBuyersCountBadge count={customer.coBuyersCount} />
        <CustomerFormSubmissionIndicator customer={customer} />
        {getValidCustomerCompaniesCount(customer, currentCompany.id) > 0 && isSuperAdmin && (
          <span>
            <OwnlyLogo />
          </span>
        )}
      </div>

      {!v.isBlank(customer.email) && (
        <span>{customer.email}</span>
      )}
    </>
  );

  useEffect(() => {
    if (!linkRef.current) return;

    const tdHeight = linkRef.current.parentElement?.offsetHeight;
    if (tdHeight) linkRef.current.style.height = `${tdHeight}px`;
  }, []);

  return (
    <td
      className={classNames('fixed-column', { 'clickable-column p-0': !isDeletedPage })}
      role={!isDeletedPage ? 'button' : null}
    >
      {isDeletedPage ? (
        customerContent
      ) : (
        <Link ref={linkRef} to={`/admin/customers/${customer.id}`} className="col-gray-600 p-3 d-block">
          {customerContent}
        </Link>
      )}
    </td>
  );
};

CustomerName.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomerName.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(CustomerName);
