import React from 'react';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';

import FormModal from './FormModal';
import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';
import List from './List';
import { isAdmin } from '../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.users.show.teams.index' };

const Teams = () => {
  const onAddUser = () => {
    ModalService.open(ModalNames.TEAM_USER_FORM);
  };

  return (
    <div>
      {isAdmin() && (
        <div className="text-right mb-3">
          <Button color="secondary" outline onClick={onAddUser}>
            {i18n.t('buttons.assignTeam', i18nOpts)}
          </Button>
        </div>
      )}

      <List />

      <FormModal />
    </div>
  );
};

export default Teams;
