import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import v from 'voca';
import { useLocation } from '@reach/router';

import './styles.scss';
import { setSubMenu } from '../../../../../store/actions/menuActions';
import { isMobile } from '../../../../../utils/windowUtils';
import { getCurrentMenu } from '../../utils';

const SubMenu = ({
  title, menu, IconElement, children, subMenu, ...props
}) => {
  const { pathname } = useLocation();
  const activeSubMenu = useMemo(() => getCurrentMenu(pathname), [pathname]);
  const [opened, setOpened] = useState(false);

  const active = !isMobile() && menu === activeSubMenu;

  const onClick = () => {
    props.setSubMenu(menu === subMenu ? null : menu);
  };

  useEffect(() => {
    if (isMobile() && v.isBlank(subMenu)) setOpened(active);
  }, []);

  useEffect(() => {
    setOpened(menu === subMenu);
  }, [menu, subMenu]);

  return (
    <li className={classNames('sidebar-item sub-menu')}>
      <div
        className={classNames('sidebar-link has-arrow', { active, opened })}
        onClick={onClick}
        role="button"
        aria-hidden
      >
        <i>{IconElement}</i>
        <span className="hide-menu">{title}</span>
      </div>

      <ul className={classNames('first-level', { opened })}>
        {children}
      </ul>
    </li>
  );
};

SubMenu.propTypes = {
  menu: PropTypes.string.isRequired,
  IconElement: PropTypes.element.isRequired
};

SubMenu.defaultProps = {};

export default connect((store) => ({
  subMenu: store.menu.subMenu
}), {
  setSubMenu
})(SubMenu);
