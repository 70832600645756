import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { formatCurrency } from '../../../../../../../../utils/currencyUtils';
import { PreApprovalVerificationMethods } from '../../../../../../../../constants';
import { getCustomerPreApprovalAmount } from '../../../../../../../../utils/customerUtils';

const PreApproval = ({ customer, currentCompany }) => {
  const { user, isFinancialVerified, companyContact } = customer;

  if (!user) return null;

  const preApproval = user?.preApproval;

  const isPreApprovalManual = preApproval?.verificationMethod
    === PreApprovalVerificationMethods.MANUAL;
  const isCurrentCompanyPreApproval = preApproval?.companyId === currentCompany.id;
  const isFinancialVerifiedAndGranted = (isFinancialVerified
      && companyContact.verifiedFinancialGranted)
    || (isPreApprovalManual && isCurrentCompanyPreApproval);

  if (!isFinancialVerifiedAndGranted) return null;

  return (
    <div>
      {formatCurrency(getCustomerPreApprovalAmount(customer))}
    </div>
  );
};

PreApproval.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

PreApproval.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(PreApproval);
