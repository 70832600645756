import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import ListItem from './ListItem';

const GridView = ({ units }) => (
  <Row>
    {
      units.map((u) => (
        <Col key={`unit-${u.id}`} xs="12" sm="6" md="6" lg="4" className="mb-4">
          <ListItem unit={u} />
        </Col>
      ))
    }
  </Row>
);

export default connect((store) => ({
  units: store.units.units
}))(GridView);
