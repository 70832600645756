import React, { useState } from 'react';
import {
  Button, FormGroup, Input, Label, Spinner, Card
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import v from 'voca';

import InputError, { isInputInvalid } from '../../../../../../ui/InputError';
import { OfferStatuses } from '../../../../../../../constants';
import { saveOffer } from '../../../../../../../store/actions/offerActions';
import { parseToFloat } from '../../../../../../../utils/parserUtils';
import { formatNumber } from '../../../../../../../utils/currencyUtils';
import { getError } from '../../../../../../../utils/requestUtils';
import { setReloadHomes, setHomeAttributes } from '../../../../../../../store/actions/homeActions';
import { confirmAlert } from '../../../../../confirmAlert';
import { DEFAULT_DEPOSIT_AMOUNT } from '../../../utils';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.offerSection.acceptOfferCard.index' };

const AcceptOfferCard = ({ home, ...props }) => {
  const [form, setForm] = useState({ requiredDepositAmount: DEFAULT_DEPOSIT_AMOUNT });
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const { offer } = home;

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onAccept = () => {
    setSaving(true);

    const input = {
      id: offer.id,
      status: OfferStatuses.ACCEPTED,
      requiredDepositAmount: parseToFloat(form.requiredDepositAmount)
    };
    const variables = { input };

    props.saveOffer(variables)
      .then(({ value: { data: { item } } }) => {
        props.setHomeAttributes(home.id, { offer: item });
        props.setReloadHomes(true);
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const onConfirmAccept = () => {
    const options = {
      title: null,
      message: `<div class="font-18">${i18n.t('confirmAlert.message', i18nOpts)}</div>`
    };
    const dialogProps = {
      buttonText: i18n.t('buttons.accept', i18nOpts)
    };
    confirmAlert(onAccept, options, dialogProps);
  };

  const isConfirmedPrice = parseToFloat(offer.offerPrice)
    === parseToFloat(form.confirmCustomerOffered);

  if ([OfferStatuses.ACCEPTED, OfferStatuses.COUNTERED, OfferStatuses.REJECTED,
    OfferStatuses.DECLINED].includes(offer.status)) return null;

  return (
    <Card body className="p-3 mb-3">
      <h3>{i18n.t('title', i18nOpts)}</h3>

      <FormGroup>
        <Label for="customerOffered">{i18n.t('customerOffered', i18nOpts)}</Label>
        <div className="d-flex align-items-center">
          <b>$</b>
          <Input
            type="text"
            name="customerOffered"
            id="customerOffered"
            value={formatNumber(offer.offerPrice) || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'customerOffered')}
            className="ml-2"
            disabled
          />
        </div>
        <InputError name="customerOffered" error={error} />
      </FormGroup>

      <FormGroup>
        <Label for="confirmCustomerOffered">{i18n.t('confirmCustomerOffered', i18nOpts)}</Label>
        <div className="d-flex align-items-center">
          <b>$</b>
          <Input
            type="number"
            name="confirmCustomerOffered"
            id="confirmCustomerOffered"
            value={form.confirmCustomerOffered || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'confirmCustomerOffered')}
            className="ml-2"
          />
        </div>
        <InputError name="confirmCustomerOffered" error={error} />
      </FormGroup>

      <FormGroup>
        <Label for="requiredDepositAmount">{i18n.t('requiredDepositAmount', i18nOpts)}</Label>
        <div className="d-flex align-items-center">
          <b>$</b>
          <Input
            type="number"
            name="requiredDepositAmount"
            id="requiredDepositAmount"
            value={form.requiredDepositAmount || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'requiredDepositAmount')}
            className="ml-2"
          />
        </div>
        <InputError name="requiredDepositAmount" error={error} />
      </FormGroup>

      <div className="text-right">
        <Button
          color="primary"
          className="ml-3"
          onClick={onConfirmAccept}
          disabled={saving || !isConfirmedPrice}
        >
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.accept', i18nOpts)}
        </Button>
      </div>
    </Card>
  );
};

AcceptOfferCard.propTypes = {};

AcceptOfferCard.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}), {
  saveOffer,
  setHomeAttributes,
  setReloadHomes
})(AcceptOfferCard);
