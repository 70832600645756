import React from 'react';
import i18n from 'i18n-js';
import { Button, Card } from 'reactstrap';

import CopyLink from '../../../../../common/CopyLink';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.buildHomeSetup.saveHome.index' };

const SAVE_HOME_CODE = `<button class="ownly-save-home">
  Save Home
</button>`;

const SaveHome = () => (
  <div>
    {/* Save Home */}
    <h2>{i18n.t('saveHome', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('saveHomeDesc', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>

    <Card body className="d-block">
      <pre>
        &lt;button class=&quot;ownly-save-home&quot;&gt;
        <br />
        &nbsp;&nbsp;Save Home
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={SAVE_HOME_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    <p>{i18n.t('similarityDesc', i18nOpts)}</p>
  </div>
);

SaveHome.propTypes = {};

SaveHome.defaultProps = {};

export default SaveHome;
