import {
  GET_QUICK_POSSESSIONS,
  DELETE_QUICK_POSSESSION,
  GET_QUICK_POSSESSION,
  SAVE_QUICK_POSSESSION,
  SET_QUICK_POSSESSION_VIEW_MODE,
  SET_RELOAD_QUICK_POSSESSIONS,
  CLEAN_QUICK_POSSESSIONS,
  SET_QUICK_POSSESSION_COLUMNS,
  SET_QUICK_POSSESSION_FILTERS
} from './types';
import {
  createQuickPossessionQuery,
  deleteQuickPossessionQuery,
  getQuickPossessionQuery,
  listQuickPossessionsQuery,
  updateQuickPossessionQuery
} from '../../graphql/quickPossessions';
import api from '../../api';

export function getQuickPossessions(variables) {
  return {
    type: GET_QUICK_POSSESSIONS,
    payload: api.graphql(listQuickPossessionsQuery, variables)
  };
}

export function saveQuickPossession(variables) {
  const query = variables.input.id ? updateQuickPossessionQuery : createQuickPossessionQuery;
  return {
    type: SAVE_QUICK_POSSESSION,
    payload: api.graphql(query, variables)
  };
}

export function getQuickPossession(variables) {
  return {
    type: GET_QUICK_POSSESSION,
    payload: api.graphql(getQuickPossessionQuery, variables)
  };
}

export function deleteQuickPossession(id) {
  return {
    type: DELETE_QUICK_POSSESSION,
    payload: api.graphql(deleteQuickPossessionQuery, { id })
  };
}

export function setViewMode(viewMode) {
  return {
    type: SET_QUICK_POSSESSION_VIEW_MODE,
    payload: { viewMode }
  };
}

export function setReloadQuickPossessions(reload) {
  return {
    type: SET_RELOAD_QUICK_POSSESSIONS,
    payload: { reload }
  };
}

export function cleanQuickPossessions(extraProps = {}) {
  return {
    type: CLEAN_QUICK_POSSESSIONS,
    payload: Promise.resolve(extraProps)
  };
}

export function setQuickPossessionColumns(columns) {
  return {
    type: SET_QUICK_POSSESSION_COLUMNS,
    payload: { columns }
  };
}

export function setQuickPossessionFilters(filters) {
  return {
    type: SET_QUICK_POSSESSION_FILTERS,
    payload: { filters }
  };
}
