import {
  CLEAN_FORMS, GET_FORM, GET_FORMS, SAVE_FORM, DELETE_FORM
} from './types';
import {
  createFormMutation, deleteFormMutation, getFormQuery, listFormsQuery,
  updateFormMutation
} from '../../graphql';
import api from '../../api';

export function getForms(variables = {}) {
  return {
    type: GET_FORMS,
    payload: api.graphql(listFormsQuery, variables)
  };
}

export function getForm(id) {
  return {
    type: GET_FORM,
    payload: api.graphql(getFormQuery, { id })
  };
}

export function cleanForms(extraProps = {}) {
  return {
    type: CLEAN_FORMS,
    payload: Promise.resolve(extraProps)
  };
}

export function saveForm(variables = {}) {
  const query = variables.input.id ? updateFormMutation : createFormMutation;
  return {
    type: SAVE_FORM,
    payload: api.graphql(query, variables)
  };
}

export function deleteForm(id) {
  return {
    type: DELETE_FORM,
    payload: api.graphql(deleteFormMutation, { id })
  };
}
