import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';
import { IoMdAddCircle } from 'react-icons/io';

import ModalService from '../../../../services/ModalService';
import { ModalNames, PriceableTypes } from '../../../../constants';

const i18nOpts = { scope: 'components.global.priceRules.addPriceRuleButton.index' };

const AddPriceRuleButton = ({
  priceableId, priceableType, ...props
}) => {
  const onClick = () => {
    ModalService.open(ModalNames.PRICE_RULE_FORM, { priceableId, priceableType });
  };

  return (
    <Button size="sm" color="primary" outline onClick={onClick} {...props}>
      <IoMdAddCircle size="1rem" className="mr-1" />
      {i18n.t('title', i18nOpts)}
    </Button>
  );
};

AddPriceRuleButton.propTypes = {
  priceableId: PropTypes.number,
  priceableType: PropTypes.oneOf(Object.values(PriceableTypes))
};

AddPriceRuleButton.defaultProps = {
  priceableId: null,
  priceableType: null
};

export default AddPriceRuleButton;
