import React from 'react';
import { connect } from 'react-redux';

import CompanySettings from '../Settings';

const EditCompany = ({ currentCompany }) => <CompanySettings id={currentCompany.id} />;

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(EditCompany);
