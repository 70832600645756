import React from 'react';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import { formatCurrency } from '../../../../../utils/currencyUtils';
import StatCardValue from '../components/StatCardValue';
import StatCardRowSeparator from '../components/StatCardRowSeparator';
import CustomerFinancialTrendBadge from '../../../../global/customer/CustomerFinancialTrendBadge';

const i18nOpts = { scope: 'components.admin.customers.financialReport.overallBalanceCard.index' };

const OverallBalanceCard = ({ financialReport }) => {
  const { overallBalance } = financialReport;
  const {
    trend, max, min, current, balance90DaysAgo, daysWithNegativeBalance
  } = overallBalance;

  return (
    <Card body>
      <div className="d-flex align-items-center justify-content-around">
        <StatCardValue title={i18n.t('trend', i18nOpts)}>
          <CustomerFinancialTrendBadge trend={trend} />
        </StatCardValue>
        <StatCardValue title={i18n.t('max', i18nOpts)} showSeparator>
          {formatCurrency(max)}
        </StatCardValue>
        <StatCardValue title={i18n.t('current', i18nOpts)} showSeparator>
          {formatCurrency(current)}
        </StatCardValue>
      </div>

      <StatCardRowSeparator />

      <div className="d-flex align-items-center justify-content-around">
        <StatCardValue title={i18n.t('daysWithNegativeBalance', i18nOpts)}>
          {daysWithNegativeBalance}
        </StatCardValue>
        <StatCardValue title={i18n.t('min', i18nOpts)} showSeparator>
          {formatCurrency(min)}
        </StatCardValue>
        <StatCardValue title={i18n.t('balance90DaysAgo', i18nOpts)} showSeparator>
          {formatCurrency(balance90DaysAgo)}
        </StatCardValue>
      </div>
    </Card>
  );
};

OverallBalanceCard.propTypes = {};

OverallBalanceCard.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
}))(OverallBalanceCard);
