import { getCustomerAffordability } from '../../../../../../utils/currencyUtils';
import {
  formatCustomerRentalPowerRange,
  getFormattedCustomerBuyingPowerRange
} from '../../../../../../utils/customerUtils';
import { PreApprovalVerificationMethods } from '../../../../../../constants';

export function getFormattedCustomerFinancialAmount(customer, isRental, combinedAmount = true) {
  const affordability = getCustomerAffordability(customer, combinedAmount);
  if (isRental) {
    const maxRentingAmount = affordability?.maxRentingAmount || 0;
    return formatCustomerRentalPowerRange(maxRentingAmount);
  } return getFormattedCustomerBuyingPowerRange(affordability);
}

export function hasCustomerFinancialAmount(customer) {
  const {
    isFinancialVerified, companyContact
  } = customer;
  const isFinancialVerifiedAndGranted = isFinancialVerified
    && companyContact.verifiedFinancialGranted;

  const affordability = getCustomerAffordability(customer);
  if (!isFinancialVerifiedAndGranted) return false;
  return !!affordability;
}

export function hasCustomerPreApprovalAmount(customer, currentCompany) {
  const { isFinancialVerified, user, companyContact } = customer;
  const preApproval = user?.preApproval;

  if (!preApproval) return false;

  const isPreApprovalManual = preApproval?.verificationMethod
    === PreApprovalVerificationMethods.MANUAL;
  const isCurrentCompanyPreApproval = preApproval?.companyId === currentCompany.id;

  return (isFinancialVerified && companyContact.verifiedFinancialGranted)
    || (isPreApprovalManual && isCurrentCompanyPreApproval);
}
