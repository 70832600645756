import {
  LIST_PROPERTY_TAX_RATES,
  GET_PROPERTY_TAX_RATE,
  SAVE_PROPERTY_TAX_RATE,
  DELETE_PROPERTY_TAX_RATE
} from './types';
import {
  listPropertyTaxRatesQuery,
  getPropertyTaxRateQuery,
  createPropertyTaxRateQuery,
  updatePropertyTaxRateQuery,
  deletePropertyTaxRateQuery
} from '../../graphql/propertyTaxRates';
import api from '../../api';

export function listPropertyTaxRates(variables = {}) {
  return {
    type: LIST_PROPERTY_TAX_RATES,
    payload: api.graphql(listPropertyTaxRatesQuery, variables)
  };
}

export function getPropertyTaxRate(id) {
  return {
    type: GET_PROPERTY_TAX_RATE,
    payload: api.graphql(getPropertyTaxRateQuery, { id })
  };
}

export function savePropertyTaxRate(variables = {}) {
  const query = variables.input.id ? updatePropertyTaxRateQuery : createPropertyTaxRateQuery;
  return {
    type: SAVE_PROPERTY_TAX_RATE,
    payload: api.graphql(query, variables)
  };
}

export function deletePropertyTaxRate(id) {
  return {
    type: DELETE_PROPERTY_TAX_RATE,
    payload: api.graphql(deletePropertyTaxRateQuery, { id })
  };
}
