import React from 'react';
import i18n from 'i18n-js';

import LevelTwoSubMenu from '../components/LevelTwoSubMenu';
import { Menus } from '../utils';
import SingleFamilyIcon from '../../../../assets/icons/sidebar-nav/SingleFamilyIcon';
import SingleFamilySideBar from '../SingleFamilySideBar';

const i18nOpts = { scope: 'layout.admin.sidebar.singleFamilyMenu.index' };

const SingleFamilyMenu = () => (
  <LevelTwoSubMenu
    title={i18n.t('title', i18nOpts)}
    menu={Menus.SINGLE_FAMILY}
    IconElement={<SingleFamilyIcon />}
  >
    <SingleFamilySideBar />
  </LevelTwoSubMenu>
);

export default SingleFamilyMenu;
