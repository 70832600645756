import { paginationFragment } from './fragments/pagination';

const fragment = `
  id
  countryCode
  country
  stateCode
  state
  city
  taxRate
  isDefault
`;

export const listPropertyTaxRatesQuery = `
  query ListPropertyTaxRates(
    $filter: PropertyTaxRateFilterInput,
    $page: Int,
    $pageSize: Int
  ) {
    result: listPropertyTaxRates(filter: $filter, page: $page, pageSize: $pageSize) {
      items {
        ${fragment}
      }
      pagination {
        ${paginationFragment}
      }
    }
  }
`;

export const getPropertyTaxRateQuery = `
  query GetPropertyTaxRate(
    $id: Int!
  ) {
    item: getPropertyTaxRate(id: $id) {
      ${fragment}
    }
  }
`;

export const createPropertyTaxRateQuery = `
  mutation CreatePropertyTaxRate(
    $input: CreatePropertyTaxRateInput!
  ) {
    item: createPropertyTaxRate(input: $input) {
      ${fragment}
    }
  }
`;

export const updatePropertyTaxRateQuery = `
  mutation UpdatePropertyTaxRate(
    $input: UpdatePropertyTaxRateInput!
  ) {
    item: updatePropertyTaxRate(input: $input) {
      ${fragment}
    }
  }
`;

export const deletePropertyTaxRateQuery = `
  mutation DeletePropertyTaxRate(
    $id: Int!
  ) {
    item: deletePropertyTaxRate(id: $id) {
      ${fragment}
    }
  }
`;
