import React, { useMemo } from 'react';
import { Card } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';

import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';
import { CHART_COLORS } from '../constants';

const i18nOpts = { scope: 'components.admin.customers.financialReport.accountOutflowCard.index' };

const options = {
  plugins: {
    legend: {
      position: 'right',
      align: 'center',
      labels: {
        font: {
          weight: '400',
          size: 14
        },
        usePointStyle: true,
        pointStyleWidth: 10,
        boxHeight: 7
      }
    },
    layout: {
      padding: 20
    },
    tooltip: {
      callbacks: {
        label(context) {
          return formatCurrency(context.raw);
        }
      }
    },
  },
};
function getChartData(dataset) {
  return {
    labels: dataset.map((dataItem) => dataItem.label),
    datasets: [
      {
        backgroundColor: dataset.map((dataItem) => dataItem.color),
        data: dataset.map((dataItem) => dataItem.value)
      }
    ]
  };
}

const AccountOutflowCard = ({ financialReport }) => {
  const { liabilityPayments } = financialReport;

  const getDataset = () => {
    const dataset = [];

    let index = 0;
    Object.entries(liabilityPayments).forEach(([label, value]) => {
      if (!isValidAmount(value)) return;

      dataset.push({
        label: i18n.t(label, i18nOpts),
        value,
        color: CHART_COLORS[CHART_COLORS.length - index - 1]
      });
      index += 1;
    });
    return dataset;
  };

  const data = useMemo(() => {
    const dataset = getDataset();
    return getChartData(dataset);
  }, []);

  return (
    <Card body className="text-dark h-100 d-flex flex-column">
      <h4>{i18n.t('title', i18nOpts)}</h4>
      <div className="my-auto">
        <Doughnut data={data} options={options} />
      </div>
    </Card>
  );
};

AccountOutflowCard.propTypes = {};

AccountOutflowCard.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
}))(AccountOutflowCard);
