import React from 'react';
import PropTypes from 'prop-types';

import { QuickPossessionColumns } from '../../../../../../../../constants';

import Community from './Community';
import Model from './Model';
import ProductType from './ProductType';
import Address from './Address';
import Size from './Size';
import Beds from './Beds';
import Baths from './Baths';
import Price from './Price';
import Availability from './Availability';
import Status from './Status';
import Interests from './Interests';
import LastModified from './LastModified';
import LastCreated from './LastCreated';

const CustomColumnValue = ({ column, quickPossession }) => {
  switch (column) {
    case QuickPossessionColumns.COMMUNITY:
      return <Community quickPossession={quickPossession} />;

    case QuickPossessionColumns.MODEL:
      return <Model quickPossession={quickPossession} />;

    case QuickPossessionColumns.PRODUCT_TYPE:
      return <ProductType quickPossession={quickPossession} />;

    case QuickPossessionColumns.ADDRESS:
      return <Address quickPossession={quickPossession} />;

    case QuickPossessionColumns.SIZE:
      return <Size quickPossession={quickPossession} />;

    case QuickPossessionColumns.BEDS:
      return <Beds quickPossession={quickPossession} />;

    case QuickPossessionColumns.BATHS:
      return <Baths quickPossession={quickPossession} />;

    case QuickPossessionColumns.PRICE:
      return <Price quickPossession={quickPossession} />;

    case QuickPossessionColumns.AVAILABILITY:
      return <Availability quickPossession={quickPossession} />;

    case QuickPossessionColumns.STATUS:
      return <Status quickPossession={quickPossession} />;

    case QuickPossessionColumns.INTERESTS:
      return <Interests quickPossession={quickPossession} />;

    case QuickPossessionColumns.LAST_MODIFIED:
      return <LastModified quickPossession={quickPossession} />;

    case QuickPossessionColumns.LAST_CREATED:
      return <LastCreated quickPossession={quickPossession} />;

    default:
      return null;
  }
};

CustomColumnValue.propTypes = {
  column: PropTypes.oneOf(Object.values(QuickPossessionColumns)).isRequired,
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomColumnValue.defaultProps = {};

export default CustomColumnValue;
