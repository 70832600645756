import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';
import {
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import { isAdmin } from '../../../../utils/authUtils';
import api from '../../../../api';
import { getTaxRateQuery } from '../../../../graphql';
import Breadcrumbs from '../../../common/Breadcrumbs';
import Overview from './Overview';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.taxRates.show.index' };

const Tabs = Object.freeze({
  OVERVIEW: 'overview'
});

async function getTaxRate(id) {
  return api.graphql(getTaxRateQuery, { id })
    .then(({ data }) => Promise.resolve(data.item));
}

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: Tabs.OVERVIEW,
      taxRate: null,
      loading: false
    };

    this.onChangeTab = this.onChangeTab.bind(this);
  }

  componentDidMount() {
    this.loadTaxRate();
  }

  onChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  loadTaxRate() {
    const { id } = this.props;
    const taxRateId = parseInt(id, 10);

    this.setState({ loading: true });

    getTaxRate(taxRateId)
      .then((taxRate) => {
        this.setState({ taxRate });
        this.setState({ loading: false });
      }).catch(() => navigate('admin/taxRates'));
  }

  render() {
    const { activeTab, taxRate, loading } = this.state;

    if (!taxRate || loading) return <Loading loading fullScreen />;

    return (
      <div>
        <Helmet title={taxRate.name} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <Breadcrumbs items={[
              { url: '/admin/taxRates', text: i18n.t('taxRates', i18nOpts) },
              { url: `/admin/taxRates/${taxRate.id}`, text: taxRate.name },
            ]}
            />
          </div>
          <div>
            {isAdmin() && (
              <Link to={`/admin/taxRates/form?id=${taxRate.id}`} className="btn btn-primary ml-3">
                <i className="far fa-edit mr-2" />
                {i18n.t('buttons.edit')}
              </Link>
            )}
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.OVERVIEW ? 'active' : ''}`}
              onClick={() => this.onChangeTab(Tabs.OVERVIEW)}
            >
              {i18n.t('tabs.overview', i18nOpts)}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="py-4">
          <TabPane tabId={Tabs.OVERVIEW}>
            <Overview taxRate={taxRate} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default Show;
