import React from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, CardImg
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import i18n from 'i18n-js';

import './styles.scss';
import { DEFAULT_IMAGE } from '../../../../../constants';
import { deletePalette } from '../../../../../store/actions/paletteActions';
import { buildPalettesPath, isMultiFamilyPage } from '../../../../../utils/multiFamilyUtils';
import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.palettes.list.listItem.index' };

const ListItem = ({ palette }) => {
  if (!palette) return null;

  const imageUrl = palette.defaultImageUrl || DEFAULT_IMAGE;
  const url = buildPalettesPath(`/${palette.id}`);
  const isMultiFamily = isMultiFamilyPage();

  return (
    <Card className="palette-card">
      <Link to={url}>
        <CardImg top width="100%" src={imageUrl} alt={palette.name} className="mb-0 palette-image" />
      </Link>
      <CardBody>
        <h3>
          <Link to={url}>{palette.name}</Link>
        </h3>

        {isMultiFamily && isValidAmount(palette.basePrice) && (
          <div className="mt-2">
            <div className="text-uppercase font-bold font-12">
              {i18n.t('from', { ...i18nOpts, price: formatCurrency(palette.basePrice) })}
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

ListItem.propTypes = {
  palette: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
}), {
  deletePalette
})(ListItem);
