import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import useFetchProjects from '../../../../../../../hooks/useFetchProjects';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';
import ShowMoreContent from '../../../../../../common/ShowMoreContent';
import ListItem from '../../../../../../global/filters/components/ListItem';

const i18nOpts = { scope: 'components.admin.units.list.components.filtersModal.projectFilter.index' };

const ProjectFilter = ({ filters, onChange }) => {
  const projects = filters.projects || [];
  const { projects: availableProjects, loading } = useFetchProjects();

  const onToggle = (selectedItem) => {
    onChange({ projects: _.xorWith(projects, [selectedItem], _.isEqual) });
  };

  const filtersCount = useMemo(() => projects.length, [filters]);

  if (loading || !availableProjects.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableProjects}
        renderItem={(project) => (
          <ListItem
            key={`project-${project.id}`}
            item={project}
            selectedItems={projects}
            onChange={onToggle}
          />
        )}
      />
    </CollapsibleFilterGroup>
  );
};

ProjectFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

ProjectFilter.defaultProps = {
  onChange: () => {}
};

export default ProjectFilter;
