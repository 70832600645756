import React from 'react';
import PropTypes from 'prop-types';

import FilterCheckbox from '../../../../../../common/FilterCheckbox';

const InterestItem = ({
  item, selectedItems, onChange
}) => {
  const isSelected = selectedItems.some((interest) => {
    const { id, __typename: typename } = interest;
    return id === item?.id && typename === item?.__typename;
  });

  return (
    <FilterCheckbox
      label={item.name}
      value={item.id}
      checked={isSelected}
      onChange={() => onChange(item)}
    />
  );
};

InterestItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChange: PropTypes.func,
};

InterestItem.defaultProps = {
  selectedItems: [],
  onChange: () => {}
};

export default InterestItem;
