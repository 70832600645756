import React from 'react';
import i18n from 'i18n-js';
import { Col, Row } from 'reactstrap';
import v from 'voca';
import { Link } from 'gatsby';

import { LotMapProviders } from '../../../../../constants';
import {
  getQPTotalBaths,
  getQPTotalBeds,
  getQPTotalPrice,
} from '../../../../../utils/quickPossessionUtils';
import { formatCurrency, formatNumber } from '../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.quickPossessions.show.overview.index' };

const Overview = ({ quickPossession }) => (
  <div>
    <Row>
      <Col lg="6">
        {!v.isBlank(quickPossession.hashId) && (
          <div className="mb-4">
            <h5 className="col-primary-1">{i18n.t('listingId', i18nOpts)}</h5>
            <strong>{quickPossession.hashId}</strong>
          </div>
        )}

        <div className="mb-4">
          <h5 className="col-primary-1">{i18n.t('community', i18nOpts)}</h5>
          <Link to={`/admin/communities/${quickPossession.community.id}`} className="col-primary-2 text-decoration-underline">
            {quickPossession.community.name}
          </Link>
        </div>

        <div className="mb-4">
          <h5 className="col-primary-1">{i18n.t('model', i18nOpts)}</h5>
          <Link to={`/admin/models/${quickPossession.model.id}`} className="col-primary-2 text-decoration-underline">
            {quickPossession.model.name}
          </Link>
        </div>

        {quickPossession.model?.productType && (
          <div className="mb-4">
            <h5 className="col-primary-1">{i18n.t('productType', i18nOpts)}</h5>
            <Link to={`/admin/productTypes/${quickPossession.model.productType.id}`} className="col-primary-2 text-decoration-underline">
              {quickPossession.model.productType.name}
            </Link>
          </div>
        )}

        { quickPossession.elevation && (
          <div className="mb-4">
            <h5 className="col-primary-1">{i18n.t('elevation', i18nOpts)}</h5>
            <div><strong>{quickPossession.elevation.name}</strong></div>
          </div>
        )}

        { quickPossession.package && (
          <div className="mb-4">
            <h5 className="col-primary-1">{i18n.t('package', i18nOpts)}</h5>
            <Link to={`/admin/packages/${quickPossession.package.id}`} className="col-primary-2 text-decoration-underline">
              {quickPossession.package.name}
            </Link>
          </div>
        )}

        { quickPossession.palette && (
          <div className="mb-4">
            <h5 className="col-primary-1">{i18n.t('palette', i18nOpts)}</h5>
            <Link to={`/admin/palettes/${quickPossession.palette.id}`}>
              {quickPossession.palette.name}
            </Link>
          </div>
        )}
      </Col>
      <Col>
        <div className="mb-4">
          <h5 className="col-primary-1">{i18n.t('address', i18nOpts)}</h5>
          <strong>{quickPossession.address}</strong>
        </div>

        <div className="mb-4">
          <h5 className="col-primary-1">{i18n.t('status', i18nOpts)}</h5>
          <strong className="text-capitalize">{quickPossession.status}</strong>
        </div>

        { !v.isBlank(quickPossession.squareFootage) && (
          <div className="mb-4">
            <h5 className="col-primary-1">{i18n.t('squareFootage', i18nOpts)}</h5>
            <strong>{formatNumber(quickPossession.squareFootage)}</strong>
          </div>
        )}

        <div className="mb-4">
          <h5 className="col-primary-1">{i18n.t('beds', i18nOpts)}</h5>
          <strong>{getQPTotalBeds(quickPossession)}</strong>
        </div>

        <div className="mb-4">
          <h5 className="col-primary-1">{i18n.t('baths', i18nOpts)}</h5>
          <strong>{getQPTotalBaths(quickPossession)}</strong>
        </div>

        <div className="mb-4">
          <h5 className="col-primary-1">{i18n.t('price', i18nOpts)}</h5>
          <strong>{formatCurrency(getQPTotalPrice(quickPossession))}</strong>
        </div>

        { !v.isBlank(quickPossession.url) && (
          <div className="mb-4">
            <h5 className="col-primary-1">{i18n.t('url', i18nOpts)}</h5>
            <strong>{quickPossession.url}</strong>
          </div>
        )}

        { quickPossession.community.lotMapSettings?.provider === LotMapProviders.LOT_WORKS
          && !v.isBlank(quickPossession.lotId) && (
          <div className="mb-4">
            <h5 className="col-primary-1">{i18n.t('lotId', i18nOpts)}</h5>
            <strong>{quickPossession.lotId}</strong>
          </div>
        )}

        { !v.isBlank(quickPossession.externalId) && (
          <div className="mb-4">
            <h5 className="col-primary-1">{i18n.t('externalId', i18nOpts)}</h5>
            <strong>{quickPossession.externalId}</strong>
          </div>
        )}

      </Col>
    </Row>
  </div>
);

export default Overview;
