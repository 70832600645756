import React, { useState } from 'react';
import {
  Button, Spinner, Card, Label, Input, FormGroup
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import v from 'voca';

import { saveOffer } from '../../../../../../../store/actions/offerActions';
import { setHomeAttributes, setReloadHomes } from '../../../../../../../store/actions/homeActions';
import { OfferSignStatuses } from '../../../../../../../constants';
import { stripToNull } from '../../../../../../../utils/stringUtils';
import { getError } from '../../../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../../../ui/InputError';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.signSection.rejectSignCard.index' };

const RejectSignCard = ({ home, ...props }) => {
  const [form, setForm] = useState({});
  const [rejecting, setRejecting] = useState(false);
  const [error, setError] = useState(null);
  const { offer } = home;
  const { signStatus } = offer;

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onReject = () => {
    setRejecting(true);

    const input = {
      id: offer.id,
      signStatus: OfferSignStatuses.REJECTED,
      signNote: stripToNull(form.signNote)
    };
    const variables = { input };

    props.saveOffer(variables)
      .then(({ value: { data: { item } } }) => {
        props.setHomeAttributes(home.id, { offer: item });
        props.setReloadHomes(true);
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setRejecting(false));
  };

  const isValidForm = !v.isBlank(form.signNote);

  if ([OfferSignStatuses.ACCEPTED, OfferSignStatuses.DECLINED].includes(signStatus)) return null;

  if (signStatus === OfferSignStatuses.REJECTED) {
    return (
      <Card body className="p-3 mb-3">
        <h3>{i18n.t('title', i18nOpts)}</h3>

        <div className="white-space-pre-wrap">{offer.signNote}</div>
      </Card>
    );
  }

  return (
    <Card body className="p-3 mb-3">
      <h3>{i18n.t('title', i18nOpts)}</h3>

      <p>
        {i18n.t('description', i18nOpts)}
      </p>

      <FormGroup>
        <Label for="signNote">{i18n.t('signNote', i18nOpts)}</Label>
        <Input
          type="textarea"
          name="signNote"
          id="signNote"
          value={form.signNote || ''}
          onChange={onTextChange}
          maxLength="250"
          rows={4}
          invalid={isInputInvalid(error, 'signNote')}
        />
        <InputError name="signNote" error={error} />
      </FormGroup>

      <div className="text-right">
        <Button
          color="danger"
          className="ml-3"
          onClick={onReject}
          disabled={rejecting || !isValidForm}
        >
          {rejecting && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.reject', i18nOpts)}
        </Button>
      </div>
    </Card>
  );
};

RejectSignCard.propTypes = {};

RejectSignCard.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}), {
  saveOffer,
  setHomeAttributes,
  setReloadHomes
})(RejectSignCard);
