import React from 'react';
import PropTypes from 'prop-types';

const BuildingModel = ({ unit }) => {
  const { buildingModel } = unit;

  if (!buildingModel) return null;

  return (
    <div className="font-weight-500 col-gray-900">
      {buildingModel.name}
    </div>
  );
};

BuildingModel.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

BuildingModel.defaultProps = {};

export default BuildingModel;
