import React, { Component } from 'react';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { getSpecifications } from '../../../../../store/actions/specificationActions';
import FormModal from './FormModal';
import ListItem from './ListItem';
import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';
import { isAdmin } from '../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.models.show.specifications.index' };

function onOpenModal() {
  ModalService.open(ModalNames.SPECIFICATION_FORM);
}

class Specifications extends Component {
  componentDidMount() {
    this.loadSpecifications();
  }

  loadSpecifications() {
    const { props } = this;
    const { model } = this.props;

    const variables = { filter: { modelId: model.id } };
    props.getSpecifications(variables);
  }

  render() {
    const { specifications } = this.props;

    return (
      <div>
        {
          specifications.map((s) => (
            <ListItem key={`specification-${s.id}`} specification={s} />
          ))
        }

        {isAdmin() && (
          <div className="text-right">
            <Button color="primary" onClick={onOpenModal}>
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Button>
          </div>
        )}

        <FormModal />

      </div>
    );
  }
}

export default connect((store) => ({
  model: store.models.model,
  specifications: store.specifications.specifications
}), {
  getSpecifications
})(Specifications);
