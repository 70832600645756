import React from 'react';
import v from 'voca';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';

import { formatTimestamp } from '../../../../../../utils/dateUtils';
import { formatCurrency } from '../../../../../../utils/currencyUtils';

const CardField = ({
  instance, label, attr, isDate, isAmount
}) => {
  let sanitizedValue = instance[attr];

  if (v.isBlank(sanitizedValue)) return null;

  if (isDate) sanitizedValue = formatTimestamp(sanitizedValue);
  if (isAmount) sanitizedValue = formatCurrency(sanitizedValue);

  return (
    <Col sm="12" lg="6">
      <div className="mb-3">
        <div className="label">{label}</div>
        <div>
          <strong>{sanitizedValue}</strong>
        </div>
      </div>
    </Col>
  );
};

CardField.propTypes = {
  instance: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  attr: PropTypes.string.isRequired,
  isDate: PropTypes.bool,
};

CardField.defaultProps = {
  isDate: false,
};

export default CardField;
