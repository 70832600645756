import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import classNames from 'classnames';

import styles from './FinancialReport.module.scss';
import { getCustomer, getCustomerFinancialReport } from '../../../../store/actions/customerActions';
import IdCard from './IdCard';
import ReportHeader from './ReportHeader';
import RentingPowerCard from './RentingPowerCard';
import AverageMonthlyRecurring from './AverageMonthlyRecurring';
import AccountOutflowCard from './AccountOutflowCard';
import EmployersSection from './EmployersSection';
import OverallGovernmentTable from './OverallGovernmentTable';
import NonEmployerIncomeCard from './NonEmployerIncomeCard';
import AccountAnalysisCard from './AccountAnalysisCard';
import OverallBalanceCard from './OverallBalanceCard';
import ClosingBalanceCard from './ClosingBalanceCard';
import NSFFeeTable from './NSFFeeTable';
import LiabilitiesSection from './LiabilitiesSection';
import { isMasterAdmin } from '../../../../utils/authUtils';
import BuyingPowerCard from './BuyingPowerCard';
import useHomeRental from '../../../../hooks/useHomeRental';

const i18nOpts = { scope: 'components.admin.customers.financialReport.index' };

function hasAffordability(customer) {
  const { isFinancialVerified, companyContact } = customer;
  const isFinancialVerifiedAndGranted = isFinancialVerified
    && companyContact.verifiedFinancialGranted;
  const { affordability } = companyContact;

  if (!isFinancialVerifiedAndGranted) return false;
  return !!affordability;
}

const FinancialReport = ({
  id, customer, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const reportSettings = currentCompany.reportSettings || {};
  const { isRental, typeI18nKey } = useHomeRental();

  const loadCustomerAndReport = () => {
    setMounted(false);

    const customerId = parseInt(id, 10);

    const variables = {
      id: customerId,
      withParty: true,
      withAffordability: true,
      withPropertyInfo: true,
      companyId: currentCompany.id,
      excludedPartyFromCompanyContact: true
    };

    props.getCustomer(variables)
      .then(({ value: { data: { item } } }) => {
        if (!hasAffordability(item)) return Promise.reject(new Error('Customer without Bank Verification'));

        return props.getCustomerFinancialReport({ contactId: customerId });
      })
      .then(() => {
        setMounted(true);
      })
      .catch(() => navigate('/admin/customers'));
  };

  useEffect(() => {
    if (!reportSettings.enabledFinancialReport || !isMasterAdmin()) {
      navigate('/admin/customers');
      return;
    }

    loadCustomerAndReport();
  }, []);

  if (!mounted) return <div className="text-center my-5"><Spinner size="lg" /></div>;

  return (
    <div>
      <Helmet title={customer.name} />

      <div className="d-flex justify-content-between align-items-end flex-wrap">
        <h1 className={classNames(styles.title)}>{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</h1>
        <div>
          <ReportHeader />
        </div>
      </div>

      <hr className={styles.headerSeparator} />

      <h2 className={styles.subtitle}>{i18n.t('sections.identity', i18nOpts)}</h2>
      <Row>
        <Col xs="12" sm="12" md="8" lg="6">
          <IdCard />
        </Col>
      </Row>

      <Row className="mb-5">
        <Col xs="12" sm="6" md="4">
          {isRental ? (
            <RentingPowerCard />
          ) : (
            <BuyingPowerCard />
          )}
        </Col>
        <Col xs="12" sm="6" md="4">
          <AverageMonthlyRecurring />
        </Col>
        <Col xs="12" sm="6" md="4">
          <AccountOutflowCard />
        </Col>
      </Row>

      <h1 className={classNames(styles.title)}>{i18n.t('report', i18nOpts)}</h1>
      <hr className={styles.headerSeparator} />
      <h2 className={styles.subtitle}>{i18n.t('sections.employerIncomeBreakdown', i18nOpts)}</h2>
      <EmployersSection />

      <h2 className={classNames(styles.subtitle, 'mt-4')}>{i18n.t('sections.nonEmployerIncomeBreakdown', i18nOpts)}</h2>
      <Row>
        <Col xs="12" sm="12" md="6">
          <NonEmployerIncomeCard />
        </Col>
      </Row>
      <OverallGovernmentTable />

      <h3 className={classNames(styles.subtitle, 'mt-4')}>{i18n.t('sections.accountAnalysis', i18nOpts)}</h3>
      <AccountAnalysisCard />

      <div className="page-break-always" />

      <Row className="mt-4">
        <Col xs="12" sm="12" md="6">
          <h3 className={styles.subtitle}>{i18n.t('sections.overallBalance', i18nOpts)}</h3>
          <OverallBalanceCard />
        </Col>
        <Col xs="12" sm="12" md="6">
          <h3 className={styles.subtitle}>{i18n.t('sections.closingBalance', i18nOpts)}</h3>
          <ClosingBalanceCard />
        </Col>
      </Row>

      <h3 className={classNames(styles.subtitle, 'mt-3')}>{i18n.t('sections.specifRiskAnalysis', i18nOpts)}</h3>
      <NSFFeeTable />

      <h3 className={classNames(styles.subtitle, 'mt-3')}>{i18n.t('sections.liabilitiesBreakdown', i18nOpts)}</h3>
      <LiabilitiesSection />
    </div>
  );
};

FinancialReport.propTypes = {};

FinancialReport.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}), {
  getCustomer,
  getCustomerFinancialReport
})(FinancialReport);
