import { useSelector } from 'react-redux';

const useHomeRental = () => {
  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany
  }));

  const isRental = !!currentCompany?.enableHomeRental;

  const typeI18nKey = isRental ? 'homeRental' : 'homeSales';

  return { isRental, typeI18nKey };
};

export default useHomeRental;
