import {
  GET_OPTION_TYPE, GET_OPTION_TYPES, SAVE_OPTION_TYPE, DELETE_OPTION_TYPE
} from './types';
import {
  listOptionTypesQuery,
  getOptionTypeQuery,
  updateOptionTypeQuery,
  createOptionTypeQuery,
  deleteOptionTypeQuery
} from '../../graphql';
import api from '../../api';

export function getOptionType(id) {
  return {
    type: GET_OPTION_TYPE,
    payload: api.graphql(getOptionTypeQuery, { id })
  };
}

export function getOptionTypes(variables = {}) {
  return {
    type: GET_OPTION_TYPES,
    payload: api.graphql(listOptionTypesQuery, variables)
  };
}

export function saveOptionType(variables = {}) {
  const query = variables.input.id ? updateOptionTypeQuery : createOptionTypeQuery;
  return {
    type: SAVE_OPTION_TYPE,
    payload: api.graphql(query, variables)
  };
}

export function deleteOptionType(id) {
  return {
    type: DELETE_OPTION_TYPE,
    payload: api.graphql(deleteOptionTypeQuery, { id })
  };
}
