import {
  CLEAN_REPORT_ITEMS,
  SET_REPORT_BUILDING_MODEL,
  SET_REPORT_COMMUNITY,
  SET_REPORT_MODEL,
  SET_REPORT_PROJECT
} from './types';

export function setReportCommunity(community) {
  return {
    type: SET_REPORT_COMMUNITY,
    payload: { community }
  };
}

export function setReportModel(model) {
  return {
    type: SET_REPORT_MODEL,
    payload: { model }
  };
}

export function setReportBuildingModel(buildingModel) {
  return {
    type: SET_REPORT_BUILDING_MODEL,
    payload: { buildingModel }
  };
}

export function setReportProject(project) {
  return {
    type: SET_REPORT_PROJECT,
    payload: { project }
  };
}

export function cleanReportItems() {
  return {
    type: CLEAN_REPORT_ITEMS,
    payload: Promise.resolve()
  };
}
