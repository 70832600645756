import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import BaseSalesRepDropdown from '../../../../../SalesRepDropdown';
import { updateOfferSalesLeadAsync } from './utils';
import { setHomeAttributes } from '../../../../../../../store/actions/homeActions';

const SalesRepDropdown = ({ home, ...props }) => {
  const { offer } = home;
  const [selectedSalesRep, setSelectedSalesRep] = useState(offer.salesLead);

  const onSelect = (newSalesRep) => {
    setSelectedSalesRep(newSalesRep);

    updateOfferSalesLeadAsync(offer.id, newSalesRep)
      .then(({ salesLead }) => {
        const updatedOffer = { ...offer, salesLead };
        props.setHomeAttributes(home.id, { offer: updatedOffer });
      })
      .catch((e) => {
        setSelectedSalesRep(offer.salesLead);
        toast.error(e.message);
      });
  };

  return (
    <BaseSalesRepDropdown
      onChange={onSelect}
      selectedSalesRep={selectedSalesRep}
      size="sm"
      defaultLabelClassName="text-secondary"
    />
  );
};

SalesRepDropdown.propTypes = {};

SalesRepDropdown.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}), {
  setHomeAttributes
})(SalesRepDropdown);
