export function isValidIP(ip) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip);
}

export function isValidIPAddresses(ipAddresses) {
  const ips = getIpsArray(ipAddresses);
  let response = true;

  ips.forEach((ip) => {
    if (ip.includes('-')) {
      const dashed = getDashedIP(ip);
      if (!isValidIP(dashed[0]) || !isValidIP(dashed[1])) response = false;
    } else if (!isValidIP(ip)) response = false;
  });

  return response;
}

export function getIpsArray(ipAddresses) {
  if (!ipAddresses) return [];
  return ipAddresses.split(',').map((ip) => ip.trim());
}

function getDashedIP(dashedIp) {
  return dashedIp.split('-').map((ip) => ip.trim());
}
