import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import v from 'voca';

import './styles.scss';
import { LotMapProviders, ModalNames } from '../../../../../../constants';
import { saveCommunityProductType } from '../../../../../../store/actions/communityProductTypeActions';
import ModalService from '../../../../../../services/ModalService';
import StandardLotWorksProductType from './StandardLotWorksProductType';
import { getError } from '../../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { parseBoolean } from '../../../../../../utils/parserUtils';
import { TagInput } from '../../../../../ui';

const i18nOpts = { scope: 'components.admin.communities.show.productTypes.settingsModal.index' };

function onClose() {
  ModalService.close(ModalNames.PRODUCT_TYPE_SETTINGS);
}

class ProductTypeSettingsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      saving: false,
      lotMapParams: [],
      lotWorksProductTypes: [],
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onParamsChanged = this.onParamsChanged.bind(this);
    this.setLotWorksProductTypes = this.setLotWorksProductTypes.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { opened } = this.props;
    if (prevProps.opened !== opened && opened) this.init();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onParamsChanged(lotMapParams) {
    this.setState({ lotMapParams });
  }

  onSave() {
    const { props } = this;

    this.setState({ saving: true });

    const input = this.getFormInput();
    const variables = { input };
    props.saveCommunityProductType(variables)
      .then(() => {
        ModalService.close(ModalNames.PRODUCT_TYPE_SETTINGS);
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  setLotWorksProductTypes(option) {
    const { lotWorksProductTypes } = this.state;
    let newProductTypes = [...lotWorksProductTypes];
    if (lotWorksProductTypes.find((l) => l === option)) {
      newProductTypes = newProductTypes.filter((pt) => pt !== option);
    } else newProductTypes.push(option);
    this.setState({ lotWorksProductTypes: newProductTypes });
  }

  getFormInput() {
    const { params: { communityProductType } } = this.props;
    const { form, lotMapParams, lotWorksProductTypes } = this.state;

    return {
      id: communityProductType.id,
      minLotPrice: v.isBlank(form.minLotPrice) ? null : parseFloat(form.minLotPrice),
      lotMapParams: this.mapParamsValues(lotMapParams),
      standardLotWorksProductTypes: lotWorksProductTypes,
      enableLotMap: parseBoolean(form?.enableLotMap)
    };
  }

  mapParamsValues = (params) => params.map((param) => param.displayValue)

  mapReverseParamsValues = (params) => {
    if (params) return params.map((param, index) => ({ index, displayValue: param }));
    return [];
  }

  init() {
    const { params: { communityProductType } } = this.props;
    const {
      minLotPrice, lotMapParams, standardLotWorksProductTypes, enableLotMap
    } = communityProductType;
    const form = {
      minLotPrice,
      enableLotMap
    };
    this.setState({
      form,
      saving: false,
      lotMapParams: this.mapReverseParamsValues(lotMapParams),
      lotWorksProductTypes: standardLotWorksProductTypes.length > 0 ? standardLotWorksProductTypes
        : []
    });
  }

  render() {
    const {
      form, saving, lotMapParams, lotWorksProductTypes, error
    } = this.state;
    const { community, opened, params: { communityProductType } } = this.props;
    const hasLotMap = community.enableLotMapIntegration;

    const lotMapProvider = community.lotMapSettings?.provider;

    return (
      <Modal isOpen={opened}>
        <ModalHeader>
          {i18n.t('title', { ...i18nOpts, productTypeName: communityProductType?.productType?.name })}
        </ModalHeader>

        <ModalBody>
          { hasLotMap && (
            <FormGroup check className="mb-3">
              <Label
                check
                className="pointer"
              >
                <Input
                  type="checkbox"
                  name="enableLotMap"
                  className="pointer"
                  value={!parseBoolean(form.enableLotMap)}
                  checked={parseBoolean(form.enableLotMap)}
                  onChange={this.onTextChange}
                />
                {i18n.t('enableLotMap', i18nOpts)}
              </Label>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="minLotPrice">{i18n.t('minLotPrice', i18nOpts)}</Label>
            <Input
              type="number"
              name="minLotPrice"
              id="minLotPrice"
              value={form.minLotPrice || ''}
              onChange={this.onTextChange}
              invalid={isInputInvalid(error, 'minLotPrice')}
            />
            <InputError name="minLotPrice" error={error} />
          </FormGroup>

          <FormGroup className="mb-4">
            <Label for="lotMapParams">{i18n.t('lotMapParams', i18nOpts)}</Label>
            <TagInput
              tags={lotMapParams}
              onTagsChanged={this.onParamsChanged}
              placeholder={i18n.t('lotMapParamsPlaceholder', i18nOpts)}
            />
          </FormGroup>

          {lotMapProvider === LotMapProviders.LOT_WORKS && (
            <FormGroup>
              <Label for="standardLotWorksProductTypes">{i18n.t('standardizedLotWorksProductType', i18nOpts)}</Label>
              <StandardLotWorksProductType
                selectedLotWorksProductTypes={lotWorksProductTypes}
                setLotWorksProductTypes={this.setLotWorksProductTypes}
              />
            </FormGroup>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onClose} disabled={saving}>
            {i18n.t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect((store) => ({
  opened: store.modals[ModalNames.PRODUCT_TYPE_SETTINGS]?.opened || false,
  params: store.modals[ModalNames.PRODUCT_TYPE_SETTINGS]?.params || {},
  community: store.communities.community,
}), {
  saveCommunityProductType
})(ProductTypeSettingsModal);
