import React from 'react';

const OwnlyLogo = () => (
  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_128_2003)">
      <path d="M3.06193 9.69447L2.29048 5.29466L2.29934 5.29299L4.61916 1.78254L8.02128 4.18353L8.80933 8.67853L3.06193 9.69447ZM8.85416 4.05069L9.95068 3.84287L4.28389 0.0683966L2.75541 2.28993L2.65461 1.76023L1.34021 2.01642L1.68775 3.84162L0.447754 5.64398L1.46182 5.4517L2.30532 10.2622C2.3416 10.4689 2.51918 10.6143 2.71975 10.6143C2.74397 10.6143 2.76829 10.6122 2.79282 10.6078L9.37073 9.44525C9.48101 9.42565 9.57913 9.36256 9.64334 9.26976C9.70765 9.17696 9.7328 9.06215 9.71322 8.95069L8.85416 4.05069Z" fill="#08A484" />
      <path d="M4.87736 3.76261L4.146 3.90505L4.28998 4.66113L5.02794 4.50837L4.87736 3.76261Z" fill="#A7C11E" />
      <path d="M5.84921 4.33835L5.70873 3.60041L4.95996 3.74629L5.11033 4.49121L5.84921 4.33835Z" fill="#A7C11E" />
      <path d="M5.27874 5.32324L6.00999 5.1808L5.86539 4.42128L5.12744 4.57404L5.27874 5.32324Z" fill="#A7C11E" />
      <path d="M4.30566 4.74397L4.44687 5.48535L5.19576 5.33947L5.04466 4.59111L4.30566 4.74397Z" fill="#A7C11E" />
      <path d="M3.95228 14.5409C3.58402 14.5409 3.28564 14.8641 3.28564 15.2627C3.28564 15.6613 3.58402 15.9844 3.95228 15.9844C4.32043 15.9844 4.61892 15.6613 4.61892 15.2627C4.61892 14.8641 4.32043 14.5409 3.95228 14.5409Z" fill="#4D4F51" />
      <path d="M7.8097 14.5409C7.44154 14.5409 7.14307 14.8641 7.14307 15.2627C7.14307 15.6613 7.44154 15.9844 7.8097 15.9844C8.17795 15.9844 8.47643 15.6613 8.47643 15.2627C8.47643 14.8641 8.17795 14.5409 7.8097 14.5409Z" fill="#4D4F51" />
      <path d="M7.18885 11.8784L7.46765 9.71389H8.79298L8.22869 11.8784H7.18885ZM1.58895 9.71389H2.78132L3.18101 11.8784H2.17447L1.58895 9.71389ZM2.34442 7.34751L2.71835 9.37271H1.4966L0.9488 7.34751H2.34442ZM5.30078 9.37271V7.34751H7.43229L7.17143 9.37271H5.30078ZM5.30078 9.71389H7.12753L6.84873 11.8784H5.30078V9.71389ZM3.06135 9.37271L2.68743 7.34751H4.96344V9.37271H3.06135ZM4.96344 9.71389V11.8784H3.52402L3.12433 9.71389H4.96344ZM8.88203 9.37271H7.51155L7.77241 7.34751H9.41014L8.88203 9.37271ZM12.5197 4.65967H10.6453C10.4939 4.65967 10.361 4.76175 10.3204 4.9094L9.86064 6.57975H0.451608C0.333288 6.57975 0.22177 6.63564 0.150036 6.73074C0.0783017 6.82605 0.0544935 6.94951 0.0857226 7.06504L1.51917 12.3635C1.56431 12.5303 1.71417 12.646 1.88506 12.646H8.52088C8.55159 12.646 8.58107 12.641 8.60993 12.6341C8.7064 12.656 9.02931 12.7635 9.02931 13.2221C9.02931 13.584 8.69733 13.6287 8.55716 13.6311H2.87284C2.6866 13.6311 2.53561 13.7839 2.53561 13.9724C2.53561 14.1607 2.6866 14.3135 2.87284 14.3135H8.55716C9.01807 14.3135 9.70377 14.0228 9.70377 13.2221C9.70377 12.5984 9.36747 12.2483 9.04714 12.0787L10.9014 5.34203H12.5197C12.7058 5.34203 12.8569 5.18927 12.8569 5.00085C12.8569 4.81243 12.7058 4.65967 12.5197 4.65967Z" fill="#4D4F51" />
    </g>
    <defs>
      <clipPath id="clip0_128_2003">
        <rect width="13" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

OwnlyLogo.propTypes = {};

OwnlyLogo.defaultProps = {};

export default OwnlyLogo;
