import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import styles from './CustomerCoBuyersCountBadge.module.scss';
import CoBuyerIcon from '../../../../assets/icons/CoBuyerIcon';

const CustomerCoBuyersCountBadge = ({ count }) => {
  if (count === 0) return null;

  return (
    <Badge color="primary" pill className={styles.container}>
      <CoBuyerIcon />
    </Badge>
  );
};

CustomerCoBuyersCountBadge.propTypes = {
  count: PropTypes.number.isRequired
};

CustomerCoBuyersCountBadge.defaultProps = {};

export default CustomerCoBuyersCountBadge;
