import {
  LIST_TRACKING_PAGES,
  GET_TRACKING_PAGE,
  CLEAN_TRACKING_PAGES, SAVE_TRACKING_PAGE, DELETE_TRACKING_PAGE
} from './types';
import {
  createTrackingPageMutation, deleteTrackingPageMutation, getTrackingPageQuery,
  listTrackingPagesQuery, updateTrackingPageMutation
} from '../../graphql';
import api from '../../api';

export function listTrackingPages(variables = {}) {
  return {
    type: LIST_TRACKING_PAGES,
    payload: api.graphql(listTrackingPagesQuery, variables)
  };
}

export function getTrackingPage(id) {
  return {
    type: GET_TRACKING_PAGE,
    payload: api.graphql(getTrackingPageQuery, { id })
  };
}

export function cleanTrackingPages(extraProps = {}) {
  return {
    type: CLEAN_TRACKING_PAGES,
    payload: Promise.resolve(extraProps)
  };
}

export function saveTrackingPage(variables = {}) {
  const query = variables.input.id ? updateTrackingPageMutation : createTrackingPageMutation;
  return {
    type: SAVE_TRACKING_PAGE,
    payload: api.graphql(query, variables)
  };
}

export function deleteTrackingPage(id) {
  return {
    type: DELETE_TRACKING_PAGE,
    payload: api.graphql(deleteTrackingPageMutation, { id })
  };
}
