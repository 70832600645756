import React from 'react';
import PropTypes from 'prop-types';

import styles from './PaymentMethod.module.scss';
import { PaymentMethods } from '../../../../../../../constants';
import CreditCard from './CreditCard';
import BankAccount from './BankAccount';

const PaymentMethod = ({ deposit }) => {
  const { paymentMethod, paymentMethodDetails } = deposit;

  return (
    <div className={styles.paymentMethodContainer}>
      {paymentMethod === PaymentMethods.CARD && (
        <CreditCard paymentMethodDetails={paymentMethodDetails} />
      )}
      {paymentMethod === PaymentMethods.ACH_DIRECT_DEBIT && (
        <BankAccount paymentMethodDetails={paymentMethodDetails} />
      )}
    </div>
  );
};

PaymentMethod.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.any).isRequired
};

PaymentMethod.defaultProps = {};

export default PaymentMethod;
