import React from 'react';
import { connect } from 'react-redux';

import ReservationDocumentManagement from '../../../../global/ReservationDocumentManagement';
import { DocumentTemplateAssignableEntities } from '../../../../../constants';

const ReservationDocument = ({ city }) => (
  <ReservationDocumentManagement
    entityId={city.id}
    entityType={DocumentTemplateAssignableEntities.CITY}
  />
);

ReservationDocument.propTypes = {};

ReservationDocument.defaultProps = {};

export default connect((store) => ({
  city: store.cities.city,
}))(ReservationDocument);
