import React from 'react';
import { connect } from 'react-redux';

import DealInfo from './DealInfo';
import { ModalNames, ReserveStepTypes } from '../../../../../constants';
import OfferSection from './OfferSection';
import SignSection from './SignSection';
import DepositSection from './DepositSection';

const Overview = ({ home, params }) => {
  const { stepType } = params;

  return (
    <div className="p-3">
      <div className="mb-3">
        <DealInfo home={home} />
      </div>

      {stepType === ReserveStepTypes.OFFER && (
        <OfferSection />
      )}

      {stepType === ReserveStepTypes.SIGN && (
        <SignSection />
      )}

      {stepType === ReserveStepTypes.DEPOSIT && (
        <DepositSection />
      )}
    </div>
  );
};

Overview.propTypes = {};

Overview.defaultProps = {};

export default connect((store) => ({
  params: store.modals[ModalNames.DEAL]?.params || {},
  home: store.homes.home,
}))(Overview);
