import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import Step from '../components/Step';
import ModalService from '../../../../../services/ModalService';
import { DepositStatuses, ModalNames, ReserveStepTypes } from '../../../../../constants';
import DepositPendingIcon from '../../../../../assets/icons/deposit/DepositPendingIcon';
import DepositAcceptedIcon from '../../../../../assets/icons/deposit/DepositAcceptedIcon';
import DepositDeclinedIcon from '../../../../../assets/icons/deposit/DepositDeclinedIcon';

const i18nOpts = { scope: 'components.global.home.offerSteps.depositStep.index' };

const DepositStep = ({ home, clickable, label: defaultLabel }) => {
  const { deposit } = home;
  const status = deposit?.status;

  let label;
  let color;
  let date;
  let Icon;

  switch (status) {
    case DepositStatuses.SUBMITTED:
      label = i18n.t('completed', i18nOpts);
      color = 'success';
      date = deposit.statusChangeDates.submittedAt;
      Icon = DepositAcceptedIcon;
      break;

    case DepositStatuses.SUCCEEDED:
      label = i18n.t('completed', i18nOpts);
      color = 'success';
      date = deposit.statusChangeDates.succeededAt;
      Icon = DepositAcceptedIcon;
      break;

    case DepositStatuses.FAILED:
      label = i18n.t('pending', i18nOpts);
      color = 'danger';
      date = deposit.statusChangeDates.acceptedAt;
      Icon = DepositDeclinedIcon;
      break;

    case DepositStatuses.ACCEPTED:
      label = i18n.t('accepted', i18nOpts);
      color = 'success';
      date = deposit.statusChangeDates.acceptedAt;
      Icon = DepositAcceptedIcon;
      break;

    case DepositStatuses.REJECTED:
      label = i18n.t('rejected', i18nOpts);
      color = 'danger';
      date = deposit.statusChangeDates.rejectedAt;
      Icon = DepositDeclinedIcon;
      break;

    default:
      label = i18n.t('pending', i18nOpts);
      color = 'light';
      date = null;
      Icon = DepositPendingIcon;
      break;
  }

  const onClick = () => {
    ModalService.open(ModalNames.DEAL, { homeId: home.id, stepType: ReserveStepTypes.DEPOSIT });
  };

  let extraProps = {};
  if (clickable && !!status) {
    extraProps = {
      role: 'button',
      'aria-hidden': true,
      onClick
    };
  }

  return (
    <div>
      <Step
        title={defaultLabel}
        label={label}
        color={color}
        date={date}
        Icon={Icon}
        {...extraProps}
      />
    </div>
  );
};

DepositStep.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired,
  clickable: PropTypes.bool,
  label: PropTypes.string
};

DepositStep.defaultProps = {
  clickable: false,
  label: null
};

export default DepositStep;
