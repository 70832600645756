import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { getReasonPhrase, } from 'http-status-codes';
import { Badge, Card } from 'reactstrap';
import v from 'voca';

import { StatusBadgeProps } from '../utils';

const i18nOpts = { scope: 'components.admin.integrations.webhooks.webhookDeliveries.show.index' };

const Show = ({ webhookDelivery }) => {
  if (!webhookDelivery) return null;

  const badgeProps = StatusBadgeProps[webhookDelivery.status];
  const { responseStatusCode, responseError } = webhookDelivery;

  return (
    <div>
      <h2 className="mb-4"><code>{webhookDelivery.event}</code></h2>

      <div>
        <h4>{i18n.t('response', i18nOpts)}</h4>
        <div className="mb-2">
          <Badge color={badgeProps.color} className="align-text-top">
            <badgeProps.Icon size="16" color="white" />
            {!v.isBlank(responseStatusCode) && (
              <>
                <span className="ml-1 text-white">{responseStatusCode}</span>
                <span className="ml-1 text-white">{getReasonPhrase(responseStatusCode)}</span>
              </>
            )}
          </Badge>

          {!v.isBlank(responseError) && (
            <div className="text-danger mt-1">
              {i18n.t('errorMessage', { ...i18nOpts, error: responseError })}
            </div>
          )}
        </div>

        <Card body color="light">
          <pre className="mb-0">{webhookDelivery.responseBody || '{}'}</pre>
        </Card>
      </div>

      <div className="mt-4">
        <h4>{i18n.t('request', i18nOpts)}</h4>

        <Card body color="light">
          <pre className="mb-0">{webhookDelivery.requestBody || '{}'}</pre>
        </Card>
      </div>

    </div>
  );
};

Show.propTypes = {};

Show.defaultProps = {};

export default connect((store) => ({
  webhookDelivery: store.webhookDeliveries.webhookDelivery
}))(Show);
