import React from 'react';
import PropTypes from 'prop-types';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import {
  EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton
} from 'react-share';
import { AiOutlineQrcode } from 'react-icons/ai';
import i18n from 'i18n-js';

import SocialShareIcon from './SocialShareIcon';
import './styles.scss';
import ModalService from '../../../services/ModalService';
import { ModalNames } from '../../../constants';

const i18nOpts = { scope: 'components.global.socialShare.index' };

const SHARE_MEDIUMS = Object.freeze({
  EMAIL: 'email',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKED_IN: 'linkedin',
  QR_CODE: 'qrcode'
});

const SocialShare = ({ url, itemName }) => {
  const text = i18n.t('text', { ...i18nOpts, itemName });

  const openQRModal = () => {
    ModalService.open(
      ModalNames.QR_CODE_MODAL, { url: buildUrl(SHARE_MEDIUMS.QR_CODE), name: itemName }
    );
  };

  const buildUrl = (medium) => `${url}?utm_medium=sharelink&utm_source=${medium}`;

  return (
    <div className="mt-4 d-flex social-share">
      <EmailShareButton subject={text} url={buildUrl(SHARE_MEDIUMS.EMAIL)}>
        <SocialShareIcon>
          <MdEmail className="col-secondary-2" />
        </SocialShareIcon>
      </EmailShareButton>

      <FacebookShareButton url={buildUrl(SHARE_MEDIUMS.FACEBOOK)} quote={text}>
        <SocialShareIcon>
          <FaFacebookF className="col-secondary-2" />
        </SocialShareIcon>
      </FacebookShareButton>

      <LinkedinShareButton url={buildUrl(SHARE_MEDIUMS.LINKED_IN)} title={text} source={text}>
        <SocialShareIcon>
          <FaLinkedinIn className="col-secondary-2" />
        </SocialShareIcon>
      </LinkedinShareButton>

      <TwitterShareButton url={buildUrl(SHARE_MEDIUMS.TWITTER)} title={text}>
        <SocialShareIcon>
          <FaTwitter className="col-secondary-2" />
        </SocialShareIcon>
      </TwitterShareButton>

      <div role="button" aria-hidden onClick={openQRModal}>
        <SocialShareIcon>
          <AiOutlineQrcode className="col-secondary-2" />
        </SocialShareIcon>
      </div>
    </div>
  );
};

SocialShare.propTypes = {
  url: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired
};

export default SocialShare;
