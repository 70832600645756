import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, CardHeader, CardTitle
} from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { NavigationMainNames, PriceableTypes } from '../../../../../../constants';
import { isAdmin } from '../../../../../../utils/authUtils';
import { deleteModelPalette } from '../../../../../../store/actions/modelPaletteActions';
import PriceRuleList from '../../../../../global/priceRules/PriceRuleList';
import AddPriceRuleButton from '../../../../../global/priceRules/AddPriceRuleButton';
import ActionsMenu from '../../../../../global/ActionsMenu';
import NavigationService from '../../../../../../services/NavigationService';

const ListItem = ({ modelPalette, ...props }) => {
  const onDelete = () => {
    props.deleteModelPalette(modelPalette.id);
  };

  const paletteUrl = useMemo(() => NavigationService.getNavigationShowUrl(
    modelPalette.palette, NavigationMainNames.PALETTES
  ), [modelPalette]);

  return (
    <Card>
      <CardHeader>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>
              <Link to={paletteUrl}>
                {modelPalette.palette.name}
              </Link>
            </CardTitle>
          </div>
          {isAdmin() && (
            <div>
              <ActionsMenu item={modelPalette} onDelete={onDelete} />
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody>
        {isAdmin() && (
          <div className="text-right">
            <AddPriceRuleButton
              priceableId={modelPalette.palette.id}
              priceableType={PriceableTypes.PALETTE}
            />
          </div>
        )}

        <PriceRuleList
          priceableId={modelPalette.palette.id}
          priceableType={PriceableTypes.PALETTE}
          className={classNames({ 'mt-3': isAdmin() })}
        />
      </CardBody>
    </Card>
  );
};

ListItem.propTypes = {
  modelPalette: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect(() => ({}), {
  deleteModelPalette
})(ListItem);
