import React from 'react';

import List from './List';

const Deals = () => (<List />);

Deals.propTypes = {};

Deals.defaultProps = {};

export default Deals;
