import {
  DELETE_OFFER, GET_OFFER, GET_OFFERS, SAVE_OFFER, SET_OFFER_FILTERS
} from './types';
import {
  listOffersQuery,
  getOfferQuery,
  deleteOfferQuery,
  createOfferQuery,
  updateOfferQuery
} from '../../graphql';
import api from '../../api';

export function getOffers(variables = {}) {
  return {
    type: GET_OFFERS,
    payload: api.graphql(listOffersQuery, variables)
  };
}

export function getOffer(id) {
  return {
    type: GET_OFFER,
    payload: api.graphql(getOfferQuery, { id })
  };
}

export function saveOffer(variables = {}) {
  const query = variables.input?.id ? updateOfferQuery : createOfferQuery;
  return {
    type: SAVE_OFFER,
    payload: api.graphql(query, variables)
  };
}

export function deleteOffer(id) {
  return {
    type: DELETE_OFFER,
    payload: api.graphql(deleteOfferQuery, { id })
  };
}

export function setOfferFilters(filters) {
  return {
    type: SET_OFFER_FILTERS,
    payload: { filters }
  };
}
