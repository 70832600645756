import React from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import ListItem from './ListItem';
import { DateFormats } from '../../../../../../constants';
import ListHeader from './ListHeader';

const List = ({ webhookDeliveries }) => {
  const groupedWebhookDeliveries = _.groupBy(webhookDeliveries,
    (wd) => moment(wd.processedAt).format(DateFormats.MMM_DD_YYYY));
  const headers = Object.keys(groupedWebhookDeliveries);

  return (
    <div>
      {
        headers.map((header) => (
          <Table responsive size="sm" key={`webhook-group-${header}`} className="mb-0">
            <thead>
              <ListHeader title={header} />
            </thead>
            <tbody>

              {
                groupedWebhookDeliveries[header].map((webhookDelivery) => (
                  <ListItem
                    key={`webhook-delivery-${webhookDelivery.id}`}
                    webhookDelivery={webhookDelivery}
                  />
                ))
              }
            </tbody>
          </Table>
        ))
      }
    </div>
  );
};

List.propTypes = {};

List.defaultsProps = {};

export default connect((store) => ({
  webhookDeliveries: store.webhookDeliveries.webhookDeliveries
}))(List);
