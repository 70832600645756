import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import i18n from 'i18n-js';
import { Link, navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import ListEmpty from '../../../common/ListEmpty';
import {
  getArrayMappedNames,
  getSanitizedType,
  getTaxRatePercentage
} from '../../../../utils/taxRateUtils';
import ActionsMenu from '../../../global/ActionsMenu';
import { getTaxRates, deleteTaxRate } from '../../../../store/actions/taxRateActions';
import { TaxRateTypes } from '../../../../constants';
import CreatedByTableItem from '../../../global/CreatedByTableItem';
import LastModifiedByTableItem from '../../../global/LastModifiedByTableItem';
import { mergeCommunitiesProjects } from '../utils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.taxRates.list.index' };

const List = ({
  taxRates, loading, error, deleteError, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    loadTaxRates();
  }, []);

  const loadTaxRates = () => {
    const variables = {
      filter: { companyId: currentCompany.id },
    };
    props.getTaxRates(variables)
      .catch(() => {
        if (error) toast.error(error);
      })
      .finally(() => setMounted(true));
  };

  const onDelete = (id) => {
    props.deleteTaxRate(id)
      .then()
      .catch(() => toast.error(deleteError));
  };

  const onEdit = (taxRate) => {
    navigate(`/admin/taxRates/form?id=${taxRate.id}`);
  };

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={taxRates} />

          {taxRates.length > 0 && (
            <div className="table-responsive">
              <Table bordered>
                <thead>
                  <tr>
                    <th>{i18n.t('name', i18nOpts)}</th>
                    <th>{i18n.t('rate', i18nOpts)}</th>
                    <th>{i18n.t('type', i18nOpts)}</th>
                    <th>{i18n.t('citiesCommunitiesProjects', i18nOpts)}</th>
                    <th>{i18n.t('createdBy', i18nOpts)}</th>
                    <th>{i18n.t('lastModifiedBy', i18nOpts)}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    taxRates.map((taxRate) => (
                      <tr key={`taxRate-${taxRate.id}`}>
                        <td>
                          <Link to={`/admin/taxRates/${taxRate.id}`}>
                            {taxRate.name}
                          </Link>
                        </td>
                        <td>{getTaxRatePercentage(taxRate.rate)}</td>
                        <td>{getSanitizedType(taxRate.type)}</td>
                        { taxRate.type === TaxRateTypes.CITY_BASED && (
                          <td>{getArrayMappedNames(taxRate.cities)}</td>
                        )}
                        { taxRate.type === TaxRateTypes.COMMUNITY_PROJECT_BASED && (
                          <td>
                            {getArrayMappedNames(
                              mergeCommunitiesProjects(taxRate.communities, taxRate.projects)
                            )}
                          </td>
                        )}
                        <CreatedByTableItem instance={taxRate} />
                        <LastModifiedByTableItem instance={taxRate} />
                        <td className="text-right">
                          <ActionsMenu item={taxRate} onDelete={onDelete} onEdit={onEdit} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  taxRates: store.taxRates.taxRates,
  loading: store.taxRates.getTaxRates.loading || false,
  error: store.taxRates.getTaxRates.error || null,
  deleteError: store.taxRates.deleteTaxRate.error || null,
  currentCompany: store.companies.currentCompany
}), {
  getTaxRates,
  deleteTaxRate
})(List);
