import React from 'react';
import i18n from 'i18n-js';
import {
  Row, Col, FormGroup, Input, Label, Card
} from 'reactstrap';

import InputError, { isInputInvalid } from '../../../../../ui/InputError';

const i18nOpts = { scope: 'components.admin.externalDataSources.marondaHomes.details.index' };

const MarondaHomesFormFields = ({ form, onTextChange, error }) => (
  <div>
    <Row form className="mb-3">
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="apiKey">{i18n.t('apiKey', i18nOpts)}</Label>
          <Input
            type="text"
            name="apiKey"
            id="apiKey"
            value={form.apiKey || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'apiKey')}
          />
          <InputError name="apiKey" error={error} />
        </FormGroup>
      </Col>
    </Row>
    <Row form className="mb-3">
      <Col lg="4" md="6" sm="8" xs="12">
        <div className="mb-3">{i18n.t('proxy', i18nOpts)}</div>

        <Card body>
          <FormGroup>
            <Label for="proxy">{i18n.t('proxyHost', i18nOpts)}</Label>
            <Input
              type="text"
              name="proxyHost"
              id="proxyHost"
              value={form.proxyHost || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'proxy')}
            />
            <InputError name="proxyHost" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="proxy">{i18n.t('proxyPort', i18nOpts)}</Label>
            <Input
              type="text"
              name="proxyPort"
              id="proxyPort"
              value={form.proxyPort || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'proxy')}
            />
            <InputError name="proxyPort" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="proxy">{i18n.t('proxyUsername', i18nOpts)}</Label>
            <Input
              type="text"
              name="proxyUsername"
              id="proxyUsername"
              value={form.proxyUsername || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'proxy')}
            />
            <InputError name="proxyUsername" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="proxy">{i18n.t('proxyPassword', i18nOpts)}</Label>
            <Input
              type="text"
              name="proxyPassword"
              id="proxyPassword"
              value={form.proxyPassword || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'proxy')}
            />
            <InputError name="proxyPassword" error={error} />
          </FormGroup>
        </Card>
      </Col>
    </Row>
  </div>
);

export default MarondaHomesFormFields;
