import i18n from 'i18n-js';

import { userIsMasterAdmin } from './authUtils';

const i18nOpts = { scope: 'utils.auditUtils' };

export function getCreatorDisplayName(instance) {
  const { creator } = instance;

  if (!creator) return null;

  return getModifierDisplayName(creator);
}

export function getLastModifierDisplayName(instance) {
  const { lastModifiedLog } = instance;

  if (!lastModifiedLog) return null;

  return getModifierDisplayName(lastModifiedLog.maker);
}

export function getModifierDisplayName(modifier) {
  if (!modifier) return null;

  const { name: displayName } = modifier;
  const isMasterAdmin = userIsMasterAdmin(modifier);

  return isMasterAdmin ? i18n.t('ownlySupport', i18nOpts) : displayName;
}
