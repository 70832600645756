import React from 'react';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import { formatCurrency } from '../../../../../utils/currencyUtils';
import StatCardValue from '../components/StatCardValue';
import StatCardRowSeparator from '../components/StatCardRowSeparator';

const i18nOpts = { scope: 'components.admin.customers.financialReport.closingBalanceCard.index' };

const ClosingBalanceCard = ({ financialReport }) => {
  const { closingBalance } = financialReport;
  const {
    dayOfIncome, dayOfEmployerIncome, dayOfGovernmentIncome,
    dayAfterIncome, dayAfterEmployerIncome, dayAfterGovernmentIncome
  } = closingBalance;

  return (
    <Card body>
      <div className="d-flex align-items-center justify-content-around">
        <StatCardValue title={i18n.t('dayOfIncome', i18nOpts)}>
          {formatCurrency(dayOfIncome)}
        </StatCardValue>
        <StatCardValue title={i18n.t('dayOfEmployerIncome', i18nOpts)} showSeparator>
          {formatCurrency(dayOfEmployerIncome)}
        </StatCardValue>
        <StatCardValue title={i18n.t('dayOfGovernmentIncome', i18nOpts)} showSeparator>
          {formatCurrency(dayOfGovernmentIncome)}
        </StatCardValue>
      </div>

      <StatCardRowSeparator />

      <div className="d-flex align-items-center justify-content-around">
        <StatCardValue title={i18n.t('dayAfterIncome', i18nOpts)}>
          {formatCurrency(dayAfterIncome)}
        </StatCardValue>
        <StatCardValue title={i18n.t('dayAfterEmployerIncome', i18nOpts)} showSeparator>
          {formatCurrency(dayAfterEmployerIncome)}
        </StatCardValue>
        <StatCardValue title={i18n.t('dayAfterGovernmentIncome', i18nOpts)} showSeparator>
          {formatCurrency(dayAfterGovernmentIncome)}
        </StatCardValue>
      </div>
    </Card>
  );
};

ClosingBalanceCard.propTypes = {};

ClosingBalanceCard.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
}))(ClosingBalanceCard);
