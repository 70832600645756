import { getHomeInfo } from './homeUtils';
import { getInvitedHomeInfo } from './invitedHomeUtils';

export function getDealHomeInfo(deal) {
  const { home: savedHome } = deal;

  if (savedHome) return getHomeInfo(savedHome);

  const { customerInvitation: { home: invitedHome } } = deal;
  return getInvitedHomeInfo(invitedHome);
}
