import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { SalesInviteMethods } from '../../../constants';
import { getSalesInviteMethodName } from '../../../../../../../utils/enumUtils';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.components.invitationListItem.inviteByButton.index' };

const InviteByButton = ({
  value, onChange, showEmail, showSms
}) => (
  <div className="d-inline-block">
    <div className="font-weight-600 text-left">{i18n.t('title', i18nOpts)}</div>
    <ButtonGroup>
      {showEmail && (
        <Button
          size="sm"
          color="primary"
          outline={value !== SalesInviteMethods.EMAIL}
          active={value === SalesInviteMethods.EMAIL}
          onClick={() => onChange(SalesInviteMethods.EMAIL)}
        >
          {getSalesInviteMethodName(SalesInviteMethods.EMAIL)}
        </Button>
      )}
      {showSms && (
        <Button
          size="sm"
          color="primary"
          outline={value !== SalesInviteMethods.SMS}
          active={value === SalesInviteMethods.SMS}
          onClick={() => onChange(SalesInviteMethods.SMS)}
        >
          {getSalesInviteMethodName(SalesInviteMethods.SMS)}
        </Button>
      )}
    </ButtonGroup>
  </div>
);

InviteByButton.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  showEmail: PropTypes.bool,
  showSms: PropTypes.bool
};

InviteByButton.defaultProps = {
  value: null,
  onChange: () => {},
  showEmail: true,
  showSms: true
};

export default InviteByButton;
