import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MdOpenInNew } from 'react-icons/md';
import classNames from 'classnames';
import moment from 'moment';
import v from 'voca';
import i18n from 'i18n-js';
import { DropdownItem } from 'reactstrap';

import { isRecently } from '../../../../../../../utils/dateUtils';
import { DateFormats, ModalNames } from '../../../../../../../constants';
import ModalService from '../../../../../../../services/ModalService';
import ActionsMenu from '../../../../../../global/ActionsMenu';
import { removeDomainFromURL } from '../../../../../../../utils/urlUtils';

const i18nOpts = { scope: 'components.admin.integrations.tracking.forms.list.listItem.index' };

const ListItem = ({ form }) => {
  const onMapFields = () => {
    ModalService.open(ModalNames.FORM_FIELDS_MAPPING, { form });
  };

  if (!form) return null;

  return (
    <tr>
      <td>
        <div>{form.name}</div>

        <small>
          <a href={form.url} target="_blank" rel="noreferrer" className="word-break-break-all">
            {removeDomainFromURL(form.url)}
            <MdOpenInNew size="12" className="ml-1" />
          </a>
        </small>
      </td>
      <td>
        <span className={classNames({ 'text-success': isRecently(form.createdAt) })}>
          {moment(form.createdAt).format(DateFormats.MMM_DD_YYYY)}
        </span>
      </td>
      <td>
        {form.isMapped && (
          i18n.t('yes', i18nOpts)
        )}
      </td>
      <td>
        {!v.isBlank(form.lastSubmittedAt) && (
          <span className={classNames({ 'text-success': isRecently(form.lastSubmittedAt) })}>
            {moment(form.lastSubmittedAt).format(DateFormats.MMM_DD_YYYY_HH_MM)}
          </span>
        )}
      </td>
      <td>
        {form.formSubmissionsCount > 0 && (
          <span>{form.formSubmissionsCount}</span>
        )}
      </td>
      <td className="text-right">
        <ActionsMenu
          item={form}
        >
          <DropdownItem onClick={onMapFields}>
            {i18n.t('buttons.mapFields', i18nOpts)}
          </DropdownItem>
        </ActionsMenu>
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
}), {})(ListItem);
