import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';

import CollapseButton from '../CollapseButton';
import styles from './CollapseFilterGroup.module.scss';

const CollapsibleFilterGroup = ({
  title, filtersCount, children
}) => {
  const [opened, setOpened] = useState(filtersCount > 0);

  const onToggle = () => {
    setOpened((prevOpened) => !prevOpened);
  };

  return (
    <div>
      <div className={classNames('d-flex align-items-center gap-2', styles.headerContainer)} onClick={onToggle} role="button" aria-hidden>
        <div className={classNames('flex-grow-1', styles.title)}>
          {title}
        </div>
        <div>
          <CollapseButton opened={opened} size="20" orientation="rightToBottom" />
        </div>
      </div>

      <Collapse isOpen={opened}>
        <div className={styles.bodyContainer}>
          {children}
        </div>
      </Collapse>
    </div>
  );
};

CollapsibleFilterGroup.propTypes = {
  title: PropTypes.string.isRequired,
  filtersCount: PropTypes.number
};

CollapsibleFilterGroup.defaultProps = {
  filtersCount: 0
};

export default CollapsibleFilterGroup;
