import React, { useEffect } from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';
import useFilters from '../../../../../../hooks/useFilters';
import ModalCloseButton from '../../../../../common/ModalCloseButton';
import { setQuickPossessionFilters } from '../../../../../../store/actions/quickPossessionActions';
import CommunityFilter from './CommunityFilter';
import ModelFilter from './ModelFilter';
import ProductTypeFilter from './ProductTypeFilter';
import BedsFilter from './BedsFilter';
import BathsFilter from './BathsFilter';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.components.quickPossessionFiltersModal.index' };

function closeModal() {
  ModalService.close(ModalNames.QUICK_POSSESSION_FILTERS);
}

const QuickPossessionFiltersModal = ({ opened, selectedFilters, ...props }) => {
  const {
    filters, changeFilters, setFilters, setInitialFilters, clearFilters, cancelFilters
  } = useFilters({});

  const onReset = () => {
    props.setQuickPossessionFilters({});
    clearFilters();
    closeModal();
  };

  const onCancel = () => {
    cancelFilters();
    closeModal();
  };

  const onApply = () => {
    props.setQuickPossessionFilters(filters);
    closeModal();
  };

  useEffect(() => {
    if (!opened) return;

    setFilters(selectedFilters);
    setInitialFilters(selectedFilters);
  }, [opened]);

  return (
    <Modal isOpen={opened} scrollable modalClassName="right">
      <ModalHeader close={<ModalCloseButton onClick={closeModal} />}>
        {i18n.t('title', i18nOpts)}
      </ModalHeader>
      <ModalBody className="pt-0">
        <div className="mb-3">{i18n.t('description', i18nOpts)}</div>

        <CommunityFilter filters={filters} onChange={changeFilters} />
        <ModelFilter filters={filters} onChange={changeFilters} />
        <ProductTypeFilter filters={filters} onChange={changeFilters} />
        <BedsFilter filters={filters} onChange={changeFilters} />
        <BathsFilter filters={filters} onChange={changeFilters} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline className="mr-auto" onClick={onReset}>
          {i18n.t('buttons.reset')}
        </Button>

        <Button color="secondary" outline className="mr-3" onClick={onCancel}>
          {i18n.t('buttons.cancel')}
        </Button>
        <Button color="primary" onClick={onApply}>
          {i18n.t('buttons.apply')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

QuickPossessionFiltersModal.propTypes = {};

QuickPossessionFiltersModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.QUICK_POSSESSION_FILTERS]?.opened || false,
  selectedFilters: store.quickPossessions.filters
}), {
  setQuickPossessionFilters
})(QuickPossessionFiltersModal);
