import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getFormattedCustomerHomePricesRange } from '../../../../utils/customerUtils';

const CustomerPriceRange = ({ customer }) => {
  const formattedPricesRange = useMemo(() => getFormattedCustomerHomePricesRange(customer),
    [customer]);

  return (
    <div>
      <span className="text-nowrap">{formattedPricesRange}</span>
    </div>
  );
};

CustomerPriceRange.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomerPriceRange.defaultProps = {};

export default CustomerPriceRange;
