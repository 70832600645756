import React from 'react';
import {
  Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import i18n from 'i18n-js';

const i18nOpts = { scope: 'components.admin.integrations.socialMedia.index.google.index' };

const Google = ({ form, onTextChange }) => (
  <>
    <Row form>
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="googleClientId">{i18n.t('clientId', i18nOpts)}</Label>
          <Input
            type="text"
            name="googleClientId"
            id="googleClientId"
            value={form.googleClientId || ''}
            onChange={onTextChange}
          />
        </FormGroup>
      </Col>
    </Row>

    <Row form>
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="googleClientSecret">{i18n.t('clientSecret', i18nOpts)}</Label>
          <Input
            type="text"
            name="googleClientSecret"
            id="googleClientSecret"
            value={form.googleClientSecret || ''}
            onChange={onTextChange}
          />
        </FormGroup>
      </Col>
    </Row>
  </>
);

export default Google;
