import React from 'react';
import { connect } from 'react-redux';

import StripeStatus from './StripeStatus';
import ConfirmDepositCard from './ConfirmDepositCard';
import DepositProblemCard from './DepositProblemCard';
import SignDocumentCard from './SignDocumentCard';
import { DepositStatuses } from '../../../../../../constants';
import useReserveNowWorkflowSteps from '../../../../../../hooks/useReserveNowWorkflowSteps';
import { isDepositStepEnabled, isSignStepEnabled } from '../../../../../../utils/companyUtils';
import RefundDepositSection from './RefundDepositSection';

const DepositSection = ({ home }) => {
  const reserveNowSteps = useReserveNowWorkflowSteps();
  const { depositStepIndex } = isDepositStepEnabled(reserveNowSteps);
  const { enabledSignStep, signStepIndex } = isSignStepEnabled(reserveNowSteps);

  const isDepositAccepted = () => deposit?.status === DepositStatuses.ACCEPTED;

  const { deposit } = home;
  const showSignSection = isDepositAccepted()
    && enabledSignStep
    && signStepIndex > depositStepIndex;

  return (
    <div>
      <RefundDepositSection />

      <StripeStatus deposit={deposit} />

      <ConfirmDepositCard />

      <DepositProblemCard />

      {showSignSection && (
        <SignDocumentCard />
      )}
    </div>
  );
};

DepositSection.propTypes = {};

DepositSection.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}))(DepositSection);
