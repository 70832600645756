import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import { listCities, cleanCities } from '../../../../store/actions/cityActions';
import ListEmpty from '../../../common/ListEmpty';
import ListItem from './ListItem';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.cities.list.index' };

const List = ({
  cities, loading, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);

  const loadCities = () => {
    const variables = { filter: { companyId: currentCompany.id } };

    props.listCities(variables)
      .finally(() => setMounted(true));
  };

  useEffect(() => {
    props.cleanCities().then(() => loadCities());
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div>
          <Link to="/admin/cities/form" className="btn btn-primary">
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Link>
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={cities} />

          {cities.length > 0 && (
            <div>
              <Row>
                {
                  cities.map((c) => (
                    <Col key={`city-${c.id}`} xs="12" sm="6" md="6" lg="4" className="mb-4">
                      <ListItem city={c} />
                    </Col>
                  ))
                }
              </Row>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  cities: store.cities.cities,
  loading: store.cities.listCities.loading,
  currentCompany: store.companies.currentCompany
}), {
  listCities,
  cleanCities
})(List);
