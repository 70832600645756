import api from '../../../../../api';
import {
  createProjectBuildingImageQuery, deleteProjectBuildingImageQuery,
  listProjectBuildingImagesQuery, sortProjectBuildingImagesQuery, updateProjectBuildingImageQuery
} from '../../../../../graphql';

export function createProjectBuildingImageAsync(variables) {
  return api.graphql(createProjectBuildingImageQuery, variables);
}

export function fetchProjectBuildingImagesAsync(variables) {
  return api.graphql(listProjectBuildingImagesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items))
    .catch(() => Promise.reject());
}

export function sortProjectBuildingImagesAsync(variables) {
  return api.graphql(sortProjectBuildingImagesQuery, variables);
}

export function deleteProjectBuildingImageAsync(variables) {
  return api.graphql(deleteProjectBuildingImageQuery, variables);
}

export function updateProjectBuildingImageAsync(variables) {
  return api.graphql(updateProjectBuildingImageQuery, variables);
}
