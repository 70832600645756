import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import ListItem from './ListItem';

const GridView = ({ communities }) => (
  <Row>
    {
      communities.map((c) => (
        <Col key={`community-${c.id}`} xs="12" sm="6" md="4" lg="4" className="mb-4">
          <ListItem community={c} />
        </Col>
      ))
    }
  </Row>
);

GridView.propTypes = {};

GridView.defaultProps = {};

export default connect((store) => ({
  communities: store.communities.communities
}))(GridView);
