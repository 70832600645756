import React from 'react';
import i18n from 'i18n-js';

import { isAdmin } from '../../../../utils/authUtils';
import CommunitiesIcon from '../../../../assets/icons/sidebar-nav/CommunitiesIcon';
import ProductTypesIcon from '../../../../assets/icons/sidebar-nav/ProductTypesIcon';
import ModelsIcon from '../../../../assets/icons/sidebar-nav/ModelsIcon';
import PalettesIcon from '../../../../assets/icons/sidebar-nav/PalettesIcon';
import PackagesIcon from '../../../../assets/icons/sidebar-nav/PackagesIcon';
import SidebarMenuItem from '../components/SidebarMenuItem';
import UnitsIcon from '../../../../assets/icons/sidebar-nav/UnitsIcon';

const i18nOpts = { scope: 'layout.admin.sidebar.singleFamilySideBar' };

const SingleFamilySideBar = () => (
  <>
    <SidebarMenuItem to="/admin/communities" Icon={<CommunitiesIcon />} title={i18n.t('menus.communities', i18nOpts)} />

    {isAdmin() && (
      <SidebarMenuItem to="/admin/productTypes" Icon={<ProductTypesIcon />} title={i18n.t('menus.productTypes', i18nOpts)} />
    )}

    <SidebarMenuItem to="/admin/models" Icon={<ModelsIcon />} title={i18n.t('menus.models', i18nOpts)} />
    <SidebarMenuItem to="/admin/palettes" Icon={<PalettesIcon />} title={i18n.t('menus.palettes', i18nOpts)} />

    {isAdmin() && (
    <>
      <SidebarMenuItem to="/admin/packages" Icon={<PackagesIcon />} title={i18n.t('menus.packages', i18nOpts)} />
      <SidebarMenuItem to="/admin/quickPossessions" Icon={<UnitsIcon />} title={i18n.t('menus.inventory', i18nOpts)} />
    </>
    )}
  </>
);

SingleFamilySideBar.propTypes = {};

SingleFamilySideBar.defaultProps = {};

export default SingleFamilySideBar;
