import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import api from '../api';

function fetchBuildingModelsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListBuildingModels($filter: BuildingModelFilterInput) {
      items: listBuildingModels(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => items);
}

const useFetchBuildingModels = () => {
  const [loading, setLoading] = useState(false);
  const [buildingModels, setBuildingModels] = useState([]);

  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany,
  }));

  useEffect(() => {
    setLoading(true);

    fetchBuildingModelsAsync(currentCompany.id)
      .then((items) => setBuildingModels(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { buildingModels, loading };
};

export default useFetchBuildingModels;
