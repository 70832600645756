import React from 'react';
import PropTypes from 'prop-types';

const SuiteNumber = ({ unit }) => {
  const { name } = unit;

  return (
    <div className="font-weight-500 col-gray-900">
      {name}
    </div>
  );
};

SuiteNumber.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

SuiteNumber.defaultProps = {};

export default SuiteNumber;
