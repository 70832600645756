import React from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';

const i18nOpts = { scope: 'components.admin.settings.index' };

const Settings = () => (
  <div>
    <Helmet title={i18n.t('title', i18nOpts)} />

    <div className="d-flex align-items-center mb-4">
      <div className="flex-grow-1">
        <h2>{i18n.t('title', i18nOpts)}</h2>
      </div>
    </div>
    <div>
      TODO
    </div>
  </div>
);

export default Settings;
