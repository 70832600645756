import React from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from 'i18n-js';

import { isInputInvalid } from '../../../../../../ui/InputError';
import { InputError } from '../../../../../../ui';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.companyAndBranding.domainField.index' };

const DomainField = ({
  index, value, onChange, error, isLast
}) => (
  <div className={classNames({ 'mb-3': !isLast })}>
    <Input
      type="text"
      name={`domain-${index}`}
      id={`domain-${index}`}
      value={value || ''}
      onChange={onChange}
      invalid={isInputInvalid(error, 'domains')}
      placeholder={i18n.t('placeholders.domain', i18nOpts)}
    />
    {isLast && (
      <>
        <span className="d-block mt-2" />
        <InputError name="domains" error={error} />
        <span className="d-block mb-2" />
      </>
    )}
  </div>
);

DomainField.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.objectOf(PropTypes.any),
  isLast: PropTypes.bool
};

DomainField.defaultProps = {
  onChange: () => {},
  error: null,
  isLast: false
};

export default DomainField;
