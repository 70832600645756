import { useSelector } from 'react-redux';

const useIsRefundsEnabled = () => {
  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany
  }));
  return !!currentCompany.paymentSettings?.stripe?.enableRefunds;
};

export default useIsRefundsEnabled;
