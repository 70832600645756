import React, { useEffect, useState } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import v from 'voca';

import { hasFormValues } from '../../../../utils/formUtils';
import { saveFloorOption } from '../../../../store/actions/floorOptionActions';
import {
  FloorableTypes,
  FloorOptionAvailabilityRules,
  FloorOptionAvailabilityRuleTypes,
  ModalNames
} from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import { parseBoolean, parseToFloat } from '../../../../utils/parserUtils';
import { stripToEmpty } from '../../../../utils/stringUtils';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { setReloadFloors } from '../../../../store/actions/floorActions';
import ImageViewer from '../../../common/ImageViewer';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import AvailabilityRuleSettings from './AvailabilityRuleSettings';

const i18nOpts = { scope: 'components.global.floorsManager.floorOptionFormModal.index' };

function onClose() {
  ModalService.close(ModalNames.FLOOR_OPTION_FORM);
}

const FloorGroupFormModal = ({ opened, params, ...props }) => {
  const [form, setForm] = useState({});
  const [image, setImage] = useState(undefined);
  const [alternateImage, setAlternateImage] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!opened) return;

    init();
  }, [opened]);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const onAlternateImageChange = (event) => {
    const file = event.target.files[0];
    setAlternateImage(file);
  };

  const onSave = () => {
    const input = getFormInput();

    setSaving(true);

    const variables = { input, image, alternateImage };
    props.saveFloorOption(variables)
      .then(() => {
        props.setReloadFloors(true);
        ModalService.close(ModalNames.FLOOR_OPTION_FORM);
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const getFormInput = () => {
    const { floorGroup, floorableType } = params;
    const frontage = { min: parseFloat(form.frontageMin), max: parseFloat(form.frontageMax) };

    const input = {
      name: stripToEmpty(form.name),
      description: stripToEmpty(form.description),
      externalId: stripToEmpty(form.externalId),
      alternateOption: form.alternateOption || '',
      isDefault: parseBoolean(form.isDefault),
      floorGroupId: floorGroup.id,
      lotWorksSettings: { frontage }
    };

    if (form.id) input.id = form.id;

    const priceChange = parseToFloat(form.priceChange);
    if (!Number.isNaN(priceChange) || priceChange === null) input.priceChange = priceChange;

    const sizeChange = parseToFloat(form.sizeChange);
    if (!Number.isNaN(sizeChange) || sizeChange === null) input.sizeChange = sizeChange;

    const bedsChange = parseToFloat(form.bedsChange);
    if (!Number.isNaN(bedsChange) || bedsChange === null) input.bedsChange = bedsChange;

    const bathsChange = parseToFloat(form.bathsChange);
    if (!Number.isNaN(bathsChange) || bathsChange === null) input.bathsChange = bathsChange;

    if (floorableType === FloorableTypes.MODEL) {
      const selectedCommunities = form.availabilityCommunities || [];
      const selectedCommunityPhases = form.availabilityCommunityPhases || [];
      if (selectedCommunities.length || selectedCommunityPhases.length) {
        input.availabilityRule = form.availabilityRule || FloorOptionAvailabilityRules.AVAILABLE;
        const availabilityRuleType = form.availabilityRuleType
          || FloorOptionAvailabilityRuleTypes.COMMUNITIES;
        if (availabilityRuleType === FloorOptionAvailabilityRuleTypes.COMMUNITY_PHASES) {
          input.availabilityCommunityPhases = selectedCommunityPhases;
          input.availabilityCommunities = null;
        } else {
          input.availabilityCommunities = selectedCommunities;
          input.availabilityCommunityPhases = null;
        }
      } else {
        input.availabilityRule = null;
        input.availabilityCommunities = null;
        input.availabilityCommunityPhases = null;
      }
    }

    return input;
  };

  const init = () => {
    const { floorOption } = params;
    let formData = {};

    if (floorOption) {
      formData = { ...floorOption };
      formData.frontageMin = floorOption.lotWorksSettings?.frontage?.min;
      formData.frontageMax = floorOption.lotWorksSettings?.frontage?.max;
      if (floorOption.availabilityCommunityPhases) {
        formData.availabilityRuleType = FloorOptionAvailabilityRuleTypes.COMMUNITY_PHASES;
      }
    }

    setForm(formData);
    setImage(undefined);
    setAlternateImage(undefined);
    setError(null);
    setSaving(false);
  };

  const isNew = () => !form.id;

  const getPreviewImageUrl = () => {
    const { imageUrl } = form;
    return image === null ? null : (getLocalImageUrl(image) || imageUrl);
  };

  const getPreviewAlternateImageUrl = () => {
    const { imageUrl } = form;
    return image === null ? null : (getLocalImageUrl(image) || imageUrl);
  };

  const onDeleteImage = () => setImage(null);

  const onDeleteAlternateImage = () => setAlternateImage(null);

  const { floorableType } = params;
  const hasValues = hasFormValues(form);
  const title = isNew() ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);
  const imageUrl = getPreviewImageUrl();
  const alternateImageUrl = getPreviewAlternateImageUrl();

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label for="floorOptionName">{i18n.t('name', i18nOpts)}</Label>
          <Input
            type="text"
            name="name"
            id="floorOptionName"
            value={form.name || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'name')}
          />
          <InputError name="name" error={error} />
        </FormGroup>

        <FormGroup>
          <Label for="image">{i18n.t('image', i18nOpts)}</Label>
          {imageUrl && (
          <div className="preview-image-container">
            <ImageViewer url={imageUrl} size="h-100" onDelete={onDeleteImage} />
          </div>
          )}
          <Input
            type="file"
            name="image"
            id="image"
            accept="image/*"
            onChange={onImageChange}
            invalid={isInputInvalid(error, 'image')}
          />
          <InputError name="image" error={error} />
        </FormGroup>

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="priceChange">{i18n.t('priceChange', i18nOpts)}</Label>
              <Input
                type="number"
                name="priceChange"
                id="priceChange"
                value={form.priceChange || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'priceChange')}
              />
              <InputError name="priceChange" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="sizeChange">{i18n.t('sizeChange', i18nOpts)}</Label>
              <Input
                type="number"
                name="sizeChange"
                id="sizeChange"
                value={form.sizeChange || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'sizeChange')}
              />
              <InputError name="sizeChange" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="bedsChange">{i18n.t('bedsChange', i18nOpts)}</Label>
              <Input
                type="number"
                name="bedsChange"
                id="bedsChange"
                value={form.bedsChange || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'bedsChange')}
              />
              <InputError name="bedsChange" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="bathsChange">{i18n.t('bathsChange', i18nOpts)}</Label>
              <Input
                type="number"
                name="bathsChange"
                id="bathsChange"
                value={form.bathsChange || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'bathsChange')}
              />
              <InputError name="bathsChange" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label for="description">{i18n.t('description', i18nOpts)}</Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            value={form.description || ''}
            onChange={onTextChange}
            rows={5}
            invalid={isInputInvalid(error, 'description')}
          />
          <InputError name="description" error={error} />
        </FormGroup>

        <FormGroup>
          <Label for="alternateImage">{i18n.t('alternateImage', i18nOpts)}</Label>
          {alternateImageUrl && (
          <div className="preview-image-container">
            <ImageViewer url={alternateImageUrl} size="h-100" onDelete={onDeleteAlternateImage} />
          </div>
          )}
          <Input
            type="file"
            name="alternateImage"
            id="alternateImage"
            accept="image/*"
            onChange={onAlternateImageChange}
            invalid={isInputInvalid(error, 'alternateImage')}
          />
          <InputError name="alternateImage" error={error} />
        </FormGroup>

        <FormGroup>
          <Label for="alternateOption">{i18n.t('alternateOption', i18nOpts)}</Label>
          <Input
            type="text"
            name="alternateOption"
            id="alternateOption"
            value={form.alternateOption || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'alternateOption')}
          />
          <InputError name="alternateOption" error={error} />
        </FormGroup>

        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              name="isDefault"
              id="isDefault"
              value={!parseBoolean(form.isDefault)}
              checked={parseBoolean(form.isDefault)}
              onChange={onTextChange}
            />
            {i18n.t('isDefault', i18nOpts)}
          </Label>
        </FormGroup>

        <FormGroup className="mt-3">
          <Label for="alternateOption">{i18n.t('frontage', i18nOpts)}</Label>
          <Row form>
            <Col lg="6" md="6" sm="8" xs="12">
              <Input
                type="number"
                name="frontageMin"
                id="frontageMin"
                placeholder={i18n.t('min', i18nOpts)}
                value={form.frontageMin || ''}
                onChange={onTextChange}
              />
            </Col>
            <Col lg="6" md="6" sm="12" xs="12">
              <Input
                type="number"
                name="frontageMax"
                id="frontageMax"
                placeholder={i18n.t('max', i18nOpts)}
                value={form.frontageMax || ''}
                onChange={onTextChange}
              />
            </Col>
          </Row>
        </FormGroup>

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="externalId">{i18n.t('externalId', i18nOpts)}</Label>
              <Input
                type="text"
                name="externalId"
                id="externalId"
                value={form.externalId || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'externalId')}
              />
              <InputError name="externalId" error={error} />
            </FormGroup>
          </Col>
        </Row>

        {floorableType === FloorableTypes.MODEL && (
          <AvailabilityRuleSettings form={form} error={error} onTextChange={onTextChange} />
        )}

      </ModalBody>

      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSave} disabled={saving || !hasValues}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.FLOOR_OPTION_FORM]?.opened || false,
  params: store.modals[ModalNames.FLOOR_OPTION_FORM]?.params || {}
}), {
  saveFloorOption,
  setReloadFloors
})(FloorGroupFormModal);
