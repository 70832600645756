import {
  GET_DOCUMENTS,
  SAVE_DOCUMENT,
  DELETE_DOCUMENT,
  ADD_DOCUMENT_FILE,
  REMOVE_DOCUMENT_FILE,
  CLEAN_DOCUMENT_FILES
} from './types';
import {
  createDocumentQuery, deleteDocumentQuery, listDocumentsQuery,
  updateDocumentQuery
} from '../../graphql';
import api from '../../api';

export function getDocuments(variables = {}) {
  return {
    type: GET_DOCUMENTS,
    payload: api.graphql(listDocumentsQuery, variables)
  };
}

export function saveDocument(variables = {}) {
  const query = variables.input.id ? updateDocumentQuery : createDocumentQuery;
  return {
    type: SAVE_DOCUMENT,
    payload: api.graphql(query, variables)
  };
}

export function deleteDocument(id) {
  return {
    type: DELETE_DOCUMENT,
    payload: api.graphql(deleteDocumentQuery, { id })
  };
}

export function addDocumentFile(documentFile) {
  return {
    type: ADD_DOCUMENT_FILE,
    payload: { documentFile }
  };
}

export function removeDocumentFile(documentFile) {
  return {
    type: REMOVE_DOCUMENT_FILE,
    payload: { documentFile }
  };
}

export function cleanDocumentFiles() {
  return {
    type: CLEAN_DOCUMENT_FILES,
    payload: { }
  };
}
