import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import FilterCheckbox from '../../../../../../../common/FilterCheckbox';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.components.quickPossessionFiltersModal.bedsFilter.lisItem.index' };

const ListItem = ({
  value, selectedValues, onChange
}) => (
  <FilterCheckbox
    label={i18n.t('beds', { ...i18nOpts, count: value })}
    value={value}
    checked={selectedValues.includes(value)}
    onChange={onChange}
  />
);

ListItem.propTypes = {
  value: PropTypes.number.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

ListItem.defaultProps = {
  selectedValues: [],
  onChange: () => {}
};

export default ListItem;
