import { useSelector } from 'react-redux';

const useIsMultiFamilyHomesEnabled = () => {
  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany
  }));

  if (!currentCompany) return false;

  return currentCompany.enableHomeRental
    ? currentCompany.enableMultiFamilyHomesRental
    : currentCompany.enableMultiFamilyHomes;
};

export default useIsMultiFamilyHomesEnabled;
