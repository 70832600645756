import React, { Component } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { getCompanies, cleanCompanies, restoreCompany } from '../../../../store/actions/companyActions';
import ListEmpty from '../../../common/ListEmpty';
import Breadcrumbs from '../../../common/Breadcrumbs';
import CompaniesTable from '../Table';
import ListPagination from '../../../common/ListPagination';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.companies.archived.index' };

class Companies extends Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };

    this.onChangePage = this.onChangePage.bind(this);
    this.onRestore = this.onRestore.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    props.cleanCompanies()
      .then(() => this.loadArchivedCompanies());
  }

  onChangePage(page) {
    this.loadArchivedCompanies(page);
  }

  onRestore(companyId) {
    const { props } = this;
    const { currentUser, currentCompany } = this.props;

    if (companyId === currentUser.companyId || companyId === currentCompany.id) {
      toast.error(i18n.t('errors.restoreCurrentCompany', i18nOpts));
      return;
    }

    props.restoreCompany(companyId)
      .then(() => {
        navigate('/admin/companies');
      })
      .catch(() => {
      });
  }

  loadArchivedCompanies(page = 1) {
    const { props } = this;
    const filter = { archived: true };
    const variables = {
      page,
      filter
    };
    props.getCompanies(variables)
      .finally(() => this.setState({ mounted: true }));
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return <Loading loading fullScreen />;

    const { companies, pagination, loading } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <Breadcrumbs items={[
              { url: '/admin/companies', text: i18n.t('companies', i18nOpts) },
              { url: '/admin/companies/archived', text: i18n.t('title', i18nOpts) },
            ]}
            />
          </div>
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={companies} />

            {companies.length > 0 && (
              <CompaniesTable
                onDelete={this.onRestore}
                companies={companies}
                deleteMessage={i18n.t('restoreMessage', i18nOpts)}
                deleteText={i18n.t('buttons.restore')}
                isArchived
              />
            )}

            <div className="text-right mt-4">
              <ListPagination pagination={pagination} onPress={this.onChangePage} />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  companies: store.companies.companies,
  loading: store.companies.getCompanies.loading,
  pagination: store.companies.pagination,
  currentUser: store.users.currentUser,
  currentCompany: store.companies.currentCompany
}), {
  getCompanies,
  cleanCompanies,
  restoreCompany
})(Companies);
