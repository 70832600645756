import React from 'react';

const Beds = ({ children }) => (
  <div>
    <strong className="text-dark">{children || 0}</strong>
    <i className="fas fa-bed ml-2 text-dark" />
  </div>
);

export default Beds;
