import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import { getPackages, cleanPackages } from '../../../../store/actions/packageActions';
import ListEmpty from '../../../common/ListEmpty';
import ListItem from './ListItem';
import { buildPackagesPath, isMultiFamilyPage } from '../../../../utils/multiFamilyUtils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.packages.list.index' };

const Packages = ({
  packages, loading, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    props.cleanPackages()
      .then(() => loadPackages());
  }, []);

  const loadPackages = () => {
    const variables = {
      filter: {
        companyId: currentCompany.id,
        isMultifamily: isMultiFamilyPage()
      }
    };
    props.getPackages(variables)
      .finally(() => setMounted(true));
  };

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div>
          <Link to={buildPackagesPath('/form')} className="btn btn-primary">
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Link>
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={packages} />

          <Row>
            {
              packages.map((p) => (
                <Col key={`package-${p.id}`} xs="12" sm="6" md="6" lg="4" className="mb-4">
                  <ListItem package={p} />
                </Col>
              ))
            }
          </Row>
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  packages: store.packages.packages,
  loading: store.packages.getPackages.loading,
  currentCompany: store.companies.currentCompany
}), {
  getPackages,
  cleanPackages
})(Packages);
