import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { TabContent, TabPane } from 'reactstrap';
import i18n from 'i18n-js';

import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';
import NavTabs from './NavTabs';
import { Tabs } from './utils';
import GeneralSettings from './GeneralSettings';
import MailingList from './MailingList';

const i18nOpts = { scope: 'components.admin.integrations.sendGrid.index' };

const SendGrid = () => {
  const [activeTab, setActiveTab] = useState(Tabs.GENERAL_SETTINGS);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

      <NavTabs activeTab={activeTab} onChange={onTabChange} />

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.GENERAL_SETTINGS}>
          <GeneralSettings />
        </TabPane>
        <TabPane tabId={Tabs.MAILING_LIST}>
          <MailingList />
        </TabPane>
      </TabContent>
    </div>
  );
};

SendGrid.propTypes = {};

SendGrid.defaultProps = {};

export default SendGrid;
