import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  FormGroup, Input
} from 'reactstrap';
import { FiCalendar } from 'react-icons/fi';
import v from 'voca';
import moment from 'moment';

import CollapsibleFilterGroup from '../../../../../common/CollapsibleFilterGroup';
import { Colors, DateFormats, ModalNames } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import ResetButton from '../components/ResetButton';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.lastActiveFilter.index' };

const LastActiveFilter = ({ filters, onChange }) => {
  const { lastActiveDate } = filters;

  const onClick = () => {
    ModalService.open(ModalNames.CUSTOMER_FILTER_DATE_PICKER,
      { name: 'lastActiveDate', value: lastActiveDate });
  };

  const onReset = () => {
    onChange({ lastActiveDate: undefined });
  };

  const formatDate = (date) => {
    if (v.isBlank(date)) return '';

    return moment(date).format(DateFormats.MMM_DD_YYYY);
  };

  const filtersCount = useMemo(() => (lastActiveDate ? 1 : 0), [filters]);

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <div className="d-flex align-items-center gap-3">
        <div>
          <FormGroup className="mb-0">
            <div className="input-icon-prepend">
              <FiCalendar size={20} color={Colors.GRAY_500} />
            </div>
            <Input
              type="text"
              name="activityDateFrom"
              id="activityDateFrom"
              value={formatDate(lastActiveDate?.from)}
              onClick={onClick}
              onFocus={(e) => e.target.blur()}
              onChange={() => {}}
              className="cursor-default"
            />
          </FormGroup>
        </div>
        <div>
          -
        </div>
        <div>
          <FormGroup className="mb-0">
            <div className="input-icon-prepend">
              <FiCalendar size={20} color={Colors.GRAY_500} />
            </div>
            <Input
              type="text"
              name="activityDateTo"
              id="activityDateTo"
              value={formatDate(lastActiveDate?.to)}
              onClick={onClick}
              onFocus={(e) => e.target.blur()}
              onChange={() => {}}
              className="cursor-default"
            />
          </FormGroup>
        </div>
      </div>

      {lastActiveDate && (
        <div className="mt-2">
          <ResetButton onClick={onReset} />
        </div>
      )}
    </CollapsibleFilterGroup>
  );
};

LastActiveFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

LastActiveFilter.defaultProps = {
  onChange: () => {}
};

export default LastActiveFilter;
