import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';

import {
  cleanMortgageProviders,
  listMortgageProviders,
  setReloadMortgageProviders
} from '../../../../store/actions/mortgageProviderActions';
import ModalService from '../../../../services/ModalService';
import { ModalNames } from '../../../../constants';
import { isAdmin, isMasterAdmin, isTecSupport } from '../../../../utils/authUtils';
import Loading from '../../../ui/Loading';
import ListEmpty from '../../../common/ListEmpty';
import GridView from './GridView';
import ListPagination from '../../../common/ListPagination';
import FormModal from '../components/FormModal';

const i18nOpts = { scope: 'components.admin.mortgageProviders.list.index' };

const MortgageProviders = ({
  mortgageProviders, pagination, loading, currentCompany, reloadMortgageProviders, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [filters, setFilters] = useState({ companyId: currentCompany.id });

  const onChangePage = (page) => {
    loadMortgageProviders(page);
  };

  const onAdd = () => {
    ModalService.open(ModalNames.MORTGAGE_PROVIDER_FORM);
  };

  const loadMortgageProviders = (page = 1) => {
    const companyId = isMasterAdmin() || isTecSupport() ? filters.companyId : currentCompany.id;

    const variables = {
      filter: {
        companyId
      },
      page
    };
    props.listMortgageProviders(variables)
      .finally(() => {
        setMounted(true);
        props.setReloadMortgageProviders(false);
      });
  };

  useEffect(() => {
    props.cleanMortgageProviders()
      .then(() => loadMortgageProviders());
  }, [filters]);

  useEffect(() => {
    if (!reloadMortgageProviders) return;

    loadMortgageProviders();
  }, [reloadMortgageProviders]);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <div className="d-flex align-items-center justify-content-end mb-4">
        {isAdmin() && (
          <div>
            <Button color="primary" onClick={onAdd}>
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Button>
          </div>
        )}
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={mortgageProviders} />

          {mortgageProviders.length > 0 && (
            <div className="mb-4">
              <GridView />
            </div>
          )}

          <div className="text-right mt-4">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}

      <FormModal />
    </div>
  );
};

export default connect((store) => ({
  mortgageProviders: store.mortgageProviders.mortgageProviders,
  loading: store.mortgageProviders.listMortgageProviders.loading,
  pagination: store.mortgageProviders.pagination,
  currentCompany: store.companies.currentCompany,
  reloadMortgageProviders: store.mortgageProviders.reloadMortgageProviders
}), {
  listMortgageProviders,
  cleanMortgageProviders,
  setReloadMortgageProviders
})(MortgageProviders);
