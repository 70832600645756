import React from 'react';
import PropTypes from 'prop-types';

import { getQPTotalBaths } from '../../../../../../../../../utils/quickPossessionUtils';

const Baths = ({ quickPossession }) => (
  <div>
    {getQPTotalBaths(quickPossession)}
  </div>
);

Baths.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Baths.defaultProps = {};

export default Baths;
