import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import useValidSelectedColumns from '../../../hooks/useValidSelectedColumns';
import CustomColumnValue from './CustomColumnValue';
import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../../../../utils/authUtils';
import ActionsMenu from '../../ActionsMenu';

const ListItem = ({ quickPossession }) => {
  const columns = useValidSelectedColumns();
  const isAdmin = isMasterAdmin() || isCompanyAdmin() || isSalesLead();

  const onClick = (event) => {
    const quickPossessionPath = `/admin/quickPossessions/${quickPossession.id}`;

    if (event.metaKey || event.ctrlKey) window.open(quickPossessionPath, '_blank');
    else navigate(quickPossessionPath);
  };

  const onActionsTdClick = (event) => {
    event.stopPropagation();
  };

  return (
    <tr role="button" aria-hidden onClick={onClick} className="clickable-row">
      {
        columns.map((column) => (
          <td key={`quick-possession-cell-${quickPossession.id}-${column}`} className="align-middle">
            <CustomColumnValue column={column} quickPossession={quickPossession} />
          </td>
        ))
      }

      {isAdmin && (
        <td className="text-right p-0">
          <div role="button" aria-hidden onClick={onActionsTdClick} className="p-3">
            <ActionsMenu quickPossession={quickPossession} />
          </div>
        </td>
      )}
    </tr>
  );
};

ListItem.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
