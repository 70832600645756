export const CustomerReservationStatuses = Object.freeze({
  SMS_EMAIL_VERIFICATION: 'SMS_EMAIL_VERIFICATION',
  INCOME_VERIFICATION: 'INCOME_VERIFICATION',
  SIGN: 'SIGN',
  DEPOSIT: 'DEPOSIT',
});

export const CustomerVerificationStatuses = Object.freeze({
  SMS_EMAIL_VERIFICATION: 'SMS_EMAIL_VERIFICATION',
  ID_VERIFICATION: 'ID_VERIFICATION',
  INCOME_VERIFICATION: 'INCOME_VERIFICATION',
  HOME_VALUATION_VERIFICATION: 'HOME_VALUATION_VERIFICATION',
});

export const CustomerStatuses = Object.freeze({
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  ID_VERIFIED: 'ID_VERIFIED',
  FINANCES_VERIFIED: 'FINANCES_VERIFIED',
  CUSTOMER_VERIFIED: 'CUSTOMER_VERIFIED'
});
