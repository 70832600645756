import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Spinner, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';

import IntegrationsBreadcrumbs from '../../../../../global/IntegrationsBreadcrumbs';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';
import { sendGridCleanContactLists, sendGridListContactLists } from '../../../../../../store/actions/sendGridActions';
import ListEmpty from '../../../../../common/ListEmpty';
import ListItem from './ListItem';
import FormModal from '../FormModal';

const i18nOpts = { scope: 'components.admin.integrations.sendGrid.contactLists.list.index' };

const ContactLists = ({
  contactLists, loading, currentCompany, ...props
}) => {
  const loadContactLists = () => {
    const variables = { companyId: currentCompany.id };

    props.sendGridCleanContactLists()
      .then(() => props.sendGridListContactLists(variables))
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const onAdd = () => {
    ModalService.open(ModalNames.SENDGRID_CONTACT_LIST_FORM);
  };

  useEffect(() => {
    loadContactLists();
  }, []);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[
        { text: i18n.t('sendGrid', i18nOpts), url: '/admin/integrations/sendGrid' },
        { text: i18n.t('title', i18nOpts) }
      ]}
      />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div>
          <Button color="primary" onClick={onAdd}>
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Button>
        </div>
      </div>

      {loading && (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      )}

      <ListEmpty loading={loading} items={contactLists} />

      {contactLists.length > 0 && (
        <Table responsive bordered>
          <thead>
            <tr>
              <th>{i18n.t('name', i18nOpts)}</th>
              <th>{i18n.t('count', i18nOpts)}</th>
              <th style={{ width: 50 }}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              contactLists.map((contactList) => (
                <ListItem key={`contact-list-${contactList.id}`} contactList={contactList} />
              ))
            }
          </tbody>
        </Table>
      )}

      <FormModal />
    </div>
  );
};

ContactLists.propTypes = {};

ContactLists.defaultProps = {};

export default connect((store) => ({
  contactLists: store.sendGrid.contactLists,
  loading: store.sendGrid.listContactLists.loading || false,
  currentCompany: store.companies.currentCompany
}), {
  sendGridCleanContactLists,
  sendGridListContactLists
})(ContactLists);
