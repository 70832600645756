import React from 'react';
import PropTypes from 'prop-types';

import { getQPTotalBeds } from '../../../../../../../../../utils/quickPossessionUtils';

const Beds = ({ quickPossession }) => (
  <div>
    {getQPTotalBeds(quickPossession)}
  </div>
);

Beds.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Beds.defaultProps = {};

export default Beds;
