import api from '../../../api';
import {
  createMortgageProviderAssignmentQuery,
  deleteMortgageProviderAssignmentQuery,
  listMortgageProviderAssignmentsQuery
} from '../../../graphql/mortgageProviderAssignments';

export function fetchMortgageProvidersAssignmentsAsync(assignableId, assignableType) {
  const variables = { filter: { assignableId, assignableType } };

  return api.graphql(listMortgageProviderAssignmentsQuery, variables)
    .then(({ data: { items } }) => items);
}

export function createMortgageProviderAssignmentAsync(variables = {}) {
  return api.graphql(createMortgageProviderAssignmentQuery, variables)
    .then(({ data: { item } }) => item);
}

export function deleteMortgageProviderAssignmentAsync(id) {
  return api.graphql(deleteMortgageProviderAssignmentQuery, { id })
    .then(({ data: { item } }) => item);
}
