import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Loading from '../../../../ui/Loading';
import ListEmpty from '../../../../common/ListEmpty';
import { ViewModes } from '../../../../../constants';
import GridView from '../components/GridView';
import ListView from '../components/ListView';
import ListPagination from '../../../../common/ListPagination';
import { fetchQuickPossessionsAsync, getQuickPossessionFilters } from '../utils';
import { setReloadQuickPossessions } from '../../../../../store/actions/quickPossessionActions';

const ActiveListings = ({
  currentCompany, viewMode, columns, filters, query, reloadQuickPossessions, initialColumnsSet,
  ...props
}) => {
  const [quickPossessions, setQuickPossessions] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadQuickPossessions = (page = 1) => {
    setLoading(true);

    const filter = getQuickPossessionFilters(currentCompany, filters, query, false);

    const variables = { filter, page };
    fetchQuickPossessionsAsync(variables)
      .then(({ items, pagination: currentPagination }) => {
        setQuickPossessions(items);
        setPagination(currentPagination);
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const onChangePage = (page) => {
    loadQuickPossessions(page);
  };

  useEffect(() => {
    if (initialColumnsSet) loadQuickPossessions();
  }, [filters, columns, query, initialColumnsSet]);

  useEffect(() => {
    if (!reloadQuickPossessions) return;

    loadQuickPossessions();
    props.setReloadQuickPossessions(false);
  }, [reloadQuickPossessions]);

  if (loading) return <Loading loading />;

  return (
    <>
      <ListEmpty loading={loading} items={quickPossessions} />

      {quickPossessions.length > 0 && (
        <div className="mb-4">
          {viewMode === ViewModes.GRID ? (
            <GridView quickPossessions={quickPossessions} />
          ) : (
            <ListView quickPossessions={quickPossessions} />
          )}
        </div>
      )}

      <div className="text-right mt-4">
        <ListPagination pagination={pagination} onPress={onChangePage} />
      </div>
    </>
  );
};

ActiveListings.propTypes = {
  initialColumnsSet: PropTypes.bool,
  query: PropTypes.string
};

ActiveListings.defaultProps = {
  initialColumnsSet: false,
  query: ''
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  viewMode: store.quickPossessions.viewMode,
  columns: store.quickPossessions.columns,
  filters: store.quickPossessions.filters,
  reloadQuickPossessions: store.quickPossessions.reloadQuickPossessions
}), {
  setReloadQuickPossessions
})(ActiveListings);
