import React from 'react';

const InvitationSentImage = ({ ...props }) => (
  <svg width="260" height="184" viewBox="0 0 260 184" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1_17545)">
      <path d="M256.228 160.34C256.201 163.266 256.074 166.213 255.799 169.153H27.8971C28.1719 167.8 28.4265 166.446 28.6543 165.12C33.9951 134.38 11.1311 108.806 5.7167 79.893C2.69453 63.7797 5.26773 46.7397 11.6203 31.7263C17.5574 17.693 27.3275 2.91967 43.6378 0.373006C57.0801 -1.72699 70.4554 4.00634 82.3698 10.553C95.2291 17.613 108.041 26.0997 123.072 27.1997C153.166 29.3997 181.156 14.4663 211.13 15.0263C227.367 15.333 240.976 21.9663 244.166 39.033C246.967 53.9997 240.467 68.973 240.286 83.8997C240.186 92.1197 241.948 99.9597 244.334 107.68C248.462 121.06 254.419 134.06 255.699 148.066C256.061 152.073 256.248 156.193 256.228 160.34Z" fill="#DDF7F0" />
      <path d="M130.121 183.713C202.022 183.713 260.309 179.09 260.309 173.387C260.309 167.683 202.022 163.06 130.121 163.06C58.2206 163.06 -0.0664062 167.683 -0.0664062 173.387C-0.0664062 179.09 58.2206 183.713 130.121 183.713Z" fill="#DDF7F0" />
      <path d="M247.918 165.473H18.3074L36.9362 158.653H229.289L247.918 165.473Z" fill="#ACEAD8" />
      <path d="M231.52 42.2134V156.973C231.52 158.48 230.153 159.7 228.451 159.7H37.7598C36.0711 159.7 34.7041 158.48 34.7041 156.973V42.2134C34.7041 40.7067 36.0711 39.4867 37.7598 39.4867H228.451C230.153 39.4934 231.52 40.7067 231.52 42.2134Z" fill="#262A41" />
      <path d="M225.644 153.187C225.644 153.187 225.644 153.193 225.637 153.193H40.5885C40.5885 153.193 40.5818 153.193 40.5818 153.187V46H225.644V153.187Z" fill="#FCFCFD" />
      <path d="M240.031 175.347H26.1941C21.3626 175.347 17.4492 171.453 17.4492 166.647C17.4492 165.793 18.1461 165.1 19.0039 165.1H247.228C248.085 165.1 248.782 165.793 248.782 166.647C248.775 171.453 244.862 175.347 240.031 175.347Z" fill="#262A41" />
      <path d="M193.391 65.3731V133.226C193.391 133.273 193.391 133.326 193.378 133.373C193.344 134.546 192.842 135.606 192.024 136.36C191.253 137.086 190.201 137.533 189.056 137.533H77.1617C76.0159 137.533 74.9638 137.086 74.1932 136.36C73.3824 135.606 72.8664 134.54 72.8396 133.36C72.8262 133.313 72.8262 133.266 72.8262 133.22V65.3731C72.8262 62.6264 75.071 60.3931 77.8318 60.3931H188.379C191.146 60.3931 193.391 62.6264 193.391 65.3731Z" fill="#EAC268" />
      <path d="M193.378 133.38C193.344 134.553 192.842 135.613 192.024 136.366C191.254 137.093 190.201 137.54 189.056 137.54H77.1618C76.0159 137.54 74.9638 137.093 74.1932 136.366C73.3824 135.613 72.8664 134.546 72.8396 133.366L133.048 81.3531L193.378 133.38Z" fill="#FFE195" />
      <path d="M193.391 65.3732V72.8132L133.048 121.627L72.8396 72.8332V65.3732C72.8396 62.6199 75.0777 60.3932 77.8453 60.3932H188.392C191.146 60.3932 193.391 62.6265 193.391 65.3732Z" fill="#EBCF90" />
    </g>
    <defs>
      <clipPath id="clip0_1_17545">
        <rect width="260" height="184" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

InvitationSentImage.propTypes = {};

InvitationSentImage.defaultProps = {};

export default InvitationSentImage;
