import v from 'voca';
import humanizeBoolean from '../../../../utils/boolUtils';

export const IGNORED_KEYS = [
  'id',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'companyId'
];

export function isChangedValue(key, oldValues, newValues) {
  const newValue = newValues ? newValues[key] : null;
  const oldValue = oldValues ? oldValues[key] : null;
  const sanitizedNewValue = sanitizeValue(newValue);
  const sanitizedOldValue = sanitizeValue(oldValue);

  const isNotEmpty = !v.isBlank(sanitizedNewValue) || !v.isBlank(sanitizedOldValue);
  return isNotEmpty && (sanitizedNewValue !== sanitizedOldValue);
}

export function sanitizeValue(value) {
  if (typeof value === 'boolean') {
    return humanizeBoolean(value);
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  if (typeof value === 'object') {
    const entries = Object.entries(value || {});
    if (entries.length === 0) return null;
    return JSON.stringify(value, null, 2);
  }

  return value;
}
