import i18n from 'i18n-js';
import React from 'react';
import moment from 'moment';

import { DateFormats, EMAIL_SMS_VERIFICATION, VerificationTypes } from '../../../../../constants';
import {
  isVerifiedStepCompleted
} from '../../../../admin/Customers/Show/Details/Verification/utils';
import IdVerificationIcon
  from '../../../../../assets/icons/verification-progress/IdVerificationIcon';
import HomeValuationIcon from '../../../../../assets/icons/verification-progress/HomeValuationIcon';
import FinancialVerificationIcon
  from '../../../../../assets/icons/verification-progress/FinancialVerificationIcon';
import EmailVerificationIcon
  from '../../../../../assets/icons/verification-progress/EmailVerificationIcon';
import api from '../../../../../api';
import { getCustomerQuery } from '../../../../../graphql';

const i18nOpts = { scope: 'components.global.customer.customerProgressPopover.verificationContent.utils' };

export function getVerifiedStep(verifiedStep, customer) {
  const {
    isEmailVerified, user, companyContact, homeValuedAt
  } = customer;
  let isCompleted = isVerifiedStepCompleted(verifiedStep.type, customer);
  const bankVerifiedAt = companyContact.affordability?.assessedAt;
  const idVerifiedAt = user?.idVerification?.completedAt;

  let title;
  let Icon;
  let completedAt = null;

  switch (verifiedStep.type) {
    case VerificationTypes.ID_VERIFICATION:
      title = i18n.t('idVerification', i18nOpts);
      Icon = IdVerificationIcon;
      completedAt = idVerifiedAt;
      break;

    case VerificationTypes.HOME_EVALUATION:
      title = i18n.t('homeValuation', i18nOpts);
      Icon = HomeValuationIcon;
      completedAt = homeValuedAt;
      break;

    case VerificationTypes.FINANCIAL_VERIFICATION:
      title = i18n.t('financialVerification', i18nOpts);
      Icon = FinancialVerificationIcon;
      completedAt = bankVerifiedAt;
      break;

    case EMAIL_SMS_VERIFICATION:
      title = i18n.t('emailSmsVerification', i18nOpts);
      Icon = EmailVerificationIcon;
      isCompleted = isEmailVerified;
      completedAt = companyContact.createdAt;
      break;

    default:
      return {};
  }

  const defaultCompletedAt = bankVerifiedAt || homeValuedAt || idVerifiedAt
    || companyContact.createdAt;

  return {
    type: verifiedStep.type,
    title,
    description: isCompleted ? moment(completedAt || defaultCompletedAt)
      .format(DateFormats.MMM_DD_YYYY) : i18n.t('notVerified', i18nOpts),
    Icon: <Icon width={28} height={28} />,
    isCompleted
  };
}

export async function fetchCustomerAsync(id, companyId) {
  const variables = {
    id,
    withTotalVisits: true,
    withAverageVisitTime: true,
    withPropertyInfo: true,
    companyId
  };

  return api.graphql(getCustomerQuery, variables)
    .then(({ data: { item } }) => item);
}
