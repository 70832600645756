import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Spinner,
  InputGroupAddon
} from 'reactstrap';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import v from 'voca';

import { getPropertyTaxRate, savePropertyTaxRate } from '../../../../store/actions/propertyTaxRateActions';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { getParam } from '../../../../utils/paramsUtils';
import { getGraphQLErrors } from '../../../../utils/requestUtils';
import { stripToEmpty } from '../../../../utils/stringUtils';
import { parseBoolean, parseToFloat } from '../../../../utils/parserUtils';
import LocationSelector from '../../../ui/LocationSelector';

const i18nOpts = { scope: 'components.admin.propertyTaxRates.form.index' };

const onCancel = () => {
  navigate('/admin/propertyTaxRates');
};

const PropertyTaxRateForm = ({ propertyTaxRate, saving, ...props }) => {
  const [mounted, setMounted] = useState(false);
  const [form, setForm] = useState({});
  const [error, setError] = useState(null);
  const id = parseInt(getParam('id'), 10);
  const isNew = !id;

  const onSave = (event) => {
    event.preventDefault();

    const input = {
      ...(!isNew) ? { id: form.id } : {},
      countryCode: stripToEmpty(form.countryCode),
      country: stripToEmpty(form.country),
      stateCode: stripToEmpty(form.stateCode),
      state: stripToEmpty(form.state),
      city: stripToEmpty(form.city),
      taxRate: (parseToFloat(form.taxRate) || 0) / 100,
      isDefault: parseBoolean(form.isDefault)
    };

    props.savePropertyTaxRate({ input })
      .then(() => navigate('/admin/propertyTaxRates'))
      .catch((e) => {
        const formattedError = getGraphQLErrors(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      });
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const loadPropertyTaxRate = () => {
    if (isNew) {
      setMounted(true);
      return;
    }

    props.getPropertyTaxRate(id)
      .then(() => {})
      .catch(() => navigate('/admin/propertyTaxRates'))
      .finally(() => setMounted(true));
  };

  const onLocationChange = (location) => {
    setForm((prevForm) => ({ ...prevForm, ...location }));
  };

  useEffect(() => {
    loadPropertyTaxRate();
  }, []);

  useEffect(() => {
    if (propertyTaxRate && !isNew) {
      setForm(
        {
          ...propertyTaxRate,
          taxRate: (propertyTaxRate.taxRate * 100).toFixed(6)
        }
      );
    } else if (isNew) setForm({ id, countryCode: 'CA', country: 'Canada' });
  }, [propertyTaxRate]);

  if (!mounted) return null;

  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

  return (
    <div>
      <Helmet title={title} />

      <h2 className="mb-4">{title}</h2>

      <Form onSubmit={onSave}>
        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <LocationSelector
              defaultCountryCode={form.countryCode}
              defaultStateCode={form.stateCode}
              defaultCity={form.city}
              onLocationChange={onLocationChange}
              errors={error}
            />
          </Col>
        </Row>

        <Row form className="mb-3">
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="isDefault"
                  id="isDefault"
                  value={!parseBoolean(form.isDefault)}
                  checked={parseBoolean(form.isDefault)}
                  onChange={onTextChange}
                />
                {i18n.t('isDefault', i18nOpts)}
              </Label>
            </FormGroup>
          </Col>
        </Row>

        <Row form className="mb-5">
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="taxRate">{i18n.t('taxRate', i18nOpts)}</Label>
              <InputGroup>
                <Input
                  type="number"
                  name="taxRate"
                  id="taxRate"
                  min={0}
                  max={100}
                  step="any"
                  value={form.taxRate || ''}
                  onChange={onTextChange}
                  invalid={isInputInvalid(error, 'taxRate')}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <InputError name="taxRate" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Button color="primary" className="mr-3" disabled={saving}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onCancel} className="mr-3">
          {i18n.t('buttons.cancel')}
        </Button>
      </Form>
    </div>
  );
};

PropertyTaxRateForm.propTypes = {};

PropertyTaxRateForm.defaultProps = {};

export default connect((store) => ({
  propertyTaxRate: store.propertyTaxRates.propertyTaxRate,
  saving: store.propertyTaxRates.savePropertyTaxRate.loading || false
}), {
  getPropertyTaxRate,
  savePropertyTaxRate
})(PropertyTaxRateForm);
