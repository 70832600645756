import React from 'react';
import PropTypes from 'prop-types';
import { BiGridVertical } from 'react-icons/bi';

import './styles.scss';

const StageItem = ({ stage }) => (
  <div className="sortable-stage-item">
    <div className="item-body">
      {stage.name}
    </div>
    <div className="move-icon">
      <BiGridVertical size="18" />
    </div>
  </div>
);

StageItem.propTypes = {
  stage: PropTypes.objectOf(PropTypes.any).isRequired
};

StageItem.defaultProps = {};

export default StageItem;
