import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import i18n from 'i18n-js';
import classNames from 'classnames';

import { Tabs } from '../utils';

const i18nOpts = { scope: 'components.admin.integrations.docuSign.userGuides.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <Nav vertical id="build-home-nav">
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.DOCUMENT_TEMPLATE })}
        onClick={() => onChange(Tabs.DOCUMENT_TEMPLATE)}
      >
        {i18n.t('documentTemplate', i18nOpts)}
      </NavLink>
    </NavItem>
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
