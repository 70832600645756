import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import v from 'voca';

import ActionsMenu from '../../../../../../global/ActionsMenu';
import ModalService from '../../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../../constants';
import { getError } from '../../../../../../../utils/requestUtils';
import {
  sendGridSaveContactList,
  sendGridDeleteContactList,
  sendGridSetContactList,
  sendGridCleanContacts,
} from '../../../../../../../store/actions/sendGridActions';

const ListItem = ({ contactList, currentCompany, ...props }) => {
  const onEdit = () => {
    ModalService.open(ModalNames.SENDGRID_CONTACT_LIST_FORM, { contactList });
  };

  const onDelete = (contactListId) => {
    const variables = { id: contactListId, companyId: currentCompany.id };
    props.sendGridDeleteContactList(variables)
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
      });
  };

  const onSelect = () => {
    props.sendGridCleanContacts();
    props.sendGridSetContactList(contactList);
    navigate(`/admin/integrations/sendGrid/contactLists/${contactList.id}/contacts`);
  };

  return (
    <tr>
      <td>
        <span
          role="button"
          aria-hidden
          className="word-break-break-all text-primary pointer-event"
          onClick={onSelect}
        >
          {contactList.name}
        </span>
      </td>
      <td>
        {contactList.contactCount}
      </td>
      <td className="text-right">
        <ActionsMenu
          item={contactList}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  contactList: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  sendGridSaveContactList,
  sendGridDeleteContactList,
  sendGridSetContactList,
  sendGridCleanContacts
})(ListItem);
