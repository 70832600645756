import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'gatsby';
import i18n from 'i18n-js';

import ActionsMenu from './ActionsMenu';
import { DateFormats } from '../../../../../constants';
import { isRecently } from '../../../../../utils/dateUtils';
import { isMasterAdmin, isTecSupport } from '../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.teams.list.listItem.index' };

const ListItem = ({ team }) => (
  <tr>
    <td>
      <Link to={`/admin/teams/${team.id}`} className="font-weight-600">
        {i18n.t('buttons.view')}
      </Link>
    </td>
    <td>
      <div className="font-weight-500">{team.name}</div>
    </td>
    {(isMasterAdmin() || isTecSupport()) && (
      <td>
        {team.company.name}
      </td>
    )}
    <td className="font-weight-500">
      {i18n.t('usersCount', { ...i18nOpts, count: team.activeUsersCount })}
    </td>
    <td>
      <span className={classNames({ 'text-success': isRecently(team.createdAt) })}>
        {moment(team.createdAt).format(DateFormats.MMM_DD_YYYY)}
      </span>
    </td>
    <td className="text-right">
      <ActionsMenu team={team} />
    </td>
  </tr>
);

ListItem.propTypes = {
  team: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
