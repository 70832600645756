import {
  SAVE_ELEVATION, DELETE_ELEVATION, GET_ELEVATIONS, SORT_ELEVATIONS, SET_ELEVATIONS
} from './types';
import {
  createElevationQuery,
  deleteElevationQuery,
  listElevationsQuery,
  sortElevationsQuery,
  updateElevationQuery
} from '../../graphql';
import api from '../../api';

export function getElevations(variables = {}) {
  return {
    type: GET_ELEVATIONS,
    payload: api.graphql(listElevationsQuery, variables)
  };
}

export function saveElevation(variables = {}) {
  const query = variables.input.id ? updateElevationQuery : createElevationQuery;
  return {
    type: SAVE_ELEVATION,
    payload: api.graphql(query, variables)
  };
}

export function deleteElevation(id) {
  return {
    type: DELETE_ELEVATION,
    payload: api.graphql(deleteElevationQuery, { id })
  };
}

export function setElevations(elevations) {
  return {
    type: SET_ELEVATIONS,
    payload: { elevations }
  };
}

export function sortElevations(variables = {}) {
  return {
    type: SORT_ELEVATIONS,
    payload: api.graphql(sortElevationsQuery, variables)
  };
}
