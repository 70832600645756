import React from 'react';
import {
  Button,
  Card, CardBody, CardHeader, Col, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import moment from 'moment';
import v from 'voca';
import { connect } from 'react-redux';

import { CustomerTabs, DateFormats } from '../../../../../../../../constants';
import {
  formatCustomerAddress,
  getCustomerAddress,
  getCustomerIdentificationInfo, getDocumentCountryName, getDocumentNumber,
  getDocumentType
} from '../../../../../../../../utils/customerUtils';
import { setActiveCustomerTab } from '../../../../../../../../store/actions/customerActions';

const i18nOpts = { scope: 'components.admin.customers.show.details.verification.idVerified.infoCard.index' };

const InfoCard = ({ customer, ...props }) => {
  const {
    isIdVerified, companyContact, user
  } = customer;
  const isIdVerifiedAndGranted = isIdVerified && companyContact.verifiedIdGranted;
  const idVerification = user?.idVerification;

  let documentExpiryDate;
  let documentType;
  let dateOfBirth;
  let documentNumber;
  let documentCountry;
  if (isIdVerifiedAndGranted) {
    const customerIdentificationInfo = getCustomerIdentificationInfo(customer);
    const { identificationDocument } = customerIdentificationInfo;
    documentExpiryDate = identificationDocument?.dateOfExpiry;
    documentType = getDocumentType(identificationDocument?.documentType);
    dateOfBirth = customerIdentificationInfo.dateOfBirth;
    documentNumber = getDocumentNumber(identificationDocument?.documentNumbers);
    documentCountry = getDocumentCountryName(identificationDocument?.issuingCountryCode);
  }

  const customerAddress = getCustomerAddress(customer);
  const address = formatCustomerAddress(customerAddress);
  const idVerifiedAt = idVerification?.completedAt;

  const onViewDocuments = () => {
    props.setActiveCustomerTab(CustomerTabs.DOCUMENTS);
  };

  return (
    <Card className="mb-0">
      <CardHeader className="py-2">
        <div className="d-flex align-items-center gap-3">
          <h4 className="mb-0 col-gray-900">{i18n.t('title', i18nOpts)}</h4>
          {idVerifiedAt && (
            <div className="small">
              {i18n.t('verified', { ...i18nOpts, date: moment(idVerifiedAt).format(DateFormats.YYYY_MM_DD) })}
            </div>
          )}
          <div className="ml-auto">
            <Button color="secondary" outline onClick={onViewDocuments}>
              {i18n.t('buttons.viewDocuments', i18nOpts)}
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="pb-0">
        <Row>
          {!v.isBlank(customer.firstName) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('firstName', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{customer.firstName}</div>
            </Col>
          )}

          <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
            <div className="font-weight-500 col-gray-500">{i18n.t('lastName', i18nOpts)}</div>
            <div className="font-weight-600 font-16">{customer.lastName}</div>
          </Col>

          {!v.isBlank(documentType) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('documentType', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{documentType}</div>
            </Col>
          )}

          {!v.isBlank(documentNumber) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('documentNumber', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{documentNumber}</div>
            </Col>
          )}

          {!v.isBlank(dateOfBirth) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('dateOfBirth', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{dateOfBirth}</div>
            </Col>
          )}

          {!v.isBlank(documentExpiryDate) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('expiryDate', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{documentExpiryDate}</div>
            </Col>
          )}

          {!v.isBlank(address) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('address', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{address}</div>
            </Col>
          )}

          {!v.isBlank(documentCountry) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('country', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{documentCountry}</div>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

InfoCard.propTypes = {};

InfoCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
}), {
  setActiveCustomerTab
})(InfoCard);
