import React, { useMemo } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import i18n from 'i18n-js';
import { navigate } from 'gatsby';

import { getParam } from '../../../../../../utils/paramsUtils';

const i18nOpts = { scope: 'components.admin.customers.show.components.backLink.index' };

const BackLink = () => {
  const [title, redirectUrl] = useMemo(() => {
    const from = getParam('from');

    switch (from) {
      case 'deals':
        return [i18n.t('deals', i18nOpts), '/admin/sales/deals'];
      case 'contracts':
        return [i18n.t('contracts', i18nOpts), '/admin/sales/contracts'];
      default:
        return [i18n.t('buttons.back'), '/admin/customers'];
    }
  }, []);

  const onClick = () => {
    navigate(redirectUrl);
  };

  return (
    <div className="d-inline-flex align-items-center text-primary" role="button" aria-hidden onClick={onClick}>
      <MdOutlineArrowBack size="20" />
      <span className="ml-1 font-weight-600">{title}</span>
    </div>
  );
};

BackLink.propTypes = {};

BackLink.defaultProps = {};

export default BackLink;
