import React from 'react';
import PropTypes from 'prop-types';

import { Roles } from '../../../constants';
import SecureStore from '../../../utils/secureStore';

const ProtectedRoute = ({
  component: Component, only, except, ...props
}) => {
  let roles = [];
  const role = SecureStore.getRole();

  if (only) {
    roles = Array.isArray(only) ? only : [only];
    if (!roles.includes(role)) return null;
  }

  if (except) {
    roles = Array.isArray(except) ? except : [except];
    if (roles.includes(role)) return null;
  }

  return <Component {...props} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  only: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(Roles)),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  except: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(Roles)),
    PropTypes.arrayOf(PropTypes.string)
  ])
};

ProtectedRoute.defaultProps = {
  only: null,
  except: null
};

export default ProtectedRoute;
