import { ADD_ITEM_TO_NAVIGATION, RESET_NAVIGATION } from './types';

export function addNavigationChildren(children) {
  return {
    type: ADD_ITEM_TO_NAVIGATION,
    payload: { children }
  };
}

export function resetNavigation() {
  return {
    type: RESET_NAVIGATION
  };
}
