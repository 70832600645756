import {
  CLEAN_CITIES, GET_CITY, SAVE_CITY, DELETE_CITY, LIST_CITIES
} from './types';
import api from '../../api';
import {
  createCityQuery, deleteCityQuery,
  getCityQuery,
  listCitiesQuery,
  updateCityQuery
} from '../../graphql';

export function listCities(variables = {}) {
  return {
    type: LIST_CITIES,
    payload: api.graphql(listCitiesQuery, variables)
  };
}

export function getCity(id) {
  return {
    type: GET_CITY,
    payload: api.graphql(getCityQuery, { id })
  };
}

export function cleanCities(extraProps = {}) {
  return {
    type: CLEAN_CITIES,
    payload: Promise.resolve(extraProps)
  };
}

export function saveCity(variables = {}) {
  const query = variables.input.id ? updateCityQuery : createCityQuery;
  return {
    type: SAVE_CITY,
    payload: api.graphql(query, variables)
  };
}

export function deleteCity(id) {
  return {
    type: DELETE_CITY,
    payload: api.graphql(deleteCityQuery, { id })
  };
}
