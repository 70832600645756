import api from '../../../../../../api';
import { listContactRegionsQuery } from '../../../../../../graphql';

export async function fetchContactRegionsAsync(companyId) {
  const variables = {
    companyId,
  };
  return api.graphql(listContactRegionsQuery, variables)
    .then(({ data: { items } }) => items);
}
