import React from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';
import { sortFloors } from '../../../../../store/actions/floorActions';
import { confirmDelete } from '../../../confirmAlert';

const i18nOpts = { scope: 'components.global.floorsManager.floor.actionsMenu.index' };

function onEdit(floor) {
  ModalService.open(ModalNames.FLOOR_FORM, { floor });
}

function sortFloorIds(floors, index, extraIndex) {
  const floorIds = floors.map((f) => f.id);

  const secondIndex = index + extraIndex;
  const floorId = floorIds[index];
  floorIds[index] = floorIds[secondIndex];
  floorIds[secondIndex] = floorId;
  return floorIds;
}

const ActionsMenu = ({
  floor, index, onDelete, floors, ...props
}) => {
  const totalFloors = floors.length;

  const onSort = (extraIndex) => {
    const floorIds = sortFloorIds(floors, index, extraIndex);

    const variables = { ids: floorIds };
    props.sortFloors(variables);
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="a" className="px-2">
        <i className="fas fa-ellipsis-v text-primary" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={() => onEdit(floor)}>
          {i18n.t('buttons.edit')}
        </DropdownItem>
        <DropdownItem className="text-danger" onClick={() => confirmDelete(onDelete)}>
          {i18n.t('buttons.delete')}
        </DropdownItem>
        {totalFloors > 1 && (
          <>
            {index > 0 && (
              <DropdownItem onClick={() => onSort(-1)}>
                {i18n.t('moveUp', i18nOpts)}
              </DropdownItem>
            )}
            {index < totalFloors - 1 && (
              <DropdownItem onClick={() => onSort(1)}>
                {i18n.t('moveDown', i18nOpts)}
              </DropdownItem>
            )}
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

ActionsMenu.propTypes = {
  floor: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func
};

ActionsMenu.defaultProps = {
  onDelete: () => {
  }
};

export default connect((store) => ({
  floors: store.floors.floors
}), {
  sortFloors
})(ActionsMenu);
