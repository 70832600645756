import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Proptypes from 'prop-types';

import { setLevelTwoSubMenu } from '../../../../../store/actions/menuActions';
import './styles.scss';

const LevelTwoSubMenu = ({
  children, title, menu, IconElement, levelTwoSubMenu, ...props
}) => {
  const [openedSubMenu, setOpenedSubMenu] = useState(false);

  const onClick = () => {
    props.setLevelTwoSubMenu(menu === levelTwoSubMenu ? null : menu);
  };

  useEffect(() => {
    setOpenedSubMenu(menu === levelTwoSubMenu);
  }, [menu, levelTwoSubMenu]);

  return (
    <li className={classNames('sidebar-item-sub')}>
      <div
        className={classNames('sidebar-link sidebar-link-sub has-arrow', { 'opened-sub-menu': openedSubMenu })}
        onClick={onClick}
        role="button"
        aria-hidden
      >
        <i>{IconElement}</i>
        <span className="sub-menu-title ml-4">{title}</span>
      </div>
      <ul className={classNames('second-level pl-0 pt-0', { 'opened-sub-menu': openedSubMenu })}>
        {children}
      </ul>
    </li>
  );
};

LevelTwoSubMenu.prototypes = {
  menu: Proptypes.string.isRequired,
  title: Proptypes.string.isRequired
};

LevelTwoSubMenu.defaultProps = {};

export default connect((store) => ({
  levelTwoSubMenu: store.menu.levelTwoSubMenu
}), {
  setLevelTwoSubMenu
})(LevelTwoSubMenu);
