import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import {
  Button, TabContent, TabPane
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { HiShare } from 'react-icons/hi';

import { isAdmin } from '../../../../utils/authUtils';
import Details from './Details';
import Images from './Images';
import { getBuildingModel } from '../../../../store/actions/buildingModelActions';
import NavTabs from './NavTabs';
import { Tabs } from './utils';
import Packages from './Packages';
import Palettes from './Palettes';
import FloorsManager from '../../../global/FloorsManager';
import { FloorableTypes, ModalNames, NavigationMainNames } from '../../../../constants';
import DirectLinkModal from './DirectLinkModal';
import QRCodeModal from '../../../global/QRCodeModal';
import { hasCompanyDomain } from '../../../../utils/companyUtils';
import ModalService from '../../../../services/ModalService';
import Modifications from '../../../global/Modifications';
import NavigationService from '../../../../services/NavigationService';
import NavigationBreadcrumbs from '../../../global/NavigationBreadcrumbs';
import Loading from '../../../ui/Loading';

const Show = ({
  id, buildingModel, loading, currentCompany, ...props
}) => {
  const [activeTab, setActiveTab] = useState(Tabs.DETAILS);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  const onOpenDirectLinkModal = () => {
    ModalService.open(ModalNames.MODEL_DIRECT_LINK_MODAL);
  };

  const loadBuildingModel = () => {
    const variables = { id: parseInt(id, 10) };
    props.getBuildingModel(variables)
      .catch(() => navigate('/admin/buildingModels'));
  };

  useEffect(() => {
    loadBuildingModel();
  }, []);

  useEffect(() => {
    if (buildingModel) {
      NavigationService.addItem(
        buildingModel, NavigationMainNames.BUILDING_MODELS
      );
    }
  }, [buildingModel]);

  if (!buildingModel || loading) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={buildingModel.name} />

      <NavigationBreadcrumbs />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{buildingModel.name}</h2>
        </div>
        <div>
          {isAdmin() && (
            <>
              {hasCompanyDomain(currentCompany) && (
                <Button color="secondary" onClick={onOpenDirectLinkModal}>
                  <HiShare size="1rem" className="col-light mr-2" />
                  <span>{i18n.t('buttons.shareLink')}</span>
                </Button>
              )}

              <Link to={`/admin/buildingModels/form/${buildingModel.id}`} className="btn btn-primary ml-3">
                <i className="far fa-edit mr-2" />
                {i18n.t('buttons.edit')}
              </Link>
            </>
          )}
        </div>
      </div>

      <NavTabs activeTab={activeTab} onChange={onTabChange} />

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.DETAILS}>
          <Details />
        </TabPane>
        <TabPane tabId={Tabs.FLOORS}>
          <FloorsManager
            floorableId={buildingModel.id}
            floorableType={FloorableTypes.BUILDING_MODEL}
          />
        </TabPane>
        <TabPane tabId={Tabs.IMAGES}>
          <Images />
        </TabPane>
        <TabPane tabId={Tabs.PACKAGES}>
          <Packages />
        </TabPane>
        <TabPane tabId={Tabs.PALETTES}>
          <Palettes />
        </TabPane>
        {isAdmin() && (
          <TabPane tabId={Tabs.MODIFICATIONS}>
            <Modifications instance={buildingModel} />
          </TabPane>
        )}
      </TabContent>

      <DirectLinkModal />
      <QRCodeModal />
    </div>
  );
};

export default connect((store) => ({
  buildingModel: store.buildingModels.buildingModel,
  loading: store.buildingModels.getBuildingModel.loading || false,
  currentCompany: store.companies.currentCompany
}), {
  getBuildingModel
})(Show);
