import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { ModalNames } from '../../../../../../constants';
import ModalCloseButton from '../../../../../common/ModalCloseButton';
import ModalService from '../../../../../../services/ModalService';
import SaveChangesImage from '../../../../../../assets/images/SaveChangesImage';

const i18nOpts = { scope: 'components.admin.companies.settings.components.saveChangesModal.index' };

function onCloseModal() {
  ModalService.close(ModalNames.COMPANY_SAVE_CHANGES_MODAL);
}

const SaveChangesModal = ({ opened, ...props }) => {
  const onSave = () => {
    onCloseModal();
    props.onSave();
  };

  const onDoNotSave = () => {
    onCloseModal();
    props.onDoNotSave();
  };

  return (
    <Modal isOpen={opened} centered modalClassName="custom-md">
      <ModalHeader close={<ModalCloseButton onClick={onCloseModal} />} className="border-bottom-0" />

      <ModalBody>
        <div className="text-center mt-n4">
          <SaveChangesImage />
        </div>

        <div className="mt-4">
          <div className="font-18 font-weight-600 mb-1">{i18n.t('title', i18nOpts)}</div>
          <div className="font-14">{i18n.t('description', i18nOpts)}</div>
        </div>
      </ModalBody>
      <ModalFooter className="gap-2">
        <div className="flex-even">
          <Button color="secondary" outline block onClick={onDoNotSave}>
            {i18n.t('buttons.doNotSave')}
          </Button>
        </div>
        <div className="flex-even">
          <Button color="primary" block className="mr-3" onClick={onSave}>
            {i18n.t('buttons.save')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

SaveChangesModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onDoNotSave: PropTypes.func.isRequired
};

SaveChangesModal.defaultProps = {};

export default connect((store) => ({
  params: store.modals[ModalNames.COMPANY_SAVE_CHANGES_MODAL]?.params || {},
  opened: store.modals[ModalNames.COMPANY_SAVE_CHANGES_MODAL]?.opened || false,
}), {})(SaveChangesModal);
