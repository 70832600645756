import _ from 'lodash';

import { getPathname } from '../../../../utils/paramsUtils';
import { convertDateRangeToUtcRange } from '../../../../utils/dateUtils';
import { parseToFloat } from '../../../../utils/parserUtils';
import { isValidAmount } from '../../../../utils/currencyUtils';
import { Statuses } from '../../../../constants';
import api from '../../../../api';

export const CustomerColumns = Object.freeze({
  STATUS: 'status',
  ASSIGNED_TO: 'assignedTo',
  INCOME: 'income',
  HOME_VALUATION: 'homeValuation',
  PRE_APPROVAL: 'preApproval',
  INTERESTS: 'interests',
  REGION: 'region',
  PHONE: 'phone'
});

export const ASSIGNED_FILTERS = Object.freeze({
  ALL: 'all',
  ASSIGNED_TO_ME: 'assignedToMe'
});

export function isDeletedCustomersPage() {
  const pathname = getPathname();
  return pathname.includes('/deleted');
}

export function getValidColumns(columns, currentCompany) {
  const allColumns = columns || [];
  return allColumns.filter((column) => {
    if ([CustomerColumns.HOME_VALUATION, CustomerColumns.INCOME].includes(column)) {
      return currentCompany.enableVerifiedBuyingPower;
    }
    return true;
  });
}

export function getCustomerFilters(currentCompany, showOnlyDeleted, filters) {
  const filter = { companyId: currentCompany.id };

  if (filters.type) filter.type = filters.type;
  if (showOnlyDeleted) filter.onlyDeleted = true;
  if (filters.salesLeadId) {
    filter.salesLeads = [{ id: filters.salesLeadId, __typename: filters.salesLeadType }];
  }
  if (filters.interestId) {
    filter.interests = [
      { id: filters.interestId, __typename: filters.interestType }
    ];
  }
  if (filters.regions?.length) filter.regions = filters.regions;
  if (filters.reservationStatuses?.length) {
    filter.reservationStatuses = filters.reservationStatuses;
  }
  if (filters.verificationStatuses?.length) {
    filter.verificationStatuses = filters.verificationStatuses;
  }
  if (filters.statuses?.length) filter.statuses = filters.statuses;
  if (filters.lastActiveDate) {
    filter.lastActiveDate = convertDateRangeToUtcRange(filters.lastActiveDate);
  }
  if (filters.signUpDate) filter.signUpDate = convertDateRangeToUtcRange(filters.signUpDate);
  if (filters.buyingPower) {
    const { buyingPower } = filters;
    filter.buyingPower = getFilterRange(buyingPower);
  }
  if (filters.rentingPower) {
    const { rentingPower } = filters;
    filter.rentingPower = getFilterRange(rentingPower);
  }
  if (filters.annualIncome) {
    const { annualIncome } = filters;
    filter.annualIncome = getFilterRange(annualIncome);
  }
  if (filters.interests?.length) {
    filter.interests = filter.interests
      ? [...filter.interests, ...filters.interests]
      : filters.interests;
  }
  if (filters.salesLeads?.length) {
    filter.salesLeads = filter.salesLeads
      ? [...filter.salesLeads, ...filters.salesLeads]
      : filters.salesLeads;
  }
  return filter;
}

function getFilterRange(filterRange) {
  const from = parseToFloat(filterRange.from) || null;
  const to = parseToFloat(filterRange.to) || null;

  return {
    ...(isValidAmount(from) ? { from } : {}),
    ...(isValidAmount(to) ? { to } : {}),
  };
}

export function getInterestKey(interest) {
  const { id, __typename: typename } = interest;
  return `interest-filter-${typename}-${id}`;
}
