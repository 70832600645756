import React from 'react';
import i18n from 'i18n-js';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';

import './styles.scss';
import { isAdmin } from '../../../../../utils/authUtils';
import ListItem from './ListItem';
import SortableColumnHeader from '../../../../common/SortableColumnHeader';
import { setModelsSearchFilters } from '../../../../../store/actions/modelActions';
import setSortFilter from '../../../../../utils/sortUtils';

const i18nOpts = { scope: 'components.admin.models.list.listView.index' };

const ListView = ({ models, searchFilters, ...props }) => {
  const onSortChange = (field) => {
    const sort = setSortFilter(field, searchFilters.sort);
    props.setModelsSearchFilters({ sort });
  };

  return (
    <Table responsive id="models-table" bordered>
      <thead>
        <tr>
          <SortableColumnHeader
            field="name"
            onPress={() => onSortChange('name')}
            label={i18n.t('name', i18nOpts)}
            sort={searchFilters.sort}
          />
          <SortableColumnHeader
            field="sizeSqft"
            onPress={() => onSortChange('sizeSqft')}
            label={i18n.t('size', i18nOpts)}
            sort={searchFilters.sort}
          />
          <th>{i18n.t('bedrooms', i18nOpts)}</th>
          <th>{i18n.t('bathrooms', i18nOpts)}</th>
          <SortableColumnHeader
            field="basePrice"
            onPress={() => onSortChange('basePrice')}
            label={i18n.t('price', i18nOpts)}
            sort={searchFilters.sort}
          />
          <SortableColumnHeader
            field="status"
            onPress={() => onSortChange('status')}
            label={i18n.t('status', i18nOpts)}
            sort={searchFilters.sort}
          />
          {isAdmin() && (
            <>
              <th>{i18n.t('createdBy', i18nOpts)}</th>
              <th>{i18n.t('lastModifiedBy', i18nOpts)}</th>
              <th>&nbsp;</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {
          models.map((model) => (
            <ListItem key={`model-${model.id}`} model={model} />
          ))
      }
      </tbody>
    </Table>
  );
};

export default connect((store) => ({
  models: store.models.models,
  searchFilters: store.models.searchFilters
}), {
  setModelsSearchFilters
})(ListView);
