import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import {
  Row, Card, CardHeader, CardBody
} from 'reactstrap';

import TimeLineElement from '../../../../common/TimeLineElement';
import CardField from '../components/CardField';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.homewisePostback.index' };

const HomewisePostback = ({ activity, innerKey }) => {
  const { homewisePostback } = activity;
  if (!homewisePostback) return null;

  const { rawData: homewisePayload } = homewisePostback;

  const title = i18n.t(`statuses.${homewisePayload.status.toLowerCase()}`, i18nOpts);
  const Field = ({ name, isDate = false, isAmount = false }) => (
    <CardField
      attr={name}
      label={i18n.t(name, i18nOpts)}
      instance={homewisePayload}
      isDate={isDate}
      isAmount={isAmount}
    />
  );

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<FaMoneyCheckAlt size="1.2rem" />}
    >
      <Card>
        <CardHeader>
          <h4>{title}</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Field name="updatedAt" isDate />
            <Field name="price" isAmount />
          </Row>
        </CardBody>
      </Card>
    </TimeLineElement>
  );
};

HomewisePostback.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired,
};

export default HomewisePostback;
