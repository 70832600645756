import React from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MdExpandMore } from 'react-icons/md';

import EditableSwitch from '../components/EditableSwitch';
import { Colors } from '../../../../../constants';

const Floor = ({
  floor, activeFloor, onToggle, children, editableFloorIds, onEditableToggle
}) => {
  const isEditable = editableFloorIds.includes(floor.id);

  return (
    <div className="floor">
      <div className={`floor-header ${classnames({ active: floor.id === activeFloor })}`}>
        <div
          className="flex-grow-1 pr-2 font-weight-600 text-left"
          onClick={() => onToggle(floor.id)}
          role="button"
          aria-hidden
        >
          {floor.name}
        </div>
        <div>
          <EditableSwitch checked={isEditable} onToggle={() => onEditableToggle(floor.id)} />
        </div>
        <div
          onClick={() => onToggle(floor.id)}
          role="button"
          aria-hidden
        >
          <div className="floor-expand-icon">
            <MdExpandMore size="22" color={Colors.GRAY_600} />
          </div>
        </div>
      </div>

      <Collapse isOpen={activeFloor === floor.id}>
        <div className="floor-content">
          {children}
        </div>
      </Collapse>
    </div>
  );
};

Floor.propTypes = {
  floor: PropTypes.objectOf(PropTypes.any).isRequired,
  activeFloor: PropTypes.number,
  onToggle: PropTypes.func,
  editableFloorIds: PropTypes.arrayOf(PropTypes.number),
  onEditableToggle: PropTypes.func
};

Floor.defaultProps = {
  activeFloor: null,
  onToggle: () => {},
  editableFloorIds: [],
  onEditableToggle: () => {}
};

export default Floor;
