import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';

import { isAdmin } from '../../../../../../utils/authUtils';
import ActionsMenu from './ActionsMenu';
import { DEFAULT_IMAGE } from '../../../../../../constants';
import UnitsMultiSelect from './UnitsMultiSelect';
import FloorDesign from './FloorDesign';

const BuildingFloor = ({
  buildingFloor, units
}) => {
  const { imageUrl = null } = buildingFloor;

  return (
    <Card>
      <CardHeader className="bg-light">
        <div className="d-flex">
          <div className="flex-grow-1">
            <strong className="text-uppercase text-dark">{buildingFloor.name}</strong>
          </div>
          {isAdmin() && (
            <div>
              <ActionsMenu buildingFloor={buildingFloor} />
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <div className="my-2">
          <UnitsMultiSelect buildingFloor={buildingFloor} units={units} />
        </div>
        { imageUrl ? (
          <FloorDesign imageUrl={imageUrl} buildingFloor={buildingFloor} />
        ) : (
          <div>
            <img src={DEFAULT_IMAGE} alt="" className="building-floor-image" />
          </div>
        ) }
      </CardBody>
    </Card>
  );
};

BuildingFloor.propTypes = {
  buildingFloor: PropTypes.objectOf(PropTypes.any).isRequired,
  units: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

BuildingFloor.defaultProps = {
  units: [],
};

export default BuildingFloor;
