import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import i18n from 'i18n-js';

import StripePaymentStatus from './StripePaymentStatus';
import StripePayoutStatus from './StripePayoutStatus';
import { DepositStatuses, PaymentVendors } from '../../../../../../../constants';
import StripeIcon from '../../../../../../../assets/icons/StripeIcon';
import { getPaymentMethodName } from '../../../../../../../utils/enumUtils';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.depositSection.stripeStatus.index' };

const StripeStatus = ({ deposit }) => {
  if (deposit?.paymentVendor !== PaymentVendors.STRIPE) return null;

  if (![DepositStatuses.SUCCEEDED, DepositStatuses.FAILED]
    .includes(deposit.status)) return null;

  return (
    <Card body className="p-3 mb-3 d-flex flex-column gap-3">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <small className="mr-1 text-secondary">{i18n.t('paymentMethod', i18nOpts)}</small>
          <br />
          <span className="font-weight-500">{getPaymentMethodName(deposit.paymentMethod)}</span>
        </div>
        <div>
          <StripeIcon width={40} height={16} />
        </div>
      </div>

      <StripePaymentStatus deposit={deposit} />

      <StripePayoutStatus deposit={deposit} />
    </Card>
  );
};

StripeStatus.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.any)
};

StripeStatus.defaultProps = {
  deposit: null
};

export default StripeStatus;
