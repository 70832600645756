import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IoMdDownload } from 'react-icons/io';
import i18n from 'i18n-js';

import { ModalNames, WebSocketMessages } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import ModalCloseButton from '../../../../../common/ModalCloseButton';
import ExportProgress from '../../../../../common/ExportProgress';
import { SocketContext } from '../../../../../../providers/Socket';
import { getCustomerFilters } from '../../utils';
import { exportCustomersAsync } from './utils';
import { getPathname } from '../../../../../../utils/paramsUtils';

const i18nOpts = { scope: 'components.admin.customers.list.components.exportModal.index' };

function closeModal() {
  ModalService.close(ModalNames.CUSTOMERS_EXPORT);
}

function getRandomProgress() {
  return Math.floor((Math.random() * 5) + 1);
}

const ExportModal = ({ opened, currentCompany, filters }) => {
  const [currentVersion, setCurrentVersion] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [extraProgress, setExtraProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const downloadInterval = useRef(null);
  const socket = useContext(SocketContext);

  const showOnlyDeleted = useMemo(() => {
    const pathname = getPathname();
    return pathname.includes('/deleted');
  }, []);

  const exportCustomers = () => {
    const filter = getCustomerFilters(currentCompany, showOnlyDeleted, filters);
    const variables = { filter };
    exportCustomersAsync(variables)
      .then((newVersion) => setCurrentVersion(newVersion))
      .catch(() => {});
  };

  const startDownload = () => {
    socket.connect();
    socket.on(WebSocketMessages.CUSTOMERS_EXPORT, (data) => {
      console.log('DATA: ', data);
      const { version, progress, fileUrl } = data;
      if (version !== currentVersion) return;

      if (progress === 100) endDownload(fileUrl);

      setExtraProgress(0);
      setCurrentProgress(progress);
    });

    downloadInterval.current = setInterval(() => {
      setExtraProgress((prevExtraProgress) => {
        const newExtraProgress = prevExtraProgress + getRandomProgress();
        return newExtraProgress <= 25 ? newExtraProgress : prevExtraProgress;
      });
    }, 500);
  };

  const endDownload = (fileUrl) => {
    clearInterval(downloadInterval?.current);
    setDownloadUrl(fileUrl);
  };

  const resetExport = () => {
    socket.off(WebSocketMessages.CUSTOMERS_EXPORT);
    socket.disconnect();

    clearInterval(downloadInterval?.current);

    setCurrentVersion(null);
    setCurrentProgress(0);
    setExtraProgress(0);
    setTotalProgress(0);
    setDownloadUrl(null);
  };

  useEffect(() => {
    if (currentProgress === 100) setTotalProgress(100);
    else setTotalProgress(Math.min(currentProgress + extraProgress, 99));
  }, [currentProgress, extraProgress]);

  useEffect(() => {
    if (currentVersion) startDownload();
  }, [currentVersion]);

  useEffect(() => {
    if (opened) exportCustomers();
    else setTimeout(resetExport, 500);
  }, [opened]);

  const isCompleted = useMemo(() => currentProgress === 100, [currentProgress]);

  return (
    <Modal isOpen={opened}>
      <ModalHeader close={<ModalCloseButton onClick={closeModal} />}>
        {i18n.t('title', i18nOpts)}
      </ModalHeader>
      <ModalBody>
        <p>{i18n.t(`messages.${isCompleted ? 'exported' : 'exporting'}`, i18nOpts)}</p>
        <ExportProgress progress={totalProgress} />

        {(isCompleted && downloadUrl) && (
          <div className="text-center mt-3">
            <a href={downloadUrl} className="btn btn-outline-secondary" download>
              <IoMdDownload size="1rem" />
              <span className="ml-2">{i18n.t('buttons.download')}</span>
            </a>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

ExportModal.propTypes = {};

ExportModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.CUSTOMERS_EXPORT]?.opened || false,
  currentCompany: store.companies.currentCompany,
  filters: store.customers.filters
}))(ExportModal);
