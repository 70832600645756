import React from 'react';

const DepositIcon = ({ ...props }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" color="#98A2B3" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6 1.61111C16.6 0.72132 15.8837 0 15 0C14.1163 0 13.4 0.72132 13.4 1.61111V3.22222C9.8654 3.22222 7 6.1075 7 9.6667C7 13.2259 9.8654 16.1111 13.4 16.1111V22.5556C12.0092 22.5556 10.8218 21.6612 10.3817 20.4075C10.0872 19.5686 9.1731 19.1289 8.3399 19.4254C7.5068 19.7219 7.0701 20.6424 7.3646 21.4813C8.2424 23.9822 10.6109 25.7778 13.4 25.7778V27.3889C13.4 28.2787 14.1163 29 15 29C15.8837 29 16.6 28.2787 16.6 27.3889V25.7778C20.1346 25.7778 23 22.8925 23 19.3333C23 15.7741 20.1346 12.8889 16.6 12.8889V6.4444C17.9908 6.4444 19.1782 7.3388 19.6183 8.5925C19.9128 9.4314 20.8269 9.8711 21.6601 9.5746C22.4932 9.2781 22.9299 8.3576 22.6354 7.5187C21.7576 5.0178 19.3891 3.22222 16.6 3.22222V1.61111ZM13.4 6.4444C11.6327 6.4444 10.2 7.8871 10.2 9.6667C10.2 11.4462 11.6327 12.8889 13.4 12.8889V6.4444ZM16.6 16.1111V22.5556C18.3673 22.5556 19.8 21.1129 19.8 19.3333C19.8 17.5538 18.3673 16.1111 16.6 16.1111Z" fill="currentColor" />
  </svg>
);

DepositIcon.propTypes = {};

DepositIcon.defaultProps = {};

export default DepositIcon;
