import React from 'react';

const OptionTypesIcon = ({ ...props }) => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.3951 18.3711L7.97955 19.6856C8.15329 20.0768 8.43683 20.4093 8.79577 20.6426C9.15472 20.8759 9.57366 21.0001 10.0018 21C10.4299 21.0001 10.8488 20.8759 11.2078 20.6426C11.5667 20.4093 11.8503 20.0768 12.024 19.6856L12.6084 18.3711C12.8165 17.9047 13.1664 17.5159 13.6084 17.26C14.0532 17.0034 14.5678 16.8941 15.0784 16.9478L16.5084 17.1C16.9341 17.145 17.3637 17.0656 17.7451 16.8713C18.1265 16.6771 18.4434 16.3763 18.6573 16.0056C18.8715 15.635 18.9735 15.2103 18.9511 14.7829C18.9286 14.3555 18.7825 13.9438 18.5307 13.5978L17.684 12.4344C17.3825 12.0171 17.2214 11.5148 17.224 11C17.2239 10.4866 17.3865 9.98635 17.6884 9.57111L18.5351 8.40778C18.787 8.06175 18.933 7.65007 18.9555 7.22267C18.978 6.79528 18.8759 6.37054 18.6618 6C18.4479 5.62923 18.131 5.32849 17.7496 5.13423C17.3681 4.93997 16.9386 4.86053 16.5129 4.90556L15.0829 5.05778C14.5722 5.11141 14.0577 5.00212 13.6129 4.74556C13.17 4.48825 12.82 4.09736 12.6129 3.62889L12.024 2.31444C11.8503 1.92317 11.5667 1.59072 11.2078 1.3574C10.8488 1.12408 10.4299 0.99993 10.0018 1C9.57366 0.99993 9.15472 1.12408 8.79577 1.3574C8.43683 1.59072 8.15329 1.92317 7.97955 2.31444L7.3951 3.62889C7.18803 4.09736 6.83798 4.48825 6.3951 4.74556C5.95032 5.00212 5.43577 5.11141 4.9251 5.05778L3.49066 4.90556C3.06499 4.86053 2.6354 4.93997 2.25397 5.13423C1.87255 5.32849 1.55567 5.62923 1.34177 6C1.12759 6.37054 1.02555 6.79528 1.04804 7.22267C1.07052 7.65007 1.21656 8.06175 1.46844 8.40778L2.3151 9.57111C2.61704 9.98635 2.77964 10.4866 2.77955 11C2.77964 11.5134 2.61704 12.0137 2.3151 12.4289L1.46844 13.5922C1.21656 13.9382 1.07052 14.3499 1.04804 14.7773C1.02555 15.2047 1.12759 15.6295 1.34177 16C1.55589 16.3706 1.8728 16.6712 2.25417 16.8654C2.63554 17.0596 3.06502 17.1392 3.49066 17.0944L4.92066 16.9422C5.43133 16.8886 5.94587 16.9979 6.39066 17.2544C6.83519 17.511 7.18687 17.902 7.3951 18.3711Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.99998 14C11.6568 14 13 12.6569 13 11C13 9.34315 11.6568 8 9.99998 8C8.34313 8 6.99998 9.34315 6.99998 11C6.99998 12.6569 8.34313 14 9.99998 14Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

OptionTypesIcon.propTypes = {};

OptionTypesIcon.defaultProps = {};

export default OptionTypesIcon;
