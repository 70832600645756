import React from 'react';
import {
  Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import i18n from 'i18n-js';

const i18nOpts = { scope: 'components.admin.integrations.socialMedia.index.apple.index' };

const Apple = ({ form, onTextChange }) => (
  <>
    <Row form>
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="appleClientId">{i18n.t('clientId', i18nOpts)}</Label>
          <Input
            type="text"
            name="appleClientId"
            id="appleClientId"
            value={form.appleClientId || ''}
            onChange={onTextChange}
          />
        </FormGroup>
      </Col>
    </Row>
  </>
);

export default Apple;
