import React from 'react';

const Baths = ({ children }) => (
  <div>
    <strong className="text-dark">{children || 0}</strong>
    <i className="fas fa-bath ml-2 text-dark" />
  </div>
);

export default Baths;
