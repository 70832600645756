export const Steps = Object.freeze({
  CUSTOMER_SELECTION_TYPE: 'CUSTOMER_SELECTION_TYPE',
  NEW_CUSTOMER: 'NEW_CUSTOMER',
  EXISTING_CUSTOMER: 'EXISTING_CUSTOMER',
  INVITATION_LIST: 'INVITATION_LIST',
  INVITATION_TYPE: 'INVITATION_TYPE',
  HOME_SELECTION_TYPE: 'HOME_SELECTION_TYPE',
  NEW_HOME: 'NEW_HOME',
  EXISTING_HOME: 'EXISTING_HOME',
  CONFIRM_INVITATION: 'CONFIRM_INVITATION',
  INVITATION_SENT: 'INVITATION_SENT',
});

export const SalesInviteMethods = Object.freeze({
  EMAIL: 'EMAIL',
  SMS: 'SMS',
});

export const InvitationHomeSortFields = Object.freeze({
  NEWEST: 'newest',
  OLDEST: 'oldest',
  PRICE_ASCENDING: 'priceAscending',
  PRICE_DESCENDING: 'priceDescending',
  ALPHABETICAL: 'alphabetical',
});

export const SalesInvitationTypes = Object.freeze({
  VERIFIED: 'verified',
  RESERVE_NOW: 'reserveNow'
});
