import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import useFetchCommunities from '../../../../../../../hooks/useFetchCommunities';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';
import ShowMoreContent from '../../../../../../common/ShowMoreContent';
import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.components.quickPossessionFiltersModal.communityFilter.index' };

const CommunityFilter = ({ filters, onChange }) => {
  const communities = filters.communities || [];
  const { communities: availableCommunities, loading } = useFetchCommunities();

  const onToggle = (selectedItem) => {
    onChange({ communities: _.xorWith(communities, [selectedItem], _.isEqual) });
  };

  const filtersCount = useMemo(() => communities.length, [filters]);

  if (loading || !availableCommunities.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableCommunities}
        renderItem={(community) => (
          <ListItem
            key={`community-${community.id}`}
            item={community}
            selectedItems={communities}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

CommunityFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

CommunityFilter.defaultProps = {
  onChange: () => {}
};

export default CommunityFilter;
