import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';
import { connect } from 'react-redux';

import CollapsibleFilterGroup from '../../../../../common/CollapsibleFilterGroup';
import RegionItem from './RegionItem';
import { fetchContactRegionsAsync } from './utils';
import ShowMoreContent from '../../../../../common/ShowMoreContent';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.regionFilter.index' };

const RegionFilter = ({ filters, onChange, currentCompany }) => {
  const regions = filters.regions || [];
  const [availableRegions, setAvailableRegions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ regions: _.xor(regions, [value]) });
  };

  const loadRegions = () => {
    setLoading(true);

    fetchContactRegionsAsync(currentCompany.id)
      .then((items) => setAvailableRegions(items))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadRegions();
  }, []);

  const filtersCount = useMemo(() => regions.length, [filters]);

  if (loading || !availableRegions.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableRegions}
        renderItem={(region) => (
          <RegionItem
            key={`region-${region}`}
            value={region}
            selectedValues={regions}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

RegionFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

RegionFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}))(RegionFilter);
