import i18n from 'i18n-js';

import {
  ProjectBuildingAvailabilities, ProjectBuildingStatuses
} from '../constants';

const ProjectBuildingAvailabilityLabels = {
  [ProjectBuildingAvailabilities.AVAILABLE]: i18n.t('projectBuildingAvailabilities.available'),
  [ProjectBuildingAvailabilities.COMING_SOON]: i18n.t('projectBuildingAvailabilities.comingSoon')
};

const ProjectBuildingStatusLabels = {
  [ProjectBuildingStatuses.DRAFT]: i18n.t('statuses.draft'),
  [ProjectBuildingStatuses.PUBLISHED]: i18n.t('statuses.published'),
};

export function getProjectBuildingStatusName(status) {
  return ProjectBuildingStatusLabels[status];
}

export function getProjectBuildingAvailabilityName(availability) {
  return ProjectBuildingAvailabilityLabels[availability];
}
