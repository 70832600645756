import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';
import i18n from 'i18n-js';
import { Card } from 'reactstrap';

import { LotMapProviders } from '../../../../../../constants';
import lotWorkImg from '../../../../../../assets/icons/lot-map-providers/lot-works.jpeg';
import focus360Img from '../../../../../../assets/icons/lot-map-providers/focus-360.png';
import streetscapeImg from '../../../../../../assets/icons/lot-map-providers/streetscape.png';
import humanizeBoolean from '../../../../../../utils/boolUtils';
import { parseBoolean } from '../../../../../../utils/parserUtils';

const i18nOpts = { scope: 'components.admin.communities.show.overview.lotMapSettings.index' };

const LotMapSettings = ({ community }) => {
  const { lotMapSettings } = community;

  if (!community.enableLotMapIntegration || v.isBlank(lotMapSettings?.provider)) return null;

  const displaySpecHomesOption = humanizeBoolean(lotMapSettings?.lotWorks?.displaySpecHomes);

  return (
    <div className="mb-4">
      <div className="label">{i18n.t('title', i18nOpts)}</div>
      <Card body className="py-3 mt-1 d-inline-block mb-0" style={{ minWidth: '300px' }}>
        <div>
          <div className="label">{i18n.t('provider', i18nOpts)}</div>
          <div>
            {lotMapSettings.provider === LotMapProviders.LOT_WORKS && (
              <img src={lotWorkImg} alt="" width="20" />
            )}
            {lotMapSettings.provider === LotMapProviders.FOCUS_360 && (
              <img src={focus360Img} alt="" width="20" />
            )}
            {lotMapSettings.provider === LotMapProviders.STREETSCAPE && (
              <img src={streetscapeImg} alt="" width="20" />
            )}
            <strong className="ml-2">{i18n.t(`lotMapProviders.${v.camelCase(lotMapSettings.provider)}`)}</strong>
          </div>
        </div>

        {lotMapSettings.provider === LotMapProviders.LOT_WORKS
          && !v.isBlank(lotMapSettings.lotWorks?.mapUrl) && (
          <>
            <div className="mt-4">
              <div className="label">{i18n.t('lotWorks.mapUrl', i18nOpts)}</div>
              <div><strong>{lotMapSettings.lotWorks.mapUrl}</strong></div>
            </div>

            {!v.isBlank(lotMapSettings.lotWorks.communityId) && (
              <div className="mt-4">
                <div className="label">{i18n.t('lotWorks.communityId', i18nOpts)}</div>
                <div><strong>{lotMapSettings.lotWorks.communityId}</strong></div>
              </div>
            )}

            <div className="mt-4">
              <div className="label">{i18n.t('lotWorks.displaySpecHomes', i18nOpts)}</div>
              <div>
                <strong>
                  {i18n.t(`lotWorks.displaySpecHomesOptions.${displaySpecHomesOption}`, i18nOpts)}
                </strong>
              </div>
            </div>
          </>
        )}

        {lotMapSettings.provider === LotMapProviders.FOCUS_360
          && !v.isBlank(lotMapSettings.focus360?.communityId) && (
          <div className="mt-4">
            <div className="label">{i18n.t('focus360.communityId', i18nOpts)}</div>
            <div><strong>{lotMapSettings.focus360.communityId}</strong></div>
          </div>
        )}

        {lotMapSettings.provider === LotMapProviders.STREETSCAPE && (
          <>
            {!v.isBlank(lotMapSettings.streetscape?.propertyId) && (
              <div className="mt-4">
                <div className="label">{i18n.t('streetscape.propertyId', i18nOpts)}</div>
                <div><strong>{lotMapSettings.streetscape.propertyId}</strong></div>
              </div>
            )}

            {!v.isBlank(lotMapSettings.streetscape?.builderId) && (
              <div className="mt-4">
                <div className="label">{i18n.t('streetscape.builderId', i18nOpts)}</div>
                <div><strong>{lotMapSettings.streetscape.builderId}</strong></div>
              </div>
            )}

            {lotMapSettings.streetscape?.mapKeys?.length > 0 && (
              <div className="mt-4">
                <div className="label">{i18n.t('streetscape.mapKeys', i18nOpts)}</div>
                <div><strong>{lotMapSettings.streetscape.mapKeys.join(', ')}</strong></div>
              </div>
            )}

            <div className="mt-4">
              <div className="label">{i18n.t('streetscape.hideLotPrice', i18nOpts)}</div>
              <div>
                <strong>
                  {parseBoolean(lotMapSettings.streetscape.hideLotPrice) ? i18n.t('buttons.yes') : i18n.t('buttons.no')}
                </strong>
              </div>
            </div>

            <div className="mt-4">
              <div className="label">{i18n.t('streetscape.displaySpecHomes', i18nOpts)}</div>
              <div>
                <strong>
                  {parseBoolean(lotMapSettings.streetscape.displaySpecHomes) ? i18n.t('buttons.yes') : i18n.t('buttons.no')}
                </strong>
              </div>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

LotMapSettings.propTypes = {
  community: PropTypes.objectOf(PropTypes.any).isRequired
};

LotMapSettings.defaultProps = {};

export default LotMapSettings;
