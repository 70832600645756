import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  Row, Card, CardHeader, CardBody, Col
} from 'reactstrap';

import TimeLineElement from '../../../../common/TimeLineElement';
import CardField from '../components/CardField';
import { getPayloadAddress } from '../../../../../utils/tourUtils';
import TourIcon from '../../../../../assets/icons/customer-activities/TourIcon';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.tour.tour.index' };

const Tour = ({ activity, innerKey }) => {
  const { tour } = activity;
  const {
    rawData: { tour: payloadTour },
  } = tour;

  const Field = ({ name, isDate = false }) => (
    <CardField
      attr={name}
      label={i18n.t(name, i18nOpts)}
      instance={payloadTour}
      isDate={isDate}
    />
  );

  const parsedAddress = getPayloadAddress(payloadTour);

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<TourIcon />}
    >
      <Card>
        <CardHeader>
          <h4>{i18n.t(`types.${tour.type.toLowerCase()}`, i18nOpts)}</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Field name="status" />
            <Field name="date" isDate />
            <Field name="registrationDate" isDate />
            <Field name="scheduledBy" />
            <Field name="division" />
            <Field name="community" />
            <Field name="plan" />
            <Field name="homesite" />
            <Col sm="12" lg="6">
              <div className="mb-3">
                <div className="label">{i18n.t('address', i18nOpts)}</div>
                <div>
                  <strong>{parsedAddress}</strong>
                </div>
              </div>
            </Col>
            <Field name="tourReason" />
          </Row>
        </CardBody>
      </Card>
    </TimeLineElement>
  );
};

Tour.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired,
};

export default Tour;
