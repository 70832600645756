import React from 'react';

const BedIcon = ({ ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M0.600002 11.04C0.430002 11.04 0.287402 10.9709 0.172202 10.8326C0.057002 10.6944 -0.000397924 10.5235 2.07613e-06 10.32V6.71999C2.07613e-06 6.39599 0.0550021 6.10199 0.165002 5.83799C0.275002 5.57399 0.420002 5.33999 0.600002 5.13599V3.11999C0.600002 2.51999 0.775002 2.00999 1.125 1.58999C1.475 1.16999 1.9 0.959991 2.4 0.959991H4.8C5.03 0.959991 5.245 1.01111 5.445 1.11335C5.645 1.21559 5.83 1.35647 6 1.53599C6.17 1.35599 6.355 1.21511 6.555 1.11335C6.755 1.01159 6.97 0.960471 7.2 0.959991H9.6C10.1 0.959991 10.525 1.16999 10.875 1.58999C11.225 2.00999 11.4 2.51999 11.4 3.11999V5.13599C11.58 5.33999 11.725 5.57399 11.835 5.83799C11.945 6.10199 12 6.39599 12 6.71999V10.32C12 10.524 11.9424 10.6951 11.8272 10.8334C11.712 10.9716 11.5696 11.0405 11.4 11.04C11.23 11.04 11.0874 10.9709 10.9722 10.8326C10.857 10.6944 10.7996 10.5235 10.8 10.32V9.59999H1.2V10.32C1.2 10.524 1.1424 10.6951 1.0272 10.8334C0.912002 10.9716 0.769602 11.0405 0.600002 11.04ZM6.6 4.55999H10.2V3.11999C10.2 2.91599 10.1424 2.74487 10.0272 2.60663C9.912 2.46839 9.7696 2.39951 9.6 2.39999H7.2C7.03 2.39999 6.8874 2.46911 6.7722 2.60735C6.657 2.74559 6.5996 2.91647 6.6 3.11999V4.55999ZM1.8 4.55999H5.4V3.11999C5.4 2.91599 5.3424 2.74487 5.2272 2.60663C5.112 2.46839 4.9696 2.39951 4.8 2.39999H2.4C2.23 2.39999 2.0874 2.46911 1.9722 2.60735C1.857 2.74559 1.7996 2.91647 1.8 3.11999V4.55999ZM1.2 8.15999H10.8V6.71999C10.8 6.51599 10.7424 6.34487 10.6272 6.20663C10.512 6.06839 10.3696 5.99951 10.2 5.99999H1.8C1.63 5.99999 1.4874 6.06911 1.3722 6.20735C1.257 6.34559 1.1996 6.51647 1.2 6.71999V8.15999Z" fill="#475467" />
    </g>
  </svg>
);

BedIcon.propTypes = {};

BedIcon.defaultProps = {};

export default BedIcon;
