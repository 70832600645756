export const importDataFromSpreadsheetQuery = `
  mutation ImportDataFromSpreadsheet(
  $input: ImportDataInput!
  $file: Upload
){
  item: importDataFromSpreadsheet(input: $input, file: $file) {
    version
  }
}
`;

export const createSpreadsheetQuery = `
  mutation CreateCompanySpreadsheet($companyId: Int!) {
    item: createSpreadsheet(companyId: $companyId) {
      downloadUrl
    }
  }
`;

export const getImportTemplateQuery = `
  query GetImportTemplate($companyId: Int!) {
    item: getImportTemplate(companyId: $companyId) {
      templateUrl
    }
  }
`;
