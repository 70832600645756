import React, { useRef, useState } from 'react';
import { Button, Input, Spinner } from 'reactstrap';
import { MdDownload, MdUpload } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from 'i18n-js';

import { WorkflowTypes } from '../../../../../../../../../constants';
import {
  downloadConfigFile,
  getWorkflowFilename,
  sanitizeWorkflowToDownload,
  validateCompanyWorkflowAsync
} from './utils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.workflow.workflows.components.configFile.index' };

const ConfigFile = ({ workflowType, workflow, onUploadWorkflow }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const onUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);

    const variables = { type: workflowType, file };
    validateCompanyWorkflowAsync(variables)
      .then((newWorkflow) => onUploadWorkflow(newWorkflow))
      .catch(() => {
        toast.error(i18n.t('errors.notSaved', i18nOpts));
      })
      .finally(() => {
        clearFileInput();
        setLoading(false);
      });
  };

  const onDownload = () => {
    const downloadableFileUrl = generateDownloadableFileUrl();
    downloadConfigFile(workflowType, downloadableFileUrl);
  };

  const generateDownloadableFileUrl = () => {
    const sanitizedWorkflow = sanitizeWorkflowToDownload(workflowType, workflow);

    const file = new Blob([JSON.stringify(sanitizedWorkflow, null, 2)], { type: 'application/json' });
    return URL.createObjectURL(file);
  };

  const clearFileInput = () => {
    formRef.current.reset();
  };

  const workflowSampleFilename = getWorkflowFilename(workflowType, true);

  return (
    <div className="my-4">
      <div className="font-weight-500 mb-2">{i18n.t('title', i18nOpts)}</div>

      <div className="d-flex align-items-center flex-wrap gap-3">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor={loading ? '' : `file-${workflowType}`}
          className={classNames('btn btn-primary btn-sm mb-0', { disabled: loading })}
        >
          {loading ? (
            <Spinner size="sm" className="mr-2" />
          ) : (
            <MdUpload size={18} className="mr-2" />
          )}
          {i18n.t('buttons.upload')}
        </label>

        <Button color="secondary" outline size="sm" onClick={onDownload}>
          <MdDownload size={18} className="mr-2" />
          {i18n.t('buttons.download')}
        </Button>

        <a href={`/${workflowSampleFilename}`} download={workflowSampleFilename} className="text-decoration-underline ml-auto">
          {i18n.t('buttons.downloadSample')}
        </a>
      </div>

      <form ref={formRef}>
        <Input
          type="file"
          name="file"
          id={`file-${workflowType}`}
          accept="application/JSON"
          onChange={onUpload}
          className="d-none"
        />
      </form>
    </div>
  );
};

ConfigFile.propTypes = {
  workflowType: PropTypes.oneOf(Object.values(WorkflowTypes)).isRequired,
  workflow: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onUploadWorkflow: PropTypes.func
};

ConfigFile.defaultProps = {
  workflow: [],
  onUploadWorkflow: () => {}
};

export default ConfigFile;
