import React, { useEffect } from 'react';
import {
  ModalHeader, Modal, ModalBody, ModalFooter, Button
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import ModalCloseButton from '../../../../../common/ModalCloseButton';
import { ModalNames } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import { setOfferFilters } from '../../../../../../store/actions/offerActions';
import CompletedDealStatusFilter from './CompletedDealStatusFilter';
import useFilters from '../../../../../../hooks/useFilters';
import CommunityProjectFilter from './CommunityProjectFilter';
import DealPriceFilter from './DealPriceFilter';
import RealtorFilter from './RealtorFilter';

const i18nOpts = { scope: 'components.admin.sales.components.dealList.filtersModal.index' };

function closeModal() {
  ModalService.close(ModalNames.DEAL_FILTERS);
}

const FiltersModal = ({ opened, selectedFilters, ...props }) => {
  const {
    filters, changeFilters, setFilters, setInitialFilters, clearFilters, cancelFilters
  } = useFilters({});

  const onReset = () => {
    props.setOfferFilters({});
    clearFilters();
    closeModal();
  };

  const onCancel = () => {
    cancelFilters();
    closeModal();
  };

  const onApply = () => {
    props.setOfferFilters(filters);
    closeModal();
  };

  useEffect(() => {
    if (!opened) return;

    setFilters(selectedFilters);
    setInitialFilters(selectedFilters);
  }, [opened]);

  return (
    <Modal isOpen={opened} scrollable modalClassName="right">
      <ModalHeader close={<ModalCloseButton onClick={closeModal} />}>
        {i18n.t('title', i18nOpts)}
      </ModalHeader>
      <ModalBody className="pt-0">
        <div className="mb-3">{i18n.t('description', i18nOpts)}</div>

        <CompletedDealStatusFilter filters={filters} onChange={changeFilters} />
        <DealPriceFilter filters={filters} onChange={changeFilters} />
        <CommunityProjectFilter filters={filters} onChange={changeFilters} />
        <RealtorFilter filters={filters} onChange={changeFilters} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline className="mr-auto" onClick={onReset}>
          {i18n.t('buttons.reset')}
        </Button>

        <Button color="secondary" outline className="mr-3" onClick={onCancel}>
          {i18n.t('buttons.cancel')}
        </Button>
        <Button color="primary" onClick={onApply}>
          {i18n.t('buttons.apply')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FiltersModal.propTypes = {};

FiltersModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.DEAL_FILTERS]?.opened || false,
  selectedFilters: store.offers.filters
}), {
  setOfferFilters
})(FiltersModal);
