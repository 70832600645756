import React from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';

import { confirmDelete } from '../confirmAlert';

const ActionsMenu = ({
  item, onDelete, onEdit, children, deleteText, deleteMessage, Icon
}) => (
  <UncontrolledDropdown>
    <DropdownToggle tag="a">
      {Icon ? (
        <>{ Icon }</>
      ) : (
        <i className="fas fa-ellipsis-v text-primary" />
      )}
    </DropdownToggle>
    <DropdownMenu right>
      { children }
      { onEdit && (
      <DropdownItem onClick={() => onEdit(item)}>
        {i18n.t('buttons.edit')}
      </DropdownItem>
      )}
      { onDelete && (
      <DropdownItem className="text-danger" onClick={() => confirmDelete(() => onDelete(item.id), null, deleteMessage)}>
        {deleteText || i18n.t('buttons.delete')}
      </DropdownItem>
      )}
    </DropdownMenu>
  </UncontrolledDropdown>
);

ActionsMenu.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  deleteText: PropTypes.string,
  deleteMessage: PropTypes.string,
  Icon: PropTypes.element
};

ActionsMenu.defaultProps = {
  onDelete: undefined,
  onEdit: undefined,
  deleteText: null,
  deleteMessage: null,
  Icon: null
};

export default ActionsMenu;
