import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Card, Spinner, Table } from 'reactstrap';
import momentTz from 'moment-timezone';

import { fetchMasterAdminMetricsByBuilder } from './utils';
import ListEmpty from '../../../common/ListEmpty';
import { SortOrders } from '../../../../constants';
import setSortFilter from '../../../../utils/sortUtils';
import SortableColumnHeader from '../../../common/SortableColumnHeader';
import SecureStore from '../../../../utils/secureStore';

const i18nOpts = { scope: 'components.admin.masterAdminDashboard.visitorLeadsTable.index' };

const DEFAULT_SORT = { field: 'companyName', direction: SortOrders.ASC };

const VisitorLeadsTable = ({ customDateRange }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(DEFAULT_SORT);

  const loadMetrics = () => {
    setLoading(true);

    const timezone = momentTz.tz.guess();
    const filter = {
      customDateRange,
      timezone
    };
    const variables = { filter, sort };
    fetchMasterAdminMetricsByBuilder(variables)
      .then((items) => setData(items))
      .finally(() => setLoading(false));
  };

  const onCompanyClick = (company) => {
    SecureStore.setCurrentCompanyId(company.id);
    window.location = '/admin/overview';
  };

  const onSortChange = (field) => {
    const sortFilter = setSortFilter(field, sort);
    setSort(sortFilter);
  };

  useEffect(() => {
    loadMetrics();
  }, [customDateRange, sort]);

  return (
    <div className="mb-4">
      <Card body>
        <h4 className="mb-4">{i18n.t('title', i18nOpts)}</h4>

        {loading ? (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        ) : (
          <>
            <ListEmpty loading={loading} items={data} />

            {data.length > 0 && (
              <Table responsive bordered className="mt-2">
                <thead>
                  <tr>
                    <SortableColumnHeader
                      field="companyName"
                      label={i18n.t('builder', i18nOpts)}
                      onPress={() => onSortChange('companyName')}
                      sort={sort}
                    />
                    <SortableColumnHeader
                      field="visitorsCount"
                      label={i18n.t('visitors', i18nOpts)}
                      onPress={() => onSortChange('visitorsCount')}
                      sort={sort}
                    />
                    <SortableColumnHeader
                      field="capturedLeadsCount"
                      label={i18n.t('capturedLeads', i18nOpts)}
                      onPress={() => onSortChange('capturedLeadsCount')}
                      sort={sort}
                    />
                    <SortableColumnHeader
                      field="idVerifiedCount"
                      label={i18n.t('idVerified', i18nOpts)}
                      onPress={() => onSortChange('idVerifiedCount')}
                      sort={sort}
                    />
                    <SortableColumnHeader
                      field="preApprovalsCount"
                      label={i18n.t('preApproval', i18nOpts)}
                      onPress={() => onSortChange('preApprovalsCount')}
                      sort={sort}
                    />
                    <SortableColumnHeader
                      field="fullyVerifiedCount"
                      label={i18n.t('fullyVerified', i18nOpts)}
                      onPress={() => onSortChange('fullyVerifiedCount')}
                      sort={sort}
                    />
                  </tr>
                </thead>

                <tbody>
                  {data.map((d) => (
                    <tr key={`visitor-leads-${d.company?.id}`}>
                      <td>
                        <div
                          role="button"
                          aria-hidden
                          className="col-primary-1 text-decoration-underline"
                          onClick={() => onCompanyClick(d.company)}
                        >
                          {d.company.name}
                        </div>
                      </td>
                      <td>{d.visitorsCount}</td>
                      <td>{d.capturedLeadsCount}</td>
                      <td>{d.idVerifiedCount}</td>
                      <td>{d.preApprovalsCount}</td>
                      <td>{d.fullyVerifiedCount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

VisitorLeadsTable.propTypes = {
  customDateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

VisitorLeadsTable.defaultProps = {};

export default VisitorLeadsTable;
