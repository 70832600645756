import React, { useEffect, useMemo, useState } from 'react';
import {
  ModalHeader, Modal, ModalBody, Card, CardHeader, CardBody,
  Row, Col, ModalFooter, Button
} from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import _ from 'lodash';
import { ReactSortable } from 'react-sortablejs';

import './styles.scss';
import { ModalNames } from '../../../../../constants';
import { CustomerColumns, getValidColumns } from '../utils';
import SelectedColumnItem from './SelectedColumnItem';
import AvailableColumnItem from './AvailableColumnItem';
import { getSortableColumns } from './utils';
import { setCustomerColumns } from '../../../../../store/actions/customerActions';
import ModalService from '../../../../../services/ModalService';
import ModalCloseButton from '../../../../common/ModalCloseButton';
import { saveUser, getProfile } from '../../../../../store/actions/userActions';

const i18nOpts = { scope: 'components.admin.customers.list.columnsPickerModal.index' };

function closeModal() {
  ModalService.close(ModalNames.CUSTOMER_COLUMNS_PICKER);
}

const ColumnsPickerModal = ({
  selectedColumns, opened, currentCompany, currentUser, ...props
}) => {
  const [columns, setColumns] = useState(getValidColumns(selectedColumns, currentCompany));

  const selectableColumns = useMemo(
    () => getValidColumns(Object.values(CustomerColumns), currentCompany),
    [currentCompany]
  );

  const onColumnToggle = (event) => {
    const { value } = event.target;
    const newColumns = _.xor(columns, [value]);
    setColumns(newColumns);
  };

  const setSortedColumns = (newColumns) => {
    const newSortedColumns = newColumns.map((c) => c.id);
    setColumns(newSortedColumns);
  };

  const onApply = () => {
    closeModal();
    const validSelectedColumns = columns
      .filter((column) => selectableColumns.includes(column));
    props.setCustomerColumns(validSelectedColumns);
    saveColumnSettings(validSelectedColumns);
  };

  const saveColumnSettings = (customerColumns) => {
    const input = { id: currentUser.id, columnSettings: { customers: customerColumns } };
    props.saveUser({ input })
      .then(() => props.getProfile())
      .catch(() => {});
  };

  useEffect(() => {
    setColumns(getValidColumns(selectedColumns, currentCompany));
  }, [selectedColumns]);

  const sortedColumns = useMemo(() => getSortableColumns(columns), [columns]);

  return (
    <Modal isOpen={opened} modalClassName="right" scrollable>
      <ModalHeader close={<ModalCloseButton onClick={closeModal} />}>{i18n.t('title', i18nOpts)}</ModalHeader>
      <ModalBody className="pt-0">
        <div>{i18n.t('description', i18nOpts)}</div>

        <Row className="mt-4">
          <Col xs="12">
            <Card className="card-customer-columns">
              <CardHeader className="font-12 font-weight-500 py-2">
                {i18n.t('editColumnOrder', i18nOpts)}
              </CardHeader>
              <CardBody>
                <ReactSortable
                  className="sortable-customer-columns"
                  list={sortedColumns}
                  setList={setSortedColumns}
                >
                  {sortedColumns.map((column) => (
                    <SelectedColumnItem key={`selected-column-${column.id}`} column={column} />
                  ))}
                </ReactSortable>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" className="pb-3">
            <div className="font-weight-500 col-gray-700">{i18n.t('availableColumnsTitle', i18nOpts)}</div>
            <div>{i18n.t('availableColumnsDescription', i18nOpts)}</div>
          </Col>
          <Col xs="12">
            <Card className="card-customer-columns">
              <CardHeader className="font-12 font-weight-500 py-2">
                {i18n.t('selectColumns', i18nOpts)}
              </CardHeader>
              <CardBody>
                {
                  selectableColumns.map((customerColumn) => (
                    <AvailableColumnItem
                      key={`available-column-${customerColumn}`}
                      column={customerColumn}
                      selectedColumns={columns}
                      onChange={onColumnToggle}
                    />
                  ))
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline className="mr-3" onClick={closeModal}>
          {i18n.t('buttons.cancel')}
        </Button>
        <Button color="primary" onClick={onApply}>
          {i18n.t('buttons.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ColumnsPickerModal.propTypes = {};

ColumnsPickerModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.CUSTOMER_COLUMNS_PICKER]?.opened || false,
  currentCompany: store.companies.currentCompany,
  selectedColumns: store.customers.columns,
  currentUser: store.users.currentUser
}), {
  setCustomerColumns,
  saveUser,
  getProfile
})(ColumnsPickerModal);
