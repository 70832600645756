import { paginationFragment } from './fragments/pagination';
import { floorFragment } from './floors';

const fragment = `
  id
  status
  availability
  address
  description
  totalPrice
  url
  squareFootage
  hashId
  customPrice
  customBeds
  customBaths
  imageUrl
  enablePossessionDate
  possessionDate
  lotId
  externalId
  community {
    id
    name
    imageUrl
    lotMapSettings {
      provider
    }
  }
  elevation {
    id
    name
    description
    isDefault
    imageUrl
  }
  palette {
    id
    name
    description
    defaultImageUrl
  }
  model {
    id
    name
    intro
    beds
    baths
    sizeSqft
    basePrice
    imageUrl
    defaultImageUrl
    tourUrl
    hashId
    status
    openTourUrlInOverlayWindow
    minLotPrice
    uTourUrl
    lotWorksSettings {
      frontage {
        min
        max
      }
      buildpocket {
        min
        max
      }
      minLotPrice
    }
    communities {
      id
      name
      imageUrl
    }
    productType {
      id
      name
    }
    elevations {
      id
      name
      description
      isDefault
      imageUrl
    }
    specifications {
      id
      name
    }
    palettes {
      id
      name
      description
      defaultImageUrl
      images {
        id
        isDefault
        imageUrl
        palette {
          id
          name
        }
      }
    }
    floors @include(if: $withModelFloors) {
      id
      name
      imageUrl
      size
      bedrooms
      bathrooms
      isBelowGrade
      floorGroups {
        id
        name
        enableMultipleSelection
        floorOptions {
          id
          name
          imageUrl
          priceChange
          sizeChange
          bedsChange
          bathsChange
          description
          alternateImageUrl
          alternateOption
          isDefault
          imagesCount
          priceRulesCount
          lotWorksSettings
          availabilityRule
          availabilityCommunities
          availabilityCommunityPhases
          dependencies {
            group
            operator
            values
          }
        }
      }
    }
    modelPackages {
      id
      isDefault
      package {
        id
        name
        description
        imageUrl
        basePrice
      }
    }
    priceRules {
      id
      price
      externalId
      community {
        id
        name
      }
      communityPhase {
        id
        phase
      }
      priceableId
      priceableType
    }
  }
  package {
    id
    name
    description
    imageUrl
    basePrice
  }
  floorOptions {
    id
    name
    imageUrl
    priceChange
    sizeChange
    bedsChange
    bathsChange
    description
    isDefault
    imagesCount
    priceRulesCount
    lotWorksSettings
    availabilityRule
    availabilityCommunities
    availabilityCommunityPhases
    dependencies {
      group
      operator
      values
    }
    floorGroup {
      id
    }
  }
  specifications {
    id
    name
  }  
  requests {
    id
    createdAt
    contact {
      email
      phone
      name
    }
  }  
  lastModifiedLog {
    id
    initialValue
    finalValue
    maker {
      id
      name
      role
    }
  }
  creator {
    id
    name
    role
  }
  logModifications @include(if: $withModificationLogs) {
    id
    initialValue
    finalValue
    actionDate
    maker {
      id
      name
      role
    }
  },
  editableFloors {
    id
  }
  editableFloorGroups {
    id
  }
  overrideFloors
  floors {
    ${floorFragment}
  }
  requiredDepositAmount
  stage
  createdAt
  updatedAt
`;

export const listQuickPossessionsQuery = `
  query ListQuickPossessions(
    $filter: QuickPossessionFilterInput
    $sort: SortInput
    $page: Int
    $pageSize: Int
    $withModelFloors: Boolean! = false
    $withModificationLogs: Boolean! = false
  ) {
    result: listQuickPossessions(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
      items {
        ${fragment}
      }
      pagination {
        ${paginationFragment}
      }
    }
  }
`;

export const getQuickPossessionQuery = `
  query GetQuickPossession(
    $id: Int!
    $withModelFloors: Boolean! = true
    $withModificationLogs: Boolean! = true
  ) {
    item: getQuickPossession(id: $id) {
      ${fragment}
    }
  }
`;

export const createQuickPossessionQuery = `
  mutation CreatePossession(
    $input: CreateQuickPossessionInput!
    $image: Upload
    $withModelFloors: Boolean! = true
    $withModificationLogs: Boolean! = true
  ) {
    item: createQuickPossession(input: $input, image: $image) {
      ${fragment}
    }
  }
`;

export const updateQuickPossessionQuery = `
  mutation UpdateQuickPossession(
    $input: UpdateQuickPossessionInput!
    $image: Upload
    $withModelFloors: Boolean! = true
    $withModificationLogs: Boolean! = true
  ) {
    item: updateQuickPossession(input: $input, image: $image) {
      ${fragment}
    }
  }
`;

export const deleteQuickPossessionQuery = `
  mutation DeleteQuickPossession(
    $id: Int!
    $withModelFloors: Boolean! = true
    $withModificationLogs: Boolean! = true
  ) {
    item: deleteQuickPossession(id: $id) {
      ${fragment}
    }
  }
`;

export const validateQuickPossessionMutation = `
  mutation ValidateQuickPossession(
    $input: CreateQuickPossessionInput!
  ) {
    validated: validateQuickPossession(input: $input)
  }
`;

export const listInvitableHomesQuery = `
  query ListInvitableHomes(
    $filter: InvitableHomeFilterInput!
    $sort: SortInput,
    $page: Int,
    $pageSize: Int
  ) {
    result: listInvitableHomes(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
      items {
        __typename
        ... on QuickPossession {
          id
          status
          availability
          address
          totalPrice
          url
          squareFootage
          hashId
          customPrice
          customBeds
          customBaths
          imageUrl
          url
          createdAt
          community {
            id
            name
            imageUrl
          }
          model {
            id
            name
            intro
            beds
            baths
            sizeSqft
            basePrice
            imageUrl
            defaultImageUrl
          }
        }
        ... on Unit {
          id
          name
          price
          imageUrl
          defaultImageUrl
          hashId
          createdAt
          buildingModel {
            id
            name
            imageUrl
            bedrooms
            bathrooms
            size
            project {
              id
              name
              address
            }
          }
        }
      }
      pagination {
        ${paginationFragment}
      }
    }
  }
`;

export const listUniqueQuickPossessionsTotalBedsQuery = `
  query ListUniqueQuickPossessionsTotalBeds($companyId: Int!) {
    result: listUniqueQuickPossessionsTotalBeds(companyId: $companyId)
  }
`;

export const listUniqueQuickPossessionsTotalBathsQuery = `
  query ListUniqueQuickPossessionsTotalBaths($companyId: Int!) {
    result: listUniqueQuickPossessionsTotalBaths(companyId: $companyId)
  }
`;
