import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import styles from './ViewModesDropdown.module.scss';
import { ViewModes } from '../../../constants';

const i18nOpts = { scope: 'components.global.viewModesDropdown.index' };

const ViewModesDropdown = ({ viewMode, onChange, alignDirection }) => (
  <UncontrolledDropdown>
    <DropdownToggle color="outline-secondary" size="sm" caret className="text-nowrap">
      {viewMode === ViewModes.LIST ? (
        <i className="fas fa-list mr-2" />
      ) : (
        <i className="fas fa-th-large mr-2" />
      )}
      {i18n.t(viewMode.toLowerCase(), i18nOpts)}
    </DropdownToggle>
    <DropdownMenu className={styles.dropDownMenu} {...(alignDirection === 'right' && { right: true })}>
      <h6 className="dropdown-header">{i18n.t('viewAs', i18nOpts)}</h6>
      <DropdownItem onClick={() => onChange(ViewModes.GRID)}>
        <i className="fas fa-th-large" />
        <span className="ml-2">{i18n.t('grid', i18nOpts)}</span>
      </DropdownItem>
      <DropdownItem onClick={() => onChange(ViewModes.LIST)}>
        <i className="fas fa-list" />
        <span className="ml-2">{i18n.t('list', i18nOpts)}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

ViewModesDropdown.propTypes = {
  viewMode: PropTypes.string,
  onChange: PropTypes.func,
  alignDirection: PropTypes.oneOf(['left', 'right'])
};

ViewModesDropdown.defaultProps = {
  viewMode: null,
  onChange: () => {},
  alignDirection: 'right'
};

export default ViewModesDropdown;
