import React from 'react';
import i18n from 'i18n-js';
import { Button, Card } from 'reactstrap';

import CopyLink from '../../../../../common/CopyLink';

const i18nOpts = { scope: 'components.admin.integrations.tracking.trackingCode.javaScriptSdk.index' };

const pageTrackingScriptContent = `
const pageUrl = document.location.href;
const pageTitle = document.title;

Ownly.tracker.trackPageView(pageUrl, pageTitle);
`;

const formTrackingScriptContent = `
const formName = 'Contact Us';
const formData = [{
    "name": "firstName",
    "type": "text",
    "value": "John"
  }, {
    "name": "lastName",
    "type": "text",
    "value": "Doe"
  }, {
    "name": "emailAddress",
    "type": "email",
    "value": "john.doe@gmail.com"
  }, {
    "name": "phone",
    "type": "tel",
    "value": "4035089889"
  },
    ...
  ];
const pageUrl = document.location.href;

Ownly.tracker.trackFormSubmission(formName, formData, pageUrl);
`;

const JavaScriptSdk = () => (
  <div>
    <h2>{i18n.t('title', i18nOpts)}</h2>
    <p>{i18n.t('description', i18nOpts)}</p>

    <br />
    <h4>{i18n.t('pageTracking', i18nOpts)}</h4>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('pageTrackingDescription', i18nOpts) }} />

    <Card body className="d-block code-block-container">
      <pre>
        const pageUrl = document.location.href;
        <br />
        const pageTitle = document.title;
        <br />
        <br />
        Ownly.tracker.trackPageView(pageUrl, pageTitle);
      </pre>

      <div className="actions-container">
        <CopyLink text={pageTrackingScriptContent}>
          <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
        </CopyLink>
      </div>
    </Card>

    <br />
    <h4>{i18n.t('formTracking', i18nOpts)}</h4>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('formTrackingDescription', i18nOpts) }} />
    <div dangerouslySetInnerHTML={{ __html: i18n.t('formDataDescription', i18nOpts) }} />

    <Card body className="d-block code-block-container">
      <pre>
        const formName = &#39;Contact Us&#39;;
        <br />
        const formData = [&#123;
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;name&#34;: &#34;firstName&#34;,
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;type&#34;: &#34;text&#34;,
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;value&#34;: &#34;John&#34;
        <br />
          &nbsp;&nbsp;&#125;,&nbsp;&#123;
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;name&#34;: &#34;lastName&#34;,
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;type&#34;: &#34;text&#34;,
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;value&#34;: &#34;Doe&#34;
        <br />
          &nbsp;&nbsp;&#125;,&nbsp;&#123;
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;name&#34;: &#34;emailAddress&#34;,
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;type&#34;: &#34;email&#34;,
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;value&#34;: &#34;john.doe@gmail.com&#34;
        <br />
          &nbsp;&nbsp;&#125;,&nbsp;&#123;
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;name&#34;: &#34;phone&#34;,
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;type&#34;: &#34;tel&#34;,
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&#34;value&#34;: &#34;4035089889&#34;
        <br />
          &nbsp;&nbsp;&#125;,
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;...
        <br />
          &nbsp;&nbsp;];
        <br />
        const pageUrl = document.location.href;
        <br />
        <br />
        Ownly.tracker.trackFormSubmission(formName, formData, pageUrl);
      </pre>

      <div className="actions-container">
        <CopyLink text={formTrackingScriptContent}>
          <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
        </CopyLink>
      </div>
    </Card>
  </div>
);

JavaScriptSdk.propTypes = {};

JavaScriptSdk.defaultProps = {};

export default JavaScriptSdk;
