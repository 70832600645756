import React, { useEffect, useState } from 'react';
import { Progress, Collapse } from 'reactstrap';
import { BiErrorCircle, BiCheckCircle } from 'react-icons/bi';
import { connect } from 'react-redux';
import filesize from 'filesize';
import PropTypes from 'prop-types';

import './styles.scss';
import api from '../../../../api';
import { createDocumentQuery } from '../../../../graphql';
import { removeDocumentFile } from '../../../../store/actions/documentActions';
import FileTypeIcon from '../../FileTypeIcon';

const Statuses = Object.freeze({
  PENDING: 'pending',
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  COMPLETED: 'completed',
  ERROR: 'error'
});

function calculateSteps(file) {
  const kiloBytes = Math.ceil(file.size / 1000);
  const steps = Math.ceil(kiloBytes / 250); // Upload 200 KB each 500 ms
  const increment = 100 / steps;

  return { steps, increment };
}

function uploadFileAsync(file, companyId, extraAttributes) {
  const input = { companyId, byteSize: file.size, ...extraAttributes };

  const variables = { input, file };
  return api.graphql(createDocumentQuery, variables)
    .then(({ data: { item } }) => Promise.resolve(item));
}

function getProgressColor(status) {
  if (status === Statuses.UPLOADED) return 'success';
  if (status === Statuses.ERROR) return 'danger';
  return 'warning';
}

const DocumentFile = ({
  documentFile, completeDelay, onUploaded, excluded, currentCompany, ...props
}) => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(Statuses.PENDING);
  let uploadProgressInterval;
  const { file } = documentFile;
  const formattedSize = filesize(file.size);

  useEffect(() => {
    setStatus(Statuses.UPLOADING);

    uploadProgress((newProgress) => {
      setProgress(newProgress);
    });

    uploadFileAsync(file, currentCompany.id, { excluded })
      .then((item) => {
        setProgress(100);
        setStatus(Statuses.UPLOADED);
        onUploaded(item);
      })
      .catch(() => {
        setStatus(Statuses.ERROR);
      })
      .finally(() => {
        completeUploading();
      });

    return () => {
      clearInterval(uploadProgressInterval);
    };
  }, []);

  const uploadProgress = (onChange) => {
    const { steps, increment } = calculateSteps(file);

    let step = 1;
    uploadProgressInterval = setInterval(() => {
      if (step === steps) {
        clearInterval(uploadProgressInterval);
        return;
      }

      const newProgress = step * increment;
      step += 1;
      onChange(newProgress);
    }, 500);
  };

  const completeUploading = () => {
    clearInterval(uploadProgressInterval);

    setTimeout(() => setStatus(Statuses.COMPLETED), completeDelay);
    setTimeout(() => {
      props.removeDocumentFile(documentFile);
    }, completeDelay + 300);
  };

  const roundedProgress = Math.ceil(progress);
  const color = getProgressColor(status);
  const isOpen = [Statuses.UPLOADING, Statuses.UPLOADED, Statuses.ERROR].includes(status);

  return (
    <Collapse isOpen={isOpen}>
      <div className="document-file-container">
        <div className="d-flex align-items-center">
          <div className="mr-3">
            <FileTypeIcon filename={file.name} />
          </div>

          <div className="flex-grow-1 overflow-hidden">
            <div className="d-flex justify-content-between mb-1">
              <div className="pr-3 text-truncate">
                {file.name}
              </div>
              <div className="d-flex text-muted">
                {`${roundedProgress} %`}
                <span className="ml-4">{formattedSize}</span>
              </div>
            </div>
            <Progress
              animated={status === Statuses.UPLOADING}
              striped
              color={color}
              value={progress}
            />
          </div>

          <div className="text-right status-container">
            {status === Statuses.UPLOADED && (
              <BiCheckCircle size="2rem" className="text-success" />
            )}
            {status === Statuses.ERROR && (
              <BiErrorCircle size="2rem" className="text-danger" />
            )}
          </div>
        </div>
      </div>
    </Collapse>
  );
};

DocumentFile.propTypes = {
  completeDelay: PropTypes.number,
  onUploaded: PropTypes.func,
  excluded: PropTypes.bool
};

DocumentFile.defaultProps = {
  completeDelay: 5000, // 5 seconds
  onUploaded: () => {},
  excluded: false
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  removeDocumentFile
})(DocumentFile);
