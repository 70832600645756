import React from 'react';
import {
  TabContent, TabPane
} from 'reactstrap';
import { connect } from 'react-redux';

import Messages from './Messages';
import BackLink from '../components/BackLink';
import DetailsButton from '../components/DetailsButton';
import CustomerVerifiedStatusIcon from '../../../../global/customer/CustomerVerifiedStatusIcon';
import Activity from './Activity';
import NavTabs from './NavTabs';
import Documents from './Documents';
import useIsVerifiedEnabled from '../../../../../hooks/useIsVerifiedEnabled';
import Verification from './Verification';
import Deals from './Deals';
import { CustomerTabs } from '../../../../../constants';
import Interests from './Interests';

const Details = ({ customer, activeTab }) => {
  const isVerifiedEnabled = useIsVerifiedEnabled();

  return (
    <div>
      <div className="d-block d-md-none">
        <BackLink />

        <div className="d-flex align-items-center gap-3">
          <div className="font-24 font-weight-600 col-gray-900">{customer.name}</div>

          <CustomerVerifiedStatusIcon customer={customer} size="20" />

          <div className="d-block d-md-none ml-auto">
            <DetailsButton />
          </div>
        </div>
      </div>

      <NavTabs />

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={CustomerTabs.ACTIVITY}>
          <Activity />
        </TabPane>
        {isVerifiedEnabled && (
          <TabPane tabId={CustomerTabs.VERIFICATION}>
            <Verification />
          </TabPane>
        )}
        <TabPane tabId={CustomerTabs.DEALS}>
          <Deals />
        </TabPane>
        <TabPane tabId={CustomerTabs.INTERESTS}>
          <Interests />
        </TabPane>
        <TabPane tabId={CustomerTabs.MESSAGES}>
          <Messages />
        </TabPane>
        <TabPane tabId={CustomerTabs.DOCUMENTS}>
          <Documents />
        </TabPane>
      </TabContent>
    </div>
  );
};

Details.propTypes = {};

Details.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  activeTab: store.customers.activeTab,
}))(Details);
