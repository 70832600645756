import React from 'react';

import styles from '../styles';

const PropertyGroup = ({ showIf = true, children }) => {
  if (!showIf) {
    return <div style={styles.empty} />;
  }
  return (
    <div style={styles.propertyGroup}>
      {children}
    </div>
  );
};

export default PropertyGroup;
