import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { BiLinkAlt } from 'react-icons/bi';

import List from './List';
import Show from './Show';
import { getWebhook } from '../../../../../store/actions/webhookActions';
import { listWebhookDeliveries, cleanWebhookDeliveries, setWebhookDelivery } from '../../../../../store/actions/webhookDeliveryActions';
import IntegrationsBreadcrumbs from '../../../../global/IntegrationsBreadcrumbs';
import { WebhookDeliveryStatuses } from '../../../../../constants';
import ListEmpty from '../../../../common/ListEmpty';
import Loading from '../../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.integrations.webhooks.webhookDeliveries.index' };

const WebhookDeliveries = ({
  id, webhook, webhookDeliveries, loading, webhookDelivery,
  ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const webhookId = parseInt(id, 10);

  const loadWebhook = () => {
    props.getWebhook(webhookId)
      .catch(() => navigate('/admin/integrations/webhooks'));
  };

  const loadWebhookDeliveries = () => {
    const variables = {
      filter: {
        webhookId,
        status: [WebhookDeliveryStatuses.DELIVERED, WebhookDeliveryStatuses.FAILED]
      }
    };
    props.listWebhookDeliveries(variables)
      .finally(() => setMounted(true));
  };

  const setFirstWebhookDelivery = () => {
    const firstWebhookDelivery = webhookDeliveries[0];
    props.setWebhookDelivery(firstWebhookDelivery);
  };

  useEffect(() => {
    loadWebhook();

    props.cleanWebhookDeliveries()
      .then(() => loadWebhookDeliveries())
      .catch((e) => toast.error(e.message));
  }, []);

  useEffect(() => {
    setFirstWebhookDelivery();
  }, [webhookDeliveries]);

  if (!mounted) return <Loading loading fullScreen />;

  if (!webhook) return null;

  return (
    <div>
      <Helmet title={webhook.hookUrl} />

      <IntegrationsBreadcrumbs items={[{
        text: i18n.t('breadcrumb.webhooks', i18nOpts),
        url: '/admin/integrations/webhooks'
      }, { text: i18n.t('title', i18nOpts) }]}
      />

      <h2 className="mb-4 word-break-break-all">
        <BiLinkAlt className="mr-2" />
        {webhook.hookUrl}
      </h2>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={webhookDeliveries} />

          {webhookDeliveries.length > 0 && (
            <Row>
              <Col>
                <List />
              </Col>
              <Col>
                <Show />
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

WebhookDeliveries.propTypes = {};

WebhookDeliveries.defaultProps = {};

export default connect((store) => ({
  webhook: store.webhooks.webhook,
  webhookDeliveries: store.webhookDeliveries.webhookDeliveries,
  loading: store.webhookDeliveries.listWebhookDeliveries.loading || false,
  webhookDelivery: store.webhookDeliveries.webhookDelivery
}), {
  getWebhook,
  cleanWebhookDeliveries,
  listWebhookDeliveries,
  setWebhookDelivery
})(WebhookDeliveries);
