import {
  GET_COMMUNITY_PRODUCT_TYPE,
  GET_COMMUNITY_PRODUCT_TYPES,
  SAVE_COMMUNITY_PRODUCT_TYPE,
  DELETE_COMMUNITY_PRODUCT_TYPE, SORT_COMMUNITY_PRODUCT_TYPES, SET_COMMUNITY_PRODUCT_TYPES
} from './types';
import {
  createCommunityProductTypeQuery,
  deleteCommunityProductTypeQuery,
  getCommunityProductTypeQuery,
  listCommunityProductTypesQuery, sortCommunityProductTypesQuery,
  updateCommunityProductTypeQuery
} from '../../graphql';
import api from '../../api';

export function getCommunityProductTypes(variables = {}) {
  return {
    type: GET_COMMUNITY_PRODUCT_TYPES,
    payload: api.graphql(listCommunityProductTypesQuery, variables)
  };
}

export function getCommunityProductType(id) {
  return {
    type: GET_COMMUNITY_PRODUCT_TYPE,
    payload: api.graphql(getCommunityProductTypeQuery, { id })
  };
}

export function saveCommunityProductType(variables = {}) {
  const query = variables.input.id ? updateCommunityProductTypeQuery
    : createCommunityProductTypeQuery;
  return {
    type: SAVE_COMMUNITY_PRODUCT_TYPE,
    payload: api.graphql(query, variables)
  };
}

export function deleteCommunityProductType(id) {
  return {
    type: DELETE_COMMUNITY_PRODUCT_TYPE,
    payload: api.graphql(deleteCommunityProductTypeQuery, { id })
  };
}

export function setCommunityProductTypes(communityProductTypes) {
  return {
    type: SET_COMMUNITY_PRODUCT_TYPES,
    payload: { communityProductTypes }
  };
}

export function sortCommunityProductTypes(variables = {}) {
  return {
    type: SORT_COMMUNITY_PRODUCT_TYPES,
    payload: api.graphql(sortCommunityProductTypesQuery, variables)
  };
}
