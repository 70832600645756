import React, { useEffect } from 'react';
import {
  ModalHeader, Modal, ModalBody, Button, ModalFooter
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import './styles.scss';
import { ModalNames } from '../../../../../constants';
import { setCustomerColumns, setCustomerFilters } from '../../../../../store/actions/customerActions';
import ModalService from '../../../../../services/ModalService';
import InterestsFilter from './InterestsFilter';
import LastActiveFilter from './LastActiveFilter';
import RegionFilter from './RegionFilter';
import useFilters from '../../../../../hooks/useFilters';
import DatePickerModal from './components/DatePickerModal';
import SignUpDateFilter from './SignUpDateFilter';
import BuyingPowerFilter from './BuyingPowerFilter';
import RentingPowerFilter from './RentingPowerFilter';
import StatusFilter from './StatusFilter';
import VerificationProgressFilter from './VerificationProgressFilter';
import ReservationProgressFilter from './ReservationProgressFilter';
import AnnualIncomeFilter from './AnnualIncomeFilter';
import AssignedToFilter from './AssignedToFilter';
import ModalCloseButton from '../../../../common/ModalCloseButton';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.index' };

function closeModal() {
  ModalService.close(ModalNames.CUSTOMER_FILTERS);
}

const FiltersModal = ({ opened, selectedFilters, ...props }) => {
  const {
    filters, changeFilters, setFilters, setInitialFilters, clearFilters, cancelFilters
  } = useFilters({});

  const onReset = () => {
    props.setCustomerFilters({});
    clearFilters();
    closeModal();
  };

  const onCancel = () => {
    cancelFilters();
    closeModal();
  };

  const onApply = () => {
    props.setCustomerFilters(filters);
    closeModal();
  };

  useEffect(() => {
    if (!opened) return;

    setFilters(selectedFilters);
    setInitialFilters(selectedFilters);
  }, [opened]);

  return (
    <Modal isOpen={opened} scrollable modalClassName="right">
      <ModalHeader close={<ModalCloseButton onClick={closeModal} />}>
        {i18n.t('title', i18nOpts)}
      </ModalHeader>
      <ModalBody className="pt-0">
        <div className="mb-3">{i18n.t('description', i18nOpts)}</div>

        <ReservationProgressFilter filters={filters} onChange={changeFilters} />
        <VerificationProgressFilter filters={filters} onChange={changeFilters} />
        <StatusFilter filters={filters} onChange={changeFilters} />
        <InterestsFilter filters={filters} onChange={changeFilters} />
        <BuyingPowerFilter filters={filters} onChange={changeFilters} />
        <RentingPowerFilter filters={filters} onChange={changeFilters} />
        <AnnualIncomeFilter filters={filters} onChange={changeFilters} />
        <RegionFilter filters={filters} onChange={changeFilters} />
        <AssignedToFilter filters={filters} onChange={changeFilters} />
        <LastActiveFilter filters={filters} onChange={changeFilters} />
        <SignUpDateFilter filters={filters} onChange={changeFilters} />

        <DatePickerModal onChange={changeFilters} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline className="mr-auto" onClick={onReset}>
          {i18n.t('buttons.reset')}
        </Button>

        <Button color="secondary" outline className="mr-3" onClick={onCancel}>
          {i18n.t('buttons.cancel')}
        </Button>
        <Button color="primary" onClick={onApply}>
          {i18n.t('buttons.apply')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FiltersModal.propTypes = {};

FiltersModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.CUSTOMER_FILTERS]?.opened || false,
  currentCompany: store.companies.currentCompany,
  selectedFilters: store.customers.filters
}), {
  setCustomerColumns,
  setCustomerFilters
})(FiltersModal);
