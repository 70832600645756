import React from 'react';
import {
  Row, Col, Card, CardHeader, CardBody
} from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';
import moment from 'moment';

import useHomeRental from '../../../../../../../../hooks/useHomeRental';
import useCoBuyers from '../../../../../../../../hooks/useCoBuyers';
import {
  getCustomerIdentificationInfo, getCustomerPreApprovalAmount, getDocumentNumber,
  getDocumentType
} from '../../../../../../../../utils/customerUtils';
import {
  getFormattedCustomerFinancialAmount,
} from '../../../../Overview/VerificationSection/utils';
import {
  formatCurrency,
  isValidAmount
} from '../../../../../../../../utils/currencyUtils';
import { DateFormats } from '../../../../../../../../constants';

const i18nOpts = { scope: 'components.admin.customers.show.details.verification.coBuyerAdded.infoCard.index' };

const InfoCard = () => {
  const { isRental, typeI18nKey } = useHomeRental();
  const { acceptedCoBuyer } = useCoBuyers();

  if (!acceptedCoBuyer) return null;

  const { contact: customer, inviteAcceptedAt } = acceptedCoBuyer;
  const { isIdVerified, companyContact, financialSummary } = customer;
  const isIdVerifiedAndGranted = isIdVerified && companyContact.verifiedIdGranted;

  let documentType;
  let documentNumber;
  if (isIdVerifiedAndGranted) {
    const customerIdentificationInfo = getCustomerIdentificationInfo(customer);
    const { identificationDocument } = customerIdentificationInfo;
    documentType = getDocumentType(identificationDocument?.documentType);
    documentNumber = getDocumentNumber(identificationDocument?.documentNumbers);
  }

  const { isFinancialVerified } = customer;
  const isFinancialVerifiedAndGranted = isFinancialVerified
    && companyContact.verifiedFinancialGranted;
  const formattedFinancialAmount = getFormattedCustomerFinancialAmount(customer, isRental, false);
  const { annualIncome } = companyContact.affordability || {};
  const employerName = financialSummary?.employerPrimaryName;
  const preApprovalAmount = getCustomerPreApprovalAmount(customer);

  return (
    <Card className="mb-0">
      <CardHeader className="py-2">
        <div className="d-flex align-items-center">
          <h4 className="mb-0 mr-3 col-gray-900">{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</h4>
          {inviteAcceptedAt && (
            <div className="small">
              {i18n.t('added', { ...i18nOpts, date: moment(inviteAcceptedAt).format(DateFormats.YYYY_MM_DD) })}
            </div>
          )}
          <div className="ml-auto">
            <a
              href={`/admin/customers/${acceptedCoBuyer.contact.id}`}
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-secondary"
            >
              {i18n.t(`${typeI18nKey}.buttons.view`, i18nOpts)}
            </a>
          </div>
        </div>
      </CardHeader>
      <CardBody className="pb-0">
        <Row>
          {!v.isBlank(customer.firstName) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('firstName', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{customer.firstName}</div>
            </Col>
          )}

          <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
            <div className="font-weight-500 col-gray-500">{i18n.t('lastName', i18nOpts)}</div>
            <div className="font-weight-600 font-16">{customer.lastName}</div>
          </Col>

          {!v.isBlank(documentType) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('documentType', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{documentType}</div>
            </Col>
          )}

          {!v.isBlank(documentNumber) && (
            <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('documentNumber', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{documentNumber}</div>
            </Col>
          )}

          {isFinancialVerifiedAndGranted && (
            <>
              {!v.isBlank(formattedFinancialAmount) && (
                <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
                  <div className="font-weight-500 col-gray-500">{i18n.t(`${typeI18nKey}.financialAmount`, i18nOpts)}</div>
                  <div className="font-weight-600 font-16">{formattedFinancialAmount}</div>
                </Col>
              )}

              {isValidAmount(annualIncome) && (
                <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
                  <div className="font-weight-500 col-gray-500">{i18n.t('annualIncome', i18nOpts)}</div>
                  <div className="font-weight-600 font-16">{formatCurrency(annualIncome)}</div>
                </Col>
              )}

              {!v.isBlank(employerName) && (
                <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
                  <div className="font-weight-500 col-gray-500">{i18n.t('employerName', i18nOpts)}</div>
                  <div className="font-weight-600 font-16">{employerName}</div>
                </Col>
              )}

              <Col xs="6" sm="6" md="4" xl="3" className="mb-4 pb-2">
                <div className="font-weight-500 col-gray-500">{i18n.t('preApproval', i18nOpts)}</div>
                <div className="font-weight-600 font-16">{formatCurrency(preApprovalAmount) || 'N/A'}</div>
              </Col>
            </>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

InfoCard.propTypes = {};

InfoCard.defaultProps = {};

export default InfoCard;
