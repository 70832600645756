import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import v from 'voca';
import { connect } from 'react-redux';

import api from '../../../../../api';
import { getImportTemplateQuery } from '../../../../../graphql/importExport';

const i18nOpts = { scope: 'components.admin.integrations.importData.downloadTemplate.index' };

function getImportTemplateAsync(companyId) {
  const variables = { companyId };
  return api.graphql(getImportTemplateQuery, variables)
    .then(({ data: { item: { templateUrl } } }) => Promise.resolve(templateUrl));
}

const DownloadTemplate = ({ currentCompany }) => {
  const [templateUrl, setTemplateUrl] = useState(null);

  useEffect(() => {
    getImportTemplateAsync(currentCompany.id)
      .then((url) => setTemplateUrl(url))
      .catch(() => {});
  }, []);

  if (v.isBlank(templateUrl)) return null;

  return (
    <div>
      {i18n.t('download', i18nOpts)}
      &nbsp;
      <a href={templateUrl} target="_blank" rel="noreferrer">
        {i18n.t('here', i18nOpts)}
      </a>
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(DownloadTemplate);
