import React from 'react';
import PropTypes from 'prop-types';

import FilterCheckbox from '../../../../../../common/FilterCheckbox';
import {
  getCustomerReservationStatusName,
} from '../../../../../../../utils/enumUtils';

const ListItem = ({
  value, selectedValues, onChange
}) => (
  <FilterCheckbox
    label={getCustomerReservationStatusName(value)}
    value={value}
    checked={selectedValues.includes(value)}
    onChange={onChange}
    color="success"
  />
);

ListItem.propTypes = {
  value: PropTypes.string.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

ListItem.defaultProps = {
  selectedValues: [],
  onChange: () => {}
};

export default ListItem;
