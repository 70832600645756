import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import Step from '../components/Step';
import { ModalNames, OfferSignStatuses, ReserveStepTypes } from '../../../../../constants';
import ModalService from '../../../../../services/ModalService';
import SignSubmittedIcon from '../../../../../assets/icons/sign/SignSubmittedIcon';
import SignPendingIcon from '../../../../../assets/icons/sign/SignPendingIcon';
import SignAcceptedIcon from '../../../../../assets/icons/sign/SignAcceptedIcon';
import SignDeclinedIcon from '../../../../../assets/icons/sign/SignDeclinedIcon';

const i18nOpts = { scope: 'components.global.home.offerSteps.signStep.index' };

const SignStep = ({ home, clickable, label: defaultLabel }) => {
  const { offer } = home;
  const signStatus = offer?.signStatus;

  let label;
  let color;
  let date;
  let Icon;

  switch (signStatus) {
    case OfferSignStatuses.SUBMITTED:
      label = i18n.t('completed', i18nOpts);
      color = 'success';
      date = offer.signStatusChangeDates.submittedAt;
      Icon = SignSubmittedIcon;
      break;

    case OfferSignStatuses.ACCEPTED:
      label = i18n.t('accepted', i18nOpts);
      color = 'success';
      date = offer.signStatusChangeDates.acceptedAt;
      Icon = SignAcceptedIcon;
      break;

    case OfferSignStatuses.REJECTED:
      label = i18n.t('rejected', i18nOpts);
      color = 'danger';
      date = offer.signStatusChangeDates.rejectedAt;
      Icon = SignDeclinedIcon;
      break;

    case OfferSignStatuses.DECLINED:
      label = i18n.t('declined', i18nOpts);
      color = 'danger';
      date = offer.signStatusChangeDates.declinedAt;
      Icon = SignDeclinedIcon;
      break;

    default:
      label = i18n.t('pending', i18nOpts);
      color = 'light';
      date = null;
      Icon = SignPendingIcon;
      break;
  }

  const onClick = () => {
    ModalService.open(ModalNames.DEAL, { homeId: home.id, stepType: ReserveStepTypes.SIGN });
  };

  let extraProps = {};
  if (clickable && !!signStatus) {
    extraProps = {
      role: 'button',
      'aria-hidden': true,
      onClick
    };
  }

  return (
    <div>
      <Step
        title={defaultLabel}
        label={label}
        color={color}
        date={date}
        Icon={Icon}
        {...extraProps}
      />
    </div>
  );
};

SignStep.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired,
  clickable: PropTypes.bool,
  label: PropTypes.string
};

SignStep.defaultProps = {
  clickable: false,
  label: null
};

export default SignStep;
