import api from '../../../../api';
import { getVerifiedAgesQuery } from '../../../../graphql';
import { fakeValue } from '../../../../utils/sampleDataUtils';

export function fetchVerifiedAgesAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getVerifiedAgesQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  const data = [
    { range: '18-25', customersCount: fakeValue(100) },
    { range: '25-30', customersCount: fakeValue(100) },
    { range: '30-40', customersCount: fakeValue(100) },
    { range: '40-55', customersCount: fakeValue(100) },
    { range: '55+', customersCount: fakeValue(100) },
  ];
  const filteredData = data.filter((i) => i.customersCount > 0);
  return Promise.resolve(filteredData);
}
