import React from 'react';
import { DropdownItem } from 'reactstrap';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import BaseActionsMenu from '../../global/ActionsMenu';

const PackageActionsMenu = ({
  packageItem, onToggleAsDefault, onEdit, onDelete
}) => (
  <BaseActionsMenu item={packageItem} onEdit={onEdit} onDelete={onDelete}>
    {packageItem.isDefault ? (
      <DropdownItem onClick={() => onToggleAsDefault(false)}>
        {i18n.t('buttons.setAsNotDefault')}
      </DropdownItem>
    ) : (
      <DropdownItem onClick={() => onToggleAsDefault(true)}>
        {i18n.t('buttons.setAsDefault')}
      </DropdownItem>
    )}
  </BaseActionsMenu>
);

PackageActionsMenu.propTypes = {
  packageItem: PropTypes.objectOf(PropTypes.any).isRequired,
  onToggleAsDefault: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

PackageActionsMenu.defaultProps = {
  onToggleAsDefault: () => {},
  onDelete: () => {},
  onEdit: undefined,
};

export default PackageActionsMenu;
