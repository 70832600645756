import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';
import i18n from 'i18n-js';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.visit.referer.index' };

const Referer = ({ visitorSession }) => {
  if (v.isBlank(visitorSession.referer)) return null;

  if (!visitorSession.isExternalReferer) return null;

  return (
    <div>
      <span className="font-weight-500 col-gray-700 mr-1">{i18n.t('title', i18nOpts)}</span>
      <span className="word-break-break-all">{visitorSession.referer}</span>
    </div>
  );
};

Referer.propTypes = {
  visitorSession: PropTypes.objectOf(PropTypes.any).isRequired
};

Referer.defaultProps = {};

export default Referer;
