import { TaxRateTypes } from '../constants';

const types = {
  [TaxRateTypes.CITY_BASED]: 'City Based',
  [TaxRateTypes.COMMUNITY_PROJECT_BASED]: 'Community/Project Based',
};

export function getSanitizedType(type) {
  return types[type];
}

export function getArrayMappedNames(communities) {
  return communities.map((community) => community.name).join(', ');
}

export function getTaxRatePercentage(taxRate) {
  return `${Math.round(taxRate * 100)}%`;
}
