import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  FormGroup, Input
} from 'reactstrap';
import v from 'voca';
import { connect } from 'react-redux';

import CollapsibleFilterGroup from '../../../../../common/CollapsibleFilterGroup';
import ResetButton from '../components/ResetButton';
import DollarIcon from '../../../../../../assets/icons/DollarIcon';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.buyingPowerFilter.index' };

const BuyingPowerFilter = ({ currentCompany, filters, onChange }) => {
  const { buyingPower } = filters;
  const { enableVerifiedBuyingPower, enableHomeRental } = currentCompany;

  const onTextChange = (event) => {
    const { name, value } = event.target;
    const newBuyingPower = { ...(buyingPower || {}) };
    newBuyingPower[name] = value;
    onChange({ buyingPower: newBuyingPower });
  };

  const onReset = () => {
    onChange({ buyingPower: null });
  };

  const hasValue = !v.isBlank(buyingPower?.from) || !v.isBlank(buyingPower?.to);
  const filtersCount = hasValue ? 1 : 0;

  if (!enableVerifiedBuyingPower || enableHomeRental) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <div className="d-flex align-items-center gap-3">
        <div>
          <FormGroup className="mb-0">
            <div className="input-icon-prepend">
              <DollarIcon />
            </div>
            <Input
              type="number"
              name="from"
              id="buyingPowerFrom"
              value={buyingPower?.from || ''}
              onChange={onTextChange}
            />
          </FormGroup>
        </div>
        <div>
          -
        </div>
        <div>
          <FormGroup className="mb-0">
            <div className="input-icon-prepend">
              <DollarIcon />
            </div>
            <Input
              type="number"
              name="to"
              id="buyingPowerTo"
              value={buyingPower?.to || ''}
              onChange={onTextChange}
            />
          </FormGroup>
        </div>
      </div>

      {hasValue && (
        <div className="mt-2">
          <ResetButton onClick={onReset} />
        </div>
      )}
    </CollapsibleFilterGroup>
  );
};

BuyingPowerFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

BuyingPowerFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}))(BuyingPowerFilter);
