import api from '../../../../api';
import { listBuildingModelModelsQuery } from '../../../../graphql';

export function fetchProjectBuildingsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListProjectBuildings(
    $filter: ProjectBuildingFilterInput
    ) {
      items: listProjectBuildings(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

export function fetchBuildingModelModelsAsync(filter) {
  const { projectBuildingId } = filter;
  if (!projectBuildingId) return Promise.resolve([]);
  const variables = { filter: { projectBuildingId } };

  return api.graphql(listBuildingModelModelsQuery, variables)
    .then(({ data: { items } }) => items);
}

export function filterBuildingModels(filter, buildingModels, buildingModelModels) {
  const { projectBuildingId } = filter;

  if (!projectBuildingId) return buildingModels;

  const selectedBuildingModelIds = buildingModelModels.map((bmm) => bmm.buildingModel.id);
  return buildingModels.filter((m) => selectedBuildingModelIds.includes(m.id));
}
