import i18n from 'i18n-js';

import { Tabs } from '../../utils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.communications.index' };

export function getSettings() {
  const settings = [];

  if (isMasterAdmin() || isTecSupport()) {
    settings.push(...[
      {
        id: 'emailService',
        label: i18n.t('emailServiceProvider', i18nOpts),
        containerId: 'emailServiceContainer'
      },
      {
        id: 'senderEmail',
        label: i18n.t('sendersEmailAddress', i18nOpts),
        containerId: 'senderEmailContainer'
      },
      {
        id: 'disclaimersSection',
        label: i18n.t('buildAndPriceDisclaimers', i18nOpts),
        containerId: 'disclaimersSectionContainer'
      },
      {
        id: 'cityDisclaimer',
        label: i18n.t('disclaimerSections.city', i18nOpts),
        containerId: 'cityDisclaimerContainer'
      },
      {
        id: 'communityProjectDisclaimer',
        label: i18n.t('disclaimerSections.communityProject', i18nOpts),
        containerId: 'communityProjectDisclaimerContainer'
      },
      {
        id: 'lotMapDisclaimer',
        label: i18n.t('disclaimerSections.lotMap', i18nOpts),
        containerId: 'lotMapDisclaimerContainer'
      },
      {
        id: 'productTypeDisclaimer',
        label: i18n.t('disclaimerSections.productType', i18nOpts),
        containerId: 'productTypeDisclaimerContainer'
      },
      {
        id: 'modelDetailsDisclaimer',
        label: i18n.t('disclaimerSections.modelDetails', i18nOpts),
        containerId: 'modelDetailsDisclaimerContainer'
      },
      {
        id: 'monthlyPaymentDisclaimer',
        label: i18n.t('disclaimerSections.monthlyPaymentDisclaimer', i18nOpts),
        containerId: 'monthlyPaymentDisclaimerContainer'
      },
      {
        id: 'floorplansDisclaimer',
        label: i18n.t('disclaimerSections.floorplans', i18nOpts),
        containerId: 'floorplansDisclaimerContainer'
      },
      {
        id: 'elevationsDisclaimer',
        label: i18n.t('disclaimerSections.elevations', i18nOpts),
        containerId: 'elevationsDisclaimerContainer'
      },
      {
        id: 'palettesDisclaimer',
        label: i18n.t('disclaimerSections.palettes', i18nOpts),
        containerId: 'palettesDisclaimerContainer'
      },
      {
        id: 'packagesDisclaimer',
        label: i18n.t('disclaimerSections.packages', i18nOpts),
        containerId: 'packagesDisclaimerContainer'
      },
      {
        id: 'depositAmountDisclaimer',
        label: i18n.t('disclaimerSections.depositAmount', i18nOpts),
        containerId: 'depositAmountDisclaimerContainer'
      },
      {
        id: 'summaryDisclaimer',
        label: i18n.t('disclaimerSections.summary', i18nOpts),
        containerId: 'summaryDisclaimerContainer'
      },
      {
        id: 'exportPagePdfDisclaimer',
        label: i18n.t('disclaimerSections.exportPagePdf', i18nOpts),
        containerId: 'exportPagePdfDisclaimerContainer'
      },
      {
        id: 'sfPriceDisclaimer',
        label: i18n.t('disclaimerSections.sfPriceDisclaimer', i18nOpts),
        containerId: 'sfPriceDisclaimerContainer'
      },
      {
        id: 'mfPriceDisclaimer',
        label: i18n.t('disclaimerSections.mfPriceDisclaimer', i18nOpts),
        containerId: 'mfPriceDisclaimerContainer'
      },
      {
        id: 'qpDepositInPlaceDisclaimer',
        label: i18n.t('disclaimerSections.qpDepositInPlace', i18nOpts),
        containerId: 'qpDepositInPlaceDisclaimerContainer'
      },
      {
        id: 'interestRateDisclaimer',
        label: i18n.t('disclaimerSections.interestRate', i18nOpts),
        containerId: 'interestRateDisclaimerContainer'
      }
    ]);
  }

  return settings.map((setting) => ({
    ...setting,
    tabId: Tabs.COMMUNICATIONS
  }));
}
