import React from 'react';
import i18n from 'i18n-js';
import { Button, Card, Table } from 'reactstrap';

import CopyLink from '../../../../../common/CopyLink';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.buildHomeSetup.buildHome.index' };

const BUILD_HOME_CODE = `<button class="ownly-build-home">
  Build Home
</button>`;

const BUILD_SINGLE_HOME_FOR_MODEL_CODE = `<button class="ownly-build-home"
 data-community-id="OWNLY_COMMUNITY_ID"
 data-model-id="OWNLY_MODEL_ID">
  Build Home
</button>`;

const BUILD_SINGLE_HOME_FOR_NEW_MODEL_CODE = `<button class="ownly-build-home"
 data-community-external-id="community1"
 data-community-name="Savanna"
 data-model-external-id="model1"
 data-model-name="Cascade"
 data-model-size="1567"
 data-model-beds="3"
 data-model-baths="2.5"
 data-model-price="650000"
 data-model-image="/assets/images/model-101.jpg"
 data-model-option1-floor="Main Floor"
 data-model-option1-group="Kitchen"
 data-model-option1-name="Spice Kitchen"
 data-model-option1-price="1000"
 data-agent-email="sales.agent1@gmail.com"
 data-agent-name="Sales Agent1">
  Build Home
</button>`;

const BUILD_MULTI_HOME_FOR_UNIT_CODE = `<button class="ownly-build-home"
 data-building-id="OWNLY_BUILDING_ID"
 data-unit-id="OWNLY_UNIT_ID">
  Build Home
</button>`;

const BUILD_MULTI_HOME_FOR_NEW_UNIT_CODE = `<button class="ownly-build-home"
 data-project-external-id="project1"
 data-project-name="Ice District"
 data-building-external-id="building1"
 data-building-name="Building 1"
 data-unit-id="uni1"
 data-unit-name="Unit 1"
 data-unit-size="930"
 data-unit-beds="2"
 data-unit-baths="2"
 data-unit-price="850000"
 data-unit-floor="19"
 data-unit-address="50 Corner Meadows Manor NE"
 data-unit-image="/assets/images/unit-101.jpg"
 data-unit-option1-floor="Main Floor"
 data-unit-option1-group="Kitchen"
 data-unit-option1-name="Spice Kitchen"
 data-unit-option1-price="1000"
 data-agent-email="sales.agent2@gmail.com"
 data-agent-name="Sales Agent2">
  Build Home
</button>`;

const COMMON_ATTRIBUTES = Object.freeze([
  {
    name: 'Sales Agent ID', attribute: 'data-agent-id', type: 'string', required: 'false'
  },
  {
    name: 'Sales Agent Email', attribute: 'data-agent-email', type: 'string', required: 'false'
  },
  {
    name: 'Sales Agent Name', attribute: 'data-agent-name', type: 'string', required: 'false'
  },
]);

const SingleFamilyFields = [
  {
    name: 'Community External ID', attribute: 'data-community-external-id', type: 'string', required: 'true'
  },
  {
    name: 'Community Name', attribute: 'data-community-name', type: 'string', required: 'true'
  },
  {
    name: 'Model External ID', attribute: 'data-model-external-id', type: 'string', required: 'true'
  },
  {
    name: 'Model Name', attribute: 'data-model-name', type: 'string', required: 'true'
  },
  {
    name: 'Model Size', attribute: 'data-model-size', type: 'number', required: 'false'
  },
  {
    name: 'Model Beds', attribute: 'data-model-beds', type: 'number', required: 'false'
  },
  {
    name: 'Model Baths', attribute: 'data-model-baths', type: 'number', required: 'false'
  },
  {
    name: 'Model Price', attribute: 'data-model-price', type: 'number', required: 'false'
  },
  {
    name: 'Model Image', attribute: 'data-model-image', type: 'string', required: 'false'
  },
  {
    name: 'Floor Name', attribute: 'data-model-option1-floor', type: 'string', required: 'true'
  },
  {
    name: 'Floor Image', attribute: 'data-model-option1-floor-image', type: 'string', required: 'false'
  },
  {
    name: 'Group Name', attribute: 'data-model-option1-group', type: 'string', required: 'true'
  },
  {
    name: 'Floor Option Name', attribute: 'data-model-option1-name', type: 'string', required: 'true'
  },
  {
    name: 'Floor Option Size', attribute: 'data-model-option1-size', type: 'number', required: 'false'
  },
  {
    name: 'Floor Option Beds', attribute: 'data-model-option1-beds', type: 'number', required: 'false'
  },
  {
    name: 'Floor Option Baths', attribute: 'data-model-option1-baths', type: 'number', required: 'false'
  },
  {
    name: 'Floor Option Price', attribute: 'data-model-option1-price', type: 'number', required: 'false'
  },
  {
    name: 'Floor Option Image', attribute: 'data-model-option1-image', type: 'string', required: 'false'
  },
  ...COMMON_ATTRIBUTES
];

const MultiFamilyFields = [
  {
    name: 'Project External ID', attribute: 'data-project-external-id', type: 'string', required: 'true'
  },
  {
    name: 'Project Name', attribute: 'data-project-name', type: 'string', required: 'true'
  },
  {
    name: 'Building External ID', attribute: 'data-building-external-id', type: 'string', required: 'true'
  },
  {
    name: 'Building Name', attribute: 'data-building-name', type: 'string', required: 'true'
  },
  {
    name: 'Model ID', attribute: 'data-model-id', type: 'string', required: 'true'
  },
  {
    name: 'Unit ID', attribute: 'data-unit-id', type: 'string', required: 'true'
  },
  {
    name: 'Unit Name', attribute: 'data-unit-name', type: 'string', required: 'true'
  },
  {
    name: 'Unit Size', attribute: 'data-unit-size', type: 'number', required: 'false'
  },
  {
    name: 'Unit Beds', attribute: 'data-unit-beds', type: 'number', required: 'false'
  },
  {
    name: 'Unit Baths', attribute: 'data-unit-baths', type: 'number', required: 'false'
  },
  {
    name: 'Unit Price', attribute: 'data-unit-price', type: 'number', required: 'false'
  },
  {
    name: 'Unit Floor', attribute: 'data-unit-floor', type: 'number', required: 'false'
  },
  {
    name: 'Unit Address', attribute: 'data-unit-address', type: 'string', required: 'false'
  },
  {
    name: 'Unit Image', attribute: 'data-unit-image', type: 'string', required: 'false'
  },
  {
    name: 'Floor Name', attribute: 'data-unit-option1-floor', type: 'string', required: 'true'
  },
  {
    name: 'Floor Image', attribute: 'data-unit-option1-floor-image', type: 'string', required: 'false'
  },
  {
    name: 'Group Name', attribute: 'data-unit-option1-group', type: 'string', required: 'true'
  },
  {
    name: 'Floor Option Name', attribute: 'data-unit-option1-name', type: 'string', required: 'true'
  },
  {
    name: 'Floor Option Size', attribute: 'data-unit-option1-size', type: 'string', required: 'false'
  },
  {
    name: 'Floor Option Beds', attribute: 'data-unit-option1-beds', type: 'string', required: 'false'
  },
  {
    name: 'Floor Option Baths', attribute: 'data-unit-option1-baths', type: 'string', required: 'false'
  },
  {
    name: 'Floor Option Price', attribute: 'data-unit-option1-price', type: 'string', required: 'false'
  },
  {
    name: 'Floor Option Image', attribute: 'data-unit-option1-image', type: 'string', required: 'false'
  },
  {
    name: 'Package Name', attribute: 'data-package-name', type: 'string', required: 'false'
  },
  {
    name: 'Package Price', attribute: 'data-package-price', type: 'number', required: 'false'
  },
  {
    name: 'Palette Name', attribute: 'data-palette-name', type: 'string', required: 'false'
  },
  {
    name: 'Palette Price', attribute: 'data-palette-price', type: 'number', required: 'false'
  },
  ...COMMON_ATTRIBUTES
];

const BuildHome = () => (
  <div>
    {/* Build Home */}
    <h2>{i18n.t('buildHome', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('buildHomeDesc', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>

    <Card body className="d-block">
      <pre>
        &lt;button class=&quot;ownly-build-home&quot;&gt;
        <br />
        &nbsp;&nbsp;Build Home
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={BUILD_HOME_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    {/* Build Single Family Home */}
    <h2>{i18n.t('buildSingleFamilyHome', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('buildSingleFamilyHomeDesc', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>
    <Card body className="d-block">
      <pre>
        &lt;button class=&quot;ownly-build-home&quot;
        <br />
        &nbsp;data-community-id=&quot;OWNLY_COMMUNITY_ID&quot;
        <br />
        &nbsp;data-model-id=&quot;OWNLY_MODEL_ID&quot;&gt;
        <br />
        &nbsp;&nbsp;Build Home
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={BUILD_SINGLE_HOME_FOR_MODEL_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>
    <p>{i18n.t('addNewCommunityAndModel', i18nOpts)}</p>
    <div className="table-responsive">
      <Table bordered>
        <thead>
          <tr>
            <th>{i18n.t('list.name', i18nOpts)}</th>
            <th>{i18n.t('list.attribute', i18nOpts)}</th>
            <th>{i18n.t('list.type', i18nOpts)}</th>
            <th>{i18n.t('list.required', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          { SingleFamilyFields.map((field) => (
            <tr key={field.attribute}>
              <td>{field.name}</td>
              <td><code>{field.attribute}</code></td>
              <td><code>{field.type}</code></td>
              <td><code>{field.required}</code></td>
            </tr>
          )) }
        </tbody>
      </Table>
    </div>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('addNewModelOption', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>
    <Card body className="d-block">
      <pre>
        &lt;button class=&quot;ownly-build-home&quot;
        <br />
        &nbsp;data-community-external-id=&quot;community1&quot;
        <br />
        &nbsp;data-community-name=&quot;Savanna&quot;
        <br />
        &nbsp;data-model-external-id=&quot;model1&quot;
        <br />
        &nbsp;data-model-name=&quot;Cascade&quot;
        <br />
        &nbsp;data-model-size=&quot;1567&quot;
        <br />
        &nbsp;data-model-beds=&quot;3&quot;
        <br />
        &nbsp;data-model-baths=&quot;2.5&quot;
        <br />
        &nbsp;data-model-price=&quot;650000&quot;
        <br />
        &nbsp;data-model-image=&quot;/assets/images/model-101.jpg&quot;
        <br />
        &nbsp;data-model-option1-floor=&quot;Main Floor&quot;
        <br />
        &nbsp;data-model-option1-group=&quot;Kitchen&quot;
        <br />
        &nbsp;data-model-option1-name=&quot;Spice Kitchen&quot;
        <br />
        &nbsp;data-model-option1-price=&quot;1000&quot;
        <br />
        &nbsp;data-agent-email=&quot;sales.agent1@gmail.com&quot;
        <br />
        &nbsp;data-agent-name=&quot;Sales Agent1&quot;&gt;
        <br />
        &nbsp;&nbsp;Build Home
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={BUILD_SINGLE_HOME_FOR_NEW_MODEL_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    {/* Build Multi Family Home */}
    <h2>{i18n.t('buildMultiFamilyHome', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('buildMultiFamilyHomeDesc', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>
    <Card body className="d-block">
      <pre>
        &lt;button class=&quot;ownly-build-home&quot;
        <br />
        &nbsp;data-building-id=&quot;OWNLY_BUILDING_ID&quot;
        <br />
        &nbsp;data-unit-id=&quot;OWNLY_UNIT_ID&quot;&gt;
        <br />
        &nbsp;&nbsp;Build Home
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={BUILD_MULTI_HOME_FOR_UNIT_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>
    <p>{i18n.t('addNewProjectAndUnit', i18nOpts)}</p>
    <div className="table-responsive">
      <Table bordered>
        <thead>
          <tr>
            <th>{i18n.t('list.name', i18nOpts)}</th>
            <th>{i18n.t('list.attribute', i18nOpts)}</th>
            <th>{i18n.t('list.type', i18nOpts)}</th>
            <th>{i18n.t('list.required', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          { MultiFamilyFields.map((field) => (
            <tr key={field.attribute}>
              <td>{field.name}</td>
              <td><code>{field.attribute}</code></td>
              <td><code>{field.type}</code></td>
              <td><code>{field.required}</code></td>
            </tr>
          )) }
        </tbody>
      </Table>
    </div>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('addNewUnitOption', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>
    <Card body className="d-block">
      <pre>
        &lt;button class=&quot;ownly-build-home&quot;
        <br />
        &nbsp;data-project-external-id=&quot;project1&quot;
        <br />
        &nbsp;data-project-name=&quot;Ice District&quot;
        <br />
        &nbsp;data-building-external-id=&quot;building1&quot;
        <br />
        &nbsp;data-building-name=&quot;Building 1&quot;
        <br />
        &nbsp;data-unit-id=&quot;unit1&quot;
        <br />
        &nbsp;data-unit-name=&quot;Unit 1&quot;
        <br />
        &nbsp;data-unit-size=&quot;930&quot;
        <br />
        &nbsp;data-unit-beds=&quot;2&quot;
        <br />
        &nbsp;data-unit-baths=&quot;2&quot;
        <br />
        &nbsp;data-unit-price=&quot;850000&quot;
        <br />
        &nbsp;data-unit-floor=&quot;19&quot;
        <br />
        &nbsp;data-unit-address=&quot;50 Corner Meadows Manor NE&quot;
        <br />
        &nbsp;data-unit-image=&quot;/assets/images/unit-101.jpg&quot;
        <br />
        &nbsp;data-unit-option1-floor=&quot;Main Floor&quot;
        <br />
        &nbsp;data-unit-option1-group=&quot;Kitchen&quot;
        <br />
        &nbsp;data-unit-option1-name=&quot;Spice Kitchen&quot;
        <br />
        &nbsp;data-unit-option1-price=&quot;1000&quot;
        <br />
        &nbsp;data-package-name=&quot;Exclusive&quot;
        <br />
        &nbsp;data-package-price=&quot;10000&quot;
        <br />
        &nbsp;data-palette-name=&quot;Nordic Clean&quot;
        <br />
        &nbsp;data-palette-price=&quot;5000&quot;
        <br />
        &nbsp;data-agent-email=&quot;sales.agent2@gmail.com&quot;
        <br />
        &nbsp;data-agent-name=&quot;Sales Agent2&quot;&gt;
        <br />
        &nbsp;&nbsp;Build Home
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={BUILD_MULTI_HOME_FOR_NEW_UNIT_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>
  </div>
);

BuildHome.propTypes = {};

BuildHome.defaultProps = {};

export default BuildHome;
