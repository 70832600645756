import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Loading.module.scss';
import { LottieAnimationsUrl } from '../../../constants';

const Loading = ({ loading, fullScreen }) => {
  if (!loading) return null;

  const loadingContainerClasses = classNames({
    [styles.fullScreen]: fullScreen
  });

  return (
    <div className={loadingContainerClasses}>
      <Player
        autoplay
        loop
        speed="1.2"
        src={LottieAnimationsUrl.LOADING_HOME}
        className={styles.loadingImage}
      />
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  fullScreen: PropTypes.bool
};

Loading.defaultProps = {
  loading: false,
  fullScreen: false // Only when the page is loading for the first time
};

export default Loading;
