import React from 'react';
import PropTypes from 'prop-types';
import { MdCheck } from 'react-icons/md';

import { getCustomerStatusName } from '../../../../../../../utils/enumUtils';
import { CustomerStatuses } from '../../utils';
import FilterCheckbox from '../../../../../../common/FilterCheckbox';

const StatusItem = ({
  value, selectedValues, onChange
}) => (
  <FilterCheckbox
    label={getCustomerStatusName(value)}
    value={value}
    checked={selectedValues.includes(value)}
    onChange={onChange}
    color={value === CustomerStatuses.CUSTOMER_VERIFIED ? 'success' : 'info'}
    Icon={value === CustomerStatuses.CUSTOMER_VERIFIED ? <MdCheck size={14} /> : null}
  />
);

StatusItem.propTypes = {
  value: PropTypes.string.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

StatusItem.defaultProps = {
  selectedValues: [],
  onChange: () => {}
};

export default StatusItem;
