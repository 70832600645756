import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { LotWorksStandardProductTypes } from '../../../../../../../constants';

const StandardLotWorksProductType = ({ selectedLotWorksProductTypes, setLotWorksProductTypes }) => {
  const lotWorksProductTypes = Object.values(LotWorksStandardProductTypes);

  return (
    <>
      { lotWorksProductTypes.map((pt) => (
        <FormGroup check className="my-1" key={`radio-option-${pt.value}`}>
          <Label
            check
            className="pointer"
          >
            <Input
              type="checkbox"
              name={`radio-${pt.value}`}
              checked={selectedLotWorksProductTypes.includes(pt.value)}
              className="pointer"
              value={pt.value}
              onChange={() => setLotWorksProductTypes(pt.value)}
            />
            {pt.label}
          </Label>
        </FormGroup>
      ))}
    </>
  );
};

export default StandardLotWorksProductType;
