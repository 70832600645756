import api from '../../../../../api';
import {
  createQuickPossessionImageQuery,
  deleteQuickPossessionImageQuery,
  listQuickPossessionImagesQuery, sortQuickPossessionImagesQuery
} from '../../../../../graphql/quickPossessionImage';

export async function saveQuickPossessionImageAsync(quickPossessionId, image) {
  const variables = {
    input: { quickPossessionId },
    image
  };
  return api.graphql(createQuickPossessionImageQuery, variables);
}

export async function deleteQuickPossessionImageAsync(quickPossessionImageId) {
  const variables = { id: quickPossessionImageId };
  return api.graphql(deleteQuickPossessionImageQuery, variables)
    .then(({ data: { item } }) => Promise.resolve(item));
}

export async function fetchQuickPossessionImages(quickPossessionId) {
  const variables = { filter: { quickPossessionId } };
  return api.graphql(listQuickPossessionImagesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

export function sortQuickPossessionImagesAsync(variables) {
  return api.graphql(sortQuickPossessionImagesQuery, variables);
}
