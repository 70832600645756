import {
  CLEAN_PALETTES, GET_PALETTE, GET_PALETTES, SAVE_PALETTE, DELETE_PALETTE
} from './types';
import {
  createPaletteQuery, deletePaletteQuery, getPaletteQuery, listPalettesQuery,
  updatePaletteQuery
} from '../../graphql';
import api from '../../api';

export function getPalettes(variables = {}) {
  return {
    type: GET_PALETTES,
    payload: api.graphql(listPalettesQuery, variables)
  };
}

export function getPalette(id) {
  return {
    type: GET_PALETTE,
    payload: api.graphql(getPaletteQuery, { id })
  };
}

export function cleanPalettes(extraProps = {}) {
  return {
    type: CLEAN_PALETTES,
    payload: Promise.resolve(extraProps)
  };
}

export function savePalette(variables = {}) {
  const query = variables.input.id ? updatePaletteQuery : createPaletteQuery;
  return {
    type: SAVE_PALETTE,
    payload: api.graphql(query, variables)
  };
}

export function deletePalette(id) {
  return {
    type: DELETE_PALETTE,
    payload: api.graphql(deletePaletteQuery, { id })
  };
}
