import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

import { DEFAULT_IMAGE } from '../../../../../constants';
import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';
import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../../utils/authUtils';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';

const i18nOpts = { scope: 'components.admin.packages.show.overview.index' };

const Overview = ({ package: specPackage, loading }) => {
  if (!specPackage || loading) return <div className="text-center my-5"><Spinner size="lg" /></div>;

  const isAdmin = isCompanyAdmin() || isMasterAdmin() || isSalesLead();
  const creatorName = getCreatorDisplayName(specPackage);
  const lastModifierName = getLastModifierDisplayName(specPackage);

  return (
    <div>
      <Row>
        <Col xs="12" sm="6" md="4" lg="3">
          <img
            src={specPackage.imageUrl || DEFAULT_IMAGE}
            alt={specPackage.name}
            className="package-image mb-4 mb-sm-0"
          />
        </Col>
        <Col xs="12" sm="6" md="8" lg="9">
          {isValidAmount(specPackage.basePrice) && (
            <div className="mb-4">
              <div className="label">{i18n.t('basePrice', i18nOpts)}</div>
              <div>{formatCurrency(specPackage.basePrice)}</div>
            </div>
          )}

          {!v.isBlank(specPackage.description) && (
            <div className="mb-4">
              <div className="label">{i18n.t('description', i18nOpts)}</div>
              <div dangerouslySetInnerHTML={{ __html: specPackage.description }} />
            </div>
          )}

          {isAdmin && (
            <>
              {!v.isBlank(creatorName) && (
                <div className="mb-4">
                  <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
                  <div className="white-space-pre-line">{creatorName}</div>
                </div>
              )}

              {!v.isBlank(lastModifierName) && (
                <div className="mb-4">
                  <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
                  <div className="white-space-pre-line">{lastModifierName}</div>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

Overview.propTypes = {};

Overview.defaultProps = {};

export default connect((store) => ({
  package: store.packages.package,
  loading: store.packages.getPackage.loading
}))(Overview);
