import React from 'react';

const OwnlyIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_217_1443)">
      <path d="M23.4833 9.25741L25.9979 8.80126L13.0005 0.5L9.49435 5.3859L9.26344 4.22165L6.24798 4.78484L7.04599 8.79797L4.20032 12.76L6.52643 12.3385L8.46203 22.9172C8.54523 23.3717 8.95272 23.6912 9.41285 23.6912C9.46888 23.6912 9.52491 23.6862 9.58094 23.678L24.6684 21.1222C24.9214 21.0794 25.1472 20.9394 25.295 20.7369C25.4427 20.5327 25.5004 20.2807 25.4545 20.0354L23.4833 9.2607V9.25741ZM10.1973 21.6673L8.42807 11.991L8.44845 11.9877L13.7696 4.26941L21.5732 9.54888L23.3814 19.4343L10.199 21.669L10.1973 21.6673Z" fill="#00CEA6" />
      <path d="M14.1687 8.62341L12.4911 8.9363L12.8205 10.5995L14.5133 10.2636L14.1687 8.62341Z" fill="#D3D300" />
      <path d="M16.5899 9.88816L16.2673 8.26611L14.5507 8.58723L14.8954 10.2241L16.5899 9.88816Z" fill="#D3D300" />
      <path d="M15.2808 12.2431L16.9583 11.9302L16.6272 10.2604L14.9344 10.5963L15.2808 12.2431Z" fill="#D3D300" />
      <path d="M12.8579 10.9684L13.1822 12.6003L14.8988 12.2775L14.5524 10.6324L12.8579 10.9684Z" fill="#D3D300" />
      <path d="M12.2382 35.5C13.0821 35.5 13.7663 34.7893 13.7663 33.9125C13.7663 33.0358 13.0821 32.3251 12.2382 32.3251C11.3942 32.3251 10.7101 33.0358 10.7101 33.9125C10.7101 34.7893 11.3942 35.5 12.2382 35.5Z" fill="#005141" />
      <path d="M21.0876 35.5C21.9315 35.5 22.6157 34.7893 22.6157 33.9125C22.6157 33.0358 21.9315 32.3251 21.0876 32.3251C20.2436 32.3251 19.5594 33.0358 19.5594 33.9125C19.5594 34.7893 20.2436 35.5 21.0876 35.5Z" fill="#005141" />
      <path d="M31.8896 10.5962H27.5905C27.2424 10.5962 26.9385 10.8201 26.8451 11.1462L25.7907 14.8201H4.20882C3.93716 14.8201 3.68247 14.9436 3.51608 15.1527C3.35138 15.3619 3.29705 15.6336 3.36836 15.8872L6.65718 27.5379C6.76075 27.9052 7.10373 28.1588 7.49594 28.1588H22.7176C22.7872 28.1588 22.8568 28.1472 22.9213 28.1324C23.142 28.1802 23.884 28.4173 23.884 29.4251C23.884 30.2205 23.1217 30.3193 22.8008 30.3242H9.76263C9.33646 30.3242 8.99009 30.6602 8.99009 31.0735C8.99009 31.4868 9.33646 31.8228 9.76263 31.8228H22.8008C23.8585 31.8228 25.4308 31.1838 25.4308 29.4235C25.4308 28.0517 24.6583 27.2827 23.9248 26.9089L28.178 12.0964H31.8896C32.3158 12.0964 32.6621 11.7604 32.6621 11.3471C32.6621 10.9338 32.3158 10.5978 31.8896 10.5978V10.5962ZM23.5444 20.9608H20.3999L20.9976 16.508H24.755L23.5444 20.9608ZM14.5558 21.7101V26.4692H11.2551L10.3382 21.7101H14.5558ZM10.1939 20.9608L9.33476 16.508H14.5558V20.9608H10.1939ZM15.33 21.7101H19.5204L18.8803 26.4692H15.3283V21.7101H15.33ZM15.33 20.9608V16.508H20.22L19.6223 20.9608H15.33ZM8.54864 16.508L9.40777 20.9608H6.60624L5.3498 16.508H8.55033H8.54864ZM6.81509 21.7117H9.55039L10.4673 26.4709H8.15812L6.81509 21.7117ZM19.6597 26.4709L20.2998 21.7117H23.3407L22.0452 26.4709H19.6597Z" fill="#005141" />
      <path d="M29.0236 13.2393H28.7672V13.0977H29.4396V13.2393H29.1832V13.9029H29.0236V13.2393ZM29.6162 13.0977H29.7859L30.0372 13.4797L30.2885 13.0977H30.4532V13.9029H30.2987V13.3298L30.0423 13.7152H30.0253L29.769 13.3298V13.9029H29.6145V13.0977H29.6162Z" fill="#005141" />
    </g>
    <defs>
      <clipPath id="clip0_217_1443">
        <rect width="35" height="35" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

OwnlyIcon.propTypes = {};

OwnlyIcon.defaultProps = {};

export default OwnlyIcon;
