import React from 'react';

const EmailVerificationIcon = ({ ...props }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" color="#98A2B3" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.6 4H25.4C26.83 4 28 5.18125 28 6.625V22.375C28 23.8187 26.83 25 25.4 25H4.6C3.17 25 2 23.8187 2 22.375V6.625C2 5.18125 3.17 4 4.6 4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28 7L15 16L2 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

EmailVerificationIcon.propTypes = {};

EmailVerificationIcon.defaultProps = {};

export default EmailVerificationIcon;
