import React from 'react';
import PropTypes from 'prop-types';

const AssignedTo = ({ customer }) => {
  const { companyContact } = customer;
  const { salesLead } = companyContact;

  if (!salesLead) return null;

  return (
    <div className="text-nowrap">
      {salesLead.name}
    </div>
  );
};

AssignedTo.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

AssignedTo.defaultProps = {};

export default AssignedTo;
