import v from 'voca';

import { createPriceRuleQuery, updatePriceRuleQuery } from '../../../../graphql';
import api from '../../../../api';
import { ModalNames, PRICE_RULE_SEPARATOR, PriceableTypes } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import { stripToNull } from '../../../../utils/stringUtils';

export function onCloseModel() {
  ModalService.close(ModalNames.PRICE_RULE_FORM);
}

export function savePriceRuleAsync(input) {
  const query = v.isBlank(input.id) ? createPriceRuleQuery : updatePriceRuleQuery;
  return api.graphql(query, { input });
}

export function getFormInput(form, model, priceableId, priceableType) {
  let input = {
    price: parseFloat(form.price) || 0,
    externalId: stripToNull(form.externalId),
    defaultSelection: form.defaultSelection
  };
  if (form.id) input.id = form.id;
  else {
    input = {
      ...input,
      modelId: model.id,
      priceableId,
      priceableType
    };

    let communityId = 0;
    if (form.communityAndPhaseId) {
      const ids = form.communityAndPhaseId?.split('-') || [];
      communityId = parseInt(ids[0], 10) || 0;
      input.communityPhaseId = parseInt(ids[1], 10) || null;
    }
    input.communityId = communityId;
  }

  delete input.communityAndPhaseId;

  return input;
}

export function getCommunityAndPhaseId(community, communityPhase) {
  return [community.id, communityPhase?.id].filter((i) => !v.isBlank(i)).join('-');
}

export function fetchCommunitiesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListCommunities($filter: CommunityFilterInput) {
      result: listCommunities(filter: $filter, pageSize: null) {
        items {
          id
          name
          phases {
            id
            phase
          }
        }
      }
    }`, variables)
    .then(({ data }) => data.result.items);
}

function someModelPriceRule(priceRules, communityId) {
  return somePriceRule(priceRules, communityId, undefined, null, null);
}

function somePriceRule(priceRules, communityId, communityPhaseId, priceableId, priceableType) {
  return priceRules.some((pr) => pr.community.id === communityId
    && pr.communityPhase?.id === communityPhaseId
    && pr.priceableId === priceableId && pr.priceableType === priceableType);
}

export function getCommunitiesAndPhases(communities, priceRules, priceableId,
  priceableType, isEdit) {
  // Model price rule
  const isModelPriceRule = v.isBlank(priceableType);

  const communityAndPhases = [];
  communities.forEach((community) => {
    let includeCommunity = false;
    let existsCommunityModel = false;
    if (isModelPriceRule) {
      existsCommunityModel = someModelPriceRule(priceRules, community.id);
      includeCommunity = isEdit || !existsCommunityModel;
    } else {
      existsCommunityModel = someModelPriceRule(priceRules, community.id);
      let found = somePriceRule(priceRules, community.id, undefined, priceableId, priceableType);
      if (!found) found = !existsCommunityModel;

      includeCommunity = isEdit || !found;
    }

    if (includeCommunity) communityAndPhases.push({ name: community.name, value: community.id });

    const { phases: communityPhases } = community;

    communityPhases.forEach((communityPhase) => {
      let includePhase;
      if (isModelPriceRule) {
        const found = somePriceRule(priceRules, community.id, communityPhase.id, null, null);
        includePhase = isEdit || !found;
      } else {
        let found = somePriceRule(priceRules, community.id, communityPhase.id, priceableId,
          priceableType);
        if (!found) {
          const existsPhasePriceRule = somePriceRule(priceRules, community.id, communityPhase.id,
            null, null);
          if (!existsPhasePriceRule) found = !existsCommunityModel;
        }

        includePhase = isEdit || !found;
      }

      if (includePhase) {
        communityAndPhases.push({
          name: [community.name, communityPhase.phase].join(PRICE_RULE_SEPARATOR),
          value: getCommunityAndPhaseId(community, communityPhase)
        });
      }
    });
  });
  return communityAndPhases;
}

export function isFloorOptionPriceRule(priceableType) {
  return priceableType === PriceableTypes.FLOOR_OPTION;
}
