import api from '../../../../../../../../../api';
import { downloadPlaidFileMutation } from '../../../../../../../../../graphql';
import { CustomerDocumentTypes } from '../../../utils';

export function getType(documentType) {
  switch (documentType) {
    case CustomerDocumentTypes.DOCUMENT_FRONT_PHOTO:
      return 'original_front';
    case CustomerDocumentTypes.DOCUMENT_BACK_PHOTO:
      return 'original_back';
    case CustomerDocumentTypes.DOCUMENT_SELFIE:
      return 'face';
    default:
      return null;
  }
}

export async function downloadPlaidFileAsync(idVerificationId, type) {
  const input = { idVerificationId, type };
  return api.graphql(downloadPlaidFileMutation, { input })
    .then(({ data: { fileUrl } }) => fileUrl);
}

export function downloadPlaidFile(fileUrl, fileName) {
  const downloadLink = document.createElement('a');
  downloadLink.href = fileUrl;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
