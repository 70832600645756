import React, { useMemo } from 'react';
import {
  Badge, ListGroupItem
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import { isAdmin } from '../../../../../../utils/authUtils';
import { saveBuildingModelPackage, reloadBuildingModelPackages, deleteBuildingModelPackage }
  from '../../../../../../store/actions/buildingModelPackagesActions';
import PackageActionsMenu from '../../../../../common/PackageActionsMenu';
import { DEFAULT_IMAGE, ModalNames, NavigationMainNames } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import { formatCurrency, isValidAmount } from '../../../../../../utils/currencyUtils';
import NavigationService from '../../../../../../services/NavigationService';
import './styles.scss';

const i18nOpts = { scope: 'components.admin.buildingModels.show.packages.listItem.index' };

const ListItem = ({ buildingModelPackage, ...props }) => {
  const onToggleAsDefault = (isDefault) => {
    const input = {
      id: buildingModelPackage.id,
      buildingModelId: buildingModelPackage.buildingModelId,
      packageId: buildingModelPackage.packageId,
      isDefault
    };
    props.saveBuildingModelPackage({ input })
      .then(() => {
        props.reloadBuildingModelPackages(true);
      });
  };

  const onEdit = () => {
    ModalService.open(ModalNames.BUILDING_MODEL_PACKAGE_FORM, { buildingModelPackage });
  };

  const onDelete = () => {
    const variables = { id: buildingModelPackage.id };
    props.deleteBuildingModelPackage(variables);
  };

  if (!buildingModelPackage) return null;

  const imageUrl = buildingModelPackage.package.imageUrl || DEFAULT_IMAGE;
  const packageUrl = useMemo(() => NavigationService.getNavigationShowUrl(
    buildingModelPackage.package, NavigationMainNames.PACKAGES
  ), [buildingModelPackage]);

  return (
    <ListGroupItem>
      <div className="d-flex">
        <div className="pr-3">
          <Link to={packageUrl}>
            <img
              src={imageUrl}
              alt={buildingModelPackage.package.name}
              className="building-model-package-image"
            />
          </Link>

        </div>
        <div className="flex-grow-1">
          <div>
            <Link to={packageUrl}>
              {buildingModelPackage.package.name}
            </Link>
            {buildingModelPackage.isDefault && (
              <Badge color="info" pill className="ml-3">{i18n.t('default', i18nOpts)}</Badge>
            )}
          </div>

          {isValidAmount(buildingModelPackage.price) && (
            <div>{formatCurrency(buildingModelPackage.price)}</div>
          )}
        </div>
        {isAdmin() && (
          <div>
            <PackageActionsMenu
              packageItem={buildingModelPackage}
              onToggleAsDefault={onToggleAsDefault}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          </div>
        )}
      </div>
    </ListGroupItem>
  );
};

export default connect(() => ({
}), {
  saveBuildingModelPackage,
  reloadBuildingModelPackages,
  deleteBuildingModelPackage
})(ListItem);
