import v from 'voca';

import { DEFAULT_NULL_STRING_VALUE } from '../constants';

/**
 * Strips whitespace from the start and end of a String returning an empty String if null input.
 * @param str
 * @return {string}
 */
export function stripToEmpty(str) {
  const strippedStr = v.trim(str);
  if (v.isBlank(strippedStr)) return '';
  return strippedStr;
}

/**
 * Strips whitespace from the start and end of a String returning null
 * if the String is empty ("") after the strip.
 * @param str
 * @return {string|null}
 */
export function stripToNull(str) {
  const strippedStr = v.trim(str);
  if (v.isBlank(strippedStr)) return null;
  return strippedStr;
}

/**
 * Returns substring with 40 chars max
 * @param name
 * @return {string}
 */
export function shortLongName(name) {
  return name.length > 40 ? `${name.substr(0, 37)}...` : name;
}

/**
 * Returns phone number in E164 format
 * @param phone
 * @return {string}
 */
export function phoneNumberParsed(phone) {
  return phone.replace(/[-\s]/g, '');
}

/**
 * Returns string with default value for null result
 * @param str
 * @param value
 * @return {string}
 */
export function stripToStringValue(str, value = DEFAULT_NULL_STRING_VALUE) {
  if (typeof str === 'string' || str instanceof String || !str) {
    const strippedStr = v.trim(str);
    if (v.isBlank(strippedStr)) return value;
    return strippedStr;
  }

  return str;
}

export function matchName(str, query) {
  if (v.isBlank(query)) return true;

  return v.includes(str.toLowerCase(), query.trim().toLowerCase());
}
