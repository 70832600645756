import store from '../store';
import { closeModal, openModal } from '../store/actions/modalActions';

function open(modalName, params = {}) {
  store.dispatch(openModal(modalName, params));
}

function close(modalName) {
  store.dispatch(closeModal(modalName));
}

export default {
  open,
  close
};
