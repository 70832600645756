import React, { useEffect, useState } from 'react';
import { Spinner, Table } from 'reactstrap';
import { connect } from 'react-redux';

import ListItem from './ListItem';
import ListHeader from './ListHeader';
import {
  getPreApprovalDocument,
  listVerificationDocumentsAsync,
  parseIdVerificationDocuments
} from './utils';
import { getIdVerificationWithDocuments } from '../../../../../../utils/customerUtils';
import ListEmpty from '../../../../../common/ListEmpty';
import { hasCustomerIdentity } from '../utils';

const Documents = ({ customer, currentCompany }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const hasIdentity = hasCustomerIdentity(customer);

  const loadIdVerificationDocumentsAsync = async () => {
    const idVerification = getIdVerificationWithDocuments(customer);
    if (!idVerification) return Promise.resolve([]);

    return listVerificationDocumentsAsync(idVerification.id)
      .then((items) => parseIdVerificationDocuments(
        items, customer, idVerification.id, idVerification.source
      ))
      .catch(() => Promise.resolve([]));
  };

  const loadPreApprovalAsync = async () => {
    const preApprovalDocument = getPreApprovalDocument(customer, currentCompany);
    return Promise.resolve(preApprovalDocument);
  };

  const loadDocuments = () => {
    setLoading(true);
    const promises = [];

    promises.push(loadPreApprovalAsync());
    if (hasIdentity) promises.push(loadIdVerificationDocumentsAsync());

    Promise.all(promises)
      .then((results) => {
        const newDocuments = [];
        results.forEach((result) => {
          if (result) {
            if (Array.isArray(result)) newDocuments.push(...result);
            else newDocuments.push(result);
          }
        });
        setDocuments(newDocuments);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  return (
    <div>
      {loading && (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      )}

      <ListEmpty loading={loading} items={documents} />

      {documents.length > 0 && (
        <Table bordered responsive>
          <thead>
            <ListHeader />
          </thead>
          <tbody>
            {
              documents.map((document) => (
                <ListItem key={`customer-document-${document.documentType}`} document={document} />
              ))
            }
          </tbody>
        </Table>
      )}
    </div>
  );
};

Documents.propTypes = {};

Documents.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}))(Documents);
