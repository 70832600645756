import React, { Component } from 'react';

const PRIMARY_COLOR = '#00AF8C';

class Handler extends Component {
  constructor(props) {
    super(props);

    this.onMouseDown = this.onMouseDown.bind(this);
  }

  onMouseDown(event) {
    const { props } = this;
    if (event.target.classList.contains('handler')) {
      props.onDrag(event);
    }
  }

  render() {
    const { props } = this;
    const {
      boundingBox, hasUnit
    } = props;

    const handlerStyle = {
      ...styles.handler,
      ...boundingBox,
      width: boundingBox.width + 10,
      height: boundingBox.height + 10,
      left: boundingBox.left - 5,
      top: boundingBox.top - 5
    };

    if (hasUnit) Object.assign(handlerStyle, { ...styles.withUnit });

    return (
      <div
        className="handler"
        style={handlerStyle}
        onMouseLeave={props.onMouseLeave}
        onDoubleClick={props.onDoubleClick}
        onMouseDown={this.onMouseDown}
      />
    );
  }
}

const styles = {
  handler: {
    position: 'absolute',
    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    borderTopColor: '#dedede',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: '#dedede',
    borderRightWidth: '2px',
    borderRightStyle: 'solid',
    borderRightColor: '#dedede',
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
    borderLeftColor: '#dedede',
    zIndex: 1000
  },
  withUnit: {
    borderTopColor: PRIMARY_COLOR,
    borderTopWidth: '3px',
    borderBottomColor: PRIMARY_COLOR,
    borderBottomWidth: '3px',
    borderRightColor: PRIMARY_COLOR,
    borderRightWidth: '3px',
    borderLeftColor: PRIMARY_COLOR,
    borderLeftWidth: '3px',
  },
};

export default Handler;
