import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import OfferStatusBadge from '../OfferStatusBadge';
import SignStatusBadge from '../SignStatusBadge';
import DepositStatusBadge from '../DepositStatusBadge';
import {
  ReserveStepTypes
} from '../../../../constants';
import useReserveNowWorkflowSteps from '../../../../hooks/useReserveNowWorkflowSteps';

const DealStatusBadge = ({ home }) => {
  const { offer, deposit } = home || {};

  const reserveNowSteps = useReserveNowWorkflowSteps();

  const getStatusBadge = () => {
    const reserveNowStepsInReverse = reserveNowSteps.reverse();
    for (const reserveNowStep of reserveNowStepsInReverse) {
      switch (reserveNowStep.type) {
        case ReserveStepTypes.OFFER:
          if (!v.isBlank(offer?.status)) return (<OfferStatusBadge offer={offer} />);
          break;
        case ReserveStepTypes.SIGN:
          if (!v.isBlank(offer?.signStatus)) return (<SignStatusBadge offer={offer} />);
          break;
        case ReserveStepTypes.DEPOSIT:
          if (!v.isBlank(deposit?.status)) return (<DepositStatusBadge deposit={deposit} />);
          break;
        default:
          break;
      }
    }
    return null;
  };

  const statusBadge = useMemo(getStatusBadge, [home]);

  return (
    <div>
      {statusBadge}
    </div>
  );
};

DealStatusBadge.propTypes = {
  home: PropTypes.objectOf(PropTypes.any)
};

DealStatusBadge.defaultProps = {
  home: null
};

export default DealStatusBadge;
