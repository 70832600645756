import _ from 'lodash';

import { formatCurrency } from './currencyUtils';
import {
  findPriceablePriceRule, getFloorOptionPrice, getModelBasePrice,
  getModelElevationPrice,
  getModelPackagePrice, getModelPalettePrice, getSpecificationPrice
} from './priceRuleUtils';
import { PriceableTypes } from '../constants';

const DependencyOperators = Object.freeze({
  IS: 'is',
  IS_NOT: 'isNot'
});

const QuickPossessionKeys = Object.freeze({
  COMMUNITY: 'community',
  MODEL: 'model',
  ELEVATION: 'elevation',
  PACKAGE: 'pack',
  PALETTE: 'palette',
  SPECIFICATIONS: 'specifications',
  FLOOR_OPTIONS: 'floorOptions'
});

export function toggleFloorOptions(
  floorOption, floorGroupId, enableMultipleSelection, floorOptions
) {
  let floorOptionsMap = { ...floorOptions };
  if (enableMultipleSelection) {
    let selectedFloorOptions = floorOptionsMap[floorGroupId] || [];
    const find = selectedFloorOptions.find((f) => f.id === floorOption.id);
    if (find) selectedFloorOptions = selectedFloorOptions.filter((f) => f.id !== floorOption.id);
    else selectedFloorOptions.push(floorOption);
    floorOptionsMap[floorGroupId] = selectedFloorOptions;
  } else floorOptionsMap[floorGroupId] = [floorOption];

  floorOptionsMap = removeFloorOptionDependencies(floorOptionsMap);

  return floorOptionsMap;
}

export function removeFloorOptionDependencies(floorOptionsMap) {
  const newFloorOptionsMap = { ...floorOptionsMap };
  const floorGroupIds = Object.keys(floorOptionsMap);

  let isThereAnyRemoved = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const floorGroupId of floorGroupIds) {
    let floorOptions = floorOptionsMap[floorGroupId];
    floorOptions = floorOptions.filter((floorOption) => {
      const enabled = isFloorOptionEnabled(floorOption, floorOptionsMap);
      if (!enabled) isThereAnyRemoved = true;
      return enabled;
    });
    newFloorOptionsMap[floorGroupId] = floorOptions;
  }

  if (isThereAnyRemoved) return removeFloorOptionDependencies(newFloorOptionsMap);
  return newFloorOptionsMap;
}

export function isFloorOptionEnabled(floorOption, selectedFloorOptions) {
  const { dependencies } = floorOption;

  if (dependencies.length === 0) return true;

  return dependencies.some((dependency) => {
    const { values } = dependency;
    const floorOptions = selectedFloorOptions[dependency.group] || [];
    const selectedFloorOptionIds = floorOptions.map((fo) => fo.id);
    let enabled;

    switch (dependency.operator) {
      case DependencyOperators.IS:
        enabled = values.some((v) => selectedFloorOptionIds.includes(v));
        break;

      case DependencyOperators.IS_NOT:
        enabled = values.every((v) => !selectedFloorOptionIds.includes(v));
        break;

      default:
        enabled = false;
    }

    return enabled;
  });
}

export function joinNameAndPrice(name, price) {
  if (!name && !price) return null;
  return `${name} ${price ? `${formatCurrency(price)}` : ''}`;
}

export function getFloorOptions(floorOptions) {
  return _.groupBy(floorOptions, (d) => d.floorGroup.id);
}

export function getSelectedFloorOptions(floorOptions) {
  return Object.values(floorOptions).flat(1);
}

export function getCommunityModel(communityId, modelId, communityModels) {
  return communityModels.find((cm) => cm?.community?.id
    === Number.parseInt(communityId, 10)
    && cm?.model?.id === Number.parseInt(modelId, 10));
}

export function resetValues(prevQuickPossession, quickPossession, resetKey) {
  const properties = Object.values(QuickPossessionKeys);
  const newQuickPossession = { ...quickPossession };
  let resetValue = false;
  if (resetKey === QuickPossessionKeys.MODEL || resetKey === QuickPossessionKeys.COMMUNITY) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of properties) {
      if (resetValue) newQuickPossession[key] = null;
      if (key === resetKey
        && prevQuickPossession[key]?.id !== quickPossession[key]?.id) resetValue = true;
    }
  }
  return newQuickPossession;
}

// Filter model packages for community
export function filterModelPackages(community, model) {
  if (!community || !model) return [];

  const { modelPackages } = model;

  return modelPackages.filter((modelPackage) => {
    const specPackage = modelPackage.package;
    const priceRule = findPriceablePriceRule(community, model, specPackage.id,
      PriceableTypes.PACKAGE);
    return priceRule != null;
  });
}

export function filterModelElevations(community, model) {
  if (!community || !model) return [];

  const { elevations } = model;

  return elevations.filter((elevation) => {
    const priceRule = findPriceablePriceRule(community, model,
      elevation.id, PriceableTypes.ELEVATION);
    return priceRule != null;
  });
}

export function filterModelPalettes(community, model) {
  if (!community || !model) return [];

  const { palettes } = model;

  return palettes.filter((palette) => {
    const priceRule = findPriceablePriceRule(community, model,
      palette.id, PriceableTypes.PALETTE);
    return priceRule != null;
  });
}

export function filterSpecifications(community, model) {
  if (!community || !model) return [];

  const { specifications } = model;

  return specifications.filter((specification) => {
    const priceRule = findPriceablePriceRule(community, model,
      specification.id, PriceableTypes.SPECIFICATION);
    return priceRule != null;
  });
}

export function getTotalPrice(quickPossession, floorOptions) {
  const {
    community, model, elevation, pack, palette, specifications
  } = quickPossession;
  const modelPrice = getModelBasePrice(community, model);
  const elevationPrice = getModelElevationPrice(community, model, elevation);
  const packagePrice = getModelPackagePrice(community, model, pack);
  const palettePrice = getModelPalettePrice(community, model, palette);
  const selectedFloorOptions = getSelectedFloorOptions(floorOptions);
  const totalFloorOptions = _.reduce(
    selectedFloorOptions, (sum, i) => {
      const price = getFloorOptionPrice(community, model, i);
      return sum + (price || 0);
    }, 0
  );
  const totalSpecifications = _.reduce(specifications, (sum, i) => {
    const price = getSpecificationPrice(community, model, i);
    return sum + (price || 0);
  }, 0);
  const lotPrice = model?.lotWorksSettings?.minLotPrice || 0;

  return modelPrice + elevationPrice + packagePrice
    + palettePrice + totalFloorOptions + totalSpecifications + lotPrice;
}

export function getTotalBeds(quickPossession, floorOptions) {
  const { model } = quickPossession;
  const selectedFloorOptions = getSelectedFloorOptions(floorOptions);

  const modelBeds = model?.beds || 0;
  const extraBeds = _.reduce(selectedFloorOptions, (sum, i) => sum + (i.bedsChange || 0), 0);
  return modelBeds + extraBeds;
}

export function getTotalBaths(quickPossession, floorOptions) {
  const { model } = quickPossession;
  const selectedFloorOptions = getSelectedFloorOptions(floorOptions);

  const modelBaths = model?.baths || 0;
  const extraBaths = _.reduce(selectedFloorOptions, (sum, i) => sum + (i.bathsChange || 0), 0);
  return modelBaths + extraBaths;
}

export function getQPTotalBeds(quickPossession) {
  return quickPossession.customBeds
    || getTotalBeds(quickPossession, quickPossession.floorOptions || []);
}

export function getQPTotalBaths(quickPossession) {
  return quickPossession.customBaths
    || getTotalBaths(quickPossession, quickPossession.floorOptions || []);
}

export function getQPTotalPrice(quickPossession) {
  return quickPossession.customPrice
    || getTotalPrice(quickPossession, quickPossession.floorOptions || []);
}
