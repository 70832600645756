import React from 'react';
import { CustomInput as BaseCustomSwitch } from 'reactstrap';

import './styles.scss';

const CustomSwitch = ({ ...props }) => (
  <BaseCustomSwitch
    type="switch"
    {...props}
  />
);

CustomSwitch.propTypes = {};

CustomSwitch.defaultProps = {};

export default CustomSwitch;
