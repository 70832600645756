import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ShowMoreButton from './ShowMoreButton';

const ShowMoreContent = ({
  items, renderItem, initialItemsToShow, showMoreButtonExtraClass
}) => {
  const [showAll, setShowAll] = useState(false);
  const visibleItems = items.slice(0, initialItemsToShow);
  const hiddenItems = items.slice(initialItemsToShow);

  const onShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  return (
    <>
      {
        visibleItems.map((item) => renderItem(item))
      }

      {hiddenItems.length > 0 && (
        <>
          {showAll && (
            <>
              {
                hiddenItems.map((item) => renderItem(item))
              }
            </>
          )}

          <ShowMoreButton
            showAll={showAll}
            onClick={onShowAll}
            extraClass={showMoreButtonExtraClass}
          />
        </>
      )}
    </>
  );
};

ShowMoreContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  renderItem: PropTypes.func.isRequired,
  initialItemsToShow: PropTypes.number,
  showMoreButtonExtraClass: PropTypes.string
};

ShowMoreContent.defaultProps = {
  items: [],
  initialItemsToShow: 5,
  showMoreButtonExtraClass: ''
};

export default ShowMoreContent;
