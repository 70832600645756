import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from '../api';

export function fetchCompaniesAsync() {
  return api.graphql(`
    query ListCompanies {
      result: listCompanies(pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `)
    .then(({ data: { result: { items } } }) => items);
}

const useFetchCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setLoading(true);

    fetchCompaniesAsync()
      .then((items) => setCompanies(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { companies, loading };
};

export default useFetchCompanies;
