import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import {
  Button,
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { HiShare } from 'react-icons/hi';

import Breadcrumbs from '../../../common/Breadcrumbs';
import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../utils/authUtils';
import { getQuickPossession } from '../../../../store/actions/quickPossessionActions';
import Overview from './Overview';
import Requests from './Requests';
import ModalService from '../../../../services/ModalService';
import { ModalNames, NavigationMainNames } from '../../../../constants';
import Images from './Images';
import DirectLinkModal from '../../../global/DirectLinkModal';
import { hasCompanyDomain } from '../../../../utils/companyUtils';
import QRCodeModal from '../../../global/QRCodeModal';
import Modifications from '../../../global/Modifications';
import NavigationService from '../../../../services/NavigationService';
import QuickPossessionFormModal from '../components/QuickPossessionFormModal';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.quickPossessions.show.index' };

const Tabs = Object.freeze({
  OVERVIEW: 'overview',
  IMAGES: 'images',
  REQUESTS: 'requests',
  MODIFICATIONS: 'modifications'
});

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: Tabs.OVERVIEW,
    };

    this.onChangeTab = this.onChangeTab.bind(this);
    this.buildDirectLink = this.buildDirectLink.bind(this);
    this.onOpenDirectLinkModal = this.onOpenDirectLinkModal.bind(this);
    this.getQuickPossessionName = this.getQuickPossessionName.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.loadQuickPossession();
  }

  onChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  onOpenDirectLinkModal() {
    const path = this.buildDirectLink();
    const name = this.getQuickPossessionName();

    const params = {
      path,
      label: name
    };
    ModalService.open(ModalNames.DIRECT_LINK_MODAL, params);
  }

  onEdit() {
    const { quickPossession } = this.props;
    ModalService.open(ModalNames.QUICK_POSSESSION_FORM, { id: quickPossession.id });
  }

  getQuickPossessionName() {
    const { quickPossession } = this.props;
    const { model, community } = quickPossession;
    return i18n.t('name', { ...i18nOpts, modelName: model.name, communityName: community.name });
  }

  loadQuickPossession() {
    const { props } = this;
    const { id } = this.props;

    const parsedId = parseInt(id, 10);

    props.getQuickPossession({ id: parsedId })
      .then(() => {
        const { quickPossession } = this.props;
        const parsedQuickPossession = { ...quickPossession, name: this.getQuickPossessionName() };

        NavigationService.addItem(parsedQuickPossession, NavigationMainNames.QUICK_POSSESSIONS);
      });
  }

  buildDirectLink() {
    const { props } = this;
    const { quickPossession } = props;

    return `/quickPossession/${quickPossession.hashId}`;
  }

  render() {
    const { quickPossession, loading, currentCompany } = this.props;
    const { activeTab } = this.state;
    const isAdmin = isCompanyAdmin() || isSalesLead() || isMasterAdmin();

    if (!quickPossession || loading) return <Loading loading fullScreen />;

    const name = this.getQuickPossessionName();

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <Breadcrumbs items={[
              { url: '/admin/quickPossessions', text: i18n.t('title', i18nOpts) },
              {
                url: `/admin/quickPossessions/${quickPossession.id}`,
                text: name
              }
            ]}
            />
          </div>
          <div className="d-flex align-items-center">
            {(isMasterAdmin() || isCompanyAdmin() || isSalesLead()) && (
              <>
                {hasCompanyDomain(currentCompany) && (
                  <Button color="secondary" onClick={this.onOpenDirectLinkModal}>
                    <HiShare size="1rem" className="col-light mr-2" />
                    <span>{i18n.t('buttons.shareLink')}</span>
                  </Button>
                )}
                <Button color="primary" onClick={this.onEdit} className="ml-3">
                  <i className="far fa-edit mr-2" />
                  {i18n.t('buttons.edit')}
                </Button>
              </>
            )}
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.OVERVIEW ? 'active' : ''}`}
              onClick={() => this.onChangeTab(Tabs.OVERVIEW)}
            >
              {i18n.t('tabs.overview', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.IMAGES ? 'active' : ''}`}
              onClick={() => this.onChangeTab(Tabs.IMAGES)}
            >
              {i18n.t('tabs.images', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.REQUESTS ? 'active' : ''}`}
              onClick={() => this.onChangeTab(Tabs.REQUESTS)}
            >
              {i18n.t('tabs.requests', i18nOpts)}
            </NavLink>
          </NavItem>
          { isAdmin && (
            <NavItem>
              <NavLink
                className={`${activeTab === Tabs.MODIFICATIONS ? 'active' : ''}`}
                onClick={() => this.onChangeTab(Tabs.MODIFICATIONS)}
              >
                {i18n.t('tabs.modifications', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab} className="py-4">
          <TabPane tabId={Tabs.OVERVIEW}>
            <Overview quickPossession={quickPossession} />
          </TabPane>
          <TabPane tabId={Tabs.IMAGES}>
            <Images quickPossession={quickPossession} />
          </TabPane>
          <TabPane tabId={Tabs.REQUESTS}>
            <Requests requests={quickPossession?.requests} />
          </TabPane>
          {isAdmin && (
            <TabPane tabId={Tabs.MODIFICATIONS}>
              <Modifications instance={quickPossession} />
            </TabPane>
          )}
        </TabContent>

        <DirectLinkModal />
        <QRCodeModal />
        <QuickPossessionFormModal />
      </div>
    );
  }
}

export default connect((store) => ({
  quickPossession: store.quickPossessions.quickPossession,
  loading: store.quickPossessions.getQuickPossession.loading || false,
  currentCompany: store.companies.currentCompany
}), {
  getQuickPossession
})(Show);
