import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';

import {
  getLastSevenDaysRange,
  getLastSevenDaysRangeOutput,
  setDaysRangeInput
} from '../../../utils/dateUtils';
import VisitorLeadsTable from './VisitorLeadsTable';
import VisitorLeadsChart from './VisitorLeadsChart';
import DateFilter from '../../common/DateFilter';

const i18nOpts = { scope: 'components.admin.masterAdminDashboard.index' };

const MasterAdminDashboard = () => {
  const [dateRange, setDateRange] = useState(getLastSevenDaysRange());
  const [customDateRange, setCustomDateRange] = useState(getLastSevenDaysRangeOutput());

  const onPickedDate = (event, picker) => {
    const pickedDate = [
      picker.startDate.startOf('day').toDate(),
      picker.endDate.endOf('day').toDate()
    ];

    setDateRange(pickedDate);
    setCustomDateRange(setDaysRangeInput(pickedDate));
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />
      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
      </div>

      <div className="mb-4">
        <DateFilter dateRange={dateRange} onDatePicked={onPickedDate} containerClassName="my-4" />
      </div>

      <VisitorLeadsTable customDateRange={customDateRange} />

      <VisitorLeadsChart customDateRange={customDateRange} />
    </div>
  );
};

MasterAdminDashboard.propTypes = {};

MasterAdminDashboard.defaultProps = {};

export default MasterAdminDashboard;
