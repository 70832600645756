import React, { Component } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { navigate, Link } from 'gatsby';
import {
  Form, Button, Spinner
} from 'reactstrap';
import v from 'voca';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../../common/Breadcrumbs';
import { getExternalDataSource, saveExternalDataSource } from '../../../../../store/actions/externalDataSourceActions';
import { hasFormValues } from '../../../../../utils/formUtils';
import { ExternalDataSourceProviders, SalesSimplicityApiUrl, MarondaHomesApiUrl } from '../../../../../constants';
import { getError } from '../../../../../utils/requestUtils';
import SalesSimplicityFormFields from '../SalesSimplicity/FormFields';
import MarondaHomesFormFields from '../MarondaHomes/FormFields';
import { checkDataProvider } from '../utils';

const i18nOpts = { scope: 'components.admin.externalDataSources.form.index' };

class ExternalDataSourceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      saving: false,
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.loadExternalDataSource();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;

    this.setState({ form });
  }

  onSave(event) {
    event.preventDefault();

    const { props } = this;
    const { form } = this.state;
    const { dataProvider, currentCompany } = this.props;

    const input = {
      companyId: currentCompany.id,
      dataProvider,
      type: 'ALL',
      headers: {},
      mappedFields: {}
    };

    if (this.isSalesSimplicity()) {
      input.url = SalesSimplicityApiUrl;
      input.headers = { Username: form.username || '', Password: form.password || '', Token: form.token || '' };
    } else if (this.isMarondaHomes()) {
      input.url = MarondaHomesApiUrl;
      input.headers = {
        ApiKey: form.apiKey || '',
        ProxyHost: form.proxyHost || '',
        ProxyPort: form.proxyPort || '',
        ProxyUsername: form.proxyUsername || '',
        ProxyPassword: form.proxyPassword || ''
      };
    }

    if (form.id) input.id = form.id;

    const variables = { input };

    props.saveExternalDataSource(variables)
      .then(() => navigate(`/admin/integrations/externalDataSources/${dataProvider}`))
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);

        this.setState({ saving: false, error });
      })
      .finally(() => this.setState({ saving: false }));
  }

  loadExternalDataSource() {
    const { props } = this;
    const { dataProvider, currentCompany } = props;

    checkDataProvider(dataProvider);

    props.getExternalDataSource({ companyId: currentCompany.id, dataProvider })
      .then(() => {
        const { externalDataSource } = this.props;

        if (externalDataSource) {
          let form = {};

          if (this.isSalesSimplicity()) {
            const {
              Username: username, Password: password, Token: token
            } = externalDataSource.headers;
            form = {
              id: externalDataSource.id,
              username,
              password,
              token
            };
          } else if (this.isMarondaHomes()) {
            const {
              ApiKey: apiKey,
              ProxyHost: proxyHost,
              ProxyPort: proxyPort,
              ProxyUsername: proxyUsername,
              ProxyPassword: proxyPassword
            } = externalDataSource.headers;
            form = {
              id: externalDataSource.id,
              apiKey,
              proxyHost,
              proxyPort,
              proxyUsername,
              proxyPassword
            };
          }
          this.setState({ form });
        }
      })
      .catch(() => {});
  }

  isSalesSimplicity() {
    const { dataProvider } = this.props;
    return dataProvider === ExternalDataSourceProviders.SALES_SIMPLICITY;
  }

  isMarondaHomes() {
    const { dataProvider } = this.props;
    return dataProvider === ExternalDataSourceProviders.MARONDA_HOMES;
  }

  render() {
    const { dataProvider } = this.props;
    const { form, saving, error } = this.state;

    const hasValues = hasFormValues(form);
    const dataProviderTitle = i18n.t(`dataProviders.${dataProvider}`, i18nOpts);

    let formFields;
    if (this.isSalesSimplicity()) {
      formFields = (
        <SalesSimplicityFormFields
          form={form}
          onTextChange={this.onTextChange}
          error={error}
        />
      );
    } else if (this.isMarondaHomes()) {
      formFields = (
        <MarondaHomesFormFields
          form={form}
          onTextChange={this.onTextChange}
          error={error}
        />
      );
    }

    return (
      <div>
        <Helmet title={dataProviderTitle} />

        <Breadcrumbs items={[
          { url: '/admin/integrations', text: i18n.t('integrations', i18nOpts) },
          { url: '/admin/integrations/externalDataSources', text: i18n.t('externalDataSources', i18nOpts) },
          { url: '/admin/integrations/externalDataSources', text: dataProviderTitle }
        ]}
        />

        <div className="mt-4">
          <Form>
            {formFields}
            <Button color="primary" onClick={this.onSave} className="mr-3" disabled={saving || !hasValues}>
              {saving && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.save')}
            </Button>
            <Link to={`/admin/integrations/externalDataSources/${dataProvider}`} className="btn btn-outline-secondary mr-3">
              {i18n.t('buttons.cancel')}
            </Link>
          </Form>
        </div>
      </div>
    );
  }
}

export default connect((store) => ({
  externalDataSource: store.externalDataSources.externalDataSource,
  currentCompany: store.companies.currentCompany
}), {
  getExternalDataSource,
  saveExternalDataSource
})(ExternalDataSourceForm);
