import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

const ModalCloseButton = ({ onClick }) => (
  <button type="button" onClick={onClick} className="close" aria-label="Close">
    <MdClose size="1.5rem" />
  </button>
);

ModalCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

ModalCloseButton.defaultProps = {};

export default ModalCloseButton;
