import React from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button
} from 'reactstrap';

import { ModalNames, SalableTypes } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import SellersManagement from '../../../../../global/SellersManagement';

const i18nOpts = { scope: 'components.admin.communities.show.productTypes.salesRepresentativesModal.index' };

function onClose() {
  ModalService.close(ModalNames.PRODUCT_TYPE_SALES_REPRESENTATIVES);
}

const SalesRepresentativesModal = ({
  opened, params: { communityProductType }
}) => (
  <Modal size="lg" isOpen={opened} contentClassName="sales-representatives-modal-container">
    <ModalHeader>{i18n.t('title', i18nOpts)}</ModalHeader>
    <ModalBody>
      {communityProductType && (
        <SellersManagement
          salableId={communityProductType.id}
          salableType={SalableTypes.COMMUNITY_PRODUCT_TYPE}
        />
      )}
    </ModalBody>
    <ModalFooter>
      <Button outline color="secondary" onClick={onClose}>
        {i18n.t('buttons.cancel')}
      </Button>
    </ModalFooter>
  </Modal>
);

SalesRepresentativesModal.propTypes = {};

SalesRepresentativesModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.PRODUCT_TYPE_SALES_REPRESENTATIVES]?.opened || false,
  params: store.modals[ModalNames.PRODUCT_TYPE_SALES_REPRESENTATIVES]?.params || {},
}))(SalesRepresentativesModal);
