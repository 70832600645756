import React from 'react';
import i18n from 'i18n-js';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';

import MonthlyAmountsChart from '../components/MonthlyAmountsChart';
import { Colors } from '../../../../../constants';
import MonthlyAmountsTable from '../components/MonthlyAmountsTable';
import MonthlyLoanPaymentsTable from './MonthlyLoanPaymentsTable';

const i18nOpts = { scope: 'components.admin.customers.financialReport.liabilitiesBreakdown.index' };

const LiabilitiesSection = ({ financialReport, customer }) => {
  const { companyContact } = customer;
  const { affordability } = companyContact;
  const { assessedAt: reportDate } = affordability;

  const {
    allLoanPayments, monthlyLoanPaymentTotalAmounts,
    monthlyLoanPaymentTotalCounts
  } = financialReport;

  const { loanPaymentList } = allLoanPayments;

  const chartDatasets = [];
  let colorIndex = 100;

  loanPaymentList.forEach((loanPayment) => {
    chartDatasets.push({
      label: i18n.t(loanPayment.loanType, i18nOpts),
      data: loanPayment.monthlyPaymentAmounts,
      color: Colors[`PRIMARY_${colorIndex}`]
    });
    colorIndex += 100;
  });

  const monthlyTotalLoanPayments = monthlyLoanPaymentTotalAmounts.map((amount, index) => ({
    amount,
    count: monthlyLoanPaymentTotalCounts[index]
  }));

  return (
    <div>
      <Nav tabs className="mb-4">
        <NavItem>
          <NavLink active className="font-16">
            {i18n.t('tabs.loanPayments', i18nOpts)}
          </NavLink>
        </NavItem>
      </Nav>

      <MonthlyAmountsTable monthlyTotals={monthlyTotalLoanPayments} />

      <div style={{ height: '300px' }}>
        <MonthlyAmountsChart titleY={i18n.t('titleY', i18nOpts)} datasets={chartDatasets} reportDate={reportDate} />
      </div>

      <div className="page-break-always" />

      <div>
        <MonthlyLoanPaymentsTable />
      </div>
    </div>
  );
};

LiabilitiesSection.propTypes = {};

LiabilitiesSection.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
  customer: store.customers.customer
}))(LiabilitiesSection);
