import { CLEAN_IMPORTED_DATA, SET_IMPORTED_DATA } from './types';

export function setImportedData(importedData) {
  return {
    type: SET_IMPORTED_DATA,
    payload: { importedData }
  };
}

export function cleanImportedData(extraProps = {}) {
  return {
    type: CLEAN_IMPORTED_DATA,
    payload: Promise.resolve(extraProps)
  };
}
