export function buildNewModel() {
  return {
    id: 0,
    name: null,
    communities: [],
    elevations: [],
    modelPackages: [],
    palettes: [],
    specifications: [],
    floors: [],
  };
}
