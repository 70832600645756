import React from 'react';
import PropTypes from 'prop-types';

import { IGNORED_KEYS, isChangedValue, sanitizeValue } from './utils';
import ChangedValue from './ChangedValue';

const InstanceModificationsInfo = ({ modification, displayChanges }) => {
  const { initialValue, finalValue } = modification;
  const initialKeys = Object.keys(initialValue);
  const finalKeys = Object.keys(finalValue);
  const finalValueMerged = { ...initialValue, ...finalValue };

  const keys = [...new Set([...initialKeys, ...finalKeys])];
  const sanitizedKeys = keys
    .filter((k) => !IGNORED_KEYS.includes(k) && isChangedValue(k, initialValue, finalValueMerged));

  return (
    <table className="font-italic instance-modification-info table-borderless">
      <tbody>
        {
          sanitizedKeys.map((k) => (
            <tr key={`modification-${modification.id}-${k}-${displayChanges ? 'new-values' : 'prev-values'}`}>
              <td className="label">
                <div className="text-capitalize">{k}</div>
              </td>
              <td>
                { displayChanges ? (
                  <ChangedValue
                    oldValue={initialValue[k]}
                    newValue={finalValue[k]}
                  />
                ) : (
                  <span key={`modification-value-${modification.id}-${k}-'prev-value`}>
                    {sanitizeValue(initialValue[k])}
                  </span>
                )}
              </td>
            </tr>
          ))
      }
      </tbody>
    </table>
  );
};

InstanceModificationsInfo.propTypes = {
  modification: PropTypes.objectOf(PropTypes.any).isRequired,
  displayChanges: PropTypes.bool
};

InstanceModificationsInfo.defaultProps = {
  displayChanges: false
};

export default InstanceModificationsInfo;
