import React from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import ExportButton from './ExportButton';
import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';

const i18nOpts = { scope: 'components.admin.integrations.exportData.index' };

const ExportData = () => (
  <div>
    <Helmet title={i18n.t('title', i18nOpts)} />

    <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

    <ExportButton />
  </div>
);

export default ExportData;
