import React from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { HiCode } from 'react-icons/hi';
import {
  BiCloudUpload,
  BiCloudDownload,
  BiDollarCircle,
  BiArrowToBottom, BiCodeBlock
} from 'react-icons/bi';
import { FaSitemap, FaDatabase } from 'react-icons/fa';
import { VscFileCode } from 'react-icons/vsc';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { IoMdLogIn } from 'react-icons/io';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';

import { isMasterAdmin, isTecSupport } from '../../../utils/authUtils';
import HubSpotIcon from '../../../assets/icons/hubspot.svg';
import ZapierIcon from '../../../assets/icons/zapier.svg';
import WebhookIcon from '../../../assets/icons/WebhookIcon';
import SendGridIcon from '../../../assets/icons/SendGridIcon';
import { getAPIDocUrl } from '../../../layout/admin/Sidebar/utils';
import IntegrationButton from './IntegrationButton';

const i18nOpts = { scope: 'components.admin.integrations.index' };

const Integrations = () => {
  const apiDocUrl = getAPIDocUrl();

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="mb-4">
        <span className="font-24">{i18n.t('title', i18nOpts)}</span>
      </div>

      <Row>
        <Col sm="12" md="6">
          <IntegrationButton
            innerId="btn-import-data"
            path="/admin/integrations/importData"
            label={i18n.t('buttons.importData', i18nOpts)}
            icon={<BiCloudUpload size="2.8rem" className="mr-4" />}
          />

          <IntegrationButton
            innerId="btn-export-data"
            path="/admin/integrations/exportData"
            label={i18n.t('buttons.exportData', i18nOpts)}
            icon={<BiCloudDownload size="2.8rem" className="mr-4" />}
          />

          <IntegrationButton
            innerId="btn-embed-widget"
            path="/admin/integrations/embedWidget"
            label={i18n.t('buttons.embedWidget', i18nOpts)}
            icon={<VscFileCode size="2.6rem" className="mr-4" />}
          />

          <IntegrationButton
            path="/admin/integrations/tracking"
            label={i18n.t('buttons.tracking', i18nOpts)}
            icon={<AiOutlineFundProjectionScreen size="2.8rem" className="mr-4" />}
          />

          <IntegrationButton
            path={apiDocUrl}
            label={i18n.t('buttons.apiDocumentation', i18nOpts)}
            icon={<BiCodeBlock size="2.6rem" className="mr-4" />}
          />

          <IntegrationButton
            path="/admin/integrations/webhooks"
            label={i18n.t('buttons.webhooks', i18nOpts)}
            icon={(
              <div className="d-inline-block mr-4">
                <WebhookIcon width={40} height={40} />
              </div>
            )}
          />

          <IntegrationButton
            path="/admin/integrations/customCode"
            label={i18n.t('buttons.customCode', i18nOpts)}
            icon={<HiCode size="2.8rem" className="mr-4" />}
          />
        </Col>

        <Col sm="12" md="6">
          <IntegrationButton
            innerId="btn-social-media"
            path="/admin/integrations/socialMedia"
            label={i18n.t('buttons.socialMedia', i18nOpts)}
            icon={<IoMdLogIn size="2.8rem" className="mr-4" />}
          />

          <IntegrationButton
            innerId="btn-payment-methods"
            path="/admin/integrations/paymentMethods"
            label={i18n.t('buttons.paymentMethods', i18nOpts)}
            icon={<BiDollarCircle size="2.8rem" className="mr-4" />}
          />

          <IntegrationButton
            innerId="btn-docusign"
            path="/admin/integrations/docuSign"
            label={i18n.t('buttons.docuSign', i18nOpts)}
            icon={<BiArrowToBottom size="2.6rem" className="mr-4" />}
          />

          {(isMasterAdmin() || isTecSupport()) && (
          <IntegrationButton
            innerId="btn-sendgrid"
            path="/admin/integrations/sendGrid"
            label={i18n.t('buttons.sendGrid', i18nOpts)}
            icon={(
              <div className="d-inline-block mr-4">
                <SendGridIcon width={45} height={45} />
              </div>
                )}
          />
          )}

          <IntegrationButton
            innerId="btn-hubspot"
            path="/admin/integrations/hubSpot"
            label={i18n.t('buttons.hubSpot', i18nOpts)}
            icon={(
              <img
                src={HubSpotIcon}
                alt={i18n.t('buttons.hubSpot', i18nOpts)}
                width={40}
                className="mr-4"
              />
            )}
          />

          <IntegrationButton
            innerId="btn-zapier"
            path="/admin/integrations/zapier"
            label={i18n.t('buttons.zapier', i18nOpts)}
            icon={(
              <img
                src={ZapierIcon}
                alt={i18n.t('buttons.zapier', i18nOpts)}
                width={40}
                className="mr-4"
              />
            )}
          />
          <IntegrationButton
            innerId="btn-google-sitemap"
            path="/admin/integrations/googleSitemap"
            label={i18n.t('buttons.googleSitemap', i18nOpts)}
            icon={<FaSitemap size="2.8rem" className="mr-4" />}
          />

          {(isMasterAdmin() || isTecSupport()) && (
          <IntegrationButton
            innerId="btn-external-data-sources"
            path="/admin/integrations/externalDataSources"
            label={i18n.t('buttons.externalDataSources', i18nOpts)}
            icon={<FaDatabase size="2.8rem" className="mr-4" />}
          />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {})(Integrations);
