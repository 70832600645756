import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import styles from './AccountMenuItem.module.scss';
import LogOutIcon from '../../../../assets/icons/sidebar-nav/LogOutIcon';
import AuthService from '../../../../services/AuthService';
import ProfileIcon from '../../../../assets/icons/ProfileIcon';

const AccountMenuItem = ({ currentUser }) => {
  const onLogout = () => {
    AuthService.logout().then();
  };

  const onProfileClick = () => {
    navigate('/admin/myProfile');
  };

  if (!currentUser) return null;

  return (
    <div className={classNames('d-flex align-items-center', styles.container)}>
      <div className="flex-grow-1">
        <div className="d-inline-flex align-items-center" onClick={onProfileClick} role="button" aria-hidden>
          <div>
            <ProfileIcon />
          </div>
          <div className="px-2">
            <small className="font-weight-600">{currentUser.name}</small>
          </div>
        </div>
      </div>
      <div>
        <div onClick={onLogout} role="button" aria-hidden className="p-2">
          <LogOutIcon />
        </div>
      </div>
    </div>
  );
};

AccountMenuItem.propTypes = {};

AccountMenuItem.defaultProps = {};

export default connect((store) => ({
  currentUser: store.users.currentUser
}))(AccountMenuItem);
