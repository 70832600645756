import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, Col, Form, FormGroup, Input, Label, Row, Spinner
} from 'reactstrap';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import v from 'voca';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import classnames from 'classnames';

import StrongPasswordInput from '../../../../ui/StrongPasswordInput';
import api from '../../../../../api';
import { changePasswordQuery } from '../../../../../graphql';
import { getError } from '../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../ui/InputError';

const i18nOpts = { scope: 'components.admin.myProfile.show.security.index' };

async function changePasswordAsync(variables) {
  return api.graphql(changePasswordQuery, variables)
    .then(({ data: { changed } }) => Promise.resolve(changed));
}

const Security = ({ currentUser, redirect }) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState({});
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const [saving, setSaving] = useState(false);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onValidate = (valid) => {
    setIsStrongPassword(valid);
  };

  const onSave = () => {
    setSaving(true);

    const input = { id: currentUser.id, ...form };
    changePasswordAsync({ input })
      .then((changed) => {
        setError({});

        if (changed) {
          toast.success(i18n.t('messages.success', i18nOpts));
          if (redirect) navigate('/admin/myProfile/form');
        }
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const canSave = isStrongPassword
    && !v.isBlank(form.currentPassword)
    && !v.isBlank(form.confirmPassword);

  return (
    <Form>
      <Row className="mb-4">
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="currentPassword">{i18n.t('currentPassword', i18nOpts)}</Label>
            <Input
              type="password"
              name="currentPassword"
              id="currentPassword"
              value={form.currentPassword || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'currentPassword')}
            />
            <InputError name="currentPassword" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="newPassword">{i18n.t('newPassword', i18nOpts)}</Label>
            <StrongPasswordInput
              name="newPassword"
              onChange={onTextChange}
              afterValidate={onValidate}
              inputClassName={classnames({ 'is-invalid': isInputInvalid(error, 'newPassword') })}
            />
            <InputError name="newPassword" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="confirmPassword">{i18n.t('confirmPassword', i18nOpts)}</Label>
            <Input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={form.confirmPassword || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'confirmPassword')}
            />
            <InputError name="confirmPassword" error={error} />
          </FormGroup>
        </Col>
      </Row>

      <Button color="primary" onClick={onSave} disabled={saving || !canSave}>
        {saving && (<Spinner size="sm" className="mr-2" />)}
        {i18n.t('buttons.save')}
      </Button>
    </Form>
  );
};

Security.propTypes = {
  redirect: PropTypes.bool
};

Security.defaultProps = {
  redirect: false
};

export default connect((store) => ({
  currentUser: store.users.currentUser
}), {})(Security);
