import React, { useEffect, useState } from 'react';
import {
  ListGroup, ListGroupItem, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown
} from 'reactstrap';
import { BiTrash } from 'react-icons/bi';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import { DEFAULT_IMAGE, NavigationMainNames } from '../../../../../constants';
import { isAdmin } from '../../../../../utils/authUtils';
import { confirmDelete } from '../../../../global/confirmAlert';
import {
  fetchBuildingModelModelsAsync,
  fetchBuildingModelsAsync, removeBuildingModelModelsAsync,
  saveBuildingModelModelsAsync
} from './utils';
import { setReloadBuildingFloorUnits } from '../../../../../store/actions/buildingFloorActions';
import NavigationService from '../../../../../services/NavigationService';
import './styles.scss';

const i18nOpts = { scope: 'components.admin.projectBuildings.show.models.index' };

const Models = ({ projectBuilding, ...props }) => {
  const [buildingModels, setBuildingModels] = useState([]);
  const [buildingModelModels, setBuildingModelModels] = useState([]);

  useEffect(() => {
    loadBuildingModels();
    loadBuildingModelModels();
  }, []);

  const onAddModel = (buildingModel) => {
    const variables = {
      input: {
        projectBuildingId: projectBuilding.id,
        buildingModelId: buildingModel.id
      }
    };
    saveBuildingModelModelsAsync(variables)
      .then(() => {
        loadBuildingModelModels();
        props.setReloadBuildingFloorUnits(true);
      });
  };

  const onRemove = (model) => {
    removeBuildingModelModelsAsync(model.id)
      .then(() => {
        loadBuildingModelModels();
        props.setReloadBuildingFloorUnits(true);
      });
  };

  const getAvailableBuildingModels = () => {
    const selectedBuildingModelIds = buildingModelModels.map((bmm) => bmm.buildingModel.id);
    return buildingModels.filter((m) => !selectedBuildingModelIds.includes(m.id));
  };

  const loadBuildingModels = () => {
    fetchBuildingModelsAsync(projectBuilding.project.id)
      .then((items) => setBuildingModels(items))
      .catch((e) => toast.error(e.message));
  };

  const loadBuildingModelModels = () => {
    fetchBuildingModelModelsAsync(projectBuilding.id)
      .then((items) => setBuildingModelModels(items))
      .catch((e) => toast.error(e.message));
  };

  const availableBuildingModels = getAvailableBuildingModels();
  const getModelUrl = (buildingModelModel) => NavigationService.getNavigationShowUrl(
    buildingModelModel?.buildingModel, NavigationMainNames.BUILDING_MODELS
  );

  return (
    <div>
      {isAdmin() && (
      <div className="text-right mb-3">
        <UncontrolledDropdown>
          <DropdownToggle color="outline-secondary" caret disabled={!availableBuildingModels.length}>
            {i18n.t('buttons.add', i18nOpts)}
          </DropdownToggle>
          <DropdownMenu right className="overflow-auto">
            {
              availableBuildingModels.map((buildingModel) => (
                <DropdownItem
                  key={`available-building-model-${buildingModel.id}`}
                  onClick={() => onAddModel(buildingModel)}
                >
                  {buildingModel.name}
                </DropdownItem>
              ))
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      )}

      <ListGroup>
        {
          buildingModelModels.map((buildingModelModel) => {
            const imageUrl = buildingModelModel.buildingModel.imageUrl || DEFAULT_IMAGE;
            return (
              <ListGroupItem
                key={`building-model-model-${buildingModelModel.id}`}
                className="building-model-model-container"
              >
                <div className="d-flex">
                  <div>
                    <Link to={getModelUrl(buildingModelModel)}>
                      <img
                        className="building-model-image mr-3"
                        src={imageUrl}
                        alt={buildingModelModel.buildingModel.name}
                      />
                    </Link>
                  </div>
                  <div className="flex-grow-1">
                    <Link to={getModelUrl(buildingModelModel)}>
                      {buildingModelModel.buildingModel.name}
                    </Link>
                  </div>
                  {isAdmin() && (
                    <div className="text-right">
                      <span
                        role="button"
                        aria-hidden
                        className="text-danger"
                        onClick={() => confirmDelete(() => onRemove(buildingModelModel))}
                        title={i18n.t('buttons.delete')}
                      >
                        <BiTrash size="1.1rem" />
                      </span>
                    </div>
                  )}
                </div>
              </ListGroupItem>
            );
          })
        }
      </ListGroup>
    </div>
  );
};

Models.propTypes = {
  projectBuilding: PropTypes.objectOf(PropTypes.any).isRequired
};

Models.defaultProps = {};

export default connect(() => ({}), {
  setReloadBuildingFloorUnits
})(Models);
