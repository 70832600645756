import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import {
  Table, Card, Spinner
} from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';

import styles from './Messages.module.scss';
import ListEmpty from '../../../../../common/ListEmpty';
import { getMessages } from '../../../../../../store/actions/messageActions';
import { isMasterAdmin } from '../../../../../../utils/authUtils';
import HomeCard from '../../../../../global/HomeCard';
import { isRecently } from '../../../../../../utils/dateUtils';
import { DateFormats } from '../../../../../../constants';

const i18nOpts = { scope: 'components.admin.customers.show.details.messages.index' };

const MessagesList = ({
  customer, messages, currentCompany, ...props
}) => {
  const [loading, setLoading] = useState(true);

  const loadMessages = () => {
    const companyId = isMasterAdmin() ? null : currentCompany.id;

    const variables = { filter: { companyId, contactId: customer?.id }, pageSize: null };
    props.getMessages(variables)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadMessages();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <div>
          <ListEmpty loading={loading} items={messages} />

          {messages.length > 0 && (
            <Card body className="p-0">
              <div className="p-4">
                <h4>{i18n.t('title', i18nOpts)}</h4>
                <div>{i18n.t('description', i18nOpts)}</div>
              </div>

              <div>
                <Table responsive className={styles.messagesTable}>
                  <thead>
                    <tr>
                      <th>{i18n.t('list.model', i18nOpts)}</th>
                      <th>{i18n.t('list.message', i18nOpts)}</th>
                      <th>{i18n.t('list.date', i18nOpts)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      messages.map((message) => (
                        <tr key={`message-${message.id}`}>
                          <td>
                            <HomeCard home={message.home} />
                          </td>
                          <td>{message.message}</td>
                          <td>
                            <span className={classNames({ 'text-success': isRecently(message.createdAt) })}>
                              {moment(message.createdAt).format(DateFormats.MMM_DD_YYYY)}
                            </span>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

MessagesList.propTypes = {};
MessagesList.defaultProps = {};

export default connect((store) => ({
  messages: store.messages.messages,
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}), {
  getMessages
})(MessagesList);
