import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import ListEmpty from '../../../common/ListEmpty';
import ListItem from './ListItem';
import { getOptionTypes } from '../../../../store/actions/optionTypeActions';
import { isAdmin } from '../../../../utils/authUtils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.optionTypes.list.index' };

class List extends Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };
  }

  componentDidMount() {
    this.loadOptionTypes();
  }

  loadOptionTypes() {
    const { props } = this;
    const { currentCompany } = this.props;

    const variables = { filter: { companyId: currentCompany.id } };
    props.getOptionTypes(variables)
      .finally(() => this.setState({ mounted: true }));
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return <Loading loading fullScreen />;

    const { optionTypes, loading } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{i18n.t('heading', i18nOpts)}</h2>
          </div>
          <div>
            { isAdmin() && (
              <Link to="/admin/optionTypes/form" className="btn btn-primary">
                <i className="fas fa-plus mr-2" />
                {i18n.t('buttons.add', i18nOpts)}
              </Link>
            )}
          </div>
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={optionTypes} />

            <Row>
              {
                optionTypes.map((ot) => (
                  <Col
                    key={`option-type-${ot.id}`}
                    xs="12"
                    sm="6"
                    md="4"
                    lg="3"
                    className="option-type-container"
                  >
                    <ListItem optionType={ot} />
                  </Col>
                ))
              }
            </Row>
          </>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  optionTypes: store.optionTypes.optionTypes,
  loading: store.optionTypes.getOptionTypes.loading,
  currentCompany: store.companies.currentCompany
}), {
  getOptionTypes
})(List);
