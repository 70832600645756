import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { Button, Spinner } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import api from '../../../../api';
import { deleteZapierWebhooksQuery, getZapierWebhookQuery } from '../../../../graphql';
import { DateFormats } from '../../../../constants';
import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';

const i18nOpts = { scope: 'components.admin.integrations.zapier.index' };

function getZapierWebhookAsync(companyId) {
  const variables = { companyId };
  return api.graphql(getZapierWebhookQuery, variables)
    .then(({ data: { item } }) => Promise.resolve(item));
}

function deleteZapierWebhooksAsync(companyId) {
  const variables = { companyId };
  return api.graphql(deleteZapierWebhooksQuery, variables);
}

const Zapier = ({ currentCompany }) => {
  const [webhook, setWebhook] = useState(null);
  const [disconnecting, setDisconnecting] = useState(false);

  const onDisconnect = () => {
    setDisconnecting(true);
    deleteZapierWebhooksAsync(currentCompany.id)
      .finally(() => {
        setDisconnecting(false);
        setWebhook(null);
      });
  };

  useEffect(() => {
    getZapierWebhookAsync(currentCompany.id)
      .then((item) => setWebhook(item))
      .catch(() => setWebhook(null));
  }, []);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

      {webhook ? (
        <div>
          <h4>{i18n.t('connected', { ...i18nOpts, connectedDate: moment(webhook.createdAt).format(DateFormats.MMM_DD_YYYY) })}</h4>

          <Button outline color="danger" onClick={onDisconnect} disabled={disconnecting} className="mt-3">
            {disconnecting && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.disconnect', i18nOpts)}
          </Button>
        </div>
      ) : (
        <div>
          <div dangerouslySetInnerHTML={{ __html: i18n.t('disconnected', i18nOpts) }} />
        </div>
      )}

    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(Zapier);
