import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import {
  Nav, NavItem, NavLink, TabContent, TabPane, Button
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import Profile from './Profile';
import { getUser } from '../../../../store/actions/userActions';
import Breadcrumbs from '../../../common/Breadcrumbs';
import { userCanBeEdited } from '../utils';
import Teams from './Teams';
import { ModalNames, Roles } from '../../../../constants';
import FormModal from '../components/FormModal';
import ModalService from '../../../../services/ModalService';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.users.show.index' };

const Tabs = Object.freeze({
  PROFILE: 'profile',
  TEAMS: 'teams'
});

const Show = ({ id, user, ...props }) => {
  const [activeTab, setActiveTab] = useState(Tabs.PROFILE);
  const [loading, setLoading] = useState(true);

  const onChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const onEdit = (event) => {
    event.preventDefault();
    ModalService.open(ModalNames.USER_FORM, { user });
  };

  const loadUser = () => {
    props.getUser(parseInt(id, 10))
      .catch(() => navigate('/admin/users'))
      .finally(() => setLoading(false));
  };

  const isSalesRole = () => [Roles.COMPANY_ADMIN, Roles.COMPANY_USER, Roles.SALES_LEAD,
    Roles.SALES_USER].includes(user.role);

  useEffect(() => {
    loadUser();
  }, []);

  if (!user || loading) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={user.name} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <Breadcrumbs items={[
            { url: '/admin/users', text: i18n.t('users', i18nOpts) },
            { url: `/admin/users/${user.id}`, text: user.name },
          ]}
          />
        </div>
        <div>
          { userCanBeEdited(user) && (
          <Button color="secondary" outline onClick={onEdit}>
            <i className="far fa-edit mr-2" />
            {i18n.t('buttons.edit')}
          </Button>
          )}
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.PROFILE ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.PROFILE)}
          >
            {i18n.t('tabs.profile', i18nOpts)}
          </NavLink>
        </NavItem>
        {isSalesRole() && (
          <NavItem>
            <NavLink
              onClick={() => onChangeTab(Tabs.TEAMS)}
              active={activeTab === Tabs.TEAMS}
            >
              {i18n.t('tabs.teams', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.PROFILE}>
          <Profile user={user} />
        </TabPane>
        {isSalesRole() && (
          <TabPane tabId={Tabs.TEAMS}>
            <Teams />
          </TabPane>
        )}
      </TabContent>

      <FormModal />
    </div>
  );
};

export default connect((store) => ({
  user: store.users.user
}), {
  getUser
})(Show);
