import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

const Region = ({ customer }) => {
  const { city } = customer;

  if (v.isBlank(city)) return null;

  return (
    <div>
      {city}
    </div>
  );
};

Region.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

Region.defaultProps = {};

export default Region;
