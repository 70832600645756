import React from 'react';
import PropTypes from 'prop-types';

import HomeCard from '../../HomeCard';
import InvitedHomeCard from './components/InvitedHomeCard';

const DealHomeInfo = ({ deal }) => {
  const { home: savedHome, customerInvitation } = deal;
  const { home: invitedHome } = customerInvitation || {};

  return (
    <>
      {savedHome ? (
        <HomeCard home={savedHome} hidePrice hideBuiltDate />
      ) : (
        <InvitedHomeCard home={invitedHome} />
      )}
    </>
  );
};

DealHomeInfo.propTypes = {
  deal: PropTypes.objectOf(PropTypes.any).isRequired
};

DealHomeInfo.defaultProps = {};

export default DealHomeInfo;
