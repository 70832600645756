import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';

import i18n from 'i18n-js';
import { confirmDelete } from '../confirmAlert';

const DeleteButton = ({ onDelete, ...props }) => {
  const [deleting, setDeleting] = useState(false);
  const onSubmit = () => {
    setDeleting(true);
    confirmDelete(onDelete);
    setDeleting(false);
  };

  return (
    <Button outline color="danger" onClick={onSubmit} disabled={deleting} {...props}>
      {deleting && (<Spinner size="sm" className="mr-2" />)}
      {i18n.t('buttons.delete')}
    </Button>
  );
};

export default DeleteButton;
