import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, Button, Spinner, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import { saveCompany, setCurrentCompany } from '../../../../store/actions/companyActions';
import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';
import Facebook from './Facebook';
import Google from './Google';
import Apple from './Apple';

const i18nOpts = { scope: 'components.admin.integrations.socialMedia.index' };

const Tabs = Object.freeze({
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  APPLE: 'apple'
});

function onCancel() {
  navigate('/admin/integrations');
}

class SocialMediaLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      saving: false,
      activeTab: Tabs.FACEBOOK
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
  }

  componentDidMount() {
    this.populateForm();
  }

  onChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;
    form[name] = value;
    this.setState({ form });
  }

  onSubmit(event) {
    event.preventDefault();

    const { props } = this;
    const { form } = this.state;

    const input = {
      id: form.id,
      socialMediaLoginSettings: {
        facebook: {
          appId: form.fbAppId,
          appSecret: form.fbAppSecret
        },
        google: {
          clientId: form.googleClientId,
          clientSecret: form.googleClientSecret
        },
        apple: {
          clientId: form.appleClientId
        }
      }
    };

    this.setState({ saving: true });

    const variables = { input };

    props.saveCompany(variables)
      .then(() => {
        props.setCurrentCompany(form.id);
        navigate('/admin/integrations');
      })
      .catch(() => {
        const { saveErrorMessage } = this.props;
        toast.error(saveErrorMessage);
        this.setState({ saving: false });
      });
  }

  populateForm() {
    const { currentCompany: { id, socialMediaLoginSettings } } = this.props;
    if (!socialMediaLoginSettings) return;

    const form = {
      fbAppId: socialMediaLoginSettings.facebook.appId,
      fbAppSecret: socialMediaLoginSettings.facebook.appSecret,
      googleClientId: socialMediaLoginSettings.google.clientId,
      googleClientSecret: socialMediaLoginSettings.google.clientSecret,
      appleClientId: socialMediaLoginSettings.apple.clientId,
    };

    this.setState({ form: { id, ...form } });
  }

  render() {
    const {
      form, saving, activeTab
    } = this.state;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

        <Form onSubmit={this.onSubmit}>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${activeTab === Tabs.FACEBOOK ? 'active' : ''}`}
                onClick={() => this.onChangeTab(Tabs.FACEBOOK)}
              >
                {i18n.t('tabs.facebook', i18nOpts)}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === Tabs.GOOGLE ? 'active' : ''}`}
                onClick={() => this.onChangeTab(Tabs.GOOGLE)}
              >
                {i18n.t('tabs.google', i18nOpts)}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${activeTab === Tabs.APPLE ? 'active' : ''}`}
                onClick={() => this.onChangeTab(Tabs.APPLE)}
              >
                {i18n.t('tabs.apple', i18nOpts)}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="py-4">
            <TabPane tabId={Tabs.FACEBOOK}>
              <Facebook form={form} onTextChange={this.onTextChange} />
            </TabPane>
            <TabPane tabId={Tabs.GOOGLE}>
              <Google form={form} onTextChange={this.onTextChange} />
            </TabPane>
            <TabPane tabId={Tabs.APPLE}>
              <Apple form={form} onTextChange={this.onTextChange} />
            </TabPane>
          </TabContent>

          <Button color="primary" className="mr-3" disabled={saving}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onCancel} disabled={saving} className="mr-3">
            {i18n.t('buttons.cancel')}
          </Button>
        </Form>
      </div>
    );
  }
}

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  saveErrorMessage: store.companies.saveCompany.error
}), {
  saveCompany,
  setCurrentCompany
})(SocialMediaLogin);
