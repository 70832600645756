import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Statuses } from '../constants';
import api from '../api';

async function fetchCommunitiesAsync(companyId) {
  const variables = {
    filter: { companyId, status: Statuses.PUBLISHED },
  };

  const query = `
    query ListCommunities(
      $filter: CommunityFilterInput
    ) {
      result: listCommunities(filter: $filter, pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `;
  return api.graphql(query, variables)
    .then(({ data: { result: { items } } }) => items);
}

async function fetchProjectsAsync(companyId) {
  const variables = {
    filter: { companyId, status: Statuses.PUBLISHED },
  };

  const query = `
    query ListProjects(
      $filter: ProjectFilterInput
    ) {
      result: listProjects(filter: $filter, pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `;
  return api.graphql(query, variables)
    .then(({ data: { result: { items } } }) => items);
}

function mergeCommunitiesAndProjects(communities, projects) {
  const items = communities.map((community) => ({ ...community, __typename: 'Community' }));
  items.push(...projects.map((project) => ({ ...project, __typename: 'Project' })));
  return _.orderBy(items, [(item) => item.name.toLowerCase()], ['asc']);
}

async function fetchCommunitiesAndProjectsAsync(companyId) {
  const promises = [];
  promises.push(fetchCommunitiesAsync(companyId));
  promises.push(fetchProjectsAsync(companyId));

  return Promise.all(promises)
    .then(([communities, projects]) => mergeCommunitiesAndProjects(communities, projects));
}

const useFetchCommunitiesAndProjects = () => {
  const [loading, setLoading] = useState(false);
  const [communitiesProjects, setCommunitiesProjects] = useState([]);

  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany,
  }));

  useEffect(() => {
    setLoading(true);

    fetchCommunitiesAndProjectsAsync(currentCompany.id)
      .then((items) => setCommunitiesProjects(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { communitiesProjects, loading };
};

export default useFetchCommunitiesAndProjects;
