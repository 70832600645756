import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import ListEmpty from '../../../common/ListEmpty';
import { fetchProjectBuildingsAsync } from './utils';
import { isAdmin } from '../../../../utils/authUtils';
import Loading from '../../../ui/Loading';
import { ViewModes } from '../../../../constants';
import GridView from './GridView';
import ListView from './ListView';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import { setProjectBuildingsViewMode } from '../../../../store/actions/projectBuildingActions';

const i18nOpts = { scope: 'components.admin.projectBuildings.list.index' };

const ProjectBuildings = ({ viewMode, currentCompany, ...props }) => {
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectBuildings, setProjectBuildings] = useState([]);

  const onChangeViewMode = (newViewMode) => {
    props.setProjectBuildingsViewMode(newViewMode);
  };

  const loadProjectBuildings = () => {
    setLoading(true);

    fetchProjectBuildingsAsync(currentCompany.id)
      .then((items) => setProjectBuildings(items))
      .catch(() => {})
      .finally(() => {
        setMounted(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadProjectBuildings();
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div className="d-flex justify-content-end align-items-center gap-3">
          <ViewModesDropdown viewMode={viewMode} onChange={onChangeViewMode} />

          {isAdmin() && (
            <Link to="/admin/projectBuildings/form" className="btn btn-primary">
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Link>
          )}
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={projectBuildings} />

          {projectBuildings.length > 0 && (
            <div className="mb-4">
              {viewMode === ViewModes.GRID ? (
                <GridView projectBuildings={projectBuildings} />
              ) : (
                <ListView projectBuildings={projectBuildings} />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  viewMode: store.projectBuildings.viewMode,
  currentCompany: store.companies.currentCompany
}), {
  setProjectBuildingsViewMode
})(ProjectBuildings);
