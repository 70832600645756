export function isMultiFamilyPage() {
  return window.location.pathname.startsWith('/admin/multifamily');
}

export function buildPalettesPath(path = '') {
  const pathPrefix = isMultiFamilyPage() ? '/multifamily' : '';
  return `/admin${pathPrefix}/palettes${path}`;
}

export function buildPackagesPath(path = '') {
  const pathPrefix = isMultiFamilyPage() ? '/multifamily' : '';
  return `/admin${pathPrefix}/packages${path}`;
}
