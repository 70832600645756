import React from 'react';
import PropTypes from 'prop-types';
import { IoMdCloudOutline } from 'react-icons/io';
import { IoCloudDoneOutline } from 'react-icons/io5';
import { UncontrolledTooltip } from 'reactstrap';
import i18n from 'i18n-js';

import { Statuses } from '../../../constants';

const StatusIndicator = ({
  status, id, size, displayTooltip
}) => {
  const isPublished = status === Statuses.PUBLISHED;
  const statusLabel = isPublished ? i18n.t('statuses.published') : i18n.t('statuses.draft');

  return (
    <div className="status-container">
      <div className="status-icon" id={`status-tooltip-${id}`}>
        {isPublished ? (
          <IoCloudDoneOutline size={size} className="text-success" />
        ) : (
          <IoMdCloudOutline size={size} className="text-muted" />
        )}
      </div>

      {displayTooltip && (
        <UncontrolledTooltip target={`status-tooltip-${id}`}>
          {statusLabel}
        </UncontrolledTooltip>
      )}
    </div>
  );
};

StatusIndicator.propTypes = {
  status: PropTypes.oneOf(Object.values(Statuses)).isRequired,
  id: PropTypes.number,
  size: PropTypes.string,
  displayTooltip: PropTypes.bool
};

StatusIndicator.defaultProps = {
  id: 0,
  size: '1.2rem',
  displayTooltip: true
};

export default StatusIndicator;
