import React from 'react';

import ConfirmSignCard from './ConfirmSignCard';
import RejectSignCard from './RejectSignCard';
import DeclinedSignCard from './DeclinedSignCard';

const SignSection = () => (
  <div>
    <ConfirmSignCard />

    <RejectSignCard />

    <DeclinedSignCard />
  </div>
);

SignSection.propTypes = {};

SignSection.defaultProps = {};

export default SignSection;
