import React, { Component } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { hasFormValues } from '../../../../../../utils/formUtils';
import { saveSpecification, getSpecifications } from '../../../../../../store/actions/specificationActions';
import { ModalNames } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { getError } from '../../../../../../utils/requestUtils';
import { stripToEmpty } from '../../../../../../utils/stringUtils';

const i18nOpts = { scope: 'components.admin.models.show.specifications.formModal.index' };

function onClose() {
  ModalService.close(ModalNames.SPECIFICATION_FORM);
}

class FormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      saving: false,
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { opened } = this.props;
    if (prevProps.opened !== opened && opened) this.init();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onSave() {
    const { props } = this;
    const { form } = this.state;
    const { model } = this.props;

    const input = {
      ...form,
      name: stripToEmpty(form.name),
      modelId: model.id
    };

    this.setState({ saving: true });

    const variables = { input };
    props.saveSpecification(variables)
      .then(() => {
        this.loadSpecifications();
        ModalService.close(ModalNames.SPECIFICATION_FORM);
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  init() {
    const { params: { specification } } = this.props;
    let form = {};
    if (specification) {
      const { id, name } = specification;
      form = { id, name };
    }

    this.setState({ form, saving: false, error: null });
  }

  loadSpecifications() {
    const { props } = this;
    const { model } = this.props;

    const variables = { filter: { modelId: model.id } };
    props.getSpecifications(variables);
  }

  isNew() {
    const { form } = this.state;
    return !form.id;
  }

  render() {
    const { form, saving, error } = this.state;
    const { opened } = this.props;
    const hasValues = hasFormValues(form);
    const isNew = this.isNew();
    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    return (
      <Modal isOpen={opened}>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="specificationName">{i18n.t('name', i18nOpts)}</Label>
            <Input
              type="text"
              name="name"
              id="specificationName"
              value={form.name || ''}
              onChange={this.onTextChange}
              invalid={isInputInvalid(error, 'name')}
            />
            <InputError name="name" error={error} />
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving || !hasValues}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onClose} disabled={saving}>
            {i18n.t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect((store) => ({
  model: store.models.model,
  opened: store.modals[ModalNames.SPECIFICATION_FORM]?.opened || false,
  params: store.modals[ModalNames.SPECIFICATION_FORM]?.params || {}
}), {
  saveSpecification,
  getSpecifications
})(FormModal);
