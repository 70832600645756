import React from 'react';
import {
  Card, Row, Col, Badge
} from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';
import { connect } from 'react-redux';
import moment from 'moment';
import { BiCheckCircle } from 'react-icons/bi';
import { RiErrorWarningLine } from 'react-icons/ri';

import {
  formatCustomerAddress,
  getCustomerAddress,
  getCustomerIdentificationInfo,
  getDocumentNumber, getDocumentType
} from '../../../../../utils/customerUtils';
import { DateFormats } from '../../../../../constants';

const i18nOpts = { scope: 'components.admin.customers.financialReport.idCard.index' };

const IdCard = ({ customer }) => {
  const {
    firstName, lastName, isIdVerified, companyContact, user, isHomeVerified
  } = customer;
  const isIdVerifiedAndGranted = isIdVerified && companyContact.verifiedIdGranted;
  const idVerification = user?.idVerification;

  let documentExpiryDate;
  let documentType;
  let dateOfBirth;
  let documentNumber;
  if (isIdVerifiedAndGranted) {
    const customerIdentificationInfo = getCustomerIdentificationInfo(customer);
    const { identificationDocument } = customerIdentificationInfo;
    documentExpiryDate = identificationDocument?.dateOfExpiry;
    documentType = getDocumentType(identificationDocument?.documentType);
    dateOfBirth = customerIdentificationInfo.dateOfBirth;
    documentNumber = getDocumentNumber(identificationDocument?.documentNumbers);
  }

  const customerAddress = getCustomerAddress(customer);
  const address = formatCustomerAddress(customerAddress);

  return (
    <Card body>
      <div className="d-flex align-items-center gap-2">
        <div className="font-weight-500 font-16 col-gray-900">{i18n.t('title', i18nOpts)}</div>
        <div>
          {isIdVerifiedAndGranted ? (
            <Badge color="primary" pill className="d-inline-flex align-items-center">
              <BiCheckCircle />
              <span className="ml-1">{i18n.t('verified', i18nOpts)}</span>
            </Badge>
          ) : (
            <Badge color="danger" pill className="d-inline-flex align-items-center">
              <RiErrorWarningLine />
              <span className="ml-1">{i18n.t('unverified', i18nOpts)}</span>
            </Badge>
          )}
        </div>
      </div>

      {idVerification?.completedAt && (
        <div className="text-muted">
          <small>{i18n.t('verificationDate', i18nOpts)}</small>
          <small className="ml-2">{moment(idVerification.completedAt).format(DateFormats.YYYY_MM_DD)}</small>
        </div>
      )}

      <Row className="mt-4">
        <Col xs="12" sm="6">
          {!v.isBlank(firstName) && (
            <div>
              <span className="font-weight-500">{i18n.t('firstName', i18nOpts)}</span>
              <span className="font-weight-500 pl-2">{firstName}</span>
            </div>
          )}

          {!v.isBlank(lastName) && (
            <div>
              <span className="font-weight-500">{i18n.t('lastName', i18nOpts)}</span>
              <span className="font-weight-500 pl-2">{lastName}</span>
            </div>
          )}

          {!v.isBlank(dateOfBirth) && (
            <div>
              <span className="font-weight-500">{i18n.t('dateOfBirth', i18nOpts)}</span>
              <span className="font-weight-500 pl-2">{dateOfBirth}</span>
            </div>
          )}

          {(isIdVerifiedAndGranted || isHomeVerified) && !v.isBlank(address) && (
            <div className="d-flex">
              <span className="font-weight-500">
                {i18n.t('address', i18nOpts)}
              </span>
              <span className="font-weight-500 pl-2">
                {address}
              </span>
            </div>
          )}
        </Col>
        <Col xs="12" sm="6">
          {!v.isBlank(documentType) && (
            <div>
              <span className="font-weight-500">{i18n.t('idType', i18nOpts)}</span>
              <span className="font-weight-500 pl-2">{documentType}</span>
            </div>
          )}

          {!v.isBlank(documentNumber) && (
            <div>
              <span className="font-weight-500">{i18n.t('idNumber', i18nOpts)}</span>
              <span className="font-weight-500 pl-2">{documentNumber}</span>
            </div>
          )}

          {!v.isBlank(documentExpiryDate) && (
            <div>
              <span className="font-weight-500">{i18n.t('idExpiryDate', i18nOpts)}</span>
              <span className="font-weight-500 pl-2">{documentExpiryDate}</span>
            </div>
          )}
        </Col>
      </Row>
    </Card>
  );
};

IdCard.propTypes = {};

IdCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer
}))(IdCard);
