import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './styles.scss';
import Form from './Form';
import { getCustomer, showCustomerForm, setActiveCustomerTab } from '../../../../store/actions/customerActions';
import Overview from './Overview';
import Details from './Details';
import { markCustomerAsViewedAsync } from './utils';
import { CustomerTabs } from '../../../../constants';
import Loading from '../../../ui/Loading';

const Show = ({
  id, customer, currentCompany, showForm, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const customerId = parseInt(id, 10);

  const loadCustomer = () => {
    setMounted(false);

    const variables = {
      id: customerId,
      withTotalVisits: true,
      withAverageVisitTime: true,
      withPropertyInfo: true,
      companyId: currentCompany.id
    };

    props.getCustomer(variables)
      .then(() => setMounted(true))
      .catch(() => navigate('/admin/customers'));
  };

  const markCustomerAsViewed = () => {
    markCustomerAsViewedAsync(currentCompany.id, customerId).catch(() => {});
  };

  const setDefaultCustomerTab = () => {
    props.setActiveCustomerTab(CustomerTabs.ACTIVITY);
  };

  useEffect(() => {
    setDefaultCustomerTab();
    loadCustomer();
    markCustomerAsViewed();
  }, [id]);

  useEffect(() => {
    props.showCustomerForm(false);

    const pageContainer = document.querySelector('.page-content');

    pageContainer.classList.add('customer-page-content');
    return () => {
      pageContainer.classList.remove('customer-page-content');
    };
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={customer.name} />

      <div id="customer-container">
        <div className="d-none d-md-block customer-overview-container">
          <Overview />
        </div>
        <div className="p-3 p-md-4 customer-details-container">
          <div className={classNames({ 'd-none': showForm })}>
            <Details />
          </div>

          {showForm && (
            <Form />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((store) => ({
  customer: store.customers.customer,
  showForm: store.customers.showForm,
  currentCompany: store.companies.currentCompany
}), {
  getCustomer,
  showCustomerForm,
  setActiveCustomerTab,
})(Show);
