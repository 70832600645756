import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { Table } from 'reactstrap';
import { navigate } from '@reach/router';

import './styles.scss';
import { isAdmin } from '../../../../../utils/authUtils';
import ActionsMenu from '../../../../global/ActionsMenu';
import { getProjectAvailabilityName, getProjectStatusName } from '../../../../../utils/projectUtils';
import CreatedByTableItem from '../../../../global/CreatedByTableItem';
import LastModifiedByTableItem from '../../../../global/LastModifiedByTableItem';
import { deleteProject } from '../../../../../store/actions/projectActions';

const i18nOpts = { scope: 'components.admin.projects.list.listView.index' };

const ListView = ({ projects, ...props }) => {
  const onEdit = (project) => {
    navigate(`/admin/projects/form?id=${project.id}`);
  };

  const onDelete = (projectId) => {
    props.deleteProject(projectId);
  };

  return (
    <Table responsive bordered id="projects-table">
      <thead>
        <tr>
          <th>{i18n.t('name', i18nOpts)}</th>
          <th>{i18n.t('city', i18nOpts)}</th>
          <th>{i18n.t('state', i18nOpts)}</th>
          <th>{i18n.t('buildingType', i18nOpts)}</th>
          <th>{i18n.t('type', i18nOpts)}</th>
          <th>{i18n.t('availability', i18nOpts)}</th>
          <th>{i18n.t('status', i18nOpts)}</th>
          {isAdmin() && (
            <>
              <th>{i18n.t('createdBy', i18nOpts)}</th>
              <th>{i18n.t('lastModifiedBy', i18nOpts)}</th>
              <th>&nbsp;</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {
          projects.map((project) => (
            <tr key={`project-${project.id}`}>
              <td>
                <Link to={`/admin/projects/${project.id}`}>
                  {project.name}
                </Link>
              </td>
              <td>{project.city?.name || ''}</td>
              <td>{project.city?.state || ''}</td>
              <td>{project.buildingType}</td>
              <td>{i18n.t(`types.${project.type.toLowerCase()}`, i18nOpts)}</td>
              <td>
                {getProjectAvailabilityName(project.availability)}
              </td>
              <td>
                <span className={`badge text-white badge-${project.status.toLowerCase()}`}>
                  {getProjectStatusName(project.status)}
                </span>
              </td>
              {isAdmin() && (
                <>
                  <CreatedByTableItem instance={project} />
                  <LastModifiedByTableItem instance={project} />
                  <td className="text-right">
                    <ActionsMenu
                      item={project}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  </td>
                </>
              )}
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propsTypes = {};

ListView.defaultProps = {};

export default connect((store) => ({
  projects: store.projects.projects
}), {
  deleteProject
})(ListView);
