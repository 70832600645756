import React from 'react';
import { Button } from 'reactstrap';
import { SiMicrosoftexcel } from 'react-icons/si';
import i18n from 'i18n-js';

import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';

const ExportButton = () => {
  const onClick = () => {
    ModalService.open(ModalNames.CUSTOMERS_EXPORT);
  };

  return (
    <Button color="secondary" outline onClick={onClick}>
      <SiMicrosoftexcel size="1rem" />
      <span className="ml-2">{i18n.t('buttons.export')}</span>
    </Button>
  );
};

ExportButton.propTypes = {};

ExportButton.defaultProps = {};

export default ExportButton;
