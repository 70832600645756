export function getLocationInfoFormatted(location) {
  const {
    // eslint-disable-next-line camelcase
    city, region, country_name, org, country
  } = location;
  // eslint-disable-next-line camelcase
  const formattedString = `${org}, ${country_name}, ${city}, ${region}, ${country}`;

  return formattedString;
}
