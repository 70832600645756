import React, { useState } from 'react';
import {
  Button, FormGroup, Input, Label, Spinner, Card
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import v from 'voca';

import InputError, { isInputInvalid } from '../../../../../../ui/InputError';
import { OfferStatuses } from '../../../../../../../constants';
import { saveOffer } from '../../../../../../../store/actions/offerActions';
import { parseToFloat } from '../../../../../../../utils/parserUtils';
import { getError } from '../../../../../../../utils/requestUtils';
import { isValidAmount } from '../../../../../../../utils/currencyUtils';
import { setReloadHomes, setHomeAttributes } from '../../../../../../../store/actions/homeActions';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.offerSection.counterOfferCard.index' };

const CounterOfferCard = ({ home, ...props }) => {
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const { offer } = home;

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onCounter = () => {
    setSaving(true);

    const input = {
      id: offer.id,
      counterOfferPrice: parseToFloat(form.counterOfferPrice) || 0,
      status: OfferStatuses.COUNTERED
    };
    const variables = { input };

    props.saveOffer(variables)
      .then(({ value: { data: { item } } }) => {
        props.setHomeAttributes(home.id, { offer: item });
        props.setReloadHomes(true);
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const isConfirmedPrice = () => {
    const counterOfferPrice = parseToFloat(form.counterOfferPrice);
    if (!isValidAmount(counterOfferPrice)) return false;

    return counterOfferPrice === parseToFloat(form.confirmCounterOfferPrice);
  };

  if ([OfferStatuses.ACCEPTED, OfferStatuses.COUNTERED, OfferStatuses.REJECTED,
    OfferStatuses.DECLINED].includes(offer.status)) return null;

  return (
    <Card body className="p-3 mb-3">
      <h3>{i18n.t('title', i18nOpts)}</h3>

      <FormGroup>
        <Label for="counterOfferPrice">{i18n.t('counterOfferPrice', i18nOpts)}</Label>
        <div className="d-flex align-items-center">
          <b>$</b>
          <Input
            type="number"
            name="counterOfferPrice"
            id="counterOfferPrice"
            value={form.counterOfferPrice || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'counterOfferPrice')}
            className="ml-2"
          />
        </div>
        <InputError name="counterOfferPrice" error={error} />
      </FormGroup>

      <FormGroup>
        <Label for="confirmCounterOfferPrice">{i18n.t('confirmCounterOfferPrice', i18nOpts)}</Label>
        <div className="d-flex align-items-center">
          <b>$</b>
          <Input
            type="number"
            name="confirmCounterOfferPrice"
            id="confirmCounterOfferPrice"
            value={form.confirmCounterOfferPrice || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'confirmCounterOfferPrice')}
            className="ml-2"
          />
        </div>
        <InputError name="confirmCounterOfferPrice" error={error} />
      </FormGroup>

      <div className="text-right">
        <Button color="primary" className="ml-3" onClick={onCounter} disabled={saving || !isConfirmedPrice()}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.counter', i18nOpts)}
        </Button>
      </div>
    </Card>
  );
};

CounterOfferCard.propTypes = {};

CounterOfferCard.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}), {
  saveOffer,
  setHomeAttributes,
  setReloadHomes
})(CounterOfferCard);
