import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import TimeLineElement from '../../../../common/TimeLineElement';
import MessageIcon from '../../../../../assets/icons/customer-activities/MessageIcon';
import ActivityDate from '../components/ActivityDate';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.message.index' };

const Message = ({ activity, innerKey }) => {
  const { message } = activity;

  if (!message) return null;

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<MessageIcon />}
    >
      <div className="d-flex align-items-start gap-3">
        <div className="activity-timeline-title">{i18n.t('title', i18nOpts)}</div>
        <ActivityDate date={activity.createdAt} />
      </div>
      <div>{i18n.t('description', i18nOpts)}</div>

      <div className="white-space-pre-wrap font-italic mt-2">
        {message.message}
      </div>
    </TimeLineElement>
  );
};

Message.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

export default Message;
