import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { CgOrganisation } from 'react-icons/cg';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';

import api from '../../../../api';

const i18nOpts = { scope: 'components.global.filters.companyFilter.index' };

function fetchCompaniesAsync(extraQueryFields) {
  return api.graphql(`
  query ListCompanies(
    $page: Int
    $pageSize: Int
  ) {
    result: listCompanies(page: $page, pageSize: $pageSize) {
      items {
        id
        name
        ${extraQueryFields}
      }
    }
  }
  `)
    .then(({ data: { result: { items } } }) => Promise.resolve(items));
}

const CompanyFilter = ({
  onChange, showLabel, extraQueryFields, defaultCompany
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(defaultCompany);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onToggleCompany = (company) => {
    setSelectedCompany(company);
    onChange(company);
  };

  useEffect(() => {
    fetchCompaniesAsync(extraQueryFields)
      .then((items) => setCompanies(items))
      .catch(() => {});
  }, []);

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
        <DropdownToggle color="outline-secondary" caret disabled={!companies.length}>
          {showLabel && (
            <>
              <CgOrganisation size="1rem" className="mr-2 text-muted d-inline-block d-sm-none" />
              <span className="mr-2 text-muted d-none d-sm-inline-block">{i18n.t('company', i18nOpts)}</span>
            </>
          )}
          {selectedCompany?.name ?? i18n.t('all', i18nOpts)}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onToggleCompany(null)}>
            {i18n.t('all', i18nOpts)}
          </DropdownItem>
          {
            companies.map((c) => (
              <DropdownItem
                key={`company-${c.id}`}
                active={c.id === selectedCompany?.id}
                onClick={() => onToggleCompany(c)}
              >
                {c.name}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

CompanyFilter.propTypes = {
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  extraQueryFields: PropTypes.string,
  defaultCompany: PropTypes.objectOf(PropTypes.any)
};

CompanyFilter.defaultProps = {
  onChange: () => {},
  showLabel: true,
  extraQueryFields: '',
  defaultCompany: null
};

export default CompanyFilter;
