import React from 'react';

import styles from './SidebarToggleButton.module.scss';
import MenuFillIcon from '../../../../assets/icons/sidebar-nav/MenuFillIcon';
import { toggleSidebar } from '../../utils';

const SidebarToggleButton = () => {
  const onToggle = () => {
    toggleSidebar();
  };

  return (
    <div className={styles.container} role="button" aria-hidden onClick={onToggle}>
      <MenuFillIcon />
    </div>
  );
};

SidebarToggleButton.propTypes = {};

SidebarToggleButton.defaultProps = {};

export default SidebarToggleButton;
