import React from 'react';

import { ORIENTATIONS } from '../constants';
import './styles.scss';

const Resizer = ({ boundingBox, onMouseDown }) => {
  const containerStyle = {
    left: boundingBox.left - 30,
    top: boundingBox.top - 30,
    width: boundingBox.width + 60,
    height: boundingBox.height + 60,
    position: 'absolute'
  };
  const topRightStyle = {
    margin: 'auto',
    float: 'right',
    position: 'relative'
  };
  const topLeftStyle = {
    margin: 'auto',
    float: 'left',
    position: 'relative'
  };
  const bottomRightStyle = {
    marginTop: boundingBox.height + 40,
    marginRight: -20,
    float: 'right',
    position: 'relative'
  };
  const bottomLeftStyle = {
    marginTop: boundingBox.height + 40,
    marginLeft: -20,
    float: 'left',
    position: 'relative'
  };
  return (
    <div className="resizer-container" style={{ ...containerStyle }}>
      <div
        style={{
          ...topRightStyle
        }}
        className="resize-anchor"
        onMouseDown={(e) => onMouseDown(e, ORIENTATIONS.topRight)}
      />
      <div
        style={{
          ...topLeftStyle
        }}
        className="resize-anchor"
        onMouseDown={(e) => onMouseDown(e, ORIENTATIONS.topLeft)}
      />
      <div
        style={{
          ...bottomLeftStyle
        }}
        className="resize-anchor"
        onMouseDown={(e) => onMouseDown(e, ORIENTATIONS.bottomLeft)}
      />
      <div
        style={{
          ...bottomRightStyle
        }}
        className="resize-anchor"
        onMouseDown={(e) => onMouseDown(e, ORIENTATIONS.bottomRight)}
      />
    </div>
  );
};

export default Resizer;
