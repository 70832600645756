import {
  LIST_WEBHOOK_DELIVERIES,
  GET_WEBHOOK_DELIVERY, CLEAN_WEBHOOK_DELIVERIES, DELETE_WEBHOOK_DELIVERY, SET_WEBHOOK_DELIVERY
} from './types';
import api from '../../api';
import {
  deleteWebhookDeliveryQuery, getWebhookDeliveryQuery,
  listWebhookDeliveriesQuery,
} from '../../graphql';

export function listWebhookDeliveries(variables = {}) {
  return {
    type: LIST_WEBHOOK_DELIVERIES,
    payload: api.graphql(listWebhookDeliveriesQuery, variables)
  };
}

export function getWebhookDelivery(id) {
  return {
    type: GET_WEBHOOK_DELIVERY,
    payload: api.graphql(getWebhookDeliveryQuery, { id })
  };
}

export function cleanWebhookDeliveries(extraProps = {}) {
  return {
    type: CLEAN_WEBHOOK_DELIVERIES,
    payload: Promise.resolve(extraProps)
  };
}

export function deleteWebhookDelivery(id) {
  return {
    type: DELETE_WEBHOOK_DELIVERY,
    payload: api.graphql(deleteWebhookDeliveryQuery, { id })
  };
}

export function setWebhookDelivery(webhookDelivery) {
  return {
    type: SET_WEBHOOK_DELIVERY,
    payload: { webhookDelivery }
  };
}
