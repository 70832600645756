import React from 'react';
import i18n from 'i18n-js';

import BaseSearchBar from '../../../../../common/SearchBar';

const i18nOpts = { scope: 'components.admin.sales.components.dealList.searchBar.index' };

const SearchBar = ({ onSearch }) => (
  <BaseSearchBar onSubmit={onSearch} inputProps={{ bsSize: 'md', placeholder: i18n.t('title', i18nOpts) }} />
);

SearchBar.propTypes = {};

SearchBar.defaultProps = {};

export default SearchBar;
