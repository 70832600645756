import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';
import 'react-phone-input-2/lib/style.css';

import './styles.scss';

const PhoneNumberInput = ({
  value, onChange, containerClass, inputClassName, buttonClassName, inputProps
}) => (
  <PhoneInput
    country="ca"
    preferredCountries={['us', 'ca']}
    value={value}
    countryCodeEditable={false}
    containerClass={containerClass}
    inputClass={classNames('form-control', inputClassName)}
    buttonClass={buttonClassName}
    onChange={(phone, data, event) => onChange(phone, event)}
    inputProps={inputProps}
  />
);

PhoneNumberInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  containerClass: PropTypes.string,
  inputClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  inputProps: PropTypes.objectOf(PropTypes.any)
};

PhoneNumberInput.defaultProps = {
  onChange: () => {},
  containerClass: 'phone-input-container',
  inputClassName: 'phone-input',
  buttonClassName: 'flags-button',
  inputProps: {}
};

export default PhoneNumberInput;
