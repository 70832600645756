import {
  SAVE_PACKAGE, DELETE_PACKAGE, GET_PACKAGES, CLEAN_PACKAGES, GET_PACKAGE
} from './types';
import {
  createPackageQuery, deletePackageQuery, getPackageQuery, listPackagesQuery,
  updatePackageQuery
} from '../../graphql';
import api from '../../api';

export function getPackage(id) {
  return {
    type: GET_PACKAGE,
    payload: api.graphql(getPackageQuery, { id })
  };
}

export function getPackages(variables = {}) {
  return {
    type: GET_PACKAGES,
    payload: api.graphql(listPackagesQuery, variables)
  };
}

export function cleanPackages(extraProps = {}) {
  return {
    type: CLEAN_PACKAGES,
    payload: Promise.resolve(extraProps)
  };
}

export function savePackage(variables = {}) {
  const query = variables.input.id ? updatePackageQuery : createPackageQuery;
  return {
    type: SAVE_PACKAGE,
    payload: api.graphql(query, variables)
  };
}

export function deletePackage(id) {
  return {
    type: DELETE_PACKAGE,
    payload: api.graphql(deletePackageQuery, { id })
  };
}
