import {
  DELETE_BUILDING_MODEL_PALETTE,
  LIST_BUILDING_MODEL_PALETTES,
  RELOAD_BUILDING_MODEL_PALETTES,
  SAVE_BUILDING_MODEL_PALETTE
} from './types';
import api from '../../api';
import {
  createBuildingModelPaletteQuery,
  deleteBuildingModelPaletteQuery,
  listBuildingModelPalettesQuery,
  updateBuildingModelPaletteQuery
} from '../../graphql';

export function getBuildingModelPalettes(variables) {
  return {
    type: LIST_BUILDING_MODEL_PALETTES,
    payload: api.graphql(listBuildingModelPalettesQuery, variables)
  };
}

export function saveBuildingModelPalette(variables) {
  const query = variables?.input?.id ? updateBuildingModelPaletteQuery
    : createBuildingModelPaletteQuery;
  return {
    type: SAVE_BUILDING_MODEL_PALETTE,
    payload: api.graphql(query, variables)
  };
}

export function deleteBuildingModelPalette(variables) {
  return {
    type: DELETE_BUILDING_MODEL_PALETTE,
    payload: api.graphql(deleteBuildingModelPaletteQuery, variables)
  };
}

export function reloadBuildingModelPalettes(reload) {
  return {
    type: RELOAD_BUILDING_MODEL_PALETTES,
    payload: { reload }
  };
}
