import React from 'react';

import List from './List';
import FormModal from './FormModal';

const Phases = () => (
  <>
    <List />

    <FormModal />
  </>
);

export default Phases;
