import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import { getCreatorDisplayName } from '../../../../../../../../../utils/auditUtils';
import { formatTimestamp } from '../../../../../../../../../utils/dateUtils';

const LastCreated = ({ quickPossession }) => {
  const { createdAt } = quickPossession;
  const creatorName = getCreatorDisplayName(quickPossession);

  if (v.isBlank(createdAt) && v.isBlank(creatorName)) return null;

  return (
    <div className="text-nowrap">
      {formatTimestamp(createdAt)}
      <div className="small">{creatorName}</div>
    </div>
  );
};

LastCreated.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

LastCreated.defaultProps = {};

export default LastCreated;
