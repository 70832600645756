import React from 'react';
import PropTypes from 'prop-types';

import FilterCheckbox from '../../../../../../common/FilterCheckbox';

const RegionItem = ({
  value, selectedValues, onChange
}) => (
  <FilterCheckbox
    label={value}
    value={value}
    checked={selectedValues.includes(value)}
    onChange={onChange}
  />
);

RegionItem.propTypes = {
  value: PropTypes.string.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

RegionItem.defaultProps = {
  selectedValues: [],
  onChange: () => {}
};

export default RegionItem;
