import React from 'react';
import { Button, ModalBody } from 'reactstrap';
import i18n from 'i18n-js';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';

import { onCloseModal } from '../../utils';
import InvitationSentImage from '../../../../../assets/images/InvitationSentImage';
import useHomeRental from '../../../../../hooks/useHomeRental';
import { getInvitationTypeName } from '../utils';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.invitationSent.index' };

const InvitationSent = ({ invitationType }) => {
  const { typeI18nKey } = useHomeRental();
  const invitationTypeName = getInvitationTypeName(invitationType);

  const onViewCustomers = () => {
    onCloseModal();
    navigate('/admin/customers');
  };

  return (
    <ModalBody>
      <div className="text-center mt-3">
        <InvitationSentImage />
      </div>

      <div className="mt-4 mb-5">
        <div className="font-18 font-weight-600 text-center mb-1">{i18n.t('title', i18nOpts)}</div>
        <div className="font-14 mb-4 text-center">
          {i18n.t(`${typeI18nKey}.description`, {
            ...i18nOpts,
            invitationTypeName
          })}
        </div>
      </div>

      <div className="d-flex align-items-center gap-3">
        <div className="flex-even">
          <Button outline color="secondary" block onClick={onCloseModal}>
            {i18n.t('buttons.close')}
          </Button>
        </div>

        <div className="flex-even">
          <Button color="primary" onClick={onViewCustomers} block>
            {i18n.t(`${typeI18nKey}.buttons.viewCustomers`, i18nOpts)}
          </Button>
        </div>
      </div>
    </ModalBody>
  );
};

InvitationSent.propTypes = {};

InvitationSent.defaultProps = {};

export default connect((store) => ({
  invitationType: store.salesInvitation.invitationType,
}))(InvitationSent);
