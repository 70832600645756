const fragment = `
  id
  hashId
  name
  url
  imageUrl
  company {
    id
    name
  }
`;

export const listMortgageProvidersQuery = `
  query ListMortgageProviders(
    $filter: MortgageProviderFilterInput
  ) {
    items: listMortgageProviders(filter: $filter) {
      ${fragment}
    }
  }
`;

export const getMortgageProviderQuery = `
  query GetMortgageProvider($id: Int!) {
    item: getMortgageProvider(id: $id) {
      ${fragment}
    }
  }
`;

export const createMortgageProviderQuery = `
  mutation CreateMortgageProvider(
    $input: CreateMortgageProviderInput!
    $image: Upload
  ) {
    item: createMortgageProvider(input: $input, image: $image) {
      ${fragment}
    }
  }
`;

export const updateMortgageProviderQuery = `
  mutation UpdateMortgageProvider(
    $input: UpdateMortgageProviderInput!
    $image: Upload
  ) {
    item: updateMortgageProvider(input: $input, image: $image) {
      ${fragment}
    }
  }
`;

export const deleteMortgageProviderQuery = `
  mutation DeleteMortgageProvider(
    $id: Int!
  ) {
    item: deleteMortgageProvider(id: $id) {
      ${fragment}
    }
  }
`;
