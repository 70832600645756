import i18n from 'i18n-js';

import { Tabs } from '../../utils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.salesConfig.index' };

export function getSettings() {
  const settings = [
    {
      id: 'companyType',
      label: i18n.t('companyType', i18nOpts),
      containerId: 'companyTypeContainer'
    },
    {
      id: 'enableSingleFamilyHome',
      label: i18n.t('enableSingleFamilyHome', i18nOpts),
      containerId: 'enableSingleFamilyHomeContainer'
    },
    {
      id: 'enableMultiFamilyHome',
      label: i18n.t('enableMultiFamilyHome', i18nOpts),
      containerId: 'enableMultiFamilyHomeContainer'
    },
    {
      id: 'fixedDepositAmount',
      label: i18n.t('fixedDepositAmount', i18nOpts),
      containerId: 'fixedDepositAmountContainer'
    },
    {
      id: 'downPayment',
      label: i18n.t('downPayment', i18nOpts),
      containerId: 'downPaymentContainer'
    },
    {
      id: 'loanTerm',
      label: i18n.t('loanTerm', i18nOpts),
      containerId: 'loanTermContainer'
    },
    {
      id: 'interestRate',
      label: i18n.t('interestRate', i18nOpts),
      containerId: 'interestRateContainer'
    },
    {
      id: 'enableValidUntil',
      label: i18n.t('enableValidUntil', i18nOpts),
      containerId: 'enableValidUntilContainer'
    },
    {
      id: 'validUntilDate',
      label: i18n.t('validUntilDate', i18nOpts),
      containerId: 'validUntilDateContainer'
    }
  ];

  if (isMasterAdmin() || isTecSupport()) {
    settings.push(...[
      {
        id: 'enableACHPayments',
        label: i18n.t('enableACHPayments', i18nOpts),
        containerId: 'enableACHPaymentsContainer'
      }
    ]);
  }

  return settings.map((setting) => ({
    ...setting,
    tabId: Tabs.SALES_CONFIG
  }));
}
