import React from 'react';

const AddImageIcon = ({ ...props }) => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" color="#667085" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20.5003 8.54199V32.4587M8.54199 20.5003H32.4587" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

AddImageIcon.propTypes = {};

AddImageIcon.defaultProps = {};

export default AddImageIcon;
