import {
  GET_COMPANY_WORKFLOW,
  GET_RESERVE_NOW_WORKFLOW,
  GET_VERIFIED_WORKFLOW,
  SAVE_COMPANY_WORKFLOW
} from './types';
import api from '../../api';
import {
  getCompanyWorkflowQuery, saveCompanyWorkflowMutation
} from '../../graphql/companyWorkflows';

export function getReserveNowWorkflow(companyId, type) {
  return {
    type: GET_RESERVE_NOW_WORKFLOW,
    payload: api.graphql(getCompanyWorkflowQuery, { companyId, type })
  };
}

export function getVerifiedWorkflow(companyId, type) {
  return {
    type: GET_VERIFIED_WORKFLOW,
    payload: api.graphql(getCompanyWorkflowQuery, { companyId, type })
  };
}

export function getCompanyWorkflow(variables = {}) {
  return {
    type: GET_COMPANY_WORKFLOW,
    payload: api.graphql(getCompanyWorkflowQuery, variables)
  };
}

export function saveCompanyWorkflow(variables = {}) {
  return {
    type: SAVE_COMPANY_WORKFLOW,
    payload: api.graphql(saveCompanyWorkflowMutation, variables)
  };
}
