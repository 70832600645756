import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

const Phone = ({ customer }) => {
  const { phone } = customer;

  if (v.isBlank(phone)) return null;

  return (
    <div className="text-nowrap">
      {phone}
    </div>
  );
};

Phone.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

Phone.defaultProps = {};

export default Phone;
