import {
  GET_COMMUNITY_MODELS, SORT_COMMUNITY_MODELS,
  SAVE_COMMUNITY_MODEL, DELETE_COMMUNITY_MODEL, SET_COMMUNITY_MODELS,
  UPDATE_COMMUNITY_MODEL
} from './types';
import {
  listCommunityModelsQuery, sortCommunityModelsQuery, createCommunityModelQuery,
  deleteCommunityModelQuery, updateCommunityModelQuery
} from '../../graphql/communityModels';
import api from '../../api';

export function getCommunityModels(variables = {}) {
  return {
    type: GET_COMMUNITY_MODELS,
    payload: api.graphql(listCommunityModelsQuery, variables)
  };
}

export function sortCommunityModels(variables = {}) {
  return {
    type: SORT_COMMUNITY_MODELS,
    payload: api.graphql(sortCommunityModelsQuery, variables)
  };
}

export function saveCommunityModel(variables = {}) {
  return {
    type: SAVE_COMMUNITY_MODEL,
    payload: api.graphql(createCommunityModelQuery, variables)
  };
}

export function updateCommunityModel(variables = {}) {
  return {
    type: UPDATE_COMMUNITY_MODEL,
    payload: api.graphql(updateCommunityModelQuery, variables)
  };
}

export function deleteCommunityModel(id) {
  return {
    type: DELETE_COMMUNITY_MODEL,
    payload: api.graphql(deleteCommunityModelQuery, { id })
  };
}

export function setCommunityModels(communityModels) {
  return {
    type: SET_COMMUNITY_MODELS,
    payload: { communityModels }
  };
}
