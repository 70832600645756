import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardImg } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { Link, navigate } from '@reach/router';

import './styles.scss';
import { DEFAULT_IMAGE } from '../../../../../../constants';
import { deleteProductType } from '../../../../../../store/actions/productTypeActions';
import ActionsMenu from '../../../../../global/ActionsMenu';

class ListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };

    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onToggleFocused = this.onToggleFocused.bind(this);
  }

  onToggleFocused(focused) {
    this.setState({ focused });
  }

  onDelete(productTypeId) {
    const { props } = this;

    props.deleteProductType(productTypeId);
  }

  onEdit(productType) {
    navigate(`/admin/productTypes/form?id=${productType.id}`);
  }

  render() {
    const { productType } = this.props;
    const { focused } = this.state;

    if (!productType) return null;

    return (
      <Card
        onMouseEnter={() => this.onToggleFocused(true)}
        onMouseLeave={() => this.onToggleFocused(false)}
      >
        <Link to={`/admin/productTypes/${productType.id}`}>
          <CardImg
            top
            width="100%"
            src={productType.imageUrl || DEFAULT_IMAGE}
            alt=""
            className="mb-0 product-type-image"
          />
        </Link>
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1">
              <div>{productType.name}</div>
            </div>
            <div className="d-flex">
              {focused && (
                <ActionsMenu
                  item={productType}
                  onDelete={this.onDelete}
                  onEdit={this.onEdit}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

ListItem.propTypes = {
  productType: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({}), {
  deleteProductType
})(ListItem);
