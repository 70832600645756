import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Button, Col, Row, Spinner
} from 'reactstrap';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { globalHistory, useLocation } from '@reach/router';
import { useBeforeunload } from 'react-beforeunload';
import i18n from 'i18n-js';
import v from 'voca';

import NavTabs from './NavTabs';
import { Tabs } from './utils';
import TabsContent from './TabsContent';
import { getCompany, saveCompany } from '../../../../store/actions/companyActions';
import { saveCompanyWorkflow } from '../../../../store/actions/companyWorkflowActions';
import { isMasterAdmin, isTecSupport } from '../../../../utils/authUtils';
import useSaveCompanySettings from './hooks/useSaveCompanySettings';
import SaveChangesModal from './components/SaveChangesModal';
import ModalService from '../../../../services/ModalService';
import { ModalNames } from '../../../../constants';
import Loading from '../../../ui/Loading';
import SettingsSearch from './components/SettingsSearch';

const i18nOpts = { scope: 'components.admin.companies.settings.index' };

function openSaveChangesModal() {
  ModalService.open(ModalNames.COMPANY_SAVE_CHANGES_MODAL);
}

const Settings = ({ id, ...props }) => {
  const [mounted, setMounted] = useState(false);
  const [activeTab, setActiveTab] = useState(Tabs.COMPANY_AND_BRANDING);
  const [ignoreChangesCheck, setIgnoreChangesCheck] = useState(false);
  const [nextPath, setNextPath] = useState('/');

  const { pathname } = useLocation();

  const {
    form, submitting, error, onTextChange, hasChanges,
    loadCompanyAsync, populateFormAsync, saveCompanySettingsAsync
  } = useSaveCompanySettings(props);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  const onSave = () => {
    saveCompanySettingsAsync()
      .then(() => {
        toast.success(i18n.t('messages.saved', i18nOpts));
        setTimeout(() => onRedirect(), 1500);
      })
      .catch(() => toast.error(i18n.t('messages.notSaved', i18nOpts)));
  };

  const onDoNotSave = () => {
    setIgnoreChangesCheck(true);
    navigate(nextPath);
  };

  const onRedirect = () => {
    const redirectPath = canViewMasterSettings ? '/admin/companies' : '/';
    navigate(redirectPath);
  };

  const loadForm = () => {
    if (v.isBlank(id)) {
      populateFormAsync(null).then(() => setMounted(true));
      return;
    }

    loadCompanyAsync(parseInt(id, 10))
      .then((item) => populateFormAsync(item))
      .then(() => setMounted(true))
      .catch(() => onRedirect());
  };

  useEffect(() => {
    loadForm();
  }, []);

  useEffect(() => globalHistory.listen(({ action, location }) => {
    if (ignoreChangesCheck) {
      setIgnoreChangesCheck(false);
      return;
    }

    if ((action === 'PUSH'
      && !location.pathname.startsWith('/admin/companies')
      && !location.pathname.startsWith('/admin/myCompany')
      && location.pathname !== '/') || action === 'POP') {
      if (hasChanges) {
        setNextPath(location.pathname);
        openSaveChangesModal();
        navigate(`${pathname}`);
      }
    }
  }), [hasChanges, ignoreChangesCheck]);

  useBeforeunload((event) => {
    if (hasChanges) {
      event.preventDefault();
      openSaveChangesModal();
    }
  });

  if (!mounted) return <Loading loading fullScreen />;

  const isMasterAdminRole = isMasterAdmin();
  const isTecSupportRole = isTecSupport();
  const canViewMasterSettings = isMasterAdminRole || isTecSupportRole;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <Row className="mb-4">
        <Col>
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </Col>
        <Col md={12} lg={7} xl={5}>
          <SettingsSearch onSelectSetting={(tab) => setActiveTab(tab)} />
        </Col>
      </Row>

      <NavTabs activeTab={activeTab} onChange={onTabChange} />

      <TabsContent activeTab={activeTab} form={form} error={error} onTextChange={onTextChange} />

      <hr className="mt-0" />
      <div className="d-flex justify-content-end gap-3">
        <Button outline color="secondary" onClick={onRedirect}>
          {i18n.t('buttons.cancel')}
        </Button>
        <Button color="primary" onClick={onSave} disabled={submitting || !hasChanges}>
          {submitting && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
      </div>

      <SaveChangesModal onSave={onSave} onDoNotSave={onDoNotSave} />
    </div>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default connect((store) => ({
  currentUser: store.users.currentUser,
  currentCompany: store.companies.currentCompany
}), {
  getCompany,
  saveCompany,
  saveCompanyWorkflow
})(Settings);
