import React from 'react';
import PropTypes from 'prop-types';

import styles from './SelectedColumnItem.module.scss';
import SortableIcon from '../../../../assets/icons/SortableIcon';

const SelectedColumnItem = ({ column }) => (
  <div className={styles.container}>
    <div className={styles.icon}>
      <SortableIcon />
    </div>
    <span className="ml-3">{column.name}</span>
  </div>
);

SelectedColumnItem.propTypes = {
  column: PropTypes.objectOf(PropTypes.any).isRequired
};

SelectedColumnItem.defaultProps = {};

export default SelectedColumnItem;
