import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import i18n from 'i18n-js';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';

const i18nOpts = { scope: 'components.common.copyLink.index' };

const TooltipContent = ({ copied, scheduleUpdate }) => {
  const [text, setText] = useState(i18n.t('copyToClipboard', i18nOpts));

  useEffect(() => {
    setText(copied ? i18n.t('copied', i18nOpts) : i18n.t('copyToClipboard', i18nOpts));
    scheduleUpdate();
  }, [copied]);

  return (<>{text}</>);
};

const CopyLink = ({ text, onCopied, children }) => {
  const [copied, setCopied] = useState(false);
  const [id] = useState(_.uniqueId('copy-tooltip-'));

  const onCopy = () => {
    setCopied(true);
    onCopied();
  };

  const onMouseLeave = () => {
    setTimeout(() => {
      setCopied(false);
    }, 100);
  };

  return (
    <>
      <CopyToClipboard
        id={id}
        text={text}
        onCopy={onCopy}
      >
        <div
          className="d-inline-block copy-link"
          onMouseLeave={onMouseLeave}
        >
          {children}
        </div>
      </CopyToClipboard>

      <UncontrolledTooltip target={id}>
        {({ scheduleUpdate }) => (
          <TooltipContent scheduleUpdate={scheduleUpdate} copied={copied} />
        )}
      </UncontrolledTooltip>
    </>
  );
};

CopyLink.propTypes = {
  onCopied: PropTypes.func
};

CopyLink.defaultProps = {
  onCopied: () => {}
};

export default CopyLink;
