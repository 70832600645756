import React from 'react';
import v from 'voca';
import classNames from 'classnames';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import styles from './Size.module.scss';
import { formatNumber } from '../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.global.home.size.index' };

const Size = ({ children, className, size }) => {
  if (v.isBlank(children)) return null;

  const sizeStyle = v.isBlank(size) ? null : styles[size];

  return (
    <div className={classNames(styles.container, 'text-nowrap', sizeStyle, className)}>
      <span className="mr-1">{formatNumber(children)}</span>
      <span>{i18n.t('sqft', i18nOpts)}</span>
    </div>
  );
};

Size.propTypes = {
  size: PropTypes.oneOf(['lg'])
};

Size.defaultProps = {
  size: null
};

export default Size;
