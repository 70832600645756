import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import styles from './DealCustomerInfo.module.scss';
import OpenWindowIcon from '../../../../assets/icons/OpenWindowIcon';

const DealCustomerInfo = ({ customer }) => (
  <div>
    <a
      href={`/admin/customers/${customer.id}`}
      target="_blank"
      rel="noreferrer"
      className="d-inline-flex align-items-center gap-2"
    >
      <span className="col-gray-700 font-weight-600 font-24">{customer.name}</span>
      <OpenWindowIcon width={20} height={20} className={styles.openIcon} />
    </a>

    {!v.isBlank(customer.phone) && (
      <div className="font-weight-500 font-16">{customer.phone}</div>
    )}

    {!v.isBlank(customer.email) && (
      <div className="font-weight-500 font-16 word-break-break-all">{customer.email}</div>
    )}
  </div>
);

DealCustomerInfo.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

DealCustomerInfo.defaultProps = {};

export default DealCustomerInfo;
