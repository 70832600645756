import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { TabContent, TabPane } from 'reactstrap';

import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';
import NavTabs from './NavTabs';
import { Tabs } from './utils';
import TrackingCode from './TrackingCode';
import Forms from './Forms';
import Pages from './Pages';

const i18nOpts = { scope: 'components.admin.integrations.tracking.index' };

const Tracking = () => {
  const [activeTab, setActiveTab] = useState(Tabs.TRACKING_CODE);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts), url: '' }]} />

      <NavTabs activeTab={activeTab} onChange={onTabChange} />

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.TRACKING_CODE}>
          <TrackingCode />
        </TabPane>
        <TabPane tabId={Tabs.FORMS}>
          <Forms />
        </TabPane>
        <TabPane tabId={Tabs.PAGES}>
          <Pages />
        </TabPane>
      </TabContent>
    </div>
  );
};

Tracking.propTypes = {};

Tracking.defaultProps = {};

export default Tracking;
