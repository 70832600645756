import React, { Component } from 'react';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';

import { getElevations, sortElevations, setElevations } from '../../../../../store/actions/elevationActions';
import FormModal from './FormModal';
import ListItem from './ListItem';
import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';
import { isAdmin } from '../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.models.show.elevations.index' };

function onOpenModal() {
  ModalService.open(ModalNames.ELEVATION_FORM);
}

class Elevations extends Component {
  constructor(props) {
    super(props);

    this.onSetElevations = this.onSetElevations.bind(this);
    this.onSortElevations = this.onSortElevations.bind(this);
  }

  componentDidMount() {
    this.loadElevations();
  }

  onSetElevations(elevations) {
    const { props } = this;
    props.setElevations(elevations);
  }

  onSortElevations() {
    const { props } = this;
    const { elevations, model } = this.props;

    const ids = elevations.map((fg) => fg.id);

    const variables = { modelId: model.id, ids };
    props.sortElevations(variables);
  }

  loadElevations() {
    const { props } = this;
    const { model } = this.props;

    const variables = { filter: { modelId: model.id } };
    props.getElevations(variables);
  }

  render() {
    const { elevations } = this.props;

    return (
      <div>
        <ReactSortable
          list={elevations}
          handle=".sortable-elevation-handler"
          draggable=".sortable-elevation-item"
          setList={this.onSetElevations}
          onEnd={this.onSortElevations}
        >
          {
            elevations.map((e) => (
              <ListItem key={`elevation-${e.id}`} elevation={e} />
            ))
          }
        </ReactSortable>

        {isAdmin() && (
          <div className="text-right">
            <Button color="primary" onClick={onOpenModal}>
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Button>
          </div>
        )}

        <FormModal />

      </div>
    );
  }
}

export default connect((store) => ({
  model: store.models.model,
  elevations: store.elevations.elevations
}), {
  getElevations,
  setElevations,
  sortElevations
})(Elevations);
