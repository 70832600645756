import React, { useEffect, useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { ASSIGNED_FILTERS } from '../utils';
import { isSalesUser } from '../../../../../utils/authUtils';
import api from '../../../../../api';
import { SellerTypes } from '../../../../../constants';
import { setCustomerFilters } from '../../../../../store/actions/customerActions';

const ASSIGNED_FILTER_KEYS = Object.keys(ASSIGNED_FILTERS);

const i18nOpts = { scope: 'components.admin.customers.list.assignedFilter.index' };

function countAssignedCustomersAsync() {
  return api.graphql(`
      query countAssignedCustomers {
          result: countAssignedCustomers
      }
  `);
}

const AssignedFilter = ({ selectedFilters, currentUser, ...props }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [mounted, setMounted] = useState(false);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onToggleFilter = (filter) => {
    setSelectedFilter(filter);
    const newFilter = filter === ASSIGNED_FILTERS.ALL
      ? { salesLeadId: null, salesLeadType: null }
      : { salesLeadId: currentUser.id, salesLeadType: SellerTypes.SALES_REP };
    props.setCustomerFilters({ ...selectedFilters, ...newFilter });
  };

  useEffect(() => {
    if (isSalesUser()) return;

    countAssignedCustomersAsync().then(({ data: { result } }) => {
      if (result > 0) setMounted(true);
    });
  }, []);

  if (!mounted) return null;

  return (
    <div className="ml-3">
      <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
        <DropdownToggle color="outline-secondary" size="md" caret>
          <span>{ selectedFilter ? i18n.t(selectedFilter.toLowerCase(), i18nOpts) : i18n.t('all', i18nOpts)}</span>
        </DropdownToggle>
        <DropdownMenu right>
          {
            ASSIGNED_FILTER_KEYS.map((filter) => (
              <DropdownItem
                key={`filter-${filter}`}
                onClick={() => onToggleFilter(filter.toLowerCase())}
              >
                {i18n.t(filter.toLowerCase(), i18nOpts)}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

AssignedFilter.propTypes = {};

AssignedFilter.defaultProps = {};

export default connect((store) => ({
  currentUser: store.users.currentUser,
  selectedFilters: store.customers.filters
}), {
  setCustomerFilters
})(AssignedFilter);
