import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import ListEmpty from '../../../../../common/ListEmpty';
import ListPagination from '../../../../../common/ListPagination';
import ListItem from './ListItem';
import ListHeader from './ListHeader';
import DealModal from '../../../../../global/home/DealModal';
import Loading from '../../../../../ui/Loading';
import DepositRefundFormModal from '../../../../../global/Deposit/DepositRefundFormModal';

const List = ({
  homes, loading, pagination, onChangePage
}) => (
  <>
    {loading ? (
      <Loading loading />
    ) : (
      <>
        <ListEmpty loading={loading} items={homes} />

        {homes.length > 0 && (
          <div className="table-responsive mb-4">
            <Table bordered style={{ height: '1px' }}>
              <thead>
                <ListHeader />
              </thead>
              <tbody>
                {
                  homes.map((home) => (
                    <ListItem key={`home-${home.id}`} home={home} />
                  ))
                }
              </tbody>
            </Table>
          </div>
        )}

        <div className="text-right mt-4">
          <ListPagination pagination={pagination} onPress={onChangePage} />
        </div>
      </>
    )}
    <DealModal />
    <DepositRefundFormModal />
  </>
);

List.propTypes = {
  homes: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  pagination: PropTypes.objectOf(PropTypes.any),
  onChangePage: PropTypes.func
};

List.defaultProps = {
  loading: false,
  pagination: {},
  onChangePage: () => {}
};

export default List;
