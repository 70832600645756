import { useSelector } from 'react-redux';

import { CustomerSortFields, SortOrders } from '../../../../../../constants';
import { CustomerColumns } from '../../utils';

const useSortOptions = () => {
  const {
    sortField, sortOrder, columns, currentCompany
  } = useSelector((store) => ({
    sortField: store.customers.sortField,
    sortOrder: store.customers.sortOrder,
    columns: store.customers.columns,
    currentCompany: store.companies.currentCompany
  }));

  if (Object.values(CustomerColumns).includes(sortField)) {
    const isSortFieldSelected = columns.includes(sortField);
    if (!currentCompany.enableVerifiedBuyingPower || !isSortFieldSelected) {
      return { field: CustomerSortFields.LAST_ACTIVE, direction: SortOrders.DESC };
    }
  }

  return { field: sortField, direction: sortOrder };
};

export default useSortOptions;
