import { Col, Row } from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';
import { connect } from 'react-redux';
import React from 'react';

import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';
import { getUnitAvailabilityName } from '../../../../../utils/unitUtils';
import { isAdmin } from '../../../../../utils/authUtils';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';

const i18nOpts = { scope: 'components.admin.units.show.details.index' };

const Details = ({ unit }) => {
  if (!unit) return null;

  const creatorName = getCreatorDisplayName(unit);
  const lastModifierName = getLastModifierDisplayName(unit);

  return (
    <div>
      <Row>
        <Col xs={12} sm={6} md={7} lg={4}>
          {!v.isBlank(unit.hashId) && (
            <div className="mb-4">
              <div className="label">{i18n.t('unitId', i18nOpts)}</div>
              <div><strong>{unit.hashId}</strong></div>
            </div>
          )}

          <div className="mb-4">
            <div className="label">{i18n.t('buildingModel', i18nOpts)}</div>
            <div><strong>{unit.buildingModel.name}</strong></div>
          </div>

          {!v.isBlank(unit.defaultImageUrl) && (
            <div className="mb-4">
              <img src={unit.defaultImageUrl} alt={unit.buildingModel.name} className="w-100" />
            </div>
          )}

          { !v.isBlank(unit.floor) && (
            <div className="mb-4">
              <div className="label">{i18n.t('floor', i18nOpts)}</div>
              <div><strong>{unit.floor}</strong></div>
            </div>
          )}

          { isValidAmount(unit.price) && (
            <div className="mb-4">
              <div className="label">{i18n.t('price', i18nOpts)}</div>
              <div><strong>{formatCurrency(unit.price)}</strong></div>
            </div>
          )}

          <div className="mb-4">
            <div className="label">{i18n.t('availability', i18nOpts)}</div>
            <div><strong>{getUnitAvailabilityName(unit.availability)}</strong></div>
          </div>

          { !v.isBlank(unit.floorLocation) && (
            <div className="mb-4">
              <div className="label">{i18n.t('floorLocation', i18nOpts)}</div>
              <div><strong>{unit.floorLocation}</strong></div>
            </div>
          )}

          { !v.isBlank(unit.view) && (
            <div className="mb-4">
              <div className="label">{i18n.t('view', i18nOpts)}</div>
              <div><strong>{unit.view}</strong></div>
            </div>
          )}

          {isAdmin() && (
            <>
              { !v.isBlank(creatorName) && (
                <div className="mb-4">
                  <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
                  <div><strong>{creatorName}</strong></div>
                </div>
              )}
              { !v.isBlank(lastModifierName) && (
                <div className="mb-4">
                  <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
                  <div><strong>{lastModifierName}</strong></div>
                </div>
              )}
            </>
          )}

        </Col>
      </Row>
    </div>
  );
};

export default connect((store) => ({
  unit: store.units.unit
}), {})(Details);
