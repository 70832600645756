import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { BiTrash } from 'react-icons/bi';
import { Link } from 'gatsby';
import i18n from 'i18n-js';
import v from 'voca';

import { getParsedPhoneNumbers } from './utils';
import { confirmDelete } from '../../../confirmAlert';
import { isAdmin } from '../../../../../utils/authUtils';
import { isSalesRep } from '../../../../../utils/salesRepUtils';
import { deleteSellerSpace } from '../../../../../store/actions/sellerSpaceActions';

const i18nOpts = { scope: 'components.global.sellersManagement.sellersList.sellerItem.index' };

const SellerItem = ({ sellerSpace, ...props }) => {
  const { seller } = sellerSpace;
  const phonesString = isSalesRep(seller) ? getParsedPhoneNumbers(seller) : null;

  const onDelete = () => {
    props.deleteSellerSpace(sellerSpace.id)
      .then(() => props.onAfterDelete());
  };

  const sellerUrl = useMemo(() => {
    if (isSalesRep(seller)) return `/admin/users/${seller.id}`;
    return `/admin/teams/${seller.id}`;
  }, [seller]);

  return (
    <Card body className="h-100 mb-0">
      <div className="d-flex flex-column h-100">
        <div>
          {isAdmin() ? (
            <Link to={sellerUrl}>{seller.name}</Link>
          ) : (
            <span>{seller.name}</span>
          )}
        </div>

        <div className="text-secondary">
          {isSalesRep(seller) ? (
            <>
              <div className="small mb-1">{seller.email}</div>
              {!v.isBlank(phonesString) && (
                <div className="mb-1">
                  {phonesString}
                </div>
              )}
            </>
          ) : (
            <small>{i18n.t('teamUsersCount', { ...i18nOpts, count: seller.activeUsersCount })}</small>
          )}
        </div>

        <div className="mt-auto text-right">
          {isAdmin() && (
            <div
              role="button"
              aria-hidden
              className="text-danger d-inline-block"
              title={i18n.t('buttons.delete')}
              onClick={() => confirmDelete(() => onDelete())}
            >
              <BiTrash size="1.1rem" />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

SellerItem.propTypes = {
  sellerSpace: PropTypes.objectOf(PropTypes.any).isRequired,
  onAfterDelete: PropTypes.func
};

SellerItem.defaultProps = {
  onAfterDelete: () => {}
};

export default connect((store) => ({}), {
  deleteSellerSpace
})(SellerItem);
