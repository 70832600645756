import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import classNames from 'classnames';

import styles from './FilterCheckbox.module.scss';

const FilterCheckbox = ({
  label, color, Icon, ...props
}) => (
  <FormGroup check className="py-1">
    <Label check>
      <Input
        type="checkbox"
        {...props}
      />
      <span className={classNames(styles.label, styles[color])}>
        {Icon}
        {label}
      </span>
    </Label>
  </FormGroup>
);

FilterCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['light', 'info', 'success']),
  Icon: PropTypes.element
};

FilterCheckbox.defaultProps = {
  color: 'light',
  Icon: null
};

export default FilterCheckbox;
