import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { DEFAULT_BUYER_VERIFIED_WORKFLOW, DEFAULT_RENTAL_VERIFIED_WORKFLOW } from '../constants';

const useVerifiedWorkflowSteps = () => {
  const { verifiedWorkflow, currentCompany } = useSelector((store) => ({
    verifiedWorkflow: store.companyWorkflows.verifiedWorkflow,
    currentCompany: store.companies.currentCompany,
  }));

  if (!currentCompany) return [];

  const isRental = !!currentCompany?.enableHomeRental;

  const defaultWorkflow = useMemo(() => (isRental ? DEFAULT_RENTAL_VERIFIED_WORKFLOW
    : DEFAULT_BUYER_VERIFIED_WORKFLOW), [isRental]);

  return verifiedWorkflow?.workflow || [...defaultWorkflow];
};

export default useVerifiedWorkflowSteps;
