import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseActionsMenu from '../../../../../../../global/ActionsMenu';
import { deleteTeamUser } from '../../../../../../../../store/actions/teamUserActions';
import ModalService from '../../../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../../../constants';

const ActionsMenu = ({ teamUser, ...props }) => {
  const onEdit = () => {
    ModalService.open(ModalNames.TEAM_USER_FORM, { teamUser });
  };

  const onDelete = () => {
    props.deleteTeamUser(teamUser.id);
  };

  return (
    <BaseActionsMenu item={teamUser} onEdit={onEdit} onDelete={onDelete} />
  );
};

ActionsMenu.propTypes = {
  teamUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

ActionsMenu.defaultProps = {};

export default connect(() => ({
}), {
  deleteTeamUser,
})(ActionsMenu);
