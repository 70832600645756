import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';
import i18n from 'i18n-js';
import classNames from 'classnames';

import styles from './HomeInfo.module.scss';
import { getHomeInfo } from '../../../../../../utils/homeUtils';
import Price from '../../../../home/Price';
import ValidUntil from '../../../../home/ValidUntil';
import CommunityProject from '../../../../home/CommunityProject';

const HomeInfo = ({ home }) => {
  if (!home) return null;

  const { homeUrl } = home;
  const {
    name, homeType, imageUrl
  } = getHomeInfo(home);

  const isDeletedHome = !v.isBlank(home.deletedByUserAt);

  return (
    <div className={classNames('d-flex gap-3 mt-2', { 'opacity-03': isDeletedHome })}>
      {!v.isBlank(imageUrl) && (
        <div className={styles.imageContainer}>
          <img src={imageUrl} alt={name} className={styles.image} />
        </div>
      )}
      <div>
        <div className="font-weight-500 col-gray-700">{name}</div>
        <div>
          <Price amount={home.totalPrice} className="mr-1 font-weight-500 col-gray-700" />
          <ValidUntil home={home} />
        </div>
        <div className="d-flex align-items-center">
          <CommunityProject home={home} />
          {!v.isBlank(homeType) && (
            <span>
              &nbsp;
              {`- ${homeType}`}
            </span>
          )}
        </div>

        {!isDeletedHome && (
          <a href={homeUrl} target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-sm px-5 mt-2">
            {i18n.t('buttons.view')}
          </a>
        )}
      </div>
    </div>
  );
};

HomeInfo.propTypes = {
  home: PropTypes.objectOf(PropTypes.any)
};

HomeInfo.defaultProps = {
  home: null
};

export default HomeInfo;
