import React from 'react';
import { Card, CardHeader } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import classNames from 'classnames';

import DownloadButton from './DownloadButton';

const i18nOpts = { scope: 'components.admin.customers.show.details.verification.additionalInfo.preApproval.infoCard.index' };

const InfoCard = ({ customer }) => {
  const { user } = customer;
  const preApproval = user?.preApproval;
  const hasPreApproval = !!preApproval;

  return (
    <Card className="mb-2 card-without-body">
      <CardHeader className={classNames({ 'py-2': hasPreApproval })}>
        <div className="d-flex align-items-center gap-3">
          <h4 className="mb-0">{i18n.t('title', i18nOpts)}</h4>
          {hasPreApproval && (
            <div className="ml-auto">
              <DownloadButton preApproval={preApproval} />
            </div>
          )}
        </div>
      </CardHeader>
    </Card>
  );
};

InfoCard.propTypes = {};

InfoCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
}))(InfoCard);
