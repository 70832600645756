import React, { Component } from 'react';
import _ from 'lodash';

import styles from '../styles';
import PropertyGroup from '../PropertyGroup';
import Columns from '../Columns';
import Column from '../Column';

class StylePanel extends Component {
  modes = [
    'normal',
    'multiply',
    'screen',
    'overlay',
    'darken',
    'lighten',
    'color-dodge',
    'color-burn',
    'hard-light',
    'soft-light',
    'difference',
    'exclusion',
    'hue',
    'saturation',
    'color',
    'luminosity'
  ];

  render() {
    const { props } = this;
    const { object } = props;
    return (
      <PropertyGroup>
        <Columns label="Width">
          <Column>
            <input
              style={{ ...styles.input, ...styles.integerInput, width: 30 }}
              onChange={(e) => props.onChange('strokeWidth', e.target.value)}
              value={object.strokeWidth}
            />
          </Column>
        </Columns>
      </PropertyGroup>
    );
  }
}

export default StylePanel;
