import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import { formatNumber } from '../../../../../../../../utils/currencyUtils';

const Size = ({ unit }) => {
  const { buildingModel } = unit;

  if (v.isBlank(buildingModel?.size)) return null;

  return (
    <div>
      {formatNumber(buildingModel.size)}
    </div>
  );
};

Size.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

Size.defaultProps = {};

export default Size;
