import v from 'voca';

import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';
import {
  createBuildingModelPackageQuery,
  updateBuildingModelPackageQuery
} from '../../../../../../graphql';
import api from '../../../../../../api';

export function onClose() {
  ModalService.close(ModalNames.BUILDING_MODEL_PACKAGE_FORM);
}

export function saveBuildingModelPackageAsync(input) {
  const query = v.isBlank(input.id) ? createBuildingModelPackageQuery
    : updateBuildingModelPackageQuery;
  return api.graphql(query, { input });
}
