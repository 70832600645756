import v from 'voca';

import {
  SearchEngineDomains,
  SocialMediaDomains,
} from '../../../../../../constants';
import { getSearchEngineName, getSocialMediaName } from '../../../../../../utils/enumUtils';

const UTM_PARAMS_ORDER = ['utmSource', 'utmMedium', 'utmCampaign', 'utmContent', 'utmTerm'];

export function getUtmParams(trafficSourceData) {
  if (!trafficSourceData) return [];

  const utmPrams = [];
  Object.entries(trafficSourceData)
    .forEach(([name, value]) => {
      if (name.startsWith('utm') && !v.isBlank(value)) {
        utmPrams.push({
          name,
          value
        });
      }
    });

  return utmPrams;
}

export function sortUtmParams(utmParams) {
  const params = [...utmParams];
  return params.sort((a, b) => UTM_PARAMS_ORDER.indexOf(a.name)
    - UTM_PARAMS_ORDER.indexOf(b.name));
}

export function getTrafficSourceName(visitorSession) {
  const { trafficSourceData } = visitorSession;

  let sourceName = getSourceNameByReferrerUrl(visitorSession.referer);
  if (!v.isBlank(sourceName)) return sourceName;

  sourceName = getSourceNameBySourceData(trafficSourceData);
  if (!v.isBlank(sourceName)) return sourceName;

  return null;
}

function getSourceNameByReferrerUrl(referrerUrl) {
  if (v.isBlank(referrerUrl)) return null;

  const urlObj = new URL(referrerUrl);
  const { hostname } = urlObj;
  const searchEngine = findDomainName(SearchEngineDomains, hostname);
  if (!v.isBlank(searchEngine)) return getSearchEngineName(searchEngine);

  const socialMedia = findDomainName(SocialMediaDomains, hostname);
  if (!v.isBlank(socialMedia)) return getSocialMediaName(socialMedia);

  return null;
}

function getSourceNameBySourceData(trafficSourceData) {
  const {
    gclid,
    wbraid,
    gbraid,
    fbclid,
  } = trafficSourceData || {};

  if ([gclid, wbraid, gbraid].some((i) => !v.isBlank(i))) {
    return getSearchEngineName('google');
  }
  if ([fbclid].some((i) => !v.isBlank(i))) return getSocialMediaName('facebook');
  return null;
}

function findDomainName(domainsMap, hostname) {
  const foundDomain = Object.entries(domainsMap)
    .find(([, domains]) => domains.some((domain) => hostname.includes(domain)));
  return foundDomain ? foundDomain[0] : null;
}
