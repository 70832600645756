import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { DropdownItem } from 'reactstrap';

import BaseActionsMenu from '../../../../../global/ActionsMenu';
import { deleteQuickPossession, setReloadQuickPossessions } from '../../../../../../store/actions/quickPossessionActions';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames, SalesInvitationStartTypes } from '../../../../../../constants';
import useIsReserveNowEnabled from '../../../../../../hooks/useIsReserveNowEnabled';

const ActionsMenu = ({ quickPossession, ...props }) => {
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const onEdit = () => {
    ModalService.open(ModalNames.QUICK_POSSESSION_FORM, { id: quickPossession.id });
  };

  const onDelete = () => {
    props.deleteQuickPossession(quickPossession.id)
      .then(() => props.setReloadQuickPossessions(true));
  };

  const onInvite = () => {
    ModalService.open(ModalNames.SALES_INVITATION, {
      startType: SalesInvitationStartTypes.FROM_HOME,
      home: { ...quickPossession, __typename: 'QuickPossession' }
    });
  };

  return (
    <BaseActionsMenu
      item={quickPossession}
      onEdit={onEdit}
      onDelete={onDelete}
    >
      {isReserveNowEnabled && (
        <DropdownItem onClick={onInvite}>
          {i18n.t('buttons.invite')}
        </DropdownItem>
      )}
    </BaseActionsMenu>
  );
};

ActionsMenu.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

ActionsMenu.defaultProps = {};

export default connect(() => ({
}), {
  deleteQuickPossession,
  setReloadQuickPossessions
})(ActionsMenu);
