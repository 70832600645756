import { LIST_DEALS, CLEAN_DEALS } from './types';
import { listDealsQuery } from '../../graphql/deals';
import api from '../../api';

export function listDeals(variables = {}) {
  return {
    type: LIST_DEALS,
    payload: api.graphql(listDealsQuery, variables)
  };
}

export function cleanDeals() {
  return {
    type: CLEAN_DEALS,
    payload: Promise.resolve()
  };
}
