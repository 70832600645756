import React from 'react';
import classNames from 'classnames';

import AddPriceRuleButton from '../../../../global/priceRules/AddPriceRuleButton';
import PriceRuleList from '../../../../global/priceRules/PriceRuleList';
import { isAdmin } from '../../../../../utils/authUtils';

const PriceRules = () => (
  <div>
    {isAdmin() && (
    <div className="text-right">
      <AddPriceRuleButton size="md" />
    </div>
    )}

    <PriceRuleList
      className={classNames({ 'mt-3': isAdmin() })}
      size="md"
    />
  </div>
);

PriceRules.propTypes = {};

PriceRules.defaultProps = {};

export default PriceRules;
