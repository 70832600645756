import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ADDITIONAL_INFO_STEP,
  CO_BUYER_ADDED_STEP,
  getVerifiedJourneySteps
} from './utils';
import useVerifiedWorkflowSteps from '../../../../../../hooks/useVerifiedWorkflowSteps';
import IdVerified from './IdVerified';
import FinancesVerified from './FinancesVerified';
import CoBuyerAdded from './CoBuyerAdded';
import JourneyVerticalProgressStep from '../../../../../common/JourneyVerticalProgressStep';
import { VerificationTypes } from '../../../../../../constants';
import AdditionalInfo from './AdditionalInfo';
import { cleanPartyContacts, listPartyContacts } from '../../../../../../store/actions/partyContactActions';

const Verification = ({ customer, currentCompany, ...props }) => {
  const verifiedWorkflow = useVerifiedWorkflowSteps();
  const journeySteps = getVerifiedJourneySteps(verifiedWorkflow, customer, currentCompany);

  const loadCoBuyers = () => {
    const { party } = customer;
    if (!party) return;

    const variables = { filter: { partyId: party.id }, companyId: currentCompany.id };
    props.listPartyContacts(variables)
      .catch(() => {});
  };

  useEffect(() => {
    props.cleanPartyContacts()
      .then(() => loadCoBuyers());
  }, []);

  return (
    <div>
      {
        journeySteps.map((verifiedStep, index) => {
          const { type } = verifiedStep;

          return (
            <div className="d-flex gap-4" key={`verification-section-${type}`}>
              <JourneyVerticalProgressStep
                index={index}
                steps={journeySteps}
              />

              <div className="flex-grow-1">
                {type === VerificationTypes.ID_VERIFICATION && (
                  <IdVerified />
                )}
                {type === VerificationTypes.FINANCIAL_VERIFICATION && (
                  <FinancesVerified />
                )}
                {type === CO_BUYER_ADDED_STEP && (
                  <CoBuyerAdded />
                )}
                {type === ADDITIONAL_INFO_STEP && (
                  <AdditionalInfo />
                )}
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

Verification.propTypes = {};

Verification.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}), {
  listPartyContacts,
  cleanPartyContacts
})(Verification);
