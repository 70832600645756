import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import v from 'voca';

import styles from './SettingsSearch.module.scss';
import SearchBar from '../../../../../common/SearchBar';
import ListEmpty from '../../../../../common/ListEmpty';
import { getSettings as companyAndBrandingSettings } from '../../TabsContent/CompanyAndBranding/utils';
import { getSettings as siteAndUXSettings } from '../../TabsContent/SiteAndUX/utils';
import { getSettings as salesConfigSettings } from '../../TabsContent/SalesConfig/utils';
import { getSettings as workflowSettings } from '../../TabsContent/Workflow/utils';
import { getSettings as communicationsSettings } from '../../TabsContent/Communications/utils';
import { getSettings as advancedSettings } from '../../TabsContent/AdvancedSettings/utils';

const i18nOpts = { scope: 'components.admin.companies.settings.components.settingsSearch.index' };

const SettingsSearch = ({ onSelectSetting }) => {
  const [query, setQuery] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);

  const loadSuggestions = () => {
    if (v.isBlank(query)) return;

    const settings = getSettings();
    const filteredSettings = settings
      .filter((setting) => setting.label.toLowerCase().includes(query.toLowerCase()));
    setSuggestions(filteredSettings);
  };

  const onSearch = (e, q) => {
    e.preventDefault();

    setQuery(q);
  };

  const onSelect = (setting) => {
    onSelectSetting(setting.tabId);
    setTimeout(() => {
      const settingContainer = document.getElementById(setting.containerId);
      if (settingContainer) {
        settingContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
        settingContainer.classList.add(styles.highlight);
        setTimeout(() => {
          settingContainer.classList.remove(styles.highlight);
        }, 2000);
      }
    }, 500);
  };

  const onFocus = () => {
    setShowSuggestions(true);
  };

  const onBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200);
  };

  useEffect(() => {
    loadSuggestions();
  }, [query]);

  const getSettings = () => [
    ...companyAndBrandingSettings(),
    ...siteAndUXSettings(),
    ...salesConfigSettings(),
    ...workflowSettings(),
    ...communicationsSettings(),
    ...advancedSettings()
  ];

  return (
    <div className={styles.searchContainer}>
      <SearchBar
        onSubmit={onSearch}
        inputProps={{
          bsSize: 'md',
          placeholder: i18n.t('searchBar.placeholder', i18nOpts),
          onFocus,
          onBlur
        }}
      />

      {(showSuggestions && !v.isBlank(query)) && (
        <div className={styles.searchResultsContainer}>
          <ListEmpty items={suggestions} />

          {suggestions.length > 0 && (
            <ul>
              {suggestions.map((suggestion) => (
                <li
                  key={`suggestion-${suggestion.id}`}
                  onClick={() => onSelect(suggestion)}
                  aria-hidden
                >
                  {suggestion.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

SettingsSearch.propTypes = {
  onSelectSetting: PropTypes.func
};

SettingsSearch.defaultProps = {
  onSelectSetting: () => {}
};

export default SettingsSearch;
