import React from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { MdCheck, MdClose } from 'react-icons/md';
import v from 'voca';
import { connect } from 'react-redux';

import { DepositPayoutStatuses } from '../../../../../../../../constants';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.depositSection.stripeStatus.stripePayoutStatus.index' };

const StripePayoutStatus = ({ deposit, currentCompany }) => {
  const isImmediatePayoutEnabled = !!currentCompany.paymentSettings?.stripe?.enableImmediatePayout;
  if (!isImmediatePayoutEnabled) return null;

  const { payoutStatus, payoutErrorMessage } = deposit;

  if (![DepositPayoutStatuses.SUCCEEDED, DepositPayoutStatuses.FAILED]
    .includes(payoutStatus)) return null;

  return (
    <div>
      {payoutStatus === DepositPayoutStatuses.SUCCEEDED ? (
        <Badge color="success">
          <MdCheck size={16} className="mr-1" />
          {i18n.t('completed', i18nOpts)}
        </Badge>
      ) : (
        <Badge color="danger">
          <MdClose size={16} className="mr-1" />
          {i18n.t('failed', i18nOpts)}
        </Badge>
      )}

      {payoutStatus === DepositPayoutStatuses.FAILED && !v.isBlank(payoutErrorMessage) && (
        <div className="mt-1">
          <small className="text-danger white-space-pre-wrap">{payoutErrorMessage}</small>
        </div>
      )}
    </div>
  );
};

StripePayoutStatus.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.any)
};

StripePayoutStatus.defaultProps = {
  deposit: null
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(StripePayoutStatus);
