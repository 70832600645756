import React from 'react';
import i18n from 'i18n-js';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';

import { OfferSignStatuses } from '../../../../../../../constants';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.signSection.declinedSignCard.index' };

const DeclinedSignCard = ({ home }) => {
  const { offer } = home;
  const { signStatus } = offer;

  const isSignDeclined = () => signStatus === OfferSignStatuses.DECLINED;

  if (!isSignDeclined()) return null;

  return (
    <Card body className="p-3 mb-3">
      <h3>{i18n.t('title', i18nOpts)}</h3>

      <p>{i18n.t('description', i18nOpts)}</p>
    </Card>
  );
};

DeclinedSignCard.propTypes = {};

DeclinedSignCard.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}))(DeclinedSignCard);
