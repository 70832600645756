import React from 'react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/all';
import _ from 'lodash';
import classnames from 'classnames';

import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';
import { isFloorOptionEnabled } from '../../../../../utils/quickPossessionUtils';
import { getFloorOptionPrice } from '../../../../../utils/priceRuleUtils';
import { CustomSwitch } from '../../../../ui';

const FloorOption = ({
  floorGroupId, floorOptions, selectedFloorOptions,
  enableMultipleSelection, toggleFloorOptions, community, model
}) => {
  const onToggle = (floorOption) => {
    toggleFloorOptions(floorOption, floorGroupId, enableMultipleSelection, selectedFloorOptions);
  };

  const getPrice = (floorOption) => getFloorOptionPrice(community, model, floorOption);

  if (floorOptions.length === 0) return null;

  const chosenFloorOptions = selectedFloorOptions[floorGroupId];
  const selectedFloorOptionIds = chosenFloorOptions?.length > 0 ? _.map(chosenFloorOptions, 'id') : [];

  return (
    <div className="floor-options-container">
      {
        floorOptions.map((floorOption) => {
          const price = getPrice(floorOption);
          return (
            <div
              onClick={() => onToggle(floorOption)}
              role="button"
              tabIndex="0"
              aria-hidden="true"
              key={`floor-option-${floorOption.id}`}
              className={classnames('floor-option', { disabled: !isFloorOptionEnabled(floorOption, selectedFloorOptions) })}
            >
              <div className="d-flex">
                {enableMultipleSelection ? (
                  <CustomSwitch
                    id={`floor-option-input-${floorOption.id}`}
                    readOnly
                    checked={selectedFloorOptionIds.includes(floorOption.id)}
                  />
                ) : (
                  <span className="floor-option-radio-button">
                    {selectedFloorOptionIds.includes(floorOption.id) ? (
                      <MdRadioButtonChecked size="1.2rem" />
                    ) : (
                      <MdRadioButtonUnchecked size="1.2rem" />
                    )}
                  </span>
                )}

                <div className="flex-grow-1">
                  <span className="mr-2 col-secondary-2">{floorOption.name}</span>
                  {isValidAmount(price) && (
                    <span className="text-body font-weight-light">{formatCurrency(price)}</span>
                  )}
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default FloorOption;
