import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import v from 'voca';
import classNames from 'classnames';

import useHomeRental from '../../../../hooks/useHomeRental';
import {
  getFormattedCustomerFinancialAmount
} from '../../../admin/Customers/Show/Overview/VerificationSection/utils';

const CustomerFinancialAmount = ({ customer, className }) => {
  const { isRental } = useHomeRental();

  const formattedFinancialAmount = useMemo(() => getFormattedCustomerFinancialAmount(customer,
    isRental, true),
  [customer, isRental]);

  if (v.isBlank(formattedFinancialAmount)) return null;

  return (
    <span className={classNames('text-nowrap', className)}>
      {formattedFinancialAmount}
    </span>
  );
};

CustomerFinancialAmount.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string
};

CustomerFinancialAmount.defaultProps = {
  className: null
};

export default CustomerFinancialAmount;
