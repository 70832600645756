import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import v from 'voca';
import i18n from 'i18n-js';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';

import { formatCurrency, formatNumber, isValidAmount } from '../../../../../../utils/currencyUtils';
import ActionsMenu from '../../../../../global/ActionsMenu';
import { isAdmin } from '../../../../../../utils/authUtils';
import { deleteModel } from '../../../../../../store/actions/modelActions';
import CreatedByTableItem from '../../../../../global/CreatedByTableItem';
import LastModifiedByTableItem from '../../../../../global/LastModifiedByTableItem';

const ListItem = ({ model, ...props }) => {
  const { basePrice } = model;

  const onEdit = () => {
    navigate(`/admin/models/form?id=${model.id}`);
  };

  const onDelete = (modelId) => {
    props.deleteModel(modelId);
  };

  return (
    <tr>
      <td>
        <Link to={`/admin/models/${model.id}`}>
          {model.name}
        </Link>
      </td>
      <td>{v.isBlank(model.sizeSqft) ? '' : formatNumber(model.sizeSqft)}</td>
      <td>{model.beds || 0}</td>
      <td>{model.baths || 0}</td>
      <td>{isValidAmount(basePrice) ? formatCurrency(basePrice) : ''}</td>
      <td>
        <span className={`badge text-white badge-${model.status.toLowerCase()}`}>
          {i18n.t(`statuses.${model.status.toLowerCase()}`)}
        </span>
      </td>
      {isAdmin() && (
        <>
          <CreatedByTableItem instance={model} />
          <LastModifiedByTableItem instance={model} />
          <td className="text-right">
            <ActionsMenu
              item={model}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </td>
        </>
      )}
    </tr>
  );
};

ListItem.propTypes = {
  model: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
}), {
  deleteModel
})(ListItem);
