import v from 'voca';

import { compareUnionTypeObjectTypename } from './objectUtils';
import store from '../store';
import { DEFAULT_IMAGE } from '../constants';

export function getInvitedHomeInfo(invitedHome) {
  let name;
  let communityProjectName;
  let address;
  let imageUrl;
  let isQuickPossession = false;

  const isUnit = compareUnionTypeObjectTypename(invitedHome, 'Unit');
  if (isUnit) {
    const { project } = invitedHome.buildingModel;
    name = invitedHome.name;
    communityProjectName = project?.name;
    address = invitedHome.address || project?.address;
    imageUrl = invitedHome.defaultImageUrl || DEFAULT_IMAGE;
  } else {
    name = invitedHome.model?.name;
    communityProjectName = invitedHome.community?.name;
    address = invitedHome.address;
    imageUrl = invitedHome.imageUrl || DEFAULT_IMAGE;
    isQuickPossession = true;
  }

  return {
    name,
    communityProjectName,
    address,
    imageUrl,
    isQuickPossession
  };
}

export function buildInvitedHomeReserveUrl(invitedHome) {
  const { currentCompany } = store.getState().companies;
  const domain = currentCompany.domains[0];
  if (v.isBlank(domain)) return null;

  const { hashId } = invitedHome;

  const isUnit = compareUnionTypeObjectTypename(invitedHome, 'Unit');
  const path = isUnit ? `/unit/${hashId}/reserve` : `/quickPossession/${hashId}/reserve`;
  const url = new URL(path, domain);

  return url.toString();
}
