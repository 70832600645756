import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';

import { isMasterAdmin, isTecSupport } from '../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.teams.show.overview.index' };

const Overview = ({ team }) => {
  if (!team) return null;

  return (
    <div>
      {!v.isBlank(team.description) && (
        <div className="mb-4">
          <div className="label">{i18n.t('description', i18nOpts)}</div>
          <div className="white-space-pre-line">{team.description}</div>
        </div>
      )}

      {(isMasterAdmin() || isTecSupport()) && (
        <div className="mb-4">
          <div className="label">{i18n.t('company', i18nOpts)}</div>
          <div><strong>{team.company.name}</strong></div>
        </div>
      )}
    </div>
  );
};

export default connect((store) => ({
  team: store.teams.team
}))(Overview);
