import React from 'react';

const CoBuyerIcon = ({ ...props }) => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1308_4882)">
      <path d="M10 10.5V7.5M8.5 9H11.5M6.5 7.5H4.5C3.56812 7.5 3.10218 7.5 2.73463 7.65224C2.24458 7.85523 1.85523 8.24458 1.65224 8.73463C1.5 9.10218 1.5 9.56812 1.5 10.5M8.25 1.64538C8.98296 1.94207 9.5 2.66066 9.5 3.5C9.5 4.33934 8.98296 5.05793 8.25 5.35462M7.25 3.5C7.25 4.60457 6.35457 5.5 5.25 5.5C4.14543 5.5 3.25 4.60457 3.25 3.5C3.25 2.39543 4.14543 1.5 5.25 1.5C6.35457 1.5 7.25 2.39543 7.25 3.5Z" stroke="#00B37F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1308_4882">
        <rect width="12" height="12" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

CoBuyerIcon.propTypes = {};

CoBuyerIcon.defaultProps = {};

export default CoBuyerIcon;
