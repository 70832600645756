import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownItem } from 'reactstrap';
import i18n from 'i18n-js';

import BaseActionsMenu from '../../../../../global/ActionsMenu';
import { deleteTeam } from '../../../../../../store/actions/teamActions';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';
import { getPublicTeamHashId } from '../../../../../../utils/salesRepUtils';
import { canManageTeam } from '../../../../../../utils/teamUtils';

const i18nOpts = { scope: 'components.admin.teams.list.listItem.actionsMenu.index' };

const ActionsMenu = ({ team, ...props }) => {
  const onGetVerifiedLink = () => {
    const hashId = getPublicTeamHashId(team);
    ModalService.open(ModalNames.VERIFICATION_DIRECT_LINK, { hashId });
  };

  const onEdit = () => {
    ModalService.open(ModalNames.TEAM_FORM, { team });
  };

  const onDelete = () => {
    props.deleteTeam(team.id)
      .catch(() => {});
  };

  const extraProps = {};
  if (canManageTeam(team.userPermission)) {
    extraProps.onEdit = onEdit;
    extraProps.onDelete = onDelete;
  }

  return (
    <BaseActionsMenu item={team} {...extraProps}>
      <DropdownItem onClick={onGetVerifiedLink}>
        {i18n.t('getVerifiedLink', i18nOpts)}
      </DropdownItem>
    </BaseActionsMenu>
  );
};

ActionsMenu.propTypes = {
  team: PropTypes.objectOf(PropTypes.any).isRequired,
};

ActionsMenu.defaultProps = {};

export default connect(() => ({
}), {
  deleteTeam
})(ActionsMenu);
