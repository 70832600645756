import { GET_TOUR, GET_TOURS } from './types';
import { listToursQuery, getTourQuery } from '../../graphql';
import api from '../../api';

export function getTours(variables = {}) {
  return {
    type: GET_TOURS,
    payload: api.graphql(listToursQuery, variables)
  };
}

export function getTour(variables = {}) {
  return {
    type: GET_TOUR,
    payload: api.graphql(getTourQuery, variables)
  };
}
