import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ImagesManager from '../../../../global/ImagesManager';
import {
  deleteUnitImageAsync,
  fetchUnitImagesAsync,
  saveUnitImageAsync,
  sortUnitImagesAsync
} from './utils';

const Images = ({ unit }) => {
  const [unitImages, setUnitImages] = useState([]);
  const loadUnitImages = () => {
    const variables = { filter: { unitId: unit.id } };
    fetchUnitImagesAsync(variables)
      .then((items) => setUnitImages(items))
      .catch(() => setUnitImages([]));
  };

  const uploadImage = (image) => {
    const input = {
      unitId: unit.id
    };

    const variables = { input, image };
    return saveUnitImageAsync(variables);
  };

  const onDrop = (images) => {
    const promises = images.map((file) => uploadImage(file));
    return Promise.all(promises)
      .finally(() => loadUnitImages());
  };

  const onSetUnitImages = (items) => {
    setUnitImages(items);
  };

  const onSortUnitImages = () => {
    const ids = unitImages.map((u) => u.id);
    const variables = { unitId: unit.id, ids };

    sortUnitImagesAsync(variables);
  };

  const onDeleteUnitImage = (unitImage) => {
    deleteUnitImageAsync(unitImage.id)
      .then(() => {
        const filteredItems = unitImages.filter((ui) => ui.id !== unitImage.id);
        setUnitImages(filteredItems);
      })
      .catch(() => {});
  };

  useEffect(() => {
    loadUnitImages();
  }, []);

  return (
    <ImagesManager
      images={unitImages}
      onSetImages={onSetUnitImages}
      onSortImages={onSortUnitImages}
      onDeleteImage={onDeleteUnitImage}
      onDrop={onDrop}
    />
  );
};

export default connect((store) => ({
  unit: store.units.unit,
}), {})(Images);
