const FREE = 0;
const DRAG = 1;
const SCALE = 2;
const ROTATE = 3;
const DRAW = 4;
const TYPE = 5;
const EDIT_OBJECT = 6;
const DRAW_PATH = 7;

export const modes = {
  FREE,
  DRAG,
  SCALE,
  ROTATE,
  DRAW,
  TYPE,
  EDIT_OBJECT,
  DRAW_PATH
};

export const ORIENTATIONS = {
  topLeft: 1,
  topRight: 2,
  bottomLeft: 3,
  bottomRight: 4
};
