import React from 'react';
import { ListGroupItem } from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IoSettingsSharp } from 'react-icons/io5';
import { BiTrash } from 'react-icons/bi';

import { isAdmin } from '../../../../../../utils/authUtils';
import { deleteOption } from '../../../../../../store/actions/optionActions';
import ModalService from '../../../../../../services/ModalService';
import { DEFAULT_IMAGE, ModalNames } from '../../../../../../constants';
import { formatCurrency, isValidAmount } from '../../../../../../utils/currencyUtils';

const onOpenModal = (option) => {
  ModalService.open(ModalNames.OPTION_FORM, { option });
};

const Option = ({ option, ...props }) => {
  if (!option) return null;

  const onDelete = () => {
    props.deleteOption(option.id);
  };

  return (
    <ListGroupItem key={`option-type-${option.id}`} className="option-type-container my-1">
      <div className="d-flex align-items-center">
        <div className="mr-3">
          <img
            className="option-type-image"
            src={option.optionType.imageUrl || DEFAULT_IMAGE}
            alt={option.optionType.name}
          />
        </div>
        <div className="flex-grow-1">
          <div className="d-flex">
            <div>{ option.optionType.name }</div>
            { isValidAmount(option.price) && (
              <div className="flex-grow-1 text-center">{formatCurrency(option.price)}</div>
            )}
          </div>
        </div>
        {isAdmin() && (
          <div className="mr-3">
            <span
              role="button"
              aria-hidden
              onClick={() => onOpenModal(option)}
              title={i18n.t('buttons.edit')}
              className="mr-3"
            >
              <IoSettingsSharp size="1.1rem" />
            </span>
            <span
              role="button"
              aria-hidden
              className="text-danger"
              onClick={() => onDelete(option)}
              title={i18n.t('buttons.delete')}
            >
              <BiTrash size="1.1rem" />
            </span>
          </div>
        )}
      </div>
    </ListGroupItem>
  );
};

Option.propTypes = {
  option: PropTypes.objectOf(PropTypes.any).isRequired
};

Option.defaultProps = {};

export default connect((store) => ({}), {
  deleteOption
})(Option);
