import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'gatsby';
import i18n from 'i18n-js';

import { saveCompany } from '../../../../../store/actions/companyActions';
import { stripToEmpty } from '../../../../../utils/stringUtils';
import { hasFormChanges } from '../../../../../utils/formUtils';
import { fetchSendGridContactListsAsync } from '../utils';
import { goToIntegrations } from '../../utils';

const i18nOpts = { scope: 'components.admin.integrations.sendGrid.mailingList.index' };

const MailingList = ({ currentCompany, ...props }) => {
  const [initialForm, setInitialForm] = useState({});
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [contactLists, setContactLists] = useState([]);
  const [loadingContactLists, setLoadingContactLists] = useState(false);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSave = () => {
    setSaving(true);

    const contactAutomationRules = {
      addedHomeListId: stripToEmpty(form.addedHomeListId)
    };
    const variables = {
      input: { id: currentCompany.id, sendGridSettings: { contactAutomationRules } }
    };
    props.saveCompany(variables)
      .then(() => {
        toast.success(i18n.t('messages.saved', i18nOpts));
        setTimeout(goToIntegrations, 1500);
        setInitialForm(form);
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setSaving(false));
  };

  const onCancel = () => goToIntegrations();

  const loadContactLists = () => {
    setLoadingContactLists(true);

    const variables = { companyId: currentCompany.id };
    fetchSendGridContactListsAsync(variables)
      .then((fetchedContactLists) => setContactLists(fetchedContactLists))
      .catch(() => setContactLists([]))
      .finally(() => setLoadingContactLists(false));
  };

  useEffect(() => {
    const contactAutomationRules = {
      addedHomeListId: currentCompany.sendGridSettings?.contactAutomationRules?.addedHomeListId || ''
    };
    setForm(contactAutomationRules);
    setInitialForm(contactAutomationRules);
  }, []);

  useEffect(() => {
    loadContactLists();
  }, [currentCompany.sendGridSettings?.apiKey]);

  const hasChanges = hasFormChanges(initialForm, form);

  return (
    <div>
      <Row>
        <Col xs="12" sm="10" md="8">
          <FormGroup>
            <Label for="addedHomeListId">{i18n.t('addedHomeListId', i18nOpts)}</Label>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <Input
                  type="select"
                  name="addedHomeListId"
                  id="addedHomeListId"
                  value={form.addedHomeListId || ''}
                  onChange={onTextChange}
                >
                  <option value="">{i18n.t('select.select')}</option>
                  {
                    contactLists.map((contactList) => (
                      <option key={`contact-list-option-${contactList.id}`} value={contactList.id}>
                        {contactList.name}
                      </option>
                    ))
                  }
                </Input>
              </div>
              {loadingContactLists && (
                <Spinner className="ml-2" size="sm" />
              )}
              <div>
                <Link to="/admin/integrations/sendGrid/contactLists" className="btn btn-primary ml-2">
                  {i18n.t('buttons.manageContactLists', i18nOpts)}
                </Link>
              </div>
            </div>

            <small className="text-muted">{i18n.t('addedHomeListIdHelp', i18nOpts)}</small>
          </FormGroup>
        </Col>
      </Row>

      <Button color="primary" onClick={onSave} disabled={saving || !hasChanges} className="mr-3">
        {saving && (<Spinner size="sm" className="mr-2" />)}
        {i18n.t('buttons.save')}
      </Button>
      <Button outline color="secondary" onClick={onCancel} disabled={saving} className="mr-3">
        {i18n.t('buttons.cancel')}
      </Button>
    </div>
  );
};

MailingList.propTypes = {};

MailingList.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveCompany
})(MailingList);
