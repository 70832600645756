import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import filesize from 'filesize';
import v from 'voca';
import classNames from 'classnames';
import moment from 'moment';

import { isRecently } from '../../../../../../../utils/dateUtils';
import { DateFormats } from '../../../../../../../constants';
import DownloadButton from './DownloadButton';

const ListItem = ({ document }) => {
  const {
    fileName, createdAt, documentName, fileSize
  } = document;

  return (
    <tr>
      <td>
        <span className="font-weight-500 col-gray-900">{fileName}</span>
      </td>
      <td>
        {documentName}
      </td>
      <td>{fileSize ? filesize(fileSize) : null}</td>
      <td>
        {!v.isBlank(createdAt) && (
          <span className={classNames({ 'text-success': isRecently(createdAt) })}>
            {moment(createdAt).format(DateFormats.MMM_DD_YYYY)}
          </span>
        )}
      </td>
      <td>
        <DownloadButton document={document} />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  document: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(ListItem);
