import v from 'voca';

export function sanitizePhoneNumber(phone) {
  if (phone.includes('+')) return phone;
  return `+${phone}`;
}

export function getValidPhoneNumber(phone) {
  if (v.isBlank(phone) || phone?.length < 5) return null;

  return sanitizePhoneNumber(phone);
}
