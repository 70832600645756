import React, { Component } from 'react';
import {
  Button, Col, Form, FormGroup, FormText, Input, Label, Row, Spinner
} from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import v from 'voca';
import { connect } from 'react-redux';

import { stripToEmpty } from '../../../../utils/stringUtils';
import { parseToFloat } from '../../../../utils/parserUtils';
import { getParam } from '../../../../utils/paramsUtils';
import { hasFormValues } from '../../../../utils/formUtils';
import { TaxRateTypes } from '../../../../constants/index';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { getTaxRate, saveTaxRate } from '../../../../store/actions/taxRateActions';
import { getError } from '../../../../utils/requestUtils';
import {
  fetchCommunitiesAsync, fetchCitiesAsync, fetchProjectsAsync
} from './utils';
import { mergeCommunitiesProjects } from '../utils';

const i18nOpts = { scope: 'components.admin.taxRates.form.index' };

function onCancel() {
  navigate('/admin/taxRates');
}

class TaxRateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      form: {
        cities: [],
        communitiesProjects: [],
        type: TaxRateTypes.COMMUNITY_PROJECT_BASED
      },
      cities: [],
      communities: [],
      projects: [],
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectOrRemoveItems = this.onSelectOrRemoveItems.bind(this);
  }

  componentDidMount() {
    const id = parseInt(getParam('id'), 10);
    this.MultiSelect = require('multiselect-react-dropdown');
    this.loadCommunities();
    this.loadProjects();
    this.setState({ id }, this.loadTaxRate);
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;
    form[name] = value;
    this.setState({ form });
  }

  onSubmit(event) {
    event.preventDefault();
    const { props } = this;
    const { currentCompany } = this.props;
    const {
      form,
    } = this.state;
    this.cleanSelections();
    const communitiesSelected = form.communitiesProjects.filter((c) => !c.isProject);
    const projectsSelected = form.communitiesProjects.filter((c) => c.isProject);

    const input = {
      name: stripToEmpty(form.name),
      rate: parseToFloat(form.rate) || 0,
      cityIds: this.mapSelectedItemIds(form.cities),
      communityIds: this.mapSelectedItemIds(communitiesSelected),
      projectIds: this.mapSelectedItemIds(projectsSelected),
      type: form.type
    };

    if (this.isNew()) input.companyId = currentCompany.id;
    else input.id = form.id;

    const variables = { input };
    props.saveTaxRate(variables)
      .then(() => {
        navigate('/admin/taxRates');
      })
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);

        this.setState({ error });
      });
  }

  onSelectOrRemoveItems(field, selectedItems) {
    this.onSelect(field, selectedItems);
  }

  onSelect(name, selectedItems) {
    const { form } = this.state;
    form[name] = selectedItems;
    this.setState({ form });
  }

  mapSelectedItemIds(selectedItems) {
    if (!selectedItems || !selectedItems.length > 0) return [];
    return selectedItems.map((item) => item.id);
  }

  cleanSelections() {
    const { form } = this.state;
    if (form.type === TaxRateTypes.CITY_BASED) form.communitiesProjects = [];
    else form.cities = [];

    this.setState({ form });
  }

  loadCommunities() {
    const { currentCompany } = this.props;
    fetchCommunitiesAsync(currentCompany.id)
      .then((communities) => {
        this.setState({ communities });
        this.loadCities();
      }).catch((e) => toast.error(e.message));
  }

  loadCities() {
    const { currentCompany } = this.props;
    fetchCitiesAsync(currentCompany.id)
      .then((cities) => {
        this.setState({ cities });
      }).catch((e) => toast.error(e.message));
  }

  loadProjects() {
    const { currentCompany } = this.props;
    fetchProjectsAsync(currentCompany.id)
      .then((projects) => {
        this.setState({ projects });
      }).catch((e) => toast.error(e.message));
  }

  loadTaxRate() {
    const { props } = this;
    const { id } = this.state;

    if (this.isNew()) {
      return;
    }

    props.getTaxRate(id)
      .then(() => {
        const { taxRate } = this.props;
        const form = {
          ...taxRate,
          communitiesProjects: mergeCommunitiesProjects(taxRate.communities, taxRate.projects)
        };
        this.setState({ form });
      })
      .catch((e) => toast.error(e.message));
  }

  hasFormValues() {
    const { form } = this.state;
    return hasFormValues(form);
  }

  isNew() {
    const { id } = this.state;
    return !id;
  }

  mergeCommunityProjects() {
    const { communities, projects } = this.state;
    return mergeCommunitiesProjects(communities, projects);
  }

  render() {
    const {
      form, cities, error
    } = this.state;
    const { loading } = this.props;

    const isNew = this.isNew();
    const hasValues = this.hasFormValues();
    const communityProjects = this.mergeCommunityProjects();

    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    return (
      <div>
        <Helmet title={title} />

        <h2 className="mb-4">{title}</h2>

        <Form onSubmit={this.onSubmit}>
          <Row form className="mb-2">
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="name">{i18n.t('name', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={form.name || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'name')}
                />
                <InputError name="name" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="rate">{i18n.t('rate', i18nOpts)}</Label>
                <Input
                  type="number"
                  name="rate"
                  id="rate"
                  min={0}
                  max={1}
                  step={0.01}
                  value={form.rate || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'rate')}
                />
                <InputError name="rate" error={error} />
                <FormText color="muted">{i18n.t('rateMessage', i18nOpts)}</FormText>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup check>
            <Label for="community-based">
              <Input
                type="radio"
                name="type"
                id="community-based"
                checked={(form.type === TaxRateTypes.COMMUNITY_PROJECT_BASED)}
                value={TaxRateTypes.COMMUNITY_PROJECT_BASED}
                onChange={this.onTextChange}
              />
              {i18n.t('communityProjectBased', i18nOpts)}
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check for="city-based">
              <Input
                type="radio"
                name="type"
                id="city-based"
                checked={(form.type === TaxRateTypes.CITY_BASED)}
                value={TaxRateTypes.CITY_BASED}
                onChange={this.onTextChange}
              />
              {i18n.t('cityBased', i18nOpts)}
            </Label>
          </FormGroup>

          <Row form className="mb-5 mt-3">
            <Col lg="4" md="6" sm="8" xs="12">
              { form.type === TaxRateTypes.COMMUNITY_PROJECT_BASED && (
              <>
                <Label>{i18n.t('communitiesProjects', i18nOpts)}</Label>
                <div className="multi-select-container">
                  {this.MultiSelect && (
                  <this.MultiSelect.Multiselect
                    id="tax-rate-communities-multiselect"
                    name="communities"
                    options={communityProjects}
                    selectedValues={form.communitiesProjects || []}
                    onSelect={(items) => this.onSelectOrRemoveItems('communitiesProjects', items)}
                    onRemove={(items) => this.onSelectOrRemoveItems('communitiesProjects', items)}
                    displayValue="name"
                    closeIcon="cancel"
                    emptyRecordMsg={i18n.t('emptyCommunities', i18nOpts)}
                    placeholder=""
                  />
                  )}
                  <InputError name="communityIds" error={error} type="multi-select" />
                </div>
              </>
              )}

              { form.type === TaxRateTypes.CITY_BASED && (
              <>
                <Label>{i18n.t('cities', i18nOpts)}</Label>
                <div className="multi-select-container">
                  {this.MultiSelect && (
                  <this.MultiSelect.Multiselect
                    id="tax-rate-cities-multiselect"
                    name="cities"
                    options={cities}
                    selectedValues={form.cities || []}
                    onSelect={(items) => this.onSelectOrRemoveItems('cities', items)}
                    onRemove={(items) => this.onSelectOrRemoveItems('cities', items)}
                    displayValue="name"
                    closeIcon="cancel"
                    emptyRecordMsg={i18n.t('emptyCities', i18nOpts)}
                    placeholder=""
                  />
                  )}
                  <InputError name="cityIds" error={error} type="multi-select" />
                </div>
              </>
              )}
            </Col>
          </Row>

          <Button color="primary" className="mr-3" disabled={loading || !hasValues}>
            {loading && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onCancel} className="mr-3">
            {i18n.t('buttons.cancel')}
          </Button>
        </Form>
      </div>
    );
  }
}

export default connect((store) => ({
  taxRate: store.taxRates.taxRate,
  error: store.taxRates.saveTaxRate.error,
  loading: store.taxRates.saveTaxRate.loading || false,
  currentCompany: store.companies.currentCompany
}), {
  saveTaxRate,
  getTaxRate
})(TaxRateForm);
