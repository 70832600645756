import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import useIsVerifiedEnabled from '../../../../../../../../hooks/useIsVerifiedEnabled';
import useHomeRental from '../../../../../../../../hooks/useHomeRental';
import {
  getFormattedCustomerFinancialAmount
} from '../../../../../../Customers/Show/Overview/VerificationSection/utils';
import {
  getFormattedCustomerHomePricesRange
} from '../../../../../../../../utils/customerUtils';
import { formatTimestamp } from '../../../../../../../../utils/dateUtils';

const ListItem = ({ observer, isStartedReservationList }) => {
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const { isRental } = useHomeRental();

  const contact = observer?.contact || {};

  const formattedFinancialAmount = useMemo(() => getFormattedCustomerFinancialAmount(contact,
    isRental, true),
  [contact, isRental]);
  const formattedPricesRange = useMemo(() => getFormattedCustomerHomePricesRange(contact),
    [contact]);

  return (
    <tr key={`observer-${observer.id}`}>
      <td>
        <div className="col-gray-600">
          <div className="font-weight-600">{contact.name}</div>
          <div>{contact.email}</div>
        </div>
      </td>
      <td>
        <div className="text-nowrap">{contact.phone}</div>
      </td>
      {isVerifiedEnabled && (
        <td>
          {!v.isBlank(formattedFinancialAmount) && (
            <div className="text-nowrap">{formattedFinancialAmount}</div>
          )}
        </td>
      )}
      <td>
        {!v.isBlank(formattedPricesRange) && (
          <div className="text-nowrap">{formattedPricesRange}</div>
        )}
      </td>
      <td>
        {isStartedReservationList ? (
          formatTimestamp(observer.reservationStartedAt)
        ) : (
          formatTimestamp(observer.createdAt)
        )}
      </td>
      <td>
        {!v.isBlank(contact.lastActivityDate) && (
          <div className="text-nowrap">{formatTimestamp(contact.lastActivityDate)}</div>
        )}
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  observer: PropTypes.objectOf(PropTypes.any).isRequired,
  isStartedReservationList: PropTypes.bool
};

ListItem.defaultProps = {
  isStartedReservationList: false
};

export default ListItem;
