import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';

import useValidSelectedColumns from '../../hooks/useValidSelectedColumns';
import CustomColumnValue from './CustomColumnValue';
import { isAdmin } from '../../../../../../utils/authUtils';
import ActionsMenu from '../../../../../global/ActionsMenu';
import { deleteUnit } from '../../../../../../store/actions/unitActions';

const ListItem = ({ unit, ...props }) => {
  const columns = useValidSelectedColumns();

  const onClick = (event) => {
    const unitPath = `/admin/units/${unit.id}`;

    if (event.metaKey || event.ctrlKey) window.open(unitPath, '_blank');
    else navigate(unitPath);
  };

  const onEdit = () => {
    navigate(`/admin/units/form?id=${unit.id}`);
  };

  const onDelete = () => {
    props.deleteUnit(unit.id);
  };

  const onActionsTdClick = (event) => {
    event.stopPropagation();
  };

  return (
    <tr role="button" aria-hidden onClick={onClick} className="clickable-row">
      {
        columns.map((column) => (
          <td key={`unit-cell-${unit.id}-${column}`}>
            <CustomColumnValue column={column} unit={unit} />
          </td>
        ))
      }

      {isAdmin() && (
        <td className="text-right p-0">
          <div role="button" aria-hidden onClick={onActionsTdClick} className="p-3">
            <ActionsMenu
              item={unit}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </div>
        </td>
      )}
    </tr>
  );
};

ListItem.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect(() => ({}), {
  deleteUnit
})(ListItem);
