import {
  CLEAN_HOMES,
  DELETE_HOME, GET_HOME,
  LIST_HOMES, SET_HOME_ATTRIBUTES, SET_RELOAD_HOMES
} from './types';
import {
  deleteHomeQuery, getHomeQuery,
  listHomesQuery
} from '../../graphql';
import api from '../../api';

export function listHomes(variables = {}) {
  return {
    type: LIST_HOMES,
    payload: api.graphql(listHomesQuery, variables)
  };
}

export function getHome(id, companyId, variables = {}) {
  return {
    type: GET_HOME,
    payload: api.graphql(getHomeQuery, { id, companyId, ...variables })
  };
}

export function setReloadHomes(reload) {
  return {
    type: SET_RELOAD_HOMES,
    payload: { reload }
  };
}

export function deleteHome(id) {
  return {
    type: DELETE_HOME,
    payload: api.graphql(deleteHomeQuery, { id })
  };
}

export function setHomeAttributes(homeId, attributes = {}) {
  return {
    type: SET_HOME_ATTRIBUTES,
    payload: { homeId, attributes },
  };
}

export function cleanHomes(extraProps = {}) {
  return {
    type: CLEAN_HOMES,
    payload: Promise.resolve(extraProps)
  };
}
