import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import styles from './UploadButton.module.scss';
import AddImageIcon from '../../../../assets/icons/AddImageIcon';

const UploadButton = ({ id, onChange }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label htmlFor={id} className={styles.container}>
    <Input
      type="file"
      name="image"
      id={id}
      accept="image/*"
      onChange={onChange}
      className="d-none"
    />
    <AddImageIcon />
  </label>
);

UploadButton.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func
};

UploadButton.defaultProps = {
  id: 'image',
  onChange: () => {}
};

export default UploadButton;
