const fragment = `
  id
  imageUrl
`;

export const listQuickPossessionImagesQuery = `
  query ListQuickPossessionImages(
    $filter: QuickPossessionImageFilterInput
  ) {
    items: listQuickPossessionImages(filter: $filter) {
      ${fragment}
    }
  }
`;

export const createQuickPossessionImageQuery = `
  mutation CreateQuickPossessionImage(
    $input: CreateQuickPossessionImageInput!
    $image: Upload
  ) {
    item: createQuickPossessionImage(input: $input, image: $image) {
      ${fragment}
    }
  }
`;

export const updateQuickPossessionImageQuery = `
  mutation UpdateQuickPossessionImage(
    $input: UpdateQuickPossessionImageInput!
    $image: Upload
  ) {
    item: updateQuickPossessionImage(input: $input, image: $image) {
      ${fragment}
    }
  }
`;

export const deleteQuickPossessionImageQuery = `
  mutation DeleteQuickPossessionImage(
    $id: Int!
  ) {
    item: deleteQuickPossessionImage(id: $id) {
      ${fragment}
    }
  }
`;

export const sortQuickPossessionImagesQuery = `
  mutation SortQuickPossessionImages(
    $quickPossessionId: Int!,
    $ids: [Int!]!
  ) {
    items: sortQuickPossessionImages(quickPossessionId: $quickPossessionId, ids: $ids) {
      id
    }
  }
`;
