import { useState } from 'react';
import { useSelector } from 'react-redux';

import api from '../../../../../api';
import { sendReserveNowInvitationMutation } from '../../../../../graphql';

async function submitReserveNowInvitationAsync(
  companyId, invitedCustomers, invitedHome, isEmbeddedInvitation
) {
  const contactIds = invitedCustomers.map((ic) => ic.id);
  const inviteMethods = invitedCustomers.map((ic) => ic.inviteMethod);
  const { __typename: homeType } = invitedHome;

  const variables = {
    companyId,
    contactIds,
    inviteMethods,
    homeId: invitedHome.id,
    homeType,
    isEmbeddedInvitation
  };
  return api.graphql(sendReserveNowInvitationMutation, variables)
    .then(({ data: { sent } }) => sent);
}

const useReserveNowInvitation = () => {
  const [inviting, setInviting] = useState(false);

  const { invitedCustomers, invitedHome, currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany,
    invitedCustomers: store.salesInvitation.invitedCustomers,
    invitedHome: store.salesInvitation.invitedHome
  }));

  const sendReserveNowInvitationAsync = (isEmbeddedInvitation) => {
    setInviting(true);

    return submitReserveNowInvitationAsync(
      currentCompany.id, invitedCustomers, invitedHome, isEmbeddedInvitation
    ).finally(() => setInviting(false));
  };

  return { inviting, sendReserveNowInvitationAsync };
};

export default useReserveNowInvitation;
