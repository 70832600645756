import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

import { isAdmin } from '../../../../../utils/authUtils';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';

const i18nOpts = { scope: 'components.admin.optionTypes.show.overview.index' };

const Overview = ({ optionType }) => {
  const creatorName = getCreatorDisplayName(optionType);
  const lastModifierName = getLastModifierDisplayName(optionType);

  return (
    <div>
      <Row>
        <Col xs={12} sm={6} md={7} lg={8}>
          <div className="mb-4">
            <div className="label">{i18n.t('name', i18nOpts)}</div>
            <div><strong>{optionType.name}</strong></div>
          </div>

          {!v.isBlank(optionType.description) && (
            <div className="mb-4">
              <div className="label">{i18n.t('description', i18nOpts)}</div>
              <div><strong>{optionType.description}</strong></div>
            </div>
          )}

          {!v.isBlank(creatorName) && isAdmin() && (
            <div className="mb-4">
              <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
              <div><strong>{creatorName}</strong></div>
            </div>
          )}

          {!v.isBlank(lastModifierName) && isAdmin() && (
            <div className="mb-4">
              <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
              <div><strong>{lastModifierName}</strong></div>
            </div>
          )}
        </Col>
        <Col xs={12} sm={6} md={5} lg={4}>
          {!v.isBlank(optionType.imageUrl) && (
            <img src={optionType.imageUrl} alt={optionType.name} className="w-100" />
          )}
        </Col>
      </Row>

    </div>
  );
};

Overview.propTypes = {};

Overview.defaultProps = {};

export default connect((store) => ({
  optionType: store.optionTypes.optionType
}))(Overview);
