import {
  createCityImageQuery, deleteCityImageQuery,
  listCityImagesQuery, sortCityImagesQuery,
  updateCityImageQuery
} from '../../../../../graphql';
import api from '../../../../../api';

export function fetchCityImagesAsync(variables = {}) {
  return api.graphql(listCityImagesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items))
    .catch(() => Promise.reject());
}

export function saveCityImageAsync(variables = {}) {
  const query = variables?.input?.id ? updateCityImageQuery : createCityImageQuery;
  return api.graphql(query, variables);
}

export function deleteCityImageAsync(id) {
  return api.graphql(deleteCityImageQuery, { id });
}

export function sortCityImagesAsync(variables = {}) {
  return api.graphql(sortCityImagesQuery, variables);
}
