import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

const GridView = ({ buildingModels }) => (
  <Row>
    {
      buildingModels.map((bm) => (
        <Col key={`building-model-${bm.id}`} xs="12" sm="6" md="4" lg="4">
          <ListItem buildingModel={bm} />
        </Col>
      ))
    }
  </Row>
);

GridView.propTypes = {
  buildingModels: PropTypes.arrayOf(PropTypes.any).isRequired
};

GridView.defaultProps = {};

export default GridView;
