import React from 'react';

import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import List from './List';
import Uploader from './Uploader';

const i18nOpts = { scope: 'components.admin.documents.index' };

const Documents = () => (
  <div>
    <Helmet title={i18n.t('title', i18nOpts)} />

    <div className="d-flex mb-5">
      <div className="flex-grow-1">
        <h2>{i18n.t('title', i18nOpts)}</h2>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <label htmlFor="fileInput" className="btn btn-primary">
          <i className="fas fa-plus mr-2" />
          {i18n.t('buttons.add', i18nOpts)}
        </label>
      </div>
    </div>

    <Uploader />
    <List />
  </div>
);

export default Documents;
