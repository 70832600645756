import React from 'react';
import PropTypes from 'prop-types';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import classNames from 'classnames';

import styles from './TimeLineElement.module.scss';
import { Colors } from '../../../constants';
import TimeAgo from '../TimeAgo';

const TimeLineElement = ({
  innerKey, showDate, date, Icon, children
}) => {
  const contentStyle = {
    backgroundColor: 'white',
    boxShadow: 'none',
    padding: '0 0.5rem 0 1rem'
  };

  const contentArrowStyle = {
    borderRight: '7px solid transparent'
  };

  const iconStyle = {
    backgroundColor: Colors.PRIMARY_50,
    color: Colors.PRIMARY_600
  };

  const extraProps = showDate ? {
    date: <TimeAgo date={date} />,
    dateClassName: styles.date
  } : { dateClassName: 'd-none' };

  return (
    <VerticalTimelineElement
      key={innerKey}
      className={classNames({ [styles.withDate]: showDate })}
      contentStyle={contentStyle}
      contentArrowStyle={contentArrowStyle}
      iconStyle={iconStyle}
      icon={Icon}
      {...extraProps}
    >
      {children}
    </VerticalTimelineElement>
  );
};

TimeLineElement.propTypes = {
  innerKey: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  showDate: PropTypes.bool
};

TimeLineElement.defaultProps = {
  Icon: null,
  showDate: false,
};

export default TimeLineElement;
