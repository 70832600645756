import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import { isMasterAdmin } from '../../../../../../utils/authUtils';
import { getValidCustomerCompanies } from '../../../utils';

const i18nOpts = { scope: 'components.admin.customers.show.overview.buildersSection.index' };

const BuildersSection = ({
  customer,
  currentCompany
}) => {
  const filteredCompanyContacts = useMemo(() => getValidCustomerCompanies(
    customer, currentCompany.id
  ), [customer, currentCompany]);

  if (!(isMasterAdmin() && filteredCompanyContacts.length > 0)) return null;

  return (
    <>
      <hr />

      <div>
        <div className="font-weight-500">{i18n.t('title', { ...i18nOpts, name: customer.firstName })}</div>
        <div>
          {filteredCompanyContacts.map((companyContact) => (
            <div key={`company-${companyContact.company.id}`} className="col-gray-700 font-16 font-weight-500">
              {companyContact.company.name}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

BuildersSection.propTypes = {};

BuildersSection.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}))(BuildersSection);
