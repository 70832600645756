import React from 'react';
import v from 'voca';
import i18n from 'i18n-js';
import {
  FaChrome, FaFirefox, FaSafari, FaWindows, FaApple, FaTabletAlt, FaMobileAlt
} from 'react-icons/fa';
import { MdAndroid, MdDesktopWindows } from 'react-icons/md';

const i18nOpts = { scope: 'utils.userAgentUtils' };

function getDeviceIcon(deviceType) {
  if (!deviceType) return (props) => <MdDesktopWindows {...props} />;
  const sanitizedDeviceType = deviceType.toLowerCase();
  switch (sanitizedDeviceType) {
    case 'tablet':
      return (props) => <FaTabletAlt {...props} />;
    case 'mobile':
      return (props) => <FaMobileAlt {...props} />;
    default:
      return (props) => <MdDesktopWindows {...props} />;
  }
}

function getBrowserIcon(browserName) {
  if (!browserName) return null;
  const sanitizedBrowserName = browserName.toLowerCase();
  switch (sanitizedBrowserName) {
    case 'safari':
      return (props) => <FaSafari {...props} />;
    case 'firefox':
      return (props) => <FaFirefox {...props} />;
    default:
      return (props) => <FaChrome {...props} />;
  }
}

function getOSIcon(osName) {
  if (!osName) return null;
  const sanitizedOSName = osName.toLowerCase();
  switch (sanitizedOSName) {
    case 'android':
      return (props) => <MdAndroid {...props} />;
    case 'windows':
      return (props) => <FaWindows {...props} />;
    default:
      return (props) => <FaApple {...props} />;
  }
}

export function getBrowserInfo(userAgentParsed) {
  if (!userAgentParsed || !userAgentParsed?.browser) return null;
  const { browser: { version, name } } = userAgentParsed;

  return [[name, version].join(' '), getBrowserIcon(name)];
}

export function getDeviceInfo(userAgentParsed) {
  if (!userAgentParsed || !userAgentParsed?.device) return null;

  const { device: { vendor, type, model } } = userAgentParsed;

  const parsed = [];

  if (!v.isBlank(vendor)) parsed.push(vendor);
  if (!v.isBlank(type)) parsed.push(type);
  if (!v.isBlank(model)) parsed.push(model);

  let parsedName = parsed.join(' ');

  if (v.isBlank(parsedName)) parsedName = i18n.t('pc', i18nOpts);

  return [parsedName, getDeviceIcon(type)];
}

export function getOSInfo(userAgentParsed) {
  if (!userAgentParsed || !userAgentParsed?.os) return null;

  const { os: { name, version } } = userAgentParsed;

  const parsed = [];

  if (!v.isBlank(name)) parsed.push(name);
  if (!v.isBlank(version)) parsed.push(version);

  return [parsed.join(' '), getOSIcon(name)];
}
