import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Badge } from 'reactstrap';
import _ from 'lodash';

import TimeLineElement from '../../../../common/TimeLineElement';
import OnfidoCheckIcon from '../../../../../assets/icons/customer-activities/OnfidoCheckIcon';
import ActivityDate from '../components/ActivityDate';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.onfidoCheck.index' };

const OnfidoCheck = ({ activity, innerKey }) => {
  const { createdAt, onfidoCheck } = activity;

  return (
    <TimeLineElement
      date={createdAt}
      innerKey={innerKey}
      Icon={<OnfidoCheckIcon />}
    >
      <div className="d-flex align-items-start gap-3">
        <div className="activity-timeline-title">{i18n.t('title', i18nOpts)}</div>
        <ActivityDate date={createdAt} />
      </div>
      <div>
        <Badge color="onfido-check">
          {_.startCase(onfidoCheck?.checkReport.status)}
        </Badge>
      </div>
    </TimeLineElement>
  );
};

OnfidoCheck.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

OnfidoCheck.defaultProps = {};

export default OnfidoCheck;
