import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import i18n from 'i18n-js';

import styles from './ListItem.module.scss';
import { setInvitedHome } from '../../../../../../../store/actions/salesInvitationActions';
import { compareUnionTypeObjectTypename } from '../../../../../../../utils/objectUtils';
import { formatCurrency, isValidAmount } from '../../../../../../../utils/currencyUtils';
import { DEFAULT_IMAGE } from '../../../../../../../constants';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.existingHome.index.gridView.listItem.index' };

const ListItem = ({
  home, selectedHome, onSelect: onAfterSelect, ...props
}) => {
  const isSelected = selectedHome?.id === home.id;

  const isUnit = compareUnionTypeObjectTypename(home, 'Unit');
  let name;
  let communityProjectName;
  let price;
  if (isUnit) {
    const { project } = home.buildingModel;
    name = home.name;
    communityProjectName = project.name;
    price = home.price;
  } else {
    name = home.model.name;
    communityProjectName = home.community.name;
    price = home.totalPrice;
  }

  const onSelect = () => {
    props.setInvitedHome(home);
    setTimeout(onAfterSelect, 500);
  };

  return (
    <div
      className={classNames(styles.card, { [styles.active]: isSelected })}
      onClick={onSelect}
      role="button"
      aria-hidden
    >
      <div className="font-weight-600 font-18">{name}</div>
      <div className={styles.imageContainer}>
        <img src={home.imageUrl || DEFAULT_IMAGE} alt="" />
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <div className="font-weight-600 font-16">{communityProjectName}</div>
          <div><small>{isUnit ? i18n.t('project', i18nOpts) : i18n.t('community', i18nOpts)}</small></div>
        </div>
        <div>
          {isValidAmount(price) && (
            <div className="font-weight-600 font-16">{formatCurrency(price)}</div>
          )}
        </div>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired,
  onAfterSelect: PropTypes.func
};

ListItem.defaultProps = {
  onAfterSelect: () => {}
};

export default connect((store) => ({
  selectedHome: store.salesInvitation.invitedHome
}), {
  setInvitedHome
})(ListItem);
