import React, { useEffect, useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import i18n from 'i18n-js';
import { BiBuildingHouse } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import api from '../../../../../api';

const i18nOpts = { scope: 'components.admin.models.list.productTypeFilter.index' };

function fetchProductTypesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListProductTypes($filter: ProductTypeFilterInput) {
      items: listProductTypes(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

const ProductTypeFilter = ({ onChange, currentCompany }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState(null);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onToggleProductType = (productType) => {
    setSelectedProductType(productType);
    onChange(productType);
  };

  useEffect(() => {
    fetchProductTypesAsync(currentCompany.id)
      .then((items) => { setProductTypes(items); })
      .catch(() => {});
  }, []);

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
        <DropdownToggle color="outline-secondary" size="sm" caret disabled={!productTypes.length} className="text-nowrap">
          <BiBuildingHouse size="1rem" className="mr-2 text-muted d-inline-block d-sm-none" />
          <span className="mr-2 text-muted d-none d-sm-inline-block">{i18n.t('productType', i18nOpts)}</span>
          {selectedProductType?.name ?? i18n.t('all', i18nOpts)}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onToggleProductType(null)}>
            {i18n.t('all', i18nOpts)}
          </DropdownItem>
          {
            productTypes.map((c) => (
              <DropdownItem
                key={`product-type-${c.id}`}
                onClick={() => onToggleProductType(c)}
              >
                {c.name}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

ProductTypeFilter.propTypes = {
  onChange: PropTypes.func
};

ProductTypeFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {})(ProductTypeFilter);
