import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';

import styles from './Insights.module.scss';
import VerifiedAgeDemographicCard from './VerifiedAgeDemographicCard';
import VerifiedFunnelCard from './VerifiedFunnelCard';
import VerifiedBuyingPowerCard from './VerifiedBuyingPowerCard';
import {
  getLastSevenDaysRange,
  getLastSevenDaysRangeOutput,
  setDaysRangeInput
} from '../../../utils/dateUtils';
import CompanyFilter from '../../global/filters/CompanyFilter';
import { isMasterAdmin, isTecSupport } from '../../../utils/authUtils';
import VerifiedLeadsLocationCard from './VerifiedLeadsLocationCard';
import DateFilter from '../../common/DateFilter';

const i18nOpts = { scope: 'components.admin.insights.index' };

const Insights = ({ currentCompany }) => {
  const isSuperAdmin = isMasterAdmin() || isTecSupport();
  const [reportCompany, setReportCompany] = useState(currentCompany);
  const [dateRange, setDateRange] = useState(getLastSevenDaysRange());
  const [customDateRange, setCustomDateRange] = useState(getLastSevenDaysRangeOutput());

  const onPickedDate = (event, picker) => {
    const pickedDate = [
      picker.startDate.startOf('day').toDate(),
      picker.endDate.endOf('day').toDate()
    ];

    setDateRange(pickedDate);
    setCustomDateRange(setDaysRangeInput(pickedDate));
  };

  const onCompanyChange = (company) => {
    const selectedCompany = company || null;
    setReportCompany(selectedCompany);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex mb-5 align-items-center justify-content-end flex-wrap gap-3">
        <div>
          <h2 className="mb-0">{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div className="flex-grow-1">
          {isSuperAdmin && (
            <CompanyFilter onChange={onCompanyChange} showLabel={false} extraQueryFields="enableSampleData" defaultCompany={currentCompany} />
          )}
        </div>
        <div>
          <DateFilter dateRange={dateRange} onDatePicked={onPickedDate} containerClassName="my-0" />
        </div>
      </div>

      <Row className={classNames('align-items-stretch')}>
        <Col xs="12" sm="4" className={styles.col}>
          <VerifiedAgeDemographicCard company={reportCompany} dateRange={customDateRange} />
        </Col>
        <Col xs="12" sm="8" className={styles.col}>
          <VerifiedFunnelCard company={reportCompany} dateRange={customDateRange} />
        </Col>
        {isSuperAdmin && (
          <Col xs="12" sm="7" className={styles.col}>
            <VerifiedBuyingPowerCard company={reportCompany} dateRange={customDateRange} />
          </Col>
        )}
        <Col xs="12" sm="5" className={styles.col}>
          <VerifiedLeadsLocationCard company={reportCompany} dateRange={customDateRange} />
        </Col>
      </Row>
    </div>
  );
};

Insights.propTypes = {};

Insights.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(Insights);
