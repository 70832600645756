import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';
import { connect } from 'react-redux';

import DeleteButton from './DeleteButton';
import InviteByButton from './InviteByButton';
import { setInvitedCustomers } from '../../../../../../store/actions/salesInvitationActions';

const InvitationListItem = ({
  customer, invitedCustomers, showDeleteButton, ...props
}) => {
  const onInviteMethodChange = (selectedValue) => {
    const updatedCustomers = invitedCustomers.map((ic) => {
      if (ic.id === customer.id) return { ...ic, inviteMethod: selectedValue };
      return ic;
    });
    props.setInvitedCustomers(updatedCustomers);
  };

  const invitedCustomer = invitedCustomers.find((ic) => ic.id === customer.id);
  const inviteMethod = invitedCustomer?.inviteMethod || null;
  const hasEmail = !v.isBlank(customer.email);
  const hasPhone = !v.isBlank(customer.phone);

  return (
    <tr>
      <td>
        <div className="font-weight-600">{customer.name}</div>

        {hasPhone && (
          <div>{customer.phone}</div>
        )}

        {hasEmail && (
          <div className="word-break-break-all">{customer.email}</div>
        )}
      </td>
      <td className="text-right">
        <InviteByButton
          value={inviteMethod}
          onChange={onInviteMethodChange}
          showEmail={hasEmail}
          showSms={hasPhone}
        />
      </td>

      {showDeleteButton && (
        <td className="text-right">
          <DeleteButton customer={customer} />
        </td>
      )}
    </tr>
  );
};

InvitationListItem.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  showDeleteButton: PropTypes.bool
};

InvitationListItem.defaultProps = {
  showDeleteButton: false
};

export default connect((store) => ({
  invitedCustomers: store.salesInvitation.invitedCustomers,
}), {
  setInvitedCustomers
})(InvitationListItem);
