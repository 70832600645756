import React from 'react';
import { Button } from 'reactstrap';
import { IoFilter } from 'react-icons/io5';

import styles from './FiltersButton.module.scss';
import ModalService from '../../../../../services/ModalService';
import { Colors, ModalNames } from '../../../../../constants';

const FiltersButton = () => {
  const onClick = () => {
    ModalService.open(ModalNames.CUSTOMER_FILTERS);
  };

  return (
    <Button color="secondary" outline onClick={onClick} className={styles.container}>
      <IoFilter size={20} color={Colors.GRAY_700} />
    </Button>
  );
};

FiltersButton.propTypes = {};

FiltersButton.defaultProps = {};

export default FiltersButton;
