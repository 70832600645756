import React from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import DealList from '../components/DealList';

const i18nOpts = { scope: 'components.admin.sales.contracts.list.index' };

const List = () => (
  <div>
    <Helmet title={i18n.t('title', i18nOpts)} />

    <DealList title={i18n.t('title', i18nOpts)} contractsOnly />
  </div>
);

List.propTypes = {};

export default List;
