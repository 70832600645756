import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import {
  JOURNEY_REALTOR_QUESTION_STEP,
  DEFAULT_BUYER_RESERVE_WORKFLOW,
  DEFAULT_RENTAL_RESERVE_WORKFLOW, JOURNEY_MULTIPLE_RESERVATIONS_STEP
} from '../utils/companyUtils';

const useReserveNowWorkflowSteps = () => {
  const { reserveNowWorkflow, currentCompany } = useSelector((store) => ({
    reserveNowWorkflow: store.companyWorkflows.reserveNowWorkflow,
    currentCompany: store.companies.currentCompany,
  }));

  if (!currentCompany) return [];

  const isRental = !!currentCompany?.enableHomeRental;

  const defaultWorkflow = useMemo(() => (isRental ? DEFAULT_RENTAL_RESERVE_WORKFLOW
    : DEFAULT_BUYER_RESERVE_WORKFLOW), [isRental]);

  let workflow = reserveNowWorkflow?.workflow || [...defaultWorkflow];
  workflow = workflow.filter((workflowItem) => workflowItem.type
    !== JOURNEY_MULTIPLE_RESERVATIONS_STEP);
  if (!isRental) {
    workflow = workflow.filter((workflowItem) => workflowItem.type
      !== JOURNEY_REALTOR_QUESTION_STEP);
  }
  return workflow;
};

export default useReserveNowWorkflowSteps;
