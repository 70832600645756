import React from 'react';
import PropTypes from 'prop-types';

const ListHeader = ({ title }) => (
  <tr>
    <th colSpan={3}>
      <b className="text-muted">{title}</b>
    </th>
  </tr>
);

ListHeader.propTypes = {
  title: PropTypes.string.isRequired
};

ListHeader.defaultProps = {};

export default ListHeader;
