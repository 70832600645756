import React from 'react';

const ExistingHomeIcon = ({ ...props }) => (
  <svg width="121" height="112" viewBox="0 0 121 112" fill="none" color="#667085" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_73_668)">
      <path d="M70.7143 63.7889C71.98 62.5273 71.98 60.4817 70.7143 59.22C69.4485 57.9583 67.3964 57.9583 66.1308 59.22L57.859 67.4654L54.869 64.4849C53.6034 63.2232 51.5512 63.2232 50.2855 64.4849C49.0197 65.7467 49.0197 67.7923 50.2855 69.0539L55.5673 74.3189C56.833 75.5806 58.8851 75.5806 60.1508 74.3189L70.7143 63.7889Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M63.994 1.01923C61.864 -0.339742 59.1357 -0.339742 57.0056 1.01923L2.98776 35.4808C-0.0275709 37.4044 -0.907566 41.4005 1.02224 44.4062C2.952 47.412 6.96088 48.2892 9.97616 46.3654L15.1248 43.0808V94.7692C15.1248 104.286 22.8638 112 32.4105 112H88.5891C98.1358 112 105.875 104.286 105.875 94.7692V43.0808L111.024 46.3654C114.039 48.2892 118.047 47.412 119.978 44.4062C121.907 41.4005 121.027 37.4044 118.012 35.4808L63.994 1.01923ZM84.2677 66.7693C84.2677 79.8542 73.6265 90.4616 60.4998 90.4616C47.3732 90.4616 36.732 79.8542 36.732 66.7693C36.732 53.6843 47.3732 43.077 60.4998 43.077C73.6265 43.077 84.2677 53.6843 84.2677 66.7693Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_73_668">
        <rect width="121" height="112" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

ExistingHomeIcon.propTypes = {};

ExistingHomeIcon.defaultProps = {};

export default ExistingHomeIcon;
