import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  getModelImages, setModelImages, sortModelImages, deleteModelImage
} from '../../../../../store/actions/modelImageActions';
import { createModelImageQuery } from '../../../../../graphql';
import api from '../../../../../api';
import ImagesManager from '../../../../global/ImagesManager';
import './styles.scss';

const Images = ({ model, modelImages, ...props }) => {
  const uploadImage = (image) => {
    const input = {
      modelId: model.id
    };

    const variables = { input, image };
    return api.graphql(createModelImageQuery, variables);
  };

  const loadModelImages = () => {
    const variables = { filter: { modelId: model.id } };
    props.getModelImages(variables);
  };

  const onDrop = (images) => {
    const promises = images.map((file) => uploadImage(file));
    return Promise.all(promises)
      .finally(() => loadModelImages());
  };

  const onSetModelImages = (items) => {
    props.setModelImages(items);
  };

  const onSortModelImages = () => {
    const ids = modelImages.map((m) => m.id);

    const variables = { modelId: model.id, ids };

    props.sortModelImages(variables);
  };

  const onDeleteModelImage = (modelImage) => {
    props.deleteModelImage(modelImage.id);
  };

  useEffect(() => {
    loadModelImages();
  }, []);

  return (
    <ImagesManager
      images={modelImages}
      onSetImages={onSetModelImages}
      onSortImages={onSortModelImages}
      onDeleteImage={onDeleteModelImage}
      onDrop={onDrop}
    />
  );
};

export default connect((store) => ({
  model: store.models.model,
  modelImages: store.modelImages.modelImages
}), {
  getModelImages,
  setModelImages,
  sortModelImages,
  deleteModelImage
})(Images);
