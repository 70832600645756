import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Table, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { GrAddCircle } from 'react-icons/gr';

import { listTeams, cleanTeams, setReloadTeams } from '../../../../store/actions/teamActions';
import ListEmpty from '../../../common/ListEmpty';
import ListPagination from '../../../common/ListPagination';
import ListItem from './ListItem';
import { isAdmin, isMasterAdmin, isTecSupport } from '../../../../utils/authUtils';
import VerificationDirectLinkModal from '../../../global/VerificationDirectLinkModal';
import QRCodeModal from '../../../global/QRCodeModal';
import FormModal from '../components/FormModal';
import ModalService from '../../../../services/ModalService';
import { ModalNames } from '../../../../constants';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.teams.list.index' };

const Teams = ({
  teams, pagination, loading, currentCompany, reloadTeams, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [filters, setFilters] = useState({ companyId: currentCompany.id });

  const onChangePage = (page) => {
    loadTeams(page);
  };

  const onAdd = () => {
    ModalService.open(ModalNames.TEAM_FORM);
  };

  const loadTeams = (page = 1) => {
    const companyId = isMasterAdmin() || isTecSupport() ? filters.companyId : currentCompany.id;

    const variables = {
      filter: {
        companyId,
      },
      page
    };
    props.listTeams(variables)
      .finally(() => {
        setMounted(true);
        props.setReloadTeams(false);
      });
  };

  useEffect(() => {
    props.cleanTeams()
      .then(() => loadTeams());
  }, [filters]);

  useEffect(() => {
    if (!reloadTeams) return;

    loadTeams();
  }, [reloadTeams]);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="mb-4">
        <h2>{i18n.t('title', i18nOpts)}</h2>
      </div>

      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4">
        {isAdmin() && (
          <Button color="secondary" outline onClick={onAdd}>
            <GrAddCircle size={20} className="mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Button>
        )}
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={teams} />

          {teams.length > 0 && (
            <Table bordered responsive className="pb-5">
              <thead>
                <tr>
                  <th style={{ width: '70px' }}>&nbsp;</th>
                  <th>{i18n.t('name', i18nOpts)}</th>
                  {(isMasterAdmin() || isTecSupport()) && (
                    <th>{i18n.t('company', i18nOpts)}</th>
                  )}
                  <th>{i18n.t('usersCount', i18nOpts)}</th>
                  <th>{i18n.t('createdAt', i18nOpts)}</th>
                  <th style={{ width: '55px' }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {
                  teams.map((team) => (
                    <ListItem team={team} key={`team-${team.id}`} />
                  ))
                }
              </tbody>
            </Table>
          )}

          <div className="text-right mt-4">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}

      <VerificationDirectLinkModal />
      <QRCodeModal />
      <FormModal />
    </div>
  );
};

export default connect((store) => ({
  teams: store.teams.teams,
  loading: store.teams.listTeams.loading,
  pagination: store.teams.pagination,
  currentCompany: store.companies.currentCompany,
  reloadTeams: store.teams.reloadTeams,
}), {
  listTeams,
  cleanTeams,
  setReloadTeams,
})(Teams);
