const fragment = `
  id
  amount
  reason
  customReason
  status
  errorMessage
`;

export const createRefundQuery = `
  mutation CreateRefund(
    $input: CreateRefundInput!
  ) {
    item: createRefund(input: $input) {
      ${fragment}
    }
  }
`;
