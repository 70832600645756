import React from 'react';
import { Link } from 'gatsby';
import i18n from 'i18n-js';
import {
  Card, CardBody, CardImg, Col, Row
} from 'reactstrap';

import { DEFAULT_IMAGE } from '../../../../../../../constants';
import { formatCurrency } from '../../../../../../../utils/currencyUtils';
import './styles.scss';
import RequestIcon from './RequestIcon';
import ActionsMenu from '../../ActionsMenu';
import { getTotalPrice } from '../../../../../../../utils/quickPossessionUtils';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.listItem.index' };

const ListItem = ({ quickPossession }) => {
  const totalPrice = quickPossession?.customPrice
    || getTotalPrice(quickPossession, quickPossession?.floorOptions || []);

  return (
    <Card className="shadow rounded border-0">
      <Link to={`/admin/quickPossessions/${quickPossession.id}`}>
        <CardImg
          top
          width="100%"
          src={quickPossession.imageUrl || quickPossession.model.defaultImageUrl || DEFAULT_IMAGE}
          alt=""
          className="mb-0 card-img"
        />
      </Link>
      { quickPossession?.requests && quickPossession.requests.length > 0 && (
        <RequestIcon quickPossession={quickPossession} tooltip="Requests available" />
      )}
      <div className="options actions-menu">
        <ActionsMenu quickPossession={quickPossession} />
      </div>

      <CardBody>
        <Row className="mb-3">
          <Col lg="6">
            <div className="d-flex flex-column text-center">
              <h4 className="col-primary-1 mb-3">{i18n.t('community', i18nOpts)}</h4>
              <h5>{quickPossession.community.name}</h5>
            </div>
          </Col>
          <Col lg="6">
            <div className="d-flex flex-column text-center">
              <h4 className="col-primary-1 mb-3">{i18n.t('model', i18nOpts)}</h4>
              <h5>{quickPossession.model.name}</h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <div className="d-flex flex-column text-center">
              <h4 className="col-primary-1 mb-3">{i18n.t('totalPrice', i18nOpts)}</h4>
              <h5>{formatCurrency(totalPrice)}</h5>
            </div>
          </Col>
          <Col lg="6">
            <div className="d-flex flex-column text-center">
              <h4 className="col-primary-1 mb-3">{i18n.t('status', i18nOpts)}</h4>
              <h5 className="text-capitalize">{quickPossession.status}</h5>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ListItem;
