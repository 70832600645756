import React from 'react';

const OfferPendingIcon = ({ ...props }) => (
  <svg width="25" height="31" viewBox="0 0 25 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_654_3065)">
      <path d="M23.4392 6.03063L18.624 1.37496H1.37842V29.6841H23.4392V6.03063ZM19.4509 0.155783L24.7038 5.23347L24.9192 5.73588V31.1109H-0.101562V-0.0518799H19.2285L19.4509 0.155783Z" fill="#667085" />
      <path d="M22.8144 5.42102L19.2291 1.95774V5.42102H22.8144ZM19.4515 0.155767L24.7044 5.23345L24.9198 5.73586V6.85456H17.7422V-0.0585938H19.2291L19.4515 0.149068V0.155767Z" fill="#667085" />
      <path d="M20.44 9.6875H4.61343C4.24537 9.6875 4 9.95426 4 10.3544C4 10.7545 4.24537 11.0213 4.61343 11.0213H20.3787C20.7467 11.0213 20.9921 10.7545 20.9921 10.3544C21.0534 10.0209 20.7467 9.6875 20.44 9.6875Z" fill="#667085" />
      <path d="M20.44 24.71H4.61343C4.24537 24.71 4 24.9767 4 25.3769C4 25.777 4.24537 26.0437 4.61343 26.0437H20.3787C20.7467 26.0437 20.9921 25.777 20.9921 25.3769C21.0534 25.0434 20.7467 24.71 20.44 24.71Z" fill="#667085" />
      <path d="M20.44 20.9546H4.61343C4.24537 20.9546 4 21.2213 4 21.6215C4 22.0216 4.24537 22.2884 4.61343 22.2884H20.3787C20.7467 22.2884 20.9921 22.0216 20.9921 21.6215C21.0534 21.288 20.7467 20.9546 20.44 20.9546Z" fill="#667085" />
      <path d="M20.44 17.1987H4.61343C4.24537 17.1987 4 17.4655 4 17.8656C4 18.2658 4.24537 18.5325 4.61343 18.5325H20.3787C20.7467 18.5325 20.9921 18.2658 20.9921 17.8656C21.0534 17.5322 20.7467 17.1987 20.44 17.1987Z" fill="#667085" />
      <path d="M20.44 13.4434H4.61343C4.24537 13.4434 4 13.7101 4 14.1103C4 14.5104 4.24537 14.7771 4.61343 14.7771H20.3787C20.7467 14.7771 20.9921 14.5104 20.9921 14.1103C21.0534 13.7768 20.7467 13.4434 20.44 13.4434Z" fill="#667085" />
    </g>
    <defs>
      <clipPath id="clip0_654_3065">
        <rect width="25" height="31" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

OfferPendingIcon.propTypes = {};

OfferPendingIcon.defaultProps = {};

export default OfferPendingIcon;
