import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Table } from 'reactstrap';
import { Link, navigate } from 'gatsby';
import i18n from 'i18n-js';

import {
  listPropertyTaxRates,
  deletePropertyTaxRate
} from '../../../../store/actions/propertyTaxRateActions';
import ListEmpty from '../../../common/ListEmpty';
import ActionsMenu from '../../../global/ActionsMenu';
import ListPagination from '../../../common/ListPagination';
import SearchBar from '../../../common/SearchBar';
import humanizeBoolean from '../../../../utils/boolUtils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.propertyTaxRates.list.index' };

const List = ({
  propertyTaxRates, loading, pagination, error, deleteError, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState(null);

  const loadPropertyTaxRates = (page = 1) => {
    const variables = { filter: { query }, page };
    props.listPropertyTaxRates(variables)
      .then(() => {})
      .catch(() => {
        if (error) toast.error(error);
      })
      .finally(() => setMounted(true));
  };

  const onEdit = (propertyTaxRate) => {
    navigate(`/admin/propertyTaxRates/form?id=${propertyTaxRate.id}`);
  };

  const onDelete = (id) => {
    props.deletePropertyTaxRate(id)
      .then()
      .catch(() => toast.error(deleteError));
  };

  const onChangePage = (page) => {
    loadPropertyTaxRates(page);
  };

  const onSearch = (event, q) => {
    event.preventDefault();

    setQuery(q);
  };

  useEffect(() => {
    loadPropertyTaxRates();
  }, []);

  useEffect(() => {
    if (!mounted) return;

    loadPropertyTaxRates();
  }, [query]);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="mb-4">
        <h2>{i18n.t('title', i18nOpts)}</h2>
      </div>

      <div className="d-flex align-items-center flex-wrap mb-4 gap-3">
        <div className="flex-grow-1">
          <SearchBar
            onSubmit={onSearch}
            inputProps={{ bsSize: 'md' }}
            searchFields={i18n.t('search', i18nOpts)}
          />
        </div>

        <div>
          <Link to="/admin/propertyTaxRates/form" className="btn btn-primary text-nowrap">
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Link>
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={propertyTaxRates} />

          {propertyTaxRates.length > 0 && (
            <div className="table-responsive mb-4">
              <Table bordered>
                <thead>
                  <tr>
                    <th>{i18n.t('country', i18nOpts)}</th>
                    <th>{i18n.t('state', i18nOpts)}</th>
                    <th>{i18n.t('city', i18nOpts)}</th>
                    <th>{i18n.t('taxRate', i18nOpts)}</th>
                    <th>{i18n.t('isDefault', i18nOpts)}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    propertyTaxRates.map((propertyTaxRate) => (
                      <tr key={`property-tax-rate-${propertyTaxRate.id}`}>
                        <td>{propertyTaxRate.country}</td>
                        <td>{propertyTaxRate.state}</td>
                        <td>{propertyTaxRate.city}</td>
                        <td>
                          {(propertyTaxRate.taxRate * 100).toFixed(6)}
                          %
                        </td>
                        <td className="text-capitalize">{humanizeBoolean(propertyTaxRate.isDefault)}</td>
                        <td className="text-right">
                          <ActionsMenu item={propertyTaxRate} onEdit={onEdit} onDelete={onDelete} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          )}

          <div className="text-right mt-4">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}
    </div>
  );
};

List.propTypes = {};

List.defaultProps = {};

export default connect((store) => ({
  propertyTaxRates: store.propertyTaxRates.propertyTaxRates,
  loading: store.propertyTaxRates.listPropertyTaxRates.loading || false,
  pagination: store.propertyTaxRates.pagination,
  error: store.propertyTaxRates.listPropertyTaxRates.error || null,
  deleteError: store.propertyTaxRates.deletePropertyTaxRate.error || null
}), {
  listPropertyTaxRates,
  deletePropertyTaxRate
})(List);
