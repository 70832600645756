import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import { UnitAvailabilities } from '../../../../../../../constants';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';
import ListValue from '../../../../../../global/filters/components/ListValue';
import { getUnitAvailabilityName } from '../../../../../../../utils/unitUtils';

const i18nOpts = { scope: 'components.admin.units.list.components.filtersModal.availabilityFilter.index' };

const AvailabilityFilter = ({ filters, onChange }) => {
  const availabilities = filters.availabilities || [];
  const availabilityOptions = Object.values(UnitAvailabilities);

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ availabilities: _.xor(availabilities, [value]) });
  };

  const filtersCount = useMemo(() => availabilities.length, [filters]);

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      {
        availabilityOptions.map((availability) => (
          <ListValue
            key={`availability-${availability}`}
            label={getUnitAvailabilityName(availability)}
            value={availability}
            selectedValues={availabilities}
            onChange={onToggle}
          />
        ))
      }
    </CollapsibleFilterGroup>
  );
};

AvailabilityFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

AvailabilityFilter.defaultProps = {
  onChange: () => {}
};

export default AvailabilityFilter;
