import React from 'react';
import i18n from 'i18n-js';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import v from 'voca';

import { formatNumber } from '../../../../../utils/currencyUtils';
import { isAdmin } from '../../../../../utils/authUtils';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';

const i18nOpts = { scope: 'components.admin.buildingModels.show.details.index' };

const Details = ({ buildingModel }) => {
  const creatorName = getCreatorDisplayName(buildingModel);
  const lastModifierName = getLastModifierDisplayName(buildingModel);

  return (
    <Row>
      <Col xs={12} sm={6} md={7} lg={4}>
        {!v.isBlank(buildingModel.hashId) && (
          <div className="mb-4">
            <div className="label">{i18n.t('modelId', i18nOpts)}</div>
            <div><strong>{buildingModel.hashId}</strong></div>
          </div>
        )}

        <div className="mb-4">
          <div className="label">{i18n.t('project', i18nOpts)}</div>
          <div><strong>{buildingModel.project.name}</strong></div>
        </div>

        {!v.isBlank(buildingModel.size) && (
          <div className="mb-4">
            <div className="label">{i18n.t('size', i18nOpts)}</div>
            <div><strong>{formatNumber(buildingModel.size)}</strong></div>
          </div>
        )}

        <div className="mb-4">
          <div>
            <i className="fas fa-bed font-20 mr-3" />
            <span className="font-18">{buildingModel.bedrooms || 0}</span>
          </div>
        </div>

        <div className="mb-4">
          <div>
            <i className="fas fa-bath font-20 mr-3" />
            <span className="font-18">{buildingModel.bathrooms || 0}</span>
          </div>
        </div>

        {isAdmin() && (
          <>
            {!v.isBlank(creatorName) && (
              <div className="mb-4">
                <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
                <div><strong>{creatorName}</strong></div>
              </div>
            )}
            {!v.isBlank(lastModifierName) && (
              <div className="mb-4">
                <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
                <div><strong>{lastModifierName}</strong></div>
              </div>
            )}
          </>
        )}

        {!v.isBlank(buildingModel.longDescription) && (
          <div className="mb-4">
            <div className="label">{i18n.t('longDescription', i18nOpts)}</div>
            <div dangerouslySetInnerHTML={{ __html: buildingModel.longDescription }} />
          </div>
        )}
      </Col>
      <Col xs={12} sm={6} md={5} lg={8}>
        {!v.isBlank(buildingModel.imageUrl) && (
          <img src={buildingModel.imageUrl} alt={buildingModel.name} className="w-100" />
        )}
      </Col>
    </Row>
  );
};

Details.propTypes = {};

Details.defaultProps = {};

export default connect((store) => ({
  buildingModel: store.buildingModels.buildingModel
}), {})(Details);
