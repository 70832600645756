import React, {
  useEffect, useMemo, useState
} from 'react';
import { connect } from 'react-redux';
import {
  Form, FormGroup, Label, Input, Button, Spinner, ModalHeader, ModalFooter, Modal,
  ModalBody
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import v from 'voca';

import { saveUser, setReloadUsers } from '../../../../../store/actions/userActions';
import { hasFormValues } from '../../../../../utils/formUtils';
import { ModalNames, Roles } from '../../../../../constants';
import { isMasterAdmin, isTecSupport, isAdmin } from '../../../../../utils/authUtils';
import api from '../../../../../api';
import { getGraphQLErrors } from '../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../ui/InputError';
import PhoneNumberField from './PhoneNumberField';
import { getValidPhones } from './utils';
import { fetchCitiesAsync } from '../../../../../hooks/useFetchCities';
import { parseToInt } from '../../../../../utils/parserUtils';
import ModalCloseButton from '../../../../common/ModalCloseButton';
import UserFormImage from '../../../../../assets/images/users/UserFormImage';
import ModalService from '../../../../../services/ModalService';

const i18nOpts = { scope: 'components.admin.users.components.formModal.index' };

function onCloseModal() {
  ModalService.close(ModalNames.USER_FORM);
}

function fetchCompaniesAsync() {
  return api.graphql(`
    {
      result: listCompanies(pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `)
    .then(({ data }) => Promise.resolve(data.result.items));
}

function getRoles() {
  const roles = [
    { value: Roles.MASTER_ADMIN, label: i18n.t('roles.masterAdmin') },
    { value: Roles.TEC_SUPPORT, label: i18n.t('roles.tecSupport') },
    { value: Roles.COMPANY_ADMIN, label: i18n.t('roles.companyAdmin') },
    { value: Roles.COMPANY_USER, label: i18n.t('roles.companyUser') },
    { value: Roles.SALES_LEAD, label: i18n.t('roles.salesLead') },
    { value: Roles.SALES_USER, label: i18n.t('roles.salesUser') }
  ];

  if (isTecSupport()) return roles.filter((role) => role.value !== Roles.MASTER_ADMIN);

  return roles;
}

function getCompanyRoles() {
  const superAdminRoles = [Roles.MASTER_ADMIN, Roles.TEC_SUPPORT];
  return getRoles().filter((role) => !superAdminRoles.includes(role.value));
}

const FormModal = ({
  opened, currentUser, currentCompany, params, ...props
}) => {
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const [phones, setPhones] = useState(['']);
  const [cities, setCities] = useState([]);
  const { user } = params;

  const isNew = useMemo(() => !user, [user]);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setSaving(true);

    saveUserAsync()
      .then(() => {
        onCloseModal();
      })
      .catch((e) => {
        const saveError = getGraphQLErrors(e);
        if (v.isString(saveError)) toast.error(saveError);
        else setError(saveError);
      })
      .finally(() => setSaving(false));
  };

  const saveUserAsync = () => {
    const validPhones = getValidPhones(phones);

    const input = {
      firstName: form.firstName || '',
      lastName: form.lastName || '',
      email: form.email || '',
      role: form.role || '',
      phones: validPhones,
      cityId: parseToInt(form.cityId)
    };

    if (isNew) {
      if (isMasterAdmin() || isTecSupport()) {
        input.role = form.role || Roles.COMPANY_ADMIN;
        input.companyId = parseInt(form.companyId, 10) || 0;
      } else {
        input.role = form.role || Roles.COMPANY_ADMIN;
        input.companyId = currentUser.company.id;
      }
    } else {
      input.id = user.id;
    }

    return props.saveUser({ input })
      .then(() => props.setReloadUsers(true));
  };

  const loadForm = () => {
    let formData;
    let userPhones;
    if (isNew) {
      formData = {};
      userPhones = [''];
    } else {
      const {
        firstName, lastName, email, role, company, city
      } = user;

      formData = {
        id: user.id,
        firstName,
        lastName,
        email,
        role,
        companyId: company.id,
        cityId: city?.id || null
      };
      userPhones = user.phones?.length ? user.phones : [''];
    }
    setForm(formData);
    setPhones(userPhones);
  };

  const loadCompanies = () => {
    fetchCompaniesAsync()
      .then((items) => setCompanies(items))
      .catch(() => {});
  };

  const onAddPhone = () => {
    setPhones((prevPhones) => ([...prevPhones, '']));
  };

  const onDeletePhone = (index) => {
    const notDeletedPhones = [...phones];
    notDeletedPhones.splice(index, 1);
    setPhones(notDeletedPhones);
  };

  const onPhoneChange = (index, value) => {
    const updatedPhones = [...phones];
    updatedPhones[index] = value;
    setPhones(updatedPhones);
  };

  const loadCities = () => {
    let companyId;
    if (isMasterAdmin() || isTecSupport()) {
      if (isNew) companyId = parseInt(form.companyId, 10) || null;
      else companyId = user?.company.id;
    } else companyId = currentCompany.id;

    if (companyId) {
      fetchCitiesAsync(companyId)
        .then((items) => setCities(items));
    } else setCities([]);

    if (isNew) setForm((prevForm) => ({ ...prevForm, cityId: null }));
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  useEffect(() => {
    if (!opened) return;

    loadForm();
  }, [opened]);

  useEffect(() => {
    loadCities();
  }, [form.companyId, user]);

  const roles = (isMasterAdmin() || isTecSupport()) ? getRoles() : getCompanyRoles();
  const hasValues = hasFormValues(form);
  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

  return (
    <Modal size="sm" isOpen={opened} modalClassName="right" scrollable>
      <ModalHeader close={<ModalCloseButton onClick={onCloseModal} />}>{title}</ModalHeader>

      <ModalBody>
        <div className="text-center">
          <UserFormImage />
        </div>
        <hr className="mb-4" />

        <Form onSubmit={onSubmit}>
          {(isMasterAdmin() || isTecSupport()) && (
            <FormGroup>
              <Label for="companyId">{i18n.t('companyId', i18nOpts)}</Label>
              <Input
                type="select"
                name="companyId"
                id="companyId"
                value={form.companyId || ''}
                onChange={onTextChange}
                disabled={!isNew}
                invalid={isInputInvalid(error, 'companyId')}
              >
                <option value="">{i18n.t('select.select')}</option>
                {
                    companies.map((c) => (
                      <option key={`company-option-${c.id}`} value={c.id}>
                        {c.name}
                      </option>
                    ))
                  }
              </Input>
              <InputError name="companyId" error={error} />
            </FormGroup>
          )}
          { isAdmin() && (
            <FormGroup>
              <Label for="role">{i18n.t('role', i18nOpts)}</Label>
              <Input
                type="select"
                name="role"
                id="role"
                value={form.role || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'role')}
              >
                <option value="">{i18n.t('select.select')}</option>
                {
                    roles.map((r) => (
                      <option key={`role-option-${r.value}`} value={r.value}>
                        {r.label}
                      </option>
                    ))
                  }
              </Input>
              <InputError name="role" error={error} />
            </FormGroup>
          )}

          <FormGroup>
            <Label for="firstName">{i18n.t('firstName', i18nOpts)}</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              value={form.firstName || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'firstName')}
            />
            <InputError name="firstName" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="lastName">{i18n.t('lastName', i18nOpts)}</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              value={form.lastName || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'lastName')}
            />
            <InputError name="lastName" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="email">{i18n.t('email', i18nOpts)}</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={form.email || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'email')}
            />
            <InputError name="email" error={error} />
          </FormGroup>

          <FormGroup>
            <div className="d-flex align-items-end justify-content-between">
              <Label for="phone">{i18n.t('phone', i18nOpts)}</Label>
              <div className="ml-3">
                <Button color="primary" size="sm" onClick={onAddPhone} className="mb-2">
                  <i className="fas fa-plus mr-2" />
                  {i18n.t('buttons.addPhone', i18nOpts)}
                </Button>
              </div>
            </div>
            {
              phones.map((phone, index) => (
                <PhoneNumberField
                  onChange={onPhoneChange}
                  value={phone}
                  key={`phone-number-${index}`}
                  index={index}
                  onDelete={onDeletePhone}
                  phonesCount={phones.length}
                />
              ))
            }

            <InputError name="phones" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="cityId">{i18n.t('cityId', i18nOpts)}</Label>
            <Input
              type="select"
              name="cityId"
              id="cityId"
              value={form.cityId || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'cityId')}
            >
              <option value="">{i18n.t('select.select')}</option>
              {
                cities.map((city) => (
                  <option key={`city-option-${city.id}`} value={city.id}>
                    {city.name}
                  </option>
                ))
              }
            </Input>
            <InputError error={error} name="cityId" />
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter className="justify-content-between gap-2">
        <div className="flex-fill">
          <Button outline color="secondary" block onClick={onCloseModal}>
            {i18n.t('buttons.cancel')}
          </Button>
        </div>
        <div className="flex-fill">
          <Button color="primary" block disabled={saving || !hasValues} onClick={onSubmit}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {isNew ? i18n.t('buttons.create') : i18n.t('buttons.save')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  currentUser: store.users.currentUser,
  currentCompany: store.companies.currentCompany,
  opened: store.modals[ModalNames.USER_FORM]?.opened || false,
  params: store.modals[ModalNames.USER_FORM]?.params || {}
}), {
  saveUser,
  setReloadUsers
})(FormModal);
