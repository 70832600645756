import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import v from 'voca';
import { Link } from 'gatsby';

import {
  getCommunities, cleanCommunities, setCommunitySearchFilters, setCommunitiesViewMode
} from '../../../../store/actions/communityActions';
import { SortOrders, ViewModes } from '../../../../constants';
import Sorting from './Sorting';
import ListEmpty from '../../../common/ListEmpty';
import { isAdmin } from '../../../../utils/authUtils';
import SearchBar from '../../../common/SearchBar';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import GridView from './GridView';
import ListView from './ListView';
import ListPagination from '../../../common/ListPagination';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.communities.list.index' };

const DEFAULT_SORT = { field: 'name', direction: SortOrders.ASC };

class List extends Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };

    this.onChangePage = this.onChangePage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onViewModeChange = this.onViewModeChange.bind(this);
  }

  componentDidMount() {
    this.loadCommunities();
  }

  componentDidUpdate(prevProps) {
    const { searchFilters: prevSearchFilters } = prevProps;
    if (this.isFilterChanged(prevSearchFilters)) this.reloadCommunities();
  }

  componentWillUnmount() {
    const { props } = this;
    const extraProps = { searchFilters: { query: null, sort: null } };
    props.cleanCommunities(extraProps);
  }

  onSubmit(e, q) {
    e.preventDefault();

    const { props } = this;

    const searchFilters = { query: q };
    props.setCommunitySearchFilters(searchFilters);
  }

  onChangePage(page) {
    this.loadCommunities(page);
  }

  onViewModeChange(viewMode) {
    const { props } = this;
    props.setCommunitiesViewMode(viewMode);
  }

  isFilterChanged(prevSearchFilters) {
    const { searchFilters } = this.props;
    return prevSearchFilters.query !== searchFilters.query
      || JSON.stringify(prevSearchFilters.sort) !== JSON.stringify(searchFilters.sort);
  }

  reloadCommunities() {
    const { props } = this;
    props.cleanCommunities()
      .then(() => this.loadCommunities());
  }

  loadCommunities(page = 1) {
    const { props } = this;
    const { searchFilters, currentCompany } = this.props;

    const sort = searchFilters.sort || DEFAULT_SORT;
    const variables = {
      filter: { companyId: currentCompany.id },
      sort,
      page
    };

    if (!v.isBlank(searchFilters.query)) variables.filter.query = searchFilters.query;

    props.getCommunities(variables)
      .finally(() => this.setState({ mounted: true }));
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return <Loading loading fullScreen />;

    const {
      communities, pagination, loading, viewMode
    } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{i18n.t('title', i18nOpts)}</h2>
          </div>
        </div>

        <div className="d-flex align-items-center flex-wrap mb-5 gap-3">
          <div className="flex-grow-1">
            <SearchBar
              onSubmit={this.onSubmit}
              inputProps={{ bsSize: 'md' }}
              searchFields="name or ID"
            />
          </div>
          <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
            <Sorting />

            <ViewModesDropdown viewMode={viewMode} onChange={this.onViewModeChange} />

            {isAdmin() && (
              <Link to="/admin/communities/form" className="btn btn-primary text-nowrap">
                <i className="fas fa-plus" />
                <span className="ml-2">{i18n.t('buttons.add', i18nOpts)}</span>
              </Link>
            )}
          </div>
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={communities} />

            {communities.length > 0 && (
              <div className="mb-4">
                {viewMode === ViewModes.GRID ? (
                  <GridView />
                ) : (
                  <ListView />
                )}
              </div>
            )}

            <div className="text-right mt-4">
              <ListPagination pagination={pagination} onPress={this.onChangePage} />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  communities: store.communities.communities,
  loading: store.communities.getCommunities.loading,
  searchFilters: store.communities.searchFilters,
  pagination: store.communities.pagination,
  viewMode: store.communities.viewMode,
  currentCompany: store.companies.currentCompany
}), {
  getCommunities,
  cleanCommunities,
  setCommunitySearchFilters,
  setCommunitiesViewMode
})(List);
