import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Spinner, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import ListItem from './ListItem';
import { PriceableTypes } from '../../../../constants';
import { addPriceRules } from '../../../../store/actions/priceRuleActions';
import { fetchPriceRulesAsync, filterAndSortPriceRules } from './utils';
import { isFloorOptionPriceRule } from '../PriceRuleFormModal/utils';

const i18nOpts = { scope: 'components.global.priceRules.priceRuleList.index' };

const PriceRuleList = ({
  model, priceableId, priceableType, priceRules, ...props
}) => {
  const [loading, setLoading] = useState(false);
  const { addPriceRules: _, ...extraProps } = props;
  const isForFloorOptions = isFloorOptionPriceRule(priceableType);

  const loadPriceRules = () => {
    setLoading(true);

    fetchPriceRulesAsync(model, priceableId, priceableType)
      .then((items) => props.addPriceRules(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadPriceRules();
  }, []);

  const filteredPriceRules = useMemo(() => filterAndSortPriceRules(priceRules, priceableId,
    priceableType), [priceRules]);

  if (loading) {
    return (
      <div className="text-center my-3">
        <Spinner size="sm" className="mr-2" />
        <span>{i18n.t('loading', i18nOpts)}</span>
      </div>
    );
  }

  if (!filteredPriceRules.length) return null;

  return (
    <Table bordered responsive size="sm" {...extraProps}>
      <thead>
        <tr className="bg-light">
          <th>{i18n.t('communityPhase', i18nOpts)}</th>
          <th>{i18n.t('price', i18nOpts)}</th>
          {isForFloorOptions && (<th>{i18n.t('defaultSelection', i18nOpts)}</th>)}
          <th>{i18n.t('externalId', i18nOpts)}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          filteredPriceRules.map((priceRule) => (
            <ListItem
              key={`price-rule-${priceRule.id}`}
              priceRule={priceRule}
              isFloorOptionPriceRule={isForFloorOptions}
            />
          ))
        }
      </tbody>
    </Table>
  );
};

PriceRuleList.propTypes = {
  priceableId: PropTypes.number,
  priceableType: PropTypes.oneOf(Object.values(PriceableTypes))
};

PriceRuleList.defaultProps = {
  priceableId: null,
  priceableType: null
};

export default connect((store) => ({
  model: store.models.model,
  priceRules: store.priceRules.priceRules
}), {
  addPriceRules
})(PriceRuleList);
