import v from 'voca';
import { PriceableTypes } from '../constants';

function findPriceRule(priceRules, communityId, communityPhaseId, priceableId, priceableType) {
  return priceRules.find((pr) => pr.communityId === communityId
    && pr.communityPhaseId === communityPhaseId
    && pr.priceableId === priceableId && pr.priceableType === priceableType);
}

function findCommunityPriceRule(priceRules, communityId) {
  return findPriceRule(priceRules, communityId, null, null, null);
}

// Find price rule for community or community phase
export function findCommunityModelPriceRule(community, model) {
  const { priceRules } = model;
  return findCommunityPriceRule(priceRules, community.id);
}

// Find price rule for community or community phase with priceable type
export function findPriceablePriceRule(community, model,
  priceableId, priceableType) {
  const { priceRules } = model;
  return findPriceRule(priceRules, community.id, null,
    priceableId, priceableType);
}

export function isCommunityModelPriceRule(priceRule) {
  return v.isBlank(priceRule.priceableType) && priceRule.communityPhase === null;
}

export function getModelBasePrice(community, model) {
  if (!community && !model) return 0;
  if (!community) return model.basePrice || 0;
  if (!community || !model) return 0;

  const priceRule = findCommunityModelPriceRule(community, model);
  return priceRule?.price || model?.basePrice || 0;
}

export function getModelElevationPrice(community, model, elevation) {
  if (!community || !model || !elevation) return 0;

  const priceRule = findPriceablePriceRule(community, model,
    elevation.id, PriceableTypes.ELEVATION);
  return priceRule?.price || 0;
}

export function getModelPalettePrice(community, model, palette) {
  if (!community || !model || !palette) return 0;

  const priceRule = findPriceablePriceRule(community, model,
    palette.id, PriceableTypes.PALETTE);
  return priceRule?.price || 0;
}

export function getSpecificationPrice(community, model, specification) {
  if (!community || !model || !specification) return 0;

  const priceRule = findPriceablePriceRule(community, model,
    specification.id, PriceableTypes.SPECIFICATION);
  return priceRule?.price || 0;
}

export function getModelPackagePrice(community, model, specPackage) {
  if (!community || !model || !specPackage) return 0;

  const priceRule = findPriceablePriceRule(community, model,
    specPackage.id, PriceableTypes.PACKAGE);
  return priceRule?.price || specPackage?.basePrice || 0;
}

export function getFloorOptionPrice(community, model, floorOption) {
  if (!floorOption) return 0;

  let price = null;
  if (community && model) {
    const priceRule = findPriceablePriceRule(community, model,
      floorOption.id, PriceableTypes.FLOOR_OPTION);
    price = priceRule?.price;
  }
  return price || floorOption.priceChange || 0;
}
