import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form, FormGroup, Label, Input, Row, Col, Button, Spinner
} from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import v from 'voca';

import { getParam } from '../../../../utils/paramsUtils';
import { hasFormValues } from '../../../../utils/formUtils';
import DeleteButton from '../../../global/DeleteButton';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { stripToEmpty } from '../../../../utils/stringUtils';
import {
  getCity, saveCity, deleteCity
} from '../../../../store/actions/cityActions';
import { CKEditor } from '../../../ui';

const i18nOpts = { scope: 'components.admin.cities.form.index' };

function onCancel() {
  navigate('/admin/cities');
}

class CityForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      form: {},
      saving: false,
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    const id = parseInt(getParam('id'), 10);
    this.setState({ id }, this.loadCity);
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onSubmit(event) {
    event.preventDefault();

    const { props } = this;
    const { form } = this.state;
    const { currentCompany } = this.props;

    const input = {
      name: stripToEmpty(form.name),
      state: stripToEmpty(form.state),
      description: stripToEmpty(form.description),
      longDescription: stripToEmpty(form.longDescription),
    };

    if (this.isNew()) input.companyId = currentCompany.id;
    else input.id = form.id;

    this.setState({ saving: true });

    const variables = { input };
    props.saveCity(variables)
      .then(() => {
        navigate('/admin/cities');
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  onDelete() {
    const { props } = this;
    const { city } = this.props;

    props.deleteCity(city.id)
      .then(() => {
        navigate('/admin/cities');
      });
  }

  loadCity() {
    const { props } = this;
    const { id } = this.state;

    if (this.isNew()) return;

    props.getCity(id)
      .then(() => {
        const { city } = this.props;

        if (city) this.setState({ form: city });
        else navigate('/admin/cities');
      })
      .catch(() => navigate('/admin/cities'));
  }

  hasFormValues() {
    const { form } = this.state;
    return hasFormValues(form);
  }

  isNew() {
    const { id } = this.state;
    return !id;
  }

  render() {
    const { form, saving, error } = this.state;

    const isNew = this.isNew();
    const hasValues = this.hasFormValues();

    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    return (
      <div>
        <Helmet title={title} />

        <h2 className="mb-4">{title}</h2>

        <Form onSubmit={this.onSubmit}>
          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="name">{i18n.t('name', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={form.name || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'name')}
                />
                <InputError name="name" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="state">{i18n.t('state', i18nOpts)}</Label>
                <Input
                  type="text"
                  name="state"
                  id="state"
                  value={form.state || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'state')}
                />
                <InputError name="state" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="description">{i18n.t('description', i18nOpts)}</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={form.description || ''}
                  onChange={this.onTextChange}
                  rows={5}
                  invalid={isInputInvalid(error, 'description')}
                />
                <InputError name="description" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form className="mb-4">
            <Col lg="6" md="8" sm="10" xs="12">
              <FormGroup>
                <Label for="longDescription">{i18n.t('longDescription', i18nOpts)}</Label>
                <CKEditor
                  data={form.longDescription || ''}
                  name="longDescription"
                  onTextChange={this.onTextChange}
                />
              </FormGroup>
            </Col>
          </Row>

          <Button color="primary" className="mr-3" disabled={saving || !hasValues}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onCancel} className="mr-3">
            {i18n.t('buttons.cancel')}
          </Button>
          {!isNew && (
            <DeleteButton onDelete={this.onDelete} />
          )}
        </Form>
      </div>
    );
  }
}

export default connect((store) => ({
  city: store.cities.city,
  currentCompany: store.companies.currentCompany
}), {
  saveCity,
  getCity,
  deleteCity
})(CityForm);
