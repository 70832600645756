import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import i18n from 'i18n-js';
import classNames from 'classnames';

import { Tabs } from '../utils';

const i18nOpts = { scope: 'components.admin.integrations.tracking.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <Nav tabs>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.TRACKING_CODE })}
        onClick={() => onChange(Tabs.TRACKING_CODE)}
      >
        {i18n.t('trackingCode', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.FORMS })}
        onClick={() => onChange(Tabs.FORMS)}
      >
        {i18n.t('forms', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.PAGES })}
        onClick={() => onChange(Tabs.PAGES)}
      >
        {i18n.t('pages', i18nOpts)}
      </NavLink>
    </NavItem>
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
