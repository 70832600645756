import React, { useMemo } from 'react';
import {
  Row, Col, Card, CardHeader, CardBody, Button
} from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import useHomeRental from '../../../../../../../../hooks/useHomeRental';
import ModalService from '../../../../../../../../services/ModalService';
import { DateFormats, ModalNames } from '../../../../../../../../constants';
import {
  getCustomerAffordability,
} from '../../../../../../../../utils/currencyUtils';
import {
  getFormattedCustomerFinancialAmount, hasCustomerFinancialAmount
} from '../../../../Overview/VerificationSection/utils';
import useCoBuyers from '../../../../../../../../hooks/useCoBuyers';
import CustomerFinancialTrendBadge
  from '../../../../../../../global/customer/CustomerFinancialTrendBadge';
import { isMasterAdmin } from '../../../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.customers.show.details.verification.financesVerified.infoCard.index' };

const InfoCard = ({ customer, currentCompany }) => {
  const { isRental, typeI18nKey } = useHomeRental();
  const { financialSummary, hasFinancialReport, financialReport } = customer;
  const { acceptedCoBuyer } = useCoBuyers();
  const hasCoBuyer = !!acceptedCoBuyer;

  const onViewReport = () => {
    ModalService.open(ModalNames.FINANCIAL_REPORT);
  };

  const hasFinancialAmount = hasCustomerFinancialAmount(customer);
  const formattedFinancialAmount = getFormattedCustomerFinancialAmount(customer, isRental);
  const { assessedAt: financialVerifiedAt } = getCustomerAffordability(customer);
  const employerName = financialSummary?.employerPrimaryName;

  const financialAmountLabel = hasCoBuyer ? i18n.t(`${typeI18nKey}.partyFinancialAmount`, i18nOpts)
    : i18n.t(`${typeI18nKey}.customerFinancialAmount`, i18nOpts);

  const { trend: employerTrend } = financialReport?.primaryEmployerIncome || {};
  const {
    debitActivityTrend, creditActivityTrend,
    overallActivityTrend
  } = financialReport?.accountAnalysis || {};
  const { trend: overallBalanceTrend } = financialReport?.overallBalance || {};

  const reportSettings = currentCompany.reportSettings || {};

  const canViewReport = useMemo(() => (
    reportSettings.enabledFinancialReport && isMasterAdmin() && hasFinancialReport
  ), [currentCompany, customer]);

  return (
    <Card className="mb-0">
      <CardHeader className={classNames({ 'py-2': canViewReport })}>
        <div className="d-flex align-items-center">
          <h4 className="mb-0 mr-3 col-gray-900">{i18n.t('title', i18nOpts)}</h4>
          {financialVerifiedAt && (
            <div className="small">
              {i18n.t('verified', { ...i18nOpts, date: moment(financialVerifiedAt).format(DateFormats.YYYY_MM_DD) })}
            </div>
          )}
          {canViewReport && (
            <div className="ml-auto">
              <Button color="secondary" outline onClick={onViewReport}>
                {i18n.t('buttons.viewReport', i18nOpts)}
              </Button>
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody className="pb-0">
        <Row>
          {hasFinancialAmount && (
            <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{financialAmountLabel}</div>
              <div className="font-weight-600 font-16">{formattedFinancialAmount}</div>
            </Col>
          )}

          {!v.isBlank(employerTrend) && (
            <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('employerTrend', i18nOpts)}</div>
              <div className="font-weight-600 font-16">
                <CustomerFinancialTrendBadge trend={employerTrend} />
              </div>
            </Col>
          )}

          {!v.isBlank(employerName) && (
            <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('employerName', i18nOpts)}</div>
              <div className="font-weight-600 font-16">{employerName}</div>
            </Col>
          )}

          {!v.isBlank(debitActivityTrend) && (
            <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('debitActivityTrend', i18nOpts)}</div>
              <div className="font-weight-600 font-16">
                <CustomerFinancialTrendBadge trend={debitActivityTrend} />
              </div>
            </Col>
          )}

          {!v.isBlank(creditActivityTrend) && (
            <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('creditActivityTrend', i18nOpts)}</div>
              <div className="font-weight-600 font-16">
                <CustomerFinancialTrendBadge trend={creditActivityTrend} />
              </div>
            </Col>
          )}

          {!v.isBlank(overallActivityTrend) && (
            <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('overallActivityTrend', i18nOpts)}</div>
              <div className="font-weight-600 font-16">
                <CustomerFinancialTrendBadge trend={overallActivityTrend} />
              </div>
            </Col>
          )}

          {!v.isBlank(overallBalanceTrend) && (
            <Col xs="12" sm="6" md="4" className="mb-4 pb-2">
              <div className="font-weight-500 col-gray-500">{i18n.t('overallBalanceTrend', i18nOpts)}</div>
              <div className="font-weight-600 font-16">
                <CustomerFinancialTrendBadge trend={overallBalanceTrend} />
              </div>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

InfoCard.propTypes = {};

InfoCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}))(InfoCard);
