import React from 'react';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { isMasterAdmin } from '../../../../../../../utils/authUtils';
import ModalService from '../../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../../constants';
import { getCustomerAffordability } from '../../../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.show.overview.verificationSection.viewReportButton.index' };

const ViewReportButton = ({ customer, currentCompany }) => {
  const { isFinancialVerified, companyContact, hasFinancialReport } = customer;

  const isFinancialVerifiedAndGranted = isFinancialVerified
    && companyContact.verifiedFinancialGranted;
  const affordability = getCustomerAffordability(customer);

  const onViewReport = () => {
    ModalService.open(ModalNames.FINANCIAL_REPORT);
  };

  if (!isFinancialVerifiedAndGranted) return null;
  if (!affordability) return null;

  const reportSettings = currentCompany.reportSettings || {};

  if (!reportSettings.enabledFinancialReport || !isMasterAdmin()
    || !hasFinancialReport) return null;

  return (
    <Button color="secondary" outline block onClick={onViewReport}>
      {i18n.t('title', i18nOpts)}
    </Button>
  );
};

ViewReportButton.propTypes = {};

ViewReportButton.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}))(ViewReportButton);
