import React, { useEffect, useState } from 'react';

const useIsMobile = () => {
  const [width, setWidth] = useState(window?.innerWidth || null);

  useEffect(() => {
    window.addEventListener('resize', manageResize);

    return () => {
      window.removeEventListener('resize', manageResize);
    };
  }, []);

  function manageResize() {
    setWidth(window.innerWidth);
  }

  return width < 768;
};

export const withIsMobile = (Component) => (props) => {
  const isMobile = useIsMobile();

  return <Component isMobile={isMobile} {...props} />;
};

export default useIsMobile;
