import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';

export const Steps = Object.freeze({
  FORM: 'FORM',
  CONFIRM: 'CONFIRM'
});

export function closeModal() {
  ModalService.close(ModalNames.QUICK_POSSESSION_FORM);
}
