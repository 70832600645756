import React from 'react';
import ReactTooltip from 'react-tooltip';
import Proptypes from 'prop-types';

import CommunitiesList from './CommunitiesList';

const CommunitiesTooltip = ({ showTooltip }) => (
  <ReactTooltip
    id="model-communities-tooltip"
    getContent={(dataTip) => (showTooltip ? (<CommunitiesList data={dataTip} />) : null)}
    effect="solid"
    delayHide={100}
    delayShow={100}
    delayUpdate={100}
    place="right"
    border
    type="light"
  />
);

CommunitiesTooltip.propTypes = {
  showTooltip: Proptypes.bool
};

CommunitiesTooltip.defaultProps = {
  showTooltip: true
};

export default CommunitiesTooltip;
