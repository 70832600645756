import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import i18n from 'i18n-js';
import * as UAParser from 'ua-parser-js';
import { connect } from 'react-redux';
import v from 'voca';

import { getBrowserInfo, getDeviceInfo, getOSInfo } from '../../../../../../../utils/userAgentUtils';
import {
  getDurationTimeFormatted,
} from '../../../../../../../utils/dateUtils';

const i18nOpts = { scope: 'components.admin.customers.show.details.activity.analyticsCard.index' };

const AnalyticsCard = ({ customer }) => {
  const uaParsed = UAParser(customer.userAgent);
  const [browserInfo] = getBrowserInfo(uaParsed);
  const [deviceInfo] = getDeviceInfo(uaParsed);
  const [osInfo] = getOSInfo(uaParsed);
  const { totalVisits = 0 } = customer;
  const averageVisitTimeFormatted = getDurationTimeFormatted(customer.averageVisitTime);

  return (
    <Card body>
      <h4>{i18n.t('title', i18nOpts)}</h4>

      <Row className="mt-1">
        <Col xs="6" className="pb-3">
          <div>{i18n.t('totalVisits', i18nOpts)}</div>
          <div className="font-16 font-weight-500 text-dark">{totalVisits}</div>
        </Col>
        <Col xs="6" className="pb-3">
          <div>{i18n.t('averageVisitTime', i18nOpts)}</div>
          <div className="font-16 font-weight-500 text-dark">{averageVisitTimeFormatted}</div>
        </Col>
        {!v.isBlank(deviceInfo) && (
          <Col xs="6" className="pb-3">
            <div>{i18n.t('device', i18nOpts)}</div>
            <div className="font-16 font-weight-500 text-dark">{deviceInfo}</div>
          </Col>
        )}
        {!v.isBlank(osInfo) && (
          <Col xs="6" className="pb-3">
            <div>{i18n.t('os', i18nOpts)}</div>
            <div className="font-16 font-weight-500 text-dark">{osInfo}</div>
          </Col>
        )}
        {!v.isBlank(browserInfo) && (
          <Col xs="6" className="pb-3">
            <div>{i18n.t('browser', i18nOpts)}</div>
            <div className="font-16 font-weight-500 text-dark">{browserInfo}</div>
          </Col>
        )}
      </Row>
    </Card>
  );
};

AnalyticsCard.propTypes = {};

AnalyticsCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer
}))(AnalyticsCard);
