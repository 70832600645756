import React from 'react';
import BaseSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { Colors } from '../../../constants';

const BASE_STYLES = Object.freeze({
  rail: {
    backgroundColor: Colors.GRAY_200,
    height: 8
  },
  track: {
    backgroundColor: Colors.GRAY_500,
    height: 8
  },
  handle: {
    width: 24,
    height: 24,
    borderWidth: 1.5,
    marginTop: -8,
    borderColor: Colors.GRAY_500,
    opacity: 1,
    boxShadow: 'none'
  },
});

const Slider = ({ ...props }) => (
  <BaseSlider
    style={{
      height: 26,
      padding: '9px 0'
    }}
    range
    allowCross={false}
    dots={false}
    styles={BASE_STYLES}
    dotStyle={{
      width: 12,
      height: 12,
      borderWidth: 1.5,
      bottom: -6
    }}
    activeDotStyle={{
      borderColor: Colors.GRAY_500
    }}
    {...props}
  />
);

Slider.propTypes = {};

Slider.defaultProps = {};

export default Slider;
