import React, { useState } from 'react';
import {
  DropdownMenu, DropdownToggle, Input, Spinner, UncontrolledDropdown
} from 'reactstrap';
import { MdKeyboardArrowDown } from 'react-icons/md';
import PropTypes from 'prop-types';
import v from 'voca';
import classNames from 'classnames';

import styles from './FormDropdown.module.scss';

const FormDropdown = ({
  value, placeholder, children, isSearchable, searchText, setSearchText, searching
}) => {
  const hasValue = !v.isBlank(value);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onToggleDropdown = () => {
    setSearchText('');
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const onInputKeyDown = (e) => {
    if (e.keyCode === 32) {
      setSearchText((prevText) => `${prevText} `);
      e.preventDefault();
    }
  };

  return (
    <UncontrolledDropdown className="w-100" isOpen={isDropdownOpen} toggle={onToggleDropdown}>
      <DropdownToggle color="outline-secondary" className={classNames(styles.dropdown, { [styles.placeholder]: !hasValue })}>
        {isSearchable && isDropdownOpen ? (
          <>
            <Input
              type="text"
              value={searchText}
              onChange={onSearchTextChange}
              onKeyDown={onInputKeyDown}
              autoFocus
            />

            {searching && <Spinner size="sm" className={styles.loading} />}
          </>
        ) : (
          <span className="text-truncate">
            {value || placeholder}
          </span>
        )}
        {!isSearchable && <MdKeyboardArrowDown size="1.5rem" className="ml-1 op-3" />}
      </DropdownToggle>
      <DropdownMenu className="w-100">
        {children}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

FormDropdown.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isSearchable: PropTypes.bool,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  searching: PropTypes.bool
};

FormDropdown.defaultProps = {
  value: null,
  isSearchable: false,
  searchText: '',
  setSearchText: () => {},
  searching: false
};

export default FormDropdown;
