import React, { useState } from 'react';
import {
  TabContent, TabPane, Row, Col
} from 'reactstrap';

import './styles.scss';
import Installation from './Installation';
import JavaScriptSdk from './JavaScriptSdk';
import { Tabs } from './utils';
import NavTabs from './NavTabs';

const TrackingCode = () => {
  const [activeTab, setActiveTab] = useState(Tabs.INSTALLATION);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Row>
      <Col xs="12" sm="5" md="4" lg="3" xl="3">
        <NavTabs activeTab={activeTab} onChange={onTabChange} />
      </Col>

      <Col xs="12" sm="7" md="8" lg="9" xl="9">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={Tabs.INSTALLATION}>
            <Installation />
          </TabPane>
          <TabPane tabId={Tabs.JAVASCRIPT_SDK}>
            <JavaScriptSdk />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

TrackingCode.propTypes = {};

TrackingCode.defaultProps = {};

export default TrackingCode;
