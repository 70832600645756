import React, { useEffect, useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import { MdLocationOn } from 'react-icons/md';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import api from '../../../../api';

const i18nOpts = { scope: 'components.global.filters.communityFilter.index' };

function fetchCommunitiesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListCommunities($filter: CommunityFilterInput) {
      result: listCommunities(filter: $filter, pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `, variables)
    .then(({ data: { result: { items } } }) => Promise.resolve(items));
}

const CommunityFilter = ({ onChange, currentCompany }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(null);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onToggleCommunity = (community) => {
    setSelectedCommunity(community);
    onChange(community);
  };

  useEffect(() => {
    fetchCommunitiesAsync(currentCompany.id)
      .then((items) => setCommunities(items))
      .catch(() => {});
  }, []);

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
        <DropdownToggle
          color="outline-secondary"
          size="sm"
          caret
          disabled={!communities.length}
          className="text-nowrap"
        >
          <MdLocationOn size="1rem" className="mr-2 text-muted d-inline-block d-sm-none" />
          <span className="mr-2 text-muted d-none d-sm-inline-block">{i18n.t('title', i18nOpts)}</span>
          {selectedCommunity?.name ?? i18n.t('select.all')}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onToggleCommunity(null)}>
            {i18n.t('select.all')}
          </DropdownItem>
          {
            communities.map((c) => (
              <DropdownItem
                key={`community-${c.id}`}
                onClick={() => onToggleCommunity(c)}
              >
                {c.name}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

CommunityFilter.propTypes = {
  onChange: PropTypes.func
};

CommunityFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(CommunityFilter);
