import api from '../../../../../../../api';
import {
  updateCompanyContactMutation
} from '../../../../../../../graphql';

export async function updateCompanyContactSalesLeadAsync(contact, salesRep) {
  const input = {
    id: contact.companyContact.id,
    salesLeadId: salesRep ? salesRep.id : null,
    // eslint-disable-next-line no-underscore-dangle
    salesLeadType: salesRep ? salesRep.__typename : null,
  };

  return api.graphql(updateCompanyContactMutation, { input })
    .then(({ data: { item } }) => item);
}
