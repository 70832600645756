import React from 'react';
import { connect } from 'react-redux';

import ReservationDocumentManagement from '../../../../global/ReservationDocumentManagement';
import { DocumentTemplateAssignableEntities } from '../../../../../constants';

const ReservationDocument = ({ community }) => (
  <ReservationDocumentManagement
    entityId={community.id}
    entityType={DocumentTemplateAssignableEntities.COMMUNITY}
  />
);

ReservationDocument.propTypes = {};

ReservationDocument.defaultProps = {};

export default connect((store) => ({
  community: store.communities.community,
}))(ReservationDocument);
