import React, { useState } from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import { setCustomerFilters } from '../../../../../store/actions/customerActions';
import { getInterestKey } from '../utils';
import useFetchCommunitiesAndProjects from '../../../../../hooks/useFetchCommunitiesAndProjects';

const i18nOpts = { scope: 'components.admin.customers.list.interestsFilter.index' };

const InterestsFilter = ({ currentCompany, selectedFilters, ...props }) => {
  const [selectedInterest, setSelectedInterest] = useState(null);
  const { communitiesProjects: availableInterests } = useFetchCommunitiesAndProjects();

  const onSelect = (interest) => {
    setSelectedInterest(interest);

    const interestId = interest?.id || null;
    const interestType = interest?.__typename || null;
    props.setCustomerFilters({ ...selectedFilters, interestId, interestType });
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle color="outline-secondary" caret>
        {selectedInterest?.name ?? i18n.t('select', i18nOpts)}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={() => onSelect(null)}>
          {i18n.t('select', i18nOpts)}
        </DropdownItem>
        {
          availableInterests.map((interest) => (
            <DropdownItem
              key={getInterestKey(interest)}
              onClick={() => onSelect(interest)}
              active={
                interest.id === selectedInterest?.id
                // eslint-disable-next-line no-underscore-dangle
                && interest.__typename === selectedInterest?.__typename
              }
            >
              {interest.name}
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

InterestsFilter.propTypes = {};

InterestsFilter.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  selectedFilters: store.customers.filters
}), {
  setCustomerFilters
})(InterestsFilter);
