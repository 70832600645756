import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import ModalService from '../../../../../../../services/ModalService';
import { ModalNames, PaymentVendors } from '../../../../../../../constants';
import { getDepositRefundedAmount } from './utils';
import { formatCurrency, isValidAmount } from '../../../../../../../utils/currencyUtils';
import useIsRefundsEnabled from '../../../../../../../hooks/useIsRefundsEnabled';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.depositSection.RefundDepositSection.index' };

const RefundDepositSection = ({ home }) => {
  const isRefundsEnabled = useIsRefundsEnabled();
  if (!isRefundsEnabled) return null;

  const { deposit } = home;
  const { confirmed, creditAllowed, paymentVendor } = deposit || {};

  const onClick = () => {
    ModalService.open(ModalNames.DEPOSIT_REFUND_FORM, { depositId: deposit.id });
  };

  if (paymentVendor !== PaymentVendors.STRIPE || !confirmed) return null;

  const refundedAmount = getDepositRefundedAmount(deposit);

  return (
    <div className="d-flex flex-column gap-3">
      {isValidAmount(refundedAmount) && (
        <div>
          <div className="col-gray-500 font-weight-500">{i18n.t('refundedAmount', i18nOpts)}</div>
          <div className="font-weight-600 font-16">
            {formatCurrency(refundedAmount)}
          </div>
        </div>
      )}
      {creditAllowed > 0 && (
        <div
          role="button"
          aria-hidden
          onClick={onClick}
          className="text-primary font-weight-600"
        >
          {i18n.t('buttons.refundDeposit', i18nOpts)}
        </div>
      )}
    </div>
  );
};

RefundDepositSection.propTypes = {};

RefundDepositSection.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}))(RefundDepositSection);
