import React from 'react';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import { BiTrash } from 'react-icons/bi';
import classNames from 'classnames';
import i18n from 'i18n-js';
import v from 'voca';

import styles from './MortgageProviderAssignmentItem.module.scss';
import { DEFAULT_IMAGE } from '../../../../../constants';
import { confirmDelete } from '../../../confirmAlert';
import { deleteMortgageProviderAssignmentAsync } from '../../utils';

const MortgageProviderAssignmentItem = ({ mortgageProviderAssignment, ...props }) => {
  const { mortgageProvider } = mortgageProviderAssignment;

  const onDelete = () => {
    deleteMortgageProviderAssignmentAsync(mortgageProviderAssignment.id)
      .then(() => props.onAfterDelete());
  };

  return (
    <Card body>
      <div className="d-flex align-items-center">
        <div className={classNames('mr-3', styles.imgContainer)}>
          <img
            src={mortgageProvider.imageUrl || DEFAULT_IMAGE}
            alt={mortgageProvider.name}
          />
        </div>

        <div className="flex-grow-1">
          <span className="col-gray-900 font-weight-600 font-16">{mortgageProvider.name}</span>
        </div>

        <div className="ml-3">
          <div
            role="button"
            aria-hidden
            className="text-danger"
            title={i18n.t('buttons.delete')}
            onClick={() => confirmDelete(() => onDelete())}
          >
            <BiTrash size="1.1rem" />
          </div>
        </div>
      </div>

      {!v.isBlank(mortgageProvider.url) && (
        <div className="mt-3">
          <a href={mortgageProvider.url} target="_blank" rel="noreferrer" className="col-gray-600">
            {mortgageProvider.url}
          </a>
        </div>
      )}
    </Card>
  );
};

MortgageProviderAssignmentItem.propTypes = {
  mortgageProviderAssignment: PropTypes.objectOf(PropTypes.any).isRequired,
  onAfterDelete: PropTypes.func
};

MortgageProviderAssignmentItem.defaultProps = {
  onAfterDelete: () => {}
};

export default MortgageProviderAssignmentItem;
