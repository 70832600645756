import React, { Component } from 'react';
import { toast } from 'react-toastify';
import {
  Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';

import { ModalNames } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import { saveOption } from '../../../../../../store/actions/optionActions';
import { parseToFloat } from '../../../../../../utils/parserUtils';
import { getError } from '../../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';

const i18nOpts = { scope: 'components.admin.units.show.options.optionFormModal.index' };

function onClose() {
  ModalService.close(ModalNames.OPTION_FORM);
}

class OptionFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      saving: false,
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { opened } = this.props;
    if (prevProps.opened !== opened && opened) this.init();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onSave() {
    const { props } = this;

    this.setState({ saving: true });

    const input = this.getFormInput();
    const variables = { input };
    props.saveOption(variables)
      .then(() => {
        ModalService.close(ModalNames.OPTION_FORM);
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  getFormInput() {
    const { params: { option } } = this.props;
    const { form } = this.state;

    return {
      id: option.id,
      price: parseToFloat(form.price)
    };
  }

  init() {
    const { params: { option } } = this.props;
    const { price } = option;
    const form = { price };
    this.setState({
      form,
      saving: false,
      error: null
    });
  }

  render() {
    const { form, saving, error } = this.state;
    const { opened } = this.props;

    return (
      <Modal isOpen={opened}>
        <ModalHeader>{i18n.t('title', i18nOpts)}</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="optionPrice">{i18n.t('price', i18nOpts)}</Label>
            <Input
              type="number"
              name="price"
              id="optionPrice"
              value={form.price || ''}
              onChange={this.onTextChange}
              invalid={isInputInvalid(error, 'price')}
            />
            <InputError name="price" error={error} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onClose} disabled={saving}>
            {i18n.t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect((store) => ({
  opened: store.modals[ModalNames.OPTION_FORM]?.opened || false,
  params: store.modals[ModalNames.OPTION_FORM]?.params || {}
}), {
  saveOption
})(OptionFormModal);
