import {
  SAVE_FLOOR_GROUP, DELETE_FLOOR_GROUP, SORT_FLOOR_GROUPS
} from './types';
import {
  createFloorGroupQuery, deleteFloorGroupQuery, sortFloorGroupsQuery, updateFloorGroupQuery
} from '../../graphql';
import api from '../../api';

export function saveFloorGroup(variables = {}) {
  const query = variables.input.id ? updateFloorGroupQuery : createFloorGroupQuery;
  return {
    type: SAVE_FLOOR_GROUP,
    payload: api.graphql(query, variables)
  };
}

export function deleteFloorGroup(id) {
  return {
    type: DELETE_FLOOR_GROUP,
    payload: api.graphql(deleteFloorGroupQuery, { id })
  };
}

export function sortFloorGroups(variables = {}) {
  return {
    type: SORT_FLOOR_GROUPS,
    payload: api.graphql(sortFloorGroupsQuery, variables)
  };
}
