import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

const Floor = ({ unit }) => {
  const { floor } = unit;

  if (v.isBlank(floor)) return null;

  return (
    <div>
      {floor}
    </div>
  );
};

Floor.propTypes = {
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

Floor.defaultProps = {};

export default Floor;
