import React from 'react';
import { connect } from 'react-redux';

import InvitationListItem from '../../components/InvitationListItem';

const CustomerInfo = ({ invitedCustomers }) => {
  const customer = invitedCustomers[0];

  return (
    <table className="w-100">
      <tbody>
        <InvitationListItem customer={customer} />
      </tbody>
    </table>
  );
};

CustomerInfo.propTypes = {};

CustomerInfo.defaultProps = {};

export default connect((store) => ({
  invitedCustomers: store.salesInvitation.invitedCustomers
}))(CustomerInfo);
