import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { MdFileDownload } from 'react-icons/md';
import i18n from 'i18n-js';
import v from 'voca';

import useIsVerifiedEnabled from '../../../../../../../hooks/useIsVerifiedEnabled';
import CustomerCoBuyersCountBadge from '../../../../../../global/customer/CustomerCoBuyersCountBadge';
import { formatCurrency } from '../../../../../../../utils/currencyUtils';
import { OfferStatuses, PreApprovalVerificationMethods } from '../../../../../../../constants';
import CustomerFinancialAmount from '../../../../../../global/customer/CustomerFinancialAmount';
import { getOfferRealtorText } from '../../../../../../../utils/offerUtils';
import OfferSteps from '../../../../../../global/home/OfferSteps';
import { getDealHomeInfo } from '../../../../../../../utils/dealUtils';
import DealLastActivityDate from '../../../../../../global/Deal/DealLastActivityDate';

const i18nOpts = { scope: 'components.admin.sales.deals.list.listView.listItem.index' };

const ListItem = ({ deal }) => {
  const { contact: customer, home: savedHome } = deal;
  const home = savedHome || {};
  const { offer } = home;

  if (!offer) {
    home.offer = {
      status: OfferStatuses.INVITED,
      statusChangeDates: {
        invitedAt: deal.createdAt
      }
    };
  }

  const { preApproval } = customer.user || {};
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const { communityProjectName, name, address } = getDealHomeInfo(deal);

  const isPreApprovalManual = preApproval?.verificationMethod
    === PreApprovalVerificationMethods.MANUAL;

  return (
    <tr>
      <td className="fixed-column clickable-column p-0">
        <Link
          to={`/admin/customers/${customer.id}?from=deals`}
          className="col-gray-600 p-3 d-flex flex-column h-100"
        >
          <div className="d-flex align-items-start gap-2">
            <span className="font-weight-600">
              {customer.name}
            </span>

            <CustomerCoBuyersCountBadge count={customer.coBuyersCount} />
          </div>

          {!v.isBlank(customer.email) && (
            <div className="col-gray-600">{customer.email}</div>
          )}
        </Link>
      </td>
      <td>
        <div className="d-flex align-items-center text-nowrap">
          <div>
            <div className="font-weight-500 col-gray-900">{formatCurrency(offer?.offerPrice)}</div>

            {isPreApprovalManual && (
              <div className="text-nowrap">{i18n.t('preApproval', i18nOpts)}</div>
            )}
          </div>
          {isPreApprovalManual && (
            <div className="pl-3">
              <a
                href={preApproval.documentUrl}
                target="_blank"
                rel="noreferrer"
                className="d-flex col-secondary-1"
                title={i18n.t('buttons.download')}
              >
                <MdFileDownload size="1.3rem" />
              </a>
            </div>
          )}
        </div>
      </td>
      <td>
        <OfferSteps home={home} clickableSteps />
      </td>
      <td>
        <div className="font-weight-600 col-gray-800">{name}</div>
        {!v.isBlank(address) && (
          <div>
            <small className="col-gray-900">{address}</small>
          </div>
        )}
      </td>
      <td>
        <div className="font-weight-500 col-gray-900">{communityProjectName}</div>
      </td>
      {isVerifiedEnabled && (
        <td>
          <CustomerFinancialAmount customer={customer} className="font-weight-500 col-gray-900" />
        </td>
      )}
      <td>
        {getOfferRealtorText(offer)}
      </td>
      <td>
        <DealLastActivityDate deal={deal} />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  deal: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
