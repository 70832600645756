import React from 'react';

import MortgageProvidersList from '../../MortgageProviders/List';

const MortgageProviders = () => (<MortgageProvidersList />);

MortgageProviders.propTypes = {};

MortgageProviders.defaultProps = {};

export default MortgageProviders;
