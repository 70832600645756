import _ from 'lodash';

export function getAvailableModelCommunities(model) {
  const communities = (model.communities || []).map((c) => ({ id: c.id, name: c.name }));
  return _.sortBy(communities, ['name']);
}

export function getAvailableModelCommunityPhases(model) {
  const communityPhases = (model.communities || []).flatMap((c) => c.phases.map((p) => ({
    id: p.id,
    name: `${c.name} / Phase ${p.phase}`
  })));
  return _.sortBy(communityPhases, ['name']);
}
