import React from 'react';
import { Button } from 'reactstrap';
import { IoFilter } from 'react-icons/io5';
import i18n from 'i18n-js';

import ModalService from '../../../../../../services/ModalService';
import { Colors, ModalNames } from '../../../../../../constants';

const FiltersButton = () => {
  const onClick = () => {
    ModalService.open(ModalNames.UNIT_FILTERS);
  };

  return (
    <Button color="secondary" outline onClick={onClick}>
      <IoFilter size={20} color={Colors.GRAY_700} />
      <span className="ml-2">{i18n.t('buttons.filters')}</span>
    </Button>
  );
};

FiltersButton.propTypes = {};

FiltersButton.defaultProps = {};

export default FiltersButton;
