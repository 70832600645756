import React from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { ModalNames, PriceableTypes } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import AddPriceRuleButton from '../../priceRules/AddPriceRuleButton';
import PriceRuleList from '../../priceRules/PriceRuleList';
import { isAdmin } from '../../../../utils/authUtils';

const i18nOpts = { scope: 'components.global.floorsManager.floorOptionPriceRulesModal.index' };

function onClose() {
  ModalService.close(ModalNames.FLOOR_OPTION_PRICE_RULES);
}

const FloorOptionPriceRulesModal = ({ opened, params }) => {
  const { floorOption } = params;

  return (
    <Modal isOpen={opened} size="lg">
      <ModalHeader>{i18n.t('title', { ...i18nOpts, name: floorOption?.name })}</ModalHeader>

      <ModalBody>
        {opened && (
          <>
            {isAdmin() && (
              <div className="text-right">
                <AddPriceRuleButton
                  priceableId={floorOption.id}
                  priceableType={PriceableTypes.FLOOR_OPTION}
                />
              </div>
            )}

            <PriceRuleList
              className={classNames({ 'mt-3': isAdmin() })}
              priceableId={floorOption.id}
              priceableType={PriceableTypes.FLOOR_OPTION}
            />
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <Button outline color="secondary" onClick={onClose}>
          {i18n.t('buttons.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.FLOOR_OPTION_PRICE_RULES]?.opened || false,
  params: store.modals[ModalNames.FLOOR_OPTION_PRICE_RULES]?.params || {}
}))(FloorOptionPriceRulesModal);
