import React from 'react';
import { MdExpandMore } from 'react-icons/md';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import EditableSwitch from '../components/EditableSwitch';
import { Colors } from '../../../../../constants';

const FloorGroup = ({
  floorGroup, activeFloorGroup, onToggle, children, editableFloorGroupIds, onEditableToggle
}) => {
  const isEditable = editableFloorGroupIds.includes(floorGroup.id);

  return (
    <div className="floor-group">
      <div className={`floor-group-header ${classnames({ active: floorGroup.id === activeFloorGroup })}`}>
        <div className="d-flex align-items-center">
          <div
            className="flex-grow-1 pr-2 font-12 font-weight-500"
            onClick={() => onToggle(floorGroup.id)}
            role="button"
            aria-hidden
          >
            {floorGroup.name}
          </div>
          <div>
            <EditableSwitch checked={isEditable} onToggle={() => onEditableToggle(floorGroup.id)} />
          </div>
          <div
            onClick={() => onToggle(floorGroup.id)}
            role="button"
            aria-hidden
          >
            <div className="expand-icon">
              <MdExpandMore size="22" color={Colors.GRAY_600} />
            </div>
          </div>
        </div>
      </div>

      <Collapse isOpen={activeFloorGroup === floorGroup.id}>
        <div className="floor-group-content">
          {children}
        </div>
      </Collapse>
    </div>
  );
};

FloorGroup.propTypes = {
  editableFloorGroupIds: PropTypes.arrayOf(PropTypes.number),
  onEditableToggle: PropTypes.func
};

FloorGroup.defaultProps = {
  editableFloorGroupIds: [],
  onEditableToggle: () => {}
};

export default FloorGroup;
