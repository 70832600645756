import React, { Component } from 'react';
import {
  ListGroup, ListGroupItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu
} from 'reactstrap';
import { BiGridVertical, BiTrash } from 'react-icons/bi';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Link, navigate } from 'gatsby';
import { ReactSortable } from 'react-sortablejs';

import api from '../../../../../api';
import {
  getCommunityModels, sortCommunityModels, saveCommunityModel, deleteCommunityModel,
  setCommunityModels
} from '../../../../../store/actions/communityModelsActions';
import { listModelsQuery } from '../../../../../graphql';
import { DEFAULT_IMAGE, NavigationMainNames } from '../../../../../constants';
import { isAdmin } from '../../../../../utils/authUtils';
import { confirmDelete } from '../../../../global/confirmAlert';
import NavigationService from '../../../../../services/NavigationService';
import './styles.scss';

const i18nOpts = { scope: 'components.admin.communities.show.models.index' };

function getModelsAsync(companyId) {
  const variables = { filter: { companyId }, pageSize: null };

  return api.graphql(listModelsQuery, variables)
    .then(({ data: { result: { items } } }) => Promise.resolve(items));
}

class Models extends Component {
  constructor(props) {
    super(props);

    this.state = {
      models: [],
      isDropdownOpen: false
    };

    this.onToggleDropdown = this.onToggleDropdown.bind(this);
    this.onSortModels = this.onSortModels.bind(this);
    this.onSetModels = this.onSetModels.bind(this);
    this.getAvailableModels = this.getAvailableModels.bind(this);
    this.onAddModel = this.onAddModel.bind(this);
    this.onRemoveCommunityModel = this.onRemoveCommunityModel.bind(this);
  }

  componentDidMount() {
    this.loadModels();
    this.loadCommunityModels();
  }

  onSortModels() {
    const { props } = this;
    const { community, communityModels } = this.props;

    const ids = communityModels.map((cm) => cm.id);

    const variables = { communityId: community.id, ids };
    props.sortCommunityModels(variables);
  }

  onSetModels(communityModels) {
    const { props } = this;
    props.setCommunityModels(communityModels);
  }

  onToggleDropdown() {
    this.setState(({ isDropdownOpen }) => ({ isDropdownOpen: !isDropdownOpen }));
  }

  onAddModel(model) {
    const { props } = this;
    const { community } = this.props;

    const variables = { input: { communityId: community.id, modelId: model.id } };
    props.saveCommunityModel(variables);
  }

  onRemoveCommunityModel(model) {
    const { props } = this;
    props.deleteCommunityModel(model.id);
  }

  getCommunityModelUrl(communityModel) {
    const { model } = communityModel;
    return NavigationService.getNavigationShowUrl(model, NavigationMainNames.MODELS);
  }

  getAvailableModels() {
    const { models } = this.state;
    const { communityModels } = this.props;
    const selectedModelIds = communityModels.map((cm) => cm.model.id);
    return models.filter((m) => !selectedModelIds.includes(m.id));
  }

  loadModels() {
    const { currentCompany } = this.props;
    getModelsAsync(currentCompany.id)
      .then((models) => {
        this.setState({ models });
      })
      .catch(() => navigate('/admin/overview'));
  }

  loadCommunityModels() {
    const { props } = this;
    const { community } = this.props;

    const variables = { filter: { communityId: community.id } };
    props.getCommunityModels(variables);
  }

  render() {
    const { isDropdownOpen } = this.state;
    const { communityModels } = this.props;

    const availableModels = this.getAvailableModels();

    return (
      <div>
        {isAdmin() && (
          <div className="text-right mb-3">
            <Dropdown isOpen={isDropdownOpen} toggle={this.onToggleDropdown}>
              <DropdownToggle color="outline-secondary" caret disabled={!availableModels.length}>
                {i18n.t('buttons.add', i18nOpts)}
              </DropdownToggle>
              <DropdownMenu right className="overflow-auto">
                {
                  availableModels.map((model) => (
                    <DropdownItem
                      key={`available-model-${model.id}`}
                      onClick={() => this.onAddModel(model)}
                    >
                      {model.name}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>
          </div>
        )}

        <ListGroup>
          <ReactSortable
            list={communityModels}
            handle=".sortable-model-handler"
            draggable=".sortable-model-item"
            setList={this.onSetModels}
            onEnd={this.onSortModels}
          >
            {
              communityModels.map((communityModel) => {
                const imageUrl = communityModel.model.imageUrl
                  || communityModel.model.defaultImageUrl || DEFAULT_IMAGE;
                return (
                  <ListGroupItem
                    key={`community-model-${communityModel.id}`}
                    className="community-model-container sortable-model-item"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="w-100">
                        <Link to={this.getCommunityModelUrl(communityModel)}>
                          <img
                            className="community-model-image mr-3"
                            src={imageUrl}
                            alt={communityModel.model.name}
                          />
                          <span className="flex-grow-1">{communityModel.model.name}</span>
                        </Link>
                      </div>
                      <div className="w-100 text-center">
                        <div className="">
                          {communityModel.model.productType?.name}
                        </div>
                      </div>
                      {isAdmin() && (
                        <div className="w-100 text-right">
                          <div>
                            <BiGridVertical
                              size="1.2rem"
                              className="sortable-model-handler mr-3"
                              title={i18n.t('buttons.sort')}
                            />

                            <span
                              role="button"
                              aria-hidden
                              className="text-danger"
                              /* eslint-disable-next-line max-len */
                              onClick={() => confirmDelete(() => this.onRemoveCommunityModel(communityModel))}
                              title={i18n.t('buttons.delete')}
                            >
                              <BiTrash size="1.1rem" />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </ListGroupItem>
                );
              })
            }
          </ReactSortable>
        </ListGroup>
      </div>
    );
  }
}

export default connect((store) => ({
  community: store.communities.community,
  communityModels: store.communityModels.communityModels,
  currentCompany: store.companies.currentCompany
}), {
  getCommunityModels,
  sortCommunityModels,
  saveCommunityModel,
  deleteCommunityModel,
  setCommunityModels
})(Models);
