import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import v from 'voca';

import { formatCurrency } from '../../../../../../utils/currencyUtils';
import { formatTimestamp } from '../../../../../../utils/dateUtils';
import PaymentMethod from './PaymentMethod';
import HomeCard from '../../../../../global/HomeCard';
import useIsRefundsEnabled from '../../../../../../hooks/useIsRefundsEnabled';
import Status from './Status';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames, ReserveStepTypes } from '../../../../../../constants';
import ActionsMenu from './ActionsMenu';
import { getHomeInfo } from '../../../../../../utils/homeUtils';

const ListItem = ({ deposit }) => {
  const isRefundsEnabled = useIsRefundsEnabled();
  const { user: { contact }, home } = deposit;

  const isValidHome = useMemo(() => (home && v.isBlank(home.deletedByUserAt)), [home]);

  const openDealModal = () => {
    if (isValidHome) {
      ModalService.open(ModalNames.DEAL, { homeId: home?.id, stepType: ReserveStepTypes.DEPOSIT });
    }
  };

  const { communityProjectName } = getHomeInfo(home);

  return (
    <tr>
      <td>
        <div className="text-nowrap">{formatTimestamp(deposit.createdAt)}</div>
      </td>
      <td className="fixed-column clickable-column p-0">
        <Link
          to={`/admin/customers/${contact.id}`}
          className="col-gray-600 p-3 d-flex flex-column h-100"
        >
          <div className="font-weight-600">{contact.name}</div>
          {!v.isBlank(contact.email) && (
            <div>{contact.email}</div>
          )}
        </Link>
      </td>
      <td>
        <span className="font-weight-500 col-gray-900">{communityProjectName}</span>
      </td>
      <td className={classNames('p-0', { 'clickable-column': isValidHome })}>
        <div role={isValidHome ? 'button' : undefined} aria-hidden onClick={openDealModal} className="p-3">
          <HomeCard home={home} hideTotalOffers hideBuiltDate hideImage isClickable={false} />
        </div>
      </td>
      <td>
        <div>
          <span className="font-weight-500 col-gray-900">{formatCurrency(deposit.amount)}</span>
          <span className="text-uppercase ml-1">{deposit.currency}</span>
        </div>
      </td>
      <td>
        <PaymentMethod deposit={deposit} />
      </td>
      <td>
        <Status deposit={deposit} />
      </td>
      {isRefundsEnabled && (
        <td className="text-right">
          <ActionsMenu deposit={deposit} />
        </td>
      )}
    </tr>
  );
};

ListItem.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
