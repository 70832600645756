import {
  SET_INVITED_CUSTOMERS,
  SET_CUSTOMERS_INVITE_METHOD,
  SET_INVITED_HOME,
  SET_INVITATION_TYPE,
  SET_HOMES_VIEW_MODE
} from './types';

export function setInvitedCustomers(invitedCustomers) {
  return {
    type: SET_INVITED_CUSTOMERS,
    payload: { invitedCustomers }
  };
}

export function setCustomersInviteMethod(inviteMethod) {
  return {
    type: SET_CUSTOMERS_INVITE_METHOD,
    payload: { inviteMethod }
  };
}

export function setInvitedHome(invitedHome) {
  return {
    type: SET_INVITED_HOME,
    payload: { invitedHome }
  };
}

export function setInvitationType(invitationType) {
  return {
    type: SET_INVITATION_TYPE,
    payload: Promise.resolve({ invitationType })
  };
}

export function setHomesViewMode(homesViewMode) {
  return {
    type: SET_HOMES_VIEW_MODE,
    payload: { homesViewMode }
  };
}
