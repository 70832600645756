import React, { Component } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { hasFormValues } from '../../../../../../utils/formUtils';
import { saveElevation, getElevations } from '../../../../../../store/actions/elevationActions';
import { ModalNames } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import { parseBoolean } from '../../../../../../utils/parserUtils';
import { stripToEmpty } from '../../../../../../utils/stringUtils';
import { getError } from '../../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';

const i18nOpts = { scope: 'components.admin.models.show.elevations.formModal.index' };

function onClose() {
  ModalService.close(ModalNames.ELEVATION_FORM);
}

class FormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      image: undefined,
      saving: false,
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { opened } = this.props;
    if (prevProps.opened !== opened && opened) this.init();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onSave() {
    const { props } = this;
    const { form, image } = this.state;
    const { model } = this.props;

    const input = {
      ...form,
      name: stripToEmpty(form.name),
      isDefault: parseBoolean(form.isDefault),
      modelId: model.id
    };

    this.setState({ saving: true });

    const variables = { input, image };
    props.saveElevation(variables)
      .then(() => {
        this.loadElevations();
        ModalService.close(ModalNames.ELEVATION_FORM);
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  onImageChange(event) {
    const image = event.target.files[0];
    this.setState({ image });
  }

  init() {
    const { params: { elevation } } = this.props;
    let form = {};
    if (elevation) {
      const {
        id, name, description, isDefault
      } = elevation;
      form = {
        id, name, description, isDefault
      };
    }

    this.setState({
      form, image: undefined, saving: false, error: null
    });
  }

  loadElevations() {
    const { props } = this;
    const { model } = this.props;

    const variables = { filter: { modelId: model.id } };
    props.getElevations(variables);
  }

  isNew() {
    const { form } = this.state;
    return !form.id;
  }

  render() {
    const {
      form, saving, error
    } = this.state;
    const { opened } = this.props;
    const hasValues = hasFormValues(form);
    const isNew = this.isNew();
    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    return (
      <Modal isOpen={opened}>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="elevationName">{i18n.t('name', i18nOpts)}</Label>
            <Input
              type="text"
              name="name"
              id="elevationName"
              value={form.name || ''}
              onChange={this.onTextChange}
              invalid={isInputInvalid(error, 'name')}
            />
            <InputError name="name" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="description">{i18n.t('description', i18nOpts)}</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              value={form.description || ''}
              onChange={this.onTextChange}
              rows={5}
              invalid={isInputInvalid(error, 'description')}
            />
            <InputError name="description" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="image">{i18n.t('image', i18nOpts)}</Label>
            <Input
              type="file"
              name="image"
              id="image"
              accept="image/*"
              onChange={this.onImageChange}
              invalid={isInputInvalid(error, 'image')}
            />
            <InputError name="image" error={error} />
          </FormGroup>

          <FormGroup check className="mb-3">
            <Label check>
              <Input
                type="checkbox"
                name="isDefault"
                id="isDefault"
                value={!parseBoolean(form.isDefault)}
                checked={parseBoolean(form.isDefault)}
                onChange={this.onTextChange}
              />
              {i18n.t('isDefault', i18nOpts)}
            </Label>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving || !hasValues}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onClose} disabled={saving}>
            {i18n.t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect((store) => ({
  model: store.models.model,
  elevation: store.elevations.elevation,
  opened: store.modals[ModalNames.ELEVATION_FORM]?.opened || false,
  params: store.modals[ModalNames.ELEVATION_FORM]?.params || {}
}), {
  saveElevation,
  getElevations
})(FormModal);
