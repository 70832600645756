import React from 'react';

const SignIcon = ({ ...props }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" color="#98A2B3" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.625 10.8H13.125C13.4702 10.8 13.75 10.4866 13.75 10.1C13.75 9.7134 13.4702 9.4 13.125 9.4H10.625C10.2798 9.4 10 9.7134 10 10.1C10 10.4866 10.2798 10.8 10.625 10.8ZM24.9514 9.8305C24.9197 9.7455 24.8739 9.6688 24.8165 9.6047L17.3173 1.20551C17.26 1.14125 17.1915 1.08998 17.1156 1.05443C17.04 1.01906 16.9585 1 16.875 1H8.75C6.6798 1.00256 5.0023 2.88142 5 5.2V24.8C5.0023 27.1186 6.6798 28.9974 8.75 29H21.25C23.3202 28.9974 24.9977 27.1186 25 24.8V10.1C25 10.0065 24.983 9.9152 24.9514 9.8305ZM17.5 3.38976L22.8663 9.4H19.1663C18.2463 9.3991 17.5008 8.5641 17.5 7.5338V3.38976ZM23.75 24.8C23.7482 26.3456 22.63 27.598 21.25 27.6H8.75C7.37 27.598 6.2518 26.3456 6.25 24.8V5.2C6.2518 3.65439 7.37 2.40197 8.75 2.4H16.25V7.5338C16.2521 9.3367 17.5565 10.7977 19.1663 10.8H23.75V24.8ZM10 15.7C10 16.0866 10.2798 16.4 10.625 16.4H19.375C19.7202 16.4 20 16.0866 20 15.7C20 15.3134 19.7202 15 19.375 15H10.625C10.2798 15 10 15.3134 10 15.7ZM19.375 20.6H10.625C10.2798 20.6 10 20.9134 10 21.3C10 21.6866 10.2798 22 10.625 22H19.375C19.7202 22 20 21.6866 20 21.3C20 20.9134 19.7202 20.6 19.375 20.6Z" fill="currentColor" stroke="currentColor" />
  </svg>
);

SignIcon.propTypes = {};

SignIcon.defaultProps = {};

export default SignIcon;
