import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import i18n from 'i18n-js';
import { HiChevronDown } from 'react-icons/hi';
import classnames from 'classnames';

import './styles.scss';
import {
  generateDatePickerSettings,
  getDatePickerRangeLabel,
  getDaysDifference
} from '../../../utils/dateUtils';

const i18nOpts = { scope: 'components.common.dateFilter.index' };

const DateFilter = ({ dateRange, onDatePicked, containerClassName }) => {
  const [labelRange, setLabelRange] = useState(i18n.t('last7Days', i18nOpts));
  const [dateRangeValue, setDateRangeValue] = useState(null);
  const dateRangePickerSettings = generateDatePickerSettings();

  useEffect(() => {
    setLabelRange(`Data from: ${getDaysDifference(dateRange)}`);
    setDateRangeValue(getDatePickerRangeLabel(dateRange));
  }, [dateRange]);

  return (
    <div className={classnames('date-filter d-flex align-items-center', containerClassName)}>
      <div className="text-right">
        <span>{labelRange}</span>
      </div>

      <DateRangePicker
        initialSettings={dateRangePickerSettings}
        onApply={onDatePicked}
      >
        <div className="d-flex btn btn-outline-secondary">
          <span className="mr-2">{dateRangeValue}</span>
          <HiChevronDown size={24} className="text-muted" />
        </div>
      </DateRangePicker>
    </div>
  );
};

DateFilter.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDatePicked: PropTypes.func,
  containerClassName: PropTypes.string
};

DateFilter.defaultProps = {
  onDatePicked: () => {},
  containerClassName: null
};

export default DateFilter;
