import {
  RELOAD_PROJECT_PACKAGES
} from './types';

export function reloadProjectPackages(reload) {
  return {
    type: RELOAD_PROJECT_PACKAGES,
    payload: { reload }
  };
}
