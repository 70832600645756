import React from 'react';

const SortableIcon = ({ ...props }) => (
  <svg width="9" height="22" viewBox="0 0 9 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.33333 14.6667C2.79357 14.6667 3.16667 14.2936 3.16667 13.8334C3.16667 13.3731 2.79357 13 2.33333 13C1.8731 13 1.5 13.3731 1.5 13.8334C1.5 14.2936 1.8731 14.6667 2.33333 14.6667Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.33333 8.83335C2.79357 8.83335 3.16667 8.46026 3.16667 8.00002C3.16667 7.53978 2.79357 7.16669 2.33333 7.16669C1.8731 7.16669 1.5 7.53978 1.5 8.00002C1.5 8.46026 1.8731 8.83335 2.33333 8.83335Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.33333 20.5C2.79357 20.5 3.16667 20.1269 3.16667 19.6667C3.16667 19.2064 2.79357 18.8334 2.33333 18.8334C1.8731 18.8334 1.5 19.2064 1.5 19.6667C1.5 20.1269 1.8731 20.5 2.33333 20.5Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.33333 14.6667C7.79357 14.6667 8.16667 14.2936 8.16667 13.8334C8.16667 13.3731 7.79357 13 7.33333 13C6.8731 13 6.5 13.3731 6.5 13.8334C6.5 14.2936 6.8731 14.6667 7.33333 14.6667Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.33333 8.83335C7.79357 8.83335 8.16667 8.46026 8.16667 8.00002C8.16667 7.53978 7.79357 7.16669 7.33333 7.16669C6.8731 7.16669 6.5 7.53978 6.5 8.00002C6.5 8.46026 6.8731 8.83335 7.33333 8.83335Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.33333 20.5C7.79357 20.5 8.16667 20.1269 8.16667 19.6667C8.16667 19.2064 7.79357 18.8334 7.33333 18.8334C6.8731 18.8334 6.5 19.2064 6.5 19.6667C6.5 20.1269 6.8731 20.5 7.33333 20.5Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.33337 3.16671C2.79361 3.16671 3.1667 2.79361 3.1667 2.33337C3.1667 1.87314 2.79361 1.50004 2.33337 1.50004C1.87313 1.50004 1.50004 1.87314 1.50004 2.33337C1.50004 2.79361 1.87313 3.16671 2.33337 3.16671Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.33337 3.16671C7.79361 3.16671 8.1667 2.79361 8.1667 2.33337C8.1667 1.87314 7.79361 1.50004 7.33337 1.50004C6.87313 1.50004 6.50004 1.87314 6.50004 2.33337C6.50004 2.79361 6.87313 3.16671 7.33337 3.16671Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

SortableIcon.propTypes = {};

SortableIcon.defaultProps = {};

export default SortableIcon;
