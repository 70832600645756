import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import CollapsibleFilterGroup from '../../../../../common/CollapsibleFilterGroup';
import ListItem from './ListItem';
import { CustomerReservationStatuses } from '../utils';
import useIsReserveNowEnabled from '../../../../../../hooks/useIsReserveNowEnabled';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.reservationProgressFilter.index' };

const ReservationProgressFilter = ({ filters, onChange }) => {
  const reservationStatuses = filters.reservationStatuses || [];
  const availableStatuses = Object.values(CustomerReservationStatuses);
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ reservationStatuses: _.xor(reservationStatuses, [value]) });
  };

  const filtersCount = useMemo(() => reservationStatuses.length, [filters]);

  if (!isReserveNowEnabled) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      {
        availableStatuses.map((status) => (
          <ListItem
            key={`reservation-status-${status}`}
            value={status}
            selectedValues={reservationStatuses}
            onChange={onToggle}
          />
        ))
      }
    </CollapsibleFilterGroup>
  );
};

ReservationProgressFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

ReservationProgressFilter.defaultProps = {
  onChange: () => {}
};

export default ReservationProgressFilter;
