import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdOutlineFileDownload } from 'react-icons/md';
import i18n from 'i18n-js';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { downloadPlaidFile, downloadPlaidFileAsync, getType } from './utils';

const PlaidDocument = ({ document }) => {
  const [downloading, setDownloading] = useState(false);

  const { idVerificationId, documentType, fileName } = document;

  const onDownload = () => {
    setDownloading(true);

    const type = getType(documentType);
    downloadPlaidFileAsync(idVerificationId, type)
      .then((fileUrl) => downloadPlaidFile(fileUrl, fileName))
      .catch((e) => toast.error(e.message))
      .finally(() => setDownloading(false));
  };

  if (downloading) return <Spinner size="sm" />;

  return (
    <div onClick={onDownload} role="button" aria-hidden className="text-primary" title={i18n.t('buttons.download')}>
      <MdOutlineFileDownload size="18" />
    </div>
  );
};

PlaidDocument.propTypes = {
  document: PropTypes.objectOf(PropTypes.any).isRequired
};

PlaidDocument.defaultProps = {};

export default PlaidDocument;
