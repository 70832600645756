import v from 'voca';

import api from '../../../../../../../api';

export function fetchBuildingModelsBathroomsAsync(companyId) {
  const variables = { companyId };

  return api.graphql(`
    query ListBuildingModels($filter: BuildingModelFilterInput) {
      items: listBuildingModels(filter: $filter) {
        id
        bathrooms
      }
    }
  `, variables)
    .then(({ data: { items = [] } }) => items.map((i) => i.bathrooms).filter((b) => !v.isBlank(b)));
}
