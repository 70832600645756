import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { isRecently } from '../../../../utils/dateUtils';
import { DateFormats } from '../../../../constants';
import DealActivityDate from '../../home/DealActivityDate';

const DealLastActivityDate = ({ deal }) => {
  const { home } = deal;

  return (
    <>
      {home ? (
        <DealActivityDate home={home} className="text-nowrap" />
      ) : (
        <div className={classNames('text-nowrap', { 'text-success': isRecently(deal.createdAt) })}>
          {moment(deal.createdAt).format(DateFormats.MMM_DD_YYYY)}
        </div>
      )}
    </>
  );
};

DealLastActivityDate.propTypes = {
  deal: PropTypes.objectOf(PropTypes.any).isRequired
};

DealLastActivityDate.defaultProps = {};

export default DealLastActivityDate;
