import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'reactstrap';

const getObjectProps = (objects, nonImportedTable) => {
  const firstElement = nonImportedTable ? objects[0].instance : objects[0];
  return Object.keys(firstElement);
};

const sanitizedErrors = (errors) => {
  if (Array.isArray(errors)) return errors.join('\n');
  if (typeof errors === 'object') return JSON.stringify(errors, null, 2);

  return errors;
};

const List = ({ title, objects, nonImportedTable }) => {
  if (!objects || objects.length === 0) return null;

  const headers = getObjectProps(objects, nonImportedTable);

  const getObjectValues = (object, rowIndex) => headers.map((op, colIndex) => {
    const principalObject = nonImportedTable ? object.instance : object;
    const sanitizedValue = principalObject[op];
    return (<td key={`value-${rowIndex}-${colIndex}`}>{sanitizedValue}</td>);
  });

  return (
    <div className="py-4">
      <h4>{title}</h4>
      <Table responsive bordered>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={`header-${nonImportedTable}-${header}`}>{header}</th>
            ))}
            {nonImportedTable && (<th>Errors</th>)}
          </tr>
        </thead>
        <tbody>
          {objects.map((o, rowIndex) => (
            <tr key={`${rowIndex}-${o.name}`}>
              {getObjectValues(o, rowIndex)}
              {nonImportedTable && (<td className="bg-danger">{sanitizedErrors(o.errors)}</td>)}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

List.propTypes = {
  objects: PropTypes.arrayOf(PropTypes.any),
  nonImportedTable: PropTypes.bool
};

List.defaultProps = {
  nonImportedTable: false,
  objects: []
};

export default List;
