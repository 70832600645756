import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import OfferDocumentToSign from '../../../../OfferDocumentToSign';
import { stripToNull } from '../../../../../../../utils/stringUtils';
import { getError } from '../../../../../../../utils/requestUtils';
import { saveOffer } from '../../../../../../../store/actions/offerActions';
import { setHomeAttributes } from '../../../../../../../store/actions/homeActions';
import { isOfferAccepted } from '../../../../../../../utils/offerUtils';
import ModalService from '../../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../../constants';

function onClose() {
  ModalService.close(ModalNames.DEAL);
}

const SignDocumentCard = ({
  home, currentCompany, ...props
}) => {
  const { isDocuSignConnected, isDocuSignGenericTemplateEnabled } = currentCompany;

  const [form, setForm] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [saving, setSaving] = useState(false);
  const { offer } = home;

  const onDocumentToSignChange = (documentId, templateId) => {
    setHasChanges(true);
    setForm((prevForm) => (
      { ...prevForm, documentToSignId: documentId, docuSignTemplateId: templateId }
    ));
  };

  const onSave = () => {
    setSaving(true);

    const input = {
      id: offer.id,
      documentToSignId: parseInt(form.documentToSignId, 10) || null,
      docuSignTemplateId: stripToNull(form.docuSignTemplateId)
    };

    const variables = { input };

    props.saveOffer(variables)
      .then(({ value: { data: { item } } }) => {
        props.setHomeAttributes(home.id, { offer: item });
        onClose();
      })
      .catch((e) => {
        const formattedError = getError(e);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (!isOfferAccepted(offer)) return;

    const formData = {
      documentToSignId: offer.documentToSign?.id || null,
      docuSignTemplateId: offer.docuSignTemplateId
    };
    setForm(formData);
  }, [offer?.status]);

  if (!home || !isDocuSignConnected || isDocuSignGenericTemplateEnabled) return null;

  return (
    <Card body className="p-3 mb-3">
      <OfferDocumentToSign
        offer={offer}
        form={form}
        onDocumentToSignChange={onDocumentToSignChange}
      />

      <div className="text-right">
        <Button color="primary" className="ml-3" onClick={onSave} disabled={saving || !hasChanges}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
      </div>
    </Card>
  );
};

SignDocumentCard.propTypes = {};

SignDocumentCard.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home,
  currentCompany: store.companies.currentCompany
}), {
  saveOffer,
  setHomeAttributes
})(SignDocumentCard);
