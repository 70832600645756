import React, { useEffect } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Spinner, Table } from 'reactstrap';
import { connect } from 'react-redux';

import { getForms, cleanForms } from '../../../../../../store/actions/formActions';
import ListEmpty from '../../../../../common/ListEmpty';
import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.integrations.tracking.forms.list.index' };

const Forms = ({
  forms, loading, currentCompany, ...props
}) => {
  const loadForms = () => {
    const variables = { filter: { companyId: currentCompany.id } };
    props.getForms(variables);
  };

  useEffect(() => {
    props.cleanForms()
      .then(() => loadForms());
  }, []);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
      </div>

      {loading && (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      )}

      <ListEmpty loading={loading} items={forms} />

      {forms.length > 0 && (
        <Table responsive bordered>
          <thead>
            <tr>
              <th>{i18n.t('name', i18nOpts)}</th>
              <th>{i18n.t('createdAt', i18nOpts)}</th>
              <th>{i18n.t('isMapped', i18nOpts)}</th>
              <th>{i18n.t('lastSubmittedAt', i18nOpts)}</th>
              <th>{i18n.t('formSubmissionsCount', i18nOpts)}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              forms.map((f) => (
                <ListItem form={f} key={`form-${f.id}`} />
              ))
            }
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default connect((store) => ({
  forms: store.forms.forms,
  loading: store.forms.getForms.loading,
  currentCompany: store.companies.currentCompany
}), {
  getForms,
  cleanForms
})(Forms);
