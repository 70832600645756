import React from 'react';
import { connect } from 'react-redux';

import Placeholder from './Placeholder';
import InfoCard from './InfoCard';
import { hasCustomerFinancialAmount } from '../../../Overview/VerificationSection/utils';

const FinancesVerified = ({ customer }) => {
  const hasFinancialAmount = hasCustomerFinancialAmount(customer);

  return (
    <div className="mt-4">
      {hasFinancialAmount ? (
        <InfoCard />
      ) : (
        <Placeholder />
      )}
    </div>
  );
};

FinancesVerified.propTypes = {};

FinancesVerified.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
}))(FinancesVerified);
