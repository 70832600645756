import React from 'react';
import PropTypes from 'prop-types';
import { RiBankLine } from 'react-icons/ri';
import v from 'voca';

const BankAccount = ({ paymentMethodDetails }) => {
  const { last4 } = paymentMethodDetails || {};

  if (v.isBlank(last4)) return null;

  return (
    <div>
      <RiBankLine />
      <span className="mx-1 align-sub">****</span>
      {last4}
    </div>
  );
};

BankAccount.propTypes = {
  paymentMethodDetails: PropTypes.objectOf(PropTypes.any).isRequired
};

BankAccount.defaultProps = {};

export default BankAccount;
