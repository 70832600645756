import React, {
  useEffect, useState
} from 'react';
import { connect } from 'react-redux';
import {
  Form, FormGroup, Label, Input, Row, Col, Button, Spinner
} from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import v from 'voca';
import { toast } from 'react-toastify';
import { BiTrash } from 'react-icons/bi';

import { savePackage, getPackage, deletePackage } from '../../../../store/actions/packageActions';
import { getParam } from '../../../../utils/paramsUtils';
import { hasFormValues } from '../../../../utils/formUtils';
import DeleteButton from '../../../global/DeleteButton';
import { parseToFloat } from '../../../../utils/parserUtils';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { stripToEmpty } from '../../../../utils/stringUtils';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import { confirmDelete } from '../../../global/confirmAlert';
import { buildPackagesPath, isMultiFamilyPage } from '../../../../utils/multiFamilyUtils';
import { CKEditor } from '../../../ui';

const i18nOpts = { scope: 'components.admin.packages.form.index' };

function onCancel() {
  navigate(buildPackagesPath());
}

const PackageForm = ({
  package: specPackage, currentCompany, ...props
}) => {
  const [form, setForm] = useState({ });
  const [saving, setSaving] = useState(false);
  const [image, setImage] = useState(undefined);
  const [error, setError] = useState(null);
  const id = parseInt(getParam('id'), 10);
  const isNew = !id;

  useEffect(() => {
    setForm({ id });
    loadPackage();
  }, []);

  useEffect(() => {
    if (specPackage && !isNew) setForm(specPackage);
  }, [specPackage]);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const input = {
      name: stripToEmpty(form.name),
      description: stripToEmpty(form.description),
      basePrice: parseToFloat(form.basePrice),
      isMultifamily: isMultiFamilyPage()
    };

    if (isNew) input.companyId = currentCompany.id;
    else input.id = form.id;

    setSaving(true);

    const variables = { input, image };
    props.savePackage(variables)
      .then(() => navigate(buildPackagesPath()))
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const onDelete = () => {
    props.deletePackage(specPackage.id)
      .then(() => navigate(buildPackagesPath()));
  };

  const loadPackage = () => {
    if (isNew) return;

    props.getPackage(id)
      .then(() => {})
      .catch(() => navigate(buildPackagesPath()));
  };

  const getPreviewImageUrl = () => {
    if (image === null) return null;
    return getLocalImageUrl(image) || getImageUrl();
  };

  const getImageUrl = () => {
    if (!specPackage) return null;

    return specPackage.id === id ? specPackage.imageUrl : null;
  };

  const onDeleteImage = () => {
    setImage(null);
  };

  const hasValues = hasFormValues(form);
  const previewImageUrl = getPreviewImageUrl();

  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

  return (
    <div>
      <Helmet title={title} />

      <h2 className="mb-4">{title}</h2>

      <Form onSubmit={onSubmit}>
        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="packageName">{i18n.t('name', i18nOpts)}</Label>
              <Input
                type="text"
                name="name"
                id="packageName"
                value={form.name || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'name')}
              />
              <InputError name="name" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="image">{i18n.t('image', i18nOpts)}</Label>
              {!v.isBlank(previewImageUrl) && (
                <div className="mb-2 position-relative">
                  <img src={previewImageUrl} alt="" className="w-100" />
                  <button
                    type="button"
                    className="text-danger rounded-circle bg-dark delete-icon"
                    onClick={() => confirmDelete(onDeleteImage)}
                    title={i18n.t('buttons.delete')}
                  >
                    <BiTrash size="1.1rem" />
                  </button>
                </div>
              )}
              <Input
                type="file"
                name="image"
                id="image"
                accept="image/*"
                onChange={onImageChange}
                invalid={isInputInvalid(error, 'image')}
              />
              <InputError name="image" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="2" md="3" sm="4" xs="6">
            <FormGroup>
              <Label for="basePrice">{i18n.t('basePrice', i18nOpts)}</Label>
              <Input
                type="number"
                name="basePrice"
                id="basePrice"
                value={form.basePrice || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'basePrice')}
              />
              <InputError name="basePrice" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup className="mb-5">
          <Label for="description">{i18n.t('description', i18nOpts)}</Label>
          <CKEditor
            data={form.description || ''}
            name="description"
            onTextChange={onTextChange}
          />
        </FormGroup>

        <Button color="primary" className="mr-3" disabled={saving || !hasValues}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onCancel} className="mr-3">
          {i18n.t('buttons.cancel')}
        </Button>
        {!isNew && (
          <DeleteButton onDelete={onDelete} />
        )}
      </Form>
    </div>
  );
};

export default connect((store) => ({
  package: store.packages.package,
  currentCompany: store.companies.currentCompany
}), {
  savePackage,
  getPackage,
  deletePackage
})(PackageForm);
