import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'reactstrap';
import i18n from 'i18n-js';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

import styles from './SearchBar.module.scss';
import SearchIcon from '../../../assets/icons/SearchIcon';

const i18nOpts = { scope: 'components.common.searchBar.index' };

const SearchBar = ({
  onSubmit, inputProps, searchFields = 'name', autoSubmit
}) => {
  const [q, setQuery] = useState(null);
  const formRef = useRef(null);
  const timerRef = useRef(null);

  const size = inputProps.bsSize || 'lg';
  let inputSizeStyle = null;
  let iconSizeStyle = null;
  let clearIconSizeStyle = null;
  let inputWithClearIconStyle = styles.searchFieldWithClearIcon;
  if (size === 'md') {
    inputSizeStyle = styles.searchFieldMd;
    iconSizeStyle = styles.searchIconMd;
    clearIconSizeStyle = styles.clearIconMd;
    inputWithClearIconStyle = styles.searchFieldWithClearIconMd;
  } else if (size === 'sm') {
    inputSizeStyle = styles.searchFieldSm;
    iconSizeStyle = styles.searchIconSm;
    clearIconSizeStyle = styles.clearIconSm;
    inputWithClearIconStyle = styles.searchFieldWithClearIconSM;
  }

  const onTextChange = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const onClearText = () => {
    setQuery('');
  };

  useEffect(() => {
    if (q !== null) {
      if (timerRef.current) clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        if (formRef.current) formRef.current.requestSubmit();
      }, 500);
    }
  }, [q]);

  useEffect(() => () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  }, []);

  return (
    <div className={styles.searchBar}>
      <SearchIcon className={classNames(styles.searchIcon, iconSizeStyle)} />

      <Form innerRef={formRef} onSubmit={(e) => onSubmit(e, q)}>
        <Input
          type="text"
          name="q"
          placeholder={i18n.t('placeholder', { ...i18nOpts, fields: searchFields })}
          bsSize="lg"
          className={classNames(
            styles.searchField, inputSizeStyle, { [inputWithClearIconStyle]: autoSubmit }
          )}
          onChange={onTextChange}
          value={q || ''}
          {...inputProps}
        />
      </Form>

      {(q && autoSubmit) && (
        <MdClose
          role="button"
          aria-hidden
          onClick={onClearText}
          className={classNames(styles.clearIcon, clearIconSizeStyle)}
        />
      )}
    </div>
  );
};

SearchBar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  inputProps: PropTypes.objectOf(PropTypes.any),
  searchFields: PropTypes.string,
  autoSubmit: PropTypes.bool
};

SearchBar.defaultProps = {
  inputProps: {},
  searchFields: 'name',
  autoSubmit: true
};

export default SearchBar;
