import React, { Component } from 'react';

class MultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false
    };
  }

  componentDidMount() {
    this.MultiSelect = require('multiselect-react-dropdown');
    this.setState({ mounted: true });
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return null;

    const { ...extraProps } = this.props;

    return (
      <div className="multi-select-container">
        <this.MultiSelect.Multiselect
          {...extraProps}
        />
      </div>
    );
  }
}

MultiSelect.propTypes = {};

MultiSelect.defaultProps = {};

export default MultiSelect;
