import React from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import SubMenu from '../components/SubMenu';
import { Menus } from '../utils';
import HomesSideBar from '../HomesSideBar';
import HomesIcon from '../../../../assets/icons/sidebar-nav/homesIcon';

const i18n0pts = { scope: 'layout.admin.sidebar.index.menus' };

const HomesMenu = ({ currentCompany }) => {
  const { enableHomeSales, enableHomeRental } = currentCompany;

  let enableSingleFamily = false;
  let enableMultiFamily = false;
  if (enableHomeSales) {
    enableSingleFamily = currentCompany.enableSingleFamilyHomes;
    enableMultiFamily = currentCompany.enableMultiFamilyHomes;
  } else if (enableHomeRental) {
    enableSingleFamily = currentCompany.enableSingleFamilyHomesRental;
    enableMultiFamily = currentCompany.enableMultiFamilyHomesRental;
  }

  if (!enableSingleFamily && !enableMultiFamily) return null;

  return (
    <SubMenu
      title={i18n.t('homes', i18n0pts)}
      menu={Menus.HOMES}
      IconElement={<HomesIcon />}
    >
      <HomesSideBar enableSingleFamily={enableSingleFamily} enableMultiFamily={enableMultiFamily} />
    </SubMenu>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(HomesMenu);
