import React from 'react';
import PropTypes from 'prop-types';

import { UnitColumns } from '../../../utils';
import SuiteNumber from './SuiteNumber';
import Floor from './Floor';
import Bedrooms from './Bedrooms';
import Bathrooms from './Bathrooms';
import Project from './Project';
import BuildingModel from './BuildingModel';
import Size from './Size';
import Availability from './Availability';

const CustomColumnValue = ({ column, unit }) => {
  switch (column) {
    case UnitColumns.SUITE_NUMBER:
      return <SuiteNumber unit={unit} />;

    case UnitColumns.FLOOR:
      return <Floor unit={unit} />;

    case UnitColumns.BEDROOMS:
      return <Bedrooms unit={unit} />;

    case UnitColumns.BATHROOMS:
      return <Bathrooms unit={unit} />;

    case UnitColumns.PROJECT:
      return <Project unit={unit} />;

    case UnitColumns.BUILDING_MODEL:
      return <BuildingModel unit={unit} />;

    case UnitColumns.SIZE:
      return <Size unit={unit} />;

    case UnitColumns.AVAILABILITY:
      return <Availability unit={unit} />;

    default:
      return null;
  }
};

CustomColumnValue.propTypes = {
  column: PropTypes.oneOf(Object.values(UnitColumns)).isRequired,
  unit: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomColumnValue.defaultProps = {};

export default CustomColumnValue;
