import React from 'react';
import v from 'voca';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import styles from './HomeInfo.module.scss';
import { formatCurrency, isValidAmount } from '../../../../../../../utils/currencyUtils';
import { DEFAULT_IMAGE } from '../../../../../../../constants';
import { getTotalBaths, getTotalBeds, getTotalPrice } from '../../../../../../../utils/quickPossessionUtils';
import Size from '../../../../../../global/home/Size';
import Beds from '../../../../../../global/home/Beds';
import Baths from '../../../../../../global/home/Baths';

const i18nOpts = { scope: 'components.admin.quickPossessions.components.quickPossessionFormModal.confirm.homeInfo.index' };

const HomeInfo = ({ home }) => {
  const {
    model = {},
    customPrice,
    customBaths,
    customBeds,
    address,
    description,
    squareFootage: size,
    url: homeUrl,
    floorOptions = {},
    image = []
  } = home;
  const { name, defaultImageUrl: modelImageUrl } = model;
  const { imageUrl: homeImageUrl } = image[0] || {};

  const price = customPrice || getTotalPrice(home, floorOptions);
  const imageUrl = homeImageUrl || modelImageUrl || DEFAULT_IMAGE;
  const baths = customBaths || getTotalBaths(home, floorOptions);
  const beds = customBeds || getTotalBeds(home, floorOptions);

  return (
    <div>
      <div className={classNames(styles.imageContainer, 'mt-3')}>
        <img src={imageUrl} alt="" />
      </div>

      <div className="d-flex align-items-center flex-wrap gap-3 mt-3 mb-2">
        <div className="font-30 font-weight-600 line-height-38">{name}</div>
        {isValidAmount(price) && (
          <div className="font-24 font-weight-500">{formatCurrency(price)}</div>
        )}
        <div className="d-flex align-items-center gap-2">
          <Size size="lg">{size}</Size>
          <Beds size="lg">{beds}</Beds>
          <Baths size="lg">{baths}</Baths>
        </div>
      </div>

      {!v.isBlank(address) && (
        <div className="font-16 font-weight-500">{address}</div>
      )}

      {!v.isBlank(homeUrl) && (
        <div className="font-16">{homeUrl}</div>
      )}

      {!v.isBlank(description) && (
        <div className={classNames('p-3 mt-4', styles.descriptionContainer)}>
          <span className="font-20 font-weight-500">{i18n.t('description', i18nOpts)}</span>
          <span className="font-16 ml-2">{description}</span>
        </div>
      )}
    </div>
  );
};

HomeInfo.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired
};

HomeInfo.defaultProps = {};

export default HomeInfo;
