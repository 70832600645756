import momentTz from 'moment-timezone';
import api from '../../../../api';
import { getLeadGenerationQuery } from '../../../../graphql';

export function fetchMasterAdminMetrics(customDateRange) {
  const timezone = momentTz.tz.guess();
  const reportFilter = {
    customDateRange,
    timezone
  };
  const variables = { reportFilter };

  return api.graphql(getLeadGenerationQuery, variables)
    .then(({ data: { results } }) => Promise.resolve(results));
}
