import React, { Component } from 'react';
import {
  Card, CardBody, CardImg, DropdownToggle, DropdownMenu, DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';

import './styles.scss';
import { DEFAULT_IMAGE } from '../../../../../constants';
import api from '../../../../../api';
import { deleteFloorOptionImageQuery } from '../../../../../graphql';

class ListItem extends Component {
  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    const { floorOptionImage, onDelete } = this.props;

    const variables = { id: floorOptionImage.id };
    api.graphql(deleteFloorOptionImageQuery, variables)
      .then(() => onDelete(floorOptionImage))
      .catch(() => {});
  }

  render() {
    const { floorOptionImage } = this.props;

    if (!floorOptionImage) return null;

    const imageUrl = floorOptionImage.imageUrl || DEFAULT_IMAGE;

    return (
      <Card className="floor-option-image-card">
        <CardImg top width="100%" src={imageUrl} alt="" className="mb-0 floor-option-image-image" />
        <CardBody className="p-2">
          <div className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="px-2">
                <i className="fas fa-ellipsis-v text-primary" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem className="text-danger" onClick={this.onDelete}>
                  {i18n.t('buttons.delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardBody>
      </Card>
    );
  }
}

ListItem.propTypes = {
  floorOptionImage: PropTypes.objectOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func
};

ListItem.defaultProps = {
  onDelete: () => {}
};

export default ListItem;
