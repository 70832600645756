import { ORIENTATIONS } from '../constants';

export default ({
  object, startPoint, mouse, orientation
}) => {
  const {
    objectX, objectY, clientX, clientY
  } = startPoint;

  let { width, height } = startPoint;
  let x;
  let y;
  switch (orientation) {
    case ORIENTATIONS.topLeft:
      width += objectX - mouse.x;
      height += objectY - mouse.y;
      x = width > 0 ? mouse.x : mouse.x + width;
      y = height > 0 ? mouse.y : mouse.y + height;
      break;
    case ORIENTATIONS.topRight:
      width += mouse.x - clientX;
      height += objectY - mouse.y;
      x = objectX;
      y = height > 0 ? objectY + (mouse.y - clientY) : objectY;
      break;
    case ORIENTATIONS.bottomLeft:
      width += objectX - mouse.x;
      height = (mouse.y - objectY);
      x = width > 0 ? mouse.x : '';
      y = width > 0 ? objectY : 0;
      break;
    case ORIENTATIONS.bottomRight:
    default:
      width += mouse.x - clientX;
      height += mouse.y - clientY;
      x = width > 0 ? objectX : objectX + width;
      y = height > 0 ? objectY : objectY + height;
      break;
  }

  return {
    ...object,
    x,
    y,
    width: Math.abs(width),
    height: Math.abs(height)
  };
};
