import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const EditableText = ({
  initialValue, children, inputProps, ...props
}) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);
  const { className = '', ...extraInputProps } = inputProps;

  const onEdit = () => {
    setEditing(true);
  };

  const onTextChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
  };

  const onBlur = () => {
    save();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    save();
  };

  const save = () => {
    setEditing(false);
    props.onChange(value);
  };

  useEffect(() => {
    if (!editing) return;

    inputRef.current?.focus();
  }, [editing]);

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Input
          innerRef={inputRef}
          value={value || ''}
          onChange={onTextChange}
          onBlur={onBlur}
          className={classNames({ 'd-none': !editing }, className)}
          {...extraInputProps}
        />
      </form>

      <div
        onClick={onEdit}
        role="button"
        aria-hidden
        className={classNames('editable-text-container', 'align-items-center', { 'd-none': editing, 'd-flex': !editing })}
      >
        {children}
      </div>
    </div>
  );
};

EditableText.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  inputProps: PropTypes.objectOf(PropTypes.any)
};

EditableText.defaultProps = {
  initialValue: '',
  onChange: () => {},
  inputProps: {}
};

export default EditableText;
