import i18n from 'i18n-js';

import { TrackingSources } from '../../../../../constants';
import { parseDuration } from '../../../../../utils/dateUtils';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.visit.utils' };

export function getVisitDescription(visitorSession) {
  const { visitor } = visitorSession;
  const [minutes, seconds] = parseDuration(visitorSession.activeTime);

  const visitType = TrackingSources.WEBTRACKER === visitor.trackingSource
    ? i18n.t('websiteVisit', i18nOpts) : i18n.t('ownlyVisit', i18nOpts);
  const descriptions = [visitType];

  if (minutes > 0 || seconds > 0) {
    descriptions.push(i18n.t('of', i18nOpts));
    if (minutes > 0) descriptions.push(i18n.t('minutes', { ...i18nOpts, count: minutes }));
    if (seconds > 0) descriptions.push(i18n.t('seconds', { ...i18nOpts, count: seconds }));
  }
  return descriptions.join(' ');
}
