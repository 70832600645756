import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { DEFAULT_IMAGE } from '../../../../constants';
import FormDropdown from '../components/FormDropdown';
import FormDropdownItem from '../components/FormDropdownItem';
import { getModelElevationPrice } from '../../../../utils/priceRuleUtils';

const i18nOpts = { scope: 'components.global.quickPossessionForm.elevationDropdown.index' };

const ElevationDropdown = ({ form, onChange, elevations }) => {
  const { elevation, community, model } = form;

  const onSelect = (newSelectedElevation) => {
    onChange({ elevation: newSelectedElevation });
  };

  return (
    <FormDropdown value={elevation?.name} placeholder={i18n.t('label', i18nOpts)}>
      {
        elevations.map((e) => {
          const imageUrl = e.imageUrl || DEFAULT_IMAGE;
          const price = getModelElevationPrice(community, model, e);

          return (
            <FormDropdownItem
              key={`elevation-option-${e.id}`}
              onClick={() => onSelect(e)}
              imageUrl={imageUrl}
              name={e.name}
              price={price}
            />
          );
        })
      }
    </FormDropdown>
  );
};

ElevationDropdown.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  elevations: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

ElevationDropdown.defaultProps = {
  onChange: () => {}
};

export default ElevationDropdown;
