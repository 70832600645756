import {
  SAVE_PRODUCT_TYPE, DELETE_PRODUCT_TYPE, GET_PRODUCT_TYPES,
  SET_PRODUCT_TYPES, GET_PRODUCT_TYPE, SET_PRODUCT_TYPES_VIEW_MODE
} from './types';
import {
  createProductTypeQuery, deleteProductTypeQuery, listProductTypesQuery, updateProductTypeQuery,
  getProductTypeQuery
} from '../../graphql';
import api from '../../api';

export function getProductTypes(variables = {}) {
  return {
    type: GET_PRODUCT_TYPES,
    payload: api.graphql(listProductTypesQuery, variables)
  };
}

export function getProductType(id) {
  return {
    type: GET_PRODUCT_TYPE,
    payload: api.graphql(getProductTypeQuery, { id })
  };
}

export function saveProductType(variables = {}) {
  const query = variables.input.id ? updateProductTypeQuery : createProductTypeQuery;
  return {
    type: SAVE_PRODUCT_TYPE,
    payload: api.graphql(query, variables)
  };
}

export function deleteProductType(id) {
  return {
    type: DELETE_PRODUCT_TYPE,
    payload: api.graphql(deleteProductTypeQuery, { id })
  };
}

export function setProductTypes(productTypes) {
  return {
    type: SET_PRODUCT_TYPES,
    payload: { productTypes }
  };
}

export function setProductTypesViewMode(viewMode) {
  return {
    type: SET_PRODUCT_TYPES_VIEW_MODE,
    payload: { viewMode }
  };
}
