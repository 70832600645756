import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import useReserveNowWorkflowSteps
  from '../../../../hooks/useReserveNowWorkflowSteps';
import { DateFormats } from '../../../../constants';
import { getDealRecentActivityDate } from './utils';
import { isRecently } from '../../../../utils/dateUtils';

const DealActivityDate = ({ home, className }) => {
  const journeySteps = useReserveNowWorkflowSteps();

  const lastActivityDate = useMemo(() => getDealRecentActivityDate(home, journeySteps), [home]);

  if (!lastActivityDate) return null;

  return (
    <div className={classNames({ 'text-success': isRecently(lastActivityDate) }, className)}>
      {moment(lastActivityDate).format(DateFormats.MMM_DD_YYYY)}
    </div>
  );
};

DealActivityDate.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string
};

DealActivityDate.defaultProps = {
  className: null
};

export default DealActivityDate;
