import i18n from 'i18n-js';
import React from 'react';
import moment from 'moment';

import {
  DateFormats,
  EMAIL_SMS_VERIFICATION,
  ReserveStepTypes,
} from '../../../../../constants';
import EmailVerificationIcon
  from '../../../../../assets/icons/verification-progress/EmailVerificationIcon';
import api from '../../../../../api';
import { getCustomerQuery } from '../../../../../graphql';
import OfferIcon from '../../../../../assets/icons/reservation-progress/OfferIcon';
import SignIcon from '../../../../../assets/icons/reservation-progress/SignIcon';
import DepositIcon from '../../../../../assets/icons/reservation-progress/DepositIcon';
import { isReserveNowStepCompleted } from '../../../../../utils/offerUtils';

const i18nOpts = { scope: 'components.global.customer.customerProgressPopover.reservationContent.utils' };

export function getReserveNowStep(reserveNowStep, customer) {
  const {
    isEmailVerified, companyContact, activeHome
  } = customer;
  let isCompleted = isReserveNowStepCompleted(reserveNowStep.type, activeHome);

  const { offer, deposit } = activeHome || {};

  let title = reserveNowStep.label;
  let Icon;
  let completedAt = null;

  switch (reserveNowStep.type) {
    case ReserveStepTypes.OFFER:
      Icon = OfferIcon;
      completedAt = offer?.statusChangeDates?.acceptedAt;
      break;

    case ReserveStepTypes.SIGN:
      Icon = SignIcon;
      completedAt = offer?.signStatusChangeDates?.acceptedAt;
      break;

    case ReserveStepTypes.DEPOSIT:
      Icon = DepositIcon;
      completedAt = deposit?.statusChangeDates?.acceptedAt;
      break;

    case EMAIL_SMS_VERIFICATION:
      title = i18n.t('emailSmsVerification', i18nOpts);
      Icon = EmailVerificationIcon;
      isCompleted = isEmailVerified;
      completedAt = companyContact.createdAt;
      break;

    default:
      return {};
  }

  return {
    type: reserveNowStep.type,
    title,
    description: isCompleted ? moment(completedAt)
      .format(DateFormats.MMM_DD_YYYY) : i18n.t('notVerified', i18nOpts),
    Icon: <Icon width={28} height={28} />,
    isCompleted
  };
}

export async function fetchCustomerAsync(id, companyId) {
  const variables = {
    id,
    withTotalVisits: true,
    withAverageVisitTime: true,
    withPropertyInfo: true,
    withActiveHome: true,
    withActiveOffer: true,
    companyId
  };

  return api.graphql(getCustomerQuery, variables)
    .then(({ data: { item } }) => item);
}
