import React from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import { DropdownItem } from 'reactstrap';
import { HiOutlineDotsVertical } from 'react-icons/hi';

import BaseActionsMenu from '../../../../../global/ActionsMenu';
import { deleteCompanyContactAsync } from './utils';
import { isAdmin, isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';
import { showCustomerForm, resetCustomerVerifications } from '../../../../../../store/actions/customerActions';
import { confirmDelete } from '../../../../../global/confirmAlert';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames, SalesInvitationStartTypes } from '../../../../../../constants';
import useIsVerifiedEnabled from '../../../../../../hooks/useIsVerifiedEnabled';
import useIsReserveNowEnabled from '../../../../../../hooks/useIsReserveNowEnabled';

const i18nOpts = { scope: 'components.admin.customers.show.components.actionsMenu.index' };

const ActionsMenu = ({ customer, currentCompany, ...props }) => {
  const { isIdVerified, isFinancialVerified, isHomeVerified } = customer;
  const isCustomerVerified = isIdVerified || isHomeVerified || isFinancialVerified;
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const onEdit = () => {
    document.getElementById('customer-container').classList.toggle('show-overview-container', false);
    props.showCustomerForm(true);
  };

  const onDelete = () => {
    const { companyContact } = customer;
    deleteCompanyContactAsync(companyContact.id)
      .then(() => navigate('/admin/customers'))
      .catch((e) => toast.error(e.message));
  };

  const onReset = () => {
    confirmDelete(() => {
      const variables = { id: customer.id, companyId: currentCompany.id };
      props.resetCustomerVerifications(variables)
        .catch(() => {});
    }, null, i18n.t('resetMessage', i18nOpts));
  };

  const onInvite = () => {
    ModalService.open(ModalNames.SALES_INVITATION, {
      startType: SalesInvitationStartTypes.FROM_CUSTOMER,
      customer
    });
  };

  if (!isAdmin()) return null;

  return (
    <div>
      <BaseActionsMenu
        item={customer}
        onEdit={onEdit}
        onDelete={onDelete}
        Icon={
          <HiOutlineDotsVertical className="col-gray-600" size="22" />
        }
      >
        {(isVerifiedEnabled || isReserveNowEnabled) && (
          <DropdownItem onClick={onInvite}>
            {i18n.t('buttons.invite')}
          </DropdownItem>
        )}

        {(isMasterAdmin() || isTecSupport()) && isCustomerVerified && (
          <DropdownItem onClick={onReset} className="text-danger">
            {i18n.t('buttons.reset')}
          </DropdownItem>
        )}
      </BaseActionsMenu>
    </div>
  );
};

ActionsMenu.propTypes = {};

ActionsMenu.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}), {
  showCustomerForm,
  resetCustomerVerifications
})(ActionsMenu);
