import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup, Label, Input, Card
} from 'reactstrap';
import i18n from 'i18n-js';

import InputError, { isInputInvalid } from '../../../../ui/InputError';

const i18nOpts = { scope: 'components.admin.communities.form.salesRepresentative.index' };

const SalesRepresentative = ({ form, onTextChange, error }) => (
  <div className="mt-4">
    <h3 className="mb-3">{i18n.t('title', i18nOpts)}</h3>
    <Card body>
      <FormGroup>
        <Label for="salesContactName">{i18n.t('salesContactName', i18nOpts)}</Label>
        <Input
          type="text"
          name="salesContactName"
          id="salesContactName"
          value={form.salesContactName || ''}
          onChange={onTextChange}
          invalid={isInputInvalid(error, 'salesContactName')}
        />
        <InputError name="salesContactName" error={error} />
      </FormGroup>

      <FormGroup className="mb-0">
        <Label for="salesAddress">{i18n.t('salesAddress', i18nOpts)}</Label>
        <Input
          type="text"
          name="salesAddress"
          id="salesAddress"
          value={form.salesAddress || ''}
          onChange={onTextChange}
          invalid={isInputInvalid(error, 'salesAddress')}
        />
        <InputError name="salesAddress" error={error} />
      </FormGroup>
    </Card>
  </div>
);

SalesRepresentative.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any)
  ]),
};

SalesRepresentative.defaultProps = {
  onTextChange: () => {},
  error: null
};

export default SalesRepresentative;
