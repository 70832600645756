import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner, Table } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import api from '../../../../../../api';
import { deleteCommunityPhaseQuery, listCommunityPhases } from '../../../../../../graphql';
import ListEmpty from '../../../../../common/ListEmpty';
import { ModalNames } from '../../../../../../constants';
import { formatCurrency } from '../../../../../../utils/currencyUtils';
import ModalService from '../../../../../../services/ModalService';
import { isAdmin } from '../../../../../../utils/authUtils';
import ActionsMenu from '../../../../../global/ActionsMenu';

const i18nOpts = { scope: 'components.admin.communities.show.phases.list.index' };

async function fetchCommunityPhasesAsync(variables) {
  return api.graphql(listCommunityPhases, variables)
    .then(({ data }) => Promise.resolve(data.items));
}

async function deleteCommunityPhaseAsync(id) {
  return api.graphql(deleteCommunityPhaseQuery, { id })
    .then(({ data }) => Promise.resolve(data));
}

function onOpenModal(modalName, communityProductType) {
  ModalService.open(modalName, { communityProductType });
}

const List = ({ community, opened }) => {
  const [communityPhases, setPhaseCommunities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCommunityPhases();
  }, [opened]);

  const loadCommunityPhases = () => {
    setLoading(true);
    const { id: communityId } = community;
    const variables = {
      filter: { communityId },
    };

    fetchCommunityPhasesAsync(variables)
      .then((result) => {
        setPhaseCommunities(result);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onDelete = (id) => {
    deleteCommunityPhaseAsync(id)
      .then(() => loadCommunityPhases())
      .catch((e) => toast.error(e.message));
  };

  const onEdit = (communityPhase) => {
    ModalService.open(ModalNames.PHASE_COMMUNITY_FORM, { communityPhase });
  };

  return (
    <div>
      {isAdmin() && (
        <div className="d-flex justify-content-end">
          <button
            onClick={() => onOpenModal(ModalNames.PHASE_COMMUNITY_FORM, {})}
            className="btn btn-primary mb-3"
            type="button"
          >
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </button>
        </div>
      )}

      {loading && communityPhases.length === 0 && (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      )}

      <ListEmpty loading={loading} items={communityPhases} />

      {communityPhases.length > 0 && (
        <div className="table-responsive">
          <Table bordered>
            <thead>
              <tr>
                <th>{i18n.t('phase', i18nOpts)}</th>
                <th>{i18n.t('architecturalPrice', i18nOpts)}</th>
                {isAdmin() && (
                <th>&nbsp;</th>
                )}
              </tr>
            </thead>
            <tbody>
              {
              communityPhases.map((communityPhase) => (
                <tr key={`taxRate-${communityPhase.id}`}>
                  <td>{communityPhase.phase}</td>
                  <td>{formatCurrency(communityPhase.architecturalPrice)}</td>
                  {isAdmin() && (
                    <td className="text-right">
                      <ActionsMenu
                        item={communityPhase}
                        onDelete={onDelete}
                        onEdit={onEdit}
                      />
                    </td>
                  )}
                </tr>
              ))
            }
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default connect((store) => ({
  community: store.communities.community,
  opened: store.modals[ModalNames.PHASE_COMMUNITY_FORM]?.opened || false
}), {})(List);
