import React, { Component } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { ModalNames } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import ListItem from './ListItem';
import FileUploader from '../../../common/FileUploader';
import api from '../../../../api';
import {
  createFloorOptionImageQuery,
  listFloorOptionImagesQuery
} from '../../../../graphql';

const i18nOpts = { scope: 'components.global.floorsManager.floorOptionImagesModal.index' };

function onClose() {
  ModalService.close(ModalNames.FLOOR_OPTION_IMAGES);
}

class FloorOptionImagesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      floorOptionImages: [],
    };

    this.onDrop = this.onDrop.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { opened } = this.props;
    if (prevProps.opened !== opened && opened) this.init();
  }

  onDrop(images) {
    this.setState({ uploading: true });

    const promises = images.map((file) => this.uploadImage(file));
    Promise.all(promises)
      .finally(() => {
        this.setState({ uploading: false });
        this.loadImages();
      });
  }

  onDelete(floorOptionImage) {
    const { floorOptionImages } = this.state;
    const filteredFloorOptionImages = floorOptionImages
      .filter((foi) => foi.id !== floorOptionImage.id);
    this.setState({ floorOptionImages: filteredFloorOptionImages });
  }

  init() {
    this.setState({ floorOptionImages: [] }, this.loadImages);
  }

  uploadImage(image) {
    const { params: { floorOption } } = this.props;

    const input = {
      floorOptionId: floorOption.id
    };

    const variables = { input, image };
    return api.graphql(createFloorOptionImageQuery, variables);
  }

  loadImages() {
    const { params: { floorOption } } = this.props;

    if (!floorOption) return;

    const variables = { filter: { floorOptionId: floorOption.id } };
    api.graphql(listFloorOptionImagesQuery, variables)
      .then(({ data: { items: floorOptionImages } }) => {
        this.setState({ floorOptionImages });
      })
      .catch(() => {
        this.setState({ floorOptionImages: [] });
      });
  }

  render() {
    const { floorOptionImages, uploading } = this.state;
    const { opened, params: { floorOption } } = this.props;

    return (
      <Modal isOpen={opened}>
        <ModalHeader>{i18n.t('title', { ...i18nOpts, name: floorOption?.name })}</ModalHeader>

        <ModalBody>
          <Row>
            {
              floorOptionImages.map((foi) => (
                <Col key={`floor-option-image-${foi.id}`} xs="12" sm="6" md="4" lg="4">
                  <ListItem floorOptionImage={foi} onDelete={this.onDelete} />
                </Col>
              ))
            }
          </Row>

          <FileUploader onUpload={this.onDrop} uploading={uploading} />
        </ModalBody>

        <ModalFooter>
          <Button outline color="secondary" onClick={onClose}>
            {i18n.t('buttons.close')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect((store) => ({
  opened: store.modals[ModalNames.FLOOR_OPTION_IMAGES]?.opened || false,
  params: store.modals[ModalNames.FLOOR_OPTION_IMAGES]?.params || {}
}), {})(FloorOptionImagesModal);
