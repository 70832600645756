import React from 'react';
import Switch from 'rc-switch';
import PropTypes from 'prop-types';

import WorkflowItem from '../../components/WorkflowItem';
import { getVerifiedWorkflowTypeName } from '../../../../../../../../../utils/enumUtils';

const WorkflowStep = ({ step, onChangeStepSetting }) => {
  const onToggle = (field, checked) => {
    const newStepSetting = { ...step, [field]: checked };
    onChangeStepSetting(newStepSetting);
  };

  return (
    <WorkflowItem
      initialTitle={getVerifiedWorkflowTypeName(step.type)}
      enabled={step.enabled}
      onToggle={(checked) => onToggle('enabled', checked)}
    >
      <div className="mt-2 pl-4">
        <div className="d-table-cell">
          <div className="d-table-row">
            <div className="d-table-cell font-12">Optional</div>
            <div className="d-table-cell pl-2">
              <Switch
                checked={step.optional}
                onChange={(checked) => onToggle('optional', checked)}
                disabled={!step.enabled}
                className="rc-switch-sm"
              />
            </div>
          </div>
        </div>
      </div>
    </WorkflowItem>
  );
};

WorkflowStep.propTypes = {
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeStepSetting: PropTypes.func
};

WorkflowStep.defaultProps = {
  onChangeStepSetting: () => {}
};

export default WorkflowStep;
