import React, { useEffect, useMemo, useState } from 'react';
import Switch from 'rc-switch';
import PropTypes from 'prop-types';

import { ReactSortable } from 'react-sortablejs';
import WorkflowItem from '../../components/WorkflowItem';
import { ReserveStepTypes } from '../../../../../../../../../constants';
import { getVerifiedWorkflowTypeName } from '../../../../../../../../../utils/enumUtils';

const WorkflowStep = ({ step, onChangeStepSetting }) => {
  const [verifications, setVerifications] = useState([]);

  const onToggle = (field, checked) => {
    const newStepSetting = { ...step, [field]: checked };
    onChangeStepSetting(newStepSetting);
  };

  const onChangeTitle = (newTitle) => {
    const newStepSetting = { ...step, label: newTitle };
    onChangeStepSetting(newStepSetting);
  };

  const onToggleVerification = (verificationType, field, checked) => {
    const newVerifications = verifications.map((item) => (item.type === verificationType
      ? { ...item, [field]: checked }
      : item));
    const newStepSetting = { ...step, verifications: newVerifications };
    onChangeStepSetting(newStepSetting);
  };

  useEffect(() => {
    if (step.verifications?.length) setVerifications(step.verifications);
  }, [step.verifications]);

  const isTitleEditable = useMemo(() => (
    [ReserveStepTypes.OFFER, ReserveStepTypes.SIGN, ReserveStepTypes.DEPOSIT].includes(step.type)
  ), [step.type]);

  return (
    <WorkflowItem
      initialTitle={step.label}
      enabled={step.enabled}
      onToggle={(checked) => onToggle('enabled', checked)}
      onChangeTitle={(newTitle) => onChangeTitle(newTitle)}
      isTitleEditable={isTitleEditable}
    >
      <div className="mt-2 pl-4">
        {[ReserveStepTypes.SIGN, ReserveStepTypes.DEPOSIT].includes(step.type) && (
          <div className="d-table-cell">
            <div className="d-table-row">
              <div className="d-table-cell font-12">Autocomplete</div>
              <div className="d-table-cell pl-2">
                <Switch
                  checked={step.autocomplete}
                  onChange={(checked) => onToggle('autocomplete', checked)}
                  disabled={!step.enabled}
                  className="rc-switch-sm"
                />
              </div>
            </div>
          </div>
        )}

        {(step.type === ReserveStepTypes.OFFER && verifications.length > 0) && (
          <ReactSortable
            list={verifications}
            setList={setVerifications}
          >
            {verifications.map((verification) => (
              <WorkflowItem
                key={`workflow-step-verification-${verification.type}`}
                initialTitle={getVerifiedWorkflowTypeName(verification.type)}
                enabled={verification.enabled}
                onToggle={(checked) => onToggleVerification(verification.type, 'enabled', checked)}
                disabled={!step.enabled}
              >
                <div className="mt-2 pl-4">
                  <div className="d-table-cell">
                    <div className="d-table-row">
                      <div className="d-table-cell font-12">Optional</div>
                      <div className="d-table-cell pl-2">
                        <Switch
                          checked={verification.optional}
                          onChange={(checked) => onToggleVerification(verification.type, 'optional', checked)}
                          disabled={!verification.enabled}
                          className="rc-switch-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </WorkflowItem>
            ))}
          </ReactSortable>
        )}
      </div>
    </WorkflowItem>
  );
};

WorkflowStep.propTypes = {
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeStepSetting: PropTypes.func
};

WorkflowStep.defaultProps = {
  onChangeStepSetting: () => {}
};

export default WorkflowStep;
