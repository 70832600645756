import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const SocialShareIcon = ({ children }) => (
  <div className="social-share-icon">
    {children}
  </div>
);

SocialShareIcon.propTypes = {
  children: PropTypes.element.isRequired
};

export default SocialShareIcon;
