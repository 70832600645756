import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import v from 'voca';

import styles from './Baths.module.scss';
import BathIcon from '../../../../assets/icons/home/BathIcon';

const Baths = ({
  firstIcon, children, className, size
}) => {
  const sizeStyle = v.isBlank(size) ? null : styles[size];

  return (
    <div className={classNames(styles.container, 'text-nowrap gap-1', sizeStyle, className)}>
      {firstIcon && (<BathIcon />)}
      <span>{children || 0}</span>
      {!firstIcon && (<BathIcon />)}
    </div>
  );
};

Baths.propTypes = {
  firstIcon: PropTypes.bool,
  size: PropTypes.oneOf(['lg'])
};

Baths.defaultProps = {
  firstIcon: false,
  size: null
};

export default Baths;
