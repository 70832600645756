import React from 'react';
import { confirmAlert as baseConfirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import i18n from 'i18n-js';

import CustomDialog from './CustomDialog';

const i18nOpts = { scope: 'components.global.deleteButton.functions' };

export function confirmDelete(onDelete, afterConfirm = null, deleteMessage = null) {
  const title = i18n.t('title', i18nOpts);
  const message = deleteMessage || i18n.t('message', i18nOpts);

  const extraOptions = {
    title,
    message
  };

  confirmAlert(onDelete, extraOptions, { afterConfirm });
}

export function confirmAlert(onConfirm, extraOptions = {}, dialogProps = {}) {
  const options = {
    title: i18n.t('title', i18nOpts),
    message: null,
    customUI: ({ title, message, onClose }) => (
      <CustomDialog
        title={title}
        message={message}
        onConfirm={onConfirm}
        onClose={onClose}
        {...dialogProps}
      />
    ),
    closeOnEscape: true,
    closeOnClickOutside: true,
    overlayClassName: '',
    ...extraOptions,
  };

  baseConfirmAlert(options);
}
