import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';

import { fetchItemsAsync, fetchPriceRulesAsync } from '../utils';
import { listModelsQuery } from '../../../../graphql';
import { DEFAULT_IMAGE } from '../../../../constants';
import FormDropdown from '../components/FormDropdown';
import FormDropdownItem from '../components/FormDropdownItem';
import { buildNewModel } from './utils';

const i18nOpts = { scope: 'components.global.quickPossessionForm.modelDropdown.index' };

const ModelDropdown = ({ currentCompany, form, onChange }) => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const { model: selectedModel } = form;
  const [searchText, setSearchText] = useState('');

  const loadModels = () => {
    setLoading(true);

    fetchItemsAsync(listModelsQuery, currentCompany.id, null, { pageSize: null })
      .then((items) => setModels(items))
      .finally(() => setLoading(false));
  };

  const onSelect = (newSelectedModel) => {
    fetchPriceRulesAsync(newSelectedModel.id)
      .then((priceRules) => {
        onChange({ model: { ...newSelectedModel, priceRules } });
      });
  };

  const onNewModel = () => {
    onSelect({ ...buildNewModel(), name: searchText });
  };

  useEffect(() => {
    loadModels();
  }, []);

  const filteredModels = models.filter(
    (model) => model.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <FormDropdown
      value={selectedModel?.name}
      placeholder={i18n.t('label', i18nOpts)}
      isSearchable
      searchText={searchText}
      setSearchText={setSearchText}
      searching={!v.isBlank(searchText) && loading}
    >
      {v.isBlank(searchText) ? (
        <FormDropdownItem name={i18n.t('select.search')} disabled />
      ) : (
        <>
          <FormDropdownItem
            key="new-model-option"
            name={`${searchText} ${i18n.t('newModel', i18nOpts)}`}
            onClick={onNewModel}
          />

          {
            filteredModels.map((m) => {
              const imageUrl = m.imageUrl || DEFAULT_IMAGE;
              const { basePrice } = m;

              return (
                <FormDropdownItem
                  key={`model-option-${m.id}`}
                  onClick={() => onSelect(m)}
                  imageUrl={imageUrl}
                  name={m.name}
                  price={basePrice}
                />
              );
            })
          }
        </>
      )}
    </FormDropdown>
  );
};

ModelDropdown.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

ModelDropdown.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(ModelDropdown);
