import React from 'react';

const OwnlyDarkText = () => (
  <svg width="100" height="34" viewBox="0 0 100 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_217_1456)">
      <path d="M9.14506 8.61368H9.10937C6.61746 8.64052 4.46828 9.58475 2.72371 11.4122C0.979139 13.2397 0.108045 15.4624 0.136605 18.017C0.162786 20.5715 1.08386 22.7747 2.86651 24.5632C4.64203 26.3418 6.79834 27.2348 9.27359 27.2153H9.30929C11.8012 27.1885 13.9504 26.2467 15.6949 24.4168C17.4395 22.5893 18.3106 20.3666 18.2821 17.812C18.2559 15.2575 17.3348 13.0543 15.5521 11.2658C13.7766 9.48471 11.6179 8.59172 9.14269 8.61368H9.14506ZM9.14506 11.9197H9.16887C10.7802 11.9075 12.1749 12.4858 13.3173 13.6423C14.474 14.8012 15.0714 16.231 15.0904 17.895C15.1095 19.5443 14.5454 20.9839 13.4125 22.1696C12.2915 23.3554 10.9015 23.9678 9.28073 23.9873H9.25693C7.64564 23.9995 6.24618 23.4213 5.09423 22.2672C3.94467 21.1058 3.35442 19.681 3.33776 18.0316C3.3211 16.37 3.87803 14.9256 5.00141 13.7399C6.13431 12.5541 7.5314 11.9417 9.1522 11.9221L9.14506 11.9197Z" fill="#101828" />
      <path d="M45.0599 10.0751C45.0551 9.62131 44.9171 9.22605 44.6458 8.90155C44.3625 8.57217 44.0341 8.41357 43.5938 8.41357C43.1963 8.41845 42.8607 8.59412 42.5966 8.92839C42.3276 9.25777 42.1943 9.65791 42.1991 10.1142L42.3062 18.9075C42.2205 21.179 42.0396 21.6328 41.3732 22.4477C40.7687 23.1895 39.9238 23.5555 38.7861 23.5701H38.7694C37.8055 23.5774 36.9606 23.17 36.2633 22.3575C35.5564 21.5328 35.1899 20.5178 35.1756 19.3418L35.0637 10.2069C35.059 9.75063 34.9138 9.35537 34.6401 9.03574C34.3664 8.70636 34.0308 8.54045 33.6095 8.54533H33.6024C33.2073 8.55021 32.8669 8.72344 32.5909 9.06014C32.3219 9.39196 32.1886 9.78966 32.1934 10.2459L32.3052 19.3808C32.3195 20.5593 31.9792 21.5816 31.2914 22.4258C30.6083 23.2627 29.7705 23.6896 28.7971 23.7043C27.807 23.7165 26.9763 23.309 26.2718 22.4917C25.5245 21.6231 25.3008 20.7301 25.1842 19.4809L25.0723 10.3411C25.0675 9.88482 24.9224 9.48956 24.6463 9.1675C24.3654 8.84055 24.0275 8.67464 23.6443 8.67952H23.5943C23.2087 8.6844 22.8731 8.85763 22.597 9.19677C22.3281 9.5286 22.1948 9.9263 22.1996 10.3826L22.3114 19.5175C22.3424 21.9207 22.866 23.5872 24.0132 24.9218C25.2103 26.315 26.9549 27.103 28.8042 27.0884H28.8351C30.882 27.0616 32.5528 26.088 33.8047 24.1971C35.0851 26.0466 36.7631 26.9762 38.7956 26.9591H38.8266C40.7877 26.9347 42.4966 26.149 43.639 24.7485C44.9338 23.1651 45.1765 21.523 45.1646 19.2222L45.0528 10.0873L45.0599 10.0751Z" fill="#101828" />
      <path d="M62.4771 10.2678C60.9967 8.78682 59.2069 8.04266 57.1553 8.05974H57.1243C55.049 8.08413 53.2592 8.86733 51.8073 10.3923C50.3555 11.9147 49.6296 13.7666 49.6534 15.8942L49.7534 25.1389C49.7581 25.5854 49.9223 25.9684 50.2341 26.2685C50.5459 26.5808 50.9172 26.737 51.3408 26.7346H51.3385L51.3932 26.7321C51.8192 26.7272 52.1881 26.5613 52.4952 26.2417C52.8046 25.9318 52.9569 25.5463 52.9498 25.0998L52.8498 15.8551C52.8379 14.6328 53.2496 13.5665 54.0803 12.6833C54.9204 11.8025 55.9581 11.3487 57.16 11.3341H57.1767C58.362 11.3243 59.3973 11.7562 60.2517 12.6125C61.1014 13.4763 61.5369 14.5327 61.5512 15.7551L61.6512 24.9998C61.656 25.4463 61.8178 25.8294 62.1296 26.1295C62.4414 26.4418 62.8198 26.5979 63.253 26.5955H63.291C63.7266 26.5906 64.0979 26.4223 64.393 26.1051C64.7024 25.7952 64.8547 25.4097 64.8476 24.9632L64.7476 15.7185C64.7238 13.5909 63.9598 11.7561 62.4723 10.2678H62.4771Z" fill="#101828" />
      <path d="M92.2133 9.29188C92.2086 8.86246 92.0562 8.49892 91.7564 8.19881C91.4684 7.89871 91.1137 7.74988 90.6972 7.75475H90.6639C90.245 7.75963 89.8904 7.91579 89.5977 8.22321C89.3049 8.53064 89.1597 8.89662 89.1645 9.32604L89.2621 18.1974C89.274 19.4613 88.8527 20.5348 87.9935 21.4229C87.1438 22.3233 86.1109 22.7795 84.8923 22.7942C83.6618 22.8088 82.6075 22.3745 81.7292 21.4937C80.8629 20.6153 80.4226 19.5467 80.4083 18.2975L80.3107 9.42851C80.306 8.99909 80.1536 8.63555 79.8538 8.33545C79.5539 8.03534 79.1992 7.88651 78.7946 7.89139H78.7447C78.3377 7.89627 77.9902 8.05242 77.6951 8.35985C77.4023 8.66727 77.2571 9.03325 77.2619 9.46267L77.3595 18.3341C77.3833 20.4519 78.133 22.2452 79.611 23.7164C81.089 25.1974 82.8621 25.927 84.928 25.9026C86.5655 25.8855 88.0316 25.3731 89.3287 24.3654L89.3406 25.4975C89.3549 26.7346 88.9217 27.8105 88.0411 28.7231C87.17 29.6234 86.149 30.0796 84.9732 30.0943C84.0498 30.104 83.1906 29.8796 82.3933 29.4135C81.6079 28.9622 81.0152 28.3546 80.6106 27.5934C80.4321 27.2567 80.1703 27.0176 79.83 26.8761C79.4872 26.7443 79.1421 26.7443 78.7923 26.8712L78.7256 26.9053C78.2996 27.0566 78.0045 27.3518 77.845 27.7935C77.6736 28.2253 77.6951 28.645 77.9093 29.0598C78.5614 30.3383 79.5063 31.3435 80.7463 32.0852C82.0291 32.8586 83.4524 33.2368 85.0113 33.2197C86.9582 33.1978 88.6671 32.4292 90.1474 30.9141C91.6683 29.3647 92.418 27.547 92.3942 25.4634L92.2181 9.29188H92.2133Z" fill="#101828" />
      <path d="M72.5589 13.8325C72.5589 13.8325 72.5589 13.8374 72.5589 13.8399C72.5637 13.7935 72.566 13.7471 72.566 13.6983L72.4375 1.76491C72.4328 1.32085 72.2733 0.94023 71.9639 0.627925C71.6545 0.327821 71.2856 0.181428 70.8667 0.183868H70.8143C70.3931 0.188748 70.0289 0.3449 69.7267 0.654764C69.422 0.974388 69.2721 1.35745 69.2792 1.80395L69.4077 13.7374C69.4077 13.7837 69.4125 13.8277 69.4172 13.8716C69.4172 13.8789 69.4149 13.8862 69.4149 13.8935L69.5458 25.9099C69.5458 26.1417 69.7028 26.3345 70.0123 26.4882C70.3217 26.6492 70.6858 26.7273 71.1071 26.72H71.1594C71.5807 26.7151 71.9425 26.6297 72.2471 26.4613C72.5518 26.3003 72.7017 26.1027 72.7017 25.8733L72.5708 13.8569C72.5708 13.8569 72.5684 13.8423 72.5684 13.835L72.5589 13.8325Z" fill="#101828" />
      <path d="M98.2055 11.523L96.8163 8.38662H97.3788L98.3504 10.6878L99.322 8.38662H99.8845L98.4952 11.523H98.2055ZM96.714 11.523V8.38662H97.2595V11.523H96.714ZM99.4413 11.523V8.38662H99.9867V11.523H99.4413ZM94.6685 11.523V8.65935H95.214V11.523H94.6685ZM93.714 8.86389V8.38662H96.1685V8.86389H93.714Z" fill="#101828" />
    </g>
    <defs>
      <clipPath id="clip0_217_1456">
        <rect width="100" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

OwnlyDarkText.propTypes = {};

OwnlyDarkText.defaultProps = {};

export default OwnlyDarkText;
