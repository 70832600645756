import React from 'react';

const OpenWindowIcon = ({ ...props }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.7976 6.31885L14.7976 2.31885M14.7976 2.31885H10.7976M14.7976 2.31885L9.46427 7.65218M7.46427 3.65218H5.99761C4.8775 3.65218 4.31745 3.65218 3.88963 3.87017C3.5133 4.06191 3.20734 4.36788 3.01559 4.7442C2.79761 5.17202 2.79761 5.73208 2.79761 6.85218V11.1188C2.79761 12.239 2.79761 12.799 3.01559 13.2268C3.20734 13.6032 3.5133 13.9091 3.88963 14.1009C4.31745 14.3188 4.8775 14.3188 5.99761 14.3188H10.2643C11.3844 14.3188 11.9444 14.3188 12.3723 14.1009C12.7486 13.9091 13.0545 13.6032 13.2463 13.2268C13.4643 12.799 13.4643 12.239 13.4643 11.1188V9.65218" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

OpenWindowIcon.propTypes = {};

OpenWindowIcon.defaultProps = {};

export default OpenWindowIcon;
