import React from 'react';

import styles from '../styles';

const Columns = ({
  showIf = true, label, children, overloadStyles
}) => {
  if (!showIf) {
    return <div style={styles.empty} />;
  }
  return (
    <div style={overloadStyles || styles.columns}>
      <div style={styles.panelTitle}>{label}</div>
      {children}
    </div>
  );
};

export default Columns;
