import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

import styles from './AvailableColumnItem.module.scss';
import { getCustomerColumnName } from '../../../../../../utils/enumUtils';

const AvailableColumnItem = ({
  column, selectedColumns, onChange
}) => (
  <div className={styles.container}>
    <FormGroup check>
      <Label check>
        <Input
          type="checkbox"
          value={column}
          checked={selectedColumns.includes(column)}
          onChange={onChange}
        />
        {getCustomerColumnName(column)}
      </Label>
    </FormGroup>
  </div>
);

AvailableColumnItem.propTypes = {
  column: PropTypes.string.isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

AvailableColumnItem.defaultProps = {
  selectedColumns: [],
  onChange: () => {}
};

export default AvailableColumnItem;
