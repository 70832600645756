import store from '../store';
import {
  addNavigationChildren, resetNavigation
} from '../store/actions/navigationActions';
import { NavigationRelativePaths } from '../constants';

function addItem(child, type) {
  const { id, name } = child;
  const url = getNavigationShowUrl(child, type);
  const children = {
    id, name, url, type
  };
  store.dispatch(addNavigationChildren(children));
}

function getNavigationShowUrl(instance, type) {
  const { id } = instance;
  const url = NavigationRelativePaths[type];
  return `${url}${id}`;
}

function reset() {
  store.dispatch(resetNavigation());
}

function isTrackedPage(pathname) {
  const navigationPaths = Object.values(NavigationRelativePaths);
  return navigationPaths.some((path) => pathname.includes(path) && pathname !== path);
}

function resetIfNecessary(pathname) {
  const isTracked = pathname && isTrackedPage(pathname);
  if (!isTracked) reset();
}

export default {
  addItem,
  getNavigationShowUrl,
  resetIfNecessary
};
