import React from 'react';
import { FaFile } from 'react-icons/fa';
import PropTypes from 'prop-types';
import v from 'voca';

import './styles.scss';

function getFileType(filename) {
  return filename.indexOf('.') !== -1 ? filename.split('.').pop().toUpperCase() : null;
}

const FileTypeIcon = ({ filename, size }) => {
  if (v.isBlank(filename)) return null;

  const fileType = getFileType(filename);

  let iconSize;
  switch (size) {
    case 'sm':
      iconSize = '1.5rem';
      break;
    default:
      iconSize = '2.5rem';
  }

  return (
    <div className={`file-type-icon-container ${size}`}>
      <FaFile size={iconSize} className="col-secondary-2" />
      <span className="file-type-name text-truncate">{fileType}</span>
    </div>
  );
};

FileTypeIcon.propTypes = {
  filename: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg'])
};

FileTypeIcon.defaultProps = {
  filename: null,
  size: 'lg'
};

export default FileTypeIcon;
