import React from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { MdCheck, MdClose } from 'react-icons/md';
import v from 'voca';

import { DepositStatuses } from '../../../../../../../../constants';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.depositSection.stripeStatus.stripePaymentStatus.index' };

const StripePaymentStatus = ({ deposit }) => (
  <div>
    {deposit.status === DepositStatuses.SUCCEEDED ? (
      <Badge color="success">
        <MdCheck size={16} className="mr-1" />
        {i18n.t('completed', i18nOpts)}
      </Badge>
    ) : (
      <Badge color="danger">
        <MdClose size={16} className="mr-1" />
        {i18n.t('failed', i18nOpts)}
      </Badge>
    )}

    {deposit.status === DepositStatuses.FAILED && !v.isBlank(deposit.errorMessage) && (
      <div className="mt-1">
        <small className="text-danger white-space-pre-wrap">{deposit.errorMessage}</small>
      </div>
    )}
  </div>
);

StripePaymentStatus.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.any)
};

StripePaymentStatus.defaultProps = {
  deposit: null
};

export default StripePaymentStatus;
