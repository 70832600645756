import React, { useEffect } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { HiOutlineMail } from 'react-icons/hi';

import { hasFormValues } from '../../../../../utils/formUtils';
import { saveCustomer, showCustomerForm } from '../../../../../store/actions/customerActions';
import { getError } from '../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../ui/InputError';
import PhoneNumberInput from '../../../../global/PhoneNumberInput';
import { getValidPhoneNumber } from '../../../../global/PhoneNumberInput/utils';
import useForm from '../../../../../hooks/useForm';
import { Colors } from '../../../../../constants';

const i18nOpts = { scope: 'components.admin.customers.show.form.index' };

const Form = ({ customer, currentCompany, ...props }) => {
  const {
    form, setForm, submitting, setSubmitting, error, setError, onTextChange
  } = useForm();

  const onSave = () => {
    setSubmitting(true);

    const input = {
      ...form,
      phone: getValidPhoneNumber(form.phone)
    };

    const variables = {
      input, withTotalVisits: true, withAverageVisitTime: true, companyId: currentCompany.id
    };
    props.saveCustomer(variables)
      .then(() => {
        props.showCustomerForm(false);
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSubmitting(false));
  };

  const onPhoneNumberChange = (value) => {
    setForm((prevForm) => ({ ...prevForm, phone: value }));
  };

  const onCancel = () => {
    props.showCustomerForm(false);
  };

  const loadForm = () => {
    let formData = {};
    if (customer) {
      const {
        id, firstName, lastName, email, phone
      } = customer;

      formData = {
        id, firstName, lastName, email, phone
      };
    }

    setForm(formData);
    setError(null);
  };

  const hasValues = hasFormValues(form);

  useEffect(() => {
    loadForm();
  }, []);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <h4>{i18n.t('title', i18nOpts)}</h4>
      <div className="mb-4">{i18n.t('description', i18nOpts)}</div>

      <hr />

      <FormGroup row>
        <Label for="name" className="col-12 col-sm-4 col-md-12 col-lg-4">{i18n.t('name', i18nOpts)}</Label>
        <div className="col-6 col-sm-4 col-md-6 col-lg-3">
          <Input
            type="text"
            name="firstName"
            id="name"
            value={form.firstName || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'firstName')}
          />
          <InputError name="firstName" error={error} />
        </div>
        <div className="col-6 col-sm-4 col-md-6 col-lg-3">
          <Input
            type="text"
            name="lastName"
            id="customerLastName"
            value={form.lastName || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'lastName')}
          />
          <InputError name="lastName" error={error} />
        </div>
      </FormGroup>

      <hr />

      <FormGroup row>
        <Label for="customerEmail" className="col-12 col-sm-4 col-md-12 col-lg-4">{i18n.t('email', i18nOpts)}</Label>
        <div className="col-12 col-sm-8 col-md-12 col-lg-6">
          <div className="input-icon-prepend">
            <HiOutlineMail size={20} color={Colors.GRAY_500} />
          </div>

          <Input
            type="text"
            name="email"
            id="customerEmail"
            value={form.email || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'email')}
          />
          <InputError name="email" error={error} />
        </div>
      </FormGroup>

      <hr />

      <FormGroup row>
        <Label for="customerPhone" className="col-12 col-sm-4 col-md-12 col-lg-4">{i18n.t('phoneNumber', i18nOpts)}</Label>

        <div className="col-12 col-sm-8 col-md-12 col-lg-6">
          <PhoneNumberInput
            value={form.phone || ''}
            onChange={onPhoneNumberChange}
          />
          <InputError name="phone" error={error} />
        </div>
      </FormGroup>

      <hr />

      <div className="d-flex justify-content-end gap-3 mt-4">
        <Button outline color="secondary" onClick={onCancel} disabled={submitting}>
          {i18n.t('buttons.cancel')}
        </Button>
        <Button color="primary" onClick={onSave} disabled={submitting || !hasValues}>
          {submitting && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
      </div>
    </div>
  );
};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}), {
  saveCustomer,
  showCustomerForm
})(Form);
