import React, { useRef } from 'react';
import {
  ModalHeader, Modal, ModalBody, Button, ModalFooter
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';

import { ModalNames } from '../../../../../../constants';
import styles from './FinancialReportModal.module.scss';
import FinancialReport from '../../../FinancialReport';
import ModalService from '../../../../../../services/ModalService';
import ModalCloseButton from '../../../../../common/ModalCloseButton';

const i18nOpts = { scope: 'components.admin.customers.show.overview.financialReportModal.index' };

function onClose() {
  ModalService.close(ModalNames.FINANCIAL_REPORT);
}

const FinancialReportModal = ({ customer, opened }) => {
  const pdfRef = useRef();

  return (
    <Modal isOpen={opened} size="lg" scrollable className={styles.modalDialog}>
      <ModalHeader close={<ModalCloseButton onClick={onClose} />} />

      <ModalBody>
        <div ref={pdfRef}>
          {opened && (
            <FinancialReport id={customer.id} />
          )}
        </div>
      </ModalBody>

      <ModalFooter className="justify-content-between">
        <Button outline color="secondary" onClick={onClose}>
          {i18n.t('buttons.close')}
        </Button>

        <ReactToPrint
          trigger={() => (
            <Button color="secondary" outline>
              {i18n.t('buttons.downloadPDF', i18nOpts)}
            </Button>
          )}
          content={() => pdfRef.current}
          bodyClass={styles.pdfBody}
        />
      </ModalFooter>
    </Modal>
  );
};

FinancialReportModal.propTypes = {};

FinancialReportModal.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  opened: store.modals[ModalNames.FINANCIAL_REPORT]?.opened || false,
}))(FinancialReportModal);
