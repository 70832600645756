import {
  SAVE_FLOOR,
  DELETE_FLOOR,
  LIST_FLOORS,
  SORT_FLOORS,
  SET_FLOOR_OPTIONS,
  SET_FLOOR_GROUPS,
  SET_RELOAD_FLOORS
} from './types';
import {
  createFloorQuery, deleteFloorQuery, listFloorsQuery, sortFloorsQuery, updateFloorQuery
} from '../../graphql';
import api from '../../api';

export function listFloors(variables = {}) {
  return {
    type: LIST_FLOORS,
    payload: api.graphql(listFloorsQuery, variables)
  };
}

export function saveFloor(variables = {}) {
  const query = variables.input.id ? updateFloorQuery : createFloorQuery;
  return {
    type: SAVE_FLOOR,
    payload: api.graphql(query, variables)
  };
}

export function deleteFloor(id) {
  return {
    type: DELETE_FLOOR,
    payload: api.graphql(deleteFloorQuery, { id })
  };
}

export function sortFloors(variables = {}) {
  return {
    type: SORT_FLOORS,
    payload: api.graphql(sortFloorsQuery, variables)
  };
}

export function setFloorGroups(floorId, floorGroups) {
  return {
    type: SET_FLOOR_GROUPS,
    payload: { floorId, floorGroups }
  };
}

export function setFloorOptions(floorGroupId, floorOptions) {
  return {
    type: SET_FLOOR_OPTIONS,
    payload: { floorGroupId, floorOptions }
  };
}

export function setReloadFloors(reload) {
  return {
    type: SET_RELOAD_FLOORS,
    payload: { reload }
  };
}
