import React, { useEffect, useState } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Button
} from 'reactstrap';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Link, navigate } from 'gatsby';
import { HiShare } from 'react-icons/hi';
import { connect } from 'react-redux';

import Overview from './Overview';
import Images from './Images';
import { isAdmin } from '../../../../utils/authUtils';
import { fetchProjectBuildingAsync, Tabs } from './utils';
import BuildingFloors from './BuildingFloors';
import Models from './Models';
import Modifications from '../../../global/Modifications';
import { ModalNames, NavigationMainNames, Statuses } from '../../../../constants';
import { hasCompanyDomain } from '../../../../utils/companyUtils';
import ModalService from '../../../../services/ModalService';
import DirectLinkModal from '../../../global/DirectLinkModal';
import QRCodeModal from '../../../global/QRCodeModal';
import NavigationService from '../../../../services/NavigationService';
import NavigationBreadcrumbs from '../../../global/NavigationBreadcrumbs';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.projectBuildings.show.index' };

const Show = ({ id, currentCompany }) => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(Tabs.OVERVIEW);
  const [projectBuilding, setProjectBuilding] = useState(null);

  useEffect(() => {
    loadProjectBuilding();
  }, []);

  const onChangeTab = (tab) => setActiveTab(tab);

  const loadProjectBuilding = () => {
    if (!id) return;

    setLoading(true);

    fetchProjectBuildingAsync(parseInt(id, 10))
      .then((item) => {
        setProjectBuilding(item);
        NavigationService.addItem(item, NavigationMainNames.PROJECT_BUILDINGS);
      })
      .catch(() => navigate('/admin/projectBuildings'))
      .finally(() => setLoading(false));
  };

  const onOpenDirectLinkModal = () => {
    const { hashId } = projectBuilding;

    const params = {
      path: `/building/${hashId}`,
      label: projectBuilding.name
    };
    ModalService.open(ModalNames.DIRECT_LINK_MODAL, params);
  };

  if (!projectBuilding || loading) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={projectBuilding.name} />

      <NavigationBreadcrumbs />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{projectBuilding.name}</h2>
        </div>
        <div>
          {isAdmin() && (
            <>
              {projectBuilding.status === Statuses.PUBLISHED
                && hasCompanyDomain(currentCompany) && (
                <Button color="secondary" className="mr-3" onClick={onOpenDirectLinkModal}>
                  <HiShare size="1rem" className="col-light mr-2" />
                  <span>{i18n.t('buttons.shareLink')}</span>
                </Button>
              )}

              <Link to={`/admin/projectBuildings/form/${projectBuilding.id}`} className="btn btn-primary">
                <i className="far fa-edit mr-2" />
                {i18n.t('buttons.edit')}
              </Link>
            </>
          )}
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.OVERVIEW ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.OVERVIEW)}
          >
            {i18n.t('tabs.overview', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.MODELS ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.MODELS)}
          >
            {i18n.t('tabs.models', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.FLOORS ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.FLOORS)}
          >
            {i18n.t('tabs.floors', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === Tabs.IMAGES ? 'active' : ''}`}
            onClick={() => onChangeTab(Tabs.IMAGES)}
          >
            {i18n.t('tabs.images', i18nOpts)}
          </NavLink>
        </NavItem>
        {isAdmin() && (
          <NavItem>
            <NavLink
              className={`${activeTab === Tabs.MODIFICATIONS ? 'active' : ''}`}
              onClick={() => onChangeTab(Tabs.MODIFICATIONS)}
            >
              {i18n.t('tabs.modifications', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.OVERVIEW}>
          <Overview projectBuilding={projectBuilding} />
        </TabPane>
        <TabPane tabId={Tabs.MODELS}>
          <Models projectBuilding={projectBuilding} />
        </TabPane>
        <TabPane tabId={Tabs.FLOORS}>
          <BuildingFloors projectBuilding={projectBuilding} />
        </TabPane>
        <TabPane tabId={Tabs.IMAGES}>
          <Images projectBuilding={projectBuilding} />
        </TabPane>
        {isAdmin() && (
          <TabPane tabId={Tabs.MODIFICATIONS}>
            <Modifications instance={projectBuilding} />
          </TabPane>
        )}
      </TabContent>

      <DirectLinkModal />
      <QRCodeModal />
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(Show);
