import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import _ from 'lodash';

import { fetchQuickPossessionsBedsAsync } from './utils';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';
import ShowMoreContent from '../../../../../../common/ShowMoreContent';
import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.components.quickPossessionFiltersModal.bedsFilter.index' };

const BedsFilter = ({ filters, onChange, currentCompany }) => {
  const beds = filters.beds || [];
  const [availableBeds, setAvailableBeds] = useState([]);
  const [loading, setLoading] = useState(false);

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ beds: _.xor(beds, [Number(value)]) });
  };

  const loadQuickPossessionsBeds = () => {
    fetchQuickPossessionsBedsAsync(currentCompany.id)
      .then((result) => setAvailableBeds(result))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadQuickPossessionsBeds();
  }, []);

  const filtersCount = useMemo(() => beds.length, [filters]);

  if (loading || !availableBeds.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableBeds}
        renderItem={(bedsItem) => (
          <ListItem
            key={`beds-${bedsItem}`}
            value={bedsItem}
            selectedValues={beds}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

BedsFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

BedsFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}))(BedsFilter);
