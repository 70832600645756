import React from 'react';
import {
  Card, CardHeader
} from 'reactstrap';
import i18n from 'i18n-js';

import TextPlaceholder from '../../../components/TextPlaceholder';

const i18nOpts = { scope: 'components.admin.customers.show.details.verification.additionalInfo.preApproval.placeholder.index' };

const Placeholder = () => (
  <Card className="mb-2 card-without-body">
    <CardHeader>
      <div className="d-flex align-items-center">
        <h4 className="mb-0 mr-3 col-gray-400">{i18n.t('title', i18nOpts)}</h4>
        <div className="ml-auto">
          <TextPlaceholder width={109} />
        </div>
      </div>
    </CardHeader>
  </Card>
);

Placeholder.propTypes = {};

Placeholder.defaultProps = {};

export default Placeholder;
