export function exportToCSV(headers, rows, filename) {
  const escapeCsvValue = (value) => `"${String(value).replace(/"/g, '""')}"`;

  const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
    `${headers.map(escapeCsvValue).join(',')}\n${
      rows.map((row) => row.map(escapeCsvValue).join(',')).join('\n')}`
  )}`;

  const link = document.createElement('a');
  link.href = csvContent;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
