import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import { getProductType } from '../../../../store/actions/productTypeActions';
import Overview from './Overview';
import Modifications from '../../../global/Modifications';
import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../utils/authUtils';
import NavigationService from '../../../../services/NavigationService';
import { NavigationMainNames } from '../../../../constants';
import NavigationBreadcrumbs from '../../../global/NavigationBreadcrumbs';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.productTypes.show.index' };

const Tabs = Object.freeze({
  OVERVIEW: 'overview',
  MODIFICATIONS: 'modifications'
});

const Show = ({
  id, productType, loading, ...props
}) => {
  const [activeTab, setActiveTab] = useState(Tabs.OVERVIEW);
  const isAdmin = isCompanyAdmin() || isSalesLead() || isMasterAdmin();

  const loadProductType = () => {
    props.getProductType(parseInt(id, 10))
      .catch(() => navigate('/admin/productTypes'));
  };

  useEffect(() => {
    loadProductType();
  }, []);

  useEffect(() => {
    if (productType) NavigationService.addItem(productType, NavigationMainNames.PRODUCT_TYPES);
  }, [productType]);

  if (loading || !productType) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={productType.name} />

      <NavigationBreadcrumbs />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{productType.name}</h2>
        </div>
        <div>
          <Link to={`/admin/productTypes/form?id=${productType.id}`} className="btn btn-primary">
            <i className="far fa-edit mr-2" />
            {i18n.t('buttons.edit')}
          </Link>
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === Tabs.OVERVIEW })}
            onClick={() => setActiveTab(Tabs.OVERVIEW)}
          >
            {i18n.t('tabs.overview', i18nOpts)}
          </NavLink>
        </NavItem>
        {isAdmin && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.MODIFICATIONS })}
              onClick={() => setActiveTab(Tabs.MODIFICATIONS)}
            >
              {i18n.t('tabs.modifications', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.OVERVIEW}>
          <Overview />
        </TabPane>
        {isAdmin && (
          <TabPane tabId={Tabs.MODIFICATIONS}>
            <Modifications instance={productType} />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

Show.propTypes = {};

Show.defaultProps = {};

export default connect((store) => ({
  productType: store.productTypes.productType,
  loading: store.productTypes.getProductType.loading
}), {
  getProductType
})(Show);
