const fragment = `
  id
  hashId
  enabled
  type
  templateId
  templateName
  emailSubject
  document {
    id
    name
    filename
    fileUrl
  }
`;

export const findDocumentTemplateQuery = `
  query FindDocumentTemplate(
    $entityId: Int!
    $entityType: String!
  ) {
    item: findDocumentTemplate(entityId: $entityId, entityType: $entityType) {
      ${fragment}
    }
  }
`;

export const createDocumentTemplateQuery = `
  mutation CreateDocumentTemplate(
    $companyId: Int!
    $input: CreateDocumentTemplateInput!
  ) {
    item: createDocumentTemplate(companyId: $companyId, input: $input) {
      ${fragment}
    }
  }
`;

export const updateDocumentTemplateQuery = `
  mutation UpdateDocumentTemplate(
    $companyId: Int!
    $input: UpdateDocumentTemplateInput!
  ) {
    item: updateDocumentTemplate(companyId: $companyId, input: $input) {
      ${fragment}
    }
  }
`;
