import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import v from 'voca';

import { formatTimestamp } from '../../../../utils/dateUtils';

const CustomerLastActivityDate = ({ customer }) => {
  const lastActivityDate = customer?.lastActivityDate;

  if (v.isBlank(lastActivityDate)) return null;

  return (
    <span className={classNames('text-nowrap')}>
      {formatTimestamp(lastActivityDate)}
    </span>
  );
};

CustomerLastActivityDate.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomerLastActivityDate.defaultProps = {};

export default CustomerLastActivityDate;
