import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { Slider } from '../../../../../../ui';
import { formatCurrency } from '../../../../../../../utils/currencyUtils';
import { getDealsPriceMaxAsync } from './utils';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';

const i18nOpts = { scope: 'components.admin.sales.components.dealList.filtersModal.dealPriceFilter.index' };

const DEFAULT_MIN_VALUE = 0;
const DEFAULT_MAX_VALUE = 1000;

const DealPriceFilter = ({ filters, onChange, currentCompany }) => {
  const dealPriceRange = filters.dealPriceRange || [];
  const [mounted, setMounted] = useState(false);
  const [maxValue, setMaxValue] = useState(DEFAULT_MAX_VALUE);

  const onSliderChange = (value) => {
    onChange({ dealPriceRange: value });
  };

  useEffect(() => {
    getDealsPriceMaxAsync(currentCompany.id)
      .then((maxPrice) => {
        const roundedMaxValue = Math.ceil(maxPrice / 1000) * 1000;
        setMaxValue(roundedMaxValue);
      })
      .catch(() => {})
      .finally(() => setMounted(true));
  }, []);

  const lowValue = dealPriceRange[0] || DEFAULT_MIN_VALUE;
  const highValue = dealPriceRange[1] || maxValue;

  const filtersCount = useMemo(() => {
    const isRangeChanged = lowValue !== DEFAULT_MIN_VALUE || highValue !== maxValue;
    return isRangeChanged ? 1 : 0;
  }, [dealPriceRange]);

  if (!mounted) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <div className="px-2">
        <Slider
          min={DEFAULT_MIN_VALUE}
          max={maxValue}
          value={[lowValue, highValue]}
          onChange={onSliderChange}
          step={1000}
        />
      </div>

      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="font-weight-500 col-gray-900 font-16">{formatCurrency(lowValue, true)}</div>
        <div className="font-weight-500 col-gray-900 font-16">{formatCurrency(highValue, true)}</div>
      </div>
    </CollapsibleFilterGroup>
  );
};

DealPriceFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

DealPriceFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(DealPriceFilter);
