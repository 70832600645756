import React, { useState, useEffect } from 'react';
import { ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

import styles from './InvitationWizard.module.scss';
import { Steps } from './constants';
import { onCloseModal } from '../utils';
import BackLink from '../components/BackLink';
import ModalCloseButton from '../../../common/ModalCloseButton';
import InvitationSent from './InvitationSent';
import InvitationType from './InvitationType';
import CustomerSelectionType from './CustomerSelectionType';
import NewCustomer from './NewCustomer';
import ExistingCustomer from './ExistingCustomer';
import InvitationList from './InvitationList';
import HomeSelectionType from './HomeSelectionType';
import NewHome from './NewHome';
import ExistingHome from './ExistingHome';
import ConfirmInvitation from './ConfirmInvitation';
import { getInitialStep } from './utils';

const InvitationWizard = ({ startType }) => {
  const [prevSteps, setPrevSteps] = useState([]);
  const initialStep = getInitialStep(startType);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [skippedStep, setSkippedStep] = useState(null);

  const onBack = () => {
    let newPrevSteps = [...prevSteps];
    const newSkippedSteps = [...skippedSteps];
    newPrevSteps.pop();

    let prevStep = newPrevSteps.pop();
    if (newSkippedSteps.includes(prevStep)) {
      setSkippedStep(null);
      setSkippedSteps(newSkippedSteps.filter((step) => step !== prevStep));

      const filteredPrevSteps = _.difference(newPrevSteps, newSkippedSteps);
      prevStep = filteredPrevSteps.pop();
      newPrevSteps = _.takeWhile(newPrevSteps, (item) => item !== prevStep);
    }

    setPrevSteps(newPrevSteps);
    setCurrentStep(prevStep);
  };

  useEffect(() => {
    setPrevSteps((prevPrevSteps) => ([...prevPrevSteps, currentStep]));
  }, [currentStep]);

  useEffect(() => {
    if (skippedStep) setSkippedSteps((prevOmittedSteps) => ([...prevOmittedSteps, skippedStep]));
  }, [skippedStep]);

  return (
    <>
      {currentStep !== Steps.INVITATION_SENT && (
        <ModalHeader
          toggle={onCloseModal}
          close={<ModalCloseButton onClick={onCloseModal} />}
          tag="div"
          className={styles.modalHeader}
        >
          {prevSteps.length > 1 && (
            <div className="float-left">
              <BackLink onClick={onBack} />
            </div>
          )}
        </ModalHeader>
      )}

      {currentStep === Steps.INVITATION_TYPE && (
        <InvitationType onContinue={(nextStep) => setCurrentStep(nextStep)} />
      )}

      {currentStep === Steps.HOME_SELECTION_TYPE && (
        <HomeSelectionType onContinue={(nextStep) => setCurrentStep(nextStep)} />
      )}

      {currentStep === Steps.NEW_HOME && (
        <NewHome onContinue={(nextStep) => setCurrentStep(nextStep)} />
      )}

      {currentStep === Steps.EXISTING_HOME && (
        <ExistingHome onContinue={(nextStep) => setCurrentStep(nextStep)} />
      )}

      {currentStep === Steps.CUSTOMER_SELECTION_TYPE && (
        <CustomerSelectionType onContinue={(nextStep) => setCurrentStep(nextStep)} />
      )}

      {currentStep === Steps.NEW_CUSTOMER && (
        <NewCustomer onContinue={() => setCurrentStep(Steps.INVITATION_LIST)} />
      )}

      {currentStep === Steps.EXISTING_CUSTOMER && (
        <ExistingCustomer onContinue={(nextStep) => setCurrentStep(nextStep)} />
      )}

      {currentStep === Steps.INVITATION_LIST && (
        <InvitationList
          onContinue={(nextStep) => setCurrentStep(nextStep)}
          onSkipped={() => setSkippedStep(Steps.INVITATION_LIST)}
        />
      )}

      {currentStep === Steps.CONFIRM_INVITATION && (
        <ConfirmInvitation onContinue={() => setCurrentStep(Steps.INVITATION_SENT)} />
      )}

      {currentStep === Steps.INVITATION_SENT && (
        <InvitationSent />
      )}
    </>
  );
};

InvitationWizard.propTypes = {
  startType: PropTypes.string
};

InvitationWizard.defaultProps = {
  startType: null,
};

export default InvitationWizard;
