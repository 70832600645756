import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Table } from 'reactstrap';

import { getTours } from '../../../../store/actions/tourActions';
import ListEmpty from '../../../common/ListEmpty';
import { formatTimestamp } from '../../../../utils/dateUtils';
import { isMasterAdmin } from '../../../../utils/authUtils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.tours.list.index' };

class List extends Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };
  }

  componentDidMount() {
    this.loadTours();
  }

  loadTours() {
    const { props } = this;
    const { currentCompany } = this.props;

    const variables = { filter: { companyId: currentCompany.id } };
    props.getTours(variables)
      .finally(() => this.setState({ mounted: true }));
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return <Loading loading fullScreen />;

    const { tours, loading } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="mb-4">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={tours} />

            {
              tours.length > 0 && (
                <div className="table-responsive mb-4">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>{i18n.t('user', i18nOpts)}</th>
                        <th>{i18n.t('date', i18nOpts)}</th>
                        <th>{i18n.t('type', i18nOpts)}</th>
                        { isMasterAdmin() && (
                          <th>{i18n.t('company', i18nOpts)}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        tours.map((tour) => (
                          <tr key={`tour-${tour.id}`}>
                            <td>
                              <Link to={`/admin/customers/${tour.user.contact.id}`}>
                                {tour.user.name}
                              </Link>
                            </td>
                            <td>{formatTimestamp(tour.createdAt)}</td>
                            <td>{i18n.t(`types.${tour.type.toLowerCase()}`, i18nOpts)}</td>
                            { isMasterAdmin() && (
                              <td className="col-primary-1">{tour.user.company.name}</td>
                            )}
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              )
            }
          </>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  tours: store.tours.tours,
  loading: store.tours.getTours.loading,
  currentCompany: store.companies.currentCompany
}), {
  getTours
})(List);
