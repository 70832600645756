import React from 'react';

const ConsistentTrendIcon = ({ ...props }) => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.375 11.875H2.49167C2.1883 11.875 2.03662 11.875 1.92076 11.816C1.81883 11.764 1.73597 11.6812 1.68404 11.5792C1.625 11.4634 1.625 11.3117 1.625 11.0083V2.125M3.84487 6.35939H10.5625" stroke="#026AA2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

ConsistentTrendIcon.propTypes = {};

ConsistentTrendIcon.defaultProps = {};

export default ConsistentTrendIcon;
