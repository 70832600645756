import React from 'react';
import PropTypes from 'prop-types';
import { FiTrash2 } from 'react-icons/fi';
import i18n from 'i18n-js';

import styles from './ImagePreviewItem.module.scss';
import { Colors } from '../../../../constants';

const ImagePreviewItem = ({ image, onDelete }) => {
  const { imageUrl } = image;

  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <div
          className={styles.deleteButton}
          onClick={() => onDelete(image)}
          role="button"
          aria-hidden
          title={i18n.t('buttons.delete')}
        >
          <FiTrash2 color={Colors.DANGER_700} size="20" />
        </div>
      </div>

      <img src={imageUrl} alt="" />
    </div>
  );
};

ImagePreviewItem.propTypes = {
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func
};

ImagePreviewItem.defaultProps = {
  onDelete: () => {}
};

export default ImagePreviewItem;
