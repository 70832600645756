import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import v from 'voca';

const i18nOpts = { scope: 'components.admin.integrations.hubSpot.loginToApp.index' };

const LoginToApp = ({ hubSpotSettings }) => {
  const { authUrl, apiKey } = hubSpotSettings;
  const showTitle = !v.isBlank(apiKey);

  return (
    <div className="mt-4">
      { showTitle && (<h3 className="text-warning">{i18n.t('title', i18nOpts)}</h3>) }
      <div>{i18n.t('description', i18nOpts)}</div>

      <div className="mt-3">
        <a href={authUrl} target="_blank" rel="noreferrer">{i18n.t('buttons.install', i18nOpts)}</a>
      </div>
    </div>
  );
};

LoginToApp.propTypes = {
  hubSpotSettings: PropTypes.objectOf(PropTypes.any).isRequired
};

LoginToApp.defaultProps = {};

export default LoginToApp;
