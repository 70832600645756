import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import PropTypes from 'prop-types';

import {
  DEFAULT_BUYER_VERIFIED_WORKFLOW,
  DEFAULT_RENTAL_VERIFIED_WORKFLOW,
  WorkflowTypes,
} from '../../../../../../../../constants';
import WorkflowStep from './WorkflowStep';
import ConfigFile from '../components/ConfigFile';

const VerifiedWorkflow = ({ form, onTextChange }) => {
  const [workflowType, setWorkflowType] = useState(WorkflowTypes.BUYER_VERIFIED);
  const [defaultWorkflow, setDefaultWorkflow] = useState(DEFAULT_BUYER_VERIFIED_WORKFLOW);
  const [workflow, setWorkflow] = useState([]);

  // eslint-disable-next-line max-len
  const mergeAndEnableSteps = (defaultVerifiedWorkflow, companyWorkflow) => defaultVerifiedWorkflow.map((step) => {
    const stepFound = companyWorkflow.find((item) => item.type === step.type);
    if (stepFound) {
      return { ...step, ...stepFound, enabled: true };
    }
    return step;
  });

  const onChangeStepSetting = (stepSetting) => {
    const newWorkflow = workflow.map((item) => (
      item.type === stepSetting.type ? stepSetting : item
    ));
    setWorkflow(newWorkflow);
    onTextChange({ target: { name: 'verifiedWorkflow', value: newWorkflow } });
  };

  const onUpdate = () => {
    onChangeStepSetting({});
  };

  const onUploadWorkflow = (newWorkflow) => {
    const updatedWorkflow = mergeAndEnableSteps(defaultWorkflow, newWorkflow);

    setWorkflow(updatedWorkflow);
    onTextChange({ target: { name: 'verifiedWorkflow', value: updatedWorkflow } });
  };

  useEffect(() => {
    let companyWorkflow = form.buyerVerifiedWorkflow?.workflow || DEFAULT_BUYER_VERIFIED_WORKFLOW;
    let defaultVerifiedWorkflow = DEFAULT_BUYER_VERIFIED_WORKFLOW;
    if (form.enableHomeRental) {
      companyWorkflow = form.rentalVerifiedWorkflow?.workflow || DEFAULT_RENTAL_VERIFIED_WORKFLOW;
      defaultVerifiedWorkflow = DEFAULT_RENTAL_VERIFIED_WORKFLOW;
      setWorkflowType(WorkflowTypes.RENTAL_VERIFIED);
      setDefaultWorkflow(defaultVerifiedWorkflow);
    }

    const newWorkflow = mergeAndEnableSteps(defaultVerifiedWorkflow, companyWorkflow);
    setWorkflow(newWorkflow);
  }, [form.enableHomeRental]);

  return (
    <div>
      <ReactSortable
        list={workflow}
        setList={setWorkflow}
        onEnd={onUpdate}
      >
        {workflow.map((step) => (
          <WorkflowStep key={`workflow-${step.type}`} step={step} onChangeStepSetting={onChangeStepSetting} />
        ))}
      </ReactSortable>

      <ConfigFile
        workflowType={workflowType}
        workflow={workflow}
        onUploadWorkflow={onUploadWorkflow}
      />
    </div>
  );
};

VerifiedWorkflow.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func
};

VerifiedWorkflow.defaultProps = {
  onTextChange: () => {}
};

export default VerifiedWorkflow;
