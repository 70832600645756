import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Table, Button } from 'reactstrap';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import v from 'voca';
import { GrAddCircle } from 'react-icons/gr';

import {
  getUsers, cleanUsers, deleteUser, setReloadUsers
} from '../../../../store/actions/userActions';
import ActionsMenu from '../../../global/ActionsMenu';
import ListEmpty from '../../../common/ListEmpty';
import {
  isMasterAdmin, isTecSupport, isAdmin,
} from '../../../../utils/authUtils';
import { getRoleName } from '../../../../utils/userUtils';
import { formatTimestamp } from '../../../../utils/dateUtils';
import { ModalNames, Roles, SortOrders } from '../../../../constants';
import SortIcons from '../../../common/SortIcons';
import setSortFilter from '../../../../utils/sortUtils';
import ListPagination from '../../../common/ListPagination';
import { userCanBeEdited } from '../utils';
import SearchBar from '../../../common/SearchBar';
import CompanyFilter from '../../../global/filters/CompanyFilter';
import UserVerifiedIcon from '../../../../assets/icons/UserVerifiedIcon';
import UserUnverifiedIcon from '../../../../assets/icons/UserUnverifiedIcon';
import ModalService from '../../../../services/ModalService';
import FormModal from '../components/FormModal';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.users.list.index' };

const columnOptions = {
  NAME: 'name',
  COMPANY: 'company',
  ROLE: 'role',
  LAST_ACTIVE: 'lastActive'
};

const DEFAULT_SORT = { field: columnOptions.NAME, direction: SortOrders.ASC };
const DEFAULT_EXCLUDED_ROLES = [Roles.CLIENT];

const EmailVerifiedIcon = ({ user }) => {
  const { emailVerified } = user;
  return (
    <div className={classnames('text-center', { 'col-primary-1': emailVerified, 'text-danger': !emailVerified })}>
      { emailVerified ? (<UserVerifiedIcon />) : (<UserUnverifiedIcon />) }
    </div>
  );
};

const Users = ({
  currentUser, users, loading, pagination, currentCompany, reloadUsers, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [query, setQuery] = useState(null);

  const onChangePage = (page) => {
    loadUsers(page);
  };

  const onDelete = (userId) => {
    if (userId === currentUser.id) {
      toast.error(i18n.t('errors.deleteCurrentUser', i18nOpts));
      return;
    }

    props.deleteUser(userId)
      .catch(() => {});
  };

  const onEdit = (user) => {
    ModalService.open(ModalNames.USER_FORM, { user });
  };

  const onAdd = () => {
    ModalService.open(ModalNames.USER_FORM);
  };

  const onChangeFilter = (company) => {
    const companyId = company ? company.id : company;
    const filter = { companyId };
    setFilters((prevFilters) => ({ ...prevFilters, ...filter }));
  };

  const onSubmit = (event, newQuery) => {
    event?.preventDefault();
    setQuery(newQuery);
  };

  const sortChange = (column) => {
    setSort(setSortFilter(column, sort));
  };

  const loadUsers = (page = 1) => {
    const excludedRoles = [...DEFAULT_EXCLUDED_ROLES];
    if (!isMasterAdmin()) excludedRoles.push(Roles.TEC_SUPPORT);

    const variables = {
      page,
      sort,
      filter: { ...filters, excludedRoles }
    };

    if (!v.isBlank(query)) variables.filter.query = query;

    if (!(isMasterAdmin() || isTecSupport())) {
      variables.filter = { ...variables.filter, companyId: currentCompany.id };
    }

    props.getUsers(variables)
      .finally(() => {
        setMounted(true);
        props.setReloadUsers(false);
      });
  };

  useEffect(() => {
    props.cleanUsers()
      .then(() => loadUsers());
  }, []);

  useEffect(() => {
    if (!mounted) return;

    loadUsers();
  }, [filters, query, sort, reloadUsers]);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="mb-4">
        <h2>{i18n.t('title', i18nOpts)}</h2>
      </div>

      <div className="d-flex d-flex align-items-center justify-content-end flex-wrap mb-4 gap-3">
        <div>
          <SearchBar
            onSubmit={onSubmit}
            inputProps={{ bsSize: 'md', placeholder: i18n.t('searchBarPlaceholder', i18nOpts) }}
          />
        </div>

        <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
          { isAdmin() && (
          <>
            {(isMasterAdmin() || isTecSupport()) && (
            <div>
              <CompanyFilter onChange={(company) => onChangeFilter(company)} />
            </div>
            )}
            <Button color="secondary" outline onClick={onAdd}>
              <GrAddCircle size={20} className="mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Button>
          </>
          )}
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={users} />

          {users.length > 0 && (
            <div className="table-responsive mb-4">
              <Table bordered>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th onClick={() => sortChange(columnOptions.NAME)} role="button">
                      {i18n.t('user', i18nOpts)}
                      <SortIcons sort={sort} field={columnOptions.NAME} />
                    </th>
                    {(isMasterAdmin() || isTecSupport()) && (
                      <th onClick={() => sortChange(columnOptions.COMPANY)} role="button">
                        {i18n.t('company', i18nOpts)}
                        <SortIcons sort={sort} field={columnOptions.COMPANY} />
                      </th>
                    )}
                    <th onClick={() => sortChange(columnOptions.ROLE)} role="button">
                      {i18n.t('role', i18nOpts)}
                      <SortIcons sort={sort} field={columnOptions.ROLE} />
                    </th>
                    <th className="text-center">
                      {i18n.t('emailVerified', i18nOpts)}
                    </th>
                    <th onClick={() => sortChange(columnOptions.LAST_ACTIVE)} role="button">
                      {i18n.t('lastActive', i18nOpts)}
                      <SortIcons sort={sort} field={columnOptions.LAST_ACTIVE} />
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    users.map((user) => (
                      <tr key={`user-${user.id}`}>
                        <td>
                          <Link to={`/admin/users/${user.id}`} className="font-weight-600">
                            {i18n.t('buttons.view')}
                          </Link>
                        </td>
                        <td>
                          <div className="font-weight-500">{user.name}</div>
                          <span className="col-gray-600">{user.email}</span>
                        </td>
                        {(isMasterAdmin() || isTecSupport()) && (
                          <td>{user.company.name}</td>
                        )}
                        <td>{getRoleName(user.role)}</td>
                        <td>
                          <EmailVerifiedIcon user={user} />
                        </td>
                        <td>{formatTimestamp(user.lastActive)}</td>
                        <td className="text-right">
                          { userCanBeEdited(user) && (
                            <ActionsMenu item={user} onDelete={onDelete} onEdit={onEdit} />
                          )}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          )}

          <div className="text-right mt-4">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}

      <FormModal />
    </div>
  );
};

export default connect((store) => ({
  users: store.users.users,
  loading: store.users.getUsers.loading,
  pagination: store.users.pagination,
  currentUser: store.users.currentUser,
  currentCompany: store.companies.currentCompany,
  reloadUsers: store.users.reloadUsers,
}), {
  getUsers,
  cleanUsers,
  deleteUser,
  setReloadUsers,
})(Users);
