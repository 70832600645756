import React from 'react';
import PropTypes from 'prop-types';
import { TbSend } from 'react-icons/tb';
import i18n from 'i18n-js';

import TimeLineElement from '../../../../../common/TimeLineElement';
import ActivityDate from '../../components/ActivityDate';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.customerInvited.verified.index' };

const Verified = ({ activity, innerKey }) => {
  const { customerInvitation: { inviter } } = activity;

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<TbSend />}
    >
      <div className="d-flex align-items-start gap-3">
        <div className="activity-timeline-title">{i18n.t('title', i18nOpts)}</div>
        <ActivityDate date={activity.createdAt} />
      </div>
      <div>{i18n.t('description', { ...i18nOpts, inviter: inviter?.firstName })}</div>
    </TimeLineElement>
  );
};

Verified.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

Verified.defaultProps = {};

export default Verified;
