import v from 'voca';

import {
  DepositStatuses, OfferSignStatuses, OfferStatuses, ReserveStepTypes
} from '../../../../constants';
import { parseBoolean } from '../../../../utils/parserUtils';
import { compareUnionTypeObjectTypename } from '../../../../utils/objectUtils';

export function getDealFilters(currentCompany, filters, query) {
  const filter = {
    companyId: currentCompany.id
  };

  if (!v.isBlank(query)) filter.query = query;

  if (filters.completedDealStatuses?.length) {
    const { completedDealStatuses } = filters;
    if (completedDealStatuses.includes(ReserveStepTypes.OFFER)) {
      filter.offerStatus = OfferStatuses.ACCEPTED;
    }
    if (completedDealStatuses.includes(ReserveStepTypes.SIGN)) {
      filter.signStatus = OfferSignStatuses.ACCEPTED;
    }
    if (completedDealStatuses.includes(ReserveStepTypes.DEPOSIT)) {
      filter.depositStatus = DepositStatuses.ACCEPTED;
    }
  }

  if (filters.dealPriceRange?.length) {
    const [from, to] = filters.dealPriceRange;
    filter.dealPriceRange = { from, to };
  }

  if (!v.isBlank(filters.isUsingRealtor)) {
    filter.isUsingRealtor = parseBoolean(filters.isUsingRealtor);
  }

  if (filters.communitiesProjects) {
    const { communitiesProjects } = filters;
    const projectIds = communitiesProjects.filter((cp) => compareUnionTypeObjectTypename(cp, 'Project')).map((cp) => cp.id);
    const communityIds = communitiesProjects.filter((cp) => compareUnionTypeObjectTypename(cp, 'Community')).map((cp) => cp.id);
    if (projectIds.length) filter.projectIds = projectIds;
    if (communityIds.length) filter.communityIds = communityIds;
  }

  if (filters.salesLeadId) {
    filter.offerSalesLeads = [{ id: filters.salesLeadId, __typename: filters.salesLeadType }];
  }

  return filter;
}
