import React, { Component } from 'react';
import { Card, Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { connect } from 'react-redux';

import api from '../../../../api';
import { getLeadGenerationQuery } from '../../../../graphql';
import { formatRange } from '../../../../utils/dateUtils';
import './styles.scss';
import ActionsMenu from '../components/ActionsMenu';
import { exportToCSV } from '../utils';

const i18nOpts = { scope: 'components.admin.overview.leadGeneration.index' };

const KEYS = ['ownlyVisitorsCount', 'ownlyReturningVisitorsCount', 'ownlyLeadsCount', 'webtrackerVisitorsCount', 'webtrackerReturningVisitorsCount', 'webtrackerLeadsCount'];
const humanizedCategoriesPath = ['ownlyVisitors', 'ownlyReturningVisitors', 'ownlyLeadsCaptured', 'webtrackerVisitors', 'webtrackerReturningVisitors', 'webtrackerLeadsCaptured'];

function fakeValue() {
  return Math.round(Math.random() * 100);
}

function fetchLeadGenerationsAsync(filter) {
  const variables = {
    reportFilter: {
      ...filter
    }
  };
  return api
    .graphql(getLeadGenerationQuery, variables)
    .then(({ data: { results } }) => Promise.resolve(results));
}

function generateSampleDataAsync() {
  const leadGenerations = [];
  let months = 6;
  let currentDate = moment();
  while (months > 0) {
    const dateDivision = currentDate.format('MMM YYYY');

    leadGenerations.push({
      dateDivision,
      visitors: {
        ownlyVisitorsCount: fakeValue() + fakeValue(),
        widgetVisitorsCount: fakeValue() + fakeValue(),
        webtrackerVisitorsCount: fakeValue() + fakeValue()
      },
      returningVisitors: {
        ownlyVisitorsCount: fakeValue(),
        widgetVisitorsCount: fakeValue(),
        webtrackerVisitorsCount: fakeValue()
      },
      capturedLeads: {
        ownlyLeadsCount: fakeValue(),
        webtrackerLeadsCount: fakeValue()
      }
    });

    months -= 1;
    currentDate = currentDate.subtract(1, 'months');
  }
  return Promise.resolve(leadGenerations.reverse());
}

function fetchLeadGenerationDataAsync(filter, company) {
  return company.enableSampleData
    ? generateSampleDataAsync()
    : fetchLeadGenerationsAsync(filter);
}

function getMappedTotalCount(items) {
  return items.reduce((prev, current) => ({
    [KEYS[0]]: prev[KEYS[0]] + current.visitors.ownlyVisitorsCount
    + current.visitors.widgetVisitorsCount,
    [KEYS[1]]: prev[KEYS[1]] + current.returningVisitors.ownlyVisitorsCount
    + current.returningVisitors.widgetVisitorsCount,
    [KEYS[2]]: prev[KEYS[2]] + current.capturedLeads.ownlyLeadsCount,
    [KEYS[3]]: prev[KEYS[3]] + current.visitors.webtrackerVisitorsCount,
    [KEYS[4]]: prev[KEYS[4]] + current.returningVisitors.webtrackerVisitorsCount,
    [KEYS[5]]: prev[KEYS[5]] + current.capturedLeads.webtrackerLeadsCount,
  }), ({
    [KEYS[0]]: 0,
    [KEYS[1]]: 0,
    [KEYS[2]]: 0,
    [KEYS[3]]: 0,
    [KEYS[4]]: 0,
    [KEYS[5]]: 0
  }));
}

class LeadGeneration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isLegendHover: false,
      leadGenerations: [],
      totalCountPerCategory: {},
      dateRangeLabel: '',
      tooltipText: null,
      tooltipProperties: {}
    };

    this.getData = this.getData.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.getLegend = this.getLegend.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!this.propsDidNotChange(prevProps)) {
      this.loadData();
    }
  }

  onExport() {
    const { leadGenerations, dateRangeLabel } = this.state;
    const headers = [
      i18n.t('date', i18nOpts),
      i18n.t('ownlyVisitors', i18nOpts),
      i18n.t('ownlyReturningVisitors', i18nOpts),
      i18n.t('ownlyLeadsCaptured', i18nOpts),
      i18n.t('webtrackerVisitors', i18nOpts),
      i18n.t('webtrackerReturningVisitors', i18nOpts),
      i18n.t('webtrackerLeadsCaptured', i18nOpts)
    ];
    const rows = leadGenerations.map((item) => [
      item.dateDivision,
      item.visitors.ownlyVisitorsCount + item.visitors.widgetVisitorsCount,
      item.returningVisitors.ownlyVisitorsCount + item.returningVisitors.widgetVisitorsCount,
      item.capturedLeads.ownlyLeadsCount,
      item.visitors.webtrackerVisitorsCount,
      item.returningVisitors.webtrackerVisitorsCount,
      item.capturedLeads.webtrackerLeadsCount
    ]);
    exportToCSV(headers, rows, `Lead Generation ${dateRangeLabel}.csv`);
  }

  getData() {
    const { leadGenerations } = this.state;
    return {
      labels: leadGenerations.map((c) => c.dateDivision.toUpperCase()),
      datasets: [
        {
          label: i18n.t('ownlyVisitors', i18nOpts)
            .toUpperCase(),
          backgroundColor: '#BAC638',
          borderWidth: 0,
          hoverBackgroundColor: '#BAC638',
          data: leadGenerations.map((c) => c.visitors.ownlyVisitorsCount
            + c.visitors.widgetVisitorsCount),
          stack: 'visitors',
        },
        {
          label: i18n.t('ownlyReturningVisitors', i18nOpts)
            .toUpperCase(),
          backgroundColor: '#00AF8C',
          borderWidth: 0,
          hoverBackgroundColor: '#00AF8C',
          data: leadGenerations.map((c) => c.returningVisitors.ownlyVisitorsCount
            + c.returningVisitors.widgetVisitorsCount),
          stack: 'returningVisitors',
        },
        {
          label: i18n.t('ownlyLeadsCaptured', i18nOpts)
            .toUpperCase(),
          backgroundColor: '#4D4D4D',
          borderWidth: 0,
          hoverBackgroundColor: '#4D4D4D',
          data: leadGenerations.map((c) => c.capturedLeads.ownlyLeadsCount),
          stack: 'leadsCaptured',
        },
        {
          label: i18n.t('webtrackerVisitors', i18nOpts)
            .toUpperCase(),
          backgroundColor: '#DCE29A',
          borderWidth: 0,
          hoverBackgroundColor: '#DCE29A',
          data: leadGenerations.map((c) => c.visitors.webtrackerVisitorsCount),
          stack: 'visitors',
        },
        {
          label: i18n.t('webtrackerReturningVisitors', i18nOpts)
            .toUpperCase(),
          backgroundColor: '#82D8C7',
          borderWidth: 0,
          hoverBackgroundColor: '#82D8C7',
          data: leadGenerations.map((c) => c.returningVisitors.webtrackerVisitorsCount),
          stack: 'returningVisitors',
        },
        {
          label: i18n.t('webtrackerLeadsCaptured', i18nOpts)
            .toUpperCase(),
          backgroundColor: '#ABABAB',
          borderWidth: 0,
          hoverBackgroundColor: '#ABABAB',
          data: leadGenerations.map((c) => c.capturedLeads.webtrackerLeadsCount),
          stack: 'leadsCaptured',
        }
      ],
    };
  }

  getOptions() {
    return {
      scales: {
        x: {
          stacked: true,
          ticks: {
            font: {
              color: '#000000',
              weight: '800',
              size: 10,
            },
          },
        },
        y: {
          stacked: true,
          ticks: {
            fontColor: '#000000',
            font: {
              weight: '800',
              size: 10,
            },
            stepSize: 1
          }
        },
      },
      plugins: {
        legend: this.getLegend(),
      },
    };
  }

  getLegend() {
    const { totalCountPerCategory, isLegendHover } = this.state;
    return {
      align: 'end',
      labels: {
        color: '#000000',
        font: {
          weight: '500',
          size: 10,
        },
        fontSize: 10,
        boxWidth: 10,
      },
      onHover: (event, item) => {
        if (isLegendHover) return;
        const { datasetIndex } = item;
        const categoryHumanized = i18n.t(humanizedCategoriesPath[datasetIndex], i18nOpts);
        const tooltipText = i18n.t('totalPerCategory', { ...i18nOpts, count: totalCountPerCategory[KEYS[datasetIndex]], category: categoryHumanized });
        this.setState({
          isLegendHover: true,
          tooltipProperties: { x: event.x, y: event.y, fillStyle: item.fillStyle },
          tooltipText
        });
      },
      onLeave: () => {
        this.setState({ isLegendHover: false });
      }
    };
  }

  propsDidNotChange(prevProps) {
    const { props } = this;
    const { filter, currentCompany } = props;
    const {
      filter: prevFilter,
      currentCompany: prevCurrentCompany,
    } = prevProps;

    return JSON.stringify(filter) === JSON.stringify(prevFilter)
      && JSON.stringify(currentCompany) === JSON.stringify(prevCurrentCompany);
  }

  loadData() {
    const { filter, currentCompany } = this.props;
    if (!filter) return;

    this.setState({ loading: true });
    const dateRangeLabel = formatRange(filter?.customDateRange);
    fetchLeadGenerationDataAsync(filter, currentCompany)
      .then((items) => {
        const totalCountPerCategory = getMappedTotalCount(items);
        const leadGenerations = items;
        this.setState({
          totalCountPerCategory, leadGenerations, dateRangeLabel
        });
      })
      .catch(() => {})
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const {
      leadGenerations, loading, dateRangeLabel, tooltipText, tooltipProperties, isLegendHover
    } = this.state;
    const data = this.getData(leadGenerations);

    return (
      <Card body>
        <div className="d-flex align-items-center">
          <h4>{i18n.t('title', i18nOpts)}</h4>
          <div className="ml-auto">
            <ActionsMenu onExport={this.onExport} />
          </div>
        </div>

        <div className="text-uppercase text-right mb-3">
          <small>{dateRangeLabel}</small>
        </div>

        {loading ? (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        ) : (
          <Bar data={data} options={{ ...this.getOptions() }} />
        )}
        { isLegendHover && (
          <div
            id="lead-generation-legend-tooltip"
            className="d-flex align-items-center"
            style={{ top: `calc(${tooltipProperties.y}px + 3.8vh)`, left: `calc(${tooltipProperties.x}px - 4vh)` }}
          >
            <span className="fill-demo mr-1 pt-1" style={{ background: tooltipProperties.fillStyle }} />
            <span>{tooltipText}</span>
          </div>
        )}
      </Card>
    );
  }
}

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  currentCommunity: store.overview.community,
  currentModel: store.overview.model,
}), {})(LeadGeneration);
