import React, { useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import { setCustomerFilters } from '../../../../../store/actions/customerActions';
import BaseSalesRepDropdown from '../../../../global/SalesRepDropdown';

const i18nOpts = { scope: 'components.admin.customers.list.assignedToFilter.index' };

const AssignedToFilter = ({ currentCompany, selectedFilters, ...props }) => {
  const [selectedSalesRep, setSelectedSalesRep] = useState(null);

  const onSelect = (newSalesRep) => {
    setSelectedSalesRep(newSalesRep);

    const salesLeadId = newSalesRep?.id || null;
    const salesLeadType = newSalesRep?.__typename;
    props.setCustomerFilters({ ...selectedFilters, salesLeadId, salesLeadType });
  };

  return (
    <BaseSalesRepDropdown
      onChange={onSelect}
      selectedSalesRep={selectedSalesRep}
      defaultLabel={i18n.t('select', i18nOpts)}
    />
  );
};

AssignedToFilter.propTypes = {};

AssignedToFilter.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  selectedFilters: store.customers.filters
}), {
  setCustomerFilters
})(AssignedToFilter);
