import React from 'react';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import moment from 'moment';

import { DateFormats } from '../../../../../constants';
import { getRoleName } from '../../../../../utils/userUtils';
import { isMasterAdmin } from '../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.users.show.profile.index' };

const Profile = ({ user }) => {
  if (!user) return null;

  return (
    <div>
      <div className="mb-4">
        <div className="label">{i18n.t('id', i18nOpts)}</div>
        <div><strong>{user.hashId}</strong></div>
      </div>

      <div className="mb-4">
        <div className="label">{i18n.t('email', i18nOpts)}</div>
        <div><strong>{user.email}</strong></div>
      </div>

      {user.phones?.length > 0 && (
        <div className="mb-4">
          <div className="label">{i18n.t('phone', i18nOpts)}</div>
          <div>
            {
              user.phones.map((phone) => (<strong key={`phone-${phone}`} className="d-block">{phone}</strong>))
            }
          </div>
        </div>
      )}

      {isMasterAdmin() && (
        <div className="mb-4">
          <div className="label">{i18n.t('company', i18nOpts)}</div>
          <div><strong>{user.company.name}</strong></div>
        </div>
      )}

      <div className="mb-4">
        <div className="label">{i18n.t('role', i18nOpts)}</div>
        <div><strong>{getRoleName(user.role)}</strong></div>
      </div>

      {user.city && (
        <div className="mb-4">
          <div className="label">{i18n.t('city', i18nOpts)}</div>
          <div><strong>{user.city.name}</strong></div>
        </div>
      )}

      <div className="mb-4">
        <div className="label">{i18n.t('createdAt', i18nOpts)}</div>
        <div><strong>{moment(user.createdAt).format(DateFormats.MMM_DD_YYYY)}</strong></div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.objectOf(PropTypes.any)
};

Profile.defaultProps = {
  user: null
};

export default Profile;
