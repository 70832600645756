import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ModalBody } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import SelectionButton from '../../components/SelectionButton';
import { SalesInvitationTypes, Steps } from '../constants';
import VerifiedIcon from '../../../../../assets/icons/sales-invitation/VerifiedIcon';
import ReserveNowIcon from '../../../../../assets/icons/sales-invitation/ReserveNowIcon';
import { setInvitationType } from '../../../../../store/actions/salesInvitationActions';
import useInvitationStartType from '../hooks/useInvitationStartType';
import useIsVerifiedEnabled from '../../../../../hooks/useIsVerifiedEnabled';
import useIsReserveNowEnabled from '../../../../../hooks/useIsReserveNowEnabled';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.invitationType.index' };

const InvitationType = ({ onContinue, ...props }) => {
  const { isFromCustomer } = useInvitationStartType();
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const onVerified = () => {
    const nextStep = isFromCustomer ? Steps.CONFIRM_INVITATION : Steps.CUSTOMER_SELECTION_TYPE;

    props.setInvitationType(SalesInvitationTypes.VERIFIED)
      .then(() => onContinue(nextStep));
  };

  const onReserveNow = () => {
    props.setInvitationType(SalesInvitationTypes.RESERVE_NOW)
      .then(() => onContinue(Steps.HOME_SELECTION_TYPE));
  };

  return (
    <ModalBody className="p-0">
      <div className="pt-0 px-3 pb-3 border-bottom">
        <div className="font-18 font-weight-600 mb-1">{i18n.t('title', i18nOpts)}</div>
        <div className="font-14">{i18n.t('description', i18nOpts)}</div>
      </div>

      <div className="p-3">
        <Row>
          {isVerifiedEnabled && (
            <Col>
              <SelectionButton
                title={i18n.t('buttons.verified', i18nOpts)}
                Icon={VerifiedIcon}
                onClick={onVerified}
              />
            </Col>
          )}
          {isReserveNowEnabled && (
            <Col>
              <SelectionButton
                title={i18n.t('buttons.reserveNow', i18nOpts)}
                Icon={ReserveNowIcon}
                onClick={onReserveNow}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          {isVerifiedEnabled && (
            <Col>
              <div className="small text-center col-gray-500">{i18n.t('descriptions.verified', i18nOpts)}</div>
            </Col>
          )}
          {isReserveNowEnabled && (
            <Col>
              <div className="small text-center col-gray-500">{i18n.t('descriptions.reserveNow', i18nOpts)}</div>
            </Col>
          )}
        </Row>
      </div>
    </ModalBody>
  );
};

InvitationType.propTypes = {
  onContinue: PropTypes.func
};

InvitationType.defaultProps = {
  onContinue: () => {}
};

export default connect(() => ({}), {
  setInvitationType
})(InvitationType);
