import React from 'react';

const StripeIcon = ({ ...props }) => (
  <svg width="512" height="214" viewBox="0 0 512 214" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M35.9822 83.4844C35.9822 77.9378 40.5333 75.8044 48.0711 75.8044C58.88 75.8044 72.5333 79.0756 83.3422 84.9067V51.4844C71.5378 46.7911 59.8756 44.9422 48.0711 44.9422C19.2 44.9422 0 60.0178 0 85.1911C0 124.444 54.0444 118.187 54.0444 135.111C54.0444 141.653 48.3556 143.787 40.3911 143.787C28.5867 143.787 13.5111 138.951 1.56444 132.409V166.258C14.7911 171.947 28.16 174.364 40.3911 174.364C69.9733 174.364 90.3111 159.716 90.3111 134.258C90.1689 91.8756 35.9822 99.4133 35.9822 83.4844ZM132.124 16.4978L97.4222 23.8933L97.28 137.813C97.28 158.862 113.067 174.364 134.116 174.364C145.778 174.364 154.311 172.231 159.004 169.671V140.8C154.453 142.649 131.982 149.191 131.982 128.142V77.6533H159.004V47.36H131.982L132.124 16.4978ZM203.236 57.8844L200.96 47.36H170.24V171.804H205.796V87.4667C214.187 76.5156 228.409 78.5067 232.818 80.0711V47.36C228.267 45.6533 211.627 42.5244 203.236 57.8844ZM241.493 47.36H277.191V171.804H241.493V47.36ZM241.493 36.5511L277.191 28.8711V0L241.493 7.53778V36.5511ZM351.431 44.9422C337.493 44.9422 328.533 51.4844 323.556 56.0356L321.707 47.2178H290.418V213.049L325.973 205.511L326.116 165.262C331.236 168.96 338.773 174.222 351.289 174.222C376.747 174.222 399.929 153.742 399.929 108.658C399.787 67.4133 376.32 44.9422 351.431 44.9422ZM342.898 142.933C334.507 142.933 329.529 139.947 326.116 136.249L325.973 83.4844C329.671 79.36 334.791 76.5156 342.898 76.5156C355.84 76.5156 364.8 91.0222 364.8 109.653C364.8 128.711 355.982 142.933 342.898 142.933ZM512 110.08C512 73.6711 494.364 44.9422 460.658 44.9422C426.809 44.9422 406.329 73.6711 406.329 109.796C406.329 152.604 430.507 174.222 465.209 174.222C482.133 174.222 494.933 170.382 504.604 164.978V136.533C494.933 141.369 483.84 144.356 469.76 144.356C455.964 144.356 443.733 139.52 442.169 122.738H511.716C511.716 120.889 512 113.493 512 110.08ZM441.742 96.5689C441.742 80.4978 451.556 73.8133 460.516 73.8133C469.191 73.8133 478.436 80.4978 478.436 96.5689H441.742Z" fill="#6772E5" />
  </svg>
);

StripeIcon.propTypes = {};

StripeIcon.defaultProps = {};

export default StripeIcon;
