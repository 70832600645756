import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from 'i18n-js';
import _ from 'lodash';

import useFetchSalesReps from '../../../../../../hooks/useFetchSalesReps';
import useFetchTeams from '../../../../../../hooks/useFetchTeams';
import CollapsibleFilterGroup from '../../../../../common/CollapsibleFilterGroup';
import ShowMoreContent from '../../../../../common/ShowMoreContent';
import SalesRepItem from './SalesRepItem';
import SalesTeamItem from './SalesTeamItem';
import { isCompanyAdmin, isSalesLead } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.assignedToFilter.index' };

const AssignedToFilter = ({ currentCompany, filters, onChange }) => {
  const [availableSalesReps] = useFetchSalesReps(currentCompany.id);
  const salesLeads = filters.salesLeads || [];
  const { teams: availableSalesTeams } = useFetchTeams(currentCompany.id);

  const onToggle = (selectedItem, type) => {
    const newSelectedItem = selectedItem ? { id: selectedItem.id, __typename: type } : null;
    onChange({ salesLeads: _.xorWith(salesLeads, [newSelectedItem], _.isEqual) });
  };

  const filtersCount = useMemo(() => salesLeads.length, [filters]);

  if (!isCompanyAdmin() && !isSalesLead()) return null;
  if (!availableSalesTeams.length && !availableSalesReps.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      {availableSalesTeams.length > 0 && (
        <div className="pl-2">
          <div className="mb-2">{i18n.t('salesTeams', i18nOpts)}</div>
          <ShowMoreContent
            items={availableSalesTeams}
            renderItem={(item) => (
              <SalesTeamItem
                key={`assigned-to-filter-sales-team-${item.id}`}
                item={item}
                selectedItems={salesLeads}
                onChange={onToggle}
              />
            )}
          />
        </div>
      )}

      {availableSalesReps.length > 0 && (
        <div className={classNames('pl-2', { 'pt-2': availableSalesTeams.length > 0 })}>
          <div className="mb-2">{i18n.t('salesRepresentatives', i18nOpts)}</div>
          <ShowMoreContent
            items={availableSalesReps}
            renderItem={(item) => (
              <SalesRepItem
                key={`assigned-to-filter-sales-rep-${item.id}`}
                item={item}
                selectedItems={salesLeads}
                onChange={onToggle}
              />
            )}
            showMoreButtonExtraClass="mt-2"
          />
        </div>
      )}
    </CollapsibleFilterGroup>
  );
};

AssignedToFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

AssignedToFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(AssignedToFilter);
