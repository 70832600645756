import api from '../../../../../../api';

export function fetchCommunitiesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListCommunities($filter: CommunityFilterInput) {
      result: listCommunities(filter: $filter, pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `, variables)
    .then(({ data: { result: { items } } }) => items);
}

export function fetchModelsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListModels($filter: ModelFilterInput) {
      result: listModels(filter: $filter, pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `, variables)
    .then(({ data: { result: { items } } }) => items);
}
