import React, { useEffect, useRef } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { connect } from 'react-redux';

import styles from './CustomerProgressPopover.module.scss';
import ModalCloseButton from '../../../common/ModalCloseButton';
import { CustomerProgressTypes, ModalNames } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import VerificationContent from './VerificationContent';
import ReservationContent from './ReservationContent';

function closeModal() {
  ModalService.close(ModalNames.CUSTOMER_PROGRESS);
}

const CustomerProgressPopover = ({ opened, params }) => {
  const containerRef = useRef(null);
  const { customerId, type } = params;

  const handleClickInDOM = (event) => {
    if (!containerRef.current) return;

    const isClickInsideContainer = containerRef.current.contains(event.target);
    if (!isClickInsideContainer) closeModal();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickInDOM);
    return () => {
      document.removeEventListener('mousedown', handleClickInDOM);
    };
  }, []);

  if (!opened) return null;

  return (
    <Popover
      placement="bottom"
      isOpen={opened}
      target={`customer-${type}-progress-${customerId}`}
      popperClassName={styles.popover}
      innerRef={containerRef}
    >
      <PopoverBody>
        <ModalCloseButton onClick={closeModal} />

        {type === CustomerProgressTypes.RESERVATION && (
          <ReservationContent customerId={customerId} />
        )}

        {type === CustomerProgressTypes.VERIFICATION && (
          <VerificationContent customerId={customerId} />
        )}
      </PopoverBody>
    </Popover>
  );
};

CustomerProgressPopover.propTypes = {

};

CustomerProgressPopover.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.CUSTOMER_PROGRESS]?.opened || false,
  params: store.modals[ModalNames.CUSTOMER_PROGRESS]?.params || {},
}))(CustomerProgressPopover);
