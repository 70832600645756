import React from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { InvitationHomeSortFields } from '../../constants';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.existingHome.index.sortDropdown.index' };

const SortDropdown = ({ sortField, onChange }) => (
  <UncontrolledDropdown>
    <DropdownToggle color="outline-secondary" size="sm" caret>
      {i18n.t(sortField, i18nOpts)}
    </DropdownToggle>
    <DropdownMenu>
      {
        Object.values(InvitationHomeSortFields).map((sf) => (
          <DropdownItem
            key={`invitation-home-${sf}`}
            onClick={() => onChange(sf)}
            active={sf === sortField}
          >
            {i18n.t(sf, i18nOpts)}
          </DropdownItem>
        ))
      }
    </DropdownMenu>
  </UncontrolledDropdown>
);

SortDropdown.propTypes = {
  onChange: PropTypes.func
};

SortDropdown.defaultProps = {
  onChange: () => {}
};

export default SortDropdown;
