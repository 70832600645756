import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import ProgressListItem from '../components/ProgressListItem';
import { fetchCustomerAsync, getReserveNowStep } from './utils';
import useReserveNowWorkflowSteps from '../../../../../hooks/useReserveNowWorkflowSteps';

const i18nOpts = { scope: 'components.global.customer.customerProgressPopover.reservationContent.index' };

const ReservationContent = ({
  customerId, currentCompany
}) => {
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const reserveNowWorkflowSteps = useReserveNowWorkflowSteps();

  const reserveNowSteps = useMemo(() => {
    if (!customer) return [];

    return reserveNowWorkflowSteps.map(
      (reserveNowStep) => getReserveNowStep(reserveNowStep, customer)
    );
  }, [reserveNowWorkflowSteps, customer]);

  const loadCustomer = () => {
    setLoading(true);

    fetchCustomerAsync(customerId, currentCompany.id)
      .then((item) => setCustomer(item))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadCustomer();
  }, []);

  return (
    <div>
      <h4>{i18n.t('title', i18nOpts)}</h4>

      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <div className="d-flex flex-column gap-4 mt-4">
          {
            reserveNowSteps.map((reserveNowStep) => (
              <ProgressListItem
                key={`reserve-step-${reserveNowStep.type}`}
                title={reserveNowStep.title}
                description={reserveNowStep.description}
                isCompleted={reserveNowStep.isCompleted}
                Icon={reserveNowStep.Icon}
              />
            ))
          }
        </div>
      )}
    </div>
  );
};

ReservationContent.propTypes = {
  customerId: PropTypes.number.isRequired,
};

ReservationContent.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(ReservationContent);
