import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import useFetchProductTypes from '../../../../../../../hooks/useFetchProductTypes';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';
import ShowMoreContent from '../../../../../../common/ShowMoreContent';
import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.components.quickPossessionFiltersModal.productTypeFilter.index' };

const ProductTypeFilter = ({ filters, onChange }) => {
  const productTypes = filters.productTypes || [];
  const { productTypes: availableProductTypes, loading } = useFetchProductTypes();

  const onToggle = (selectedItem) => {
    onChange({ productTypes: _.xorWith(productTypes, [selectedItem], _.isEqual) });
  };

  const filtersCount = useMemo(() => productTypes.length, [filters]);

  if (loading || !availableProductTypes.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableProductTypes}
        renderItem={(productType) => (
          <ListItem
            key={`product-type-${productType.id}`}
            item={productType}
            selectedItems={productTypes}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

ProductTypeFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

ProductTypeFilter.defaultProps = {
  onChange: () => {}
};

export default ProductTypeFilter;
