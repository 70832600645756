import v from 'voca';
import moment from 'moment';

import { ReserveStepTypes } from '../../../../constants';

export function getDealRecentActivityDate(home, journeySteps) {
  const { offer, deposit } = home;

  const dates = journeySteps.map((journeyStep) => {
    if (!offer) return null;

    const { signStatus, status: offerStatus } = offer;
    const depositStatus = deposit?.status;

    switch (journeyStep.type) {
      case ReserveStepTypes.OFFER:
        return v.isBlank(offerStatus) ? offer.createdAt : offer.statusChangeDates[`${v.camelCase(offerStatus)}At`];
      case ReserveStepTypes.SIGN:
        return v.isBlank(signStatus) ? null : offer.signStatusChangeDates[`${v.camelCase(signStatus)}At`];
      case ReserveStepTypes.DEPOSIT:
        return v.isBlank(depositStatus) ? null : deposit.statusChangeDates[`${v.camelCase(depositStatus)}At`];
      default:
        return null;
    }
  });

  const times = dates.filter((d) => !v.isBlank(d)).map((d) => moment(d).toDate().getTime());
  if (!times.length) return null;

  const recentDate = Math.max(...times);
  return moment(recentDate).toDate();
}
