import colorLib from '@kurkle/color';

const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(156, 219, 206)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(112, 72, 232)',
  grey: 'rgb(201, 203, 207)',
};

function transparentize(value, opacity) {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

export default {
  CHART_COLORS,
  transparentize
};
