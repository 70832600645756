import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

import { DEFAULT_IMAGE } from '../../../../../../constants';
import StatusIndicator from '../../../../../global/StatusIndicator';
import './styles.scss';

const ListItem = ({ project }) => {
  if (!project) return null;

  return (
    <div className="project-card">
      <Link to={`/admin/projects/${project.id}`} className="d-inline-block">
        <div className="project-image">
          <img src={project.imageUrl || DEFAULT_IMAGE} alt="" className="mb-0" />
          <div className="backdrop" />
        </div>
        <div className="d-flex align-items-center justify-content-center title-container">
          <h4 className="title">{project.name}</h4>
        </div>
      </Link>

      <StatusIndicator status={project.status} id={project.id} />
    </div>
  );
};

ListItem.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
