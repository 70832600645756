import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import { Button, Table } from 'reactstrap';
import { connect } from 'react-redux';

import IntegrationsBreadcrumbs from '../../../../global/IntegrationsBreadcrumbs';
import FormModal from '../FormModal';
import ListEmpty from '../../../../common/ListEmpty';
import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';
import ListItem from './ListItem';
import { cleanWebhooks, listWebhooks } from '../../../../../store/actions/webhookActions';
import Loading from '../../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.integrations.webhooks.list.index' };

const Webhooks = ({
  webhooks, loading, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);

  const loadWebhooks = () => {
    const variables = { filter: { companyId: currentCompany.id } };
    props.cleanWebhooks()
      .then(() => props.listWebhooks(variables))
      .catch((e) => toast.error(e.message))
      .finally(() => setMounted(true));
  };

  const onAdd = () => {
    ModalService.open(ModalNames.WEBHOOK_FORM);
  };

  useEffect(() => {
    loadWebhooks();
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div>
          <Button color="primary" onClick={onAdd}>
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Button>
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={webhooks} />

          {webhooks.length > 0 && (
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>{i18n.t('hookUrl', i18nOpts)}</th>
                  <th>{i18n.t('events', i18nOpts)}</th>
                  <th>{i18n.t('enabled', i18nOpts)}</th>
                  <th style={{ width: 50 }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {
                  webhooks.map((webhook) => (
                    <ListItem key={`webhook-${webhook.id}`} webhook={webhook} />
                  ))
                }
              </tbody>
            </Table>
          )}
        </>
      )}

      <FormModal />
    </div>
  );
};

Webhooks.propTypes = {};

Webhooks.defaultProps = {};

export default connect((store) => ({
  webhooks: store.webhooks.webhooks,
  loading: store.webhooks.listWebhooks.loading || false,
  currentCompany: store.companies.currentCompany
}), {
  cleanWebhooks,
  listWebhooks
})(Webhooks);
