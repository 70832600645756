import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import v from 'voca';

import { getDeposits } from '../../../../store/actions/depositActions';
import ListEmpty from '../../../common/ListEmpty';
import Loading from '../../../ui/Loading';
import ListView from './ListView';
import { DepositsSortFields, SortOrders } from '../../../../constants';
import setSortFilter from '../../../../utils/sortUtils';
import ListPagination from '../../../common/ListPagination';
import SearchBar from '../../../common/SearchBar';
import DepositRefundFormModal from '../../../global/Deposit/DepositRefundFormModal';
import DealModal from '../../../global/home/DealModal';

const i18nOpts = { scope: 'components.admin.deposits.list.index' };

const DEFAULT_SORT = { field: DepositsSortFields.DATE, direction: SortOrders.DESC };

const Deposits = ({
  deposits, loading, pagination, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState();
  const [sort, setSort] = useState(DEFAULT_SORT);

  const loadDeposits = (page = 1) => {
    const variables = {
      filter: {
        companyId: currentCompany.id,
        ...(!v.isBlank(query) ? { query } : {})
      },
      sort,
      page,
      withHome: true
    };
    props.getDeposits(variables)
      .finally(() => setMounted(true));
  };

  const onChangePage = (page) => {
    loadDeposits(page);
  };

  const onSearch = (e, q) => {
    e.preventDefault();

    setQuery(q);
  };

  const onSortChange = (field) => {
    setSort(setSortFilter(field, sort));
  };

  useEffect(() => {
    loadDeposits();
  }, [query, sort]);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center justify-content-between gap-3 mb-4">
        <div>
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>

        <SearchBar
          onSubmit={onSearch}
          inputProps={{ bsSize: 'md', placeholder: i18n.t('searchBar.placeholder', i18nOpts) }}
        />
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={deposits} />

          <ListView sort={sort} onSortChange={onSortChange} />

          <div className="text-right mt-4">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}

      <DealModal />
      <DepositRefundFormModal />
    </div>
  );
};

Deposits.propTypes = {};

Deposits.defaultProps = {};

export default connect((store) => ({
  deposits: store.deposits.deposits,
  loading: store.deposits.getDeposits.loading,
  pagination: store.deposits.pagination,
  currentCompany: store.companies.currentCompany
}), {
  getDeposits
})(Deposits);
