import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { getTeamUserPermissionName } from '../../../../../../../utils/enumUtils';
import ActionsMenu from './ActionsMenu';
import {
  canManageTeamMembers,
} from '../../../../../../../utils/teamUtils';

const ListItem = ({ teamUser, team }) => {
  const { user } = teamUser;

  const canManageMembers = canManageTeamMembers(team.userPermission);

  return (
    <tr>
      <td>
        <Link to={`/admin/users/${user.id}`} className="font-weight-600">{i18n.t('buttons.view')}</Link>
      </td>
      <td>
        <div className="font-weight-500 col-gray-900">{user.name}</div>
        <div className="col-gray-600">{user.email}</div>
      </td>
      <td>{getTeamUserPermissionName(teamUser.permission)}</td>
      {canManageMembers && (
        <td>
          <ActionsMenu teamUser={teamUser} />
        </td>
      )}
    </tr>
  );
};

ListItem.propTypes = {
  teamUser: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
  team: store.teams.team
}))(ListItem);
