import React from 'react';

const MenuFillIcon = ({ ...props }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.5 12H21.5M3.5 6H21.5M3.5 18H21.5" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

MenuFillIcon.propTypes = {};

MenuFillIcon.defaultProps = {};

export default MenuFillIcon;
