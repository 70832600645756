import React, { Component } from 'react';
import {
  Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Row, Col, Label
} from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io';
import { toast } from 'react-toastify';
import v from 'voca';

import { FloorOptionLogicalOperators, ModalNames } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import { removeItem } from '../../../../utils/arrayUtils';
import { saveFloorOption } from '../../../../store/actions/floorOptionActions';
import { getError } from '../../../../utils/requestUtils';
import { setReloadFloors } from '../../../../store/actions/floorActions';

const i18nOpts = { scope: 'components.global.floorsManager.floorOptionDependencyModal.index' };

const Operators = Object.freeze({
  IS: 'is',
  IS_NOT: 'isNot'
});

const DEFAULT_DEPENDENCY = Object.freeze({
  floor: null,
  group: null,
  operator: Operators.IS,
  values: []
});

const OperatorOptions = Object.freeze([
  { value: Operators.IS, label: i18n.t('operators.is', i18nOpts) },
  { value: Operators.IS_NOT, label: i18n.t('operators.isNot', i18nOpts) }
]);

function onClose() {
  ModalService.close(ModalNames.FLOOR_OPTION_DEPENDENCY);
}

function getSelectedFloorOptions(floorOptionsMap, dependency) {
  const floorOptions = floorOptionsMap[dependency.group] || [];
  return floorOptions.filter((fo) => dependency.values.includes(fo.id));
}

class FloorOptionDependencyModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dependencies: [],
      floorGroupsMap: {},
      floorOptionsMap: {},
      logicalOperator: null
    };

    this.onLogicalOperatorChange = this.onLogicalOperatorChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onRemoveDependency = this.onRemoveDependency.bind(this);
    this.onAddDependency = this.onAddDependency.bind(this);
    this.onChangeFloorOptions = this.onChangeFloorOptions.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.MultiSelect = require('multiselect-react-dropdown');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { opened } = this.props;
    if (!prevProps.opened && opened) this.init();
  }

  onLogicalOperatorChange(event) {
    const { target } = event;
    this.setState({ logicalOperator: target.value });
  }

  onTextChange(event, index) {
    const { dependencies } = this.state;
    const { name, value } = event.target;

    const dependency = dependencies[index];
    if ((name === 'group' && value !== dependency.group)
      || (name === 'floor' && value !== dependency.floor)) dependency.values = [];
    dependency[name] = value;

    this.setState({ dependencies });
  }

  onChangeFloorOptions(selectedFloorOptions, index) {
    const { dependencies } = this.state;
    const values = selectedFloorOptions.map((fo) => fo.id);

    const dependency = dependencies[index];
    dependency.values = values;

    this.setState({ dependencies });
  }

  onRemoveDependency(index) {
    const { dependencies } = this.state;
    const filteredDependencies = removeItem(dependencies, index);
    this.setState({ dependencies: filteredDependencies });
  }

  onAddDependency() {
    const { dependencies } = this.state;

    dependencies.push({ ...DEFAULT_DEPENDENCY });

    this.setState({ dependencies });
  }

  onSave() {
    const { props } = this;
    const input = this.getFormInput();

    const variables = { input };
    props.saveFloorOption(variables)
      .then(() => {
        props.setReloadFloors(true);
        ModalService.close(ModalNames.FLOOR_OPTION_DEPENDENCY);
      })
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
      });
  }

  getFormInput() {
    const { params: { floorOption } } = this.props;
    const { dependencies, logicalOperator } = this.state;

    const validDependencies = dependencies.filter((dependency) => dependency.group !== null
      && dependency.values.length > 0);

    return {
      id: floorOption.id,
      dependencies: validDependencies,
      dependenciesLogicalOperator: logicalOperator
    };
  }

  init() {
    const { floors, params: { floorGroup, floorOption } } = this.props;
    const floor = floors.find((f) => f.floorGroups.some((fg) => fg.id === floorGroup.id));

    const floorGroupsMap = {};
    const floorOptionsMap = {};
    floors.forEach((f) => {
      const { floorGroups } = f;
      floorGroupsMap[f.id] = floorGroups;
      floorGroups.forEach((fg) => {
        // Exclude current option
        floorOptionsMap[fg.id] = fg.floorOptions.filter((fo) => fo.id !== floorOption.id);
      });
    });

    let { dependencies } = floorOption;
    if (dependencies.length === 0) dependencies = [{ ...DEFAULT_DEPENDENCY, floor: floor.id }];

    this.setState({
      floorOptionsMap,
      floorGroupsMap,
      dependencies,
      logicalOperator: floorOption.dependenciesLogicalOperator
    });
  }

  render() {
    const { opened, saving, floors } = this.props;
    const {
      dependencies, floorGroupsMap, floorOptionsMap, logicalOperator
    } = this.state;

    return (
      <Modal isOpen={opened}>
        <ModalHeader>{i18n.t('title', i18nOpts)}</ModalHeader>

        <ModalBody>
          {
            dependencies.map((dependency, index) => {
              const floorGroups = floorGroupsMap[dependency.floor] || [];
              return (
                <Row key={`dependency-${index}`} className="mb-5">
                  <Col xs="12">
                    <FormGroup className="d-flex">
                      <Label for="floor-select">{i18n.t('floor', i18nOpts)}</Label>
                      <div className="flex-grow-1 ml-4">
                        <Input
                          type="select"
                          name="floor"
                          value={dependency.floor || ''}
                          onChange={(e) => this.onTextChange(e, index)}
                          id="floor-select"
                        >
                          <option value="">{i18n.t('select.select')}</option>
                          {
                          floors.map((f) => (
                            <option key={`floor-${f.id}-${index}`} value={f.id}>
                              {f.name}
                            </option>
                          ))
                        }
                        </Input>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="7" md="8" lg="8">
                    <FormGroup>
                      <Input
                        type="select"
                        name="group"
                        value={dependency.group || ''}
                        onChange={(e) => this.onTextChange(e, index)}
                      >
                        <option value="">{i18n.t('select.select')}</option>
                        {
                        floorGroups.map((f) => (
                          <option key={`floor-${f.id}-${index}`} value={f.id}>
                            {f.name}
                          </option>
                        ))
                      }
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="5" md="4" lg="4">
                    <FormGroup>
                      <Input
                        type="select"
                        name="operator"
                        value={dependency.operator}
                        onChange={(e) => this.onTextChange(e, index)}
                      >
                        {
                        OperatorOptions.map((o) => (
                          <option key={`operator-${o.value}-${index}`} value={o.value}>
                            {o.label}
                          </option>
                        ))
                      }
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="7" md="8" lg="8">
                    <div className="multi-select-container">
                      {this.MultiSelect && (
                      <this.MultiSelect.Multiselect
                        id={`floor-options-multiselect-${index}`}
                        options={floorOptionsMap[dependency.group] || []}
                        selectedValues={getSelectedFloorOptions(floorOptionsMap, dependency)}
                        onSelect={(items) => this.onChangeFloorOptions(items, index)}
                        onRemove={(items) => this.onChangeFloorOptions(items, index)}
                        displayValue="name"
                        closeIcon="cancel"
                      />
                      )}
                    </div>
                  </Col>
                  <Col xs="12" sm="5" md="4" lg="4" className="text-right pt-2">
                    {dependencies.length - 1 === index && (
                    <span onClick={this.onAddDependency} role="button" aria-hidden>
                      <IoMdAddCircle size="1.5rem" className="col-primary-1" />
                    </span>
                    )}
                    {dependencies.length > 1 && (
                    <span onClick={() => this.onRemoveDependency(index)} role="button" aria-hidden className="ml-3">
                      <IoMdRemoveCircle size="1.5rem" className="text-danger" />
                    </span>
                    )}
                  </Col>
                </Row>
              );
            })
          }
          { dependencies.length > 1 && (
            <Row>
              <Col xs="12">
                <FormGroup check>
                  <Label for="type">
                    <Input
                      type="radio"
                      name="type"
                      checked={(logicalOperator
                        === FloorOptionLogicalOperators.OR)}
                      value={FloorOptionLogicalOperators.OR}
                      onChange={this.onLogicalOperatorChange}
                    />
                    {i18n.t('oneCondition', i18nOpts)}
                  </Label>
                </FormGroup>

                <FormGroup check>
                  <Label check for="type">
                    <Input
                      type="radio"
                      name="type"
                      checked={(logicalOperator
                        === FloorOptionLogicalOperators.AND)}
                      value={FloorOptionLogicalOperators.AND}
                      onChange={this.onLogicalOperatorChange}
                    />
                    {i18n.t('allConditions', i18nOpts)}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onClose} disabled={saving}>
            {i18n.t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect((store) => ({
  opened: store.modals[ModalNames.FLOOR_OPTION_DEPENDENCY]?.opened || false,
  params: store.modals[ModalNames.FLOOR_OPTION_DEPENDENCY]?.params || {},
  floors: store.floors.floors,
  saving: store.floorOptions.saveFloorOption.loading
}), {
  saveFloorOption,
  setReloadFloors
})(FloorOptionDependencyModal);
