import React from 'react';
import PropTypes from 'prop-types';

import ListItem from './ListItem';
import { groupAndSortProjectBuildings } from '../utils';

const GridView = ({ projectBuildings }) => {
  const groupedProjectBuildings = groupAndSortProjectBuildings(projectBuildings);

  return (
    <div>
      {
        groupedProjectBuildings.map((p) => (
          <ListItem project={p} key={`project-list-${p.id}`} />
        ))
      }
    </div>
  );
};

GridView.propTypes = {
  projectBuildings: PropTypes.arrayOf(PropTypes.any).isRequired
};

GridView.defaultProps = {};

export default GridView;
