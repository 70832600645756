import React from 'react';
import { TagInput as BaseTagInput } from 'reactjs-tag-input';

const TagInput = ({ ...props }) => (
  <BaseTagInput
    wrapperStyle={`
      background: none;
      position: relative;
      box-shadow: none;
      transform:none;
      left: 0;
      top: 0;
      width: 100%;
      border: 1px solid #D0D5DD;
      border-radius: 8px;
      padding: 4px;
      font-family: 'Poppins';
    `}
    inputStyle={`
      background-color: white;
      padding: 0 8px;
      font-size: 1rem;
      color: black;
      width: 100%;
      height: 30px;
      vertical-align: top;
      &::-webkit-input-placeholder {
        font-style: normal;
      }
    `}
    tagStyle={`
      background: #00AF8C;
      font-size: 1rem;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: default;
    `}
    tagDeleteStyle={`
      padding-left: 6px;
      opacity: 0.7;
      font-size: 15px;
      line-height: 10px;
      cursor: pointer;
      :hover {
       color: white;
      }
    `}
    {...props}
  />
);

TagInput.propTypes = {};

TagInput.defaultProps = {};

export default TagInput;
