import React from 'react';
import i18n from 'i18n-js';

import useHomeRental from '../../../../../../../hooks/useHomeRental';
import useIsVerifiedEnabled from '../../../../../../../hooks/useIsVerifiedEnabled';

const i18nOpts = { scope: 'components.admin.sales.components.dealList.list.listHeader.index' };

const ListHeader = () => {
  const { typeI18nKey } = useHomeRental();
  const isVerifiedEnabled = useIsVerifiedEnabled();

  return (
    <tr>
      <th className="fixed-column">{i18n.t('customer', i18nOpts)}</th>
      <th>{i18n.t('dealPrice', i18nOpts)}</th>
      <th>{i18n.t('dealState', i18nOpts)}</th>
      <th>{i18n.t('modelUnit', i18nOpts)}</th>
      <th>{i18n.t('communityProject', i18nOpts)}</th>
      {isVerifiedEnabled && (
        <th>{i18n.t(`${typeI18nKey}.financialAmount`, i18nOpts)}</th>
      )}
      <th>{i18n.t('realtor', i18nOpts)}</th>
      <th>{i18n.t('lastActive', i18nOpts)}</th>
    </tr>
  );
};

ListHeader.propTypes = {};

ListHeader.defaultProps = {};

export default ListHeader;
