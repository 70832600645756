import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { DateFormats } from '../../../../../../constants';

const ActivityDate = ({ date }) => {
  if (!date) return null;

  const timezone = moment.tz.guess();
  const formattedDate = moment(date).tz(timezone).format(DateFormats.H_MM_A);

  return (
    <small className="text-nowrap line-height-21">
      {formattedDate}
    </small>
  );
};

ActivityDate.propTypes = {
  date: PropTypes.string
};

ActivityDate.defaultProps = {
  date: null
};

export default ActivityDate;
