import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ImagesManager from '../../../../global/ImagesManager';
import {
  deletePaletteImageAsync,
  fetchPaletteImagesAsync,
  savePaletteImageAsync,
  sortPaletteImagesAsync
} from './utils';

const Images = ({ palette }) => {
  const [paletteImages, setPaletteImages] = useState([]);

  const onDrop = (images) => {
    const promises = images.map((file) => uploadImage(file));
    return Promise.all(promises)
      .finally(() => loadPaletteImages());
  };

  const loadPaletteImages = () => {
    const variables = { filter: { paletteId: palette.id } };
    fetchPaletteImagesAsync(variables)
      .then((items) => setPaletteImages(items))
      .catch(() => setPaletteImages([]));
  };

  const uploadImage = (image) => {
    const input = {
      paletteId: palette.id
    };

    const variables = { input, image };
    return savePaletteImageAsync(variables);
  };

  const onSortPaletteImages = () => {
    const ids = paletteImages.map((pi) => pi.id);
    const variables = { paletteId: palette.id, ids };

    return sortPaletteImagesAsync(variables);
  };

  const onSetPaletteImages = (images) => {
    setPaletteImages(images);
  };

  const onDeletePaletteImage = (paletteImage) => {
    deletePaletteImageAsync(paletteImage.id);
  };

  const onMarkAsDefault = (paletteImage) => {
    const input = {
      id: paletteImage.id,
      paletteId: palette.id,
      isDefault: true
    };

    const variables = { input };
    savePaletteImageAsync(variables)
      .then(() => {
        loadPaletteImages();
      })
      .catch(() => {});
  };

  useEffect(() => {
    loadPaletteImages();
  }, []);

  return (
    <ImagesManager
      images={paletteImages}
      onSortImages={onSortPaletteImages}
      onSetImages={onSetPaletteImages}
      onDeleteImage={onDeletePaletteImage}
      onDrop={onDrop}
      onMarkAsDefault={onMarkAsDefault}
    />
  );
};

export default connect((store) => ({
  palette: store.palettes.palette,
}), {})(Images);
