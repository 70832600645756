import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import filesize from 'filesize';
import i18n from 'i18n-js';
import moment from 'moment';
import classnames from 'classnames';

import { getDocuments, deleteDocument } from '../../../../store/actions/documentActions';
import { DateFormats } from '../../../../constants';
import FileTypeIcon from '../../../common/FileTypeIcon';
import ActionsMenu from '../../../global/ActionsMenu';
import ListEmpty from '../../../common/ListEmpty';
import ListPagination from '../../../common/ListPagination';
import { isRecently } from '../../../../utils/dateUtils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.documents.list.index' };

const List = ({
  documents, pagination, loading, documentFiles, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    loadDocuments();
  }, []);

  useEffect(() => {
    if (!mounted) return;

    loadDocuments();
  }, [documentFiles.length]);

  const onDelete = (id) => {
    props.deleteDocument(id);
  };

  const onChangePage = (page) => {
    loadDocuments(page);
  };

  const loadDocuments = (page = 1) => {
    const variables = { filter: { companyId: currentCompany.id }, page };
    props.getDocuments(variables)
      .finally(() => setMounted(true));
  };

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={documents} />

          {documents.length > 0 && (
            <div className="table-responsive">
              <Table bordered>
                <thead>
                  <tr>
                    <th>{i18n.t('name', i18nOpts)}</th>
                    <th>{i18n.t('size', i18nOpts)}</th>
                    <th>{i18n.t('modifiedDate', i18nOpts)}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    documents.map((document) => (
                      <tr key={`document-${document.id}`}>
                        <td>
                          <div className="d-inline-block mr-3">
                            <FileTypeIcon filename={document.name} size="sm" />
                          </div>

                          <a href={document.fileUrl} target="_blank" rel="noreferrer">
                            {document.name}
                          </a>
                        </td>
                        <td>{filesize(document.byteSize)}</td>
                        <td>
                          <span className={classnames({ 'text-success': isRecently(document.updatedAt) })}>
                            {moment(document.updatedAt).format(DateFormats.MMM_DD_YYYY_HH_MM_SS)}
                          </span>
                        </td>
                        <td className="text-right">
                          <ActionsMenu item={document} onDelete={onDelete} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          )}

          <div className="text-right mt-4">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  documents: store.documents.documents,
  pagination: store.documents.pagination,
  loading: store.documents.getDocuments.loading,
  documentFiles: store.documents.documentFiles,
  currentCompany: store.companies.currentCompany
}), {
  getDocuments,
  deleteDocument
})(List);
