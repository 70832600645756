import { FiChevronsRight } from 'react-icons/fi';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Item = ({ item, isFirst, isLast }) => {
  if (isLast) return (<span className={`font-24 ${!isFirst && 'ml-3'}`}>{item.text}</span>);
  return (
    <>
      <Link to={item.url} className={`font-24 mr-3 ${!isFirst && 'ml-3'}`}>{item.text}</Link>
      <FiChevronsRight size="1.5rem" />
    </>
  );
};

const Breadcrumbs = ({ items }) => (
  <div className="d-flex align-items-center">
    {
      items.map((item, index) => (
        <Item
          key={`breadcrumb-item-${item.text}`}
          item={item}
          isFirst={index === 0}
          isLast={index === items.length - 1}
        />
      ))
    }
  </div>
);

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string.isRequired
  })).isRequired
};

Breadcrumbs.defaultProps = {};

export default Breadcrumbs;
