import React from 'react';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

import './styles.scss';
import ActionsMenu from '../../../../global/ActionsMenu';
import { deleteCommunity, setCommunitySearchFilters } from '../../../../../store/actions/communityActions';
import { isAdmin } from '../../../../../utils/authUtils';
import LastModifiedByTableItem from '../../../../global/LastModifiedByTableItem';
import CreatedByTableItem from '../../../../global/CreatedByTableItem';
import SortableColumnHeader from '../../../../common/SortableColumnHeader';

import setSortFilter from '../../../../../utils/sortUtils';

const i18nOpts = { scope: 'components.admin.communities.list.listView.index' };

const ListView = ({ communities, searchFilters, ...props }) => {
  const onEdit = (community) => {
    navigate(`/admin/communities/form?id=${community.id}`);
  };

  const onDelete = (communityId) => {
    props.deleteCommunity(communityId);
  };

  const onSortChange = (field) => {
    const sort = setSortFilter(field, searchFilters.sort);
    props.setCommunitySearchFilters({ sort });
  };

  return (
    <div className="table-responsive">
      <Table id="communities-table" bordered>
        <thead>
          <tr>
            <SortableColumnHeader
              field="name"
              label={i18n.t('name', i18nOpts)}
              onPress={() => onSortChange('name')}
              sort={searchFilters.sort}
            />
            <th>{i18n.t('city', i18nOpts)}</th>
            <th>{i18n.t('state', i18nOpts)}</th>
            <th>{i18n.t('modelsCount', i18nOpts)}</th>
            <SortableColumnHeader
              field="status"
              label={i18n.t('status', i18nOpts)}
              onPress={() => onSortChange('status')}
              sort={searchFilters.sort}
            />
            {isAdmin() && (
            <>
              <th>{i18n.t('createdBy', i18nOpts)}</th>
              <th>{i18n.t('lastModifiedBy', i18nOpts)}</th>
              <th>&nbsp;</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {
          communities.map((community) => (
            <tr key={`community-${community.id}`}>
              <td>
                <Link to={`/admin/communities/${community.id}`}>
                  {community.name}
                </Link>
              </td>
              <td>{community.city?.name || ''}</td>
              <td>{community.city?.state || ''}</td>
              <td>{community.modelsCount > 0 ? community.modelsCount : ''}</td>
              <td>
                <span className={`badge text-white badge-${community.status.toLowerCase()}`}>
                  {i18n.t(`statuses.${community.status.toLowerCase()}`)}
                </span>
              </td>
              {isAdmin() && (
                <>
                  <CreatedByTableItem instance={community} />
                  <LastModifiedByTableItem instance={community} />
                  <td className="text-right">
                    <ActionsMenu
                      item={community}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  </td>
                </>
              )}
            </tr>
          ))
        }
        </tbody>
      </Table>
    </div>
  );
};

export default connect((store) => ({
  communities: store.communities.communities,
  searchFilters: store.communities.searchFilters,
}), {
  deleteCommunity,
  setCommunitySearchFilters
})(ListView);
