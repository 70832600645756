import i18n from 'i18n-js';

import { UnitAvailabilities } from '../constants';

const UnitAvailabilityLabels = {
  [UnitAvailabilities.AVAILABLE]: i18n.t('unitAvailabilities.available'),
  [UnitAvailabilities.NOT_AVAILABLE]: i18n.t('unitAvailabilities.notAvailable'),
  [UnitAvailabilities.COMING_SOON]: i18n.t('unitAvailabilities.comingSoon'),
  [UnitAvailabilities.SOLD]: i18n.t('unitAvailabilities.sold'),
  [UnitAvailabilities.RENTED]: i18n.t('unitAvailabilities.rented')
};

export function getUnitAvailabilityName(availability) {
  return UnitAvailabilityLabels[availability];
}
