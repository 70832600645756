import { useSelector } from 'react-redux';

import { getValidColumns } from '../utils';

const useValidSelectedColumns = () => {
  const { columns, currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany,
    columns: store.customers.columns
  }));

  return getValidColumns(columns, currentCompany);
};

export default useValidSelectedColumns;
