import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import v from 'voca';

import styles from './ProgressListItem.module.scss';

const ProgressListItem = ({
  title, description, Icon, isCompleted
}) => (
  <div className={classNames(styles.container, { [styles.containerCompleted]: isCompleted })}>
    <div className={classNames(styles.iconContainer, { [styles.iconCompleted]: isCompleted })}>
      {Icon}
    </div>
    <div>
      <div className={classNames('font-weight-600', styles.title, { [styles.titleCompleted]: isCompleted })}>
        {title}
      </div>
      {!v.isBlank(description) && (
        <div className={classNames({ [styles.descriptionCompleted]: isCompleted })}>
          {description}
        </div>
      )}
    </div>
  </div>
);

ProgressListItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  Icon: PropTypes.element,
  isCompleted: PropTypes.bool
};

ProgressListItem.defaultProps = {
  description: null,
  Icon: null,
  isCompleted: false
};

export default ProgressListItem;
