import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ListItem from './ListItem';
import { isAdmin } from '../../../../../utils/authUtils';
import AddDropDown from './AddDropDown';
import { listModelPackages, reloadModelPackages } from '../../../../../store/actions/modelPackageActions';

const Packages = ({
  modelPackages, model, reload, ...props
}) => {
  useEffect(() => {
    loadPackages();
  }, []);

  useEffect(() => {
    if (!reload) return;

    loadPackages();
  }, [reload]);

  const loadPackages = () => {
    const variables = { filter: { modelId: model.id } };

    props.listModelPackages(variables)
      .finally(() => {
        props.reloadModelPackages(false);
      });
  };

  return (
    <div>
      {isAdmin() && (
        <div className="d-flex justify-content-end mb-3">
          <AddDropDown />
        </div>
      )}

      {
        modelPackages.map((mp) => (
          <ListItem key={`model-package-${mp.id}`} modelPackage={mp} />
        ))
      }
    </div>
  );
};

export default connect((store) => ({
  model: store.models.model,
  modelPackages: store.modelPackages.modelPackages,
  reload: store.modelPackages.reload
}), {
  listModelPackages,
  reloadModelPackages
})(Packages);
