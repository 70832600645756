import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency, isValidAmount } from '../../../../../../../../utils/currencyUtils';

const HomeValuation = ({ customer }) => {
  const { propertyInfo, isHomeVerified } = customer;
  const { homeValue } = propertyInfo || {};

  if (!isHomeVerified || !isValidAmount(homeValue)) return null;

  return (
    <div className="text-nowrap">
      {formatCurrency(homeValue)}
    </div>
  );
};

HomeValuation.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

HomeValuation.defaultProps = {};

export default HomeValuation;
