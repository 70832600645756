import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import ListItem from './ListItem';
import { getUnionTypeObjectKey } from '../../../../../../utils/objectUtils';

const GridView = ({ homes, onSelect }) => {
  if (!homes.length) return null;

  return (
    <div className="px-4 py-3">
      <Row>
        {
          homes.map((home) => (
            <Col sm="6" key={`home-${getUnionTypeObjectKey(home)}`} className="px-2">
              <ListItem home={home} onSelect={onSelect} />
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

GridView.propTypes = {
  homes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onSelect: PropTypes.func
};

GridView.defaultProps = {
  onSelect: () => {}
};

export default GridView;
