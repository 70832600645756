import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'gatsby';
import i18n from 'i18n-js';
import v from 'voca';

import { Tabs } from '../utils';
import BasicInfo from './BasicInfo';
import Security from './Security';
import { getParam } from '../../../../utils/paramsUtils';

const i18nOpts = { scope: 'components.admin.myProfile.show.index' };

const Show = () => {
  const initialTab = getParam('tab');
  const [activeTab, setActiveTab] = useState(initialTab || Tabs.BASIC_INFO);

  const onChangeTab = (tab) => setActiveTab(tab);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>

        <Link to="/admin/myProfile/form" className="btn btn-primary ml-3">
          <i className="far fa-edit mr-2" />
          {i18n.t('buttons.edit')}
        </Link>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === Tabs.BASIC_INFO })}
            onClick={() => onChangeTab(Tabs.BASIC_INFO)}
          >
            {i18n.t('tabs.basicInfo', i18nOpts)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === Tabs.SECURITY })}
            onClick={() => onChangeTab(Tabs.SECURITY)}
          >
            {i18n.t('tabs.security', i18nOpts)}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.BASIC_INFO}>
          <BasicInfo />
        </TabPane>
        <TabPane tabId={Tabs.SECURITY}>
          <Security redirect={!v.isBlank(initialTab)} />
        </TabPane>
      </TabContent>
    </div>
  );
};

Show.propTypes = {};

Show.defaultProps = {};

export default Show;
