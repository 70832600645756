import React from 'react';
import i18n from 'i18n-js';

import { Bar } from 'react-chartjs-2';
import { Card } from 'reactstrap';

const i18nOpts = { scope: 'components.admin.externalDataSources.builtOptionsReport.reportChart.index' };

function getData(builtOptionsReport) {
  const mappedKeys = [];
  const mappedCount = [];
  builtOptionsReport.forEach(([key, count]) => {
    mappedKeys.push(key);
    mappedCount.push(count);
  });

  return {
    labels: mappedKeys,
    datasets: [
      {
        label: i18n.t('labels.timesRepeated', i18nOpts),
        backgroundColor: '#00AF8C',
        borderWidth: 0,
        data: mappedCount
      }
    ]
  };
}

const legend = {
  align: 'start',
  labels: {
    font: {
      color: '#000000',
      weight: '500',
      size: 10
    },
    boxWidth: 10
  }
};

const options = {
  scales: {
    x: {
      ticks: {
        font: {
          color: '#000000',
          weight: '500',
          size: 10
        },
        stepSize: 1,
        beginAtZero: true,
      }
    },
    yAxes: {
      ticks: {
        font: {
          color: '#000000',
          weight: '500',
          size: 10
        },
      }
    }
  },
  indexAxis: 'y',
  plugins: {
    legend
  }
};

const ReportChart = ({ builtOptionsReport, title }) => {
  const data = getData(builtOptionsReport);

  return (
    <Card body className="m-0 h-100 mt-3">
      <h4>{title}</h4>
      <div className="d-block ny-auto w-auto">
        <Bar data={data} options={options} legend={legend} />
      </div>
    </Card>
  );
};

export default ReportChart;
