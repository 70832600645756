import React from 'react';
import { DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import BaseActionsMenu from '../../../../../../global/ActionsMenu';
import { ModalNames, PaymentVendors } from '../../../../../../../constants';
import ModalService from '../../../../../../../services/ModalService';

const ActionsMenu = ({ deposit }) => {
  const { confirmed, creditAllowed, paymentVendor } = deposit;
  const isStripePayment = paymentVendor === PaymentVendors.STRIPE;

  const onRefund = () => {
    ModalService.open(ModalNames.DEPOSIT_REFUND_FORM, { depositId: deposit.id, from: 'depositList' });
  };

  if (!(isStripePayment && confirmed && creditAllowed)) return null;

  return (
    <BaseActionsMenu item={deposit}>
      <DropdownItem onClick={onRefund}>
        {i18n.t('buttons.refund')}
      </DropdownItem>
    </BaseActionsMenu>
  );
};

ActionsMenu.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.any).isRequired
};

ActionsMenu.defaultProps = {};

export default ActionsMenu;
