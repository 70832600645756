import React from 'react';
import {
  Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import i18n from 'i18n-js';

const i18nOpts = { scope: 'components.admin.integrations.socialMedia.index.facebook.index' };

const Facebook = ({ form, onTextChange }) => (
  <>
    <Row form>
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="fbAppId">{i18n.t('appId', i18nOpts)}</Label>
          <Input
            type="text"
            name="fbAppId"
            id="fbAppId"
            value={form.fbAppId || ''}
            onChange={onTextChange}
          />
        </FormGroup>
      </Col>
    </Row>

    <Row form>
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="fbAppSecret">{i18n.t('appSecret', i18nOpts)}</Label>
          <Input
            type="text"
            name="fbAppSecret"
            id="fbAppSecret"
            value={form.fbAppSecret || ''}
            onChange={onTextChange}
          />
        </FormGroup>
      </Col>
    </Row>
  </>
);

export default Facebook;
