import React, { useMemo } from 'react';
import { Table } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';

import { setInvitedCustomers, setCustomersInviteMethod } from '../../../../../../store/actions/salesInvitationActions';
import { SalesInviteMethods } from '../../constants';
import useHomeRental from '../../../../../../hooks/useHomeRental';
import InvitationListItem from '../../components/InvitationListItem';
import InviteByDropdown from '../../components/InviteByDropdown';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.invitationList.listView.index' };

const ListView = ({ invitedCustomers, inviteMethod, ...props }) => {
  const { typeI18nKey } = useHomeRental();

  const onInviteMethodChange = (selectedValue) => {
    const updatedCustomers = invitedCustomers.map((ic) => {
      let newInvitedMethod = selectedValue;
      if (selectedValue === SalesInviteMethods.SMS && v.isBlank(ic.phone)) {
        newInvitedMethod = SalesInviteMethods.EMAIL;
      }
      if (selectedValue === SalesInviteMethods.EMAIL && v.isBlank(ic.email)) {
        newInvitedMethod = SalesInviteMethods.SMS;
      }

      return { ...ic, inviteMethod: newInvitedMethod };
    });
    props.setCustomersInviteMethod(selectedValue);
    props.setInvitedCustomers(updatedCustomers);
  };

  const availableInvitedMethods = useMemo(() => {
    const hasSomeEmail = invitedCustomers.some((ic) => !v.isBlank(ic.email));
    const hasSomePhone = invitedCustomers.some((ic) => !v.isBlank(ic.phone));

    const invitedMethods = [];
    if (hasSomeEmail) invitedMethods.push(SalesInviteMethods.EMAIL);
    if (hasSomePhone) invitedMethods.push(SalesInviteMethods.SMS);
    return invitedMethods;
  }, [invitedCustomers]);

  if (!invitedCustomers.length) return null;

  return (
    <Table borderless>
      <thead>
        <tr>
          <th>{i18n.t(`${typeI18nKey}.customer`, i18nOpts)}</th>
          <th className="text-right">
            {invitedCustomers.length > 1 && availableInvitedMethods.length > 1 && (
              <InviteByDropdown value={inviteMethod} onChange={onInviteMethodChange} />
            )}
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          invitedCustomers.map((customer) => (
            <InvitationListItem key={`customer-${customer.id}`} customer={customer} />
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propTypes = {};

ListView.defaultProps = {};

export default connect((store) => ({
  invitedCustomers: store.salesInvitation.invitedCustomers,
  inviteMethod: store.salesInvitation.inviteMethod
}), {
  setCustomersInviteMethod,
  setInvitedCustomers
})(ListView);
