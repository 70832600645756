import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';

import { fetchItemsAsync } from '../utils';
import { listCommunitiesQuery } from '../../../../graphql';
import { DEFAULT_IMAGE } from '../../../../constants';
import FormDropdown from '../components/FormDropdown';
import FormDropdownItem from '../components/FormDropdownItem';
import { buildNewCommunity } from './utils';

const i18nOpts = { scope: 'components.global.quickPossessionForm.communityDropdown.index' };

const CommunityDropdown = ({ currentCompany, form, onChange }) => {
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(false);
  const { community: selectedCommunity } = form;
  const [searchText, setSearchText] = useState('');

  const loadCommunities = () => {
    setLoading(true);

    fetchItemsAsync(listCommunitiesQuery, currentCompany.id, null, { pageSize: null })
      .then((items) => setCommunities(items))
      .finally(() => setLoading(false));
  };

  const onSelect = (newSelectedCommunity) => {
    onChange({ community: newSelectedCommunity });
  };

  const onNewCommunity = () => {
    onSelect({ ...buildNewCommunity(), name: searchText });
  };

  useEffect(() => {
    loadCommunities();
  }, []);

  const filteredCommunities = communities.filter(
    (community) => community.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <FormDropdown
      value={selectedCommunity?.name}
      placeholder={i18n.t('label', i18nOpts)}
      isSearchable
      searchText={searchText}
      setSearchText={setSearchText}
      searching={!v.isBlank(searchText) && loading}
    >
      {v.isBlank(searchText) ? (
        <FormDropdownItem name={i18n.t('select.search')} disabled />
      ) : (
        <>
          <FormDropdownItem
            key="new-community-option"
            name={`${searchText} ${i18n.t('newCommunity', i18nOpts)}`}
            onClick={onNewCommunity}
          />

          {
            filteredCommunities.map((community) => {
              const imageUrl = community.imageUrl || DEFAULT_IMAGE;

              return (
                <FormDropdownItem
                  key={`community-option-${community.id}`}
                  onClick={() => onSelect(community)}
                  imageUrl={imageUrl}
                  name={community.name}
                />
              );
            })
          }
        </>
      )}
    </FormDropdown>
  );
};

CommunityDropdown.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

CommunityDropdown.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(CommunityDropdown);
