import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import i18n from 'i18n-js';

import './styles.scss';
import { CustomSwitch } from '../../../../../ui';

const i18nOpts = { scope: 'components.global.quickPossessionForm.floorplan.components.editableSwitch.index' };

const EditableSwitch = ({ checked, onToggle, ...props }) => {
  const [htmlId] = useState(_.uniqueId('editable-'));

  return (
    <div onClick={onToggle} role="button" aria-hidden className="d-flex justify-content-center">
      <small className={classnames('editable-switch-label mr-1', { 'text-muted': !checked })}>
        {i18n.t('title', i18nOpts)}
      </small>
      <CustomSwitch
        id={htmlId}
        checked={checked}
        onChange={() => {}}
        {...props}
      />
    </div>
  );
};

EditableSwitch.propTypes = {
  checked: PropTypes.bool,
  onToggle: PropTypes.func
};

EditableSwitch.defaultProps = {
  checked: false,
  onToggle: () => {}
};

export default EditableSwitch;
