import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Spinner } from 'reactstrap';
import { AiOutlinePlus } from 'react-icons/ai';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import PropTypes from 'prop-types';

import Visit from './Visit';
import Tour from './Tour';
import HomewisePostback from './HomewisePostback';
import './styles.scss';
import BuiltHome from './BuiltHome';
import hastMoreItems from '../../../../utils/paginationUtils';
import IdVerified from './IdVerified';
import Message from './Message';
import FormSubmission from './FormSubmission';
import Affordability from './Affordability';
import OnfidoCheck from './OnfidoCheck';
import OfferAccepted from './OfferAccepted';
import DepositSubmitted from './DepositSubmitted';
import SignSubmitted from './SignSubmitted';
import { Colors } from '../../../../constants';
import { fetchActivitiesAsync, groupActivities } from './utils';
import CustomerInvited from './CustomerInvited';
import RefundIssued from './RefundIssued';

const ActivityTypes = {
  TOUR: 'TOUR',
  VISIT: 'VISIT',
  HOME: 'HOME',
  HOMEWISE_POSTBACK: 'HOMEWISE_POSTBACK',
  ID_VERIFIED: 'ID_VERIFIED',
  MESSAGE: 'MESSAGE',
  FORM_SUBMISSION: 'FORM_SUBMISSION',
  AFFORDABILITY: 'AFFORDABILITY',
  ONFIDO_CHECK: 'ONFIDO_CHECK',
  OFFER_ACCEPTED: 'OFFER_ACCEPTED',
  DEPOSIT_SUBMITTED: 'DEPOSIT_SUBMITTED',
  SIGN_SUBMITTED: 'SIGN_SUBMITTED',
  CUSTOMER_INVITED: 'CUSTOMER_INVITED',
  REFUND_ISSUED: 'REFUND_ISSUED'
};

const Activity = ({ activity, innerKey }) => {
  switch (activity.type) {
    case ActivityTypes.TOUR:
      return <Tour innerKey={`tour-${innerKey}`} activity={activity} />;
    case ActivityTypes.VISIT:
      return <Visit innerKey={`visit-${innerKey}`} activity={activity} />;
    case ActivityTypes.HOME:
      return <BuiltHome innerKey={`built-home-${innerKey}`} activity={activity} />;
    case ActivityTypes.HOMEWISE_POSTBACK:
      return <HomewisePostback innerKey={`homewise-postback-${innerKey}`} activity={activity} />;
    case ActivityTypes.ID_VERIFIED:
      return <IdVerified innerKey={`id-verified-${innerKey}`} activity={activity} />;
    case ActivityTypes.MESSAGE:
      return <Message innerKey={`message-${innerKey}`} activity={activity} />;
    case ActivityTypes.FORM_SUBMISSION:
      return <FormSubmission innerKey={`form-submission-${innerKey}`} activity={activity} />;
    case ActivityTypes.AFFORDABILITY:
      return <Affordability innerKey={`affordability-${innerKey}`} activity={activity} />;
    case ActivityTypes.ONFIDO_CHECK:
      return <OnfidoCheck innerKey={`affordability-${innerKey}`} activity={activity} />;
    case ActivityTypes.OFFER_ACCEPTED:
      return <OfferAccepted innerKey={`offer-accepted-${innerKey}`} activity={activity} />;
    case ActivityTypes.DEPOSIT_SUBMITTED:
      return <DepositSubmitted innerKey={`deposit-submitted-${innerKey}`} activity={activity} />;
    case ActivityTypes.SIGN_SUBMITTED:
      return <SignSubmitted innerKey={`sign-submitted-${innerKey}`} activity={activity} />;
    case ActivityTypes.CUSTOMER_INVITED:
      return <CustomerInvited innerKey={`customer-invited-${innerKey}`} activity={activity} />;
    case ActivityTypes.REFUND_ISSUED:
      return <RefundIssued innerKey={`refund-issued-${innerKey}`} activity={activity} />;
    default:
      return null;
  }
};

const CustomerTimeline = ({
  customerId, currentCompany, reservationActivitiesOnly,
}) => {
  const [page, setPage] = useState(1);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);

  const onLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getActivityTypes = () => {
    if (reservationActivitiesOnly) {
      return [
        ActivityTypes.OFFER_ACCEPTED,
        ActivityTypes.SIGN_SUBMITTED,
        ActivityTypes.DEPOSIT_SUBMITTED,
        ActivityTypes.REFUND_ISSUED
      ];
    }
    return null;
  };

  const loadActivities = () => {
    setLoading(true);

    const types = getActivityTypes();
    const filter = { contactId: customerId, companyId: currentCompany.id, types };
    const variables = { filter, page };

    fetchActivitiesAsync(variables)
      .then(({ pagination: currentPagination, items }) => {
        setPagination(currentPagination);
        setActivities((prevActivities) => [...prevActivities, ...items]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadActivities();
  }, [page]);

  const hasMoreItems = hastMoreItems(pagination);
  const groupedActivities = groupActivities(activities);

  return (
    <div>
      {loading && activities.length === 0 && (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      )}

      {groupedActivities.length > 0 && (
      <VerticalTimeline layout="1-column-left" lineColor={Colors.GRAY_200}>
        {
          groupedActivities.map((activity, index) => (
            <Activity
              key={`activity-${index}`}
              activity={activity}
              innerKey={`activity-${index}`}
            />
          ))
        }

        {hasMoreItems && (
          <VerticalTimelineElement
            key="load-more-button"
            iconStyle={{ background: 'rgb(0, 175, 140)', color: '#fff' }}
            icon={(
              <AiOutlinePlus
                className="activities-load-more-button"
                size="1rem"
                title={i18n.t('buttons.loadMore')}
              />
                  )}
            iconOnClick={onLoadMore}
          />
        )}
      </VerticalTimeline>
      )}
    </div>
  );
};

CustomerTimeline.propTypes = {
  customerId: PropTypes.number.isRequired,
  reservationActivitiesOnly: PropTypes.bool
};

CustomerTimeline.defaultProps = {
  reservationActivitiesOnly: false
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(CustomerTimeline);
