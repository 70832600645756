import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

import styles from './IntegrationButton.module.scss';

const IntegrationButton = ({
  path, innerId, label, icon: Icon, ...props
}) => (
  <div className="mt-4" {...props}>
    <Link
      to={path}
      className={classNames('btn btn-lg btn-block btn-outline-secondary py-3', styles.button)}
      id={innerId}
    >
      {Icon}
      <span className="display-7 align-middle">{label}</span>
    </Link>
  </div>
);

IntegrationButton.propTypes = {
  path: PropTypes.string.isRequired,
  innerId: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
};

IntegrationButton.defaultProps = {
  innerId: undefined
};

export default IntegrationButton;
