import React from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import * as PropTypes from 'prop-types';
import i18n from 'i18n-js';

const ActionsMenu = ({ onExport }) => (
  <UncontrolledDropdown>
    <DropdownToggle tag="a" className="pl-1 cursor-pointer">
      <i className="fas fa-ellipsis-v" />
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem onClick={onExport} className="py-1">
        {i18n.t('buttons.exports.csv')}
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

ActionsMenu.propTypes = {
  onExport: PropTypes.func
};

ActionsMenu.defaultProps = {
  onExport: () => {}
};

export default ActionsMenu;
