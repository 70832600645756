import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { Tabs } from '../utils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.workflow.workflows.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <Nav vertical tabs className="border-0 mb-3">
    <NavItem id="reserveNowWorkflowContainer">
      <NavLink
        active={activeTab === Tabs.RESERVE_NOW_WORKFLOW}
        onClick={() => onChange(Tabs.RESERVE_NOW_WORKFLOW)}
        className="border-0"
      >
        {i18n.t('reserveNowWorkflow', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem id="verifiedWorkflowContainer">
      <NavLink
        active={activeTab === Tabs.VERIFIED_WORKFLOW}
        onClick={() => onChange(Tabs.VERIFIED_WORKFLOW)}
        className="border-0"
      >
        {i18n.t('verifiedWorkflow', i18nOpts)}
      </NavLink>
    </NavItem>
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
