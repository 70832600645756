import React from 'react';
import { connect } from 'react-redux';

import Placeholder from './Placeholder';
import InfoCard from './InfoCard';

const CoBuyerAdded = ({ customer }) => {
  const { coBuyersCount } = customer;
  const hasCoBuyer = coBuyersCount > 1;

  return (
    <div className="mt-4">
      {hasCoBuyer ? (
        <InfoCard />
      ) : (
        <Placeholder />
      )}
    </div>
  );
};

CoBuyerAdded.propTypes = {};

CoBuyerAdded.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
}))(CoBuyerAdded);
