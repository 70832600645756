import React, { useMemo, useState } from 'react';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import { MortgageProviderAssignableTypes } from '../../../../constants';
import useFetchMortgageProviders from '../../../../hooks/useFetchMortgageProviders';
import { createMortgageProviderAssignmentAsync } from '../utils';

const i18nOpts = { scope: 'components.global.mortgageProvidersManagement.addMortgageProviderDropdown.index' };

const AddMortgageProviderDropdown = ({
  mortgageProviderAssignments, assignableId, assignableType, ...props
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { mortgageProviders } = useFetchMortgageProviders();

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getAvailableMortgageProviders = () => {
    const selectedMortgageProviderIds = mortgageProviderAssignments.map(
      (mpa) => mpa.mortgageProvider.id
    );
    return mortgageProviders.filter((mp) => !selectedMortgageProviderIds.includes(mp.id));
  };

  const onAddMortgageProvider = (mortgageProvider) => {
    const input = {
      assignableId,
      assignableType,
      mortgageProviderId: mortgageProvider.id
    };
    const variables = { input };
    createMortgageProviderAssignmentAsync(variables)
      .then(() => props.onAfterAdd());
  };

  const availableMortgageProviders = useMemo(
    getAvailableMortgageProviders,
    [mortgageProviders, mortgageProviderAssignments]
  );

  return (
    <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
      <DropdownToggle color="outline-secondary" caret disabled={!availableMortgageProviders.length}>
        {i18n.t('buttons.add', i18nOpts)}
      </DropdownToggle>
      <DropdownMenu right>
        {
          availableMortgageProviders.map((mortgageProvider) => (
            <DropdownItem
              key={`available-mortgage-provider-${mortgageProvider.id}`}
              onClick={() => onAddMortgageProvider(mortgageProvider)}
            >
              {mortgageProvider.name}
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  );
};

AddMortgageProviderDropdown.propTypes = {
  mortgageProviderAssignments: PropTypes.arrayOf(PropTypes.any).isRequired,
  assignableId: PropTypes.number.isRequired,
  assignableType: PropTypes.oneOf(Object.values(MortgageProviderAssignableTypes)).isRequired,
  onAfterAdd: PropTypes.func
};

AddMortgageProviderDropdown.defaultProps = {
  onAfterAdd: () => {}
};

export default AddMortgageProviderDropdown;
