import v from 'voca';

/**
 * Format currency
 * @param amount
 * @param humanize
 * @param includeDecimals
 * @param currency
 * @returns {string|null} CA$18,234.50 | US$18,234.50
 */
export function formatCurrency(amount, humanize = false, includeDecimals = false, currency = 'USD') {
  if (v.isBlank(amount)) return null;

  let formattedCurrency = parseFloat(amount).toLocaleString('en-US', { style: 'currency', currency });
  if (humanize) formattedCurrency = humanizeCurrency(amount, formattedCurrency);
  if (!includeDecimals) formattedCurrency = removeDecimals(formattedCurrency);
  return formattedCurrency;
}

export function formatNumber(amount) {
  if (v.isBlank(amount)) return null;

  return parseFloat(amount).toLocaleString('en-US');
}

export function isValidAmount(amount) {
  return !!amount && !Number.isNaN(amount);
}

function humanizeCurrency(amount, formattedCurrency) {
  let delimiter;
  let suffix = '';
  let roundMillionValue = false;
  if (amount >= 1000000) {
    suffix = 'M';
    delimiter = '.';
    roundMillionValue = true;
  } else if (amount >= 50000) {
    delimiter = ',';
    suffix = 'k';
  } else delimiter = '.';
  const lastIndex = formattedCurrency.lastIndexOf(delimiter);
  const validCurrency = roundMillionValue ? roundMillionCurrency(amount)
    : formattedCurrency.substr(0, lastIndex);
  return validCurrency + suffix;
}

function roundMillionCurrency(amount, decimals = 2) {
  const roundedValue = parseFloat((amount / 1000000).toFixed(decimals));
  return `$${roundedValue}`;
}

function removeDecimals(formattedCurrency) {
  const isHumanized = ['k', 'M'].some((suffix) => formattedCurrency.includes(suffix));
  if (isHumanized) return formattedCurrency;

  const lastIndex = formattedCurrency.lastIndexOf('.');
  return lastIndex >= 0 ? formattedCurrency.substr(0, lastIndex) : formattedCurrency;
}

export function getCustomerAffordability(customer, combinedAmount = true) {
  const { party } = customer;
  if (combinedAmount && party?.affordability) return party.affordability;

  const { companyContact: { affordability } } = customer;
  return affordability;
}
