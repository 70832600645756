import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

import { SalesInviteMethods } from '../../constants';
import { getSalesInviteMethodName } from '../../../../../../utils/enumUtils';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.components.inviteByDropdown.index' };

const InviteByDropdown = ({
  value, onChange, showEmail, showSms
}) => (
  <UncontrolledDropdown>
    <DropdownToggle color="outline-secondary" caret className="text-nowrap" size="sm">
      {v.isBlank(value) ? i18n.t('title', i18nOpts) : getSalesInviteMethodName(value)}
    </DropdownToggle>
    <DropdownMenu>
      {showEmail && (
        <DropdownItem
          onClick={() => onChange(SalesInviteMethods.EMAIL)}
          active={value === SalesInviteMethods.EMAIL}
        >
          {getSalesInviteMethodName(SalesInviteMethods.EMAIL)}
        </DropdownItem>
      )}
      {showSms && (
        <DropdownItem
          onClick={() => onChange(SalesInviteMethods.SMS)}
          active={value === SalesInviteMethods.SMS}
        >
          {getSalesInviteMethodName(SalesInviteMethods.SMS)}
        </DropdownItem>
      )}
    </DropdownMenu>
  </UncontrolledDropdown>
);

InviteByDropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  showEmail: PropTypes.bool,
  showSms: PropTypes.bool
};

InviteByDropdown.defaultProps = {
  onChange: () => {},
  value: null,
  showEmail: true,
  showSms: true
};

export default InviteByDropdown;
