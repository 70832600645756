import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import classNames from 'classnames';
import v from 'voca';

import styles from './HomeInfo.module.scss';
import { compareUnionTypeObjectTypename } from '../../../../../../../utils/objectUtils';
import Price from '../../../../../home/Price';
import { buildInvitedHomeReserveUrl } from '../../../../../../../utils/invitedHomeUtils';

const HomeInfo = ({ home }) => {
  if (!home) return null;

  const isUnit = compareUnionTypeObjectTypename(home, 'Unit');

  let name;
  let communityProjectName;
  let price;

  if (isUnit) {
    const { project } = home.buildingModel;
    name = home.name;
    communityProjectName = project.name;
    price = home.price;
  } else {
    name = home.model.name;
    communityProjectName = home.community.name;
    price = home.customPrice || home.totalPrice;
  }

  const isDeletedHome = !v.isBlank(home.deletedAt);
  const homeUrl = buildInvitedHomeReserveUrl(home);

  return (
    <div className={classNames('d-flex gap-3 mt-2', { 'opacity-03': isDeletedHome })}>
      {!v.isBlank(home.imageUrl) && (
        <div className={styles.imageContainer}>
          <img src={home.imageUrl} alt={name} className={styles.image} />
        </div>
      )}
      <div>
        <div className="font-weight-500 col-gray-700">{name}</div>
        <div>
          <Price amount={price} className="mr-1 font-weight-500 col-gray-700" />
        </div>
        <div className="d-flex align-items-center">
          {communityProjectName}
        </div>

        {!isDeletedHome && (
          <a href={homeUrl} target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-sm px-5 mt-2">
            {i18n.t('buttons.view')}
          </a>
        )}
      </div>
    </div>
  );
};

HomeInfo.propTypes = {
  home: PropTypes.objectOf(PropTypes.any)
};

HomeInfo.defaultProps = {
  home: null
};

export default HomeInfo;
