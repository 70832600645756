import React from 'react';
import i18n from 'i18n-js';

import LevelTwoSubMenu from '../components/LevelTwoSubMenu';
import { Menus } from '../utils';
import MultiFamilyIcon from '../../../../assets/icons/sidebar-nav/MultiFamilyIcon';
import MultiFamilySideBar from '../MultiFamilySideBar';

const i18nOpts = { scope: 'layout.admin.sidebar.multiFamilyMenu.index' };

const MultiFamilyMenu = () => (
  <LevelTwoSubMenu
    title={i18n.t('title', i18nOpts)}
    menu={Menus.MULTI_FAMILY}
    IconElement={<MultiFamilyIcon />}
  >
    <MultiFamilySideBar />
  </LevelTwoSubMenu>
);

export default MultiFamilyMenu;
