import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import {
  sendGridGetContactList,
  sendGridSetContacts
} from '../../../../../../store/actions/sendGridActions';
import IntegrationsBreadcrumbs from '../../../../../global/IntegrationsBreadcrumbs';
import ListEmpty from '../../../../../common/ListEmpty';
import ContactList from './List';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';
import FormModal from './FormModal';

const i18nOpts = { scope: 'components.admin.integrations.sendGrid.contactLists.contacts.index' };

const Contacts = ({
  id: contactListId, contactList, loading, contacts, currentCompany, ...props
}) => {
  const loadContactList = () => {
    const variables = { id: contactListId, companyId: currentCompany.id };
    props.sendGridGetContactList(variables)
      .catch(() => navigate('/admin/integrations/sendGrid/contactLists'));
  };

  const loadListContacts = () => {
    if (contactList.contacts) props.sendGridSetContacts(contactList.contacts);
  };

  const onAdd = () => {
    ModalService.open(ModalNames.SENDGRID_CONTACT_FORM);
  };

  useEffect(() => {
    if (contactList) loadListContacts();
  }, [contactList]);

  useEffect(() => {
    loadContactList();
  }, []);

  if (!contactList) return null;

  return (
    <div>
      <Helmet title={contactList.name} />

      <IntegrationsBreadcrumbs items={[
        { text: i18n.t('sendGrid', i18nOpts), url: '/admin/integrations/sendGrid' },
        { text: i18n.t('contactLists', i18nOpts), url: '/admin/integrations/sendGrid/contactLists' },
        { text: i18n.t('title', i18nOpts) }
      ]}
      />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{contactList.name}</h2>
        </div>
        <div>
          <Button color="primary" onClick={onAdd}>
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Button>
        </div>
      </div>

      {loading && (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      )}

      <ListEmpty loading={loading} items={contacts} />

      {contacts.length > 0 && (
        <ContactList contacts={contacts} />
      )}

      <FormModal />
    </div>
  );
};

Contacts.propTypes = {};

Contacts.defaultProps = {};

export default connect((store) => ({
  contactList: store.sendGrid.contactList,
  loading: store.sendGrid.getContactList.loading || false,
  contacts: store.sendGrid.contacts,
  currentCompany: store.companies.currentCompany
}), {
  sendGridGetContactList,
  sendGridSetContacts
})(Contacts);
