import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';

const i18nOpts = { scope: 'components.admin.cities.show.overview.index' };

const Overview = ({ city }) => {
  if (!city) return null;

  const creatorName = getCreatorDisplayName(city);
  const lastModifierName = getLastModifierDisplayName(city);

  return (
    <div>
      {!v.isBlank(city.state) && (
        <div className="mb-4">
          <div className="label">{i18n.t('state', i18nOpts)}</div>
          <div className="white-space-pre-line">{city.state}</div>
        </div>
      )}

      {!v.isBlank(city.description) && (
        <div className="mb-4">
          <div className="label">{i18n.t('description', i18nOpts)}</div>
          <div className="white-space-pre-line">{city.description}</div>
        </div>
      )}

      {!v.isBlank(city.longDescription) && (
        <div className="mb-4">
          <div className="label">{i18n.t('longDescription', i18nOpts)}</div>
          <div dangerouslySetInnerHTML={{ __html: city.longDescription }} />
        </div>
      )}

      { !v.isBlank(creatorName) && (
        <div className="mb-4">
          <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
          <div><strong>{creatorName}</strong></div>
        </div>
      )}

      { !v.isBlank(lastModifierName) && (
        <div className="mb-4">
          <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
          <div><strong>{lastModifierName}</strong></div>
        </div>
      )}
    </div>
  );
};

export default connect((store) => ({
  city: store.cities.city
}))(Overview);
