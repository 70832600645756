import React from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';

import './styles.scss';
import ListHeader from './ListHeader';
import ListItem from './ListItem';

const ListView = ({ units }) => (
  <Table responsive bordered id="units-table">
    <thead>
      <ListHeader />
    </thead>
    <tbody>
      {
        units.map((unit) => (
          <ListItem key={`unit-${unit.id}`} unit={unit} />
        ))
      }
    </tbody>
  </Table>
);

ListView.propTypes = {};

ListView.defaultProps = {};

export default connect((store) => ({
  units: store.units.units,
}))(ListView);
