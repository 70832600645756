import api from '../../../../../api';
import {
  createUnitImageQuery, deleteUnitImageQuery,
  listUnitImagesQuery, sortUnitImagesQuery,
  updateUnitImageQuery
} from '../../../../../graphql';

export function fetchUnitImagesAsync(variables = {}) {
  return api.graphql(listUnitImagesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items))
    .catch(() => Promise.reject());
}

export function saveUnitImageAsync(variables = {}) {
  const query = variables?.input?.id ? updateUnitImageQuery : createUnitImageQuery;

  return api.graphql(query, variables);
}

export function deleteUnitImageAsync(id) {
  return api.graphql(deleteUnitImageQuery, { id });
}

export function sortUnitImagesAsync(variables) {
  return api.graphql(sortUnitImagesQuery, variables);
}
