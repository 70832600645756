import React from 'react';
import PropTypes from 'prop-types';

const Interests = ({ quickPossession }) => {
  if (quickPossession.requests?.length === 0) return null;

  return (
    <div>
      {quickPossession.requests.length}
    </div>
  );
};

Interests.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Interests.defaultProps = {};

export default Interests;
