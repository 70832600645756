import React, { useEffect } from 'react';
import {
  Row, Col, Button
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FloorFormModal from './FloorFormModal';
import Floor from './Floor';
import ModalService from '../../../services/ModalService';
import { FloorableTypes, ModalNames } from '../../../constants';
import FloorGroupFormModal from './FloorGroupFormModal';
import FloorOptionFormModal from './FloorOptionFormModal';
import FloorOptionDependencyModal from './FloorOptionDependencyModal';
import FloorOptionImagesModal from './FloorOptionImagesModal';
import { isAdmin } from '../../../utils/authUtils';
import { listFloors, setReloadFloors } from '../../../store/actions/floorActions';
import FloorOptionPriceRulesModal from './FloorOptionPriceRulesModal';
import './styles.scss';

const i18nOpts = { scope: 'components.global.floorsManager.index' };

function onOpenModal() {
  ModalService.open(ModalNames.FLOOR_FORM);
}

const FloorsManager = ({
  floors, floorableId, floorableType, reloadFloors, ...props
}) => {
  const loadFloors = () => {
    const variables = { filter: { floorableId, floorableType } };
    props.listFloors(variables)
      .finally(() => props.setReloadFloors(false));
  };

  useEffect(() => {
    loadFloors(floorableId, floorableType);
  }, []);

  useEffect(() => {
    if (!reloadFloors) return;

    loadFloors();
  }, [reloadFloors]);

  return (
    <div>
      <Row>
        {
          floors.map((f, index) => (
            <Col key={`floor-${f.id}`} xs="12">
              <Floor floor={f} index={index} floorableType={floorableType} />
            </Col>
          ))
        }
      </Row>

      {isAdmin() && (
      <div className="text-right">
        <Button color="primary" onClick={onOpenModal}>
          <i className="fas fa-plus mr-2" />
          {i18n.t('buttons.add', i18nOpts)}
        </Button>
      </div>
      )}

      <FloorFormModal floorableId={floorableId} floorableType={floorableType} />

      <FloorGroupFormModal />

      <FloorOptionFormModal />

      <FloorOptionDependencyModal />

      <FloorOptionImagesModal />

      <FloorOptionPriceRulesModal />

    </div>
  );
};

FloorsManager.propTypes = {
  floorableId: PropTypes.number.isRequired,
  floorableType: PropTypes.oneOf(Object.values(FloorableTypes)).isRequired
};

FloorsManager.defaultProps = {};

export default connect((store) => ({
  floors: store.floors.floors,
  reloadFloors: store.floors.reloadFloors
}), {
  listFloors,
  setReloadFloors
})(FloorsManager);
