import React from 'react';
import { Link } from 'gatsby';
import i18n from 'i18n-js';
import { MdLocationOn } from 'react-icons/md';

import { isCompanyUser, isSalesUser } from '../../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.models.list.gridView.communitiesTooltip.communitiesList.index' };

const CommunitiesList = ({ data }) => {
  const communities = JSON.parse(data) || [];

  return (
    <div>
      <h4>{i18n.t('title', i18nOpts)}</h4>
      {
        communities.map((community) => (
          <div key={`model-community-${community.id}`}>
            {(isCompanyUser() || isSalesUser()) ? (
              <>
                <span className="ml-1 text-uppercase">{community.name}</span>
              </>
            ) : (
              <Link to={`/admin/communities/${community.id}`}>
                <MdLocationOn />
                <span className="ml-1 text-uppercase">{community.name}</span>
              </Link>
            )}
          </div>
        ))
      }
    </div>
  );
};

export default CommunitiesList;
