import React, { useMemo, useState } from 'react';
import {
  FormGroup, Input, Label, Row, Col, Button, Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import Switch from 'rc-switch';
import _ from 'lodash';

import { saveCompany } from '../../../../../store/actions/companyActions';
import StripeIcon from '../../../../../assets/icons/StripeIcon';
import ConnectionBadge from './ConnectionBadge';
import { Currencies, PaymentMethods } from '../../../../../constants';
import { getCurrencyName, getPaymentMethodName } from '../../../../../utils/enumUtils';
import { getPaymentMethods } from './utils';

const i18nOpts = { scope: 'components.admin.integrations.paymentMethods.creditCard.index' };

function goBackToIntegrations() {
  navigate('/admin/integrations');
}

const CreditCard = ({ currentCompany, ...props }) => {
  const stripeSettings = currentCompany.paymentSettings?.stripe || {};
  const [form, setForm] = useState(stripeSettings);
  const [saving, setSaving] = useState(false);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => {
      let paymentMethods = [...(prevForm.paymentMethods || [])];
      if (name === 'currency' && value !== Currencies.USD) {
        paymentMethods = paymentMethods.filter((pm) => pm !== PaymentMethods.ACH_DIRECT_DEBIT);
      }

      return { ...prevForm, [name]: value, paymentMethods };
    });
  };

  const onSwitchChange = (name) => {
    setForm((prevForm) => ({ ...prevForm, [name]: !prevForm[name] }));
  };

  const onPaymentMethodChange = (event) => {
    const { value } = event.target;

    setForm((prevForm) => {
      const paymentMethods = _.xor(prevForm.paymentMethods || [], [value]);
      return { ...prevForm, paymentMethods };
    });
  };

  const onSubmit = () => {
    setSaving(true);

    const paymentSettings = {
      stripe: form
    };
    const variables = { input: { id: currentCompany.id, paymentSettings } };
    props.saveCompany(variables)
      .then(() => {
        toast.success(i18n.t('messages.saved', i18nOpts));
        setTimeout(goBackToIntegrations, 1500);
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setSaving(false));
  };

  const onCancel = () => goBackToIntegrations();

  const paymentMethods = useMemo(() => getPaymentMethods(form.currency, currentCompany),
    [form.currency, currentCompany]);

  return (
    <div>
      <div className="mb-4">
        <div className="d-flex align-items-center">
          <div>
            <StripeIcon width={80} height={45} />
          </div>
          <ConnectionBadge />
        </div>
        <div>{i18n.t('description', i18nOpts)}</div>
      </div>

      <Row>
        <Col xs="12" sm="10" md="8" lg="6">
          <FormGroup>
            <Label for="secretKey">{i18n.t('secretKey', i18nOpts)}</Label>
            <Input
              type="textarea"
              name="secretKey"
              id="secretKey"
              value={form.secretKey || ''}
              onChange={onTextChange}
              rows={3}
              maxLength="255"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="10" md="8" lg="6">
          <FormGroup>
            <Label for="publishableKey">{i18n.t('publishableKey', i18nOpts)}</Label>
            <Input
              type="textarea"
              name="publishableKey"
              id="publishableKey"
              value={form.publishableKey || ''}
              onChange={onTextChange}
              rows={3}
              maxLength="255"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="5" md="4" lg="3">
          <FormGroup>
            <Label for="currency">{i18n.t('currency', i18nOpts)}</Label>
            <Input
              type="select"
              name="currency"
              id="currency"
              value={form.currency || ''}
              onChange={onTextChange}
            >
              <option value="">{i18n.t('select.select')}</option>
              {
                Object.values(Currencies).map((c) => (
                  <option key={`currency-option-${c}`} value={c}>
                    {getCurrencyName(c)}
                  </option>
                ))
              }
            </Input>
          </FormGroup>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs="12" sm="5" md="4" lg="3">
          <Label for="events" className="mb-1">{i18n.t('paymentMethods', i18nOpts)}</Label>

          {
            paymentMethods.map((paymentMethod) => (
              <FormGroup check className="mb-2" key={`payment-method-${paymentMethod}`}>
                <Label check>
                  <Input type="checkbox" value={paymentMethod} checked={form.paymentMethods?.includes(paymentMethod) || false} onChange={onPaymentMethodChange} />
                  {getPaymentMethodName(paymentMethod)}
                </Label>
              </FormGroup>
            ))
          }
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="5" md="4" lg="3">
          <FormGroup>
            <div className="d-inline-block">
              <span
                onClick={() => onSwitchChange('enableImmediatePayout')}
                role="button"
                aria-hidden
                className="d-flex align-items-center"
              >
                <Switch checked={form.enableImmediatePayout || false} />
                <span className="ml-2">{i18n.t('enableImmediatePayout', i18nOpts)}</span>
              </span>
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs="12" sm="5" md="4" lg="3">
          <FormGroup>
            <div className="d-inline-block">
              <span
                onClick={() => onSwitchChange('enableRefunds')}
                role="button"
                aria-hidden
                className="d-flex align-items-center"
              >
                <Switch checked={form.enableRefunds || false} />
                <span className="ml-2">{i18n.t('enableRefunds', i18nOpts)}</span>
              </span>
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Button color="primary" className="mr-3" disabled={saving} onClick={onSubmit}>
        {saving && (<Spinner size="sm" className="mr-2" />)}
        {i18n.t('buttons.save')}
      </Button>
      <Button outline color="secondary" onClick={onCancel} disabled={saving} className="mr-3">
        {i18n.t('buttons.cancel')}
      </Button>
    </div>
  );
};

CreditCard.propTypes = {};

CreditCard.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveCompany
})(CreditCard);
