import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, DropdownToggle, DropdownMenu,
  DropdownItem, Card, UncontrolledDropdown, Spinner
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { MdContentCopy } from 'react-icons/md';

import { ModalNames, ProjectBuildingStatuses } from '../../../../../constants';
import ModalService from '../../../../../services/ModalService';
import api from '../../../../../api';
import SocialShare from '../../../../global/SocialShare';

const i18nOpts = { scope: 'components.admin.buildingModels.show.directLinkModal.index' };

function onClose() {
  ModalService.close(ModalNames.MODEL_DIRECT_LINK_MODAL);
}

function onCopy() {
  toast.info(i18n.t('messages.copied', i18nOpts));
  onClose();
}

function fetchBuildingsAsync(buildingModelId) {
  const variables = {
    filter: { buildingModelId, projectBuildingStatus: ProjectBuildingStatuses.PUBLISHED }
  };

  return api.graphql(`
    query ListBuildingModelModels($filter: BuildingModelModelFilterInput) {
      items: listBuildingModelModels(filter: $filter) {
        projectBuilding { 
          id
          name
          hashId
        }
      }
    }
  `, variables)
    .then(({ data: { items } }) => items.map((i) => i.projectBuilding));
}

const DirectLinkModal = ({ currentCompany, buildingModel, opened }) => {
  const [buildings, setBuildings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  useEffect(() => {
    if (!opened) return;

    init();
  }, [opened]);

  const onToggleBuilding = (building) => setSelectedBuilding(building);

  const loadCommunities = () => {
    setLoading(true);
    fetchBuildingsAsync(buildingModel.id)
      .then((items) => {
        setBuildings(items);
        setSelectedBuilding(items[0]);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const init = () => {
    setSelectedBuilding(null);
    loadCommunities();
  };

  const buildBuildingModelUrl = () => {
    if (!selectedBuilding) return null;

    const domain = currentCompany.domains[0];
    const buildingPath = selectedBuilding ? `/building/${selectedBuilding.hashId}` : '';
    return `${domain}${buildingPath}/model/${buildingModel.hashId}`;
  };

  if (!buildingModel) return null;

  const url = buildBuildingModelUrl();
  const title = i18n.t('title', i18nOpts);
  const socialItemName = `${buildingModel?.name} in ${selectedBuilding?.name}`;

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        ) : (
          <div>
            <div className="mb-1">
              <div className="mb-1">
                {i18n.t('selectBuilding', i18nOpts)}
              </div>
              <UncontrolledDropdown>
                <DropdownToggle color="outline-secondary" caret disabled={!buildings.length}>
                  {selectedBuilding?.name ?? i18n.t('noBuildings', i18nOpts)}
                </DropdownToggle>
                <DropdownMenu>
                  {
                    buildings.map((b) => (
                      <DropdownItem
                        key={`published-model-${b.id}`}
                        onClick={() => onToggleBuilding(b)}
                      >
                        {b.name}
                      </DropdownItem>
                    ))
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            {selectedBuilding && (
              <div className="mt-3">
                <div className="text-uppercase mb-1">{buildingModel.name}</div>
                <Card body className="mb-4 px-3 py-2">
                  <a href={url} target="_blank" rel="noreferrer" style={{ wordBreak: 'break-all' }}>
                    {url}
                  </a>
                </Card>
              </div>
            )}
          </div>
        )}

        {selectedBuilding && (
          <SocialShare url={url} itemName={socialItemName} />
        )}
      </ModalBody>
      <ModalFooter>
        {selectedBuilding && (
          <CopyToClipboard text={url} onCopy={onCopy} className="mr-3">
            <Button outline color="primary" className="text-uppercase">
              <MdContentCopy size="1rem" className="mr-2 align-middle" />
              {i18n.t('buttons.copy', i18nOpts)}
            </Button>
          </CopyToClipboard>
        )}
        <Button outline color="secondary" onClick={onClose}>
          {i18n.t('buttons.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  buildingModel: store.buildingModels.buildingModel,
  opened: store.modals[ModalNames.MODEL_DIRECT_LINK_MODAL]?.opened || false,
}))(DirectLinkModal);
