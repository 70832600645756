import api from '../../../../../../../api';
import {
  updateCompanyContactMutation
} from '../../../../../../../graphql';

export async function updateCompanyContactAsync(contact, stage) {
  const input = { id: contact.companyContact.id, stage };

  return api.graphql(updateCompanyContactMutation, { input })
    .then(({ data: { item } }) => item);
}
