import { countAllPurchasableCustomersQuery, countNotReadMessagesQuery } from '../../../graphql';
import api from '../../../api';

export function fetchCountPurchasableCustomersAsync(companyId) {
  return api.graphql(countAllPurchasableCustomersQuery, { companyId })
    .then(({ data: { count } }) => Promise.resolve(count))
    .catch((e) => Promise.reject(e));
}

export function fetchCountNotReadMessagesAsync(companyId) {
  return api.graphql(countNotReadMessagesQuery, { companyId })
    .then(({ data: { count } }) => Promise.resolve(count))
    .catch((e) => Promise.reject(e));
}

export function getValidCustomerCompanies(customer, companyId) {
  const { companyContacts } = customer;
  return companyContacts.filter((cc) => cc.company.id !== companyId);
}

export function getValidCustomerCompaniesCount(customer, companyId) {
  return getValidCustomerCompanies(customer, companyId).length;
}
