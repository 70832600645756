import React from 'react';

const OfferCounteredIcon = ({ ...props }) => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_654_3009)">
      <path d="M17.1015 15.4808C17.0162 15.2676 16.7603 15.1397 16.5471 15.225L7.71912 18.5941C7.50589 18.6794 7.37795 18.9353 7.46324 19.1485C7.54853 19.3617 7.80442 19.4897 8.01765 19.4044L16.8456 16.0353C17.0588 15.95 17.1868 15.7367 17.1015 15.4808ZM18.1677 18.2529C18.0824 18.0397 17.8265 17.9117 17.6132 17.997L8.7853 21.3661C8.52942 21.4941 8.44412 21.75 8.52942 21.9632C8.61471 22.1764 8.87059 22.3044 9.08383 22.2191L17.9118 18.8073C18.125 18.722 18.2529 18.4661 18.1677 18.2529ZM16.5471 21.5794L9.80883 24.1382C9.59559 24.2235 9.46765 24.4794 9.55295 24.6926C9.63824 24.9058 9.89412 25.0338 10.1074 24.9485L16.8456 22.3897C17.0588 22.3044 17.1868 22.0485 17.1015 21.8353C17.0162 21.622 16.7603 21.4941 16.5471 21.5794Z" fill="#00A572" />
      <path d="M23.0298 16.9736C22.1769 16.9736 21.4092 17.1868 20.6842 17.4854L16.5475 6.69566C16.4622 6.43977 16.2916 6.26919 16.0357 6.18389L10.6622 4.05154C9.68129 1.06624 6.5254 -0.255816 3.79599 0.767714C1.10923 1.79124 -0.255479 4.86183 0.768051 7.5486C1.15187 8.57213 1.87687 9.46772 2.77246 10.0648L2.04746 11.5148C1.91952 11.728 1.91952 12.0265 2.00482 12.2398L7.76217 27.3368C8.14599 28.3177 9.25482 28.8295 10.2357 28.4457L18.2533 25.3751C19.1916 27.0383 20.9828 28.1471 23.0298 28.1471C26.1004 28.1471 28.5739 25.631 28.5739 22.5604C28.5739 19.4898 26.1004 16.9736 23.0298 16.9736ZM1.57834 7.25007C0.725404 4.98977 1.83423 2.43095 4.13717 1.53536C6.22687 0.725067 8.52982 1.62065 9.55334 3.58242L7.59158 2.81477C7.03717 2.60154 6.39746 2.81477 6.14158 3.36918L3.15629 9.29713C2.43129 8.78536 1.87687 8.10301 1.57834 7.25007ZM9.93717 27.6354C9.38276 27.8486 8.7857 27.5927 8.57246 27.0383L2.81511 11.9412V11.8986L3.54011 10.4912C4.77687 11.0457 6.09893 11.1309 7.42099 10.6618C8.0607 11.3442 9.08423 11.6001 9.97982 11.2589C11.2166 10.7898 11.8136 9.42507 11.3445 8.1883C10.8754 6.99419 9.5107 6.31183 8.27393 6.8236C7.03717 7.29271 6.39746 8.70007 6.90923 9.97948C5.92835 10.278 4.86217 10.1927 3.92393 9.80889L6.90923 3.8383C6.99452 3.71036 7.12246 3.62507 7.29305 3.71036L15.7372 7.03683L15.9078 6.65301L15.7798 7.07948L19.9166 17.9118C17.7416 19.3618 16.8886 22.1339 17.8695 24.6074L9.93717 27.6354ZM8.57246 7.59124C8.91364 7.4633 9.25482 7.4633 9.59599 7.59124C9.21217 8.35889 8.48717 9.21183 7.71952 9.6383C7.37835 8.78536 7.76217 7.88977 8.57246 7.59124ZM10.321 8.06036C10.9607 8.87066 10.6195 10.0648 9.68129 10.4486C9.21217 10.6192 8.7004 10.5765 8.27393 10.278C9.04158 9.80889 9.85188 8.9133 10.321 8.06036ZM23.0298 27.2942C20.4283 27.2942 18.3386 25.1618 18.3386 22.5604C18.3386 19.9589 20.4283 17.8265 23.0298 17.8265C25.6313 17.8265 27.721 19.9589 27.721 22.5604C27.721 25.1618 25.5886 27.2942 23.0298 27.2942Z" fill="#00A572" />
      <path d="M23.0298 16.9736C22.1769 16.9736 21.4092 17.1868 20.6842 17.4854L18.6158 12.0905L16.5475 6.69566C16.4622 6.43977 16.2916 6.26919 16.0357 6.18389L10.6622 4.05154C9.68129 1.06624 6.5254 -0.255816 3.79599 0.767714C1.10923 1.79124 -0.255479 4.86183 0.768051 7.5486C1.15187 8.57213 1.87687 9.46772 2.77246 10.0648L2.04746 11.5148C1.91952 11.728 1.91952 12.0265 2.00482 12.2398L7.76217 27.3368C8.14599 28.3177 9.25482 28.8295 10.2357 28.4457L18.2533 25.3751C19.1916 27.0383 20.9828 28.1471 23.0298 28.1471C26.1004 28.1471 28.5739 25.631 28.5739 22.5604C28.5739 19.4898 26.1004 16.9736 23.0298 16.9736ZM1.57834 7.25007C0.725404 4.98977 1.83423 2.43095 4.13717 1.53536C6.22687 0.725067 8.52982 1.62065 9.55334 3.58242L7.59158 2.81477C7.03717 2.60154 6.39746 2.81477 6.14158 3.36918L3.15629 9.29713C2.43129 8.78536 1.87687 8.10301 1.57834 7.25007ZM9.93717 27.6354C9.38276 27.8486 8.7857 27.5927 8.57246 27.0383L2.81511 11.9412V11.8986L3.54011 10.4912C4.77687 11.0457 6.09893 11.1309 7.42099 10.6618C8.0607 11.3442 9.08423 11.6001 9.97982 11.2589C11.2166 10.7898 11.8136 9.42507 11.3445 8.1883C10.8754 6.99419 9.5107 6.31183 8.27393 6.8236C7.03717 7.29271 6.39746 8.70007 6.90923 9.97948C5.92835 10.278 4.86217 10.1927 3.92393 9.80889L6.90923 3.8383C6.99452 3.71036 7.12246 3.62507 7.29305 3.71036L15.7372 7.03683L15.9078 6.65301L15.7798 7.07948L19.9166 17.9118C17.7416 19.3618 16.8886 22.1339 17.8695 24.6074L9.93717 27.6354ZM8.57246 7.59124C8.91364 7.4633 9.25482 7.4633 9.59599 7.59124C9.21217 8.35889 8.48717 9.21183 7.71952 9.6383C7.37835 8.78536 7.76217 7.88977 8.57246 7.59124ZM10.321 8.06036C10.9607 8.87066 10.6195 10.0648 9.68129 10.4486C9.21217 10.6192 8.7004 10.5765 8.27393 10.278C9.04158 9.80889 9.85188 8.9133 10.321 8.06036ZM23.0298 27.2942C20.4283 27.2942 18.3386 25.1618 18.3386 22.5604C18.3386 19.9589 20.4283 17.8265 23.0298 17.8265C25.6313 17.8265 27.721 19.9589 27.721 22.5604C27.721 25.1618 25.5886 27.2942 23.0298 27.2942Z" fill="#00A572" />
      <path d="M23.4563 21.9207L23.4137 20.1722C23.8828 20.2575 24.3519 20.428 24.7357 20.7266L25.3328 19.8736C24.7357 19.4472 24.0534 19.2339 23.371 19.1913V18.7222H22.774V19.1913C22.1769 19.2339 21.6651 19.4045 21.2813 19.7457C20.8975 20.0869 20.7269 20.5133 20.7269 21.0678C20.7269 21.6222 20.8975 22.006 21.2387 22.3045C21.5798 22.5604 22.0916 22.8163 22.774 22.9869V24.7354C22.2196 24.6501 21.6651 24.3942 21.1534 23.9251L20.5137 24.7354C21.196 25.3325 21.9637 25.6736 22.8166 25.7589V26.4413H23.4137V25.7589C24.0534 25.7163 24.5651 25.5457 24.949 25.2045C25.3328 24.8633 25.546 24.3942 25.546 23.8825C25.546 23.3281 25.3754 22.9016 25.0343 22.6457C24.6931 22.3045 24.1387 22.0913 23.4563 21.9207ZM22.8166 21.7501C22.4754 21.6222 22.2196 21.5369 22.0916 21.4089C21.9637 21.281 21.921 21.1104 21.921 20.8972C21.921 20.6839 22.0063 20.5133 22.1769 20.3854C22.3475 20.2575 22.5607 20.1722 22.8166 20.1295V21.7501ZM24.1387 24.4795C23.9681 24.6075 23.7122 24.6928 23.4137 24.7354V23.0295C23.7975 23.1575 24.0534 23.2428 24.1813 23.3707C24.3093 23.4986 24.3946 23.6692 24.3946 23.8825C24.3946 24.181 24.3093 24.3516 24.1387 24.4795Z" fill="#00A572" />
    </g>
    <defs>
      <clipPath id="clip0_654_3009">
        <rect width="29" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

OfferCounteredIcon.propTypes = {};

OfferCounteredIcon.defaultProps = {};

export default OfferCounteredIcon;
