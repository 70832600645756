import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import './styles.scss';
import { DateFormats } from '../../../../../../constants';

const Step = ({
  title, label, date, color, Icon, ...props
}) => (
  <div className={classNames('offer-step-container', color)} {...props}>
    <div className="step-title">{title}</div>
    <div className="step-icon-container">
      <Icon />
    </div>
    <div className="step-label">
      {label}
    </div>
    <div className="step-date text-nowrap">
      {date ? moment(date).format(DateFormats.YYYY_MM_DD) : (<>&nbsp;</>)}
    </div>
  </div>
);

Step.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  color: PropTypes.oneOf(['success', 'warning', 'info', 'light', 'danger']),
  Icon: PropTypes.elementType.isRequired
};

Step.defaultProps = {
  date: null,
  color: null
};

export default Step;
