import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Table } from 'reactstrap';

import ListEmpty from '../../../common/ListEmpty';
import { getMessages } from '../../../../store/actions/messageActions';
import { isMasterAdmin, isTecSupport } from '../../../../utils/authUtils';
import ListItem from './ListItem';
import { SocketContext } from '../../../../providers/Socket';
import './styles.scss';
import { WebSocketMessages } from '../../../../constants';
import ListPagination from '../../../common/ListPagination';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.messages.list.index' };

const isSuperAdmin = isMasterAdmin() || isTecSupport();

const List = ({
  messages, pagination, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const socket = useContext(SocketContext);

  const addSocketEventListener = () => {
    socket.on(WebSocketMessages.CLIENT_MESSAGE_READ, ({ companyId }) => {
      if (!isSuperAdmin && companyId) {
        const parsedCompanyId = parseInt(companyId, 10);
        if (parsedCompanyId !== currentCompany.id) return;
      }

      loadMessages();
    });
  };

  const onChangePage = (page) => {
    loadMessages(page);
  };

  const loadMessages = (page = 1) => {
    const variables = { filter: { companyId: currentCompany.id }, page };
    props.getMessages(variables)
      .finally(() => {
        setMounted(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMessages();

    socket.connect();
    addSocketEventListener();

    return () => {
      socket.disconnect();
    };
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="mb-4">
        <h2>{i18n.t('title', i18nOpts)}</h2>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={messages} />

          {messages.length > 0 && (
            <div className="table-responsive mb-4">
              <Table bordered>
                <thead>
                  <tr>
                    <th>{i18n.t('name', i18nOpts)}</th>
                    <th>{i18n.t('email', i18nOpts)}</th>
                    <th>{i18n.t('phoneNumber', i18nOpts)}</th>
                    <th>{i18n.t('date', i18nOpts)}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    messages.map((message) => (
                      <ListItem message={message} key={`message-${message.id}`} />
                    ))
                  }
                </tbody>
              </Table>
            </div>
          )}

          <div className="text-right mt-4">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}
    </div>
  );
};

List.propTypes = {};

List.defaultProps = {};

export default connect((store) => ({
  messages: store.messages.messages,
  pagination: store.messages.pagination,
  currentCompany: store.companies.currentCompany
}), {
  getMessages
})(List);
