import React, { useState } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { BiGridVertical } from 'react-icons/bi';

import i18n from 'i18n-js';
import { ReactSortable } from 'react-sortablejs';
import { DEFAULT_IMAGE } from '../../../../../../constants';
import StatusIndicator from '../../../../../global/StatusIndicator';
import { sortProjectBuildingsAsync } from '../../utils';
import './styles.scss';
import { isAdmin } from '../../../../../../utils/authUtils';

const ListItem = ({ project }) => {
  const { projectBuildings = [] } = project;
  const [buildings, setBuildings] = useState(projectBuildings);

  if (projectBuildings.length === 0) return null;

  const onSortProjectBuildings = () => {
    if (!isAdmin()) return;
    const ids = buildings.map((pb) => pb.id);
    const variables = { projectId: project.id, ids };
    sortProjectBuildingsAsync(variables).then();
  };

  const onSetProjectBuildings = (internalProjectBuildings) => {
    if (!isAdmin()) return;
    setBuildings(internalProjectBuildings);
  };

  return (
    <div className="mb-4">
      <h4>{project.name}</h4>

      <ListGroup>
        <ReactSortable
          list={buildings}
          handle=".sortable-project-building-handler"
          draggable=".sortable-project-building-item"
          setList={onSetProjectBuildings}
          onEnd={onSortProjectBuildings}
        >
          {buildings.map((projectBuilding) => (
            <ListGroupItem
              key={`project-building-${projectBuilding.id}`}
              className="project-building-container sortable-project-building-item"
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="mr-3">
                  <img
                    src={projectBuilding.defaultImageUrl || DEFAULT_IMAGE}
                    alt={projectBuilding.name}
                    className="project-building-image"
                  />
                </div>
                <div className="flex-grow-1dfg">
                  <div className="d-flex align-items-center">
                    <h3 className="mr-3">
                      <Link to={`/admin/projectBuildings/${projectBuilding.id}`}>{projectBuilding.name}</Link>
                    </h3>
                    <StatusIndicator status={projectBuilding.status} id={projectBuilding.id} />
                  </div>
                </div>

                <div>
                  <BiGridVertical
                    size="1.2rem"
                    className="sortable-project-building-handler mr-3"
                    title={i18n.t('buttons.sort')}
                  />
                </div>
              </div>
            </ListGroupItem>
          ))}
        </ReactSortable>
      </ListGroup>

    </div>
  );
};

ListItem.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
