import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ModalBody } from 'reactstrap';
import i18n from 'i18n-js';

import SelectionButton from '../../components/SelectionButton';
import { Steps } from '../constants';
import useHomeRental from '../../../../../hooks/useHomeRental';
import NewHomeIcon from '../../../../../assets/icons/sales-invitation/NewHomeIcon';
import ExistingHomeIcon from '../../../../../assets/icons/sales-invitation/ExistingHomeIcon';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.homeSelectionType.index' };

const HomeSelectionType = ({ onContinue }) => {
  const { typeI18nKey } = useHomeRental();

  return (
    <ModalBody className="p-0">
      <div className="pt-0 px-3 pb-3 border-bottom">
        <div className="font-18 font-weight-600 mb-1">{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</div>
        <div className="font-14">{i18n.t('description', i18nOpts)}</div>
      </div>

      <div className="p-3">
        <Row>
          <Col>
            <SelectionButton
              title={i18n.t('buttons.newHome', i18nOpts)}
              Icon={NewHomeIcon}
              onClick={() => onContinue(Steps.NEW_HOME)}
            />
          </Col>
          <Col>
            <SelectionButton
              title={i18n.t('buttons.existingHome', i18nOpts)}
              Icon={ExistingHomeIcon}
              onClick={() => onContinue(Steps.EXISTING_HOME)}
            />
          </Col>
        </Row>
      </div>
    </ModalBody>
  );
};

HomeSelectionType.propTypes = {
  onContinue: PropTypes.func
};

HomeSelectionType.defaultProps = {
  onContinue: () => {}
};

export default HomeSelectionType;
