import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from '../api';

export function fetchCitiesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListCities($filter: CityFilterInput) {
      items: listCities(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

const useFetchCities = (companyId) => {
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setLoading(true);

    fetchCitiesAsync(companyId)
      .then((items) => setCities(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return [cities, loading];
};

export default useFetchCities;
