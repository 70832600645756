import v from 'voca';

import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';
import {
  createBuildingModelPaletteQuery, updateBuildingModelPaletteQuery
} from '../../../../../../graphql';
import api from '../../../../../../api';

export function onClose() {
  ModalService.close(ModalNames.BUILDING_MODEL_PALETTE_FORM);
}

export function saveBuildingModelPaletteAsync(input) {
  const query = v.isBlank(input.id) ? createBuildingModelPaletteQuery
    : updateBuildingModelPaletteQuery;
  return api.graphql(query, { input });
}
