import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  createProjectBuildingImageAsync, deleteProjectBuildingImageAsync,
  fetchProjectBuildingImagesAsync,
  sortProjectBuildingImagesAsync, updateProjectBuildingImageAsync
} from './utils';
import ImagesManager from '../../../../global/ImagesManager';

const Images = ({ projectBuilding }) => {
  const [projectBuildingImages, setProjectBuildingImages] = useState([]);

  useEffect(() => {
    loadImages();
  }, []);

  const onDrop = (images) => {
    const promises = images.map((file) => uploadImage(file));
    return Promise.all(promises)
      .finally(() => loadImages());
  };

  const onMarkAsDefault = (projectBuildingImage) => {
    const input = {
      id: projectBuildingImage.id,
      isDefault: true
    };

    const variables = { input };
    updateProjectBuildingImageAsync(variables)
      .then(() => onAfterMarkingAsDefault(projectBuildingImage))
      .catch(() => {});
  };

  const onAfterMarkingAsDefault = (projectBuildingImage) => {
    const updatedProjectBuildingImages = projectBuildingImages.map((pbi) => {
      if (pbi.id === projectBuildingImage.id) return { ...pbi, isDefault: true };
      return { ...pbi, isDefault: false };
    });
    setProjectBuildingImages(updatedProjectBuildingImages);
  };

  const onAfterDeleting = (projectBuildingImage) => {
    const filteredProjectBuildingImages = projectBuildingImages
      .filter((pbi) => pbi.id !== projectBuildingImage.id);
    setProjectBuildingImages(filteredProjectBuildingImages);
  };

  const onDeleteImage = (projectBuildingImage) => {
    const variables = { id: projectBuildingImage.id };
    deleteProjectBuildingImageAsync(variables)
      .then(() => onAfterDeleting(projectBuildingImage))
      .catch();
  };

  const uploadImage = (image) => {
    const input = {
      projectBuildingId: projectBuilding.id
    };

    const variables = { input, image };
    return createProjectBuildingImageAsync(variables);
  };

  const onSetProjectBuildingImages = (images) => {
    setProjectBuildingImages(images);
  };

  const onSortProjectBuildingImages = () => {
    const ids = projectBuildingImages.map((pbi) => pbi.id);
    const variables = { projectBuildingId: projectBuilding.id, ids };

    sortProjectBuildingImagesAsync(variables);
  };

  const loadImages = () => {
    if (!projectBuilding) return;

    const variables = { filter: { projectBuildingId: projectBuilding.id } };
    fetchProjectBuildingImagesAsync(variables)
      .then((items) => setProjectBuildingImages(items))
      .catch(() => setProjectBuildingImages([]));
  };

  return (
    <ImagesManager
      images={projectBuildingImages}
      onSortImages={onSortProjectBuildingImages}
      onSetImages={onSetProjectBuildingImages}
      onDeleteImage={onDeleteImage}
      onDrop={onDrop}
      onMarkAsDefault={onMarkAsDefault}
    />
  );
};

Images.propTypes = {
  projectBuilding: PropTypes.objectOf(PropTypes.any).isRequired
};

Images.defaultProps = {};

export default Images;
