import React, { useEffect, useMemo } from 'react';
import {
  Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, FormGroup
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { ModalNames, TeamUserPermissions } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import { saveTeamUser, setReloadTeamUsers } from '../../../../../../store/actions/teamUserActions';
import { getError } from '../../../../../../utils/requestUtils';
import useForm from '../../../../../../hooks/useForm';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { getTeamUserPermissionName } from '../../../../../../utils/enumUtils';
import { stripToEmpty } from '../../../../../../utils/stringUtils';
import { hasFormChanges, isFormValid } from '../../../../../../utils/formUtils';
import useFetchTeams from '../../../../../../hooks/useFetchTeams';

const i18nOpts = { scope: 'components.admin.users.show.teams.formModal.index' };

function onClose() {
  ModalService.close(ModalNames.TEAM_USER_FORM);
}

const FormModal = ({
  opened, params, currentCompany, user, teamUsers, ...props
}) => {
  const {
    submitting, setSubmitting, form, setForm, error, setError, onTextChange,
    initialForm, setInitialForm
  } = useForm();
  const { teams } = useFetchTeams(user.company.id);
  const { teamUser } = params;
  const isNew = !teamUser;

  const onSave = () => {
    setSubmitting(true);

    const input = {
      permission: stripToEmpty(form.permission),
    };

    if (isNew) {
      input.userId = user.id;
      input.teamId = parseInt(form.teamId, 10) || 0;
    } else input.id = teamUser.id;

    props.saveTeamUser({ input })
      .then(() => {
        props.setReloadTeamUsers(true);
        onClose();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSubmitting(false));
  };

  const loadTeamUser = () => {
    const formData = teamUser ? { ...teamUser, teamId: teamUser.team.id } : {};
    setInitialForm(formData);
    setForm(formData);
    setError(null);
  };

  const getAvailableTeams = () => {
    if (!isNew) return teams;

    const addedTeamIds = teamUsers.map((ug) => ug.team.id);
    return teams.filter((team) => !addedTeamIds.includes(team.id));
  };

  const availableTeams = useMemo(getAvailableTeams,
    [teams, teamUsers, isNew]);

  useEffect(() => {
    if (!opened) return;

    loadTeamUser();
  }, [opened]);

  const hasChanges = hasFormChanges(initialForm, form);
  const isValid = isFormValid(form, ['teamId', 'permission']);

  return (
    <Modal isOpen={opened} size="sm">
      <ModalHeader>{i18n.t('title', i18nOpts)}</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label for="teamId">{i18n.t('form.teamId', i18nOpts)}</Label>
          <Input
            type="select"
            name="teamId"
            id="teamId"
            value={form.teamId || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'teamId')}
            disabled={!isNew}
          >
            <option value="">{i18n.t('select.select')}</option>
            {
              availableTeams.map((team) => (
                <option key={`team-option-${team.id}`} value={team.id}>
                  {team.name}
                </option>
              ))
            }
          </Input>
          <InputError error={error} name="teamId" />
        </FormGroup>

        <FormGroup>
          <Label for="permission">{i18n.t('form.permissionLevel', i18nOpts)}</Label>
          <Input
            type="select"
            name="permission"
            id="permission"
            value={form.permission || ''}
            onChange={onTextChange}
          >
            <option value="">{i18n.t('select.select')}</option>
            {
              Object.values(TeamUserPermissions).map((tup) => (
                <option key={`team-user-permission-option-${tup}`} value={tup}>
                  {getTeamUserPermissionName(tup)}
                </option>
              ))
            }
          </Input>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSave} disabled={submitting || !hasChanges || !isValid}>
          {submitting && (<Spinner size="sm" className="mr-2" />)}
          {isNew ? i18n.t('buttons.assign', i18nOpts) : i18n.t('buttons.update')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={submitting}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.TEAM_USER_FORM]?.opened || false,
  params: store.modals[ModalNames.TEAM_USER_FORM]?.params || {},
  currentCompany: store.companies.currentCompany,
  user: store.users.user,
  teamUsers: store.teamUsers.teamUsers,
}), {
  saveTeamUser,
  setReloadTeamUsers
})(FormModal);
