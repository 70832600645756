import i18n from 'i18n-js';
import v from 'voca';

import { getCountryName } from './isoCountriesUtils';
import { formatCurrency, isValidAmount } from './currencyUtils';
import { IdVerificationProviders } from '../constants';
import { getIdDocumentTypeName } from './enumUtils';

const i18nOpts = { scope: 'utils.customerUtils' };

const MAX_BUYING_POWER_UPPER_LIMIT = 1.05;
const MIN_BUYING_POWER_LOWER_LIMIT = 0.9;
const MIN_BUYING_POWER_AMOUNT = Object.freeze(100000);
const MAX_RENTAL_POWER_UPPER_LIMIT = 1.05;
const MIN_RENTAL_POWER_LOWER_LIMIT = 0.95;

export function getCustomerInterestsFromHomes(homes) {
  const interests = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const home of homes) {
    const { singleFamilyHome, multiFamilyHome } = home;
    if (singleFamilyHome) {
      const { community, model } = singleFamilyHome;
      interests.push({ __typename: 'Community', ...community });
      if (model?.productType) interests.push({ __typename: 'ProductType', ...model.productType });
    } else {
      const { project, unit } = multiFamilyHome;
      interests.push({ __typename: 'Project', ...project });
      if (unit?.buildingModel) interests.push({ __typename: 'BuildingModel', ...unit.buildingModel });
    }
  }
  return interests;
}

export function getCustomerInterestsFromTrackingEvents(trackingEvents) {
  const dataObjects = trackingEvents.map((te) => te.dataObjects).flat();
  const interests = [];
  for (const object of dataObjects) {
    // eslint-disable-next-line no-underscore-dangle
    switch (object?.__typename) {
      case 'Community':
      case 'Project':
      case 'BuildingModel':
        interests.push(object);
        break;
      case 'Model':
        if (object.productType) interests.push({ __typename: 'ProductType', ...object.productType });
        break;
      default:
    }
  }
  return interests;
}

export function getNotVerifiedLabel() {
  return i18n.t('notVerified', i18nOpts);
}

const ALTERNATIVE_ISSUING_COUNTRY_NAMES = Object.freeze({
  GBR: 'Great Britain'
});

export function getCustomerIdentificationInfo(customer) {
  const { user } = customer;

  return user?.idVerification?.customerInfo || {};
}

export function getIdVerificationProvider(customer) {
  const { user } = customer;

  return user?.idVerification?.source;
}

export function getIdVerificationWithDocuments(customer) {
  const idVerificationProvider = getIdVerificationProvider(customer);

  if (idVerificationProvider === IdVerificationProviders.ONFIDO
    || idVerificationProvider === IdVerificationProviders.PLAID) {
    return customer.user.idVerification;
  }

  return null;
}

export function getDocumentCountryName(documentCountryCode) {
  if (!documentCountryCode) return null;

  const alternativeName = ALTERNATIVE_ISSUING_COUNTRY_NAMES[documentCountryCode];
  if (alternativeName) return alternativeName;

  return getCountryName(documentCountryCode);
}

export function getCustomerPreApprovalAmount(customer) {
  const { user: { preApproval } } = customer;

  if (!preApproval) return null;

  return preApproval.amount;
}

export function getCustomerAddress(customer) {
  if (!customer) return null;

  const {
    street, city, zip, state, country
  } = customer;
  let hasLocation = [street, city, zip, state, country].some((a) => !v.isBlank(a));
  if (hasLocation) {
    return {
      street, city, zip, state, country
    };
  }
  const { isIdVerified } = customer;
  if (!isIdVerified) return null;

  const customerInfo = getCustomerIdentificationInfo(customer);
  const address = customerInfo?.address || {};
  const {
    street: iStreet, city: iCity, state: iState, postalCode: iPostalCode, country: iCountry
  } = address;
  hasLocation = [iStreet, iCity, iState, iPostalCode, iCountry].some((a) => !v.isBlank(a));
  if (hasLocation) {
    return {
      street: iStreet, city: iCity, state: iState, zip: iPostalCode, country: iCountry
    };
  }
  return null;
}

export function formatCustomerAddress(address) {
  if (!address) return null;

  const {
    street, city, state, zip
  } = address;
  return [street, city, state, zip].filter((a) => !v.isBlank(a)).join(', ');
}

export function getCustomerHomePricesRange(customer) {
  const prices = customer.homes.map((h) => h.totalPrice);
  const validPrices = prices.filter((p) => isValidAmount(p) && p > 0);
  const min = Math.min(...validPrices);
  const max = Math.max(...validPrices);
  if (min === Infinity || max === Infinity) return { min: null, max: null };
  return { min, max };
}

export function getFormattedCustomerHomePricesRange(customer) {
  const { min, max } = getCustomerHomePricesRange(customer);

  if (min === max && max === null) return null;
  if (min === max) return formatCurrency(min, true, true);

  const parsedMinPrice = min && min > 0 ? formatCurrency(min, true, true) : null;
  const parsedMaxPrice = max && max > 0 ? formatCurrency(max, true, true) : null;

  const result = [];

  if (parsedMinPrice) result.push(parsedMinPrice);
  if (parsedMaxPrice) result.push(parsedMaxPrice);

  return result.join(' - ');
}

function getCustomerBuyingPowerRange(buyingPowerAmount) {
  if (!isValidAmount(buyingPowerAmount)) return null;

  const max = buyingPowerAmount * MAX_BUYING_POWER_UPPER_LIMIT;
  const min = buyingPowerAmount * MIN_BUYING_POWER_LOWER_LIMIT;

  return { max, min };
}

function getCustomerRentalPowerRange(rentalPowerAmount) {
  if (rentalPowerAmount === null || rentalPowerAmount === undefined) return null;

  const max = rentalPowerAmount * MAX_RENTAL_POWER_UPPER_LIMIT;
  const min = rentalPowerAmount * MIN_RENTAL_POWER_LOWER_LIMIT;

  return { max, min };
}

export function getFormattedCustomerBuyingPowerRange(affordability) {
  if (!affordability) return null;

  const { maxHomeAffordability, assessedAt } = affordability;

  if (!isValidAmount(maxHomeAffordability) && v.isBlank(assessedAt)) return null;

  return formatCustomerBuyingPowerRange(maxHomeAffordability);
}

export function formatCustomerBuyingPowerRange(buyingPowerAmount) {
  const affordabilityAmount = Math.max(MIN_BUYING_POWER_AMOUNT, buyingPowerAmount);
  const affordabilityRange = getCustomerBuyingPowerRange(affordabilityAmount);

  const minAmount = formatCurrency(affordabilityRange.min, true, true);
  const maxAmount = formatCurrency(affordabilityRange.max, true, true);
  return [minAmount, maxAmount].join(' - ');
}

export function formatCustomerRentalPowerRange(rentalPowerAmount) {
  const rentalPowerRange = getCustomerRentalPowerRange(rentalPowerAmount);
  if (!rentalPowerRange) return null;

  const { min, max } = rentalPowerRange;
  if (min === max) return formatCurrency(min);

  const minAmount = formatCurrency(min);
  const maxAmount = formatCurrency(max);
  return [minAmount, maxAmount].join(' - ');
}

export function getDocumentType(documentType) {
  if (!documentType) return null;

  return getIdDocumentTypeName(documentType);
}

export function getDocumentNumber(documentNumbers) {
  if (!documentNumbers?.length) return null;

  return documentNumbers[0];
}

export function hasCustomerPreApproval(customer) {
  if (!customer.user) return false;

  const { user: { preApproval } } = customer;
  return !!preApproval;
}

export function getCustomerInterestId(interest) {
  // eslint-disable-next-line no-underscore-dangle
  return `${interest.__typename}-${interest.id}`;
}

export function getCustomerInterestColor(interest) {
  // eslint-disable-next-line no-underscore-dangle
  return ['Community', 'Project'].includes(interest.__typename) ? 'primary' : 'light';
}

export function getCustomerHomeEquityAmount(customer) {
  if (!customer) return null;

  const { propertyInfo, outstandingMortgageBalance } = customer;
  const { homeValue } = propertyInfo || {};

  if (!isValidAmount(homeValue) || !isValidAmount(outstandingMortgageBalance)) return null;

  return homeValue - outstandingMortgageBalance;
}
