import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';
import { AiOutlineLink } from 'react-icons/ai';
import { UncontrolledTooltip } from 'reactstrap';

import {
  formatCurrency,
  isValidAmount
} from '../../../../../../utils/currencyUtils';
import {
  getFormattedCustomerFinancialAmount,
  hasCustomerFinancialAmount, hasCustomerPreApprovalAmount,
} from './utils';
import useHomeRental from '../../../../../../hooks/useHomeRental';
import PreApproval from './PreApproval';
import ViewReportButton from './ViewReportButton';

const i18nOpts = { scope: 'components.admin.customers.show.overview.verificationSection.index' };

const VerificationSection = ({ customer, currentCompany }) => {
  const {
    propertyInfo, isHomeVerified
  } = customer;
  const { isRental } = useHomeRental();

  const { homeValue, homeUrl } = propertyInfo || {};

  const hasFinancialAmount = hasCustomerFinancialAmount(customer);
  const hasHomeValuation = isHomeVerified && isValidAmount(homeValue);
  const hasPreApprovalAmount = hasCustomerPreApprovalAmount(customer, currentCompany);

  const formattedFinancialAmount = getFormattedCustomerFinancialAmount(customer, isRental);

  if (!hasFinancialAmount && !hasHomeValuation && !hasPreApprovalAmount) return null;

  return (
    <>
      <hr />

      <div className="d-flex flex-column gap-3">
        {hasFinancialAmount && (
          <div className="d-flex flex-column gap-2">
            <div>
              <div className="font-weight-500">
                {isRental ? i18n.t('rentingPower', i18nOpts) : i18n.t('buyingPower', i18nOpts)}
              </div>
              <div className="d-flex align-items-center">
                <div className="col-gray-700 font-18 font-weight-600">
                  {formattedFinancialAmount}
                </div>
              </div>
            </div>

            <ViewReportButton />
          </div>
        )}

        {hasHomeValuation && (
          <div>
            <div className="font-weight-500">{i18n.t('homeValuation', i18nOpts)}</div>
            <div className="d-flex align-items-center gap-2">
              <div className="col-gray-700 font-18 font-weight-600">
                {formatCurrency(homeValue)}
              </div>

              {!v.isBlank(homeUrl) && (
                <div>
                  <a href={homeUrl} target="_blank" rel="noreferrer" className="col-primary-1" id="btn-open-honest-door">
                    <AiOutlineLink size={18} className="float-left" />
                  </a>
                  <UncontrolledTooltip target="btn-open-honest-door">{i18n.t('buttons.view', i18nOpts)}</UncontrolledTooltip>
                </div>
              )}
            </div>
          </div>
        )}

        {hasPreApprovalAmount && (
          <PreApproval />
        )}
      </div>
    </>
  );
};

VerificationSection.propTypes = {};

VerificationSection.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}))(VerificationSection);
