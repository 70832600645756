import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';

import api from '../../../../api';
import { getTrafficByModelAndBuildingModelQuery } from '../../../../graphql';
import { addSampleVisitors } from '../../../../utils/sampleDataUtils';
import { formatRange } from '../../../../utils/dateUtils';
import ActionsMenu from '../components/ActionsMenu';
import { exportToCSV } from '../utils';

const i18nOpts = { scope: 'components.admin.overview.trafficByModel.index' };

function getChartData(models) {
  return {
    labels: models.map((c) => c.name),
    datasets: [
      {
        label: i18n.t('visitors', i18nOpts).toUpperCase(),
        backgroundColor: '#4D4D4D',
        borderWidth: 0,
        hoverBackgroundColor: '#4D4D4D',
        data: models.map((m) => m.visitorsCount)
      }
    ]
  };
}

const legend = {
  align: 'start',
  labels: {
    font: {
      color: '#000000',
      weight: '800',
      size: 10
    },
    boxWidth: 10
  }
};

const options = {
  scales: {
    x: {
      position: 'top',
      ticks: {
        major: {
          enabled: true,
        },
        font: {
          color: '#000000',
          weight: '800',
          size: 10
        },
        beginAtZero: true,
        stepSize: 1
      }
    },
    y: {
      ticks: {
        major: {
          enabled: true,
        },
        font: {
          color: '#000000',
          weight: '800',
          size: 10
        },
      }
    }
  },
  indexAxis: 'y',
  plugins: {
    legend
  }
};

function fetchModelsAsync(variables) {
  return api.graphql(getTrafficByModelAndBuildingModelQuery, variables)
    .then(({ data: { results } }) => Promise.resolve(results));
}

function generateSampleDataAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListModels($filter: ModelFilterInput) {
      result: listModels(filter: $filter) {
        items {
          id
          name
        }
      }
    }
  `, variables)
    .then((response) => {
      const { items } = response.data.result;
      const models = addSampleVisitors(items);
      return Promise.resolve(models);
    });
}

function fetchTrafficDataAsync(company, variables) {
  return company.enableSampleData ? generateSampleDataAsync(company.id)
    : fetchModelsAsync(variables);
}

const TrafficByModelBuildingModel = ({ currentCompany, filter, onModelPress }) => {
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [rangeDateLabel, setRangeDateLabel] = useState('');

  const onExport = () => {
    const headers = [i18n.t('model', i18nOpts), i18n.t('visitors', i18nOpts)];
    const rows = models.map((model) => [model.name, model.visitorsCount]);
    exportToCSV(headers, rows, `Traffic By Models ${rangeDateLabel}.csv`);
  };

  useEffect(() => {
    if (!filter) return;

    setLoading(true);
    setRangeDateLabel(formatRange(filter?.customDateRange));
    getModelTraffic();
  }, [filter]);

  const getModelTraffic = () => {
    const variables = { reportFilter: filter };
    fetchTrafficDataAsync(currentCompany, variables)
      .then((items) => setModels(items))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const data = getChartData(models);

  return (
    <Card body>
      <div className="d-flex align-items-center">
        <h4>{i18n.t('title', i18nOpts)}</h4>
        <div className="ml-auto">
          <ActionsMenu onExport={onExport} />
        </div>
      </div>
      <div className="text-uppercase mb-3">
        <small>{rangeDateLabel}</small>
      </div>

      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <Bar
          data={data}
          options={options}
          getElementAtEvent={(element) => onModelPress(element, models)}
        />
      )}
    </Card>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}), {})(TrafficByModelBuildingModel);
