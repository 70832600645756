import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import PropTypes from 'prop-types';

import useFetchSalesReps from '../../../../hooks/useFetchSalesReps';
import useFetchTeams from '../../../../hooks/useFetchTeams';
import { isSalesRep, isSalesTeam } from '../../../../utils/salesRepUtils';
import { SalableTypes, SellerTypes } from '../../../../constants';
import { saveSellerSpace } from '../../../../store/actions/sellerSpaceActions';

const i18nOpts = { scope: 'components.global.sellersManagement.addSellerDropdown.index' };

const AddSellerDropdown = ({
  sellerSpaces, salableId, salableType, currentCompany, onAfterAdd, ...props
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [salesReps] = useFetchSalesReps(currentCompany.id);
  const { teams } = useFetchTeams(currentCompany.id);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getAvailableSalesReps = () => {
    const selectedSalesRepIds = sellerSpaces.filter((s) => isSalesRep(s.seller))
      .map((s) => s.seller.id);
    return salesReps.filter((sr) => !selectedSalesRepIds.includes(sr.id));
  };

  const getAvailableTeams = () => {
    const selectedTeamIds = sellerSpaces.filter((ss) => isSalesTeam(ss.seller))
      .map((s) => s.seller.id);
    return teams.filter((sr) => !selectedTeamIds.includes(sr.id));
  };

  const onAddSeller = (seller, sellerType) => {
    const input = {
      salableId,
      salableType,
      sellerId: seller.id,
      sellerType
    };
    const variables = { input };
    props.saveSellerSpace(variables)
      .then(() => onAfterAdd());
  };

  const availableSalesReps = useMemo(getAvailableSalesReps, [salesReps, sellerSpaces]);
  const availableTeams = useMemo(getAvailableTeams, [teams, sellerSpaces]);

  const hasSalesRepsAndTeams = availableSalesReps.length || availableTeams.length;

  return (
    <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
      <DropdownToggle color="outline-secondary" caret disabled={!hasSalesRepsAndTeams}>
        {i18n.t('buttons.add', i18nOpts)}
      </DropdownToggle>
      <DropdownMenu right>
        {availableTeams.length > 0 && (
          <>
            <h6 className="dropdown-header bg-light">{i18n.t('teams', i18nOpts)}</h6>

            {
              availableTeams.map((team) => (
                <DropdownItem key={`available-team-${team.id}`} onClick={() => onAddSeller(team, SellerTypes.SALES_TEAM)}>
                  <div>{team.name}</div>
                  <div className="text-secondary small">{i18n.t('teamUsersCount', { ...i18nOpts, count: team.activeUsersCount })}</div>
                </DropdownItem>
              ))
            }
          </>
        )}

        {availableSalesReps.length > 0 && (
          <>
            <h6 className="dropdown-header bg-light">{i18n.t('salesReps', i18nOpts)}</h6>

            {
              availableSalesReps.map((salesRep) => (
                <DropdownItem key={`available-sales-rep-${salesRep.id}`} onClick={() => onAddSeller(salesRep, SellerTypes.SALES_REP)}>
                  <div>{salesRep.name}</div>
                  <div className="text-secondary small">{salesRep.email}</div>
                </DropdownItem>
              ))
            }
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

AddSellerDropdown.propTypes = {
  sellerSpaces: PropTypes.arrayOf(PropTypes.any).isRequired,
  salableId: PropTypes.number.isRequired,
  salableType: PropTypes.oneOf(Object.values(SalableTypes)).isRequired,
  onAfterAdd: PropTypes.func
};

AddSellerDropdown.defaultProps = {
  onAfterAdd: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveSellerSpace
})(AddSellerDropdown);
