import React, { Component } from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import { getProductTypes, setProductTypes, setProductTypesViewMode } from
  '../../../../store/actions/productTypeActions';
import ListEmpty from '../../../common/ListEmpty';
import { ViewModes } from '../../../../constants';
import GridView from './GridView';
import ListView from './ListView';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../utils/authUtils';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.productTypes.list.index' };

class List extends Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };

    this.onSetProductTypes = this.onSetProductTypes.bind(this);
    this.onViewModeChange = this.onViewModeChange.bind(this);
  }

  componentDidMount() {
    this.loadProductTypes();
  }

  onSetProductTypes(sortedProductTypes) {
    const { props } = this;
    props.setProductTypes(sortedProductTypes);
  }

  onViewModeChange(viewMode) {
    const { props } = this;
    props.setProductTypesViewMode(viewMode);
  }

  loadProductTypes() {
    const { props } = this;
    const { currentCompany } = this.props;

    const variables = { filter: { companyId: currentCompany.id } };
    props.getProductTypes(variables)
      .finally(() => this.setState({ mounted: true }));
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return <Loading loading fullScreen />;

    const { productTypes, loading, viewMode } = this.props;
    const isAdmin = isMasterAdmin() || isCompanyAdmin() || isSalesLead();

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{i18n.t('heading', i18nOpts)}</h2>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <div className="ml-3">
              <ViewModesDropdown viewMode={viewMode} onChange={this.onViewModeChange} />
            </div>
            {isAdmin && (
              <Link to="/admin/productTypes/form" className="btn btn-primary ml-3">
                <i className="fas fa-plus mr-2" />
                {i18n.t('buttons.add', i18nOpts)}
              </Link>
            )}
          </div>
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={productTypes} />

            {productTypes.length > 0 && (
              <div className="mb-4">
                {viewMode === ViewModes.GRID ? (
                  <GridView />
                ) : (
                  <ListView />
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  productTypes: store.productTypes.productTypes,
  loading: store.productTypes.getProductTypes.loading,
  viewMode: store.productTypes.viewMode,
  currentCompany: store.companies.currentCompany
}), {
  getProductTypes,
  setProductTypes,
  setProductTypesViewMode
})(List);
