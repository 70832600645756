import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import v from 'voca';

import styles from './FormDropdownItem.module.scss';
import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';

const FormDropdownItem = ({
  imageUrl, name, price, ...props
}) => (
  <DropdownItem
    className="d-flex align-items-center gap-3"
    {...props}
  >
    {!v.isBlank(imageUrl) && (
      <div className={styles.imageContainer}>
        <img src={imageUrl} alt={name} className={styles.image} />
      </div>
    )}
    <div>
      <div className="font-weight-500">{name}</div>
      {isValidAmount(price) && (
      <div>
        <small>{formatCurrency(price)}</small>
      </div>
      )}
    </div>
  </DropdownItem>
);

FormDropdownItem.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  price: PropTypes.number,
};

FormDropdownItem.defaultProps = {
  imageUrl: null,
  price: null
};

export default FormDropdownItem;
