import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import { CreditCardBrands } from '../../../../../../../../constants';
import VisaIcon from '../../../../../../../../assets/icons/credit-card/VisaIcon';
import MasterCardIcon from '../../../../../../../../assets/icons/credit-card/MasterCardIcon';
import AmericanExpressIcon from '../../../../../../../../assets/icons/credit-card/AmericanExpressIcon';
import DiscoverIcon from '../../../../../../../../assets/icons/credit-card/DiscoverIcon';
import DinersIcon from '../../../../../../../../assets/icons/credit-card/DinersIcon';
import UnionPayIcon from '../../../../../../../../assets/icons/credit-card/UnionPayIcon';
import JcbIcon from '../../../../../../../../assets/icons/credit-card/JcbIcon';

const CardBrandIcon = ({ brand }) => {
  switch (brand) {
    case CreditCardBrands.VISA:
      return <VisaIcon />;
    case CreditCardBrands.MASTER_CARD:
      return <MasterCardIcon />;
    case CreditCardBrands.AMERICAN_EXPRESS:
      return <AmericanExpressIcon />;
    case CreditCardBrands.DISCOVER:
      return <DiscoverIcon />;
    case CreditCardBrands.DINERS:
      return <DinersIcon />;
    case CreditCardBrands.UNION_PAY:
      return <UnionPayIcon />;
    case CreditCardBrands.JCB:
      return <JcbIcon />;
    default:
      return null;
  }
};

const CreditCard = ({ paymentMethodDetails }) => {
  const { brand, last4 } = paymentMethodDetails || {};

  if (v.isBlank(last4)) return null;

  return (
    <div>
      <CardBrandIcon brand={brand} />
      <span className="mx-1 align-sub">****</span>
      {last4}
    </div>
  );
};

CreditCard.propTypes = {
  paymentMethodDetails: PropTypes.objectOf(PropTypes.any).isRequired
};

CreditCard.defaultProps = {};

export default CreditCard;
