import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import './styles.scss';
import api from '../../../../api';
import SecureStore from '../../../../utils/secureStore';
import { isTecSupport, isMasterAdmin } from '../../../../utils/authUtils';

function fetchCompaniesAsync() {
  return api.graphql(`
    {
      result: listCompanies(pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `)
    .then(({ data }) => Promise.resolve(data.result.items.map((c) => (
      { label: c.name, value: c.id, ...c }))));
}

const CompanySelect = ({ loading, currentCompany }) => {
  const [companies, setCompanies] = useState([]);
  const canSelectCompany = isMasterAdmin() || isTecSupport();

  const onChange = (companyId) => {
    SecureStore.setCurrentCompanyId(companyId);
    window.location = '/admin/overview';
  };

  function noValue() {
    return 'Company not found';
  }

  useEffect(() => {
    if (loading || loading === undefined) {
      fetchCompaniesAsync()
        .then((list) => setCompanies(list))
        .catch(() => {});
    }
  }, [loading]);

  const selectedCompany = useMemo(() => {
    if (!currentCompany) return null;

    return companies.find((c) => c.id === currentCompany.id);
  }, [companies, currentCompany]);

  if (!selectedCompany || !canSelectCompany) return null;

  return (
    <Select
      value={selectedCompany}
      defaultValue={selectedCompany}
      onChange={(c) => onChange(c.id)}
      options={companies}
      className="react-select-container"
      classNamePrefix="react-select"
      noOptionsMessage={noValue}
    />
  );
};

export default connect((store) => ({
  loading: store.companies.getCompanies.loading,
  currentCompany: store.companies.currentCompany
}), {})(CompanySelect);
