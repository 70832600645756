import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Card, Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import classNames from 'classnames';

import ListItem from './ListItem';
import ListHeader from './ListHeader';
import { setReloadHomes } from '../../../../../../store/actions/homeActions';
import { listDeals } from '../../../../../../store/actions/dealActions';
import DealModal from '../../../../../global/home/DealModal';
import DepositRefundFormModal from '../../../../../global/Deposit/DepositRefundFormModal';
import ListEmpty from '../../../../../common/ListEmpty';

const i18nOpts = { scope: 'components.admin.customers.show.details.deals.index' };

const Deals = ({
  deals, customer, reloadHomes, currentCompany, ...props
}) => {
  const [loading, setLoading] = useState(false);

  const loadDeals = () => {
    const variables = {
      filter: { contactId: customer.id, companyId: currentCompany.id },
      withTotalOffers: true,
      companyId: currentCompany.id,
    };
    props.listDeals(variables)
      .finally(() => {
        props.setReloadHomes(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDeals();
  }, []);

  useEffect(() => {
    if (!reloadHomes) return;

    loadDeals();
  }, [reloadHomes]);

  return (
    <div>
      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <div>
          <ListEmpty loading={loading} items={deals} />

          {deals.length > 0 && (
            <Card body className="p-0">
              <div className="p-4">
                <h4 className="mb-0">{i18n.t('title', i18nOpts)}</h4>
              </div>

              <Table bordered responsive className={classNames('mb-0', 'table-borderless-in-card')}>
                <thead>
                  <ListHeader />
                </thead>
                <tbody>
                  {
                    deals.map((deal) => (
                      <ListItem key={`deal-${deal.contactId}`} deal={deal} />
                    ))
                  }
                </tbody>
              </Table>

              <DealModal />
              <DepositRefundFormModal />
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

Deals.propTypes = {};

Deals.defaultProps = {};

export default connect((store) => ({
  deals: store.deals.deals,
  reloadHomes: store.homes.reloadHomes,
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}), {
  listDeals,
  setReloadHomes
})(Deals);
