import React from 'react';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import v from 'voca';

import { TaxRateTypes } from '../../../../../constants';
import { isValidLength } from '../../../../../utils/arrayUtils';
import { getArrayMappedNames, getSanitizedType, getTaxRatePercentage } from '../../../../../utils/taxRateUtils';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';
import { mergeCommunitiesProjects } from '../../utils';

const i18nOpts = { scope: 'components.admin.taxRates.show.overview.index' };

const Overview = ({ taxRate }) => {
  if (!taxRate) return null;

  const creatorName = getCreatorDisplayName(taxRate);
  const lastModifierName = getLastModifierDisplayName(taxRate);

  return (
    <div>
      <div className="mb-4">
        <div className="label">{i18n.t('name', i18nOpts)}</div>
        <div><strong>{taxRate.name}</strong></div>
      </div>

      <div className="mb-4">
        <div className="label">{i18n.t('rate', i18nOpts)}</div>
        <div><strong>{getTaxRatePercentage(taxRate.rate)}</strong></div>
      </div>

      <div className="mb-4">
        <div className="label">{i18n.t('type', i18nOpts)}</div>
        <div><strong>{getSanitizedType(taxRate.type)}</strong></div>
      </div>

      { taxRate.type === TaxRateTypes.CITY_BASED && isValidLength(taxRate.cities) && (
        <div className="mb-4">
          <div className="label">{i18n.t('cities', i18nOpts)}</div>
          <div><strong>{getArrayMappedNames(taxRate.cities)}</strong></div>
        </div>
      )}

      { taxRate.type === TaxRateTypes.COMMUNITY_PROJECT_BASED
        && (isValidLength(taxRate.communities) || isValidLength(taxRate.projects)) && (
        <div className="mb-4">
          <div className="label">{i18n.t('communitiesProjects', i18nOpts)}</div>
          <div>
            <strong>
              {getArrayMappedNames(mergeCommunitiesProjects(taxRate.communities, taxRate.projects))}
            </strong>
          </div>
        </div>
      )}

      { !v.isBlank(creatorName) && (
        <div className="mb-4">
          <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
          <div><strong>{creatorName}</strong></div>
        </div>
      )}

      { !v.isBlank(lastModifierName) && (
        <div className="mb-4">
          <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
          <div><strong>{lastModifierName}</strong></div>
        </div>
      )}
    </div>
  );
};

Overview.propTypes = {
  taxRate: PropTypes.objectOf(PropTypes.any)
};

Overview.defaultProps = {
  taxRate: null
};

export default Overview;
