import React, { useEffect, useState } from 'react';
import {
  Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, FormGroup
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { MappableTypes, ModalNames } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import { getError } from '../../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { saveTrackingPage } from '../../../../../../store/actions/trackingPageActions';
import { fetchCommunitiesAsync, fetchModelsAsync } from './utils';
import { stripToEmpty } from '../../../../../../utils/stringUtils';
import { parseToInt } from '../../../../../../utils/parserUtils';

const i18nOpts = { scope: 'components.admin.integrations.tracking.pages.formModal.index' };

function onClose() {
  ModalService.close(ModalNames.TRACKING_PAGE_FORM);
}

const FormModal = ({
  opened, params, currentCompany, ...props
}) => {
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [communities, setCommunities] = useState([]);
  const [models, setModels] = useState([]);

  const onSave = () => {
    setSaving(true);

    const { trackingPage } = params;

    const input = {
      url: stripToEmpty(form.url),
      mappableType: stripToEmpty(form.mappableType),
      mappableId: parseToInt(form.mappableId) || 0,
      ...(trackingPage ? { id: trackingPage.id } : { companyId: currentCompany.id }),
    };

    props.saveTrackingPage({ input })
      .then(() => onClose())
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const initForm = () => {
    const { trackingPage } = params;

    if (trackingPage) {
      const {
        id, url, mappableId, mappableType
      } = trackingPage;
      setForm({
        id, url, mappableId, mappableType
      });
    } else setForm({ });

    setError(null);
  };

  const loadCommunities = () => {
    fetchCommunitiesAsync(currentCompany.id)
      .then((items) => setCommunities(items))
      .catch((e) => toast.error(e.message));
  };

  const loadModels = () => {
    fetchModelsAsync(currentCompany.id)
      .then((items) => setModels(items))
      .catch((e) => toast.error(e.message));
  };

  useEffect(() => {
    if (!opened) return;

    initForm();
  }, [opened]);

  useEffect(() => {
    loadCommunities();
    loadModels();
  }, []);

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{i18n.t('title', i18nOpts)}</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label for="url">{i18n.t('url', i18nOpts)}</Label>
          <Input
            type="textarea"
            name="url"
            id="url"
            value={form.url || ''}
            placeholder={i18n.t('urlPlaceholder', i18nOpts)}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'url')}
          />
          <InputError name="url" error={error} />
        </FormGroup>

        <Label className="d-block">{i18n.t('mapActivityTo', i18nOpts)}</Label>

        <div className="mb-2">
          <FormGroup check inline>
            <Label for="community">
              <Input
                type="radio"
                name="mappableType"
                id="community"
                checked={(form.mappableType === MappableTypes.COMMUNITY)}
                value={MappableTypes.COMMUNITY}
                onChange={onTextChange}
              />
              {i18n.t('community', i18nOpts)}
            </Label>
          </FormGroup>

          <FormGroup check inline className="ml-3">
            <Label for="model">
              <Input
                type="radio"
                name="mappableType"
                id="model"
                checked={(form.mappableType === MappableTypes.MODEL)}
                value={MappableTypes.MODEL}
                onChange={onTextChange}
              />
              {i18n.t('model', i18nOpts)}
            </Label>
          </FormGroup>

          <FormGroup>
            <InputError error={error} name="mappableType" type="radio" />
          </FormGroup>
        </div>

        {form.mappableType === MappableTypes.COMMUNITY && (
          <FormGroup>
            <Label for="mappableId">{i18n.t('community', i18nOpts)}</Label>
            <Input
              type="select"
              name="mappableId"
              id="mappableId"
              value={form.mappableId || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'mappableId')}
            >
              <option value="">{i18n.t('select.select')}</option>
              {
                communities.map((community) => (
                  <option key={`community-option-${community.id}`} value={community.id}>
                    {community.name}
                  </option>
                ))
              }
            </Input>
            <InputError error={error} name="mappableId" />
          </FormGroup>
        )}

        {form.mappableType === MappableTypes.MODEL && (
          <FormGroup>
            <Label for="mappableId">{i18n.t('model', i18nOpts)}</Label>
            <Input
              type="select"
              name="mappableId"
              id="mappableId"
              value={form.mappableId || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'mappableId')}
            >
              <option value="">{i18n.t('select.select')}</option>
              {
                models.map((model) => (
                  <option key={`model-option-${model.id}`} value={model.id}>
                    {model.name}
                  </option>
                ))
              }
            </Input>
            <InputError error={error} name="mappableId" />
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSave} disabled={saving}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.TRACKING_PAGE_FORM]?.opened || false,
  params: store.modals[ModalNames.TRACKING_PAGE_FORM]?.params || {},
  currentCompany: store.companies.currentCompany
}), {
  saveTrackingPage,
})(FormModal);
