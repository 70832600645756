import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Switch from 'rc-switch';
import v from 'voca';
import { toast } from 'react-toastify';
import { Link } from 'gatsby';
import { BiLinkAlt } from 'react-icons/bi';

import ActionsMenu from '../../../../../global/ActionsMenu';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';
import { getError } from '../../../../../../utils/requestUtils';
import { saveWebhook, deleteWebhook } from '../../../../../../store/actions/webhookActions';

const ListItem = ({ webhook, ...props }) => {
  const onEdit = () => {
    ModalService.open(ModalNames.WEBHOOK_FORM, { webhook });
  };

  const onDelete = (webhookId) => {
    props.deleteWebhook(webhookId)
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
      });
  };

  const onEnableToggle = (isEnabled) => {
    const input = { id: webhook.id, enabled: isEnabled };

    props.saveWebhook({ input })
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
      });
  };

  const { events = [] } = webhook;

  return (
    <tr>
      <td>
        <Link to={`/admin/integrations/webhooks/${webhook.id}/deliveries`} className="word-break-break-all">
          <BiLinkAlt size="16" className="mr-2" />
          {webhook.hookUrl}
        </Link>
      </td>
      <td>
        {
          events.map((event) => (
            <div key={`event-${webhook.id}-${event}`}>
              <code className="text-nowrap">{event}</code>
            </div>
          ))
        }
      </td>
      <td>
        <Switch checked={webhook.enabled || false} onChange={onEnableToggle} />
      </td>
      <td className="text-right">
        <ActionsMenu
          item={webhook}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  webhook: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
}), {
  deleteWebhook,
  saveWebhook
})(ListItem);
