import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import classnames from 'classnames';
import moment from 'moment';
import { Table } from 'reactstrap';

import { isRecently } from '../../../../utils/dateUtils';
import { DateFormats } from '../../../../constants';
import ActionsMenu from '../../../global/ActionsMenu';

const i18nOpts = { scope: 'components.admin.companies.table.index' };

const CompaniesTable = ({
  companies, isArchived, onDelete, onEdit, deleteMessage, deleteText
}) => (
  <div className="table-responsive mb-4">
    <Table bordered>
      <thead>
        <tr>
          <th>{i18n.t('name', i18nOpts)}</th>
          <th>{i18n.t('createdAt', i18nOpts)}</th>
          {isArchived && (
            <th>{i18n.t('archivedAt', i18nOpts)}</th>
          )}
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          companies.map((company) => (
            <tr key={`company-${company.id}`}>
              <td>{company.name}</td>
              <td>
                <span className={classnames({ 'text-success': isRecently(company.createdAt) })}>
                  {moment(company.createdAt).format(DateFormats.MMM_DD_YYYY)}
                </span>
              </td>
              {isArchived && (
                <td>
                  <span className={classnames({ 'text-success': isRecently(company.archivedAt) })}>
                    {moment(company.archivedAt).format(DateFormats.MMM_DD_YYYY)}
                  </span>
                </td>
              )}
              <td className="text-right">
                <ActionsMenu
                  item={company}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  deleteText={deleteText}
                  deleteMessage={deleteMessage}
                />
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  </div>
);

CompaniesTable.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.any).isRequired,
  isArchived: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  deleteMessage: PropTypes.string,
  deleteText: PropTypes.string
};

CompaniesTable.defaultProps = {
  isArchived: false,
  onEdit: null,
  deleteMessage: '',
  deleteText: ''
};

export default CompaniesTable;
