import React from 'react';
import PropTypes from 'prop-types';
import { getLastModifierDisplayName } from '../../../utils/auditUtils';

const LastModifiedByTableItem = ({ instance }) => {
  const modifierName = getLastModifierDisplayName(instance);
  return (
    <td>
      {modifierName}
    </td>
  );
};

LastModifiedByTableItem.propTypes = {
  instance: PropTypes.objectOf(PropTypes.any).isRequired
};

LastModifiedByTableItem.defaultProps = {};

export default LastModifiedByTableItem;
