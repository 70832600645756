import v from 'voca';

function isEmptyValidUntil(validUntil) {
  return v.isBlank(validUntil?.amount) && v.isBlank(validUntil?.period);
}

export function validateValidUntil(validUntil) {
  if (isEmptyValidUntil(validUntil)) return true;

  return v.isDigit(validUntil?.amount) && !v.isBlank(validUntil?.period);
}

export function buildValidUntil(validUntil) {
  if (isEmptyValidUntil(validUntil)) return {};
  return {
    amount: parseInt(validUntil?.amount, 10),
    period: validUntil?.period
  };
}

export function buildDisclaimerTexts(disclaimerTexts) {
  const pdfDisclaimer = disclaimerTexts.find((d) => d.section === 'exportPagePdf');
  const text = pdfDisclaimer?.text || '';
  pdfDisclaimer.text = text.substr(0, 1200);

  return disclaimerTexts;
}
