import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import ListItem from './ListItem';
import { listTeamUsers, setReloadTeamUsers } from '../../../../../../store/actions/teamUserActions';

const i18nOpts = { scope: 'components.admin.users.show.teams.list.index' };

const List = ({
  teamUsers, user, reloadTeamUsers, ...props
}) => {
  const loadTeamUsers = () => {
    const variables = {
      filter: { userId: user.id }
    };
    props.listTeamUsers(variables)
      .catch(() => {})
      .finally(() => props.setReloadTeamUsers(false));
  };

  useEffect(() => {
    loadTeamUsers();
  }, []);

  useEffect(() => {
    if (!reloadTeamUsers) return;

    loadTeamUsers();
  }, [reloadTeamUsers]);

  if (!teamUsers.length) return null;

  return (
    <Table bordered responsive>
      <thead>
        <tr>
          <th style={{ width: '70px' }}>&nbsp;</th>
          <th>{i18n.t('team', i18nOpts)}</th>
          <th>{i18n.t('permissionLevel', i18nOpts)}</th>
          <th style={{ width: 50 }}>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          teamUsers.map((teamUser) => (
            <ListItem
              key={`user-team-${teamUser.id}`}
              teamUser={teamUser}
            />
          ))
        }
      </tbody>
    </Table>
  );
};

List.propTypes = {};

List.defaultProps = {};

export default connect((store) => ({
  teamUsers: store.teamUsers.teamUsers,
  reloadTeamUsers: store.teamUsers.reloadTeamUsers,
  user: store.users.user
}), {
  listTeamUsers,
  setReloadTeamUsers,
})(List);
