import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import api from '../../../../../../api';
import { saveModelPackage } from '../../../../../../store/actions/modelPackageActions';
import AddItemDropdown from '../../../../../common/AddItemDropdown';

const i18nOpts = { scope: 'components.admin.models.show.packages.addDropDown.index' };

function fetchPackagesAsync(companyId) {
  const variables = { filter: { companyId, isMultifamily: false } };

  return api.graphql(`
    query ListPackages($filter: PackageFilterInput) {
      items: listPackages(filter: $filter) {
        id
        name
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

const AddDropDown = ({
  model, modelPackages, currentCompany, ...props
}) => {
  const [packages, setPackages] = useState([]);
  const [isDropdownOpen, onToggleDropdown] = useState(false);

  const loadPackages = () => {
    fetchPackagesAsync(currentCompany.id)
      .then((items) => setPackages(items))
      .catch(() => {});
  };

  const onSelect = (packageId) => {
    const input = { modelId: model.id, packageId };
    props.saveModelPackage({ input });
  };

  useEffect(() => {
    loadPackages();
  }, []);

  const availablePackages = packages.filter(
    (p) => modelPackages.findIndex((mp) => mp.package.id === p.id) === -1
  );

  return (
    <AddItemDropdown
      open={isDropdownOpen}
      items={availablePackages}
      toggleDropdown={onToggleDropdown}
      onSelect={onSelect}
      label={i18n.t('title', i18nOpts)}
    />
  );
};

AddDropDown.propTypes = {};

AddDropDown.defaultProps = {};

export default connect((store) => ({
  model: store.models.model,
  modelPackages: store.modelPackages.modelPackages,
  currentCompany: store.companies.currentCompany
}), {
  saveModelPackage
})(AddDropDown);
