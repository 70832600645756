import React from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { cleanReportItems } from '../../../../store/actions/overviewActions';

const i18nOpts = { scope: 'components.admin.overview.reportItemTitle.index' };

const Title = ({ children }) => (
  <h4 className="mb-1">{children}</h4>
);

const ReportItemTitle = ({
  currentModel, currentCommunity, currentBuildingModel, currentProject, ...props
}) => {
  const clearSelection = () => {
    props.cleanReportItems();
  };

  return (
    <div>
      { currentModel && (
        <Title>{i18n.t('modelReport', { ...i18nOpts, name: currentModel.name })}</Title>
      )}
      { currentCommunity && (
        <Title className="mb-1">{i18n.t('communityReport', { ...i18nOpts, name: currentCommunity.name })}</Title>
      )}
      { currentProject && (
        <Title className="mb-1">{i18n.t('projectReport', { ...i18nOpts, name: currentProject.name })}</Title>
      )}
      { currentBuildingModel && (
        <Title className="mb-1">{i18n.t('modelReport', { ...i18nOpts, name: currentBuildingModel.name })}</Title>
      )}
      {(currentCommunity || currentModel || currentBuildingModel || currentProject) && (
        <div
          className="col-primary-1 font-12"
          role="button"
          aria-hidden
          onClick={clearSelection}
        >
          {i18n.t('clearSelection', i18nOpts)}
        </div>
      )}
    </div>
  );
};

export default connect((store) => ({
  currentCommunity: store.overview.community,
  currentModel: store.overview.model,
  currentBuildingModel: store.overview.buildingModel,
  currentProject: store.overview.project
}), {
  cleanReportItems
})(ReportItemTitle);
