import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import './styles.scss';

const DEFAULT_CENTER = {
  lat: 30.277372,
  lng: -97.732439
};
const DEFAULT_ZOOM = 5;

const Map = ({ position }) => {
  const centerAndFitBounds = (map, maps) => {
    const marker = new maps.Marker({
      position
    });
    marker.setMap(map);
    const bounds = new maps.LatLngBounds(position);

    map.fitBounds(bounds);
    map.setZoom(10);
  };

  return (
    <div className="user-location-map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.GATSBY_GOOGLE_API_KEY,
          libraries: ['visualization', 'places']
        }}
        defaultCenter={DEFAULT_CENTER}
        defaultZoom={DEFAULT_ZOOM}
        onGoogleApiLoaded={({ map, maps }) => centerAndFitBounds(map, maps)}
        yesIWantToUseGoogleMapApiInternals
      />
    </div>
  );
};

Map.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }).isRequired
};

export default Map;
