import { isCommunityModelPriceRule } from '../../../../../utils/priceRuleUtils';
import { isValidAmount } from '../../../../../utils/currencyUtils';

function getMinCommunityModelPrice(priceRules) {
  const communityModelPriceRules = priceRules.filter((priceRule) => {
    if (isCommunityModelPriceRule(priceRule)) return isValidAmount(priceRule.price);
    return false;
  });
  const priceRulePrices = communityModelPriceRules.map((pr) => pr.price);
  const minCommunityModelPrice = Math.min(...priceRulePrices);

  return minCommunityModelPrice === Infinity ? 0 : minCommunityModelPrice;
}

export function getModelTotalBasePrice(model, priceRules) {
  const modelBasePrice = model?.basePrice;

  if (!modelBasePrice) return null;

  const minLotPrice = model?.lotWorksSettings?.minLotPrice || model?.minLotPrice || 0;
  const minCommunityModelPrice = getMinCommunityModelPrice(priceRules);

  const basePrice = minCommunityModelPrice || modelBasePrice;
  return basePrice + minLotPrice;
}
