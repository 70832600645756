import React from 'react';
import {
  Card, CardBody, CardImg
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import i18n from 'i18n-js';

import './styles.scss';
import { DEFAULT_IMAGE } from '../../../../../constants';
import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';
import { buildPackagesPath } from '../../../../../utils/multiFamilyUtils';

const i18nOpts = { scope: 'components.admin.packages.list.index' };

const ListItem = ({ package: specPackage }) => {
  if (!specPackage) return null;

  const imageUrl = specPackage.imageUrl || DEFAULT_IMAGE;
  const url = buildPackagesPath(`/${specPackage.id}`);

  return (
    <Card className="package-card">
      <Link to={url}>
        <CardImg top width="100%" src={imageUrl} alt={specPackage.name} className="mb-0 package-image" />
      </Link>
      <CardBody>
        <h3>
          <Link to={url}>{specPackage.name}</Link>
        </h3>
        {isValidAmount(specPackage.basePrice) && (
          <div className="mt-2">
            <div className="text-uppercase font-bold font-12">
              {i18n.t('from', { ...i18nOpts, price: formatCurrency(specPackage.basePrice) })}
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

ListItem.propTypes = {
  package: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
