import { useState } from 'react';

const useFilters = (defaultFilters) => {
  const [initialFilters, setInitialFilters] = useState(defaultFilters);
  const [filters, setFilters] = useState(defaultFilters);

  const changeFilters = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const clearFilters = () => {
    setFilters({});
  };

  const cancelFilters = () => {
    setFilters(initialFilters);
  };

  return {
    filters,
    setFilters,
    changeFilters,
    clearFilters,
    cancelFilters,
    initialFilters,
    setInitialFilters
  };
};

export default useFilters;
