import React from 'react';
import i18n from 'i18n-js';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';

import InputError, { isInputInvalid } from '../../../../../ui/InputError';

const i18nOpts = { scope: 'components.admin.externalDataSources.salesSimplicity.details.index' };

const SalesSimplicityFormFields = ({ form, onTextChange, error }) => (
  <div>
    <Row form className="mb-3">
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="username">{i18n.t('username', i18nOpts)}</Label>
          <Input
            type="text"
            name="username"
            id="username"
            value={form.username || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'username')}
          />
          <InputError name="username" error={error} />
        </FormGroup>
      </Col>
    </Row>
    <Row form className="mb-3">
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="password">{i18n.t('password', i18nOpts)}</Label>
          <Input
            type="text"
            name="password"
            id="password"
            value={form.password || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'password')}
          />
          <InputError name="password" error={error} />
        </FormGroup>
      </Col>
    </Row>
    <Row form className="mb-3">
      <Col lg="4" md="6" sm="8" xs="12">
        <FormGroup>
          <Label for="token">{i18n.t('token', i18nOpts)}</Label>
          <Input
            type="text"
            name="token"
            id="token"
            value={form.token || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'token')}
          />
          <InputError name="token" error={error} />
        </FormGroup>
      </Col>
    </Row>
  </div>
);

export default SalesSimplicityFormFields;
