import React from 'react';
import {
  Card, CardBody, CardImg
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './styles.scss';
import { DEFAULT_IMAGE } from '../../../../../constants';

const ListItem = ({ city }) => {
  if (!city) return null;

  const imageUrl = city.defaultImageUrl || DEFAULT_IMAGE;

  return (
    <Card className="city-card">
      <Link to={`/admin/cities/${city.id}`}>
        <CardImg top width="100%" src={imageUrl} alt={city.name} className="mb-0 city-image" />
      </Link>
      <CardBody>
        <h3>
          <Link to={`/admin/cities/${city.id}`}>{city.name}</Link>
        </h3>
      </CardBody>
    </Card>
  );
};

ListItem.propTypes = {
  city: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
