import React from 'react';

import List from './List';
import FormModal from './FormModal';

const Pages = () => (
  <>
    <List />
    <FormModal />
  </>
);

Pages.propTypes = {};

Pages.defaultProps = {};

export default Pages;
