import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import { getLastModifierDisplayName } from '../../../../../../../../../utils/auditUtils';
import { formatTimestamp } from '../../../../../../../../../utils/dateUtils';

const LastModified = ({ quickPossession }) => {
  const { updatedAt } = quickPossession;
  const modifierName = getLastModifierDisplayName(quickPossession);

  if (v.isBlank(updatedAt) && v.isBlank(modifierName)) return null;

  return (
    <div className="text-nowrap">
      {formatTimestamp(updatedAt)}
      <div className="small">{modifierName}</div>
    </div>
  );
};

LastModified.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

LastModified.defaultProps = {};

export default LastModified;
