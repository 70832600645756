import React from 'react';
import v from 'voca';
import { Badge } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

const i18nOpts = { scope: 'components.admin.integrations.paymentMethods.creditCard.connectionBadge.index' };

const ConnectionBadge = ({ currentCompany }) => {
  const stripeSettings = currentCompany.paymentSettings?.stripe || {};
  if (v.isBlank(stripeSettings.secretKey) && v.isBlank(stripeSettings.publishableKey)) return null;

  return (
    <>
      {stripeSettings.isConnected ? (
        <Badge color="success" className="ml-4">
          {i18n.t('connected', i18nOpts)}
        </Badge>
      ) : (

        <Badge color="danger" className="ml-4">
          {i18n.t('notConnected', i18nOpts)}
        </Badge>
      )}
    </>
  );
};

ConnectionBadge.propTypes = {};

ConnectionBadge.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(ConnectionBadge);
