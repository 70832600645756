import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { updateCompanyContactSalesLeadAsync } from './utils';
import { setCustomerAttributes } from '../../../../../../../store/actions/customerActions';
import BaseSalesRepDropdown from '../../../../../../global/SalesRepDropdown';

const SalesRepDropdown = ({
  customer, currentCompany, ...props
}) => {
  const [selectedSalesRep, setSelectedSalesRep] = useState(customer.companyContact.salesLead);

  const onSelect = (newSalesRep) => {
    setSelectedSalesRep(newSalesRep);

    updateCompanyContactSalesLeadAsync(customer, newSalesRep)
      .then((companyContact) => {
        props.setCustomerAttributes(customer.id, { companyContact });
      })
      .catch((e) => {
        setSelectedSalesRep(customer.companyContact.salesLead);
        toast.error(e.message);
      });
  };

  return (
    <BaseSalesRepDropdown
      onChange={onSelect}
      selectedSalesRep={selectedSalesRep}
      size="sm"
      defaultLabelClassName="text-secondary"
    />
  );
};

SalesRepDropdown.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

SalesRepDropdown.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  setCustomerAttributes
})(SalesRepDropdown);
