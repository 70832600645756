import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import StageDropdown from './StageDropdown';
import SalesRepDropdown from './SalesRepDropdown';
import { isAdmin } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.customers.show.overview.salesSection.index' };

const SalesSection = ({ customer }) => {
  const { salesLead } = customer.companyContact;
  const showSalesRep = isAdmin() ? true : !!salesLead;

  return (
    <>
      <hr />

      <div className="d-flex flex-column gap-3">
        {showSalesRep && (
          <div>
            <div className="font-weight-500">
              {i18n.t('salesRep', i18nOpts)}
            </div>
            {isAdmin() ? (
              <div className="mt-1">
                <SalesRepDropdown customer={customer} />
              </div>
            ) : (
              <div className="col-gray-700 font-16 font-weight-500">
                {salesLead.name}
              </div>
            )}
          </div>
        )}

        <div>
          <div className="font-weight-500 mb-1">
            {i18n.t('stage', i18nOpts)}
          </div>
          <StageDropdown customer={customer} />
        </div>
      </div>
    </>
  );
};

SalesSection.propTypes = {};

SalesSection.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer
}))(SalesSection);
