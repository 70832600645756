import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { ModalNames } from '../../../constants';
import ModalService from '../../../services/ModalService';
import { downloadQRCode } from '../../../utils/qrCodeUtils';

const i18nOpts = { scope: 'components.global.QRCodeModal.index' };

function onClose() {
  ModalService.close(ModalNames.QR_CODE_MODAL);
}

function downloadQR(name) {
  downloadQRCode(name);
}

const QRCodeModal = ({ opened, params }) => {
  const url = params?.url;
  const name = params?.name;

  return (
    <Modal isOpen={opened} className="direct-link-modal">
      <ModalHeader>{i18n.t('title', i18nOpts)}</ModalHeader>
      <ModalBody>
        <div>{i18n.t('message', i18nOpts)}</div>
        <div className="d-flex justify-content-center" id="svg-container">
          <QRCodeCanvas value={url} id="qr-code" includeMargin size={200} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button outline color="primary" onClick={() => downloadQR(name)}>
          {i18n.t('buttons.downloadQr', i18nOpts)}
        </Button>
        <Button outline color="secondary" onClick={onClose}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  opened: store.modals[ModalNames.QR_CODE_MODAL]?.opened || false,
  params: store.modals[ModalNames.QR_CODE_MODAL]?.params || {}
}), {})(QRCodeModal);
