import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import ReportChart from './ReportChart';

const i18nOpts = { scope: 'components.admin.externalDataSources.builtOptionsReport.index' };

const BuiltOptionsReport = ({ builtOptionsReport }) => {
  if (!builtOptionsReport.length) return null;
  const firstRange = [];
  const secondRange = [];
  const thirdRange = [];
  const fourthRange = [];
  const fifthRange = [];

  builtOptionsReport.forEach(([key, count]) => {
    const parsed = parseInt(count, 10);

    if (parsed <= 7) firstRange.push([key, count]);
    else if (parsed > 7 && parsed <= 12) secondRange.push([key, count]);
    else if (parsed > 12 && parsed <= 20) thirdRange.push([key, count]);
    else if (parsed > 20 && parsed <= 50) fourthRange.push([key, count]);
    else fifthRange.push([key, count]);
  });

  console.log('1: ', firstRange.length);
  console.log('2: ', secondRange.length);
  console.log('3: ', thirdRange.length);
  console.log('4: ', fourthRange.length);
  console.log('5: ', fifthRange.length);
  return (
    <div className="my-4">
      <h2 className="text-center">{i18n.t('title', i18nOpts)}</h2>
      <ReportChart builtOptionsReport={firstRange} title={i18n.t('titles.firstRange', i18nOpts)} />
      <ReportChart builtOptionsReport={secondRange} title={i18n.t('titles.secondRange', i18nOpts)} />
      <ReportChart builtOptionsReport={thirdRange} title={i18n.t('titles.thirdRange', i18nOpts)} />
      <ReportChart builtOptionsReport={fourthRange} title={i18n.t('titles.fourthRange', i18nOpts)} />
      <ReportChart builtOptionsReport={fifthRange} title={i18n.t('titles.fifthRange', i18nOpts)} />
    </div>
  );
};

BuiltOptionsReport.propTypes = {
  builtOptionsReport: PropTypes.arrayOf(PropTypes.any)
};

BuiltOptionsReport.defaultProps = {
  builtOptionsReport: []
};

export default BuiltOptionsReport;
