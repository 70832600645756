import React from 'react';

const VerifiedIcon = ({ ...props }) => (
  <svg width="125" height="125" viewBox="0 0 48 48" fill="none" color="#667085" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.00005 43.6349C9.2052 44 10.833 44 13.6 44H34.4C37.167 44 38.7948 44 40 43.6349M8.00005 43.6349C7.74164 43.5566 7.50267 43.4615 7.27606 43.346C6.14708 42.7708 5.2292 41.8529 4.65396 40.7239C4 39.4405 4 37.7603 4 34.4V13.6C4 10.2397 4 8.55953 4.65396 7.27606C5.2292 6.14708 6.14708 5.2292 7.27606 4.65396C8.55953 4 10.2397 4 13.6 4H34.4C37.7603 4 39.4405 4 40.7239 4.65396C41.8529 5.2292 42.7708 6.14708 43.346 7.27606C44 8.55953 44 10.2397 44 13.6V34.4C44 37.7603 44 39.4405 43.346 40.7239C42.7708 41.8529 41.8529 42.7708 40.7239 43.346C40.4973 43.4615 40.2584 43.5566 40 43.6349M8.00005 43.6349C8.00071 42.0162 8.01041 41.1597 8.15372 40.4393C8.78497 37.2658 11.2658 34.785 14.4393 34.1537C15.2121 34 16.1414 34 18 34H30C31.8586 34 32.7879 34 33.5607 34.1537C36.7342 34.785 39.215 37.2658 39.8463 40.4393C39.9896 41.1597 39.9993 42.0162 40 43.6349M32 19C32 23.4183 28.4183 27 24 27C19.5817 27 16 23.4183 16 19C16 14.5817 19.5817 11 24 11C28.4183 11 32 14.5817 32 19Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <g clipPath="url(#clip0_1_13)">
      <path d="M37 43.6667C40.6819 43.6667 43.6667 40.6819 43.6667 37C43.6667 33.3181 40.6819 30.3333 37 30.3333C33.3181 30.3333 30.3333 33.3181 30.3333 37C30.3333 40.6819 33.3181 43.6667 37 43.6667Z" fill="currentColor" />
      <path d="M34 37L36 39L40 35M43.6667 37C43.6667 40.6819 40.6819 43.6667 37 43.6667C33.3181 43.6667 30.3333 40.6819 30.3333 37C30.3333 33.3181 33.3181 30.3333 37 30.3333C40.6819 30.3333 43.6667 33.3181 43.6667 37Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <path d="M34 37L36 39L40 35" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <clipPath id="clip0_1_13">
        <rect width="16" height="16" fill="white" transform="translate(29 29)" />
      </clipPath>
    </defs>
  </svg>
);

VerifiedIcon.propTypes = {};

VerifiedIcon.defaultProps = {};

export default VerifiedIcon;
