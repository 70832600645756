import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';

import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';
import NavTabs from './NavTabs';
import { Tabs } from './utils';
import Connection from './Connection';
import Settings from './Settings';
import UserGuides from './UserGuides';

const i18nOpts = { scope: 'components.admin.integrations.docuSign.index' };

const DocuSign = () => {
  const [activeTab, setActiveTab] = useState(Tabs.CONNECTION);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

      <NavTabs activeTab={activeTab} onChange={onTabChange} />

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.CONNECTION}>
          <Connection />
        </TabPane>
        <TabPane tabId={Tabs.SETTINGS}>
          <Settings />
        </TabPane>
        <TabPane tabId={Tabs.USER_GUIDES}>
          <UserGuides />
        </TabPane>
      </TabContent>
    </div>
  );
};

DocuSign.propTypes = {};

DocuSign.defaultPros = {};

export default DocuSign;
