import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18n-js';
import {
  Button, Card, FormGroup, Input, Label, Spinner
} from 'reactstrap';
import v from 'voca';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import InputError, { isInputInvalid } from '../../../../../../ui/InputError';
import { getError } from '../../../../../../../utils/requestUtils';
import { saveOffer } from '../../../../../../../store/actions/offerActions';
import { ModalNames } from '../../../../../../../constants';
import ModalService from '../../../../../../../services/ModalService';
import { hasFormChanges } from '../../../../../../../utils/formUtils';
import { setHomeAttributes } from '../../../../../../../store/actions/homeActions';
import { parseToFloat } from '../../../../../../../utils/parserUtils';
import OfferDocumentToSign from '../../../../OfferDocumentToSign';
import { stripToNull } from '../../../../../../../utils/stringUtils';
import { isOfferAccepted } from '../../../../../../../utils/offerUtils';
import { isDepositStepEnabled, isSignStepEnabled } from '../../../../../../../utils/companyUtils';
import useReserveNowWorkflowSteps from '../../../../../../../hooks/useReserveNowWorkflowSteps';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.offerSection.acceptedOfferCard.index' };

function onClose() {
  ModalService.close(ModalNames.DEAL);
}

const AcceptedOfferCard = ({ home, ...props }) => {
  const { offer } = home;

  const [initialForm, setInitialForm] = useState({});
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const reserveNowSteps = useReserveNowWorkflowSteps();
  const { enabledDepositStep } = isDepositStepEnabled(reserveNowSteps);
  const { enabledSignStep } = isSignStepEnabled(reserveNowSteps);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onDocumentToSignChange = (documentId, templateId) => {
    setForm((prevForm) => (
      { ...prevForm, documentToSignId: documentId, docuSignTemplateId: templateId }
    ));
  };

  const onSave = () => {
    setSaving(true);

    const input = {
      id: offer.id,
      requiredDepositAmount: parseToFloat(form.requiredDepositAmount),
      documentToSignId: parseInt(form.documentToSignId, 10) || null,
      docuSignTemplateId: stripToNull(form.docuSignTemplateId)
    };
    const variables = { input };

    props.saveOffer(variables)
      .then(({ value: { data: { item } } }) => {
        props.setHomeAttributes(home.id, { offer: item });
        onClose();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (!isOfferAccepted(offer)) return;

    const formData = {
      requiredDepositAmount: offer.requiredDepositAmount,
      documentToSignId: offer.documentToSign?.id || null,
      docuSignTemplateId: offer.docuSignTemplateId
    };
    setInitialForm(formData);
    setForm(formData);
  }, [offer?.status]);

  const hasChanges = useMemo(() => hasFormChanges(initialForm, form), [initialForm, form]);

  if (!isOfferAccepted(offer)) return null;
  if (!enabledSignStep && !enabledDepositStep) return null;

  return (
    <Card body className="p-3 mb-3">
      {enabledDepositStep && (
        <FormGroup>
          <Label for="requiredDepositAmount">{i18n.t('requiredDepositAmount', i18nOpts)}</Label>
          <div className="d-flex align-items-center">
            <b>$</b>
            <Input
              type="number"
              name="requiredDepositAmount"
              id="requiredDepositAmount"
              value={form.requiredDepositAmount || ''}
              onChange={onTextChange}
              invalid={isInputInvalid(error, 'requiredDepositAmount')}
              className="ml-2"
            />
          </div>
          <InputError name="requiredDepositAmount" error={error} />
        </FormGroup>
      )}

      {enabledSignStep && (
        <OfferDocumentToSign
          offer={offer}
          form={form}
          onDocumentToSignChange={onDocumentToSignChange}
        />
      )}

      <div className="text-right">
        <Button color="primary" className="ml-3" onClick={onSave} disabled={saving || !hasChanges}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
      </div>
    </Card>
  );
};

AcceptedOfferCard.propTypes = {};

AcceptedOfferCard.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}), {
  saveOffer,
  setHomeAttributes
})(AcceptedOfferCard);
