import React from 'react';

import Icon from '../../Icon';
import Vector from '../Vector';

const PRIMARY_COLOR = '#00AF8C';

class Rect extends Vector {
  static meta = {
    icon: <Icon icon="rectangle" size={30} />,
    initial: {
      width: 5,
      height: 5,
      fill: 'transparent',
      radius: 0,
      blendmode: 'normal',
      rotate: 0,
      stroke: PRIMARY_COLOR,
      strokeWidth: 2
    }
  };

  render() {
    const { object } = this.props;
    return (
      <rect
        style={this.getStyle()}
        {...this.getObjectAttributes()}
        rx={object.radius}
        width={object.width}
        height={object.height}
        fillOpacity="0.5"
      />
    );
  }
}

export default Rect;
