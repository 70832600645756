import v from 'voca';

export function getPayloadAddress(tour) {
  const array = [];

  const {
    address, city, stateProvince, postalCode
  } = tour;

  if (!v.isBlank(address)) array.push(address);
  if (!v.isBlank(city)) array.push(city);
  if (!v.isBlank(stateProvince)) array.push(stateProvince);
  if (!v.isBlank(postalCode)) array.push(postalCode);

  return array.join(', ');
}
