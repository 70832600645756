import React, { useState } from 'react';
import { Button, Spinner, Card } from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { MdFileDownload } from 'react-icons/md';
import v from 'voca';

import { saveOffer } from '../../../../../../../store/actions/offerActions';
import { setHomeAttributes, setReloadHomes } from '../../../../../../../store/actions/homeActions';
import { OfferSignStatuses } from '../../../../../../../constants';
import { getError } from '../../../../../../../utils/requestUtils';
import { confirmAlert } from '../../../../../confirmAlert';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.signSection.confirmSignCard.index' };

const ConfirmSignCard = ({ home, ...props }) => {
  const [saving, setSaving] = useState(false);
  const { offer } = home;
  const { signStatus, signedDocumentUrl } = offer;

  const onAccept = () => {
    setSaving(true);

    const input = {
      id: offer.id,
      signStatus: OfferSignStatuses.ACCEPTED
    };
    const variables = { input };

    props.saveOffer(variables)
      .then(({ value: { data: { item } } }) => {
        props.setHomeAttributes(home.id, { offer: item });
        props.setReloadHomes(true);
      })
      .catch((e) => {
        const formattedError = getError(e);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const onConfirmAccept = () => {
    const options = {
      title: null,
      message: `<div class="font-18">${i18n.t('confirmAlert.message', i18nOpts)}</div>`
    };
    const dialogProps = {
      buttonText: i18n.t('buttons.confirm', i18nOpts)
    };
    confirmAlert(onAccept, options, dialogProps);
  };

  if ([OfferSignStatuses.REJECTED,
    OfferSignStatuses.DECLINED].includes(signStatus)) return null;

  if (signStatus === OfferSignStatuses.ACCEPTED) {
    return (
      <Card body className="p-3 mb-3">
        <h3>{i18n.t('signConfirmed', i18nOpts)}</h3>

        <a href={signedDocumentUrl} target="_blank" rel="noreferrer">
          <MdFileDownload size="1.1rem" className="mr-1" />
          {i18n.t('downloadSignedDocument', i18nOpts)}
        </a>
      </Card>
    );
  }

  const hasSignedDocumentUrl = !v.isBlank(signedDocumentUrl);

  return (
    <Card body className="p-3 mb-3">
      <h3>{i18n.t('title', i18nOpts)}</h3>

      <p>{i18n.t('description', i18nOpts)}</p>

      {hasSignedDocumentUrl ? (
        <a href={signedDocumentUrl} target="_blank" rel="noreferrer">
          <MdFileDownload size="1.1rem" className="mr-1" />
          {i18n.t('downloadSignedDocument', i18nOpts)}
        </a>
      ) : (
        <p className="text-warning">{i18n.t('noSignedDocument', i18nOpts)}</p>
      )}

      <div className="text-right">
        <Button
          color="primary"
          className="ml-3"
          onClick={onConfirmAccept}
          disabled={saving || !hasSignedDocumentUrl}
        >
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.confirm', i18nOpts)}
        </Button>
      </div>
    </Card>
  );
};

ConfirmSignCard.propTypes = {};

ConfirmSignCard.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}), {
  saveOffer,
  setHomeAttributes,
  setReloadHomes
})(ConfirmSignCard);
