import React, { Component } from 'react';
import _ from 'lodash';
import { HotKeys } from 'react-hotkeys';

import InsertMenu from './panels/InsertMenu';
import SVGRenderer from './SVGRenderer';
import Handler from './Handler';
import { modes } from './constants';
import Scaler from './actions/Scaler';
import Dragger from './actions/Dragger';
import Path from './objects/Path';
import Rect from './objects/Rect';
import styles from './panels/styles';
import StylePanel from './panels/StylePanel';
import EditPanel from './panels/EditPanel';
import { uuidv4 } from '../../../utils/randomUtils';
import Tooltip from './Tooltip';
import Resizer from './Resizer';

class Designer extends Component {
  keyMap = {
    removeObject: ['del', 'backspace'],
    moveLeft: ['left', 'shift+left'],
    moveRight: ['right', 'shift+right'],
    moveUp: ['up', 'shift+up'],
    moveDown: ['down', 'shift+down'],
    closePath: ['enter']
  };

  constructor(props) {
    super(props);

    this.state = {
      mode: modes.FREE,
      handler: {
        top: 200,
        left: 200,
        width: 50,
        height: 50,
        rotate: 0
      },
      currentObjectIndex: null,
      selectedObjectIndex: null,
      selectedTool: null,
      objectRefs: {},
      showHandler: false,
      handlerWithUnit: false,
      editingObject: null,
      editingObjectIndex: null,
      orientation: null
    };

    this.hideHandler = this.hideHandler.bind(this);
    this.showEditor = this.showEditor.bind(this);
    this.startDrag = this.startDrag.bind(this);
    this.applyOffset = this.applyOffset.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.stopDrag = this.stopDrag.bind(this);
    this.selectTool = this.selectTool.bind(this);
    this.showHandler = this.showHandler.bind(this);
    this.handleArrange = this.handleArrange.bind(this);
    this.handleObjectChange = this.handleObjectChange.bind(this);
    this.updateObject = this.updateObject.bind(this);
    this.newObject = this.newObject.bind(this);
    this.removeCurrent = this.removeCurrent.bind(this);
    this.moveSelectedObject = this.moveSelectedObject.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onSaveObject = this.onSaveObject.bind(this);
    this.onEditObject = this.onEditObject.bind(this);
    this.getObjectBorders = this.getObjectBorders.bind(this);
  }

  getKeymapHandlers() {
    const handlers = {
      removeObject: this.removeCurrent,
      moveLeft: (event) => this.moveSelectedObject('x', -1, event, 'left'),
      moveRight: (event) => this.moveSelectedObject('x', 1, event, 'right'),
      moveUp: (event) => this.moveSelectedObject('y', -1, event, 'up'),
      moveDown: (event) => this.moveSelectedObject('y', 1, event, 'down'),
      closePath: () => this.setState({ mode: modes.FREE })
    };

    return _.mapValues(handlers, (handler) => (event, key) => {
      if (event.target.tagName !== 'INPUT') {
        event.preventDefault();
        handler(event, key);
      }
    });
  }

  getStartPointBundle(event, object) {
    const { currentObjectIndex } = this.state;
    const { objects } = this.props;
    const mouse = this.getMouseCoords(event);
    object = object || objects[currentObjectIndex];
    return {
      clientX: mouse.x,
      clientY: mouse.y,
      objectX: object.x,
      objectY: object.y,
      width: object.width,
      height: object.height,
      rotate: object.rotate
    };
  }

  snapCoordinates({ x, y }) {
    const { snapToGrid } = this.props;
    return {
      x: x - (x % snapToGrid),
      y: y - (y % snapToGrid)
    };
  }

  getMouseCoords(event) {
    const { clientX, clientY } = event;
    const coords = this.applyOffset({
      x: clientX,
      y: clientY
    });

    return this.snapCoordinates(coords);
  }

  onDrag(event) {
    const {
      currentObjectIndex, startPoint, mode, objectRefs, orientation
    } = this.state;
    const { objects } = this.props;
    const object = objects[currentObjectIndex];
    const mouse = this.getMouseCoords(event);

    const map = {
      [modes.SCALE]: Scaler,
      [modes.DRAG]: Dragger
    };

    const action = map[mode];

    if (action) {
      const actionProps = {
        object,
        startPoint,
        mouse,
        objectIndex: currentObjectIndex,
        objectRefs
      };

      if (orientation) actionProps.orientation = orientation;

      const newObject = action(actionProps);

      this.updateObject(currentObjectIndex, newObject);
      this.updateHandler(currentObjectIndex, newObject);
    }

    if (currentObjectIndex !== null) {
      this.detectOverlappedObjects(event);
    }
  }

  onCancelEdit() {
    const { editingObject, selectedObjectIndex } = this.state;
    if (editingObject) this.updateObject(selectedObjectIndex, editingObject);
    this.setState({ mode: modes.FREE, editingObject: null });
  }

  onSaveObject() {
    const { selectedObjectIndex } = this.state;
    const { props } = this;
    const { objects } = props;
    const currentObject = objects[selectedObjectIndex];

    if (currentObject && _.has(currentObject, 'unit')) {
      const { unit } = currentObject;
      const objectToSave = { ...currentObject };
      delete objectToSave.unit;
      props.onAssignObject(objectToSave, unit)
        .then(() => {
          this.setState({ editingObject: null, mode: modes.FREE });
        });
    }
  }

  detectOverlappedObjects(event) {
    const { currentObjectIndex, objectRefs } = this.state;
    const mouse = this.getMouseCoords(event);

    const refs = objectRefs;
    const keys = Object.keys(refs);
    const offset = this.getOffset();

    const currentRect = (refs[currentObjectIndex]
      .getBoundingClientRect());

    keys.filter(
      (object, index) => index !== currentObjectIndex
    ).forEach((key) => {
      const currentRef = refs[key];
      if (currentRef) {
        const rect = currentRef.getBoundingClientRect();
        let {
          left, top, width, height
        } = rect;

        left -= offset.x;
        top -= offset.y;

        const isOverlapped = (
          mouse.x > left && mouse.x < left + width
          && mouse.y > top && mouse.y < top + height
          && currentRect.width > width
          && currentRect.height > height
        );

        if (isOverlapped) {
          this.showHandler(Number(key));
        }
      }
    });
  }

  stopDrag() {
    const { mode } = this.state;

    if (_.includes([modes.DRAG,
      modes.SCALE], mode)) {
      this.setState({
        mode: modes.FREE
      }, () => this.onSaveObject());
    }
  }

  showEditor(editingObject = null) {
    const { selectedObjectIndex } = this.state;

    const { objects } = this.props;
    const currentObject = objects[selectedObjectIndex];
    const objectComponent = this.getObjectComponent(currentObject.type);

    if (objectComponent.meta.editor) {
      this.setState({
        mode: modes.EDIT_OBJECT,
        showHandler: false
      });
    }
    if (editingObject) this.setState({ editingObject });
  }

  getObjectComponent(type) {
    const { objectTypes } = this.props;
    return objectTypes[type];
  }

  showHandler(index) {
    const { mode } = this.state;
    const { objects } = this.props;
    const object = objects[index];

    if (mode !== modes.FREE) {
      return;
    }

    const currentObjectHasUnit = _.has(object, 'unit');
    this.updateHandler(index, object);
    this.setState({
      currentObjectIndex: index,
      showHandler: true,
      handlerWithUnit: currentObjectHasUnit
    });
  }

  hideHandler() {
    const { mode } = this.state;
    if (mode === modes.FREE) {
      this.setState({
        showHandler: false
      });
    }
  }

  getCanvas() {
    const { width, height } = this.props;
    const {
      canvasWidth = width,
      canvasHeight = height
    } = this.props;
    return {
      width,
      height,
      canvasWidth,
      canvasHeight,
      canvasOffsetX: (canvasWidth - width) / 2,
      canvasOffsetY: (canvasHeight - height) / 2
    };
  }

  applyOffset(bundle) {
    const offset = this.getOffset();
    return {
      ...bundle,
      x: bundle.x - offset.x,
      y: bundle.y - offset.y
    };
  }

  getOffset() {
    const parent = this.svgElement.getBoundingClientRect();
    const { canvasWidth, canvasHeight } = this.getCanvas();
    return {
      x: parent.left,
      y: parent.top,
      width: canvasWidth,
      height: canvasHeight
    };
  }

  updateHandler(index, object) {
    const handler = this.getObjectBorders(index, object);
    this.setState({
      handler
    });
  }

  getObjectBorders(index, object) {
    const { objectRefs, handler: stateHandler } = this.state;
    const target = objectRefs[index];
    const bbox = target.getBoundingClientRect();
    const { canvasOffsetX, canvasOffsetY } = this.getCanvas();

    let handler = {
      ...stateHandler,
      width: object.width || bbox.width,
      height: object.height || bbox.height,
      top: object.y + canvasOffsetY,
      left: object.x + canvasOffsetX,
      rotate: object.rotate
    };

    if (!object.width) {
      const offset = this.getOffset();
      handler = {
        ...handler,
        left: bbox.left - offset.x,
        top: bbox.top - offset.y
      };
    }

    return handler;
  }

  startDrag(event, mode, orientation = null) {
    const { currentObjectIndex, editingObjectIndex } = this.state;
    const newIndex = editingObjectIndex || currentObjectIndex;
    this.setState({
      mode,
      startPoint: this.getStartPointBundle(event),
      selectedObjectIndex: newIndex,
      orientation
    });
  }

  resetSelection() {
    this.setState({
      selectedObjectIndex: null
    });
  }

  newObject(event) {
    const { mode, selectedTool } = this.state;

    this.resetSelection(event);

    if (mode !== modes.DRAW) {
      return;
    }

    const { meta } = this.getObjectComponent(selectedTool);
    const mouse = this.getMouseCoords(event);

    const { objects, onUpdate } = this.props;
    const object = {
      ...meta.initial,
      type: selectedTool,
      x: mouse.x,
      y: mouse.y,
      uuid: uuidv4()
    };

    onUpdate([...objects, object]);

    this.setState({
      currentObjectIndex: objects.length,
      selectedObjectIndex: objects.length,
      startPoint: this.getStartPointBundle(event, object),
      mode: meta.editor ? modes.EDIT_OBJECT : modes.SCALE,
      selectedTool: null
    });
  }

  updatePath(object) {
    const { path } = object;
    const diffX = object.x - object.moveX;
    const diffY = object.y - object.moveY;

    const newPath = path.map(({
      x1, y1, x2, y2, x, y
    }) => ({
      x1: diffX + x1,
      y1: diffY + y1,
      x2: diffX + x2,
      y2: diffY + y2,
      x: diffX + x,
      y: diffY + y
    }));

    return {
      ...object,
      path: newPath,
      moveX: object.x,
      moveY: object.y
    };
  }

  updateObject(objectIndex, changes, updatePath) {
    const { objects, onUpdate } = this.props;
    onUpdate(objects.map((object, index) => {
      if (index === objectIndex) {
        const newObject = {
          ...object,
          ...changes
        };

        return updatePath
          ? this.updatePath(newObject)
          : newObject;
      }

      return object;
    }));
  }

  renderSVG() {
    const canvas = this.getCanvas();
    const { width, height } = canvas;
    const { background, objects, objectTypes } = this.props;

    const { objectRefs } = this.state;
    return (
      <SVGRenderer
        background={background}
        width={width}
        canvas={canvas}
        height={height}
        objects={objects}
        onMouseOver={this.showHandler}
        objectTypes={objectTypes}
        objectRefs={objectRefs}
        onRender={(ref) => this.svgElement = ref}
        onMouseDown={this.newObject}
      />
    );
  }

  selectTool(tool) {
    this.setState({
      selectedTool: tool,
      mode: modes.DRAW,
      currentObjectIndex: null,
      showHandler: false,
      handler: null
    });
  }

  handleObjectChange(key, value) {
    const { selectedObjectIndex } = this.state;

    this.updateObject(selectedObjectIndex, {
      [key]: value
    });
  }

  handleArrange(arrange) {
    const { selectedObjectIndex } = this.state;
    const { objects } = this.props;
    const { props } = this;
    const object = objects[selectedObjectIndex];

    const arrangers = {
      front: (rest, o) => ([[...rest, o], rest.length]),
      back: (rest, o) => ([[o, ...rest], 0])
    };

    const rest = objects.filter(
      (o, index) => selectedObjectIndex !== index
    );

    this.setState({
      selectedObjectIndex: null
    }, () => {
      const arranger = arrangers[arrange];
      const [arranged, newIndex] = arranger(rest, object);
      props.onUpdate(arranged);
      this.setState({
        selectedObjectIndex: newIndex
      });
    });
  }

  removeCurrent() {
    const { selectedObjectIndex } = this.state;
    const { objects } = this.props;
    const { props } = this;
    const currentObject = objects[selectedObjectIndex];

    const rest = objects.filter(
      (object, index) => selectedObjectIndex !== index
    );

    props.onRemoveObject(currentObject)
      .then(() => this.setState({
        currentObjectIndex: null,
        selectedObjectIndex: null,
        showHandler: false,
        handler: null,
        objectRefs: {}
      }, () => {
        props.onUpdate(rest);
      }));
  }

  moveSelectedObject(attr, points, event, key) {
    const { selectedObjectIndex } = this.state;
    const { objects } = this.props;
    const object = objects[selectedObjectIndex];

    if (key.startsWith('shift')) {
      points *= 10;
    }

    const changes = {
      ...object,
      [attr]: object[attr] + points
    };

    this.updateObject(selectedObjectIndex, changes);
    this.updateHandler(selectedObjectIndex, changes);
  }

  onEditObject(object, editingObjectIndex) {
    this.setState({
      mode: modes.EDIT_OBJECT,
      showHandler: false,
      editingObject: object,
      editingObjectIndex
    });
  }

  render() {
    const {
      showHandler, handler, mode, currentObjectIndex,
      selectedObjectIndex, selectedTool, handlerWithUnit, editingObjectIndex
    } = this.state;

    const {
      objects,
      objectTypes,
      insertMenu: InsertMenuComponent,
      style,
      ExtraPanel,
      onAssignObject
    } = this.props;

    const currentObject = objects[selectedObjectIndex];
    const currentHoverObject = objects[currentObjectIndex];
    const isEditMode = mode === modes.EDIT_OBJECT;
    const showPropertyPanel = selectedObjectIndex !== null;

    const { width, height } = this.getCanvas();

    const DesignerExtraPanel = (props) => (
      <ExtraPanel {...props} />
    );

    let objectComponent; let objectWithInitial; let
      ObjectEditor;
    if (currentObject) {
      objectComponent = this.getObjectComponent(currentObject.type);
      objectWithInitial = {
        ...objectComponent.meta.initial,
        ...currentObject
      };
      ObjectEditor = objectComponent.meta.editor;
    }

    return (
      <HotKeys
        keyMap={this.keyMap}
        style={ownStyles.keyboardManager}
        handlers={this.getKeymapHandlers()}
      >
        <div
          className="container"
          style={{
            ...ownStyles.container,
            ...style,
            padding: 0
          }}
          onMouseMove={this.onDrag}
          onMouseUp={this.stopDrag}
        >

          {/* Left Panel: Displays insertion tools (shapes, images, etc.) */}
          {InsertMenuComponent && (
            <InsertMenuComponent
              tools={objectTypes}
              currentTool={selectedTool}
              onSelect={this.selectTool}
            />
          )}

          {/* Center Panel: Displays the preview */}
          <div style={ownStyles.canvasContainer}>
            {isEditMode && ObjectEditor && (
            <ObjectEditor
              object={objectWithInitial}
              offset={this.getOffset()}
              onUpdate={(object) => this.updateObject(selectedObjectIndex, object)}
              onClose={this.onCancelEdit}
              width={width}
              height={height}
            />
            )}

            {isEditMode && !ObjectEditor && (
              <Resizer
                boundingBox={this.getObjectBorders(editingObjectIndex, currentObject)}
                onMouseDown={(e, orientation) => this.startDrag(e, modes.SCALE, orientation)}
              />
            )}

            {showHandler && (
              <>
                <Handler
                  boundingBox={handler}
                  onMouseLeave={this.hideHandler}
                  onDrag={(e) => this.startDrag(e, modes.DRAG)}
                  hasUnit={handlerWithUnit}
                />
                <Tooltip
                  object={currentHoverObject}
                  positionX={handler.top}
                  positionY={handler.left}
                  onMouseLeave={this.hideHandler}
                />
              </>
            )}

            {this.renderSVG()}

          </div>

          {/* Right Panel: Displays text, styling and sizing tools */}
          {showPropertyPanel && (
            <div style={{ ...styles.propertyPanel }}>
              <StylePanel
                object={objectWithInitial}
                onArrange={this.handleArrange}
                onChange={this.handleObjectChange}
                objectComponent={objectComponent}
              />
              { ExtraPanel && (
                <DesignerExtraPanel
                  object={objectWithInitial}
                  onArrange={this.handleArrange}
                  onChange={this.handleObjectChange}
                  objectComponent={objectComponent}
                  onAssign={onAssignObject}
                />
              )}
              <EditPanel
                object={currentObject}
                onEdit={() => this.onEditObject(currentObject, selectedObjectIndex)}
                canEdit
                onDelete={this.removeCurrent}
                onCancel={this.onCancelEdit}
                onSave={this.onSaveObject}
                isEditing={isEditMode && !!ObjectEditor}
              />
            </div>
          )}
        </div>
      </HotKeys>
    );
  }
}

export const ownStyles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row'
  },
  canvasContainer: {
    position: 'relative'
  },
  keyboardManager: {
    outline: 'none'
  }
};

Designer.defaultProps = {
  objectTypes: {
    polygon: Path,
    rectangle: Rect
  },
  snapToGrid: 1,
  svgStyle: {},
  width: 800,
  height: 700,
  insertMenu: InsertMenu
};

export default Designer;
