import { useState } from 'react';
import { useSelector } from 'react-redux';

import api from '../../../../../api';
import { sendVerifiedInvitationMutation } from '../../../../../graphql';

async function submitVerifiedInvitationAsync(companyId, invitedCustomers) {
  const ids = invitedCustomers.map((ic) => ic.id);
  const inviteMethods = invitedCustomers.map((ic) => ic.inviteMethod);

  const variables = {
    companyId,
    ids,
    inviteMethods
  };
  return api.graphql(sendVerifiedInvitationMutation, variables)
    .then(({ data: { sent } }) => sent);
}

const useVerifiedInvitation = () => {
  const [inviting, setInviting] = useState(false);

  const { currentCompany, invitedCustomers } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany,
    invitedCustomers: store.salesInvitation.invitedCustomers
  }));

  const sendVerifiedInvitationAsync = () => {
    setInviting(true);

    return submitVerifiedInvitationAsync(currentCompany.id, invitedCustomers)
      .finally(() => setInviting(false));
  };

  return { inviting, sendVerifiedInvitationAsync };
};

export default useVerifiedInvitation;
