import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import classNames from 'classnames';

import { Tabs } from '../utils';

const i18nOpts = { scope: 'components.admin.integrations.docuSign.navTabs.index' };

const NavTabs = ({ activeTab, onChange, currentCompany }) => {
  const { isDocuSignConnected } = currentCompany;

  return (
    <Nav tabs>
      <NavItem>
        <NavLink
          className={classNames({ active: activeTab === Tabs.CONNECTION })}
          onClick={() => onChange(Tabs.CONNECTION)}
        >
          {i18n.t('connection', i18nOpts)}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classNames({ active: activeTab === Tabs.SETTINGS })}
          onClick={() => onChange(Tabs.SETTINGS)}
          disabled={!isDocuSignConnected}
        >
          {i18n.t('settings', i18nOpts)}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classNames({ active: activeTab === Tabs.USER_GUIDES })}
          onClick={() => onChange(Tabs.USER_GUIDES)}
        >
          {i18n.t('userGuides', i18nOpts)}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(NavTabs);
