import React, { useState } from 'react';
import {
  Button, Spinner, Card
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { OfferStatuses } from '../../../../../../../constants';
import { saveOffer } from '../../../../../../../store/actions/offerActions';
import { setReloadHomes, setHomeAttributes } from '../../../../../../../store/actions/homeActions';
import { confirmAlert } from '../../../../../confirmAlert';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.offerSection.declineOfferCard.index' };

const DeclineOfferCard = ({ home, ...props }) => {
  const [declining, setDeclining] = useState(false);
  const { offer } = home;

  const onDecline = () => {
    setDeclining(true);

    const input = {
      id: offer.id,
      status: OfferStatuses.DECLINED
    };
    const variables = { input };

    props.saveOffer(variables)
      .then(({ value: { data: { item } } }) => {
        props.setHomeAttributes(home.id, { offer: item });
        props.setReloadHomes(true);
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setDeclining(false));
  };

  const onConfirmAccept = () => {
    const options = {
      title: null,
      message: `<div class="font-18">${i18n.t('confirmAlert.message', i18nOpts)}</div>`
    };
    const dialogProps = {
      buttonText: i18n.t('buttons.decline', i18nOpts)
    };
    confirmAlert(onDecline, options, dialogProps);
  };

  if ([OfferStatuses.ACCEPTED, OfferStatuses.COUNTERED, OfferStatuses.REJECTED,
    OfferStatuses.DECLINED].includes(offer.status)) return null;

  return (
    <Card body className="p-3 mb-3">
      <h3>{i18n.t('title', i18nOpts)}</h3>

      <p>
        {i18n.t('description', i18nOpts)}
      </p>

      <div className="text-right">
        <Button
          color="danger"
          className="ml-3"
          onClick={onConfirmAccept}
          disabled={declining}
        >
          {declining && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.decline', i18nOpts)}
        </Button>
      </div>
    </Card>
  );
};

DeclineOfferCard.propTypes = {};

DeclineOfferCard.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}), {
  saveOffer,
  setHomeAttributes,
  setReloadHomes
})(DeclineOfferCard);
