import i18n from 'i18n-js';

import { Tabs } from '../../utils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.siteAndUX.index' };

export function getSettings() {
  const settings = [
    {
      id: 'logoRedirectUrl',
      label: i18n.t('defaultHomePage', i18nOpts),
      containerId: 'logoRedirectUrlContainer'
    },
    {
      id: 'termsUrl',
      label: i18n.t('termsAndConditionsUrl', i18nOpts),
      containerId: 'termsUrlContainer'
    },
    {
      id: 'enableCommunication',
      label: i18n.t('enableCommunicationToggle', i18nOpts),
      containerId: 'enableCommunicationContainer'
    },
    {
      id: 'useStartingFromPrice',
      label: i18n.t('startingFromForModel', i18nOpts),
      containerId: 'useStartingFromPriceContainer'
    },
    {
      id: 'enableHomesFromPriceForCommunities',
      label: i18n.t('startingFromForCommunity', i18nOpts),
      containerId: 'enableHomesFromPriceForCommunitiesContainer'
    },
    {
      id: 'overrideQuickPossessionSize',
      label: i18n.t('overrideQPSizeWithModelSize', i18nOpts),
      containerId: 'overrideQuickPossessionSizeContainer'
    },
    {
      id: 'startPageMessage',
      label: i18n.t('introPageMessage', i18nOpts),
      containerId: 'startPageMessageContainer'
    },
    {
      id: 'completePageTitle',
      label: i18n.t('completionTitle', i18nOpts),
      containerId: 'completePageTitleContainer'
    },
    {
      id: 'completePageMessage',
      label: i18n.t('completionMessage', i18nOpts),
      containerId: 'completePageMessageContainer'
    },
    {
      id: 'saveButtonText',
      label: i18n.t('saveButtonText', i18nOpts),
      containerId: 'saveButtonTextContainer'
    },
    {
      id: 'modelText',
      label: i18n.t('modelText', i18nOpts),
      containerId: 'modelTextContainer'
    },
    {
      id: 'verificationText',
      label: i18n.t('verificationText', i18nOpts),
      containerId: 'verificationTextContainer'
    },
    {
      id: 'buyButtonText',
      label: i18n.t('reserveNowButtonText', i18nOpts),
      containerId: 'buyButtonTextContainer'
    },
    {
      id: 'completeQuickPossessionTitle',
      label: i18n.t('completionTitleForQP', i18nOpts),
      containerId: 'completeQuickPossessionTitleContainer'
    },
    {
      id: 'completeQuickPossessionMessage',
      label: i18n.t('completionMessageForQP', i18nOpts),
      containerId: 'completeQuickPossessionMessageContainer'
    },
    {
      id: 'reserveQuickPossessionButtonText',
      label: i18n.t('reserveNowQPButtonText', i18nOpts),
      containerId: 'reserveQuickPossessionButtonTextContainer'
    },
    {
      id: 'saveQuickPossessionButtonText',
      label: i18n.t('textForSaveQPButton', i18nOpts),
      containerId: 'saveQuickPossessionButtonTextContainer'
    },
    {
      id: 'quickPossessionText',
      label: i18n.t('quickPossessionText', i18nOpts),
      containerId: 'quickPossessionTextContainer'
    },
    {
      id: 'singleFamilyPriceNote',
      label: i18n.t('singleFamilyPriceNote', i18nOpts),
      containerId: 'singleFamilyPriceNoteContainer'
    },
    {
      id: 'multiFamilyPriceNote',
      label: i18n.t('multiFamilyPriceNote', i18nOpts),
      containerId: 'multiFamilyPriceNoteContainer'
    },
    {
      id: 'lotPremiumLabel',
      label: i18n.t('lotPremiumLabel', i18nOpts),
      containerId: 'lotPremiumLabelContainer'
    }
  ];

  if (isMasterAdmin() || isTecSupport()) {
    settings.push(...[
      {
        id: 'enableExitPageReminder',
        label: i18n.t('enableExitPageReminder', i18nOpts),
        containerId: 'enableExitPageReminderContainer'
      },
      {
        id: 'enableCaptureLeadsFromBeginning',
        label: i18n.t('enableInitialLeadCapture', i18nOpts),
        containerId: 'enableCaptureLeadsFromBeginningContainer'
      },
      {
        id: 'enableNewHomeNavigation',
        label: i18n.t('enableNewHomeBuilds', i18nOpts),
        containerId: 'enableNewHomeNavigationContainer'
      },
      {
        id: 'enableNewClientUI',
        label: i18n.t('enableLegacyUI', i18nOpts),
        containerId: 'enableNewClientUIContainer'
      }
    ]);
  }

  return settings.map((setting) => ({
    ...setting,
    tabId: Tabs.SITE_AND_UX
  }));
}
