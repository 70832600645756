import api from '../../../../../../../api';

import { listUniqueQuickPossessionsTotalBedsQuery } from '../../../../../../../graphql/quickPossessions';

export function fetchQuickPossessionsBedsAsync(companyId) {
  const variables = { companyId };

  return api.graphql(listUniqueQuickPossessionsTotalBedsQuery, variables)
    .then(({ data: { result = [] } }) => result);
}
