import React, { Component } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Button
} from 'reactstrap';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { HiShare } from 'react-icons/all';

import Overview from './Overview';
import { getCommunity } from '../../../../store/actions/communityActions';
import { ModalNames, NavigationMainNames, Statuses } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import DirectLinkModal from '../../../global/DirectLinkModal';
import ProductTypes from './ProductTypes';
import { hasCompanyDomain, isDocuSignConnected, isPreApprovalEnabled } from '../../../../utils/companyUtils';
import Models from './Models';
import { isAdmin } from '../../../../utils/authUtils';
import Phases from './Phases';
import Images from './Images';
import api from '../../../../api';
import { cloneCommunityQuery } from '../../../../graphql';
import SalesRepresentatives from './SalesRepresentatives';
import QRCodeModal from '../../../global/QRCodeModal';
import Modifications from '../../../global/Modifications';
import NavigationBreadcrumbs from '../../../global/NavigationBreadcrumbs';
import NavigationService from '../../../../services/NavigationService';
import confirmClone from '../../../common/ConfirmClone';
import Loading from '../../../ui/Loading';
import MortgageProviders from './MortgageProviders';
import ReservationDocument from './ReservationDocument';

const i18nOpts = { scope: 'components.admin.communities.show.index' };

const Tabs = Object.freeze({
  OVERVIEW: 'overview',
  PRODUCT_TYPES: 'productTypes',
  MODELS: 'models',
  PHASES: 'phases',
  IMAGES: 'images',
  SALES_REPRESENTATIVES: 'salesRepresentatives',
  MODIFICATIONS: 'modifications',
  MORTGAGE_PROVIDERS: 'mortgageProviders',
  RESERVATION_DOCUMENT: 'reservationDocument'
});

function cloneCommunity(id) {
  const variables = { id };
  return api.graphql(cloneCommunityQuery, variables)
    .then(({ data: { item } }) => Promise.resolve(item));
}

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      activeTab: Tabs.OVERVIEW,
      cloning: false
    };

    this.onChangeTab = this.onChangeTab.bind(this);
    this.onOpenDirectLinkModal = this.onOpenDirectLinkModal.bind(this);
    this.onOpenPreviewLinkModal = this.onOpenPreviewLinkModal.bind(this);
    this.clone = this.clone.bind(this);
  }

  componentDidMount() {
    const { id } = this.props;
    const sanitizedId = parseInt(id, 10);
    this.setState({ id: sanitizedId }, this.loadCommunity);
  }

  onChangeTab(tab) {
    this.setState({ activeTab: tab });
  }

  onOpenDirectLinkModal() {
    const { community } = this.props;
    const { hashId } = community;

    const params = {
      path: `/community/${hashId}`,
      label: community.name
    };
    ModalService.open(ModalNames.DIRECT_LINK_MODAL, params);
  }

  onOpenPreviewLinkModal() {
    const { community } = this.props;
    const { hashId } = community;

    const params = {
      path: `preview/community/${hashId}`,
      label: community.name,
      isPreview: true
    };
    ModalService.open(ModalNames.DIRECT_LINK_MODAL, params);
  }

  loadCommunity() {
    const { props } = this;
    const { id } = this.state;

    if (!id) return;

    props.getCommunity(id)
      .then(() => {
        const { community } = this.props;
        NavigationService.addItem(community, NavigationMainNames.COMMUNITIES);
      })
      .catch(() => navigate('/admin/communities'));
  }

  clone() {
    const { id } = this.state;
    this.setState({ cloning: true });

    cloneCommunity(id)
      .then((item) => {
        navigate(`/admin/communities/${item.id}`);
        this.setState({ id: item.id }, this.loadCommunity);
      }).finally(() => {
        this.setState({ cloning: false });
      });
  }

  render() {
    const { activeTab, cloning } = this.state;
    const { community, currentCompany, loading } = this.props;

    if (loading || !community) return <Loading loading fullScreen />;

    return (
      <div>
        <Helmet title={community.name} />

        <NavigationBreadcrumbs />

        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h2>{community.name}</h2>
          </div>
          <div>
            <Button
              color="secondary"
              onClick={
                () => confirmClone(
                  i18n.t('clone.title', { ...i18nOpts, communityName: community.name }),
                  i18n.t('clone.message', i18nOpts),
                  this.clone
                )
              }
              className="mr-3"
              disabled={cloning}
            >
              {cloning && (<Spinner size="sm" className="mr-2" />)}
              <span>{i18n.t('buttons.clone', i18nOpts)}</span>
            </Button>
            {community.status === Statuses.DRAFT && hasCompanyDomain(currentCompany) && (
              <Button color="secondary" className="mr-3" onClick={this.onOpenPreviewLinkModal}>
                {i18n.t('buttons.preview', i18nOpts)}
              </Button>
            )}
            {community.status === Statuses.PUBLISHED && hasCompanyDomain(currentCompany) && (
              <Button color="secondary" className="mr-3" onClick={this.onOpenDirectLinkModal}>
                <HiShare size="1rem" className="col-light mr-2" />
                <span>{i18n.t('buttons.shareLink')}</span>
              </Button>
            )}

            {isAdmin() && (
              <Link to={`/admin/communities/form?id=${community.id}`} className="btn btn-primary">
                <i className="far fa-edit mr-2" />
                {i18n.t('buttons.edit')}
              </Link>
            )}
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.OVERVIEW })}
              onClick={() => this.onChangeTab(Tabs.OVERVIEW)}
            >
              {i18n.t('tabs.overview', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.PRODUCT_TYPES })}
              onClick={() => this.onChangeTab(Tabs.PRODUCT_TYPES)}
            >
              {i18n.t('tabs.productTypes', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.MODELS })}
              onClick={() => this.onChangeTab(Tabs.MODELS)}
            >
              {i18n.t('tabs.models', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.PHASES })}
              onClick={() => this.onChangeTab(Tabs.PHASES)}
            >
              {i18n.t('tabs.phases', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.IMAGES })}
              onClick={() => this.onChangeTab(Tabs.IMAGES)}
            >
              {i18n.t('tabs.images', i18nOpts)}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.SALES_REPRESENTATIVES })}
              onClick={() => this.onChangeTab(Tabs.SALES_REPRESENTATIVES)}
            >
              {i18n.t('tabs.salesRepresentatives', i18nOpts)}
            </NavLink>
          </NavItem>
          {isPreApprovalEnabled() && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === Tabs.MORTGAGE_PROVIDERS })}
                onClick={() => this.onChangeTab(Tabs.MORTGAGE_PROVIDERS)}
              >
                {i18n.t('tabs.mortgageProviders', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
          {isDocuSignConnected() && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === Tabs.RESERVATION_DOCUMENT })}
                onClick={() => this.onChangeTab(Tabs.RESERVATION_DOCUMENT)}
              >
                {i18n.t('tabs.reservationDocument', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
          {isAdmin && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === Tabs.MODIFICATIONS })}
                onClick={() => this.onChangeTab(Tabs.MODIFICATIONS)}
              >
                {i18n.t('tabs.modifications', i18nOpts)}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab} className="py-4">
          <TabPane tabId={Tabs.OVERVIEW}>
            <Overview />
          </TabPane>
          <TabPane tabId={Tabs.PRODUCT_TYPES}>
            <ProductTypes />
          </TabPane>
          <TabPane tabId={Tabs.MODELS}>
            <Models />
          </TabPane>
          <TabPane tabId={Tabs.PHASES}>
            <Phases />
          </TabPane>
          <TabPane tabId={Tabs.IMAGES}>
            <Images />
          </TabPane>
          <TabPane tabId={Tabs.SALES_REPRESENTATIVES}>
            <SalesRepresentatives />
          </TabPane>
          {isPreApprovalEnabled() && (
            <TabPane tabId={Tabs.MORTGAGE_PROVIDERS}>
              <MortgageProviders />
            </TabPane>
          )}
          {isDocuSignConnected() && (
            <TabPane tabId={Tabs.RESERVATION_DOCUMENT}>
              <ReservationDocument />
            </TabPane>
          )}
          {isAdmin() && (
            <TabPane tabId={Tabs.MODIFICATIONS}>
              <Modifications instance={community} />
            </TabPane>
          )}
        </TabContent>

        <DirectLinkModal />
        <QRCodeModal />
      </div>
    );
  }
}

export default connect((store) => ({
  community: store.communities.community,
  currentCompany: store.companies.currentCompany,
  loading: store.communities.getCommunity.loading
}), {
  getCommunity
})(Show);
