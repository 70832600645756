import React, { useEffect } from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { ModalNames } from '../../../../../../constants';
import { setUnitFilters } from '../../../../../../store/actions/unitActions';
import ModalCloseButton from '../../../../../common/ModalCloseButton';
import ModalService from '../../../../../../services/ModalService';
import useFilters from '../../../../../../hooks/useFilters';
import ProjectFilter from './ProjectFilter';
import BuildingModelFilter from './BuildingModelFilter';
import BedroomFilter from './BedroomFilter';
import BathroomFilter from './BathroomFilter';
import AvailabilityFilter from './AvailabilityFilter';

const i18nOpts = { scope: 'components.admin.units.list.components.filtersModal.index' };

function closeModal() {
  ModalService.close(ModalNames.UNIT_FILTERS);
}

const FiltersModal = ({ opened, selectedFilters, ...props }) => {
  const {
    filters, changeFilters, setFilters, setInitialFilters, clearFilters, cancelFilters
  } = useFilters({});

  const onReset = () => {
    props.setUnitFilters({});
    clearFilters();
    closeModal();
  };

  const onCancel = () => {
    cancelFilters();
    closeModal();
  };

  const onApply = () => {
    props.setUnitFilters(filters);
    closeModal();
  };

  useEffect(() => {
    if (!opened) return;

    setFilters(selectedFilters);
    setInitialFilters(selectedFilters);
  }, [opened]);

  return (
    <Modal isOpen={opened} scrollable modalClassName="right">
      <ModalHeader close={<ModalCloseButton onClick={closeModal} />}>
        {i18n.t('title', i18nOpts)}
      </ModalHeader>
      <ModalBody className="pt-0">
        <div className="mb-3">{i18n.t('description', i18nOpts)}</div>

        <ProjectFilter filters={filters} onChange={changeFilters} />
        <BuildingModelFilter filters={filters} onChange={changeFilters} />
        <BedroomFilter filters={filters} onChange={changeFilters} />
        <BathroomFilter filters={filters} onChange={changeFilters} />
        <AvailabilityFilter filters={filters} onChange={changeFilters} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline className="mr-auto" onClick={onReset}>
          {i18n.t('buttons.reset')}
        </Button>

        <Button color="secondary" outline className="mr-3" onClick={onCancel}>
          {i18n.t('buttons.cancel')}
        </Button>
        <Button color="primary" onClick={onApply}>
          {i18n.t('buttons.apply')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FiltersModal.propTypes = {};

FiltersModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.UNIT_FILTERS]?.opened || false,
  selectedFilters: store.units.filters
}), {
  setUnitFilters
})(FiltersModal);
