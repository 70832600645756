import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Button, Spinner } from 'reactstrap';
import { FaSync } from 'react-icons/fa';
import v from 'voca';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../../common/Breadcrumbs';
import {
  getExternalDataSource, syncExternalDataSource, getBuiltOptionsReport
} from '../../../../../store/actions/externalDataSourceActions';
import SalesSimplicityDetails from '../SalesSimplicity/Details';
import MarondaHomesDetails from '../MarondaHomes/Details';
import { EXTERNAL_DATA_SOURCE_STATUSES, ExternalDataSourceProviders, WebSocketMessages } from '../../../../../constants';
import { getError } from '../../../../../utils/requestUtils';
import SyncLogs from '../SyncLogs';
import BuiltOptionsReport from '../BuiltOptionsReport';
import { checkDataProvider } from '../utils';
import { SocketContext } from '../../../../../providers/Socket';
import Loading from '../../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.externalDataSources.show.index' };

const MappedExternalDataSourcesStatusClasses = {
  [EXTERNAL_DATA_SOURCE_STATUSES.successful]: 'text-success',
  [EXTERNAL_DATA_SOURCE_STATUSES.failed]: 'text-danger',
  [EXTERNAL_DATA_SOURCE_STATUSES.processing]: 'text-warning',
  [EXTERNAL_DATA_SOURCE_STATUSES.waiting]: ''
};

function getStatusClass(status) {
  return MappedExternalDataSourcesStatusClasses[status];
}

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      syncing: false,
      gettingReport: false
    };

    this.onStartSync = this.onStartSync.bind(this);
    this.addSocketEventListener = this.addSocketEventListener.bind(this);
  }

  componentDidMount() {
    this.loadExternalDataSource();
    this.addSocketEventListener();
  }

  componentWillUnmount() {
    const { context: socket } = this;
    socket.disconnect();
  }

  onStartSync(id) {
    const { props, context: socket } = this;

    socket.connect();

    props.syncExternalDataSource({ id })
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => this.setState({ syncing: true }));
  }

  onGetBuiltOptionsReport(id) {
    const { props } = this;
    this.setState({ gettingReport: true });
    props.getBuiltOptionsReport({ id })
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
      });
  }

  endSync() {
    const { context: socket } = this;
    this.loadExternalDataSource();
    this.setState({ syncing: false });

    socket.disconnect();
  }

  loadExternalDataSource() {
    const { props } = this;
    const { dataProvider } = props;

    checkDataProvider(dataProvider);

    const { currentCompany } = this.props;

    props.getExternalDataSource({ companyId: currentCompany.id, dataProvider })
      .then(() => {
        const newState = {};

        if (this.isSyncing()) newState.syncing = true;
        if (this.isGettingReport()) newState.gettingReport = true;

        this.setState({ ...newState });
      })
      .catch(() => {});
  }

  addSocketEventListener() {
    const { context: socket } = this;
    socket.on(WebSocketMessages.EXTERNAL_DATA_SOURCE_SYNC, (data) => {
      const { version } = data;
      const { externalDataSource } = this.props;
      if (version !== externalDataSource?.id) return;

      this.endSync();
    });
  }

  isSalesSimplicity() {
    const { externalDataSource } = this.props;
    return externalDataSource?.dataProvider === ExternalDataSourceProviders.SALES_SIMPLICITY;
  }

  isMarondaHomes() {
    const { externalDataSource } = this.props;
    return externalDataSource?.dataProvider === ExternalDataSourceProviders.MARONDA_HOMES;
  }

  isSyncing() {
    const { externalDataSource } = this.props;
    return externalDataSource?.status === EXTERNAL_DATA_SOURCE_STATUSES.processing;
  }

  isGettingReport() {
    const { externalDataSource } = this.props;
    return externalDataSource?.builtOptionsReport?.reportStatus
      === EXTERNAL_DATA_SOURCE_STATUSES.processing;
  }

  render() {
    const { syncing, gettingReport } = this.state;
    const { dataProvider, externalDataSource, loading } = this.props;
    const builtReport = externalDataSource?.builtOptionsReport?.entries || [];

    const dataProviderTitle = i18n.t(`dataProviders.${dataProvider}`, i18nOpts);

    let dataProviderDetails;
    if (this.isSalesSimplicity()) {
      dataProviderDetails = <SalesSimplicityDetails />;
    } else if (this.isMarondaHomes()) {
      dataProviderDetails = <MarondaHomesDetails />;
    }

    return (
      <div>
        <Helmet title={dataProviderTitle} />

        <Breadcrumbs items={[
          { url: '/admin/integrations', text: i18n.t('integrations', i18nOpts) },
          { url: '/admin/integrations/externalDataSources', text: i18n.t('externalDataSources', i18nOpts) },
          { url: '/admin/integrations/externalDataSources', text: dataProviderTitle }
        ]}
        />

        <br />

        <Loading loading={loading} fullScreen />

        {externalDataSource ? (
          <div className="d-flex justify-content-between mb-4">
            <div className="font-18">{i18n.t('config', i18nOpts)}</div>
            <div>
              <Link to={`/admin/integrations/externalDataSources/${dataProvider}/form`} className="btn btn-primary ml-3">
                <i className="far fa-edit mr-2" />
                {i18n.t('buttons.edit', i18nOpts)}
              </Link>
            </div>
          </div>
        ) : (
          <Link to={`/admin/integrations/externalDataSources/${dataProvider}/form`} className="btn btn-primary">
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Link>
        )}

        {dataProviderDetails}

        {externalDataSource && (
          <div>
            <Button
              size="lg"
              className={`btn-outline-secondary ${getStatusClass(externalDataSource.status)}`}
              onClick={() => this.onStartSync(externalDataSource.id)}
              disabled={syncing}
            >
              { syncing ? (
                <Spinner size="sm" />
              ) : (
                <FaSync size="1.2rem" />
              ) }
              <span className="ml-3">
                {i18n.t(`buttons.${syncing ? 'syncing' : 'startSync'}`, i18nOpts)}
              </span>
            </Button>

            { !syncing && (
              <div className="my-5">
                <SyncLogs
                  syncLogs={externalDataSource.syncLogs}
                  lastSyncDate={externalDataSource.lastSyncDate}
                />
              </div>
            )}

            { this.isSalesSimplicity() && (
              <div className="mt-5">
                { builtReport.length > 0 && (
                  <BuiltOptionsReport builtOptionsReport={builtReport} />
                )}
                <Button
                  size="lg"
                  className="btn-outline-secondary"
                  onClick={() => this.onGetBuiltOptionsReport(externalDataSource.id)}
                  disabled={gettingReport}
                >
                  { gettingReport ? (
                    <Spinner size="sm" />
                  ) : (
                    <FaSync size="1.2rem" />
                  ) }
                  <span className="ml-3">
                    {i18n.t('buttons.getBuiltOptionsReport', i18nOpts)}
                  </span>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Show.contextType = SocketContext;

export default connect((store) => ({
  externalDataSource: store.externalDataSources.externalDataSource,
  loading: store.externalDataSources.getExternalDataSource.loading,
  currentCompany: store.companies.currentCompany
}), {
  getExternalDataSource,
  syncExternalDataSource,
  getBuiltOptionsReport
})(Show);
