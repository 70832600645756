const fragment = `
  id
  community {
    id
    name
  }
  project {
    id
    name
  }
  mortgageProvider {
    id
    name
    url
    imageUrl
  }
`;

export const listMortgageProviderAssignmentsQuery = `
  query ListMortgageProviderAssignments(
    $filter: MortgageProviderAssignmentFilterInput
  ) {
    items: listMortgageProviderAssignments(filter: $filter) {
      ${fragment}
    }
  }
`;

export const getMortgageProviderAssignmentQuery = `
  query GetMortgageProviderAssignment($id: Int!) {
    item: getMortgageProviderAssignment(id: $id) {
      ${fragment}
    }
  }
`;

export const createMortgageProviderAssignmentQuery = `
  mutation CreateMortgageProviderAssignment(
    $input: CreateMortgageProviderAssignmentInput!
  ) {
    item: createMortgageProviderAssignment(input: $input) {
      ${fragment}
    }
  }
`;

export const updateMortgageProviderAssignmentQuery = `
  mutation UpdateMortgageProviderAssignment(
    $input: UpdateMortgageProviderAssignmentInput!
  ) {
    item: updateMortgageProviderAssignment(input: $input) {
      ${fragment}
    }
  }
`;

export const deleteMortgageProviderAssignmentQuery = `
  mutation DeleteMortgageProviderAssignment(
    $id: Int!
  ) {
    item: deleteMortgageProviderAssignment(id: $id) {
      ${fragment}
    }
  }
`;
