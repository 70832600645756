import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import Switch from 'rc-switch';
import i18n from 'i18n-js';

import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { parseBoolean } from '../../../../../../utils/parserUtils';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';
import { CKEditor } from '../../../../../ui';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.siteAndUX.index' };

const SiteAndUX = ({ form, error, onTextChange }) => {
  const settings = form.settings || {};
  const messages = settings.messages || [];
  const design = settings.design || {};

  const onChangeSwitch = (name, checked) => {
    onTextChange({ target: { name, value: checked } });
  };

  const onChangeMessage = (event) => {
    const { name, value } = event.target;
    const newMessages = messages.some((msg) => msg.section === name)
      ? messages.map((msg) => (msg.section === name
        ? { ...msg, text: value }
        : msg))
      : [...messages, { section: name, text: value }];

    const newSettings = { ...settings, messages: newMessages };
    onTextChange({ target: { name: 'settings', value: newSettings } });
  };

  const onChangeDesignSetting = (event) => {
    const { name, value } = event.target;
    const newSettings = {
      ...settings,
      design: { ...design, [name]: value }
    };
    onTextChange({ target: { name: 'settings', value: newSettings } });
  };

  const getMessageText = (name) => {
    const message = messages.find((msg) => msg.section === name);
    return message ? message.text : '';
  };

  const isMasterAdminRole = isMasterAdmin();
  const isTecSupportRole = isTecSupport();
  const canViewMasterSettings = isMasterAdminRole || isTecSupportRole;

  return (
    <>
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="logoRedirectUrlContainer">
            <Label sm={5} className="py-0">
              {i18n.t('defaultHomePage', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.defaultHomePage', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="logoRedirectUrl"
                id="logoRedirectUrl"
                value={form.logoRedirectUrl || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'logoRedirectUrl')}
              />
              <InputError name="logoRedirectUrl" error={error} />
            </Col>
          </FormGroup>

          <FormGroup row id="termsUrlContainer">
            <Label sm={5}>{i18n.t('termsAndConditionsUrl', i18nOpts)}</Label>
            <Col sm={7}>
              <Input
                type="text"
                name="termsUrl"
                id="termsUrl"
                value={form.termsUrl || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'termsUrl')}
              />
              <InputError name="termsUrl" error={error} />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="enableCommunicationContainer">
            <Label sm={5} className="py-0">
              {i18n.t('enableCommunicationToggle', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.enableCommunicationToggle', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Switch
                checked={parseBoolean(form.enableCommunication)}
                onChange={(checked) => onChangeSwitch('enableCommunication', checked)}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="useStartingFromPriceContainer">
            <Label sm={5} className="py-0">
              {i18n.t('startingFromForModel', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.startingFromForModel', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Switch
                checked={parseBoolean(form.useStartingFromPrice)}
                onChange={(checked) => onChangeSwitch('useStartingFromPrice', checked)}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="enableHomesFromPriceForCommunitiesContainer">
            <Label sm={5} className="py-0">
              {i18n.t('startingFromForCommunity', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.startingFromForCommunity', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Switch
                checked={parseBoolean(form.enableHomesFromPriceForCommunities)}
                onChange={(checked) => onChangeSwitch('enableHomesFromPriceForCommunities', checked)}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="overrideQuickPossessionSizeContainer">
            <Label sm={5} className="py-0">
              {i18n.t('overrideQPSizeWithModelSize', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.overrideQPSizeWithModelSize', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Switch
                checked={parseBoolean(form.overrideQuickPossessionSize)}
                onChange={(checked) => onChangeSwitch('overrideQuickPossessionSize', checked)}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      {canViewMasterSettings && (
        <>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enableExitPageReminderContainer">
                <Label sm={5} className="py-0">
                  {i18n.t('enableExitPageReminder', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.enableExitPageReminder', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableExitPageReminder)}
                    onChange={(checked) => onChangeSwitch('enableExitPageReminder', checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enableCaptureLeadsFromBeginningContainer">
                <Label sm={5} className="py-0">
                  {i18n.t('enableInitialLeadCapture', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.enableInitialLeadCapture', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableCaptureLeadsFromBeginning)}
                    onChange={(checked) => onChangeSwitch('enableCaptureLeadsFromBeginning', checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enableNewHomeNavigationContainer">
                <Label sm={5} className="py-0">
                  {i18n.t('enableNewHomeBuilds', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.enableNewHomeBuilds', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Switch
                    checked={parseBoolean(form.enableNewHomeNavigation)}
                    onChange={(checked) => onChangeSwitch('enableNewHomeNavigation', checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row id="enableNewClientUIContainer">
                <Label sm={5} className="py-0">
                  {i18n.t('enableLegacyUI', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.enableLegacyUI', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  <Switch
                    checked={!parseBoolean(form.enableNewClientUI)}
                    onChange={(checked) => onChangeSwitch('enableNewClientUI', !checked)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="startPageMessageContainer">
            <Label sm={5} className="py-0">
              {i18n.t('introPageMessage', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.introPageMessage', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="textarea"
                rows={5}
                name="startPageMessage"
                id="startPageMessage"
                value={getMessageText('startPageMessage')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="completePageTitleContainer">
            <Label sm={5} className="py-0">
              {i18n.t('completionTitle', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.completionTitle', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="completePageTitle"
                id="completePageTitle"
                value={getMessageText('completePageTitle')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="completePageMessageContainer">
            <Label sm={5} className="py-0">
              {i18n.t('completionMessage', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.completionMessage', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="textarea"
                rows={5}
                name="completePageMessage"
                id="completePageMessage"
                value={getMessageText('completePageMessage')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="saveButtonTextContainer">
            <Label sm={5} className="py-0">
              {i18n.t('saveButtonText', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.saveButtonText', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="saveButtonText"
                id="saveButtonText"
                value={getMessageText('saveButtonText')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="modelTextContainer">
            <Label sm={5} className="py-0">
              {i18n.t('modelText', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.modelText', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="modelText"
                id="modelText"
                value={getMessageText('modelText')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="verificationTextContainer">
            <Label sm={5} className="py-0">
              {i18n.t('verificationText', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.verificationText', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <CKEditor
                name="verificationText"
                data={getMessageText('verificationText')}
                onTextChange={onChangeMessage}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="buyButtonTextContainer">
            <Label sm={5} className="py-0">
              {i18n.t('reserveNowButtonText', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.reserveNowButtonText', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="buyButtonText"
                id="buyButtonText"
                value={getMessageText('buyButtonText')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="completeQuickPossessionTitleContainer">
            <Label sm={5} className="py-0">
              {i18n.t('completionTitleForQP', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.completionTitleForQP', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="completeQuickPossessionTitle"
                id="completeQuickPossessionTitle"
                value={getMessageText('completeQuickPossessionTitle')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="completeQuickPossessionMessageContainer">
            <Label sm={5} className="py-0">
              {i18n.t('completionMessageForQP', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.completionMessageForQP', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="textarea"
                rows={5}
                name="completeQuickPossessionMessage"
                id="completeQuickPossessionMessage"
                value={getMessageText('completeQuickPossessionMessage')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="reserveQuickPossessionButtonTextContainer">
            <Label sm={5} className="py-0">
              {i18n.t('reserveNowQPButtonText', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.reserveNowQPButtonText', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="reserveQuickPossessionButtonText"
                id="reserveQuickPossessionButtonText"
                value={getMessageText('reserveQuickPossessionButtonText')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="saveQuickPossessionButtonTextContainer">
            <Label sm={5} className="py-0">
              {i18n.t('textForSaveQPButton', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.textForSaveQPButton', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="saveQuickPossessionButtonText"
                id="saveQuickPossessionButtonText"
                value={getMessageText('saveQuickPossessionButtonText')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="quickPossessionTextContainer">
            <Label sm={5} className="py-0">
              {i18n.t('quickPossessionText', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.quickPossessionText', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="quickPossessionText"
                id="quickPossessionText"
                value={getMessageText('quickPossessionText')}
                onChange={onChangeMessage}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row id="singleFamilyPriceNoteContainer">
            <Label sm={5} className="py-0">
              {i18n.t('singleFamilyPriceNote', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.singleFamilyPriceNote', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="singleFamilyPriceNote"
                id="singleFamilyPriceNote"
                value={design.singleFamilyPriceNote || ''}
                onChange={onChangeDesignSetting}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="multiFamilyPriceNoteContainer">
            <Label sm={5} className="py-0">
              {i18n.t('multiFamilyPriceNote', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.multiFamilyPriceNote', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="multiFamilyPriceNote"
                id="multiFamilyPriceNote"
                value={design.multiFamilyPriceNote || ''}
                onChange={onChangeDesignSetting}
              />
            </Col>
          </FormGroup>

          <FormGroup row id="lotPremiumLabelContainer">
            <Label sm={5} className="py-0">
              {i18n.t('lotPremiumLabel', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.lotPremiumLabel', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="lotPremiumLabel"
                id="lotPremiumLabel"
                value={design.lotPremiumLabel || ''}
                onChange={onChangeDesignSetting}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

SiteAndUX.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

SiteAndUX.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default SiteAndUX;
