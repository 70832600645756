import {
  GET_BUILDING_MODELS,
  SAVE_BUILDING_MODEL,
  DELETE_BUILDING_MODEL,
  GET_BUILDING_MODEL,
  SET_BUILDING_MODELS_VIEW_MODE
} from './types';
import {
  createBuildingModelQuery,
  deleteBuildingModelQuery,
  getBuildingModelQuery,
  listBuildingModelsQuery,
  updateBuildingModelQuery
} from '../../graphql';
import api from '../../api';

export function getBuildingModels(variables = {}) {
  return {
    type: GET_BUILDING_MODELS,
    payload: api.graphql(listBuildingModelsQuery, variables)
  };
}

export function getBuildingModel(variables = {}) {
  return {
    type: GET_BUILDING_MODEL,
    payload: api.graphql(getBuildingModelQuery, variables)
  };
}

export function saveBuildingModel(variables = {}) {
  const query = variables.input.id ? updateBuildingModelQuery : createBuildingModelQuery;
  return {
    type: SAVE_BUILDING_MODEL,
    payload: api.graphql(query, variables)
  };
}

export function deleteBuildingModel(id) {
  return {
    type: DELETE_BUILDING_MODEL,
    payload: api.graphql(deleteBuildingModelQuery, { id })
  };
}

export function setBuildingModelsViewMode(viewMode) {
  return {
    type: SET_BUILDING_MODELS_VIEW_MODE,
    payload: { viewMode }
  };
}
