import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './JourneyVerticalProgressStep.module.scss';
import { Colors } from '../../../constants';
import ChevronDownIcon from './ChevronDownIcon';

const GRADIENT_COLORS = ['#9672ba', '#7f8db3', '#69A9AD', '#59BAA9', '#4DC9A5'];

const JourneyVerticalProgressStep = ({
  index, steps
}) => {
  const { isCompleted, title } = steps[index];
  const nextStep = steps[index + 1];
  const isNextStepCompleted = nextStep?.isCompleted;

  let startColor = Colors.GRAY_200;
  let endColor = Colors.GRAY_200;
  if (isCompleted) {
    startColor = GRADIENT_COLORS[index];
    endColor = GRADIENT_COLORS[index + 1];
  }

  const nextStepColor = useMemo(() => {
    if (!nextStep) return 'white';
    if (isNextStepCompleted) return GRADIENT_COLORS[index + 1];
    return Colors.GRAY_200;
  }, [nextStep, isNextStepCompleted]);

  const customStyles = {
    zIndex: steps.length - index,
    marginTop: index > 0 ? '24px' : '0',
    '--journey-next-step-color': nextStepColor,
    '--journey-gradient-start-color': startColor,
    '--journey-gradient-end-color': endColor
  };

  return (
    <div
      className={classNames(styles.container, { [styles.containerCompleted]: isCompleted })}
      style={customStyles}
    >
      <ChevronDownIcon className={styles.arrow} />
      {title}
    </div>
  );
};

JourneyVerticalProgressStep.propTypes = {
  index: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

JourneyVerticalProgressStep.defaultProps = {
  steps: []
};

export default JourneyVerticalProgressStep;
