import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import classNames from 'classnames';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.existingCustomer.listView.listItem.selectLink.index' };

const SelectLink = ({ onClick, selected }) => (
  <span
    onClick={onClick}
    role="button"
    aria-hidden
    className={classNames('font-weight-600', { 'col-gray-500': !selected, 'text-primary': selected })}
  >
    {selected ? i18n.t('selected', i18nOpts) : i18n.t('select', i18nOpts)}
  </span>
);

SelectLink.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

SelectLink.defaultProps = {
  onClick: () => {},
  selected: false
};

export default SelectLink;
