import React, { useState } from 'react';
import {
  Button, FormGroup, Input, Label, Spinner, Card
} from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import v from 'voca';

import InputError, { isInputInvalid } from '../../../../../../ui/InputError';
import { DepositStatuses } from '../../../../../../../constants';
import { getError } from '../../../../../../../utils/requestUtils';
import { setReloadHomes, setHomeAttributes } from '../../../../../../../store/actions/homeActions';
import { saveDeposit } from '../../../../../../../store/actions/depositActions';
import { stripToNull } from '../../../../../../../utils/stringUtils';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.depositSection.depositProblemCard.index' };

const DepositProblemCard = ({ home, ...props }) => {
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const { deposit } = home;

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onReject = () => {
    setSaving(true);

    const input = {
      id: deposit.id,
      note: stripToNull(form.note),
      status: DepositStatuses.REJECTED
    };
    const variables = { input };

    props.saveDeposit(variables)
      .then(({ value: { data: { item } } }) => {
        props.setHomeAttributes(home.id, { deposit: item });
        props.setReloadHomes(true);
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const isValidForm = !v.isBlank(form.note);

  if ([DepositStatuses.ACCEPTED].includes(deposit.status)) return null;

  if (deposit.status === DepositStatuses.REJECTED) {
    return (
      <Card body className="p-3 mb-3">
        <h3>{i18n.t('title', i18nOpts)}</h3>

        <div className="white-space-pre-wrap">{deposit.note}</div>
      </Card>
    );
  }

  return (
    <Card body className="p-3 mb-3">
      <h3>{i18n.t('title', i18nOpts)}</h3>

      <p>{i18n.t('description', i18nOpts)}</p>

      <FormGroup>
        <Label for="note">{i18n.t('note', i18nOpts)}</Label>
        <Input
          type="textarea"
          name="note"
          id="note"
          value={form.note || ''}
          onChange={onTextChange}
          maxLength="250"
          rows={4}
          invalid={isInputInvalid(error, 'note')}
        />
        <InputError name="note" error={error} />
      </FormGroup>

      <div className="text-right">
        <Button
          color="danger"
          className="ml-3"
          onClick={onReject}
          disabled={saving || !isValidForm}
        >
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.reject', i18nOpts)}
        </Button>
      </div>
    </Card>
  );
};

DepositProblemCard.propTypes = {};

DepositProblemCard.defaultProps = {};

export default connect((store) => ({
  home: store.homes.home
}), {
  saveDeposit,
  setHomeAttributes,
  setReloadHomes
})(DepositProblemCard);
