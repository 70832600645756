import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

import styles from './SelectionButton.module.scss';

const SelectionButton = ({
  title, Icon, onClick, disabled
}) => {
  const extraProps = {
    ...(disabled ? {} : {
      onClick,
      role: 'button',
      'aria-hidden': true
    })
  };

  return (
    <Card body className={styles.cardContainer} {...extraProps}>
      <div className={styles.title}>{title}</div>

      <div className="text-center m-auto">
        <Icon />
      </div>
    </Card>
  );
};

SelectionButton.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

SelectionButton.defaultProps = {
  onClick: () => {},
  disabled: false
};

export default SelectionButton;
