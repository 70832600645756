import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import ProgressListItem from '../components/ProgressListItem';
import useVerifiedWorkflowSteps from '../../../../../hooks/useVerifiedWorkflowSteps';
import { fetchCustomerAsync, getVerifiedStep } from './utils';
import { EMAIL_SMS_VERIFICATION } from '../../../../../constants';

const i18nOpts = { scope: 'components.global.customer.customerProgressPopover.verificationContent.index' };

const VerificationContent = ({
  customerId, currentCompany
}) => {
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const verifiedWorkflowSteps = useVerifiedWorkflowSteps();

  const verifiedSteps = useMemo(() => {
    if (!customer) return [];

    const allSteps = [{ type: EMAIL_SMS_VERIFICATION }, ...verifiedWorkflowSteps];
    return allSteps.map((verifiedWorkflowStep) => getVerifiedStep(verifiedWorkflowStep, customer));
  }, [verifiedWorkflowSteps, customer]);

  const loadCustomer = () => {
    setLoading(true);

    fetchCustomerAsync(customerId, currentCompany.id)
      .then((item) => setCustomer(item))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadCustomer();
  }, []);

  return (
    <div>
      <h4>{i18n.t('title', i18nOpts)}</h4>

      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <div className="d-flex flex-column gap-4 mt-4">
          {
            verifiedSteps.map((verifiedStep) => (
              <ProgressListItem
                key={`verified-step-${verifiedStep.type}`}
                title={verifiedStep.title}
                description={verifiedStep.description}
                isCompleted={verifiedStep.isCompleted}
                Icon={verifiedStep.Icon}
              />
            ))
          }
        </div>
      )}
    </div>
  );
};

VerificationContent.propTypes = {
  customerId: PropTypes.number.isRequired,
};

VerificationContent.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(VerificationContent);
