import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';

import { getRoleName } from '../../../../../utils/userUtils';

const i18nOpts = { scope: 'components.admin.myProfile.show.basicInfo.index' };

const InfoItem = ({ title, value }) => (
  <>
    { !v.isBlank(value) && (
      <div className="mb-3">
        <div className="label mb-2">{title}</div>
        <div>{value}</div>
      </div>
    )}
  </>
);

const BasicInfo = ({ currentUser }) => (
  <div>
    <InfoItem title={i18n.t('name', i18nOpts)} value={currentUser.name} />

    <InfoItem title={i18n.t('email', i18nOpts)} value={currentUser.email} />

    <InfoItem title={i18n.t('phone', i18nOpts)} value={currentUser.phone} />

    <InfoItem title={i18n.t('role', i18nOpts)} value={getRoleName(currentUser.role)} />

    <InfoItem title={i18n.t('company', i18nOpts)} value={currentUser.company.name} />
  </div>
);

export default connect((store) => ({
  currentUser: store.users.currentUser
}), {})(BasicInfo);
