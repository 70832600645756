import React from 'react';

const NewHomeIcon = ({ ...props }) => (
  <svg width="121" height="112" viewBox="0 0 121 112" fill="none" color="#667085" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_73_511)">
      <path d="M45.0359 66.7717C45.0374 64.9873 46.4897 63.5396 48.2798 63.5381L57.2722 63.5304L57.28 54.6088C57.2816 52.8246 58.734 51.3769 60.5239 51.3752C62.3139 51.3737 63.7638 52.819 63.7622 54.6032L63.7544 63.5247L72.7043 63.5169C74.4942 63.5154 75.944 64.9606 75.9425 66.7449C75.9409 68.5292 74.4886 69.9769 72.6987 69.9784L63.7488 69.9862L63.741 78.9501C63.7395 80.7344 62.287 82.1821 60.4971 82.1836C58.7071 82.1852 57.2573 80.74 57.2588 78.9557L57.2666 69.9918L48.2742 69.9997C46.4841 70.0012 45.0343 68.5559 45.0359 66.7717Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M63.994 1.01923C61.864 -0.339742 59.1357 -0.339742 57.0056 1.01923L2.98776 35.4808C-0.0275709 37.4044 -0.907566 41.4005 1.02224 44.4062C2.952 47.412 6.96088 48.2892 9.97616 46.3654L15.1248 43.0808V94.7692C15.1248 104.286 22.8638 112 32.4105 112H88.5891C98.1358 112 105.875 104.286 105.875 94.7692V43.0808L111.024 46.3654C114.039 48.2892 118.047 47.412 119.978 44.4062C121.907 41.4005 121.027 37.4044 118.012 35.4808L63.994 1.01923ZM84.2677 66.7693C84.2677 79.8542 73.6265 90.4616 60.4998 90.4616C47.3732 90.4616 36.732 79.8542 36.732 66.7693C36.732 53.6843 47.3732 43.077 60.4998 43.077C73.6265 43.077 84.2677 53.6843 84.2677 66.7693Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_73_511">
        <rect width="121" height="112" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

NewHomeIcon.propTypes = {};

NewHomeIcon.defaultProps = {};

export default NewHomeIcon;
