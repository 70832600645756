import {
  GET_BUILDING_FLOORS,
  GET_BUILDING_FLOOR,
  SAVE_BUILDING_FLOOR,
  DELETE_BUILDING_FLOOR,
  SET_BUILDING_FLOOR_UNITS,
  SORT_BUILDING_FLOORS,
  SET_RELOAD_BUILDING_FLOOR_UNITS
} from './types';
import {
  listBuildingFloorsQuery,
  createBuildingFloorQuery,
  updateBuildingFloorQuery,
  deleteBuildingFloorQuery,
  getBuildingFloorQuery,
  setBuildingFloorUnitsQuery,
  sortBuildingFloorsQuery
} from '../../graphql';

import api from '../../api';

export function getBuildingFloors(variables = {}) {
  return {
    type: GET_BUILDING_FLOORS,
    payload: api.graphql(listBuildingFloorsQuery, variables)
  };
}

export function getBuildingFloor(id) {
  return {
    type: GET_BUILDING_FLOOR,
    payload: api.graphql(getBuildingFloorQuery, { id })
  };
}

export function saveBuildingFloor(variables = {}) {
  const query = variables.input.id ? updateBuildingFloorQuery : createBuildingFloorQuery;
  return {
    type: SAVE_BUILDING_FLOOR,
    payload: api.graphql(query, variables),
  };
}

export function deleteBuildingFloor(id) {
  return {
    type: DELETE_BUILDING_FLOOR,
    payload: api.graphql(deleteBuildingFloorQuery, { id })
  };
}

export function setBuildingFloorUnits(variables = {}) {
  return {
    type: SET_BUILDING_FLOOR_UNITS,
    payload: api.graphql(setBuildingFloorUnitsQuery, variables)
  };
}

export function sortBuildingFloors(variables = {}) {
  return {
    type: SORT_BUILDING_FLOORS,
    payload: api.graphql(sortBuildingFloorsQuery, variables)
  };
}

export function setReloadBuildingFloorUnits(reload) {
  return {
    type: SET_RELOAD_BUILDING_FLOOR_UNITS,
    payload: { reload }
  };
}
