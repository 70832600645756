import React from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';

import { confirmDelete } from '../../../../confirmAlert';
import { FloorableTypes } from '../../../../../../constants';

const i18nOpts = { scope: 'components.global.floorsManager.floor.floorOption.actionsMenu.index' };

const ActionsMenu = ({
  floorableType, onEdit, onDelete, onDependencyEdit, onImagesPress, onPriceRulesClick
}) => (
  <UncontrolledDropdown>
    <DropdownToggle tag="a" className="px-2">
      <i className="fas fa-ellipsis-v text-primary" />
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem onClick={onEdit}>
        {i18n.t('buttons.edit')}
      </DropdownItem>
      <DropdownItem className="text-danger" onClick={() => confirmDelete(onDelete)}>
        {i18n.t('buttons.delete')}
      </DropdownItem>
      <DropdownItem onClick={onDependencyEdit}>
        {i18n.t('dependency', i18nOpts)}
      </DropdownItem>
      <DropdownItem onClick={onImagesPress}>
        {i18n.t('images', i18nOpts)}
      </DropdownItem>
      {floorableType === FloorableTypes.MODEL && (
        <DropdownItem onClick={onPriceRulesClick}>
          {i18n.t('priceRules', i18nOpts)}
        </DropdownItem>
      )}
    </DropdownMenu>
  </UncontrolledDropdown>
);

ActionsMenu.propTypes = {
  floorableType: PropTypes.oneOf(Object.values(FloorableTypes)).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDependencyEdit: PropTypes.func,
  onImagesPress: PropTypes.func,
  onPriceRulesClick: PropTypes.func,
};

ActionsMenu.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  onDependencyEdit: () => {},
  onImagesPress: () => {},
  onPriceRulesClick: () => {}
};

export default ActionsMenu;
