import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import './styles.scss';
import { setSubMenu } from '../../../../../store/actions/menuActions';
import { isMobile } from '../../../../../utils/windowUtils';
import { toggleSidebar } from '../../../utils';

const i18nOpts = { scope: 'layout.admin.sidebar.singleFamilySideBar.menus' };

const SidebarMenuItem = ({
  Icon, title, children, setSubMenu: setCurrentSubMenu, ...props
}) => {
  const isQuickPossessions = (title === i18n.t('quickPossessions', i18nOpts)) && isMobile();

  const onClick = (event) => {
    if (isMobile()) toggleSidebar();
    else {
      const subMenu = event.target.closest('.first-level');
      if (subMenu) setCurrentSubMenu(null);
    }
  };

  return (
    <li className={classNames('sidebar-item')}>
      <Link className="sidebar-link" activeClassName="active" partiallyActive onClick={onClick} {...props}>
        {Icon && (
          <i>{Icon}</i>
        )}
        <span className={classNames('hide-menu', { 'less-letter-spacing': isQuickPossessions })}>{title}</span>
        {children}
      </Link>
    </li>
  );
};

SidebarMenuItem.propTypes = {
  Icon: PropTypes.element,
};

SidebarMenuItem.defaultProps = {
  Icon: null
};

export default connect((store) => ({}), {
  setSubMenu
})(SidebarMenuItem);
