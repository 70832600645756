import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import i18n from 'i18n-js';

import SellerItem from './SellerItem';
import { isSalesRep, isSalesTeam } from '../../../../utils/salesRepUtils';

const i18nOpts = { scope: 'components.global.sellersManagement.sellersList.index' };

const SellersList = ({ sellerSpaces, onAfterDelete }) => {
  const salesRepSellerSpaces = useMemo(() => sellerSpaces.filter((ss) => isSalesRep(ss.seller)),
    [sellerSpaces]);
  const salesTeamSellerSpaces = useMemo(() => sellerSpaces.filter((ss) => isSalesTeam(ss.seller)),
    [sellerSpaces]);

  return (
    <div className="d-flex flex-column gap-3">
      {salesTeamSellerSpaces.length > 0 && (
        <div>
          <h5 className="mb-3">{i18n.t('salesTeams', i18nOpts)}</h5>

          <Row className="gutter-3">
            {
              salesTeamSellerSpaces.map((sellerSpace) => (
                <Col key={`seller-space-${sellerSpace.id}`} xs="12" sm="6" md="4" lg="3" className="mb-4">
                  <SellerItem sellerSpace={sellerSpace} onAfterDelete={onAfterDelete} />
                </Col>
              ))
            }
          </Row>
        </div>
      )}

      {salesRepSellerSpaces.length > 0 && (
        <div>
          <h5 className="mb-3">{i18n.t('salesReps', i18nOpts)}</h5>

          <Row className="gutter-3">
            {
              salesRepSellerSpaces.map((sellerSpace) => (
                <Col key={`seller-space-${sellerSpace.id}`} xs="12" sm="6" md="4" lg="3" className="mb-4">
                  <SellerItem sellerSpace={sellerSpace} onAfterDelete={onAfterDelete} />
                </Col>
              ))
            }
          </Row>
        </div>
      )}
    </div>
  );
};

SellersList.propTypes = {
  sellerSpaces: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onAfterDelete: PropTypes.func
};

SellersList.defaultProps = {
  onAfterDelete: () => {}
};

export default SellersList;
