import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';

import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../../utils/authUtils';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';

const i18nOpts = { scope: 'components.admin.productTypes.show.overview.index' };

const Overview = ({ productType }) => {
  const isAdmin = isCompanyAdmin() || isSalesLead() || isMasterAdmin();
  const creatorName = getCreatorDisplayName(productType);
  const lastModifierName = getLastModifierDisplayName(productType);

  return (
    <div>
      <div className="mb-4">
        <div className="label">{i18n.t('name', i18nOpts)}</div>
        <div><strong>{productType.name}</strong></div>
      </div>

      {!v.isBlank(creatorName) && isAdmin && (
        <div className="mb-4">
          <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
          <div><strong>{creatorName}</strong></div>
        </div>
      )}

      {!v.isBlank(lastModifierName) && isAdmin && (
        <div className="mb-4">
          <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
          <div><strong>{lastModifierName}</strong></div>
        </div>
      )}
    </div>
  );
};

Overview.propTypes = {};

Overview.defaultProps = {};

export default connect((store) => ({
  productType: store.productTypes.productType
}))(Overview);
