import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdOutlineFileDownload } from 'react-icons/md';
import i18n from 'i18n-js';
import { Spinner } from 'reactstrap';

import { downloadOnfidoFileAsync, getType } from './utils';
import { downloadOnfidoFile } from '../../../../../../../../../utils/onfidoUtils';

const OnfidoDocument = ({ document }) => {
  const [downloading, setDownloading] = useState(false);

  const { data: onfidoDocument, documentType, fileName } = document;

  const onDownload = () => {
    setDownloading(true);
    const type = getType(documentType);

    downloadOnfidoFileAsync(onfidoDocument.id, type, fileName)
      .then((onfidoFile) => {
        downloadOnfidoFile(onfidoFile.file, onfidoFile.fileName);
      })
      .catch(() => {})
      .finally(() => setDownloading(false));
  };

  if (downloading) { return (<Spinner size="sm" />); }

  return (
    <div onClick={onDownload} role="button" aria-hidden className="text-primary" title={i18n.t('buttons.download')}>
      <MdOutlineFileDownload size="18" />
    </div>
  );
};

OnfidoDocument.propTypes = {
  document: PropTypes.objectOf(PropTypes.any).isRequired
};

OnfidoDocument.defaultProps = {};

export default OnfidoDocument;
