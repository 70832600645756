import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  Button, Card, FormGroup, Input
} from 'reactstrap';
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io';
import v from 'voca';
import { connect } from 'react-redux';

import { isInputInvalid } from '../../../../ui/InputError';
import { FloorOptionAvailabilityRules, FloorOptionAvailabilityRuleTypes } from '../../../../../constants';
import {
  getFloorOptionAvailabilityRuleName,
  getFloorOptionAvailabilityRuleTypeName
} from '../../../../../utils/enumUtils';
import { MultiSelect } from '../../../../ui';
import { getAvailableModelCommunities, getAvailableModelCommunityPhases } from './utils';

const i18nOpts = { scope: 'components.global.floorsManager.floorOptionFormModal.availabilityRuleSettings.index' };

const AvailabilityRuleSettings = ({
  form, error, onTextChange, model
}) => {
  const [isRuleVisible, setIsRuleVisible] = useState(!v.isBlank(form.availabilityRule));

  const onSetRule = () => setIsRuleVisible(true);

  const onRemoveRule = () => {
    onTextChange({ target: { name: 'availabilityCommunities', value: [] } });
    onTextChange({ target: { name: 'availabilityCommunityPhases', value: [] } });
    setIsRuleVisible(false);
  };

  const onAvailabilityRuleTypeChange = (event) => {
    const { name, value } = event.target;
    onTextChange({ target: { name, value } });
    onTextChange({ target: { name: 'availabilityCommunities', value: [] } });
    onTextChange({ target: { name: 'availabilityCommunityPhases', value: [] } });
  };

  const onCommunityMultiSelectChange = (items) => {
    const ids = items.map((i) => i.id);
    const event = { target: { name: 'availabilityCommunities', value: ids } };
    onTextChange(event);
  };

  const onCommunityPhaseMultiSelectChange = (items) => {
    const ids = items.map((i) => i.id);
    const event = { target: { name: 'availabilityCommunityPhases', value: ids } };
    onTextChange(event);
  };

  const modelCommunities = getAvailableModelCommunities(model);
  const ids = form.availabilityCommunities || [];
  const selectedModelCommunities = modelCommunities.filter((mc) => ids.includes(mc.id));

  const communityPhases = getAvailableModelCommunityPhases(model);
  const communityPhaseIds = form.availabilityCommunityPhases || [];
  const selectedCommunityPhases = communityPhases.filter((cp) => communityPhaseIds.includes(cp.id));

  return (
    <div className="mb-4">
      <div>{i18n.t('title', i18nOpts)}</div>
      <Card body className="py-3 mt-1" color="light">
        {isRuleVisible ? (
          <>
            <div className="form-row align-items-center mb-2">
              <div className="col-auto">
                <Input
                  type="select"
                  name="availabilityRule"
                  id="availabilityRule"
                  value={form.availabilityRule || ''}
                  onChange={(event) => onTextChange(event)}
                  invalid={isInputInvalid(error, 'availabilityRule')}
                  className="d-inline-block"
                >
                  {
                    Object.values(FloorOptionAvailabilityRules).map((availability) => (
                      <option key={`availability-option-${availability}`} value={availability}>
                        {getFloorOptionAvailabilityRuleName(availability)}
                      </option>
                    ))
                  }
                </Input>
              </div>
              <div className="ml-2">
                {i18n.t('onlyFor', i18nOpts)}
              </div>
            </div>

            <div className="form-row mb-2">
              <div className="col-auto">
                <Input
                  type="select"
                  name="availabilityRuleType"
                  id="availabilityRuleType"
                  value={form.availabilityRuleType || ''}
                  onChange={onAvailabilityRuleTypeChange}
                >
                  {
                    Object.values(FloorOptionAvailabilityRuleTypes).map((availableRuleType) => (
                      <option key={`availability-rule-type-option-${availableRuleType}`} value={availableRuleType}>
                        {getFloorOptionAvailabilityRuleTypeName(availableRuleType)}
                      </option>
                    ))
                  }
                </Input>
              </div>
            </div>

            {form.availabilityRuleType === FloorOptionAvailabilityRuleTypes.COMMUNITY_PHASES ? (
              <FormGroup>
                <MultiSelect
                  id="availability-rule-type-multiselect"
                  options={communityPhases}
                  selectedValues={selectedCommunityPhases}
                  onSelect={onCommunityPhaseMultiSelectChange}
                  onRemove={onCommunityPhaseMultiSelectChange}
                  displayValue="name"
                  closeIcon="cancel"
                  emptyRecordMsg={i18n.t('empty', i18nOpts)}
                  placeholder={i18n.t('availabilityCommunityPhasesPlaceholder', i18nOpts)}
                  hidePlaceholder
                />
              </FormGroup>
            ) : (
              <FormGroup>
                <MultiSelect
                  id="availability-rule-multiselect"
                  options={modelCommunities}
                  selectedValues={selectedModelCommunities}
                  onSelect={onCommunityMultiSelectChange}
                  onRemove={onCommunityMultiSelectChange}
                  displayValue="name"
                  closeIcon="cancel"
                  emptyRecordMsg={i18n.t('empty', i18nOpts)}
                  placeholder={i18n.t('availabilityCommunitiesPlaceholder', i18nOpts)}
                  hidePlaceholder
                />
              </FormGroup>
            )}
          </>
        ) : (
          <div>
            <small dangerouslySetInnerHTML={{ __html: i18n.t('description', i18nOpts) }} />
          </div>
        )}

        {isRuleVisible ? (
          <div className="text-right">
            <Button color="link" outline size="sm" onClick={onRemoveRule}>
              <IoMdRemoveCircle size="14" className="mr-1 text-danger" />
              {i18n.t('buttons.removeRule', i18nOpts)}
            </Button>
          </div>
        ) : (
          <div className="text-right">
            <Button color="link" outline size="sm" onClick={onSetRule}>
              <IoMdAddCircle size="14" className="mr-1 text-primary" />
              {i18n.t('buttons.setRule', i18nOpts)}
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};

AvailabilityRuleSettings.propTypes = {
  form: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func,
  error: PropTypes.objectOf(PropTypes.any)
};

AvailabilityRuleSettings.defaultProps = {
  form: {},
  onTextChange: () => {},
  error: null
};

export default connect((store) => ({
  model: store.models.model
}), {})(AvailabilityRuleSettings);
