import React from 'react';
import PropTypes from 'prop-types';

import Verified from './Verified';
import ReserveNow from './ReserveNow';

const CustomerInvitationTypes = {
  VERIFIED: 'VERIFIED',
  RESERVE_NOW: 'RESERVE_NOW'
};

const CustomerInvited = ({ activity, innerKey }) => {
  const { customerInvitation: { invitationType } } = activity;

  switch (invitationType) {
    case CustomerInvitationTypes.VERIFIED:
      return <Verified innerKey={innerKey} activity={activity} />;
    case CustomerInvitationTypes.RESERVE_NOW:
      return <ReserveNow innerKey={innerKey} activity={activity} />;
    default:
      return null;
  }
};

CustomerInvited.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired,
};

CustomerInvited.defaultProps = {};

export default CustomerInvited;
