import React from 'react';

const ModelsIcon = ({ ...props }) => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 20V12.6C8 12.0399 8 11.7599 8.109 11.546C8.20487 11.3578 8.35785 11.2049 8.54601 11.109C8.75993 11 9.03995 11 9.6 11H12.4C12.9601 11 13.2401 11 13.454 11.109C13.6422 11.2049 13.7951 11.3578 13.891 11.546C14 11.7599 14 12.0399 14 12.6V20M1 8.5L10.04 1.72C10.3843 1.46181 10.5564 1.33271 10.7454 1.28294C10.9123 1.23902 11.0877 1.23902 11.2546 1.28295C11.4436 1.33271 11.6157 1.46181 11.96 1.72L21 8.5M3 7V16.8C3 17.9201 3 18.4802 3.21799 18.908C3.40974 19.2843 3.7157 19.5903 4.09202 19.782C4.51985 20 5.0799 20 6.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4802 19 17.9201 19 16.8V7L12.92 2.44C12.2315 1.92361 11.8872 1.66542 11.5091 1.56589C11.1754 1.47804 10.8246 1.47804 10.4909 1.56589C10.1128 1.66542 9.76852 1.92361 9.08 2.44L3 7Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

ModelsIcon.propTypes = {};

ModelsIcon.defaultProps = {};

export default ModelsIcon;
