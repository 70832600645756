import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import v from 'voca';

import styles from './ListItem.module.scss';
import { DEFAULT_IMAGE, ModalNames } from '../../../../../../constants';
import { isAdmin } from '../../../../../../utils/authUtils';
import ActionsMenu from '../../../../../global/ActionsMenu';
import ModalService from '../../../../../../services/ModalService';
import { deleteMortgageProvider } from '../../../../../../store/actions/mortgageProviderActions';

const ListItem = ({ mortgageProvider, ...props }) => {
  const [focused, setFocused] = useState(false);
  const { name, imageUrl, url } = mortgageProvider;

  const onToggleFocused = (newFocused) => {
    setFocused(newFocused);
  };

  const onEdit = () => {
    ModalService.open(ModalNames.MORTGAGE_PROVIDER_FORM, { mortgageProvider });
  };

  const onDelete = () => {
    props.deleteMortgageProvider(mortgageProvider.id)
      .catch(() => {});
  };

  return (
    <Card
      onMouseEnter={() => onToggleFocused(true)}
      onMouseLeave={() => onToggleFocused(false)}
    >
      <CardBody>
        <div className="d-flex align-items-center">
          <div className={classNames('mr-3', styles.imgContainer)}>
            <img src={imageUrl || DEFAULT_IMAGE} alt={name} />
          </div>
          <div className="flex-grow-1">
            <span className="col-gray-900 font-weight-600 font-16">{name}</span>
          </div>
        </div>

        <div className="d-flex mt-3">
          <div className="flex-grow-1 mw-100">
            {!v.isBlank(url) && (
              <a href={url} target="_blank" rel="noreferrer" className="col-gray-600">
                {url}
              </a>
            )}
          </div>
          <div>
            {isAdmin() && focused && (
              <ActionsMenu
                item={mortgageProvider}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

ListItem.propTypes = {
  mortgageProvider: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect(() => ({}), {
  deleteMortgageProvider
})(ListItem);
