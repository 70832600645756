import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import ListEmpty from '../../../../../common/ListEmpty';
import ObserversList from '../components/ObserversList';

const i18nOpts = { scope: 'components.admin.units.show.observers.savedHomes.index' };

const SavedHomes = ({ observers }) => (
  <div>
    <h4>{i18n.t('title', i18nOpts)}</h4>

    <ListEmpty items={observers} />

    {observers && observers.length > 0 && (
      <ObserversList observers={observers} />
    )}
  </div>
);

SavedHomes.propTypes = {
  observers: PropTypes.arrayOf(PropTypes.object).isRequired
};

SavedHomes.defaultProps = {};

export default SavedHomes;
