import { LIST_CLIENT_MESSAGES, SAVE_CLIENT_MESSAGE } from './types';
import { listMessagesQuery, updateClientMessageQuery } from '../../graphql';
import api from '../../api';

export function getMessages(variables = {}) {
  return {
    type: LIST_CLIENT_MESSAGES,
    payload: api.graphql(listMessagesQuery, variables)
  };
}

export function saveMessage(variables = {}) {
  return {
    type: SAVE_CLIENT_MESSAGE,
    payload: api.graphql(updateClientMessageQuery, variables)
  };
}
