import React, { useEffect, useMemo, useState } from 'react';
import {
  ModalHeader, Modal, ModalBody, Card, CardHeader, CardBody,
  Row, Col, ModalFooter, Button
} from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import i18n from 'i18n-js';
import { ReactSortable } from 'react-sortablejs';
import PropTypes from 'prop-types';

import './styles.scss';

import ModalService from '../../../services/ModalService';
import AvailableColumnItem from './AvailableColumnItem';
import SelectedColumnItem from './SelectedColumnItem';
import ModalCloseButton from '../../common/ModalCloseButton';
import { getProfile, saveUser } from '../../../store/actions/userActions';

const i18nOpts = { scope: 'components.global.columnsPickerModal.index' };

function closeModal(modalName) {
  ModalService.close(modalName);
}

const ColumnsPickerModal = ({
  opened, modalName, getColumnName, columnSettingsKey, selectedColumns, selectableColumns,
  currentUser, ...props
}) => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(selectedColumns);

  const onColumnToggle = (event) => {
    const { value } = event.target;
    const newColumns = _.xor(columns, [value]);
    setColumns(newColumns);
  };

  const setSortedColumns = (newColumns) => {
    const newSortedColumns = newColumns.map((c) => c.id);
    setColumns(newSortedColumns);
  };

  const onApply = () => {
    closeModal(modalName);
    const validSelectedColumns = columns
      .filter((column) => selectableColumns.includes(column));
    dispatch(props.setColumnsAction(validSelectedColumns));
    saveColumnSettings(validSelectedColumns);
  };

  const saveColumnSettings = (selCols) => {
    const input = { id: currentUser.id, columnSettings: { [columnSettingsKey]: selCols } };
    props.saveUser({ input })
      .then(() => props.getProfile())
      .catch(() => {});
  };

  useEffect(() => {
    setColumns(selectedColumns);
  }, [selectedColumns]);

  const sortedColumns = useMemo(
    () => columns.map((columnKey) => ({ id: columnKey, name: getColumnName(columnKey) })),
    [columns]
  );

  return (
    <Modal isOpen={opened} modalClassName="right" scrollable>
      <ModalHeader close={<ModalCloseButton onClick={() => closeModal(modalName)} />}>{i18n.t('title', i18nOpts)}</ModalHeader>
      <ModalBody className="pt-0">
        <div>{i18n.t('description', i18nOpts)}</div>

        <Row className="mt-4">
          <Col xs="12">
            <Card className="card-picker-columns">
              <CardHeader className="font-12 font-weight-500 py-2">
                {i18n.t('editColumnOrder', i18nOpts)}
              </CardHeader>
              <CardBody>
                <ReactSortable
                  className="card-picker-sortable-columns"
                  list={sortedColumns}
                  setList={setSortedColumns}
                >
                  {sortedColumns.map((column) => (
                    <SelectedColumnItem key={`selected-column-${column.id}`} column={column} />
                  ))}
                </ReactSortable>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" className="pb-3">
            <div className="font-weight-500 col-gray-700">{i18n.t('availableColumnsTitle', i18nOpts)}</div>
            <div>{i18n.t('availableColumnsDescription', i18nOpts)}</div>
          </Col>
          <Col xs="12">
            <Card className="card-picker-columns">
              <CardHeader className="font-12 font-weight-500 py-2">
                {i18n.t('selectColumns', i18nOpts)}
              </CardHeader>
              <CardBody>
                {
                  selectableColumns.map((column) => (
                    <AvailableColumnItem
                      key={`available-column-${column}`}
                      column={column}
                      getColumnName={getColumnName}
                      selectedColumns={columns}
                      onChange={onColumnToggle}
                    />
                  ))
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline className="mr-3" onClick={() => closeModal(modalName)}>
          {i18n.t('buttons.cancel')}
        </Button>
        <Button color="primary" onClick={onApply}>
          {i18n.t('buttons.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ColumnsPickerModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  getColumnName: PropTypes.func.isRequired,
  columnSettingsKey: PropTypes.string.isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  selectableColumns: PropTypes.arrayOf(PropTypes.string)
};

ColumnsPickerModal.defaultProps = {
  selectedColumns: [],
  selectableColumns: []
};

export default connect((store, ownProps) => ({
  opened: store.modals[ownProps.modalName]?.opened || false,
  currentUser: store.users.currentUser
}), {
  saveUser,
  getProfile
})(ColumnsPickerModal);
