import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createProjectPaletteQuery } from '../../../../../../graphql';
import api from '../../../../../../api';
import { reloadProjectPalettes } from '../../../../../../store/actions/projectPaletteActions';
import AddItemDropdown from '../../../../../common/AddItemDropdown';

const AddDropdown = ({ availablePalettes, project, ...props }) => {
  const [isDropdownOpen, onToggleDropdown] = useState(false);

  const addPalette = (paletteId) => {
    const input = {
      projectId: project.id,
      paletteId
    };
    api.graphql(createProjectPaletteQuery, { input })
      .then(() => props.reloadProjectPalettes(true));
  };

  return (
    <AddItemDropdown
      open={isDropdownOpen}
      items={availablePalettes}
      toggleDropdown={onToggleDropdown}
      onSelect={addPalette}
    />
  );
};

AddDropdown.propTypes = {
  availablePalettes: PropTypes.arrayOf(PropTypes.any)
};

AddDropdown.defaultProps = {
  availablePalettes: []
};

export default connect(() => ({}), {
  reloadProjectPalettes
})(AddDropdown);
