import React from 'react';
import i18n from 'i18n-js';
import { Button, Card } from 'reactstrap';

import CopyLink from '../../../../../common/CopyLink';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.buildHomeSetup.myHomes.index' };

const MY_HOMES_CODE = `<button class="ownly-my-homes">
  My Homes
</button>`;

const MyHomes = () => (
  <div>
    <h2>{i18n.t('myHomes', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('myHomesDesc', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>

    <Card body className="d-inline-block">
      <pre>
        &lt;button class=&quot;ownly-my-homes&quot;&gt;
        <br />
        &nbsp;&nbsp;My Homes
        <br />
        &lt;/button&gt;
      </pre>
      <CopyLink text={MY_HOMES_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>
  </div>
);

MyHomes.propTypes = {};

MyHomes.defaultProps = {};

export default MyHomes;
