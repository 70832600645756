import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import EmployerCard from './EmployerCard';

const Employers = ({ financialReport }) => {
  const { primaryEmployerIncome, secondaryEmployerIncome } = financialReport;

  return (
    <Row>
      <Col xs="12" sm="12" md="6">
        <EmployerCard
          name={primaryEmployerIncome.name}
          income={primaryEmployerIncome.income}
          trend={primaryEmployerIncome.trend}
          isPrimary
        />
      </Col>
      {secondaryEmployerIncome && (
        <Col xs="12" sm="12" md="6">
          <EmployerCard
            name={secondaryEmployerIncome.name}
            income={secondaryEmployerIncome.income}
            trend={secondaryEmployerIncome.trend}
          />
        </Col>
      )}
    </Row>
  );
};

Employers.propTypes = {};

Employers.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport
}))(Employers);
