import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Badge, CardHeader, CardTitle, Row, Col
} from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import v from 'voca';
import { BiGridVertical } from 'react-icons/bi';
import classNames from 'classnames';

import './styles.scss';
import { DEFAULT_IMAGE, ModalNames, PriceableTypes } from '../../../../../../constants';
import { deleteElevation } from '../../../../../../store/actions/elevationActions';
import ModalService from '../../../../../../services/ModalService';
import ActionsMenu from '../../../../../global/ActionsMenu';
import { isAdmin } from '../../../../../../utils/authUtils';
import AddPriceRuleButton from '../../../../../global/priceRules/AddPriceRuleButton';
import PriceRuleList from '../../../../../global/priceRules/PriceRuleList';

const i18nOpts = { scope: 'components.admin.models.show.elevations.listItem.index' };

class ListItem extends Component {
  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete(elevationId) {
    const { props } = this;

    props.deleteElevation(elevationId);
  }

  onEdit(elevation) {
    ModalService.open(ModalNames.ELEVATION_FORM, { elevation });
  }

  render() {
    const { elevation } = this.props;

    if (!elevation) return null;

    return (
      <Card className="elevation-container sortable-elevation-item">
        <CardHeader>
          <div className="d-flex">
            <div className="flex-grow-1">
              <CardTitle>
                {elevation.name}
                {elevation.isDefault && (
                  <Badge color="info" pill className="ml-3">{i18n.t('isDefault', i18nOpts)}</Badge>
                )}
              </CardTitle>
            </div>
            {isAdmin() && (
              <div className="d-flex">
                <BiGridVertical size="1.2rem" className="sortable-elevation-handler mr-3" title={i18n.t('buttons.sort')} />
                <ActionsMenu item={elevation} onEdit={this.onEdit} onDelete={this.onDelete} />
              </div>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs="12" sm="6" md="4" lg="3">
              <img
                src={elevation.imageUrl || DEFAULT_IMAGE}
                alt={elevation.name}
                className="elevation-image mb-4 mb-sm-0"
              />
            </Col>
            <Col xs="12" sm="6" md="8" lg="9">
              {!v.isBlank(elevation.description) && (
                <div className="mb-4">
                  <div className="label">{i18n.t('description', i18nOpts)}</div>
                  <div><strong className="white-space-pre-line">{elevation.description}</strong></div>
                </div>
              )}

              {isAdmin() && (
                <div className="text-right">
                  <AddPriceRuleButton
                    priceableId={elevation.id}
                    priceableType={PriceableTypes.ELEVATION}
                  />
                </div>
              )}

              <PriceRuleList
                priceableId={elevation.id}
                priceableType={PriceableTypes.ELEVATION}
                className={classNames({ 'mt-3': isAdmin() })}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

ListItem.propTypes = {
  elevation: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
}), {
  deleteElevation
})(ListItem);
