import React from 'react';
import PropTypes from 'prop-types';
import { TbTrash } from 'react-icons/tb';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import { setInvitedCustomers } from '../../../../../../../store/actions/salesInvitationActions';

const DeleteButton = ({ customer, invitedCustomers, ...props }) => {
  const onDelete = () => {
    const filteredCustomers = invitedCustomers.filter((ic) => ic.id !== customer.id);
    props.setInvitedCustomers(filteredCustomers);
  };

  return (
    <div onClick={onDelete} role="button" aria-hidden title={i18n.t('buttons.delete')}>
      <TbTrash size="1.3rem" className="opacity-03" />
    </div>
  );
};

DeleteButton.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

DeleteButton.defaultProps = {};

export default connect((store) => ({
  invitedCustomers: store.salesInvitation.invitedCustomers
}), {
  setInvitedCustomers
})(DeleteButton);
