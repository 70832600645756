import React, { useEffect } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Table, Button, Spinner } from 'reactstrap';
import { connect } from 'react-redux';

import { listTrackingPages, cleanTrackingPages } from '../../../../../../store/actions/trackingPageActions';
import ListEmpty from '../../../../../common/ListEmpty';
import ListItem from './ListItem';
import { isAdmin } from '../../../../../../utils/authUtils';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';

const i18nOpts = { scope: 'components.admin.integrations.tracking.pages.list.index' };

const Pages = ({
  trackingPages, loading, currentCompany, ...props
}) => {
  const loadTrackingPages = () => {
    const variables = { filter: { companyId: currentCompany.id } };
    props.listTrackingPages(variables);
  };

  const onAdd = () => {
    ModalService.open(ModalNames.TRACKING_PAGE_FORM);
  };

  useEffect(() => {
    props.cleanTrackingPages()
      .then(() => loadTrackingPages());
  }, []);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2 className="mb-0">{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div>
          {isAdmin() && (
            <Button color="primary" onClick={onAdd} className="ml-3">
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Button>
          )}
        </div>
      </div>

      {loading && (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      )}

      <ListEmpty loading={loading} items={trackingPages} />

      {trackingPages.length > 0 && (
        <Table responsive bordered>
          <thead>
            <tr>
              <th>{i18n.t('url', i18nOpts)}</th>
              <th>{i18n.t('mapTo', i18nOpts)}</th>
              <th>{i18n.t('communityModel', i18nOpts)}</th>
              <th>{i18n.t('createdAt', i18nOpts)}</th>
              <th>{i18n.t('lastVisitedAt', i18nOpts)}</th>
              <th>{i18n.t('visitsCount', i18nOpts)}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
              trackingPages.map((tp) => (
                <ListItem trackingPage={tp} key={`tracking-page-${tp.id}`} />
              ))
            }
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default connect((store) => ({
  trackingPages: store.trackingPages.trackingPages,
  loading: store.trackingPages.listTrackingPages.loading,
  currentCompany: store.companies.currentCompany
}), {
  listTrackingPages,
  cleanTrackingPages
})(Pages);
