import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import v from 'voca';
import i18n from 'i18n-js';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { BiLineChartDown } from 'react-icons/bi';

import { FinancialTrends } from '../../../../constants';
import IncreasingTrendIcon
  from '../../../../assets/icons/financial-report/IncreasingTrendIcon';
import ConsistentTrendIcon
  from '../../../../assets/icons/financial-report/ConsistentTrendIcon';

const i18nOpts = { scope: 'components.global.customer.customerFinancialTrendBadge.index' };

const CustomerFinancialTrendBadge = ({ trend }) => {
  if (v.isBlank(trend)) return null;

  let Icon;
  let color;
  let label;
  switch (trend) {
    case FinancialTrends.INCREASING:
      Icon = IncreasingTrendIcon;
      color = 'primary';
      label = i18n.t('increasing', i18nOpts);
      break;
    case FinancialTrends.DECREASING:
      Icon = BiLineChartDown;
      color = 'danger';
      label = i18n.t('decreasing', i18nOpts);
      break;
    case FinancialTrends.CONSISTENT:
      Icon = ConsistentTrendIcon;
      color = 'info';
      label = i18n.t('consistent', i18nOpts);
      break;
    default:
      Icon = AiOutlineMinusCircle;
      color = 'light';
      label = i18n.t('noneDetected', i18nOpts);
      break;
  }

  return (
    <Badge color={color} pill className="d-inline-flex align-items-center">
      <Icon className="mr-2" />
      {label}
    </Badge>
  );
};

CustomerFinancialTrendBadge.propTypes = {
  trend: PropTypes.string
};

CustomerFinancialTrendBadge.defaultProps = {
  trend: null
};

export default CustomerFinancialTrendBadge;
