import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { TabContent, TabPane } from 'reactstrap';
import i18n from 'i18n-js';

import NavTabs from './NavTabs';
import { Tabs } from './utils';
import MortgageProviders from './MortgageProviders';

const i18nOpts = { scope: 'components.admin.mortgageRate.index' };

const MortgageRate = () => {
  const [activeTab, setActiveTab] = useState(Tabs.MORTGAGE_PROVIDERS);

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <h2 className="mb-4">{i18n.t('title', i18nOpts)}</h2>

      <NavTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.MORTGAGE_PROVIDERS}>
          <MortgageProviders />
        </TabPane>
      </TabContent>
    </div>
  );
};

MortgageRate.propTypes = {};

MortgageRate.defaultProps = {};

export default MortgageRate;
