import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

const Model = ({ quickPossession }) => {
  const { model } = quickPossession;

  if (v.isBlank(model)) return null;

  return (
    <div className="text-nowrap col-gray-600 font-weight-600">
      {model.name}
    </div>
  );
};

Model.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Model.defaultProps = {};

export default Model;
