import i18n from 'i18n-js';

import api from '../../../../../../api';
import {
  listVerificationDocumentsQuery
} from '../../../../../../graphql';
import { IdDocumentTypes, PreApprovalVerificationMethods } from '../../../../../../constants';
import { getIdDocumentTypeName } from '../../../../../../utils/enumUtils';

const i18nOpts = { scope: 'components.admin.customers.show.details.documents.utils' };

export const CustomerDocumentTypes = Object.freeze({
  PRE_APPROVAL: 'preApproval',
  DOCUMENT_SELFIE: 'documentSelfie',
  DOCUMENT_FRONT_PHOTO: 'documentFrontPhoto',
  DOCUMENT_BACK_PHOTO: 'documentBackPhoto',
  DOCUMENT_REPORT: 'documentReport',
});

export async function listVerificationDocumentsAsync(id) {
  return api.graphql(listVerificationDocumentsQuery, { id })
    .then(({ data: { items } }) => items);
}

export function getPreApprovalDocument(customer, currentCompany) {
  const { isFinancialVerified, user, companyContact } = customer;
  const preApproval = user?.preApproval;

  const isPreApprovalManual = preApproval?.verificationMethod
    === PreApprovalVerificationMethods.MANUAL;
  const isCurrentCompanyPreApproval = preApproval?.companyId === currentCompany.id;
  const isFinancialVerifiedAndGranted = (isFinancialVerified
      && companyContact.verifiedFinancialGranted)
    || (isPreApprovalManual && isCurrentCompanyPreApproval);

  if (!isFinancialVerifiedAndGranted || !isPreApprovalManual) return null;

  const { documentUrl } = preApproval;

  const fileExtension = documentUrl.split('.').pop();
  const fileName = `${i18n.t('preApproval', i18nOpts)} (${customer.name}).${fileExtension}`;

  return {
    documentType: CustomerDocumentTypes.PRE_APPROVAL,
    fileName,
    documentName: i18n.t('preApproval', i18nOpts),
    fileSize: null,
    createdAt: preApproval.createdAt,
    data: {
      downloadUrl: documentUrl,
    }
  };
}

function getIdVerificationDocumentPhoto(
  document, customer, idVerificationId, idVerificationSource
) {
  const {
    side, fileType, fileSize, createdAt, type
  } = document;
  const documentName = getIdDocumentTypeName(type);
  let documentSide;
  let documentType;

  if (side === 'back') {
    documentType = CustomerDocumentTypes.DOCUMENT_BACK_PHOTO;
    documentSide = i18n.t('documentBack', i18nOpts);
  } else {
    documentType = CustomerDocumentTypes.DOCUMENT_FRONT_PHOTO;
    documentSide = i18n.t('documentFront', i18nOpts);
  }
  const fileName = i18n.t('documentPhoto', {
    ...i18nOpts, documentSide, documentName, customerName: customer.name, fileType
  });

  return {
    documentType,
    fileName,
    documentName,
    fileSize,
    createdAt,
    data: document,
    idVerificationId,
    idVerificationSource
  };
}

function getIDVerificationDocumentSelfie(
  document, customer, idVerificationId, idVerificationSource
) {
  const { fileSize, createdAt } = document;

  const fileType = document.fileName.split('.').pop();
  const fileName = i18n.t('documentSelfie', { ...i18nOpts, customerName: customer.name, fileType });
  return {
    documentType: CustomerDocumentTypes.DOCUMENT_SELFIE,
    fileName,
    documentName: i18n.t('customerPhoto', i18nOpts),
    fileSize,
    createdAt,
    data: document,
    idVerificationId,
    idVerificationSource
  };
}

function getIdVerificationDocumentReport(
  document, customer, idVerificationId, idVerificationSource
) {
  const { fileSize, fileType, createdAt } = document;

  const fileName = i18n.t('documentReport', { ...i18nOpts, customerName: customer.name, fileType });
  return {
    documentType: CustomerDocumentTypes.DOCUMENT_REPORT,
    fileName,
    documentName: i18n.t('idVerification', i18nOpts),
    fileSize,
    createdAt,
    data: document,
    idVerificationId,
    idVerificationSource
  };
}

export function parseIdVerificationDocuments(
  documents, customer, idVerificationId, idVerificationSource
) {
  return documents.map((document) => {
    const { type, fileName } = document;
    const isIdDocumentPhoto = Object.values(IdDocumentTypes).includes(type);
    if (isIdDocumentPhoto) {
      return getIdVerificationDocumentPhoto(
        document, customer, idVerificationId, idVerificationSource
      );
    }
    if (type === 'selfie') {
      return getIDVerificationDocumentSelfie(
        document, customer, idVerificationId, idVerificationSource
      );
    }
    if (fileName?.includes('check')) {
      return getIdVerificationDocumentReport(
        document, customer, idVerificationId, idVerificationSource
      );
    }

    return null;
  });
}
