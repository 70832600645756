import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Card, CardBody, CardImg,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import i18n from 'i18n-js';

import { DEFAULT_IMAGE } from '../../../../constants';
import { isAdmin } from '../../../../utils/authUtils';

const i18nOpts = { scope: 'components.global.imagesManager.listItem.index' };

const ListItem = ({ image, onMarkAsDefault, ...props }) => {
  const canBeMarkedAsDefault = !!onMarkAsDefault;

  const markAsDefault = () => {
    if (!canBeMarkedAsDefault) return () => {};

    return onMarkAsDefault(image);
  };

  const onDelete = () => {
    props.onDelete(image);
  };

  if (!image) return null;

  const imageUrl = image.imageUrl || DEFAULT_IMAGE;

  return (
    <Card className="image-container">
      <CardImg top width="100%" src={imageUrl} alt="" className="mb-0 image sortable-image-item-handler" />
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            {canBeMarkedAsDefault && image?.isDefault && (
              <Badge color="info" pill>{i18n.t('isDefault', i18nOpts)}</Badge>
            )}
          </div>
          {isAdmin() && (
            <div className="d-flex justify-content-between action-buttons">
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="px-2">
                  <i className="fas fa-ellipsis-v text-primary" />
                </DropdownToggle>
                <DropdownMenu right>
                  {canBeMarkedAsDefault && (
                    <DropdownItem onClick={markAsDefault}>
                      {i18n.t('buttons.markAsDefault', i18nOpts)}
                    </DropdownItem>
                  )}
                  <DropdownItem className="text-danger" onClick={onDelete}>
                    {i18n.t('buttons.delete')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

ListItem.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrl: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func,
  onMarkAsDefault: PropTypes.func
};

ListItem.defaultProps = {
  onDelete: () => {},
  onMarkAsDefault: null
};

export default ListItem;
