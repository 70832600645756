import React from 'react';
import { modes } from '../../constants';
import Icon from '../../Icon';

import Vector from '../Vector';
import BezierEditor from '../../editors/BezierEditor';

const PRIMARY_COLOR = '#00AF8C';

class Path extends Vector {
  static meta = {
    initial: {
      fill: 'transparent',
      closed: false.toString(),
      rotate: 0,
      movex: 0,
      movey: 0,
      path: [],
      stroke: PRIMARY_COLOR,
      strokeWidth: 2
    },
    mode: modes.DRAW_PATH,
    icon: <Icon icon="polygon" size={30} />,
    editor: BezierEditor
  };

  buildPath(object) {
    const { path } = object;

    const curves = path.map(({
      x1, y1, x2, y2, x, y
    }) => {
      let curve = `${x} ${y}`;
      if (x2 && y2) curve += `, ${x} ${y}`;
      if (x && y) curve += `, ${x} ${y}`;
      return curve;
    });

    let instructions = [
      `M ${object.movex} ${object.movey}`,
      ...curves
    ];

    if (object.closed) {
      instructions = [
        ...instructions, 'Z'
      ];
    }

    return instructions.join(' ');
  }

  getTransformMatrix({
    rotate, x, y, movex, movey
  }) {
    return `
      translate(${x - movex} ${y - movey})
      rotate(${rotate} ${x} ${y})
    `;
  }

  render() {
    const { object } = this.props;
    const fill = object.closed ? object.fill : 'transparent';
    return (
      <path
        style={this.getStyle(object)}
        {...this.getObjectAttributes()}
        d={this.buildPath(object)}
        fill={fill}
        fillOpacity="0.5"
      />
    );
  }
}

export default Path;
