import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './styles.scss';
import Status from './Status';
import { DateFormats } from '../../../../../../../constants';
import { setWebhookDelivery } from '../../../../../../../store/actions/webhookDeliveryActions';

const ListItem = ({ webhookDelivery, activeWebhookDelivery, ...props }) => {
  const isActive = webhookDelivery.id === activeWebhookDelivery?.id;

  const onShow = () => {
    props.setWebhookDelivery(webhookDelivery);
  };

  return (
    <tr className={classNames('webhook-delivery-row', { 'bg-light': isActive, active: isActive })} role="button" onClick={onShow} aria-hidden>
      <td><Status webhookDelivery={webhookDelivery} /></td>
      <td><code>{webhookDelivery.event}</code></td>
      <td className="text-right">
        {webhookDelivery.processedAt && (
          <pre className="mb-0 font-weight-bold">{moment(webhookDelivery.processedAt).format(DateFormats.HH_MM_SS)}</pre>
        )}
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  webhookDelivery: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
  activeWebhookDelivery: store.webhookDeliveries.webhookDelivery
}), {
  setWebhookDelivery
})(ListItem);
