import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Input } from 'reactstrap';
import v from 'voca';
import classNames from 'classnames';

import { MappableFields } from '../../../../../../../constants';
import {
  getFormMappableFieldName,
} from '../../../../../../../utils/enumUtils';
import { parseBoolean } from '../../../../../../../utils/parserUtils';

const ListItem = ({ mappedField, ...props }) => {
  const onMapped = (event) => {
    const { name, value } = event.target;
    props.onMapped(name, value);
  };

  const onExcluded = (event) => {
    const { name, value } = event.target;
    props.onExcluded(name, value);
  };

  const mappedValue = mappedField.mapped || '';
  const excludedValue = parseBoolean(mappedField.excluded);

  return (
    <tr className={classNames({ 'bg-light': !v.isBlank(mappedValue) })}>
      <td>{mappedField.name}</td>
      <td>{mappedField.type}</td>
      <td>
        <Input
          type="select"
          name={mappedField.name}
          value={mappedValue}
          onChange={onMapped}
        >
          <option value="">{i18n.t('select.select')}</option>
          {
            Object.values(MappableFields).map((mf) => (
              <option key={`mappable-field-${mf}`} value={mf}>
                {getFormMappableFieldName(mf)}
              </option>
            ))
          }
        </Input>
      </td>
      <td className="text-center">
        <Input
          type="checkbox"
          className="ml-n1"
          name={mappedField.name}
          value={!excludedValue}
          checked={excludedValue}
          onChange={onExcluded}
        />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  mappedField: PropTypes.objectOf(PropTypes.any).isRequired,
  onMapped: PropTypes.func,
  onExcluded: PropTypes.func
};

ListItem.defaultProps = {
  onMapped: () => {},
  onExcluded: () => {}
};

export default ListItem;
