import {
  RELOAD_PROJECT_PALETTES
} from './types';

export function reloadProjectPalettes(reload) {
  return {
    type: RELOAD_PROJECT_PALETTES,
    payload: { reload }
  };
}
