import React, { useEffect, useState } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { hasFormChanges } from '../../../../../../utils/formUtils';
import { ModalNames } from '../../../../../../constants';
import { getError } from '../../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { parseBoolean, parseToFloat } from '../../../../../../utils/parserUtils';
import { onClose, saveBuildingModelPaletteAsync } from './utils';
import { reloadBuildingModelPalettes } from '../../../../../../store/actions/buildingModelPalettesActions';

const i18nOpts = { scope: 'components.admin.buildingModels.show.palettes.buildingModelPaletteFormModal.index' };

const BuildingModelPaletteFormModal = ({
  opened, params, ...props
}) => {
  const [initialForm, setInitialForm] = useState({});
  const [form, setForm] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const { buildingModelPalette } = params;

  useEffect(() => {
    if (!opened) return;

    loadBuildingModelPalette();
  }, [opened]);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSave = () => {
    const input = {
      ...form,
      price: parseToFloat(form.price),
      isDefault: parseBoolean(form.isDefault),
    };

    setSaving(true);

    saveBuildingModelPaletteAsync(input)
      .then(() => {
        props.reloadBuildingModelPalettes(true);
        onClose();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const loadBuildingModelPalette = () => {
    let formData = {};

    if (buildingModelPalette) {
      const { id, price, isDefault } = buildingModelPalette;
      formData = { id, price, isDefault };
    }

    setInitialForm(formData);
    setForm(formData);

    setError(null);
  };

  const hasChanges = hasFormChanges(initialForm, form);
  const title = i18n.t('title', { ...i18nOpts, paletteName: buildingModelPalette?.palette.name });

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <Row form>
          <Col lg="4" md="4" sm="6" xs="8">
            <FormGroup>
              <Label for="price">{i18n.t('price', i18nOpts)}</Label>
              <Input
                type="number"
                name="price"
                id="price"
                value={form.price || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'price')}
              />
              <InputError name="price" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup check className="mb-3">
          <Label check>
            <Input
              type="checkbox"
              name="isDefault"
              id="isDefault"
              value={!parseBoolean(form.isDefault)}
              checked={parseBoolean(form.isDefault)}
              onChange={onTextChange}
            />
            {i18n.t('isDefault', i18nOpts)}
          </Label>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSave} disabled={saving || !hasChanges}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.BUILDING_MODEL_PALETTE_FORM]?.opened || false,
  params: store.modals[ModalNames.BUILDING_MODEL_PALETTE_FORM]?.params || {}
}), {
  reloadBuildingModelPalettes
})(BuildingModelPaletteFormModal);
