import moment from 'moment';
import i18n from 'i18n-js';
import v from 'voca';

import { DEFAULT_IMAGE, DateFormats } from '../constants';
import LOT_DEFAULT_IMAGE from '../assets/images/lot-default-image.svg';

const i18nOpts = { scope: 'utils.homeUtils' };

export function getHomeInfo(home) {
  const { singleFamilyHome, multiFamilyHome } = home;
  let imageUrl;
  let homeType;
  let communityProjectName;
  let cityName;
  let isQuickPossession;
  let isLot;
  let address;

  if (multiFamilyHome) {
    const { project, unit } = multiFamilyHome;
    imageUrl = unit.defaultImageUrl || DEFAULT_IMAGE;
    homeType = unit.buildingModel.name;
    communityProjectName = project.name;
    cityName = project.city?.name;
    address = unit.address || project.address;
    isQuickPossession = false;
  } else {
    const {
      community, model, elevation
    } = singleFamilyHome;
    isLot = singleFamilyHome.isLot;
    const defaultImage = isLot ? LOT_DEFAULT_IMAGE : DEFAULT_IMAGE;
    imageUrl = elevation?.imageUrl || model?.defaultImageUrl || defaultImage;
    homeType = model?.productType?.name;
    communityProjectName = community.name;
    cityName = community.city?.name;
    isQuickPossession = singleFamilyHome.isQuickPossession;
    if (isQuickPossession) address = singleFamilyHome.quickPossession?.address;
  }

  const name = getHomeName(home);

  return {
    name,
    homeType,
    imageUrl,
    communityProjectName,
    cityName,
    isQuickPossession,
    isLot,
    address
  };
}

export function getValidUntilDate(home, company) {
  const createdDate = home ? moment(home.createdAt) : moment();
  const defaultValidUntilDate = moment(createdDate).add(7, 'days').format(DateFormats.YYYY_MM_DD);

  if (!company) return defaultValidUntilDate;
  const { settings: { design } } = company;
  const validUntil = design?.validUntil || {};
  if (!validUntil?.period || !validUntil?.amount) return defaultValidUntilDate;

  return createdDate.add(validUntil?.amount, validUntil?.period).format(DateFormats.YYYY_MM_DD);
}
export function getHomeName(home) {
  if (!home) return null;

  const { singleFamilyHome, multiFamilyHome } = home;

  if (singleFamilyHome) {
    const { isLot, model, lot } = singleFamilyHome;
    if (isLot) return getLotName(lot);
    return model.name;
  } return multiFamilyHome.unit.name;
}

export function getLotName(lot) {
  if (!lot?.data) return null;

  const { name, address } = lot.data;
  const names = [];
  if (!v.isBlank(name)) names.push(i18n.t('lotName', { ...i18nOpts, name }));
  if (!v.isBlank(address)) names.push(address);

  return names.join(' - ');
}
