import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Input,
  Label,
  Spinner
} from 'reactstrap';
import i18n from 'i18n-js';
import { BiLinkExternal, BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import v from 'voca';
import { connect } from 'react-redux';

import FileTypeIcon from '../../../common/FileTypeIcon';
import DocumentFile from '../../../common/DocumentUploader/DocumentFile';
import { uuidv4 } from '../../../../utils/randomUtils';
import api from '../../../../api';
import {
  docuSignCreateTemplateViewQuery,
  createOfferDocumentToSignTemplateQuery
} from '../../../../graphql';
import { OfferSignStatuses, DocuSignTemplateTypes } from '../../../../constants';
import { stripToEmpty } from '../../../../utils/stringUtils';
import { fetchDocuSignTemplatesAsync } from '../../../admin/Integrations/DocuSign/utils';

const i18nOpts = { scope: 'components.global.home.dealModal.overview.offerSection.acceptedOfferCard.offerDocumentToSign.index' };

const DocumentTemplate = ({ templateUrl }) => {
  if (v.isBlank(templateUrl)) return null;

  return (
    <a href={templateUrl} target="_blank" rel="noreferrer" className="d-block mt-2">
      <span className="mr-1">{i18n.t('editDocumentTemplate', i18nOpts)}</span>
      <BiLinkExternal size="1.1rem" />
    </a>
  );
};

const OfferDocumentToSign = ({
  offer, form, onDocumentToSignChange, currentCompany
}) => {
  const { isDocuSignConnected, isDocuSignGenericTemplateEnabled } = currentCompany;

  const [documentToSign, setDocumentToSign] = useState(offer.documentToSign);
  const [newDocumentToSign, setNewDocumentToSign] = useState(null);
  const [documentTemplateUrl, setDocumentTemplateUrl] = useState(null);
  const [uploadingTemplate, setUploadingTemplate] = useState(false);
  const [uploadDocumentManually, setUploadDocumentManually] = useState(!!offer.documentToSign);
  const [templates, setTemplates] = useState([]);
  const { signStatus } = offer;

  const isSignAccepted = () => signStatus === OfferSignStatuses.ACCEPTED;

  const onNewDocumentToSignChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setNewDocumentToSign({
      id: uuidv4(),
      file
    });
  };

  const onDocumentToSignUploaded = (document) => {
    setTimeout(() => {
      setNewDocumentToSign(null);
      setUploadingTemplate(true);

      const variables = { id: offer.id, documentId: stripToEmpty(document.id) };
      api.graphql(createOfferDocumentToSignTemplateQuery, variables)
        .then(({ data: { templateId } }) => {
          setDocumentToSign(document);
          onDocumentToSignChange(document.id, templateId);
          loadTemplates();
        })
        .catch(() => toast.error(i18n.t('messages.notUploaded', i18nOpts)))
        .finally(() => setUploadingTemplate(false));
    }, 1000);
  };

  const onDeleteDocumentToSign = () => {
    setDocumentToSign(null);
    onDocumentToSignChange(null, null);
  };

  const createTemplateView = (templateId) => {
    if (v.isBlank(templateId)) return;

    const variables = { companyId: currentCompany.id, templateId };
    api.graphql(docuSignCreateTemplateViewQuery, variables)
      .then(({ data: { result: { templateUrl } } }) => {
        setDocumentTemplateUrl(templateUrl);
      });
  };

  const onChangeTemplateType = () => {
    setUploadDocumentManually(!uploadDocumentManually);
  };

  const onSelectTemplate = (event) => {
    const { value: templateId } = event.target;
    onDocumentToSignChange(null, templateId);
  };

  const loadTemplates = () => {
    const variables = { companyId: currentCompany.id };
    fetchDocuSignTemplatesAsync(variables)
      .then((fetchedTemplates) => setTemplates(fetchedTemplates))
      .catch(() => setTemplates([]));
  };

  useEffect(() => {
    if (isSignAccepted()) return;

    createTemplateView(form.docuSignTemplateId);
  }, [form.docuSignTemplateId]);

  useEffect(() => {
    loadTemplates();
  }, []);

  if (!isDocuSignConnected || isDocuSignGenericTemplateEnabled) return null;

  return (
    <div>
      <Label className="d-block">{i18n.t('documentToSign', i18nOpts)}</Label>

      <FormGroup check>
        <Label for="uploadDocument">
          <Input
            type="radio"
            name="templateType"
            id="uploadDocument"
            checked={uploadDocumentManually}
            value={DocuSignTemplateTypes.UPLOAD_DOCUMENT}
            onChange={onChangeTemplateType}
            disabled={isSignAccepted()}
          />
          {i18n.t('uploadDocument', i18nOpts)}
        </Label>
      </FormGroup>

      <FormGroup check className="mb-2">
        <Label for="selectTemplate">
          <Input
            type="radio"
            name="templateType"
            id="selectTemplate"
            checked={!uploadDocumentManually}
            value={DocuSignTemplateTypes.SELECT_TEMPLATE}
            onChange={onChangeTemplateType}
            disabled={isSignAccepted()}
          />
          {i18n.t('selectTemplate', i18nOpts)}
        </Label>
      </FormGroup>

      {uploadDocumentManually && (
        <>
          {documentToSign && (
            <div className="d-flex mb-2">
              <div className="flex-grow-1">
                <FileTypeIcon filename={documentToSign.name} size="sm" />
                <a href={documentToSign.fileUrl} target="_blank" rel="noreferrer">
                  {documentToSign.name}
                </a>

                <DocumentTemplate templateUrl={documentTemplateUrl} />
              </div>

              {!isSignAccepted() && (
                <div className="d-flex">
                  <BiTrash
                    size="1.1rem"
                    role="button"
                    className="text-danger"
                    title={i18n.t('buttons.delete')}
                    onClick={onDeleteDocumentToSign}
                  />
                </div>
              )}
            </div>
          )}

          {!isSignAccepted() && (
            <>
              <Label for="documentToSign" className="btn btn-sm btn-primary mb-3">
                <i className="fas fa-plus mr-2" />
                {i18n.t('buttons.uploadFile', i18nOpts)}
              </Label>

              <Input
                type="file"
                name="documentToSign"
                id="documentToSign"
                onChange={onNewDocumentToSignChange}
                className="d-none"
              />
            </>
          )}

          {newDocumentToSign && (
            <DocumentFile
              documentFile={newDocumentToSign}
              key={`document-file-${newDocumentToSign.id}`}
              completeDelay={1000}
              excluded
              onUploaded={onDocumentToSignUploaded}
            />
          )}

          {uploadingTemplate && (
            <div className="text-center mb-3">
              <Spinner size="sm" className="mr-2" />
              {i18n.t('uploadingDocumentTemplate', i18nOpts)}
            </div>
          )}
        </>
      )}

      {!uploadDocumentManually && (
        <>
          <FormGroup>
            <Label for="templateId">{i18n.t('templateId', i18nOpts)}</Label>
            <Input
              type="select"
              name="templateId"
              id="templateId"
              value={form.docuSignTemplateId || ''}
              onChange={onSelectTemplate}
              disabled={isSignAccepted()}
            >
              <option value="">{i18n.t('select.select')}</option>
              {
                templates.map((template) => (
                  <option key={`template-option-${template.templateId}`} value={template.templateId}>
                    {template.name}
                  </option>
                ))
              }
            </Input>
          </FormGroup>

          <DocumentTemplate templateUrl={documentTemplateUrl} />
        </>
      )}
    </div>
  );
};

OfferDocumentToSign.propTypes = {
  onDocumentToSignChange: PropTypes.func
};

OfferDocumentToSign.defaultProps = {
  onDocumentToSignChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}))(OfferDocumentToSign);
