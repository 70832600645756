import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import Step from '../components/Step';
import { ModalNames, OfferStatuses, ReserveStepTypes } from '../../../../../constants';
import ModalService from '../../../../../services/ModalService';
import OfferSubmittedIcon from '../../../../../assets/icons/offer/OfferSubmittedIcon';
import OfferAcceptedIcon from '../../../../../assets/icons/offer/OfferAcceptedIcon';
import OfferDeclinedIcon from '../../../../../assets/icons/offer/OfferDeclinedIcon';
import OfferRecounteredIcon from '../../../../../assets/icons/offer/OfferRecounteredIcon';
import OfferCounteredIcon from '../../../../../assets/icons/offer/OfferCounteredIcon';
import OfferPendingIcon from '../../../../../assets/icons/offer/OfferPendingIcon';
import OfferInvitedIcon from '../../../../../assets/icons/offer/OfferInvitedIcon';

const i18nOpts = { scope: 'components.global.home.offerSteps.offerStep.index' };

const OfferStep = ({ home, clickable, label: defaultLabel }) => {
  const { offer } = home;
  const status = offer?.status;

  let label;
  let color;
  let date;
  let Icon;
  let isClickable = clickable;

  switch (status) {
    case OfferStatuses.SUBMITTED:
      label = i18n.t('submitted', i18nOpts);
      color = 'success';
      date = offer.statusChangeDates.submittedAt;
      Icon = OfferSubmittedIcon;
      break;

    case OfferStatuses.COUNTERED:
      label = i18n.t('countered', i18nOpts);
      color = 'success';
      date = offer.statusChangeDates.counteredAt;
      Icon = OfferCounteredIcon;
      break;

    case OfferStatuses.RECOUNTERED:
      label = i18n.t('pending', i18nOpts);
      color = 'warning';
      date = offer.statusChangeDates.recounteredAt;
      Icon = OfferRecounteredIcon;
      break;

    case OfferStatuses.ACCEPTED:
      label = i18n.t('accepted', i18nOpts);
      color = 'success';
      date = offer.statusChangeDates.acceptedAt;
      Icon = OfferAcceptedIcon;
      break;

    case OfferStatuses.REJECTED:
      label = i18n.t('rejected', i18nOpts);
      color = 'danger';
      date = offer.statusChangeDates.rejectedAt;
      Icon = OfferDeclinedIcon;
      break;

    case OfferStatuses.DECLINED:
      label = i18n.t('declined', i18nOpts);
      color = 'danger';
      date = offer.statusChangeDates.declinedAt;
      Icon = OfferDeclinedIcon;
      break;

    case OfferStatuses.INVITED:
      label = i18n.t('invited', i18nOpts);
      color = 'success';
      date = offer.statusChangeDates.invitedAt;
      Icon = OfferInvitedIcon;
      isClickable = false;
      break;

    default:
      label = i18n.t('pending', i18nOpts);
      color = 'light';
      date = null;
      Icon = OfferPendingIcon;
      break;
  }

  const onClick = () => {
    ModalService.open(ModalNames.DEAL, { homeId: home.id, stepType: ReserveStepTypes.OFFER });
  };

  let extraProps = {};

  if (isClickable && !!status) {
    extraProps = {
      role: 'button',
      'aria-hidden': true,
      onClick
    };
  }

  return (
    <div>
      <Step
        title={defaultLabel}
        label={label}
        color={color}
        date={date}
        Icon={Icon}
        {...extraProps}
      />
    </div>
  );
};

OfferStep.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired,
  clickable: PropTypes.bool,
  label: PropTypes.string
};

OfferStep.defaultProps = {
  clickable: false,
  label: null
};

export default OfferStep;
