import { useSelector } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { getTotalPrice } from '../../../../utils/quickPossessionUtils';
import { parseBoolean } from '../../../../utils/parserUtils';
import { stripToNull } from '../../../../utils/stringUtils';
import { FloorableTypes, QuickPossessionStages, QuickPossessionStatuses } from '../../../../constants';
import useForm from '../../../../hooks/useForm';
import { getError } from '../../../../utils/requestUtils';
import api from '../../../../api';
import {
  createQuickPossessionQuery,
  getQuickPossessionQuery,
  updateQuickPossessionQuery,
  validateQuickPossessionMutation
} from '../../../../graphql/quickPossessions';
import { formatTimestampToDateInput } from '../../../../utils/dateUtils';
import { createQuickPossessionImageQuery } from '../../../../graphql/quickPossessionImage';
import { createFloorQuery, deleteFloorQuery } from '../../../../graphql';

function saveQuickPossession(variables) {
  const query = variables.input.id ? updateQuickPossessionQuery : createQuickPossessionQuery;
  return api.graphql(query, variables)
    .then(({ data: { item } }) => item);
}

const useSaveQuickPossession = () => {
  const {
    form, setForm, submitting, setSubmitting, error, setError, onTextChange
  } = useForm();

  const {
    currentCompany
  } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany
  }));

  const isValidForm = () => !v.isBlank(form.model?.id) && !v.isBlank(form.community?.id)
    && !v.isBlank(form.address) && !v.isBlank(form.availability);

  const getInput = () => {
    const {
      specifications, community, model, elevation, package: specPackage, palette, floorOptions = {}
    } = form;

    const selectedFloorOptions = Object.values(floorOptions).flat(1);
    const enablePossessionDate = parseBoolean(form.enablePossessionDate);
    const overrideFloors = parseBoolean(form.overrideFloors);

    const input = {
      address: stripToNull(form.address),
      description: stripToNull(form.description),
      squareFootage: parseFloat(form.squareFootage) || null,
      availability: stripToNull(form.availability),
      communityId: parseInt(community?.id, 10) || 0,
      ...(community?.id === 0) ? { communityName: community?.name } : {},
      modelId: parseInt(model?.id, 10) || 0,
      ...(model?.id === 0) ? { modelName: model?.name } : {},
      elevationId: elevation?.id || null,
      paletteId: palette?.id || null,
      packageId: specPackage?.id || null,
      ...(overrideFloors ? {} : {
        floorOptionIds: selectedFloorOptions?.map((f) => (f.id)) || [],
        editableFloorIds: form.editableFloorIds,
        editableFloorGroupIds: form.editableFloorGroupIds,
      }),
      specificationIds: specifications?.map((s) => (s.id)) || [],
      totalPrice: getTotalPrice(form, floorOptions),
      enablePossessionDate,
      possessionDate: enablePossessionDate ? form.possessionDate : null,
      url: stripToNull(form.url),
      customPrice: parseFloat(form.customPrice) || null,
      customBeds: parseFloat(form.customBeds) || null,
      customBaths: parseFloat(form.customBaths) || null,
      overrideFloors,
      requiredDepositAmount: parseFloat(form.requiredDepositAmount) || null
    };

    const isNew = v.isBlank(form.id);
    if (isNew) {
      input.companyId = currentCompany.id;
      input.status = QuickPossessionStatuses.PUBLISHED;
      input.stage = QuickPossessionStages.ACTIVE;
    } else {
      input.id = form.id;
      input.status = stripToNull(form.status);
      input.stage = stripToNull(form.stage);
    }

    return input;
  };

  const getMainImage = () => {
    const { image } = form;
    return image?.length ? image[0].file : null;
  };

  const saveQuickPossessionAsync = async () => {
    setSubmitting(true);
    setError(null);

    const input = getInput();
    const image = getMainImage();

    return saveQuickPossession({ input, image })
      .then((item) => saveQuickPossessionImagesAsync(item.id)
        .then(() => saveQuickPossessionFloorsAsync(item.id))
        .then(() => Promise.resolve(item)))
      .catch((e) => {
        const formattedError = getError(e);
        if (v.isString(formattedError)) toast.error(formattedError);
        setError(formattedError);
        return Promise.reject(e);
      })
      .finally(() => setSubmitting(false));
  };

  const validateQuickPossessionAsync = async () => {
    setSubmitting(true);
    setError(null);

    const input = getInput();

    return api.graphql(validateQuickPossessionMutation, { input })
      .catch((e) => {
        const formattedError = getError(e);
        if (v.isString(formattedError)) toast.error(formattedError);
        setError(formattedError);
        return Promise.reject(e);
      })
      .finally(() => setSubmitting(false));
  };

  const uploadQuickPossessionImageAsync = async (quickPossessionId, image) => {
    const variables = {
      input: { quickPossessionId },
      image
    };
    return api.graphql(createQuickPossessionImageQuery, variables);
  };

  const saveQuickPossessionImagesAsync = async (quickPossessionId) => {
    const { images } = form;
    if (!images?.length) return Promise.resolve();

    const promises = images.map((image) => uploadQuickPossessionImageAsync(quickPossessionId,
      image.file));
    return Promise.all(promises)
      .catch(() => Promise.resolve());
  };

  const uploadQuickPossessionFloorImageAsync = async (floorInput, image) => {
    const variables = { input: floorInput, image };

    return api.graphql(createFloorQuery, variables);
  };

  const deleteQuickPossessionFloorImageAsync = async (id) => api.graphql(deleteFloorQuery, { id });

  const saveQuickPossessionFloorsAsync = async (quickPossessionId) => {
    const { overrideFloors, floorImages = [], initialFloorImages = [] } = form;
    if (!parseBoolean(overrideFloors)) return Promise.resolve();

    let displayOrderCounter = initialFloorImages.length;
    const uploadPromises = floorImages
      .filter((image) => image.file)
      .map((image) => {
        // eslint-disable-next-line no-plusplus
        const displayOrder = ++displayOrderCounter;
        const input = {
          name: `Floor ${displayOrder}`,
          displayOrder,
          floorableId: quickPossessionId,
          floorableType: FloorableTypes.QUICK_POSSESSION
        };
        return uploadQuickPossessionFloorImageAsync(input, image.file);
      });

    const initialFloorImageIds = initialFloorImages.map((image) => image.id);
    const currentFloorImageIds = floorImages.map((image) => image.id);
    const floorImageIdsToDelete = initialFloorImageIds.filter(
      (id) => !currentFloorImageIds.includes(id)
    );

    const deletePromises = floorImageIdsToDelete.map(
      (id) => deleteQuickPossessionFloorImageAsync(id)
    );

    return Promise.all([...uploadPromises, ...deletePromises])
      .catch(() => Promise.resolve());
  };

  const loadQuickPossessionAsync = async (id) => api.graphql(getQuickPossessionQuery, { id })
    .then(({ data: { item: quickPossession } }) => {
      const {
        url, address, description, status, availability, community,
        squareFootage, model, customPrice, customBeds, customBaths,
        enablePossessionDate, possessionDate, lotId,
        editableFloors = [], editableFloorGroups = [], overrideFloors,
        floors, requiredDepositAmount, stage
      } = quickPossession;

      let initialFloorImages = [];
      if (floors && floors.length > 0) {
        initialFloorImages = floors.map((floor) => ({ id: floor.id, imageUrl: floor.imageUrl }));
      }

      const initialForm = {
        url,
        address,
        description,
        status,
        availability,
        squareFootage,
        id,
        enablePossessionDate,
        possessionDate: formatTimestampToDateInput(possessionDate),
        lotId,
        editableFloorIds: editableFloors.map((f) => f.id),
        editableFloorGroupIds: editableFloorGroups.map((fg) => fg.id),
        customPrice,
        customBeds,
        customBaths,
        community,
        model,
        overrideFloors,
        initialFloorImages,
        requiredDepositAmount,
        stage
      };

      setForm(initialForm);
    });

  return {
    form,
    setForm,
    submitting,
    setSubmitting,
    error,
    setError,
    onTextChange,
    saveQuickPossessionAsync,
    isValidForm,
    validateQuickPossessionAsync,
    loadQuickPossessionAsync
  };
};

export default useSaveQuickPossession;
