import React from 'react';
import {
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { setCommunitySearchFilters } from '../../../../../store/actions/communityActions';
import { SortOrders } from '../../../../../constants';

const i18nOpts = { scope: 'components.admin.communities.list.sorting.index' };

const Sorting = ({ searchFilters, ...props }) => {
  const sortOption = searchFilters.sort?.direction === SortOrders.DESC
    ? i18n.t('desc', i18nOpts) : i18n.t('asc', i18nOpts);

  const onSortChange = (direction) => {
    const sort = { field: 'name', direction };
    props.setCommunitySearchFilters({ sort });
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle color="outline-secondary" caret size="sm" className="text-nowrap">
        <span className="text-uppercase text-muted">{i18n.t('sort', i18nOpts)}</span>
        <span className="ml-1">{sortOption}</span>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={() => onSortChange(SortOrders.ASC)}>
          {i18n.t('asc', i18nOpts)}
        </DropdownItem>
        <DropdownItem onClick={() => onSortChange(SortOrders.DESC)}>
          {i18n.t('desc', i18nOpts)}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default connect((store) => ({
  searchFilters: store.communities.searchFilters
}), {
  setCommunitySearchFilters
})(Sorting);
