import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import { getOfferJourneyLabel } from '../../../../utils/reserveNowUtils';
import { OfferStatuses } from '../../../../constants';

const OfferStatusBadge = ({ offer }) => {
  if (!offer) return null;

  const getColor = () => {
    const { status } = offer;
    if (status === OfferStatuses.SUBMITTED) return 'primary';
    if (status === OfferStatuses.ACCEPTED) return 'primary';
    if (status === OfferStatuses.COUNTERED) return 'warning';
    if (status === OfferStatuses.RECOUNTERED) return 'warning';
    if (status === OfferStatuses.REJECTED) return 'danger';
    if (status === OfferStatuses.DECLINED) return 'danger';
    return 'light';
  };

  const color = useMemo(getColor, [offer]);

  return (
    <Badge color={color} pill>
      {getOfferJourneyLabel(offer)}
    </Badge>
  );
};

OfferStatusBadge.propTypes = {
  offer: PropTypes.objectOf(PropTypes.any)
};

OfferStatusBadge.defaultProps = {
  offer: null,
};

export default OfferStatusBadge;
