import React from 'react';
import { connect } from 'react-redux';

import ColumnsPickerModal from '../../../../../global/ColumnsPickerModal';
import { ModalNames, QuickPossessionColumns } from '../../../../../../constants';
import { getQuickPossessionColumnName } from '../../../../../../utils/enumUtils';
import { setQuickPossessionColumns } from '../../../../../../store/actions/quickPossessionActions';

const QuickPossessionColumnsPickerModal = ({ selectedColumns }) => (
  <ColumnsPickerModal
    modalName={ModalNames.QUICK_POSSESSION_COLUMNS_PICKER}
    getColumnName={getQuickPossessionColumnName}
    columnSettingsKey="quickPossessions"
    selectedColumns={selectedColumns}
    selectableColumns={Object.values(QuickPossessionColumns)}
    setColumnsAction={setQuickPossessionColumns}
  />
);

QuickPossessionColumnsPickerModal.propTypes = {};

QuickPossessionColumnsPickerModal.defaultProps = {};

export default connect((store) => ({
  selectedColumns: store.quickPossessions.columns || []
}))(QuickPossessionColumnsPickerModal);
