import React from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';

import ListItem from './ListItem';
import ListHeader from './ListHeader';

const ListView = ({ customers }) => {
  if (!customers.length) return null;

  return (
    <Table bordered responsive>
      <thead>
        <ListHeader />
      </thead>
      <tbody>
        {
          customers.map((customer) => (
            <ListItem key={`customer-${customer.id}`} customer={customer} />
          ))
        }
      </tbody>
    </Table>
  );
};

ListView.propTypes = {};

ListView.defaultProps = {};

export default connect((store) => ({
  customers: store.customers.customers,
}))(ListView);
