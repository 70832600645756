const fragment = `
  id
  displayOrder
  model {
    id
    name
    imageUrl
    defaultImageUrl
    productType {
      id
      name
    }
  }
  community {
    id
    name
    imageUrl
    phases {
      id
      phase
      architecturalPrice
    }
  }
`;

export const listCommunityModelsQuery = `
  query ListCommunityModels(
    $filter: CommunityModelFilterInput
  ) {
    items: listCommunityModels(filter: $filter) {
      ${fragment}
    }
  }
`;

export const sortCommunityModelsQuery = `
  mutation SortCommunityModels(
    $communityId: Int!,
    $ids: [Int!]!
  ) {
    items: sortCommunityModels(communityId: $communityId, ids: $ids) {
      id
    }
  }
`;

export const createCommunityModelQuery = `
  mutation CreateCommunityModel(
    $input: CreateCommunityModelInput!
  ) {
    item: createCommunityModel(input: $input) {
      ${fragment}
    }
  }
`;

export const updateCommunityModelQuery = `
  mutation UpdateCommunityModel(
    $input: UpdateCommunityModelInput!
  ) {
    item: updateCommunityModel(input: $input) {
      ${fragment}
    }
  }
`;

export const deleteCommunityModelQuery = `
  mutation DeleteCommunityModel(
    $id: Int!
  ) {
    item: deleteCommunityModel(id: $id) {
      ${fragment}
    }
  }
`;
