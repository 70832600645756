import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import moment from 'moment/moment';
import BaseTimeAgo from 'react-timeago';

import { DateFormats } from '../../../constants';

const i18nOpts = { scope: 'components.common.timeAgo.index' };

const TimeAgo = ({ date }) => {
  if (!date) return null;

  const now = moment();
  const dateMoment = moment(date);

  const isToday = now.isSame(dateMoment, 'date');

  if (isToday) return (<BaseTimeAgo date={date} />);

  const isYesterday = now.clone().subtract(1, 'day').isSame(dateMoment, 'date');
  const title = dateMoment.format(DateFormats.MMM_DD_YYYY_HH_MM);

  let formattedDate = null;

  if (isYesterday) formattedDate = i18n.t('yesterday', i18nOpts);
  else { formattedDate = dateMoment.format(DateFormats.DD_MMM_YYYY); }

  return (
    <span title={title}>
      {formattedDate}
    </span>
  );
};

TimeAgo.propTypes = {
  date: PropTypes.string
};

TimeAgo.defaultProps = {
  date: null
};

export default TimeAgo;
