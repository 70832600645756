import React, { useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import v from 'voca';

import FormSubmissionIcon from '../../../../assets/icons/FormSubmissionIcon';
import { TrackingSources } from '../../../../constants';

const i18nOpts = { scope: 'components.global.customer.customerFormSubmissionIndicator.index' };

const CustomerFormSubmissionIndicator = ({ customer }) => {
  const { companyContact } = customer;
  if (!companyContact) return null;

  const {
    leadSource,
    lastBuiltDate,
    verifiedIdGranted,
    verifiedFinancialGranted,
    verifiedHomeGranted
  } = companyContact;

  const hasActivity = !v.isBlank(lastBuiltDate)
    || verifiedIdGranted || verifiedFinancialGranted || verifiedHomeGranted;

  if (leadSource !== TrackingSources.WEBTRACKER) return null;
  if (leadSource === TrackingSources.WEBTRACKER && hasActivity) return null;

  const [showPopover, setShowPopover] = useState(false);

  return (
    <div>
      <div
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        id={`form-submission-indicator-icon-${customer.id}`}
      >
        <FormSubmissionIcon width={14} height={17} />
      </div>
      <Popover target={`form-submission-indicator-icon-${customer.id}`} placement="bottom" isOpen={showPopover}>
        <PopoverBody>
          {i18n.t('description', i18nOpts)}
        </PopoverBody>
      </Popover>
    </div>
  );
};

CustomerFormSubmissionIndicator.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomerFormSubmissionIndicator.defaultProps = {};

export default CustomerFormSubmissionIndicator;
