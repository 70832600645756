import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { getValidUntilDate } from '../../../../utils/homeUtils';
import { isValidAmount } from '../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.global.home.validUntil.index' };

const ValidUntil = ({ home, currentCompany, className }) => {
  const { enableValidUntil } = currentCompany;
  if (!enableValidUntil) return null;

  const { totalPrice } = home;
  if (!isValidAmount(totalPrice)) return null;

  const validUntilDate = getValidUntilDate(home, currentCompany);

  return (
    <small className={classNames('text-muted', className)}>
      { i18n.t('validUntil', { ...i18nOpts, date: validUntilDate }) }
    </small>
  );
};

ValidUntil.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired
};

ValidUntil.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(ValidUntil);
