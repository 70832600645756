import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Card, Spinner } from 'reactstrap';
import { Bar } from 'react-chartjs-2';

import api from '../../../../api';
import { getTopElevationsQuery, listElevationsQuery } from '../../../../graphql';
import { formatRange } from '../../../../utils/dateUtils';
import { addSampleVisitors } from '../../../../utils/sampleDataUtils';

const i18nOpts = { scope: 'components.admin.overview.topElevations.index' };

function getChartData(elevations) {
  return {
    labels: elevations.map((c) => c.name),
    datasets: [
      {
        datasetKeyProvider: elevations.map((c) => c.id),
        label: i18n.t('selections', i18nOpts).toUpperCase(),
        backgroundColor: '#BAC638',
        borderWidth: 0,
        hoverBackgroundColor: '#BAC638',
        data: elevations.map((c) => c.elevationsCount)
      }
    ]
  };
}

const legend = {
  align: 'start',
  labels: {
    font: {
      color: '#000000',
      weight: '800',
      size: 10
    },
    boxWidth: 10
  }
};

const options = {
  scales: {
    x: {
      position: 'top',
      ticks: {
        font: {
          color: '#000000',
          weight: '800',
          size: 10
        },
        beginAtZero: true,
        stepSize: 1
      }
    },
    yAxes: {
      ticks: {
        font: {
          color: '#000000',
          weight: '800',
          size: 10
        },
      }
    }
  },
  indexAxis: 'y',
  plugins: {
    legend
  }
};

function fetchElevationsAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getTopElevationsQuery, variables)
    .then(({ data: { results } }) => Promise.resolve(results));
}

function getElevationsSampleDataAsync(filter) {
  const { communityId, modelId } = filter;
  const sampleDataFilter = { communityId, modelId };
  return api.graphql(listElevationsQuery, { sampleDataFilter })
    .then(({ data: { items } }) => {
      let elevations = items.map((e) => ({ name: e.name, id: e.id })).slice(0, 5);
      elevations = addSampleVisitors(elevations, 10, 'elevationsCount');
      return Promise.resolve(elevations);
    });
}

function fetchTopElevationsAsync(company, filter) {
  return company.enableSampleData ? getElevationsSampleDataAsync(filter)
    : fetchElevationsAsync(filter);
}

const TopElevations = ({ currentCompany, filter }) => {
  const [loading, setLoading] = useState(false);
  const [elevations, setElevations] = useState([]);
  const [rangeDateLabel, setRangeDateLabel] = useState('');

  useEffect(() => {
    if (!filter) return;

    setLoading(true);
    setRangeDateLabel(formatRange(filter?.customDateRange));
    fetchTopElevationsAsync(currentCompany, filter)
      .then((items) => setElevations(items))
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [filter]);

  const data = getChartData(elevations);

  return (
    <Card body className="mb-0 h-100">
      <h4>{i18n.t('title', i18nOpts)}</h4>
      <div className="text-uppercase mb-3">
        <small>{rangeDateLabel}</small>
      </div>

      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <Bar
          data={{ ...data }}
          options={{ ...options }}
        />
      )}
    </Card>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  currentCommunity: store.overview.community,
  currentModel: store.overview.model
}), {})(TopElevations);
