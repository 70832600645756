import React, { useEffect, useState } from 'react';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import i18n from 'i18n-js';

import PropertyGroup from '../PropertyGroup';
import Columns from '../Columns';
import Column from '../Column';

const PRIMARY_COLOR = '#00AF8C';

const UnitsPanel = ({
  buildingFloor, object, onChange: onChangeObject, onAssign, updateUnits
}) => {
  const [opened, setOpened] = useState(false);
  const [filteredUnits, setUnits] = useState([]);
  const title = object?.unit?.name || i18n.t('select.select');

  useEffect(() => {
    const { units = [] } = buildingFloor;
    setUnits(units.filter((u) => Object.keys(u.designedObject).length === 0));
  }, []);

  const onChange = (unit) => {
    onAssign(object, unit)
      .then(() => {
        onChangeObject('fill', PRIMARY_COLOR);
        onChangeObject('unit', unit);
        updateUnits(unit, object);
      });
  };

  if (filteredUnits.length === 0) return null;

  return (
    <PropertyGroup>
      <Columns label="Unit" overloadStyles={{ height: '60px' }}>
        <Column>
          <Dropdown isOpen={opened} toggle={() => setOpened(!opened)}>
            <DropdownToggle color="outline-secondary" caret disabled={!filteredUnits.length}>
              <span className="ml-1">{title}</span>
            </DropdownToggle>
            <DropdownMenu right>
              {filteredUnits.map((u) => (
                <DropdownItem key={`building-floor-unit-${u.id}`} onClick={() => onChange(u)}>
                  {u.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Column>
      </Columns>
    </PropertyGroup>
  );
};

export default UnitsPanel;
