import React from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import List from './List';

const i18nOpts = { scope: 'components.admin.taxRates.index' };

const TaxRates = () => (
  <div>
    <Helmet title={i18n.t('title', i18nOpts)} />

    <div className="mb-4 d-flex">
      <div className="flex-grow-1">
        <span className="font-24">{i18n.t('title', i18nOpts)}</span>
      </div>
      <div>
        <Link to="/admin/taxRates/form" className="btn btn-primary">
          <i className="fas fa-plus mr-2" />
          {i18n.t('buttons.add', i18nOpts)}
        </Link>
      </div>
    </div>
    <div>
      <List />
    </div>
  </div>
);

export default TaxRates;
