import { useState } from 'react';

const useForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [initialForm, setInitialForm] = useState({});
  const [form, setForm] = useState({});
  const [error, setError] = useState(null);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  return {
    form,
    setForm,
    submitting,
    setSubmitting,
    initialForm,
    setInitialForm,
    error,
    setError,
    onTextChange
  };
};

export default useForm;
