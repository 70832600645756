import React, { useMemo } from 'react';
import { Badge, Button } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';

const i18nOpts = { scope: 'components.admin.customers.list.editColumnsButton.index' };

const EditColumnsButton = ({ selectedColumns }) => {
  const columnsCount = useMemo(() => selectedColumns.length, [selectedColumns]);

  const onClick = () => {
    ModalService.open(ModalNames.CUSTOMER_COLUMNS_PICKER);
  };

  return (
    <Button color="secondary" outline onClick={onClick}>
      {i18n.t('title', i18nOpts)}
      {columnsCount > 0 && (
        <Badge pill color="primary" className="ml-2">{columnsCount}</Badge>
      )}
    </Button>
  );
};

EditColumnsButton.propTypes = {};

EditColumnsButton.defaultProps = {};

export default connect((store) => ({
  selectedColumns: store.customers.columns,
}))(EditColumnsButton);
