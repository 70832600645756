import React, { Component } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import v from 'voca';
import PropTypes from 'prop-types';

import { hasFormValues } from '../../../../utils/formUtils';
import { saveFloor, setReloadFloors } from '../../../../store/actions/floorActions';
import { FloorableTypes, ModalNames } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import { parseBoolean, parseToFloat } from '../../../../utils/parserUtils';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { stripToEmpty } from '../../../../utils/stringUtils';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import ImageViewer from '../../../common/ImageViewer';

const i18nOpts = { scope: 'components.global.floorsManager.floorFormModal.index' };

function onClose() {
  ModalService.close(ModalNames.FLOOR_FORM);
}

class FloorFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      image: null,
      saving: false,
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { opened } = this.props;
    if (prevProps.opened !== opened && opened) this.init();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onSave() {
    const { props } = this;
    const { image } = this.state;

    const input = this.getFormInput();

    this.setState({ saving: true });

    const variables = { input, image };
    props.saveFloor(variables)
      .then(() => {
        props.setReloadFloors(true);
        ModalService.close(ModalNames.FLOOR_FORM);
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  onImageChange(event) {
    const image = event.target.files[0];
    this.setState({ image });
  }

  getFormInput() {
    const { floorableId, floorableType } = this.props;
    const { form } = this.state;
    const { id, name } = form;

    const input = {
      name: stripToEmpty(name),
      isBelowGrade: parseBoolean(form.isBelowGrade),
      floorableId,
      floorableType
    };

    if (id) input.id = id;

    const size = parseToFloat(form.size);
    if (!Number.isNaN(size) || size === null) input.size = size;

    const bedrooms = parseToFloat(form.bedrooms);
    if (!Number.isNaN(bedrooms) || bedrooms === null) input.bedrooms = bedrooms;

    const bathrooms = parseToFloat(form.bathrooms);
    if (!Number.isNaN(bathrooms) || bathrooms === null) input.bathrooms = bathrooms;

    return input;
  }

  getPreviewImageUrl() {
    const { image, form: { imageUrl } } = this.state;

    return image === null ? null : (getLocalImageUrl(image) || imageUrl);
  }

  isNew() {
    const { form } = this.state;
    return !form.id;
  }

  init() {
    const { params: { floor } } = this.props;
    let form = {};
    if (floor) {
      const {
        id, name, size, bedrooms, bathrooms, isBelowGrade, imageUrl
      } = floor;
      form = {
        id, name, size, bedrooms, bathrooms, isBelowGrade, imageUrl
      };
    }

    this.setState({
      form, image: undefined, saving: false, error: null
    });
  }

  deleteImage() {
    this.setState({ image: null });
  }

  render() {
    const { form, saving, error } = this.state;
    const { opened } = this.props;
    const hasValues = hasFormValues(form);
    const isNew = this.isNew();
    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);
    const imageUrl = this.getPreviewImageUrl();

    return (
      <Modal isOpen={opened}>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="floorName">{i18n.t('name', i18nOpts)}</Label>
            <Input
              type="text"
              name="name"
              id="floorName"
              value={form.name || ''}
              onChange={this.onTextChange}
              invalid={isInputInvalid(error, 'name')}
            />
            <InputError name="name" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="image">{i18n.t('image', i18nOpts)}</Label>
            {imageUrl && (
              <div className="preview-image-container">
                <ImageViewer url={imageUrl} size="h-100" onDelete={this.deleteImage} />
              </div>
            )}
            <Input
              type="file"
              name="image"
              id="image"
              accept="image/*"
              onChange={this.onImageChange}
              invalid={isInputInvalid(error, 'image')}
            />
            <InputError name="image" error={error} />
          </FormGroup>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="size">{i18n.t('size', i18nOpts)}</Label>
                <Input
                  type="number"
                  name="size"
                  id="size"
                  value={form.size || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'size')}
                />
                <InputError name="size" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form className="mb-3">
            <Col>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="isBelowGrade"
                    id="isBelowGrade"
                    value={!parseBoolean(form.isBelowGrade)}
                    checked={parseBoolean(form.isBelowGrade)}
                    onChange={this.onTextChange}
                  />
                  {i18n.t('isBelowGrade', i18nOpts)}
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="bedrooms">{i18n.t('bedrooms', i18nOpts)}</Label>
                <Input
                  type="number"
                  name="bedrooms"
                  id="bedrooms"
                  value={form.bedrooms || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'bedrooms')}
                />
                <InputError name="bedrooms" error={error} />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="bathrooms">{i18n.t('bathrooms', i18nOpts)}</Label>
                <Input
                  type="number"
                  name="bathrooms"
                  id="bathrooms"
                  value={form.bathrooms || ''}
                  onChange={this.onTextChange}
                  invalid={isInputInvalid(error, 'bathrooms')}
                />
                <InputError name="bathrooms" error={error} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving || !hasValues}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onClose} disabled={saving}>
            {i18n.t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

FloorFormModal.propTypes = {
  floorableId: PropTypes.number.isRequired,
  floorableType: PropTypes.oneOf(Object.values(FloorableTypes)).isRequired
};

FloorFormModal.defaultProps = {};

export default connect((store) => ({
  floor: store.floors.floor,
  opened: store.modals[ModalNames.FLOOR_FORM]?.opened || false,
  params: store.modals[ModalNames.FLOOR_FORM]?.params || {}
}), {
  saveFloor,
  setReloadFloors
})(FloorFormModal);
