import React from 'react';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';
import { TbSend } from 'react-icons/tb';

import ModalService from '../../../../services/ModalService';
import { ModalNames } from '../../../../constants';
import useIsVerifiedEnabled from '../../../../hooks/useIsVerifiedEnabled';
import useIsReserveNowEnabled from '../../../../hooks/useIsReserveNowEnabled';

const i18nOpts = { scope: 'layout.admin.topBar.inviteButton.index' };

const InviteButton = () => {
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const isReserveNowEnabled = useIsReserveNowEnabled();

  if (!isVerifiedEnabled && !isReserveNowEnabled) return null;

  const onClick = () => {
    ModalService.open(ModalNames.SALES_INVITATION);
  };

  return (
    <Button onClick={onClick} color="secondary" outline className="text-nowrap">
      <TbSend size="18" className="mr-2" />
      {i18n.t('title', i18nOpts)}
    </Button>
  );
};

InviteButton.propTypes = {};

InviteButton.defaultProps = {};

export default InviteButton;
