import React from 'react';

const OfferInvitedIcon = ({ ...props }) => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M24.0904 3.95996C20.2954 3.95996 17.1934 6.86396 16.8634 10.56H4.29039C2.83839 10.56 1.65039 11.748 1.65039 13.2V26.4C1.65039 27.852 2.83839 29.04 4.29039 29.04H26.0704C27.5224 29.04 28.7104 27.852 28.7104 26.4V16.83C30.3274 15.51 31.3504 13.497 31.3504 11.22C31.3504 7.22696 28.0834 3.95996 24.0904 3.95996ZM4.29039 11.88H16.8634C17.0614 14.157 18.3154 16.104 20.1304 17.292L15.6094 21.186C15.3454 21.417 14.9824 21.417 14.7514 21.186L4.02639 11.913C4.09239 11.88 4.19139 11.88 4.29039 11.88ZM2.97039 26.4V13.2C2.97039 13.068 3.00339 12.936 3.03639 12.804L11.1214 19.8L3.03639 26.796C3.00339 26.664 2.97039 26.532 2.97039 26.4ZM26.0704 27.72H4.29039C4.19139 27.72 4.09239 27.72 4.02639 27.687L12.1444 20.658L13.8934 22.176C14.2564 22.506 14.7184 22.671 15.1804 22.671C15.6424 22.671 16.1044 22.506 16.4674 22.176L18.2164 20.658L26.3344 27.687C26.2684 27.72 26.1694 27.72 26.0704 27.72ZM27.3904 26.4C27.3904 26.532 27.3574 26.664 27.3244 26.796L19.2394 19.8L21.3844 17.952C22.2094 18.282 23.1334 18.48 24.0904 18.48C25.2784 18.48 26.4004 18.183 27.3904 17.688V26.4ZM24.0904 17.16C20.8234 17.16 18.1504 14.487 18.1504 11.22C18.1504 7.95296 20.8234 5.27996 24.0904 5.27996C27.3574 5.27996 30.0304 7.95296 30.0304 11.22C30.0304 14.487 27.3574 17.16 24.0904 17.16Z" fill="#00A572" />
    <path d="M24.2555 7.91994V14.1899M24.2555 7.91994L21.7805 10.2323M24.2555 7.91994L26.7305 10.2323" stroke="#00A572" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

OfferInvitedIcon.propTypes = {};

OfferInvitedIcon.defaultProps = {};

export default OfferInvitedIcon;
