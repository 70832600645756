import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setModelPalettes } from '../../../../../store/actions/modelActions';
import { isAdmin } from '../../../../../utils/authUtils';
import { listModelPalettes } from '../../../../../store/actions/modelPaletteActions';
import ListItem from './ListItem';
import AddDropDown from './AddDropDown';

const Palettes = ({
  model, palettes, modelPalettes, ...props
}) => {
  useEffect(() => {
    loadPalettes();
  }, []);

  const loadPalettes = () => {
    const variables = { filter: { modelId: model.id } };

    props.listModelPalettes(variables);
  };

  if (!model) return null;

  return (
    <div>
      { isAdmin() && (
      <div className="d-flex justify-content-end mb-3">
        <AddDropDown />
      </div>
      )}

      {
        modelPalettes.map((mp) => (
          <ListItem key={`model-palette-${mp.id}`} modelPalette={mp} />
        ))
      }
    </div>
  );
};

export default connect((store) => ({
  model: store.models.model,
  modelPalettes: store.modelPalettes.modelPalettes,
}), {
  setModelPalettes,
  listModelPalettes
})(Palettes);
