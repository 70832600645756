export function fakeValue(base) {
  return Math.round(Math.random() * base);
}

export function addSampleVisitors(items, base = 100, fieldName = 'visitorsCount') {
  let visitorsCount = 0;
  return items.map((i) => {
    visitorsCount += fakeValue(base);
    return { ...i, [fieldName]: visitorsCount };
  }).reverse();
}

function getRandomCoordinates(radius, uniform) {
  // Generate two random numbers
  let a = Math.random();
  let b = Math.random();

  // Flip for more uniformity.
  if (uniform) {
    if (b < a) {
      const c = b;
      b = a;
      a = c;
    }
  }

  // It's all triangles.
  return [
    b * radius * Math.cos(2 * Math.PI * a / b),
    b * radius * Math.sin(2 * Math.PI * a / b)
  ];
}

export function generateSampleLocation(latitude, longitude, radiusInMeters) {
  const randomCoordinates = getRandomCoordinates(radiusInMeters, true);

  // Earths radius in meters via WGS 84 model.
  const earth = 6378137;

  // Offsets in meters.
  const northOffset = randomCoordinates[0];
  const eastOffset = randomCoordinates[1];

  // Offset coordinates in radians.
  const offsetLatitude = northOffset / earth;
  const offsetLongitude = eastOffset / (earth * Math.cos(Math.PI * (latitude / 180)));

  // Offset position in decimal degrees.
  return {
    lat: latitude + (offsetLatitude * (180 / Math.PI)),
    lng: longitude + (offsetLongitude * (180 / Math.PI))
  };
}
