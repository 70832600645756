import {
  DELETE_BUILDING_MODEL_PACKAGE,
  LIST_BUILDING_MODEL_PACKAGES,
  RELOAD_BUILDING_MODEL_PACKAGES,
  SAVE_BUILDING_MODEL_PACKAGE
} from './types';
import {
  createBuildingModelPackageQuery, deleteBuildingModelPackageQuery,
  listBuildingModelPackagesQuery,
  updateBuildingModelPackageQuery
} from '../../graphql';
import api from '../../api';

export function saveBuildingModelPackage(variables) {
  const query = variables?.input?.id ? updateBuildingModelPackageQuery
    : createBuildingModelPackageQuery;
  return {
    type: SAVE_BUILDING_MODEL_PACKAGE,
    payload: api.graphql(query, variables)
  };
}

export function listBuildingModelPackages(variables) {
  return {
    type: LIST_BUILDING_MODEL_PACKAGES,
    payload: api.graphql(listBuildingModelPackagesQuery, variables)
  };
}

export function deleteBuildingModelPackage(variables) {
  return {
    type: DELETE_BUILDING_MODEL_PACKAGE,
    payload: api.graphql(deleteBuildingModelPackageQuery, variables)
  };
}

export function reloadBuildingModelPackages(reload) {
  return {
    type: RELOAD_BUILDING_MODEL_PACKAGES,
    payload: { reload }
  };
}
