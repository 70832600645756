import { useSelector } from 'react-redux';

const useIsReserveNowEnabled = () => {
  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany
  }));
  return !!currentCompany?.reserveNowSettings?.enabled;
};

export default useIsReserveNowEnabled;
