import React from 'react';

const UserUnverifiedIcon = ({ ...props }) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.60841 4.10829L16.3917 15.8916M16.3917 4.10829L4.60836 15.8916M18.8334 9.99996C18.8334 14.6023 15.1025 18.3333 10.5001 18.3333C5.89771 18.3333 2.16675 14.6023 2.16675 9.99996C2.16675 5.39759 5.89771 1.66663 10.5001 1.66663C15.1025 1.66663 18.8334 5.39759 18.8334 9.99996Z" stroke="#F79009" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

UserUnverifiedIcon.propTypes = {};

UserUnverifiedIcon.defaultProps = {};

export default UserUnverifiedIcon;
