import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import {
  FormGroup,
  Input,
  Label,
  Form,
  Row,
  Col,
  Spinner,
  Button,
} from 'reactstrap';
import { toast } from 'react-toastify';

import { saveModel } from '../../../../../store/actions/modelActions';
import { isAdmin } from '../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.models.show.lotWorks.index' };

class LotWorks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.populateForm();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onSubmit(event) {
    event.preventDefault();

    const { props } = this;
    const { model } = this.props;
    const { form } = this.state;

    const input = {
      id: model.id,
      lotWorksSettings: {
        frontage: {
          min: parseFloat(form.frontageMin) || null,
          max: parseFloat(form.frontageMax) || null,
        },
        buildpocket: {
          min: parseFloat(form.buildpocketMin) || null,
          max: parseFloat(form.buildpocketMax) || null,
        },
        minLotPrice: parseFloat(form.minLotPrice) || null
      },
    };

    const variables = { input };
    props.saveModel(variables).then(() => {
      toast.info(i18n.t('messages.saved', i18nOpts));
    });
  }

  populateForm() {
    const { model } = this.props;

    const { lotWorksSettings: { frontage, buildpocket, minLotPrice } } = model;

    const frontageMin = frontage?.min;
    const frontageMax = frontage?.max;
    const buildpocketMin = buildpocket?.min;
    const buildpocketMax = buildpocket?.max;
    const form = {
      frontageMin,
      frontageMax,
      buildpocketMin,
      buildpocketMax,
      minLotPrice
    };
    this.setState({ form });
  }

  render() {
    const { model, saving } = this.props;
    const { form } = this.state;

    if (!model) return null;

    const isEditable = isAdmin();

    return (
      <Form onSubmit={this.onSubmit}>
        <FormGroup>
          <Label for="frontageMin">{i18n.t('frontage', i18nOpts)}</Label>

          <Row>
            <Col xs="6" sm="4" md="3" lg="2">
              <Input
                type="number"
                name="frontageMin"
                id="frontageMin"
                value={form.frontageMin || ''}
                placeholder={i18n.t('min', i18nOpts)}
                onChange={this.onTextChange}
                disabled={!isEditable}
              />
            </Col>
            <Col xs="6" sm="4" md="3" lg="2">
              <Input
                type="number"
                name="frontageMax"
                id="frontageMax"
                value={form.frontageMax || ''}
                placeholder={i18n.t('max', i18nOpts)}
                onChange={this.onTextChange}
                disabled={!isEditable}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup className="mb-5">

          <Row>
            <Col xs="6" sm="4" md="3" lg="2" className="mt-sm-3 mt-md-0">
              <Label for="buildpocketMin">{i18n.t('buildpocket', i18nOpts)}</Label>
              <Input
                type="number"
                name="buildpocketMin"
                id="buildpocketMin"
                value={form.buildpocketMin || ''}
                placeholder={i18n.t('min', i18nOpts)}
                onChange={this.onTextChange}
                disabled={!isEditable}
              />
            </Col>
            <Col xs="6" sm="4" md="3" lg="2" className="d-flex align-items-end">
              <div>
                <Input
                  type="number"
                  name="buildpocketMax"
                  id="buildpocketMax"
                  value={form.buildpocketMax || ''}
                  placeholder={i18n.t('max', i18nOpts)}
                  onChange={this.onTextChange}
                  disabled={!isEditable}
                />
              </div>
            </Col>
            <Col xs="6" sm="4" md="3" lg="2" className="mt-3 mt-sm-3 mt-md-0">
              <Label for="minLotPrice">{i18n.t('minLotPrice', i18nOpts)}</Label>
              <Input
                type="number"
                name="minLotPrice"
                id="minLotPrice"
                value={form.minLotPrice || ''}
                placeholder={i18n.t('minLotPrice', i18nOpts)}
                onChange={this.onTextChange}
                disabled={!isEditable}
              />
            </Col>
          </Row>
        </FormGroup>

        {isEditable && (
          <Button color="primary" className="mr-3" disabled={saving}>
            {saving && <Spinner size="sm" className="mr-2" />}
            {i18n.t('buttons.save')}
          </Button>
        )}
      </Form>
    );
  }
}

export default connect(
  (store) => ({
    model: store.models.model,
    saving: store.models.saveModel.loading,
  }),
  {
    saveModel,
  }
)(LotWorks);
