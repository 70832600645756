import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.integrations.sendGrid.contactLists.contacts.list.index' };

const ContactList = ({ contacts }) => (
  <Table responsive bordered>
    <thead>
      <tr>
        <th>{i18n.t('name', i18nOpts)}</th>
        <th>{i18n.t('email', i18nOpts)}</th>
        <th>{i18n.t('addedDate', i18nOpts)}</th>
        <th>{i18n.t('updatedDate', i18nOpts)}</th>
        <th style={{ width: 50 }}>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      {
        contacts.map((contact) => (
          <ListItem key={`contact-${contact.id}`} contact={contact} />
        ))
      }
    </tbody>
  </Table>
);

ContactList.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.any).isRequired
};

ContactList.defaultProps = {};

export default ContactList;
