import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import v from 'voca';
import moment from 'moment';

import ActionsMenu from '../../../../../../../global/ActionsMenu';
import ModalService from '../../../../../../../../services/ModalService';
import { DateFormats, ModalNames } from '../../../../../../../../constants';
import { sendGridDeleteContactFromList } from '../../../../../../../../store/actions/sendGridActions';
import { getError } from '../../../../../../../../utils/requestUtils';

const ListItem = ({
  contact, contactList, currentCompany, ...props
}) => {
  const onEdit = () => {
    ModalService.open(ModalNames.SENDGRID_CONTACT_FORM, { contact });
  };

  const onDelete = (contactId) => {
    const variables = {
      id: contactId,
      listId: contactList.id,
      companyId: currentCompany.id
    };
    props.sendGridDeleteContactFromList(variables)
      .catch((e) => {
        const error = getError(e);
        if (v.isString(error)) toast.error(error);
      });
  };

  return (
    <tr>
      <td>{`${contact.firstName} ${contact.lastName}`}</td>
      <td>{contact.email}</td>
      <td>{moment(contact.createdAt).format(DateFormats.MMM_DD_YYYY)}</td>
      <td>{moment(contact.updatedAt).format(DateFormats.MMM_DD_YYYY)}</td>
      <td className="text-right">
        <ActionsMenu
          item={contact}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  contact: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default connect((store) => ({
  contactList: store.sendGrid.contactList,
  currentCompany: store.companies.currentCompany
}), {
  sendGridDeleteContactFromList
})(ListItem);
