import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  Button, ModalFooter, Spinner, ModalBody, Row, Col
} from 'reactstrap';
import { connect } from 'react-redux';
import v from 'voca';

import QuickPossessionForm from '../../../QuickPossessionForm';
import useSaveQuickPossession from '../../../QuickPossessionForm/hooks/useSaveQuickPossession';
import {
  setInvitedHome
} from '../../../../../store/actions/salesInvitationActions';
import { Steps } from '../constants';
import useInvitationStartType from '../hooks/useInvitationStartType';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.newHome.index' };

const NewHome = ({ invitedHome, ...props }) => {
  const {
    form, setForm, submitting, error,
    onTextChange, saveQuickPossessionAsync, loadQuickPossessionAsync,
    isValidForm
  } = useSaveQuickPossession();
  const [mounted, setMounted] = useState(false);

  const { isFromCustomer } = useInvitationStartType();

  const onContinue = () => {
    const nextStep = isFromCustomer ? Steps.CONFIRM_INVITATION : Steps.CUSTOMER_SELECTION_TYPE;

    saveQuickPossessionAsync()
      .then((quickPossession) => {
        props.setInvitedHome({ ...quickPossession, __typename: 'QuickPossession' });
        props.onContinue(nextStep);
      })
      .catch(() => {});
  };

  const loadForm = () => {
    const isNew = v.isBlank(invitedHome?.id);
    if (isNew) {
      setForm((prevForm) => ({
        ...prevForm,
        overrideFloors: true,
        notEditableOverrideFloors: true
      }));
      setMounted(true);
      return;
    }

    loadQuickPossessionAsync(invitedHome.id)
      .then(() => setMounted(true));
  };

  useEffect(() => {
    loadForm();
  }, []);

  return (
    <>
      <ModalBody className="pt-0">
        <div className="font-18 font-weight-600 mb-1">{i18n.t('title', i18nOpts)}</div>
        <div className="font-14 mb-4">{i18n.t('description', i18nOpts)}</div>

        {mounted ? (
          <QuickPossessionForm
            form={form}
            setForm={setForm}
            error={error}
            onTextChange={onTextChange}
          />
        ) : (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        )}
      </ModalBody>

      <ModalFooter>
        <Row className="flex-even">
          <Col xs="12" sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }}>
            <Button color="primary" onClick={onContinue} block disabled={submitting || !isValidForm()}>
              {submitting && (<Spinner size="sm" className="mr-2" />)}
              {i18n.t('buttons.continue')}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </>
  );
};

NewHome.propTypes = {
  onContinue: PropTypes.func
};

NewHome.defaultProps = {
  onContinue: () => {}
};

export default connect((store) => ({
  invitedHome: store.salesInvitation.invitedHome
}), {
  setInvitedHome
})(NewHome);
