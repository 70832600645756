import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';

import { getQuickPossessionAvailabilityName } from '../../../../../../../../../utils/enumUtils';

const Availability = ({ quickPossession }) => (
  <div>
    <span className={`badge text-white badge-${v.slugify(quickPossession.availability)}`}>
      {getQuickPossessionAvailabilityName(quickPossession.availability)}
    </span>
  </div>
);

Availability.propTypes = {
  quickPossession: PropTypes.objectOf(PropTypes.any).isRequired
};

Availability.defaultProps = {};

export default Availability;
