import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { MdFileDownload } from 'react-icons/md';
import v from 'voca';
import { useLocation } from '@reach/router';

import { formatCurrency } from '../../../../../../../utils/currencyUtils';
import { getHomeInfo } from '../../../../../../../utils/homeUtils';
import OfferSteps from '../../../../../../global/home/OfferSteps';
import { PreApprovalVerificationMethods } from '../../../../../../../constants';
import { getOfferRealtorText } from '../../../../../../../utils/offerUtils';
import CustomerFinancialAmount from '../../../../../../global/customer/CustomerFinancialAmount';
import DealActivityDate from '../../../../../../global/home/DealActivityDate';
import CustomerCoBuyersCountBadge
  from '../../../../../../global/customer/CustomerCoBuyersCountBadge';
import useIsVerifiedEnabled from '../../../../../../../hooks/useIsVerifiedEnabled';

const i18nOpts = { scope: 'components.admin.sales.components.dealList.list.listItem.index' };

const ListItem = ({ home }) => {
  const { offer, contact: customer } = home;
  const { communityProjectName, name, address } = getHomeInfo(home);
  const { preApproval } = customer.user || {};
  const isVerifiedEnabled = useIsVerifiedEnabled();
  const location = useLocation();
  const currentPage = location.pathname.includes('/deals') ? 'deals' : 'contracts';

  const isPreApprovalManual = preApproval?.verificationMethod
    === PreApprovalVerificationMethods.MANUAL;

  return (
    <tr>
      <td className="fixed-column clickable-column p-0">
        <Link
          to={`/admin/customers/${customer.id}?from=${currentPage}`}
          className="col-gray-600 p-3 d-flex flex-column h-100 justify-content-center"
        >
          <div className="d-flex align-items-start gap-2">
            <span className="font-weight-600">
              {customer.name}
            </span>

            <CustomerCoBuyersCountBadge count={customer.coBuyersCount} />
          </div>

          {!v.isBlank(customer.email) && (
            <div className="col-gray-600">{customer.email}</div>
          )}
        </Link>
      </td>
      <td className="align-middle">
        <div className="d-flex align-items-center text-nowrap">
          <div>
            <div className="font-weight-500 col-gray-900">{formatCurrency(offer.offerPrice)}</div>

            {isPreApprovalManual && (
              <div className="text-nowrap">{i18n.t('preApproval', i18nOpts)}</div>
            )}
          </div>
          {isPreApprovalManual && (
            <div className="pl-3">
              <a
                href={preApproval.documentUrl}
                target="_blank"
                rel="noreferrer"
                className="d-flex col-secondary-1"
                title={i18n.t('buttons.download')}
              >
                <MdFileDownload size="1.3rem" />
              </a>
            </div>
          )}
        </div>
      </td>
      <td>
        <OfferSteps home={home} clickableSteps />
      </td>
      <td className="align-middle">
        <div className="font-weight-600 col-gray-800">{name}</div>
        {!v.isBlank(address) && (
          <div>
            <small className="col-gray-900">{address}</small>
          </div>
        )}
      </td>
      <td className="align-middle">
        <div className="font-weight-500 col-gray-900">{communityProjectName}</div>
      </td>
      {isVerifiedEnabled && (
        <td className="align-middle">
          <CustomerFinancialAmount customer={customer} className="font-weight-500 col-gray-900" />
        </td>
      )}
      <td className="align-middle">
        {getOfferRealtorText(offer)}
      </td>
      <td className="align-middle">
        <DealActivityDate home={home} className="text-nowrap" />
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired,
};

ListItem.defaultProps = {};

export default ListItem;
