import {
  CLEAN_TEAMS, GET_TEAM, SAVE_TEAM, DELETE_TEAM, LIST_TEAMS, SET_RELOAD_TEAMS
} from './types';
import api from '../../api';
import {
  createTeamQuery, deleteTeamQuery,
  getTeamQuery,
  listTeamsQuery,
  updateTeamQuery
} from '../../graphql';

export function listTeams(variables = {}) {
  return {
    type: LIST_TEAMS,
    payload: api.graphql(listTeamsQuery, variables)
  };
}

export function getTeam(id) {
  return {
    type: GET_TEAM,
    payload: api.graphql(getTeamQuery, { id })
  };
}

export function cleanTeams(extraProps = {}) {
  return {
    type: CLEAN_TEAMS,
    payload: Promise.resolve(extraProps)
  };
}

export function saveTeam(variables = {}) {
  const query = variables.input.id ? updateTeamQuery : createTeamQuery;
  return {
    type: SAVE_TEAM,
    payload: api.graphql(query, variables)
  };
}

export function deleteTeam(id) {
  return {
    type: DELETE_TEAM,
    payload: api.graphql(deleteTeamQuery, { id })
  };
}

export function setReloadTeams(reload) {
  return {
    type: SET_RELOAD_TEAMS,
    payload: { reload }
  };
}
