import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  FormGroup, Input
} from 'reactstrap';
import v from 'voca';

import CollapsibleFilterGroup from '../../../../../common/CollapsibleFilterGroup';
import ResetButton from '../components/ResetButton';
import DollarIcon from '../../../../../../assets/icons/DollarIcon';
import useIsVerifiedEnabled from '../../../../../../hooks/useIsVerifiedEnabled';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.annualIncomeFilter.index' };

const AnnualIncomeFilter = ({ filters, onChange }) => {
  const { annualIncome } = filters;
  const isVerifiedEnabled = useIsVerifiedEnabled();

  const onTextChange = (event) => {
    const { name, value } = event.target;
    const newAnnualIncome = { ...(annualIncome || {}) };
    newAnnualIncome[name] = value;
    onChange({ annualIncome: newAnnualIncome });
  };

  const onReset = () => {
    onChange({ annualIncome: null });
  };

  const hasValue = !v.isBlank(annualIncome?.from) || !v.isBlank(annualIncome?.to);
  const filtersCount = hasValue ? 1 : 0;

  if (!isVerifiedEnabled) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <div className="d-flex align-items-center gap-3">
        <div>
          <FormGroup className="mb-0">
            <div className="input-icon-prepend">
              <DollarIcon />
            </div>
            <Input
              type="number"
              name="from"
              id="annualIncomeFrom"
              value={annualIncome?.from || ''}
              onChange={onTextChange}
            />
          </FormGroup>
        </div>
        <div>
          -
        </div>
        <div>
          <FormGroup className="mb-0">
            <div className="input-icon-prepend">
              <DollarIcon />
            </div>
            <Input
              type="number"
              name="to"
              id="annualIncomeTo"
              value={annualIncome?.to || ''}
              onChange={onTextChange}
            />
          </FormGroup>
        </div>
      </div>

      {hasValue && (
        <div className="mt-2">
          <ResetButton onClick={onReset} />
        </div>
      )}
    </CollapsibleFilterGroup>
  );
};

AnnualIncomeFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

AnnualIncomeFilter.defaultProps = {
  onChange: () => {}
};

export default AnnualIncomeFilter;
