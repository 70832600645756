import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FunnelChart as BaseFunnelChart } from 'chartjs-chart-funnel';

const FunnelChart = ({ data, options }) => {
  const chartRef = useRef(null);

  const initChart = () => {
    const chartElement = document.getElementById('funnelChart');

    return new BaseFunnelChart(chartElement.getContext('2d'), { data, options });
  };

  useEffect(() => {
    chartRef.current = initChart();

    return () => {
      chartRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;

    chartRef.current.data = data;
    chartRef.current.options = options;
    chartRef.current.update();
  }, [data, options]);

  return (
    <canvas id="funnelChart" />
  );
};

FunnelChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.objectOf(PropTypes.any)
};

FunnelChart.defaultProps = {
  options: {}
};

export default FunnelChart;
