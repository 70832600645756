import React from 'react';
import PropTypes from 'prop-types';
import v from 'voca';
import i18n from 'i18n-js';
import classNames from 'classnames';
import { connect } from 'react-redux';

import styles from './ListItem.module.scss';
import { formatCurrency, isValidAmount } from '../../../../../../../utils/currencyUtils';
import { setInvitedHome } from '../../../../../../../store/actions/salesInvitationActions';
import {
  compareUnionTypeObjectTypename,
} from '../../../../../../../utils/objectUtils';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.existingHome.index.listView.listItem.index' };

const ListItem = ({
  home, selectedHome, onSelect: onAfterSelect, ...props
}) => {
  const isSelected = selectedHome?.id === home.id;

  const isUnit = compareUnionTypeObjectTypename(home, 'Unit');
  let name;
  let communityProjectName;
  let price;
  let address;
  if (isUnit) {
    const { project } = home.buildingModel;
    name = home.name;
    communityProjectName = project.name;
    price = home.price;
    address = project.address;
  } else {
    name = home.model.name;
    communityProjectName = home.community.name;
    price = home.totalPrice;
    address = home.address;
  }

  const onSelect = () => {
    props.setInvitedHome(home);
    setTimeout(onAfterSelect, 500);
  };

  return (
    <div
      className={classNames(styles.card, { [styles.active]: isSelected })}
      onClick={onSelect}
      role="button"
      aria-hidden
    >
      <div className="d-flex justify-content-between gap-3">
        <div>
          <div className="font-weight-600 font-18">{name}</div>
          <div><small>{isUnit ? i18n.t('project', i18nOpts) : i18n.t('community', i18nOpts)}</small></div>
          <div className="font-weight-500">{communityProjectName}</div>
        </div>
        <div className="text-right">
          <div className="font-weight-600 font-18">
            {isValidAmount(price) ? (
              <span>{formatCurrency(price)}</span>
            ) : (
              <>&nbsp;</>
            )}
          </div>

          {!v.isBlank(address) && (
            <>
              <div><small>{i18n.t('address', i18nOpts)}</small></div>
              <div className="font-weight-500">{address}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired,
  onAfterSelect: PropTypes.func
};

ListItem.defaultProps = {
  onAfterSelect: () => {}
};

export default connect((store) => ({
  selectedHome: store.salesInvitation.invitedHome
}), {
  setInvitedHome
})(ListItem);
