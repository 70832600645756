import React, { useState } from 'react';
import {
  Col, Row, TabContent, TabPane
} from 'reactstrap';
import PropTypes from 'prop-types';

import NavTabs from './NavTabs';
import { Tabs } from './utils';
import ReserveNowWorkflow from './ReserveNowWorkflow';
import VerifiedWorkflow from './VerifiedWorkflow';

const Workflows = ({ form, onTextChange }) => {
  const [activeTab, setActiveTab] = useState(Tabs.RESERVE_NOW_WORKFLOW);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Row>
      <Col md={12} lg={9} xl={8}>
        <Row>
          <Col sm={5}>
            <NavTabs activeTab={activeTab} onChange={onTabChange} />
          </Col>
          <Col sm={7}>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={Tabs.RESERVE_NOW_WORKFLOW}>
                <ReserveNowWorkflow form={form} onTextChange={onTextChange} />
              </TabPane>
              <TabPane tabId={Tabs.VERIFIED_WORKFLOW}>
                <VerifiedWorkflow form={form} onTextChange={onTextChange} />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Workflows.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func
};

Workflows.defaultProps = {
  onTextChange: () => {}
};

export default Workflows;
