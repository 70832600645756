import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { MortgageProviderAssignableTypes } from '../../../constants';
import { fetchMortgageProvidersAssignmentsAsync } from './utils';
import { isAdmin } from '../../../utils/authUtils';
import AddMortgageProviderDropdown from './AddMortgageProviderDropdown';
import MortgageProviderAssignmentsList from './MortgageProviderAssignmentsList';

const MortgageProvidersManagement = ({ assignableId, assignableType }) => {
  const [mortgageProviderAssignments, setMortgageProviderAssignments] = useState([]);

  const loadMortgageProviderAssignments = () => {
    fetchMortgageProvidersAssignmentsAsync(assignableId, assignableType)
      .then((items) => setMortgageProviderAssignments(items))
      .catch(() => {});
  };

  const reloadMortgageProviderAssignments = () => {
    loadMortgageProviderAssignments();
  };

  useEffect(() => {
    loadMortgageProviderAssignments();
  }, []);

  return (
    <div>
      {isAdmin() && (
        <div className="mb-4 text-right">
          <AddMortgageProviderDropdown
            assignableId={assignableId}
            assignableType={assignableType}
            mortgageProviderAssignments={mortgageProviderAssignments}
            onAfterAdd={reloadMortgageProviderAssignments}
          />
        </div>
      )}

      <MortgageProviderAssignmentsList
        mortgageProviderAssignments={mortgageProviderAssignments}
        onAfterDelete={reloadMortgageProviderAssignments}
      />
    </div>
  );
};

MortgageProvidersManagement.propTypes = {
  assignableId: PropTypes.number.isRequired,
  assignableType: PropTypes.oneOf(Object.values(MortgageProviderAssignableTypes)).isRequired
};

MortgageProvidersManagement.defaultProps = {};

export default MortgageProvidersManagement;
