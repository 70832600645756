import React, { useEffect, useMemo } from 'react';
import {
  Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, FormGroup
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';

import { ModalNames, TeamUserPermissions } from '../../../../../../constants';
import ModalService from '../../../../../../services/ModalService';
import { saveTeamUser, setReloadTeamUsers } from '../../../../../../store/actions/teamUserActions';
import { getError } from '../../../../../../utils/requestUtils';
import useForm from '../../../../../../hooks/useForm';
import useFetchCompanyUsers from '../../../../../../hooks/useFetchCompanyUsers';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { getTeamUserPermissionName } from '../../../../../../utils/enumUtils';
import { stripToEmpty } from '../../../../../../utils/stringUtils';
import { hasFormChanges, isFormValid } from '../../../../../../utils/formUtils';

const i18nOpts = { scope: 'components.admin.teams.show.users.formModal.index' };

function onClose() {
  ModalService.close(ModalNames.TEAM_USER_FORM);
}

const FormModal = ({
  opened, params, currentCompany, team, teamUsers, ...props
}) => {
  const {
    submitting, setSubmitting, form, setForm, error, setError, onTextChange,
    initialForm, setInitialForm
  } = useForm();
  const { companyUsers } = useFetchCompanyUsers(team.company.id);
  const { teamUser } = params;
  const isNew = !teamUser;

  const onSave = () => {
    setSubmitting(true);

    const input = {
      permission: stripToEmpty(form.permission),
    };

    if (isNew) {
      input.teamId = team.id;
      input.userId = parseInt(form.userId, 10) || 0;
    } else input.id = teamUser.id;

    props.saveTeamUser({ input })
      .then(() => {
        props.setReloadTeamUsers(true);
        onClose();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSubmitting(false));
  };

  const loadTeamUser = () => {
    const formData = teamUser ? { ...teamUser, userId: teamUser.user.id } : {};
    setInitialForm(formData);
    setForm(formData);
    setError(null);
  };

  const getAvailableCompanyUsers = () => {
    if (!isNew) return companyUsers;

    const addedUserIds = teamUsers.map((ug) => ug.user.id);
    return companyUsers.filter((companyUser) => !addedUserIds.includes(companyUser.id));
  };

  const availableCompanyUsers = useMemo(getAvailableCompanyUsers,
    [companyUsers, teamUsers, isNew]);

  useEffect(() => {
    if (!opened) return;

    loadTeamUser();
  }, [opened]);

  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);
  const hasChanges = hasFormChanges(initialForm, form);
  const isValid = isFormValid(form, ['userId', 'permission']);

  return (
    <Modal isOpen={opened} size="sm">
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label for="userId">{i18n.t('userId', i18nOpts)}</Label>
          <Input
            type="select"
            name="userId"
            id="userId"
            value={form.userId || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'userId')}
            disabled={!isNew}
          >
            <option value="">{i18n.t('select.select')}</option>
            {
              availableCompanyUsers.map((user) => (
                <option key={`user-option-${user.id}`} value={user.id}>
                  {user.name}
                </option>
              ))
            }
          </Input>
          <InputError error={error} name="userId" />
        </FormGroup>

        <FormGroup>
          <Label for="permission">{i18n.t('permission', i18nOpts)}</Label>
          <Input
            type="select"
            name="permission"
            id="permission"
            value={form.permission || ''}
            onChange={onTextChange}
          >
            <option value="">{i18n.t('select.select')}</option>
            {
              Object.values(TeamUserPermissions).map((tup) => (
                <option key={`team-user-permission-option-${tup}`} value={tup}>
                  {getTeamUserPermissionName(tup)}
                </option>
              ))
            }
          </Input>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSave} disabled={submitting || !hasChanges || !isValid}>
          {submitting && (<Spinner size="sm" className="mr-2" />)}
          {isNew ? i18n.t('buttons.add') : i18n.t('buttons.update')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={submitting}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.TEAM_USER_FORM]?.opened || false,
  params: store.modals[ModalNames.TEAM_USER_FORM]?.params || {},
  currentCompany: store.companies.currentCompany,
  team: store.teams.team,
  teamUsers: store.teamUsers.teamUsers,
}), {
  saveTeamUser,
  setReloadTeamUsers
})(FormModal);
