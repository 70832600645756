import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import {
  Button, Col, FormGroup, Input, Label, Row, Spinner, Form
} from 'reactstrap';
import { connect } from 'react-redux';
import v from 'voca';
import { Helmet } from 'react-helmet';

import { parseToFloat } from '../../../../utils/parserUtils';
import { hasFormValues } from '../../../../utils/formUtils';
import { saveBuildingModel, getBuildingModel, deleteBuildingModel } from '../../../../store/actions/buildingModelActions';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { stripToEmpty } from '../../../../utils/stringUtils';
import DeleteButton from '../../../global/DeleteButton';
import { fetchProjectsAsync, goToBuildingModels } from './utils';
import { CKEditor } from '../../../ui';
import { getLocalImageUrl } from '../../../../utils/imageUtils';
import ImageViewer from '../../../common/ImageViewer';
import './styles.scss';

const i18nOpts = { scope: 'components.admin.buildingModels.form.index' };

function onCancel() {
  goToBuildingModels();
}

const BuildingModelForm = ({ id, currentCompany, ...props }) => {
  const [form, setForm] = useState({});
  const [image, setImage] = useState(undefined);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSave = (event) => {
    event.preventDefault();

    const input = getFormInput();

    setSaving(true);

    const variables = { input, image };
    props.saveBuildingModel(variables)
      .then(() => goToBuildingModels())
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      })
      .finally(() => setSaving(false));
  };

  const onImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const onDelete = () => {
    props.deleteBuildingModel(parseInt(id, 10))
      .then(() => goToBuildingModels())
      .catch((e) => toast.error(e.message));
  };

  const getFormInput = () => {
    const input = {
      name: stripToEmpty(form.name),
      longDescription: stripToEmpty(form.longDescription),
      projectId: parseInt(form.projectId, 10) || 0
    };

    if (!isNew) input.id = form.id;

    const size = parseToFloat(form.size);
    if (!Number.isNaN(size) || size === null) input.size = size;

    const bedrooms = parseToFloat(form.bedrooms);
    if (!Number.isNaN(bedrooms) || bedrooms === null) input.bedrooms = bedrooms;

    const bathrooms = parseToFloat(form.bathrooms);
    if (!Number.isNaN(bathrooms) || bathrooms === null) input.bathrooms = bathrooms;

    return input;
  };

  const loadForm = () => {
    if (v.isBlank(id)) return;

    const variables = { id: parseInt(id, 10) };
    props.getBuildingModel(variables)
      .then(({ value: { data: { item } } }) => {
        setForm({
          id: item.id,
          name: item.name,
          size: item.size,
          bedrooms: item.bedrooms,
          bathrooms: item.bathrooms,
          projectId: item.project.id,
          longDescription: item.longDescription,
          imageUrl: item.imageUrl
        });
      })
      .catch(() => goToBuildingModels());
  };

  const loadProjects = () => {
    fetchProjectsAsync(currentCompany.id)
      .then((items) => setProjects(items))
      .catch(() => goToBuildingModels());
  };

  const getPreviewImageUrl = () => (image === null ? null
    : (getLocalImageUrl(image) || form?.imageUrl));

  const deleteImage = () => {
    setImage(null);
  };

  useEffect(() => {
    loadForm();
    loadProjects();
  }, []);

  const isNew = !form.id;

  const hasValues = hasFormValues(form);
  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);
  const imageUrl = getPreviewImageUrl();

  return (
    <div>
      <Helmet title={title} />

      <h2 className="mb-4">{title}</h2>

      <Form onSubmit={onSave}>
        {isNew && (
          <Row form>
            <Col lg="4" md="6" sm="8" xs="12">
              <FormGroup>
                <Label for="projectId">{i18n.t('projectId', i18nOpts)}</Label>
                <Input
                  type="select"
                  name="projectId"
                  id="projectId"
                  value={form.projectId || ''}
                  onChange={onTextChange}
                  invalid={isInputInvalid(error, 'projectId')}
                >
                  <option value="">{i18n.t('select.select')}</option>
                  {
                    projects.map((p) => (
                      <option key={`project-option-${p.id}`} value={p.id}>
                        {p.name}
                      </option>
                    ))
                  }
                </Input>
                <InputError error={error} name="projectId" />
              </FormGroup>
            </Col>
          </Row>
        )}

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="name">{i18n.t('name', i18nOpts)}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={form.name || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'name')}
              />
              <InputError name="name" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="4" md="6" sm="8" xs="12">
            <FormGroup>
              <Label for="image">{i18n.t('image', i18nOpts)}</Label>
              {imageUrl && (
                <div className="building-model-image-preview">
                  <ImageViewer url={imageUrl} size="h-100" onDelete={deleteImage} />
                </div>
              )}
              <Input
                type="file"
                name="image"
                id="image"
                accept="image/*"
                onChange={onImageChange}
                invalid={isInputInvalid(error, 'image')}
              />
              <InputError name="image" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="2" md="3" sm="4" xs="6">
            <FormGroup>
              <Label for="size">{i18n.t('size', i18nOpts)}</Label>
              <Input
                type="number"
                name="size"
                id="size"
                value={form.size || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'size')}
              />
              <InputError name="size" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="2" md="3" sm="4" xs="6">
            <FormGroup>
              <Label for="bedrooms">{i18n.t('bedrooms', i18nOpts)}</Label>
              <Input
                type="number"
                name="bedrooms"
                id="bedrooms"
                value={form.bedrooms || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'bedrooms')}
              />
              <InputError name="bedrooms" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col lg="2" md="3" sm="4" xs="6">
            <FormGroup>
              <Label for="bathrooms">{i18n.t('bathrooms', i18nOpts)}</Label>
              <Input
                type="number"
                name="bathrooms"
                id="bathrooms"
                value={form.bathrooms || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'bathrooms')}
              />
              <InputError name="bathrooms" error={error} />
            </FormGroup>
          </Col>
        </Row>

        <Row form className="mb-4">
          <Col>
            <FormGroup>
              <Label for="longDescription">{i18n.t('longDescription', i18nOpts)}</Label>
              <CKEditor
                data={form.longDescription || ''}
                name="longDescription"
                onTextChange={onTextChange}
              />
            </FormGroup>
          </Col>
        </Row>

        <Button type="submit" color="primary" className="mr-3" disabled={saving || !hasValues}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" className="mr-3" onClick={onCancel} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
        {!isNew && (
          <DeleteButton onDelete={onDelete} />
        )}
      </Form>
    </div>
  );
};

BuildingModelForm.propTypes = {};

BuildingModelForm.defaultProps = {};

export default connect((store) => ({
  buildingModel: store.buildingModels.buildingModel,
  currentCompany: store.companies.currentCompany
}), {
  saveBuildingModel,
  getBuildingModel,
  deleteBuildingModel
})(BuildingModelForm);
