import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import api from '../api';

function fetchModelsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListModels($filter: ModelFilterInput) {
      result: listModels(filter: $filter, pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `, variables)
    .then(({ data: { result: { items } } }) => items);
}

const useFetchModels = () => {
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);

  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany,
  }));

  useEffect(() => {
    setLoading(true);

    fetchModelsAsync(currentCompany.id)
      .then((items) => setModels(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { models, loading };
};

export default useFetchModels;
