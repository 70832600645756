import React from 'react';
import { Table } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import moment from 'moment';

import { formatCurrency } from '../../../../../../utils/currencyUtils';
import { DateFormats } from '../../../../../../constants';

const i18nOpts = { scope: 'components.admin.customers.financialReport.employersSection.attributesTable.index' };

function getLast2Paydates(primaryEmployerIncome, secondaryEmployerIncome) {
  const last2PaydatesA = primaryEmployerIncome.last2Paydates?.split(',') || [];
  const last2PaydatesB = secondaryEmployerIncome?.last2Paydates?.split(',') || [];

  const last2Paydates = [...last2PaydatesA, ...last2PaydatesB].map((date) => v.trim(date))
    .filter((date) => !v.isBlank(date)).map((date) => moment(date));

  const sortedLastPaydates = last2Paydates.sort((a, b) => b.diff(a));
  const formattedLastPaydates = sortedLastPaydates.map((m) => m.format(DateFormats.MM_DD_YYYY_2));
  return formattedLastPaydates.slice(0, 2);
}

const AttributesTable = ({ financialReport }) => {
  const {
    averageEmployerIncomeDeposit, primaryEmployerIncome,
    secondaryEmployerIncome, estimatedAnnualNetEmployerIncome
  } = financialReport;

  const frequencies = [];
  if (!v.isBlank(primaryEmployerIncome.frequency)) {
    frequencies.push(`${primaryEmployerIncome.frequency} (${i18n.t('primary', i18nOpts)})`);
  }
  if (secondaryEmployerIncome && !v.isBlank(secondaryEmployerIncome?.frequency)) {
    frequencies.push(`${secondaryEmployerIncome.frequency} (${i18n.t('secondary', i18nOpts)})`);
  }

  const last2Paydates = getLast2Paydates(primaryEmployerIncome, secondaryEmployerIncome);

  return (
    <Table bordered responsive className="mt-4">
      <thead>
        <tr>
          <th>{i18n.t('attribute', i18nOpts)}</th>
          <th>{i18n.t('value', i18nOpts)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{i18n.t('averageMonthlyDeposit', i18nOpts)}</td>
          <td>{formatCurrency(averageEmployerIncomeDeposit)}</td>
        </tr>
        <tr>
          <td>{i18n.t('frequency', i18nOpts)}</td>
          <td>{frequencies.join(', ')}</td>
        </tr>
        <tr>
          <td>{i18n.t('last2Paydates', i18nOpts)}</td>
          <td>{last2Paydates.join(', ')}</td>
        </tr>
        <tr>
          <td>{i18n.t('estimatedAnnualNet', i18nOpts)}</td>
          <td>{formatCurrency(estimatedAnnualNetEmployerIncome)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

AttributesTable.propTypes = {};

AttributesTable.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport
}))(AttributesTable);
