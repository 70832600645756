import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Floor from './Floor';
import FloorGroup from './FloorGroup';
import FloorOption from './FloorOption';
import './styles.scss';
import { toggleFloorOptions } from '../../../../utils/quickPossessionUtils';

const Floorplan = ({ form, onChange }) => {
  const {
    community, model, floorOptions = {}, editableFloorIds, editableFloorGroupIds
  } = form;
  const { floors } = model;
  const [activeFloor, setActiveFloor] = useState(null);
  const [activeFloorGroup, setActiveFloorGroup] = useState(null);

  useEffect(() => {
    setDefaultOptions();
  }, [model]);

  const onToggleFloor = (newActiveFloor) => {
    setActiveFloor(activeFloor === newActiveFloor ? null : newActiveFloor);
  };

  const onToggleFloorGroup = (newActiveFloorGroup) => {
    setActiveFloorGroup(activeFloorGroup === newActiveFloorGroup ? null : newActiveFloorGroup);
  };

  const onToggleFloorOption = (floorOption, floorGroupId, enableMultipleSelection) => {
    const newFloorOptions = toggleFloorOptions(floorOption, floorGroupId, enableMultipleSelection,
      floorOptions);
    onChange({ floorOptions: newFloorOptions });
  };

  const setDefaultOptions = () => {
    const defaultFloorOptions = {};

    floors.forEach((floor) => {
      floor.floorGroups.forEach((floorGroup) => {
        const defaultFound = floorGroup.floorOptions.find((f) => f.isDefault);
        if (defaultFound) defaultFloorOptions[floorGroup.id] = [defaultFound];
      });
    });
    onChange({ floorOptions: defaultFloorOptions });
  };

  const onToggleEditableFloorId = (floorId) => {
    const floor = floors.find((f) => f.id === floorId);
    const floorGroupIds = floor.floorGroups.map((fg) => fg.id);

    const newEditableFloorIds = _.xor(editableFloorIds, [floorId]);

    const isEditableFloor = newEditableFloorIds.includes(floorId);
    const newEditableFloorGroupIds = isEditableFloor
      ? [...new Set([...(editableFloorGroupIds || []), ...floorGroupIds])]
      : editableFloorGroupIds.filter((id) => !floorGroupIds.includes(id));

    onChange({
      editableFloorIds: newEditableFloorIds,
      editableFloorGroupIds: newEditableFloorGroupIds
    });
  };

  const onToggleEditableFloorGroupId = (floorGroupId) => {
    const newEditableFloorGroupIds = _.xor(editableFloorGroupIds, [floorGroupId]);

    onChange({
      editableFloorGroupIds: newEditableFloorGroupIds
    });
  };

  if (!floors) return null;

  return (
    <>
      { floors.map((floor) => (
        <Floor
          key={`floor-${floor.id}`}
          floor={floor}
          onToggle={() => onToggleFloor(floor.id)}
          activeFloor={activeFloor}
          editableFloorIds={editableFloorIds}
          onEditableToggle={onToggleEditableFloorId}
        >
          {
            floor.floorGroups.map((floorGroup) => (
              <FloorGroup
                floorGroup={floorGroup}
                onToggle={() => onToggleFloorGroup(floorGroup.id)}
                activeFloorGroup={activeFloorGroup}
                key={`floor-group-${floorGroup.id}`}
                editableFloorGroupIds={editableFloorGroupIds}
                onEditableToggle={onToggleEditableFloorGroupId}
              >
                <FloorOption
                  floorGroupId={floorGroup.id}
                  floorOptions={floorGroup.floorOptions}
                  enableMultipleSelection={floorGroup.enableMultipleSelection}
                  selectedFloorOptions={floorOptions}
                  toggleFloorOptions={onToggleFloorOption}
                  community={community}
                  model={model}
                />
              </FloorGroup>
            ))
          }
        </Floor>
      ))}
    </>
  );
};

Floorplan.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

Floorplan.defaultProps = {
  onChange: () => {}
};

export default Floorplan;
