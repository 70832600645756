import React from 'react';
import { connect } from 'react-redux';
import v from 'voca';
import i18n from 'i18n-js';
import moment from 'moment';
import classnames from 'classnames';
import { FiCopy } from 'react-icons/fi';
import { MdLaunch } from 'react-icons/md';

import { Colors, DateFormats } from '../../../../../../constants';
import { isRecently } from '../../../../../../utils/dateUtils';
import {
  isMasterAdmin,
  isTecSupport
} from '../../../../../../utils/authUtils';
import CustomerVerifiedStatusIcon from '../../../../../global/customer/CustomerVerifiedStatusIcon';
import OwnlyLogo from '../../../../../../assets/icons/OwnlyLogo';
import { getValidCustomerCompaniesCount } from '../../../utils';
import CopyLink from '../../../../../common/CopyLink';
import useCoBuyers from '../../../../../../hooks/useCoBuyers';
import CustomerFormSubmissionIndicator from '../../../../../global/customer/CustomerFormSubmissionIndicator';

const i18nOpts = { scope: 'components.admin.customers.show.overview.personalSection.index' };

const PersonalSection = ({ customer, currentCompany }) => {
  const { companyContact } = customer;
  const { createdAt, lastBuiltDate, lastActivityDate: lastActivityAt } = companyContact || {};
  const lastActivityDate = lastBuiltDate || lastActivityAt;
  const customerCompaniesCount = getValidCustomerCompaniesCount(customer, currentCompany.id);
  const { acceptedCoBuyer } = useCoBuyers();
  const { companyContact: { airMilesRewardNumber } } = customer;
  const [emailUsername, emailDomain] = customer.email.split('@');

  return (
    <div className="mb-2 d-flex flex-column gap-3">
      <div>
        <div className="font-weight-500">{i18n.t('name', i18nOpts)}</div>
        <div className="d-flex align-items-center gap-2">
          <div
            className="col-gray-700 font-18 font-weight-600 text-decoration-underline"
          >
            {customer.name}
          </div>

          <CustomerVerifiedStatusIcon customer={customer} size="20" />
          <CustomerFormSubmissionIndicator customer={customer} />
          { customerCompaniesCount > 0 && (isMasterAdmin() || isTecSupport()) && (
            <span>
              <OwnlyLogo />
            </span>
          ) }
        </div>
      </div>

      <div>
        <div className="font-weight-500">{i18n.t('email', i18nOpts)}</div>
        <div className="d-flex gap-2 align-items-center">
          <a href={`mailto:${customer.email}`} className="d-flex font-weight-500 font-16 text-primary" style={{ minWidth: 0 }}>
            <span className="text-truncate d-inline-block">{emailUsername}</span>
            <span>{`@${emailDomain}`}</span>
          </a>

          <CopyLink text={customer.email}>
            <FiCopy color={Colors.PRIMARY_700} size={18} role="button" aria-hidden className="float-left" />
          </CopyLink>
        </div>
      </div>

      {!v.isBlank(customer.phone) && (
        <div>
          <div className="font-weight-500">{i18n.t('phone', i18nOpts)}</div>
          <div className="d-flex gap-2 align-items-center">
            <a href={`tel:${customer.phone}`} className="text-primary font-16 font-weight-500">
              {customer.phone}
            </a>

            <CopyLink text={customer.phone}>
              <FiCopy color={Colors.PRIMARY_700} size={18} role="button" aria-hidden className="float-left" />
            </CopyLink>
          </div>
        </div>
      )}

      {acceptedCoBuyer && (
        <div>
          <div className="font-weight-500">{i18n.t('coBuyer', i18nOpts)}</div>
          <div className="d-flex gap-2 align-items-center">
            <span className="font-16 font-weight-500">
              {acceptedCoBuyer.contact.name}
            </span>

            <a href={`/admin/customers/${acceptedCoBuyer.contact.id}`} target="_blank" rel="noreferrer">
              <MdLaunch color={Colors.PRIMARY_700} size={18} className="float-left" />
            </a>
          </div>
        </div>
      )}

      {createdAt && (
        <div>
          <div className="font-weight-500">{i18n.t('dateCreated', i18nOpts)}</div>
          <div className={classnames('col-gray-700 font-16 font-weight-500', { 'text-success': isRecently(createdAt) })}>
            {moment(createdAt).format(DateFormats.YYYY_MM_DD)}
          </div>
        </div>
      )}
      {lastActivityDate && (
        <div>
          <div className="font-weight-500">{i18n.t('lastActive', i18nOpts)}</div>
          <div className={classnames('col-gray-700 font-16 font-weight-500', { 'text-success': isRecently(lastActivityDate) })}>
            {moment(lastActivityDate).format(DateFormats.YYYY_MM_DD)}
          </div>
        </div>
      )}

      {!v.isBlank(airMilesRewardNumber) && (
        <div>
          <div className="font-weight-500">{i18n.t('airMilesRewardNumber', i18nOpts)}</div>
          <div className="col-gray-700 font-16 font-weight-500">
            {airMilesRewardNumber}
          </div>
        </div>
      )}
    </div>
  );
};

PersonalSection.propTypes = {};

PersonalSection.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  currentCompany: store.companies.currentCompany
}))(PersonalSection);
