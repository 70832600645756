import React, { useState } from 'react';

import styles from '../styles';

const Column = ({
  showIf = true, children, style, value, label, ...props
}) => {
  const [initialValue, setInitialValue] = useState(value);
  if (!showIf) {
    return <div style={styles.empty} />;
  }

  const onChange = (event) => {
    const { value: eventValue } = event.target;
    setInitialValue(eventValue);
    props.onChange(eventValue);
  };

  return (
    <div style={{ ...styles.column, ...style }}>
      {children
        || (
        <input
          style={{ ...styles.input, ...styles.integerInput }}
          value={initialValue}
          onChange={(e) => onChange(e)}
        />
        )}
      {label
        && <div style={styles.inputHelper}>{label}</div>}
    </div>
  );
};

export default Column;
