import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import i18n from 'i18n-js';

import { Tabs } from '../utils';

const i18nOpts = { scope: 'components.admin.teams.show.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <Nav tabs>
    <NavItem>
      <NavLink
        onClick={() => onChange(Tabs.OVERVIEW)}
        active={activeTab === Tabs.OVERVIEW}
      >
        {i18n.t('overview', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        onClick={() => onChange(Tabs.USERS)}
        active={activeTab === Tabs.USERS}
      >
        {i18n.t('users', i18nOpts)}
      </NavLink>
    </NavItem>
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
