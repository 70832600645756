import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';
import { Card } from 'reactstrap';

import IpapiService from '../../../../../../../services/IpapiService';
import Map from './Map';
import { getLocationInfoFormatted } from '../../../../../../../utils/locationUtils';

const i18nOpts = { scope: 'components.admin.customers.show.details.activity.locationCard.index' };

const LocationCard = ({ customer }) => {
  const [position, setPosition] = useState(null);
  const [address, setAddress] = useState(null);

  const loadIpLocation = () => {
    if (!customer.ip) return;

    IpapiService.getIpLocation(customer.ip)
      .then((location) => {
        if (v.isBlank(location?.latitude) || v.isBlank(location?.longitude)) return;

        setPosition({ lat: location.latitude, lng: location.longitude });
        setAddress(getLocationInfoFormatted(location));
      }).catch((error) => {
        console.error('There was an error obtaining the customer\'s location: ', error);
      });
  };

  useEffect(() => {
    loadIpLocation();
  }, []);

  if (!position) return null;

  return (
    <Card body>
      <h4>{i18n.t('title', i18nOpts)}</h4>

      <div className="mb-3 mt-1">
        <div className="word-break-break-all">
          {i18n.t('ip', { ...i18nOpts, ip: customer.ip })}
        </div>
        {!v.isBlank(address) && (
          <div>{address}</div>
        )}
      </div>

      <Map position={position} />
    </Card>
  );
};

LocationCard.propTypes = {};

LocationCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer
}))(LocationCard);
