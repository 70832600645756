import React from 'react';
import i18n from 'i18n-js';

const i18nOpts = { scope: 'components.admin.customers.show.details.deals.listHeader.index' };

const ListHeader = () => (
  <tr>
    <th>{i18n.t('model', i18nOpts)}</th>
    <th>{i18n.t('dealPrice', i18nOpts)}</th>
    <th className="text-center">{i18n.t('dealState', i18nOpts)}</th>
    <th>{i18n.t('communityProject', i18nOpts)}</th>
    <th>{i18n.t('realtor', i18nOpts)}</th>
    <th>{i18n.t('lastActive', i18nOpts)}</th>
  </tr>
);

ListHeader.propTypes = {};
ListHeader.defaultProps = {};

export default ListHeader;
