import React from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import AuthService from '../../../../services/AuthService';
import ProfileIcon from '../../../../assets/icons/ProfileIcon';

const i18nOpts = { scope: 'layout.admin.userMenuDropdown.index' };

const UserMenuDropdown = ({ currentUser }) => (
  <UncontrolledDropdown>
    <DropdownToggle tag="div" className="dropdown-toggle" role="button" aria-hidden>
      <ProfileIcon />
    </DropdownToggle>
    <DropdownMenu right className="user-dd">
      <span className="with-arrow">
        <span className="bg-white" />
      </span>
      <DropdownItem tag="div" onClick={() => {}}>
        <h4 className="mb-0">{currentUser.name}</h4>
        <small className="text-muted">{currentUser.email}</small>
      </DropdownItem>
      <DropdownItem onClick={() => navigate('/admin/myProfile')}>
        <i className="fa fa-user-circle mr-2 ml-1" />
        {i18n.t('myProfile', i18nOpts)}
      </DropdownItem>
      <DropdownItem onClick={() => AuthService.logout()}>
        <i className="fa fa-power-off mr-2 ml-1" />
        {i18n.t('logout', i18nOpts)}
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

UserMenuDropdown.propTypes = {};

UserMenuDropdown.defaultProps = {};

export default connect((store) => ({
  currentUser: store.users.currentUser,
}))(UserMenuDropdown);
