import React from 'react';
import { connect } from 'react-redux';
import v from 'voca';

import SavedHomes from './SavedHomes';
import useIsReserveNowEnabled from '../../../../../hooks/useIsReserveNowEnabled';
import StartedReservationFlow from './StartedReservationFlow';

const Observers = ({ unit }) => {
  if (!unit) return null;

  const { observers } = unit;
  const isReserveNowEnabled = useIsReserveNowEnabled();

  const savedHomes = [...observers];
  const reservationStarted = observers.filter((item) => !v.isBlank(item.reservationStartedAt));

  return (
    <div>
      <SavedHomes observers={savedHomes} />

      {isReserveNowEnabled && (
        <StartedReservationFlow observers={reservationStarted} />
      )}
    </div>
  );
};

Observers.propTypes = {};

Observers.defaultProps = {};

export default connect((store) => ({
  unit: store.units.unit
}), {})(Observers);
