import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import PropTypes from 'prop-types';

import {
  DEFAULT_BUYER_RESERVE_WORKFLOW,
  DEFAULT_RENTAL_RESERVE_WORKFLOW
} from '../../../../../../../../utils/companyUtils';
import WorkflowStep from './WorkflowStep';
import { ReserveStepTypes, WorkflowTypes } from '../../../../../../../../constants';
import ConfigFile from '../components/ConfigFile';

const ReserveNowWorkflow = ({ form, onTextChange }) => {
  const [workflowType, setWorkflowType] = useState(WorkflowTypes.BUYER_RESERVE);
  const [defaultWorkflow, setDefaultWorkflow] = useState(DEFAULT_BUYER_RESERVE_WORKFLOW);
  const [workflow, setWorkflow] = useState([]);

  // eslint-disable-next-line max-len
  const mergeAndEnableSteps = (defaultReserveNowWorkflow, companyWorkflow) => defaultReserveNowWorkflow.map((step) => {
    const stepFound = companyWorkflow.find((item) => item.type === step.type);
    if (stepFound) {
      if (step.type === ReserveStepTypes.OFFER && step.verifications?.length > 0) {
        const updatedVerifications = step.verifications.map((verification) => {
          const verificationFound = stepFound.verifications?.find(
            (v) => v.type === verification.type
          );
          if (verificationFound) return { ...verification, ...verificationFound, enabled: true };
          return verification;
        });
        return {
          ...step, ...stepFound, verifications: updatedVerifications, enabled: true
        };
      }
      return { ...step, ...stepFound, enabled: true };
    }
    return step;
  });

  const onChangeStepSetting = (stepSetting) => {
    const newWorkflow = workflow.map((item) => (
      item.type === stepSetting.type ? stepSetting : item
    ));
    setWorkflow(newWorkflow);
    onTextChange({ target: { name: 'reserveNowWorkflow', value: newWorkflow } });
  };

  const onUpdate = () => {
    onChangeStepSetting({});
  };

  const onUploadWorkflow = (newWorkflow) => {
    const updatedWorkflow = mergeAndEnableSteps(defaultWorkflow, newWorkflow);

    setWorkflow(updatedWorkflow);
    onTextChange({ target: { name: 'reserveNowWorkflow', value: updatedWorkflow } });
  };

  useEffect(() => {
    let companyWorkflow = form.buyerReserveWorkflow?.workflow || DEFAULT_BUYER_RESERVE_WORKFLOW;
    let defaultReserveNowWorkflow = DEFAULT_BUYER_RESERVE_WORKFLOW;
    if (form.enableHomeRental) {
      companyWorkflow = form.rentalReserveWorkflow?.workflow || DEFAULT_RENTAL_RESERVE_WORKFLOW;
      defaultReserveNowWorkflow = DEFAULT_RENTAL_RESERVE_WORKFLOW;
      setWorkflowType(WorkflowTypes.RENTAL_RESERVE);
      setDefaultWorkflow(defaultReserveNowWorkflow);
    }

    const newWorkflow = mergeAndEnableSteps(defaultReserveNowWorkflow, companyWorkflow);
    setWorkflow(newWorkflow);
  }, [form.enableHomeRental]);

  return (
    <div>
      <ReactSortable
        list={workflow}
        setList={setWorkflow}
        onEnd={onUpdate}
      >
        {workflow.map((step) => (
          <WorkflowStep key={`reserve-workflow-${step.type}`} step={step} onChangeStepSetting={onChangeStepSetting} />
        ))}
      </ReactSortable>

      <ConfigFile
        workflowType={workflowType}
        workflow={workflow}
        onUploadWorkflow={onUploadWorkflow}
      />
    </div>
  );
};

ReserveNowWorkflow.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onTextChange: PropTypes.func
};

ReserveNowWorkflow.defaultProps = {
  onTextChange: () => {}
};

export default ReserveNowWorkflow;
