import api from '../../api';
import {
  SENDGRID_LIST_CONTACT_LISTS,
  SENDGRID_CLEAN_CONTACT_LISTS,
  SENDGRID_GET_CONTACT_LIST,
  SENDGRID_SAVE_CONTACT_LIST,
  SENDGRID_DELETE_CONTACT_LIST,
  SENDGRID_SET_CONTACT_LIST,
  SENDGRID_SET_CONTACTS,
  SENDGRID_CLEAN_CONTACTS,
  SENDGRID_SAVE_CONTACT,
  SENDGRID_DELETE_CONTACT_FROM_LIST
} from './types';
import {
  sendGridListContactListsQuery,
  sendGridGetContactListQuery,
  sendGridCreateContactListQuery,
  sendGridUpdateContactListQuery,
  sendGridDeleteContactListQuery,
  sendGridSaveContactQuery,
  sendGridDeleteContactFromListQuery
} from '../../graphql';

export function sendGridListContactLists(variables = {}) {
  return {
    type: SENDGRID_LIST_CONTACT_LISTS,
    payload: api.graphql(sendGridListContactListsQuery, variables)
  };
}

export function sendGridCleanContactLists(extraProps = {}) {
  return {
    type: SENDGRID_CLEAN_CONTACT_LISTS,
    payload: Promise.resolve(extraProps)
  };
}

export function sendGridGetContactList(variables) {
  return {
    type: SENDGRID_GET_CONTACT_LIST,
    payload: api.graphql(sendGridGetContactListQuery, variables)
  };
}

export function sendGridSaveContactList(variables = {}) {
  const query = variables.input.id
    ? sendGridUpdateContactListQuery
    : sendGridCreateContactListQuery;
  return {
    type: SENDGRID_SAVE_CONTACT_LIST,
    payload: api.graphql(query, variables)
  };
}

export function sendGridDeleteContactList(variables = {}) {
  return {
    type: SENDGRID_DELETE_CONTACT_LIST,
    payload: api.graphql(sendGridDeleteContactListQuery, variables)
  };
}

export function sendGridSetContactList(contactList) {
  return {
    type: SENDGRID_SET_CONTACT_LIST,
    payload: { contactList }
  };
}

export function sendGridSetContacts(contacts) {
  return {
    type: SENDGRID_SET_CONTACTS,
    payload: { contacts }
  };
}

export function sendGridCleanContacts(extraProps = {}) {
  return {
    type: SENDGRID_CLEAN_CONTACTS,
    payload: Promise.resolve(extraProps)
  };
}

export function sendGridSaveContact(variables = {}) {
  return {
    type: SENDGRID_SAVE_CONTACT,
    payload: api.graphql(sendGridSaveContactQuery, variables)
  };
}

export function sendGridDeleteContactFromList(variables = {}) {
  return {
    type: SENDGRID_DELETE_CONTACT_FROM_LIST,
    payload: api.graphql(sendGridDeleteContactFromListQuery, variables)
  };
}
