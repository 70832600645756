import React, { Component } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import v from 'voca';

import { hasFormValues } from '../../../../utils/formUtils';
import { saveFloorGroup } from '../../../../store/actions/floorGroupActions';
import { ModalNames } from '../../../../constants';
import ModalService from '../../../../services/ModalService';
import { getError } from '../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../ui/InputError';
import { stripToEmpty } from '../../../../utils/stringUtils';
import { setReloadFloors } from '../../../../store/actions/floorActions';

const i18nOpts = { scope: 'components.global.floorsManager.floorGroupFormModal.index' };

const SELECTION_VALUES = Object.freeze({
  single: 'SINGLE',
  multiple: 'MULTIPLE'
});

const selectionOptions = [
  { value: SELECTION_VALUES.single, label: i18n.t('selectionOptions.single', i18nOpts) },
  { value: SELECTION_VALUES.multiple, label: i18n.t('selectionOptions.multiple', i18nOpts) }
];

function onClose() {
  ModalService.close(ModalNames.FLOOR_GROUP_FORM);
}

class FloorGroupFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      saving: false,
      selectionValue: SELECTION_VALUES.single,
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { opened } = this.props;
    if (!prevProps.opened && opened) this.init();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    if (name === 'selection') {
      this.setState({ selectionValue: value });
    } else {
      form[name] = value;
      this.setState({ form });
    }
  }

  onSave() {
    const { props } = this;
    const { form, selectionValue } = this.state;
    const { params: { floor } } = this.props;

    const input = {
      name: stripToEmpty(form.name),
      floorId: floor.id,
      enableMultipleSelection: selectionValue === SELECTION_VALUES.multiple
    };

    if (!this.isNew()) input.id = form.id;

    this.setState({ saving: true });

    const variables = { input };
    props.saveFloorGroup(variables)
      .then(() => {
        props.setReloadFloors(true);
        ModalService.close(ModalNames.FLOOR_GROUP_FORM);
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  init() {
    const { params: { floorGroup } } = this.props;
    let form = {};
    let selectionValue = SELECTION_VALUES.single;
    if (floorGroup) {
      form = { id: floorGroup.id, name: floorGroup.name };
      if (floorGroup.enableMultipleSelection) {
        selectionValue = SELECTION_VALUES.multiple;
      }
    }

    this.setState({
      form, saving: false, selectionValue, error: null
    });
  }

  isNew() {
    const { form } = this.state;
    return !form.id;
  }

  render() {
    const {
      form, saving, selectionValue, error
    } = this.state;
    const { opened } = this.props;
    const hasValues = hasFormValues(form);
    const isNew = this.isNew();
    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    return (
      <Modal isOpen={opened}>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="floorGroupName">{i18n.t('name', i18nOpts)}</Label>
            <Input
              type="text"
              name="name"
              id="floorGroupName"
              value={form.name || ''}
              onChange={this.onTextChange}
              invalid={isInputInvalid(error, 'name')}
            />
            <InputError name="name" error={error} />
          </FormGroup>
          <FormGroup>
            <Label for="selection">{i18n.t('selection', i18nOpts)}</Label>
            <Input
              type="select"
              name="selection"
              id="selection"
              value={selectionValue}
              onChange={this.onTextChange}
              invalid={isInputInvalid(error, 'selection')}
            >
              { selectionOptions.map((s) => (
                <option key={`selection-option-${s.value}`} value={s.value}>
                  {s.label}
                </option>
              ))}
            </Input>
            <InputError name="selection" error={error} />
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving || !hasValues}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onClose} disabled={saving}>
            {i18n.t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect((store) => ({
  opened: store.modals[ModalNames.FLOOR_GROUP_FORM]?.opened || false,
  params: store.modals[ModalNames.FLOOR_GROUP_FORM]?.params || {},
}), {
  saveFloorGroup,
  setReloadFloors
})(FloorGroupFormModal);
