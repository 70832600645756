import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import v from 'voca';
import moment from 'moment';
import i18n from 'i18n-js';

import styles from './CustomerSignUpDate.module.scss';
import { DateFormats } from '../../../../constants';

const i18nOpts = { scope: 'components.global.home.customerSignUpDate.index' };

const CustomerSignUpDate = ({ customer }) => {
  const { viewedAt } = customer;
  const createdAt = customer.companyContact?.createdAt;

  if (v.isBlank(createdAt)) return null;

  const formattedDate = moment(createdAt).format(DateFormats.MMM_DD_YYYY);

  return (
    <span className={classNames('text-nowrap')}>
      {formattedDate}
      {v.isBlank(viewedAt) && (
        <span className={styles.viewIndicator} title={i18n.t('new', i18nOpts)} />
      )}
    </span>
  );
};

CustomerSignUpDate.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

CustomerSignUpDate.defaultProps = {};

export default CustomerSignUpDate;
