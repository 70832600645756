import React from 'react';
import { Button } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import FormModal from './FormModal';
import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';
import List from './List';
import { canManageTeamMembers } from '../../../../../utils/teamUtils';

const i18nOpts = { scope: 'components.admin.teams.show.users.index' };

const Users = ({ team }) => {
  const { userPermission } = team;

  const onAddUser = () => {
    ModalService.open(ModalNames.TEAM_USER_FORM);
  };

  return (
    <div>
      {(canManageTeamMembers(userPermission)) && (
        <div className="text-right mb-3">
          <Button color="secondary" outline onClick={onAddUser}>
            {i18n.t('buttons.add', i18nOpts)}
          </Button>
        </div>
      )}

      <List />

      <FormModal />
    </div>
  );
};

export default connect((store) => ({
  team: store.teams.team
}))(Users);
