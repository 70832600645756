import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, DropdownToggle, DropdownMenu,
  DropdownItem, Dropdown, Card, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { MdContentCopy } from 'react-icons/md';

import { ModalNames, Statuses } from '../../../../../constants';
import ModalService from '../../../../../services/ModalService';
import api from '../../../../../api';
import SocialShare from '../../../../global/SocialShare';

const i18nOpts = { scope: 'components.admin.models.show.directLinkModal.index' };

function onClose() {
  ModalService.close(ModalNames.MODEL_DIRECT_LINK_MODAL);
}

function onCopy() {
  toast.info(i18n.t('messages.copied', i18nOpts));
  onClose();
}

function fetchCommunitiesAsync(modelId, companyId, isPreview = false) {
  const variables = {
    filter: { companyId, modelId, status: isPreview ? null : Statuses.PUBLISHED }
  };

  return api.graphql(`
    query ListCommunities($filter: CommunityFilterInput) {
      result: listCommunities(filter: $filter) {
        items {
          id
          name
          hashId
        }
      }
    }
  `, variables)
    .then(({ data: { result: { items } } }) => Promise.resolve(items));
}

class DirectLinkModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      communities: [],
      isDropdownOpen: false,
      selectedCommunity: null,
      loading: false,
      isCheckedReserveNow: false
    };

    this.onToggleDropdown = this.onToggleDropdown.bind(this);
    this.onToggleCommunity = this.onToggleCommunity.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { opened } = this.props;
    if (prevProps.opened !== opened && opened) this.init();
  }

  onToggleDropdown() {
    this.setState(({ isDropdownOpen }) => ({ isDropdownOpen: !isDropdownOpen }));
  }

  onToggleCommunity(selectedCommunity) {
    this.setState({ selectedCommunity });
  }

  loadCommunities() {
    const { model, params: { isPreview }, currentCompany } = this.props;

    this.setState({ loading: true });
    fetchCommunitiesAsync(model.id, currentCompany.id, isPreview)
      .then((communities) => {
        this.setState({ communities, selectedCommunity: communities[0] });
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  init() {
    this.setState({ selectedCommunity: null, isDropdownOpen: false });
    this.loadCommunities();
  }

  buildModelUrl(isCheckedReserveNow) {
    const { selectedCommunity } = this.state;
    const { model, currentCompany, params: { isPreview } } = this.props;

    if (!selectedCommunity && !isPreview) return null;

    const domain = currentCompany.domains[0];
    const communityPath = selectedCommunity ? `/community/${selectedCommunity.hashId}` : '';
    const previewPath = isPreview ? '/preview' : '';
    let url = `${domain}${previewPath}${communityPath}/model/${model.hashId}`;

    if (isCheckedReserveNow) url += '/reserve';

    return url;
  }

  onReserveNowChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({ isCheckedReserveNow: isChecked });
  }

  render() {
    const {
      model, opened, params: { isPreview }, currentCompany
    } = this.props;
    const {
      isDropdownOpen, communities, selectedCommunity, loading, isCheckedReserveNow
    } = this.state;

    if (!model) return null;

    const url = this.buildModelUrl(isCheckedReserveNow);
    const title = isPreview ? i18n.t('previewTitle', i18nOpts) : i18n.t('title', i18nOpts);
    const socialItemName = `${model?.name} in ${selectedCommunity?.name}`;

    return (
      <Modal isOpen={opened}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {loading ? (
            <div className="text-center my-5"><Spinner size="lg" /></div>
          ) : (
            <div>
              <div className="mb-1">
                <div className="mb-1">
                  {i18n.t('selectCommunity', i18nOpts)}
                </div>
                <Dropdown isOpen={isDropdownOpen} toggle={this.onToggleDropdown}>
                  <DropdownToggle color="outline-secondary" caret disabled={!communities.length}>
                    {selectedCommunity?.name ?? i18n.t('noCommunities', i18nOpts)}
                  </DropdownToggle>
                  <DropdownMenu>
                    {
                      communities.map((c) => (
                        <DropdownItem
                          key={`published-community-${c.id}`}
                          onClick={() => this.onToggleCommunity(c)}
                        >
                          {c.name}
                        </DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </Dropdown>
              </div>

              {(selectedCommunity || isPreview) && (
                <div className="mt-3">
                  <div className="text-uppercase mb-1">{model.name}</div>
                  <Card body className="mb-4 px-3 py-2">
                    <a href={url} target="_blank" rel="noreferrer" style={{ wordBreak: 'break-all' }}>
                      {url}
                    </a>
                  </Card>
                </div>
              )}

              {currentCompany.reserveNowSettings.enabled && selectedCommunity && !isPreview && (
                <div className="ml-2">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="generateReserveNowLink"
                        id="generateReserveNowLink"
                        checked={isCheckedReserveNow}
                        onChange={this.onReserveNowChange}
                      />
                      {i18n.t('reserveNowCheckbox', i18nOpts)}
                    </Label>
                  </FormGroup>
                </div>
              )}
            </div>
          )}
          {!isPreview && selectedCommunity && model && (
            <SocialShare url={url} itemName={socialItemName} />
          )}
        </ModalBody>
        <ModalFooter>
          {(selectedCommunity || isPreview) && (
            <CopyToClipboard text={url} onCopy={onCopy} className="mr-3">
              <Button outline color="primary" className="text-uppercase">
                <MdContentCopy size="1rem" className="mr-2 align-middle" />
                {i18n.t('buttons.copy', i18nOpts)}
              </Button>
            </CopyToClipboard>
          )}
          <Button outline color="secondary" onClick={onClose}>
            {i18n.t('buttons.close')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  model: store.models.model,
  opened: store.modals[ModalNames.MODEL_DIRECT_LINK_MODAL]?.opened || false,
  params: store.modals[ModalNames.MODEL_DIRECT_LINK_MODAL]?.params || {}
}), {})(DirectLinkModal);
