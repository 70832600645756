import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import PackagesDropdown from './PackagesDropdown';
import { listBuildingModelPackages, reloadBuildingModelPackages } from '../../../../../store/actions/buildingModelPackagesActions';
import ListItem from './ListItem';
import { isAdmin } from '../../../../../utils/authUtils';
import BuildingModelPackageFormModal from './BuildingModelPackageFormModal';

const Packages = ({
  buildingModel, buildingModelPackages, reload, ...props
}) => {
  useEffect(() => {
    loadBuildingModelPackages();
  }, []);

  useEffect(() => {
    if (!reload) return;

    loadBuildingModelPackages();
  }, [reload]);

  const loadBuildingModelPackages = () => {
    const filter = { buildingModelId: buildingModel?.id };
    const variables = { filter };

    return props.listBuildingModelPackages(variables)
      .finally(() => props.reloadBuildingModelPackages(false));
  };

  return (
    <>
      {isAdmin() && (
        <div className="d-flex justify-content-end mb-3">
          <PackagesDropdown />
        </div>
      )}

      { buildingModelPackages.map((buildingModelPackage) => (
        <ListItem key={`building-model-package-${buildingModelPackage.id}`} buildingModelPackage={buildingModelPackage} />
      ))}

      <BuildingModelPackageFormModal />
    </>
  );
};

export default connect((store) => ({
  buildingModel: store.buildingModels.buildingModel,
  buildingModelPackages: store.buildingModelPackages.buildingModelPackages,
  reload: store.buildingModelPackages.reload
}), {
  listBuildingModelPackages,
  reloadBuildingModelPackages
})(Packages);
