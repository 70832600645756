import React, { useEffect, useState } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, FormText
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';
import Switch from 'rc-switch';
import _ from 'lodash';

import { ModalNames, WebhookEvents } from '../../../../../constants';
import ModalService from '../../../../../services/ModalService';
import { hasFormValues } from '../../../../../utils/formUtils';
import { getError } from '../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../ui/InputError';
import { saveWebhook } from '../../../../../store/actions/webhookActions';

const i18nOpts = { scope: 'components.admin.integrations.webhooks.formModal.index' };

function onClose() {
  ModalService.close(ModalNames.WEBHOOK_FORM);
}

const FormModal = ({
  opened, params, saving, currentCompany, ...props
}) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState(null);
  const isNew = !form.id;

  const onSubmit = () => {
    const input = {
      ...form
    };

    if (isNew) input.companyId = currentCompany.id;

    props.saveWebhook({ input })
      .then(() => {
        ModalService.close(ModalNames.WEBHOOK_FORM);
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      });
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSwitchChange = (name) => {
    setForm((prevForm) => ({ ...prevForm, [name]: !prevForm[name] }));
  };

  const onEventChange = (event) => {
    const { value } = event.target;

    setForm((prevForm) => {
      const events = _.xor(prevForm.events || [], [value]);
      return { ...prevForm, events };
    });
  };

  const initForm = () => {
    const { webhook } = params;

    if (webhook) {
      const {
        id, hookUrl, events, enabled
      } = webhook;
      setForm({
        id, hookUrl, events, enabled
      });
    } else setForm({ enabled: true });

    setError(null);
  };

  useEffect(() => {
    if (opened) initForm();
  }, [opened]);

  const hasValues = hasFormValues(form);
  const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);
  const eventTypes = Object.values(WebhookEvents);

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label for="hookUrl">{i18n.t('hookUrl', i18nOpts)}</Label>
          <Input
            type="text"
            name="hookUrl"
            id="hookUrl"
            value={form.hookUrl || ''}
            placeholder={i18n.t('hookUrlPlaceholder', i18nOpts)}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'hookUrl')}
          />
          <InputError name="hookUrl" error={error} />
        </FormGroup>

        <div className="mb-4">
          <Label for="events" className="mb-1">{i18n.t('events', i18nOpts)}</Label>

          {
            eventTypes.map((event) => (
              <FormGroup check className="mb-2 ml-4" key={`event-${event}`}>
                <Label check>
                  <Input type="checkbox" value={event} checked={form.events?.includes(event) || false} onChange={onEventChange} />
                  <code>{event}</code>
                </Label>
                <FormText color="muted" className="mt-0">{i18n.t(`eventHelps.${event}`, i18nOpts)}</FormText>
              </FormGroup>
            ))
          }
        </div>

        <FormGroup>
          <div className="d-inline-block">
            <span
              onClick={() => onSwitchChange('enabled')}
              role="button"
              aria-hidden
              className="d-flex align-items-center"
            >
              <Switch checked={form.enabled || false} />
              <span className="ml-2">{i18n.t('enabled', i18nOpts)}</span>
            </span>
          </div>
        </FormGroup>

      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSubmit} disabled={saving || !hasValues}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.WEBHOOK_FORM]?.opened || false,
  params: store.modals[ModalNames.WEBHOOK_FORM]?.params || {},
  saving: store.webhooks.saveWebhook.loading || false,
  currentCompany: store.companies.currentCompany
}), {
  saveWebhook
})(FormModal);
