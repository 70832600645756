import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../../utils/authUtils';

export function hasCustomerIdentity(customer) {
  const { companyContact, isIdVerified } = customer;
  const isIdVerifiedAndGranted = isIdVerified && companyContact.verifiedIdGranted;

  const isAdmin = isMasterAdmin() || isCompanyAdmin() || isSalesLead();

  return isAdmin && isIdVerifiedAndGranted;
}
