import React, { Component } from 'react';
import {
  FormGroup, Label, Input, Button, Spinner, Modal, ModalHeader, ModalBody,
  ModalFooter,
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import v from 'voca';
import PropTypes from 'prop-types';

import { hasFormValues } from '../../../../../../utils/formUtils';
import { ModalNames } from '../../../../../../constants';
import { saveBuildingFloor } from '../../../../../../store/actions/buildingFloorActions';
import ModalService from '../../../../../../services/ModalService';
import { getError } from '../../../../../../utils/requestUtils';
import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import { stripToEmpty } from '../../../../../../utils/stringUtils';

const i18nOpts = { scope: 'components.admin.projectBuildings.show.buildingFloors.buildingFloorFormModal.index' };

function onClose() {
  ModalService.close(ModalNames.BUILDING_FLOOR_FORM);
}

class BuildingFloorFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      image: undefined,
      saving: false,
      error: null
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { opened } = this.props;
    if (prevProps.opened !== opened && opened) this.init();
  }

  onTextChange(event) {
    const { form } = this.state;
    const { name, value } = event.target;

    form[name] = value;
    this.setState({ form });
  }

  onImageChange(event) {
    const image = event.target.files[0];
    this.setState({ image });
  }

  onSave() {
    const { props } = this;
    const { image } = this.state;
    const input = this.getFormInput();

    this.setState({ saving: true });

    const variables = { input, image };
    props.saveBuildingFloor(variables)
      .then(() => {
        ModalService.close(ModalNames.BUILDING_FLOOR_FORM);
      })
      .catch((e) => {
        const error = getError(e);
        this.setState({ error });
        if (v.isString(error)) toast.error(error);
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  }

  getFormInput() {
    const { projectBuilding } = this.props;
    const { form } = this.state;
    const { id, name, description } = form;

    const input = {
      name: stripToEmpty(name),
      projectBuildingId: projectBuilding.id,
      description
    };

    if (id) input.id = id;

    return input;
  }

  init() {
    const { params: { buildingFloor } } = this.props;
    let form = {};
    if (buildingFloor) {
      const { id, name, description } = buildingFloor;
      form = { id, name, description };
    }

    this.setState({
      form, image: undefined, saving: false, error: null
    });
  }

  isNew() {
    const { form } = this.state;
    return !form.id;
  }

  render() {
    const { form, saving, error } = this.state;
    const { opened } = this.props;
    const hasValues = hasFormValues(form);
    const isNew = this.isNew();
    const title = isNew ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);

    return (
      <Modal isOpen={opened}>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label for="buildingFloorName">{i18n.t('name', i18nOpts)}</Label>
            <Input
              type="text"
              name="name"
              id="buildingFloorName"
              value={form.name || ''}
              onChange={this.onTextChange}
              invalid={isInputInvalid(error, 'name')}
            />
            <InputError name="name" error={error} />
          </FormGroup>

          <FormGroup>
            <Label for="buildingFloorDescription">{i18n.t('description', i18nOpts)}</Label>
            <Input
              type="text"
              name="description"
              id="buildingFloorDescription"
              value={form.description || ''}
              onChange={this.onTextChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="image">{i18n.t('image', i18nOpts)}</Label>
            <Input
              type="file"
              name="image"
              id="image"
              accept="image/*"
              onChange={this.onImageChange}
            />
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="mr-3" onClick={this.onSave} disabled={saving || !hasValues}>
            {saving && (<Spinner size="sm" className="mr-2" />)}
            {i18n.t('buttons.save')}
          </Button>
          <Button outline color="secondary" onClick={onClose} disabled={saving}>
            {i18n.t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

BuildingFloorFormModal.propTypes = {
  projectBuilding: PropTypes.objectOf(PropTypes.any).isRequired
};

BuildingFloorFormModal.defaultProps = {};

export default connect((store) => ({
  params: store.modals[ModalNames.BUILDING_FLOOR_FORM]?.params || {},
  opened: store.modals[ModalNames.BUILDING_FLOOR_FORM]?.opened || false
}), {
  saveBuildingFloor
})(BuildingFloorFormModal);
