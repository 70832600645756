import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { getBuildingFloors, setReloadBuildingFloorUnits } from '../../../../../store/actions/buildingFloorActions';
import BuildingFloor from './BuildingFloor';
import { isAdmin } from '../../../../../utils/authUtils';
import ModalService from '../../../../../services/ModalService';
import { ModalNames } from '../../../../../constants';
import BuildingFloorFormModal from './BuildingFloorFormModal';
import './styles.scss';
import api from '../../../../../api';

const i18nOpts = { scope: 'components.admin.projectBuildings.show.buildingFloors.index' };

function fetchUnitsAsync(projectBuildingId) {
  const variables = { filter: { projectBuildingId } };

  return api.graphql(`
    query ListUnits($filter: UnitFilterInput) {
      items: listUnits(filter: $filter) {
        id
        name
        floor
        price
        availability
        floorLocation
        view
        buildingModel {
          id
          name
          imageUrl
          bedrooms
          bathrooms
        }
        buildingFloor {
          id
        }
      }
    }
  `, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

function onOpenModal() {
  ModalService.open(ModalNames.BUILDING_FLOOR_FORM);
}

const BuildingFloors = ({
  projectBuilding, buildingFloors, reloadUnits, ...props
}) => {
  const [units, setUnits] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!reloadUnits) return;

    loadData();
  }, [reloadUnits]);

  const loadData = () => {
    const promises = [];

    promises.push(loadBuildingFloors());
    promises.push(loadUnits());

    Promise.all(promises)
      .catch((e) => toast.error(e.message))
      .finally(() => {
        props.setReloadBuildingFloorUnits(false);
      });
  };

  const loadBuildingFloors = () => {
    const filter = { projectBuildingId: projectBuilding.id };
    const variables = { filter };

    return props.getBuildingFloors(variables);
  };

  const loadUnits = () => fetchUnitsAsync(projectBuilding.id)
    .then((items) => {
      setUnits(items);
      return Promise.resolve();
    });

  return (
    <div>
      <div id="building-floors-container">
        <Row>
          {
            buildingFloors.map((bf) => (
              <Col key={`building-floor-${bf.id}`} xs="12">
                <BuildingFloor
                  buildingFloor={bf}
                  units={units}
                />
              </Col>
            ))
          }
        </Row>

        {isAdmin() && (
        <div className="text-right">
          <Button color="primary" onClick={onOpenModal}>
            <i className="fas fa-plus mr-2" />
            {i18n.t('buttons.add', i18nOpts)}
          </Button>
        </div>
        )}

        <BuildingFloorFormModal projectBuilding={projectBuilding} />
      </div>
    </div>
  );
};

BuildingFloors.propTypes = {
  projectBuilding: PropTypes.objectOf(PropTypes.any).isRequired
};

BuildingFloors.defaultProps = {};

export default connect((store) => ({
  buildingFloors: store.buildingFloors.buildingFloors,
  loading: store.buildingFloors.getBuildingFloors.loading,
  reloadUnits: store.buildingFloors.reloadUnits
}), {
  getBuildingFloors,
  setReloadBuildingFloorUnits
})(BuildingFloors);
