import React from 'react';
import { connect } from 'react-redux';
import v from 'voca';
import classNames from 'classnames';

import styles from './HomeInfo.module.scss';
import { formatCurrency, isValidAmount } from '../../../../../../utils/currencyUtils';
import { compareUnionTypeObjectTypename } from '../../../../../../utils/objectUtils';
import { DEFAULT_IMAGE } from '../../../../../../constants';
import Beds from '../../../../home/Beds';
import Baths from '../../../../home/Baths';
import Size from '../../../../home/Size';
import { getTotalBaths, getTotalBeds } from '../../../../../../utils/quickPossessionUtils';

const HomeInfo = ({ selectedHome }) => {
  const isMultiFamilyHome = compareUnionTypeObjectTypename(selectedHome, 'Unit');
  let name;
  let price;
  let address;
  let size;
  let baths;
  let beds;
  let homeUrl;
  let imageUrl;
  if (isMultiFamilyHome) {
    const { buildingModel } = selectedHome;
    const { project } = buildingModel;
    name = selectedHome.name;
    price = selectedHome.price;
    address = project.address;
    imageUrl = selectedHome.defaultImageUrl || DEFAULT_IMAGE;
    baths = buildingModel.bathrooms;
    beds = buildingModel.bedrooms;
    size = buildingModel.size;
    homeUrl = '';
  } else {
    name = selectedHome.model.name;
    price = selectedHome.totalPrice;
    address = selectedHome.address;
    imageUrl = selectedHome.imageUrl || selectedHome.model.defaultImageUrl || DEFAULT_IMAGE;
    baths = selectedHome.customBaths || getTotalBaths(selectedHome, {});
    beds = selectedHome.customBeds || getTotalBeds(selectedHome, {});
    size = selectedHome.squareFootage;
    homeUrl = selectedHome.url;
  }

  return (
    <div>
      <div className={classNames(styles.imageContainer, 'mt-3')}>
        <img src={imageUrl} alt="" />
      </div>

      <div className="d-flex align-items-center flex-wrap gap-3 mt-3 mb-2">
        <div className="font-30 font-weight-600 line-height-38">{name}</div>
        {isValidAmount(price) && (
          <div className="font-24 font-weight-500">{formatCurrency(price)}</div>
        )}
        <div className="d-flex align-items-center gap-2">
          <Size size="lg">{size}</Size>
          <Beds size="lg">{beds}</Beds>
          <Baths size="lg">{baths}</Baths>
        </div>
      </div>

      {!v.isBlank(address) && (
        <div className="font-16 font-weight-500">{address}</div>
      )}

      {!v.isBlank(homeUrl) && (
        <div className="font-16">{homeUrl}</div>
      )}
    </div>
  );
};

HomeInfo.propTypes = {};

HomeInfo.defaultProps = {};

export default connect((store) => ({
  selectedHome: store.salesInvitation.invitedHome
}))(HomeInfo);
