import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getLocalImageUrl } from '../../../utils/imageUtils';
import ImagePreviewItem from './ImagePreviewItem';
import UploadButton from './UploadButton';

const ImagesUploader = ({
  fieldName, multiple, onChange, initialImages
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (initialImages && initialImages.length > 0) setImages(initialImages);
  }, [initialImages]);

  const onImageChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = getLocalImageUrl(file);
    const imageItem = { id: _.uniqueId(), imageUrl, file };
    setImages((prevImages) => (multiple ? [...prevImages, imageItem] : [imageItem]));
  };

  const onDeleteImage = (imageToDelete) => {
    const filteredImages = images.filter((image) => image.id !== imageToDelete.id);
    setImages(filteredImages);
  };

  useEffect(() => {
    onChange(fieldName, images);
  }, [images]);

  return (
    <div>
      <div className="d-flex flex-wrap gap-2">
        <UploadButton id={fieldName} onChange={onImageChange} />

        {
          images.map((image) => (
            <ImagePreviewItem key={`image-preview-${image.id}`} image={image} onDelete={onDeleteImage} />
          ))
        }
      </div>
    </div>
  );
};

ImagesUploader.propTypes = {
  fieldName: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  initialImages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    imageUrl: PropTypes.string
  }))
};

ImagesUploader.defaultProps = {
  multiple: true,
  onChange: () => {},
  initialImages: []
};

export default ImagesUploader;
