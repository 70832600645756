import React from 'react';

import List from './List';
import FormModal from './FormModal';

const Forms = () => (
  <>
    <List />
    <FormModal />
  </>
);

Forms.propTypes = {};

Forms.defaultProps = {};

export default Forms;
