import React from 'react';

const HomeValuationIcon = ({ ...props }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" color="#98A2B3" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15 2L2 14.5H6.00001V27H12V18.6667H18V27H24V14.5H28L15 2Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

HomeValuationIcon.propTypes = {};

HomeValuationIcon.defaultProps = {};

export default HomeValuationIcon;
