import api from '../../../../../../../api';
import { updateOfferQuery } from '../../../../../../../graphql';

export async function updateOfferSalesLeadAsync(offerId, salesRep) {
  const input = {
    id: offerId,
    salesLeadId: salesRep ? salesRep.id : null,
    // eslint-disable-next-line no-underscore-dangle
    salesLeadType: salesRep ? salesRep.__typename : null,
  };

  return api.graphql(updateOfferQuery, { input })
    .then(({ data: { item } }) => item);
}
