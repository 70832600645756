import React, { useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { FaFacebookSquare, FaApple } from 'react-icons/fa';
import { AiOutlineGoogle } from 'react-icons/ai';
import v from 'voca';

import { SocialMediaProviders } from '../../../../../constants';

const i18nOpts = { scope: 'components.admin.customers.list.socialMediaLoginType.index' };

const SocialMediaLoginType = ({ customer }) => {
  const [showPopover, setShowPopover] = useState(false);
  const type = customer?.socialMediaType;
  if (!type) return null;

  let Icon = AiOutlineGoogle;
  if (type === SocialMediaProviders.FACEBOOK) Icon = FaFacebookSquare;
  if (type === SocialMediaProviders.APPLE) Icon = FaApple;

  return (
    <div>
      <div
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        id={`social-media-icon-${customer.id}`}
      >
        <Icon size="1.2rem" />
      </div>
      <Popover placement="bottom" isOpen={showPopover} target={`social-media-icon-${customer.id}`}>
        <PopoverBody>
          {i18n.t('signUp', { ...i18nOpts, name: v.titleCase(type) })}
        </PopoverBody>
      </Popover>
    </div>
  );
};

SocialMediaLoginType.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

SocialMediaLoginType.defaultProps = {};

export default SocialMediaLoginType;
