import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import v from 'voca';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { Link } from '@reach/router';

import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';
import { isAdmin } from '../../../../../utils/authUtils';
import ActionsMenu from '../../../ActionsMenu';
import ModalService from '../../../../../services/ModalService';
import { ModalNames, PRICE_RULE_SEPARATOR } from '../../../../../constants';
import api from '../../../../../api';
import { deletePriceRuleQuery } from '../../../../../graphql';
import { removePriceRule } from '../../../../../store/actions/priceRuleActions';

const i18nOpts = { scope: 'components.global.priceRules.priceRuleList.listItem.index' };

const ListItem = ({ priceRule, isFloorOptionPriceRule, ...props }) => {
  const [focused, setFocused] = useState(false);
  const name = useMemo(() => [priceRule.community.name, priceRule.communityPhase?.phase]
    .filter((n) => !v.isBlank(n)).join(PRICE_RULE_SEPARATOR),
  [priceRule]);

  const onToggleFocused = (newFocused) => {
    setFocused(newFocused);
  };

  const onEdit = () => {
    ModalService.open(ModalNames.PRICE_RULE_FORM, {
      priceRule,
      priceableId: priceRule.priceableId,
      priceableType: priceRule.priceableType
    });
  };

  const onDelete = () => {
    const variables = { id: priceRule.id };
    api.graphql(deletePriceRuleQuery, variables)
      .then(() => {
        props.removePriceRule(priceRule);
      });
  };

  return (
    <tr
      onMouseEnter={() => onToggleFocused(true)}
      onMouseLeave={() => onToggleFocused(false)}
    >
      <td>
        <Link to={`/admin/communities/${priceRule.community.id}`}>
          {name}
        </Link>
      </td>
      <td>
        {isValidAmount(priceRule.price) && formatCurrency(priceRule.price)}
      </td>
      {isFloorOptionPriceRule && (
        <td>{i18n.t(`defaultSelections.${v.camelCase(priceRule.defaultSelection)}`, i18nOpts)}</td>
      )}
      <td>{!v.isBlank(priceRule.externalId) && priceRule.externalId}</td>
      <td className="text-right">
        <div style={{ width: 20 }} className="d-inline-block">
          {isAdmin() && focused && (
            <ActionsMenu onEdit={onEdit} onDelete={onDelete} item={priceRule} />
          )}
        </div>
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  priceRule: PropTypes.objectOf(PropTypes.any).isRequired,
  isFloorOptionPriceRule: PropTypes.bool
};

ListItem.defaultProps = {
  isFloorOptionPriceRule: false
};

export default connect((store) => ({}), {
  removePriceRule
})(ListItem);
