import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import { MdLocationOn } from 'react-icons/md';
import { connect } from 'react-redux';

import { fetchProjectBuildingsAsync } from '../utils';

const i18nOpts = { scope: 'components.admin.buildingModels.list.buildingFilter.index' };

const ProjectBuildingFilter = ({ onChange, currentCompany }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onToggleBuilding = (building) => {
    setSelectedBuilding(building);
    const projectBuildingId = building ? building.id : null;
    onChange({ projectBuildingId });
  };

  useEffect(() => {
    fetchProjectBuildingsAsync(currentCompany.id)
      .then((items) => {
        setBuildings(items);
      })
      .catch();
  }, []);

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={onToggleDropdown}>
        <DropdownToggle color="outline-secondary" size="sm" caret disabled={!buildings.length}>
          <MdLocationOn size="0.8rem" className="mr-2 text-muted d-inline-block d-sm-none" />
          <span className="mr-2 text-muted d-none d-sm-inline-block">{i18n.t('building', i18nOpts)}</span>
          {selectedBuilding?.name ?? i18n.t('all', i18nOpts)}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onToggleBuilding(null)}>
            {i18n.t('all', i18nOpts)}
          </DropdownItem>
          {
            buildings.map((b) => (
              <DropdownItem
                key={`building-${b.id}`}
                onClick={() => onToggleBuilding(b)}
              >
                {b.name}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

ProjectBuildingFilter.propTypes = {
  onChange: PropTypes.func
};

ProjectBuildingFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(ProjectBuildingFilter);
