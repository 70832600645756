import React from 'react';
import i18n from 'i18n-js';

const i18nOpts = { scope: 'components.admin.customers.show.details.documents.listHeader.index' };

const ListHeader = () => (
  <tr>
    <th>{i18n.t('filename', i18nOpts)}</th>
    <th>{i18n.t('documentType', i18nOpts)}</th>
    <th>{i18n.t('fileSize', i18nOpts)}</th>
    <th>{i18n.t('dateUploaded', i18nOpts)}</th>
    <th>&nbsp;</th>
  </tr>
);

ListHeader.propTypes = {};

ListHeader.defaultProps = {};

export default ListHeader;
