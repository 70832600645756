import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import api from '../api';

function fetchCommunitiesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListCommunities($filter: CommunityFilterInput) {
      result: listCommunities(filter: $filter, pageSize: null) {
        items {
          id
          name
        }
      }
    }
  `, variables)
    .then(({ data: { result: { items } } }) => items);
}

const useFetchCommunities = () => {
  const [loading, setLoading] = useState(false);
  const [communities, setCommunities] = useState([]);

  const { currentCompany } = useSelector((store) => ({
    currentCompany: store.companies.currentCompany,
  }));

  useEffect(() => {
    setLoading(true);

    fetchCommunitiesAsync(currentCompany.id)
      .then((items) => setCommunities(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { communities, loading };
};

export default useFetchCommunities;
