export function downloadQRCode(name) {
  // eslint-disable-next-line no-undef
  const document = window?.document;

  const canvas = document.getElementById('qr-code');
  const pngUrl = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream');
  const downloadLink = document.createElement('a');
  downloadLink.href = pngUrl;
  downloadLink.download = `${name} QR Code.png`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
