import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';

function getDistance(firstPoint, secondPoint) {
  const { lat: xa, lng: ya } = firstPoint;
  const { lat: xb, lng: yb } = secondPoint;

  const xDiff = xa - xb;
  const yDiff = ya - yb;

  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}

const DEFAULT_CENTER = {
  lat: 30.277372,
  lng: -97.732439
};
const DEFAULT_ZOOM = 11;
const MAX_RANGE_IN_MAP = 83;
const MAX_LNG_RANGE = 110;

function sanitizePositions(positions) {
  if (positions.length === 0) return positions;
  const principalPoint = positions[0];
  const sanitizedPositions = [];
  positions.forEach((p) => {
    const distance = getDistance(principalPoint, p);
    if (distance < MAX_LNG_RANGE) sanitizedPositions.push(p);
  });

  return sanitizedPositions;
}

function centerAndFitBounds(map, maps, positions) {
  if (!positions.length) return;

  const bounds = new maps.LatLngBounds();

  const ordered = _.sortBy(positions, ['lng']);
  const firstItem = ordered[0];
  const lastItem = ordered[ordered.length - 1];
  const distance = getDistance(lastItem, firstItem);
  const sanitizedPositions = distance > MAX_RANGE_IN_MAP ? sanitizePositions(ordered) : ordered;

  sanitizedPositions.forEach((position) => {
    const location = new maps.LatLng(position.lat, position.lng);
    bounds.extend(location);
  });

  map.fitBounds(bounds);
}

function getData(positions) {
  return {
    positions,
    options: {
      radius: 30,
      opacity: 0.8,
      gradient: [
        'rgba(0, 255, 255, 0)',
        'rgb(216,229,172)',
        'rgb(218,206,32)',
        'rgb(212,224,60)',
        'rgb(207,219,57)',
        'rgba(186, 198, 56, 1)',
        'rgb(0,172,137)',
        'rgb(0,191,157)',
        'rgb(0,177,142)',
        'rgb(0,175,140)',
        'rgb(0,175,140)'
      ]
    }
  };
}

const GoogleHeatmap = ({ positions }) => {
  const heatmap = useMemo(() => getData(positions), [positions]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.GATSBY_GOOGLE_API_KEY,
        libraries: ['visualization', 'places']
      }}
      defaultCenter={DEFAULT_CENTER}
      defaultZoom={DEFAULT_ZOOM}
      options={(map) => ({ mapTypeId: map.MapTypeId.TERRAIN })}
      heatmap={heatmap}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        centerAndFitBounds(map, maps, positions);
      }}
    />
  );
};

GoogleHeatmap.propTypes = {
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    })
  )
};

GoogleHeatmap.defaultProps = {
  positions: []
};

export default GoogleHeatmap;
