import React, { useState } from 'react';
import { Col } from 'reactstrap';
import { ReactSortable } from 'react-sortablejs';

import PropTypes from 'prop-types';
import ListItem from './ListItem';
import { isAdmin } from '../../../utils/authUtils';
import FileUploader from '../../common/FileUploader';
import './styles.scss';

const ImagesManager = ({
  images, onMarkAsDefault, onSetImages, onSortImages, onDeleteImage, ...props
}) => {
  const [uploading, setUploading] = useState(false);

  const onDrop = (items) => {
    setUploading(true);
    props.onDrop(items)
      .finally(() => setUploading(false));
  };

  return (
    <div>
      <ReactSortable
        list={images}
        setList={onSetImages}
        onEnd={onSortImages}
        draggable=".sortable-image-item"
        handle=".sortable-image-item-handler"
        className="row"
      >
        {images.map((i) => (
          <Col key={`image-${i.id}`} xs="12" sm="6" md="4" lg="3" className="sortable-image-item">
            <ListItem
              image={i}
              onDelete={onDeleteImage}
              onMarkAsDefault={onMarkAsDefault}
            />
          </Col>
        ))}
      </ReactSortable>

      {isAdmin() && (
        <FileUploader onUpload={onDrop} uploading={uploading} />
      )}
    </div>
  );
};

ImagesManager.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSetImages: PropTypes.func,
  onSortImages: PropTypes.func,
  onDrop: PropTypes.func,
  onDeleteImage: PropTypes.func,
  onMarkAsDefault: PropTypes.func
};

ImagesManager.defaultProps = {
  onSetImages: () => {},
  onSortImages: () => {},
  onDrop: () => {},
  onDeleteImage: () => {},
  onMarkAsDefault: null
};

export default ImagesManager;
