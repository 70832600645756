import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';
import { Row, Col, FormText } from 'reactstrap';

import { formatCurrency, isValidAmount } from '../../../../../utils/currencyUtils';
import StatusIndicator from '../../../../global/StatusIndicator';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';
import { isAdmin } from '../../../../../utils/authUtils';
import { getModelTotalBasePrice } from './utils';

const i18nOpts = { scope: 'components.admin.models.show.details.index' };

const Details = ({ model, priceRules }) => {
  if (!model) return null;

  const creatorName = getCreatorDisplayName(model);
  const lastModifierName = getLastModifierDisplayName(model);

  const totalPrice = useMemo(() => getModelTotalBasePrice(model, priceRules),
    [model, priceRules]);

  return (
    <div>
      <Row>
        <Col xs={12} sm={6} md={7} lg={4}>
          {!v.isBlank(model.hashId) && (
            <div className="mb-4">
              <div className="label">{i18n.t('modelId', i18nOpts)}</div>
              <div><strong>{model.hashId}</strong></div>
            </div>
          )}

          <div className="mb-4">
            <div className="label">{i18n.t('productType', i18nOpts)}</div>
            <div><strong>{model.productType?.name}</strong></div>
          </div>

          {!v.isBlank(model.intro) && (
            <div className="mb-4">
              <div className="label">{i18n.t('intro', i18nOpts)}</div>
              <div><strong>{model.intro}</strong></div>
            </div>
          )}

          {!v.isBlank(model.sizeSqft) && (
            <div className="mb-4">
              <div className="label">{i18n.t('sizeSqft', i18nOpts)}</div>
              <div><strong>{model.sizeSqft}</strong></div>
            </div>
          )}

          {!v.isBlank(model.tourUrl) && (
            <div className="mb-4">
              <div className="label">{i18n.t('tourUrl', i18nOpts)}</div>
              <div><strong>{model.tourUrl}</strong></div>
            </div>
          )}

          {!v.isBlank(model.quickPossessionUrl) && (
            <div className="mb-4">
              <div className="label">{i18n.t('quickPossessionUrl', i18nOpts)}</div>
              <div><strong>{model.quickPossessionUrl}</strong></div>
            </div>
          )}

          {!v.isBlank(model.uTourUrl) && (
            <div className="mb-4">
              <div className="label">{i18n.t('uTourUrl', i18nOpts)}</div>
              <div><strong>{model.uTourUrl}</strong></div>
            </div>
          )}

          {isValidAmount(model.basePrice) && (
            <div className="mb-4">
              <div className="label">{i18n.t('basePrice', i18nOpts)}</div>
              <div><strong>{formatCurrency(model.basePrice)}</strong></div>
            </div>
          )}

          {isValidAmount(totalPrice) && totalPrice !== model.basePrice && (
            <div className="mb-4">
              <div className="label">{i18n.t('totalPrice', i18nOpts)}</div>
              <div><strong>{formatCurrency(totalPrice)}</strong></div>
              <FormText color="muted">{i18n.t('priceMessage', i18nOpts)}</FormText>
            </div>
          )}

          <div className="mb-4">
            <div>
              <i className="fas fa-bed font-20 mr-3" />
              <span className="font-18">{model.beds || 0}</span>
            </div>
          </div>

          <div className="mb-4">
            <div>
              <i className="fas fa-bath font-20 mr-3" />
              <span className="font-18">{model.baths || 0}</span>
            </div>
          </div>

          <div className="mb-4">
            <div className="label">{i18n.t('status', i18nOpts)}</div>
            <div>
              <div className="d-inline-block mr-2">
                <StatusIndicator status={model.status} displayTooltip={false} />
              </div>
              <strong>{i18n.t(`statuses.${model.status.toLowerCase()}`)}</strong>
            </div>
          </div>

          { !v.isBlank(creatorName) && isAdmin() && (
            <div className="mb-4">
              <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
              <div><strong>{creatorName}</strong></div>
            </div>
          )}

          { !v.isBlank(lastModifierName) && isAdmin() && (
            <div className="mb-4">
              <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
              <div><strong>{lastModifierName}</strong></div>
            </div>
          )}

          { !v.isBlank(model.externalId) && isAdmin() && (
            <div className="mb-4">
              <div className="label">{i18n.t('externalId', i18nOpts)}</div>
              <div><strong>{model.externalId}</strong></div>
            </div>
          )}
        </Col>
        <Col xs={12} sm={6} md={5} lg={4}>
          {!v.isBlank(model.imageUrl) && (
            <img src={model.imageUrl} alt={model.name} className="w-100" />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default connect((store) => ({
  model: store.models.model,
  priceRules: store.priceRules.priceRules
}), {})(Details);
