import React, { useState } from 'react';
import { connect } from 'react-redux';

import { reloadBuildingModelPalettes, saveBuildingModelPalette }
  from '../../../../../../store/actions/buildingModelPalettesActions';
import AddItemDropdown from '../../../../../common/AddItemDropdown';

const PalettesDropdown = ({ buildingModel, buildingModelPalettes, ...props }) => {
  const [open, toggleDropdown] = useState(false);

  if (!buildingModel) return null;
  const { project: { palettes = [] } } = buildingModel;

  const onSelect = (paletteId) => {
    const input = { paletteId, buildingModelId: buildingModel.id };
    props.saveBuildingModelPalette({ input })
      .then(() => props.reloadBuildingModelPalettes(true));
  };

  const filteredPalettes = palettes.filter(
    (p) => !buildingModelPalettes.find((fpp) => fpp.palette.id === p.id)
  );

  return (
    <AddItemDropdown
      open={open}
      items={filteredPalettes}
      toggleDropdown={toggleDropdown}
      onSelect={onSelect}
    />
  );
};

export default connect((store) => ({
  buildingModel: store.buildingModels.buildingModel,
  buildingModelPalettes: store.buildingModelPalettes.buildingModelPalettes,
}), {
  reloadBuildingModelPalettes,
  saveBuildingModelPalette
})(PalettesDropdown);
