import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { MdOutlineCloudUpload } from 'react-icons/all';
import i18n from 'i18n-js';

import styles from './ImageUploader.module.scss';

const i18nOpts = { scope: 'components.common.imageUploader.index' };

const ImageUploader = ({ onUpload, ...props }) => (
  <Dropzone
    onDrop={onUpload}
    accept={['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/x-icon', 'image/vnd.microsoft.icon']}
    {...props}
  >
    {({ getRootProps, getInputProps }) => (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={styles.uploaderContainer}>
          <div className={styles.iconContainer}>
            <MdOutlineCloudUpload />
          </div>

          <div className={styles.textContainer}>
            <div className="font-14">
              <span className="col-primary-700 font-weight-600">{i18n.t('clickToUpload', i18nOpts)}</span>
              <span className="col-gray-600">{i18n.t('dragAndDrop', i18nOpts)}</span>
            </div>
            <div className="font-12 col-gray-600">{i18n.t('helpText', i18nOpts)}</div>
          </div>
        </div>
      </div>
    )}
  </Dropzone>
);

ImageUploader.propTypes = {
  onUpload: PropTypes.func.isRequired
};

ImageUploader.defaultProps = {};

export default ImageUploader;
