import React from 'react';

const DollarIcon = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5 13.3334C5 15.1744 6.49238 16.6667 8.33333 16.6667H11.6667C13.5076 16.6667 15 15.1744 15 13.3334C15 11.4925 13.5076 10.0001 11.6667 10.0001H8.33333C6.49238 10.0001 5 8.5077 5 6.66675C5 4.8258 6.49238 3.33341 8.33333 3.33341H11.6667C13.5076 3.33341 15 4.8258 15 6.66675M10 1.66675V18.3334" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

DollarIcon.propTypes = {};

DollarIcon.defaultProps = {};

export default DollarIcon;
