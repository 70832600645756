import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import ListItem from './ListItem';

const GridView = ({ productTypes }) => (
  <div className="mb-4">
    <Row>
      {
        productTypes.map((pt) => (
          <Col
            key={`product-type-${pt.id}`}
            xs="12"
            sm="6"
            md="4"
            lg="3"
            className="product-type-container"
          >
            <ListItem productType={pt} />
          </Col>
        ))
      }
    </Row>
  </div>
);

GridView.propTypes = {};

GridView.defaultProps = {};

export default connect((store) => ({
  productTypes: store.productTypes.productTypes
}))(GridView);
