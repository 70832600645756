import { Colors } from '../../../../constants';

export const CHART_COLORS = Object.freeze([
  Colors.PRIMARY_100,
  Colors.PRIMARY_200,
  Colors.PRIMARY_300,
  Colors.PRIMARY_400,
  Colors.PRIMARY_500,
  Colors.PRIMARY_600,
]);
