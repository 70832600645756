import React, { useEffect, useState } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import v from 'voca';

import './styles.scss';

const i18nOpts = { scope: 'components.global.addressAutocomplete.index' };

const AddressAutocomplete = ({ defaultAddress, onAddressSelected }) => {
  const [mounted, setMounted] = useState(false);
  const [address, setAddress] = useState(defaultAddress);
  const [isLoaded, setIsLoaded] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const onTextChange = (e) => {
    const { value } = e.target;
    setAddress(value);
    onAddressSelected(value);

    fetchPredictions(value);
  };

  const onSelect = (suggestion) => {
    const { description, placeId } = suggestion;
    setAddress(description);
    onAddressSelected(description);
    setShowSuggestions(false);

    if (!v.isBlank(placeId)) {
      const filteredSuggestions = suggestions.filter((s) => s.placeId !== null);
      setSuggestions(filteredSuggestions);
    }
  };

  const fetchPredictions = (value) => {
    if (v.isBlank(value)) {
      setSuggestions([]);
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: value }, (predictions) => {
      let mappedPredictions = [];
      if (predictions && predictions.length > 0) {
        mappedPredictions = predictions
          .filter((prediction) => prediction.description !== value)
          .map((prediction) => ({
            description: prediction.description,
            placeId: prediction.place_id
          }));
      }

      setSuggestions([{ description: value, placeId: null }, ...mappedPredictions]);
      setShowSuggestions(true);
    });
  };

  useEffect(() => {
    if (window.google && window.google.maps) setIsLoaded(true);
    else setMounted(true);
  }, []);

  useEffect(() => {
    if (isLoaded) setMounted(true);
  }, [isLoaded]);

  if (!mounted) return null;

  const autocompleteContent = (
    <div>
      <Input
        type="text"
        name="address"
        id="address"
        placeholder={i18n.t('address', i18nOpts)}
        value={address}
        onChange={onTextChange}
        onFocus={() => setShowSuggestions(true)}
      />
      {(suggestions.length > 0 && showSuggestions) && (
        <div className="pac-container">
          {suggestions.map((suggestion) => (
            <div
              key={`suggestion-${suggestion.placeId}`}
              className="pac-item"
              role="button"
              aria-hidden
              onClick={() => onSelect(suggestion)}
            >
              <span className="pac-icon pac-icon-marker" />
              <span>{suggestion.description}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <>
      {isLoaded ? (
        autocompleteContent
      ) : (
        <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_API_KEY} libraries={['places', 'visualization']}>
          {autocompleteContent}
        </LoadScript>
      )}
    </>
  );
};

AddressAutocomplete.propTypes = {
  defaultAddress: PropTypes.string,
  onAddressSelected: PropTypes.func
};

AddressAutocomplete.defaultProps = {
  defaultAddress: '',
  onAddressSelected: () => {}
};

export default AddressAutocomplete;
