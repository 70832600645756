import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import ListItem from './ListItem';
import CommunitiesTooltip from './CommunitiesTooltip';

const GridView = ({ models }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onHoverModelCommunities = () => {
    if (!showTooltip) setShowTooltip(true);
  };

  const handleScroll = () => {
    if (showTooltip) setShowTooltip(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="mb-4">
      <Row>
        {
          models.map((m) => (
            <Col key={`model-${m.id}`} xs="12" sm="6" md="6" lg="4" className="mb-4">
              <ListItem model={m} onHoverModelCommunities={onHoverModelCommunities} />
            </Col>
          ))
        }
      </Row>

      { !!models.length && (
        <CommunitiesTooltip showTooltip={showTooltip} />
      )}
    </div>
  );
};

GridView.propTypes = {};

GridView.defaultProps = {};

export default connect((store) => ({
  models: store.models.models
}))(GridView);
