import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import SearchBar from '../../../../common/SearchBar';
import GridView from './GridView';
import ListView from './ListView';
import ListEmpty from '../../../../common/ListEmpty';
import {
  fetchInvitableHomesAsync,
} from './utils';
import SortDropdown from './SortDropdown';
import { InvitationHomeSortFields, Steps } from '../constants';
import useInvitationStartType from '../hooks/useInvitationStartType';
import { ViewModes } from '../../../../../constants';
import { setHomesViewMode } from '../../../../../store/actions/salesInvitationActions';
import ViewModesDropdown from '../../../ViewModesDropdown';
import ListPagination from '../../../../common/ListPagination';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.existingHome.index' };

const ExistingHome = ({
  onContinue, currentCompany, homesViewMode: viewMode, ...props
}) => {
  const [homes, setHomes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [sortField, setSortField] = useState(InvitationHomeSortFields.NEWEST);
  const [pagination, setPagination] = useState(null);

  const { isFromCustomer } = useInvitationStartType();

  const loadHomes = (page = 1) => {
    setLoading(true);

    const filter = { companyId: currentCompany.id, query: filters?.query };
    const sort = { field: sortField };
    const variables = {
      filter,
      sort,
      page
    };

    fetchInvitableHomesAsync(variables)
      .then(({ pagination: currentPagination, items: invitableHomes }) => {
        setPagination(currentPagination);
        setHomes(invitableHomes);
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const onSortChange = (selectedSortField) => {
    setSortField(selectedSortField);
  };

  const onSearch = (event, query) => {
    event.preventDefault();
    setFilters((prevFilters) => ({ ...prevFilters, query }));
  };

  const onSelect = () => {
    const nextStep = isFromCustomer ? Steps.CONFIRM_INVITATION : Steps.CUSTOMER_SELECTION_TYPE;

    onContinue(nextStep);
  };

  const onChangeViewMode = (vm) => {
    props.setHomesViewMode(vm);
  };

  const onChangePage = (page) => {
    loadHomes(page);
  };

  useEffect(() => {
    loadHomes();
  }, [filters, sortField]);

  return (
    <ModalBody className="not-scrollable-container d-flex flex-column p-0">
      <div className="pt-0 px-3 pb-3 border-bottom">
        <div className="font-18 font-weight-600 mb-3">{i18n.t('title', i18nOpts)}</div>

        <div className="d-flex align-items-center justify-content-end gap-3">
          <div className="flex-grow-1">
            <ViewModesDropdown
              viewMode={viewMode}
              onChange={(vm) => onChangeViewMode(vm)}
              alignDirection="left"
            />
          </div>

          <SortDropdown sortField={sortField} onChange={onSortChange} />
          <SearchBar
            onSubmit={onSearch}
            inputProps={{ bsSize: 'sm', placeholder: i18n.t('searchBarPlaceholder', i18nOpts) }}
          />
        </div>
      </div>

      <div className="scrollable-container" style={{ minHeight: '300px' }}>
        {loading && homes.length === 0 && (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        )}

        {homes.length > 0 ? (
          <>
            {viewMode === ViewModes.GRID ? (
              <GridView homes={homes} onSelect={onSelect} />
            ) : (
              <ListView homes={homes} onSelect={onSelect} />
            )}
          </>
        ) : (
          <div className="p-3">
            <ListEmpty loading={loading} items={homes} />
          </div>
        )}

        <div className="text-right px-3 mt-4">
          <ListPagination pagination={pagination} onPress={onChangePage} />
        </div>

      </div>
    </ModalBody>
  );
};

ExistingHome.propTypes = {
  onContinue: PropTypes.func
};

ExistingHome.defaultProps = {
  onContinue: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  homesViewMode: store.salesInvitation.homesViewMode
}), {
  setHomesViewMode
})(ExistingHome);
