import api from '../../../../api';
import {
  docuSignListTemplatesQuery,
  createDocumentToSignTemplateQuery,
  docuSignCreateTemplateViewQuery
} from '../../../../graphql';

export const Tabs = Object.freeze({
  CONNECTION: 'connection',
  SETTINGS: 'settings',
  USER_GUIDES: 'userGuides'
});

export function fetchDocuSignTemplatesAsync(variables = {}) {
  return api.graphql(docuSignListTemplatesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

export function createDocumentToSignTemplateAsync(variables = {}) {
  return api.graphql(createDocumentToSignTemplateQuery, variables)
    .then(({ data: { templateId } }) => Promise.resolve(templateId))
    .catch((e) => Promise.reject(e));
}

export function createTemplateViewAsync(variables = {}) {
  return api.graphql(docuSignCreateTemplateViewQuery, variables)
    .then(({ data: { result: { templateUrl } } }) => Promise.resolve(templateUrl));
}
