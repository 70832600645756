import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import v from 'voca';

import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import ModalService from '../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../constants';
import { hasFormValues } from '../../../../../../utils/formUtils';
import { sendGridSaveContactList } from '../../../../../../store/actions/sendGridActions';
import { getError } from '../../../../../../utils/requestUtils';

const i18nOpts = { scope: 'components.admin.integrations.sendGrid.contactLists.formModal.index' };

function onClose() {
  ModalService.close(ModalNames.SENDGRID_CONTACT_LIST_FORM);
}

const FormModal = ({
  opened, params, saving, currentCompany, ...props
}) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState(null);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSubmit = () => {
    const input = {
      ...form,
      companyId: currentCompany.id
    };

    props.sendGridSaveContactList({ input })
      .then(() => {
        onClose();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      });
  };

  const initForm = () => {
    const { contactList } = params;

    if (contactList) {
      const { id, name } = contactList;
      setForm({ id, name });
    } else setForm({});
  };

  useEffect(() => {
    if (opened) initForm();
  }, [opened]);

  const title = !form.id ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);
  const hasValues = hasFormValues(form);

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        {form.id && (
          <FormGroup>
            <Label for="id">{i18n.t('id', i18nOpts)}</Label>
            <Input
              type="text"
              name="id"
              id="id"
              value={form.id || ''}
              readOnly
            />
          </FormGroup>
        )}

        <FormGroup>
          <Label for="name">{i18n.t('name', i18nOpts)}</Label>
          <Input
            type="text"
            name="name"
            id="name"
            value={form.name || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'name')}
          />
          <InputError name="name" error={error} />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSubmit} disabled={saving || !hasValues}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.SENDGRID_CONTACT_LIST_FORM]?.opened || false,
  params: store.modals[ModalNames.SENDGRID_CONTACT_LIST_FORM]?.params || {},
  saving: store.sendGrid.saveContactList.loading || false,
  currentCompany: store.companies.currentCompany
}), {
  sendGridSaveContactList
})(FormModal);
