import React from 'react';
import i18n from 'i18n-js';
import { Table } from 'reactstrap';
import { formatTimestamp } from '../../../../../utils/dateUtils';
import ListEmpty from '../../../../common/ListEmpty';

const i18nOpts = { scope: 'components.admin.quickPossessions.show.requests.index' };

const Requests = ({ requests }) => (
  <div>
    { requests && requests.length === 0 && (
      <ListEmpty items={requests} />
    )}

    { requests && requests.length > 0 && (
      <Table responsive bordered>
        <thead>
          <tr>
            <th>{i18n.t('name', i18nOpts)}</th>
            <th>{i18n.t('email', i18nOpts)}</th>
            <th>{i18n.t('phone', i18nOpts)}</th>
            <th>{i18n.t('requestDate', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          { requests?.map((request) => (
            <tr key={`home-request-${request.id}`}>
              <td>{request?.contact.name}</td>
              <td>{request?.contact.email}</td>
              <td>{request?.contact.phone}</td>
              <td>{formatTimestamp(request?.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}
  </div>
);

export default Requests;
