import moment from 'moment-timezone';
import i18n from 'i18n-js';

import { DateFormats } from '../constants';

const NO_DATE_FOUND = 'No record';
const MOMENT_INVALID_DATE_RESPONSE = 'Invalid date';
const today = new Date();

const i18nOpts = { scope: 'utils.dateUtils' };

export function isRecently(date, numberOfDays = 1) {
  if (!date) return null;

  const now = moment();
  const days = now.diff(moment(date), 'days');
  return days < numberOfDays;
}

export function formatTimestamp(date) {
  const returningDate = moment(date).format(DateFormats.MMM_DD_YYYY_HH_MM);
  return returningDate !== MOMENT_INVALID_DATE_RESPONSE ? returningDate : NO_DATE_FOUND;
}

export function formatTimestampByFormat(date, dateFormat) {
  const timezone = moment.tz.guess();
  const returningDate = moment(date).tz(timezone).format(dateFormat);
  return returningDate !== MOMENT_INVALID_DATE_RESPONSE ? returningDate : NO_DATE_FOUND;
}

export function getLastSevenDaysRange() {
  const endDate = moment().format(DateFormats.MM_DD_YYYY);
  const startDate = moment().subtract(6, 'days').format(DateFormats.MM_DD_YYYY);

  return [new Date(startDate), new Date(endDate)];
}

export function getDaysDifference(dateRange) {
  const [startDate, endDate] = dateRange;
  const numberOfDays = moment(endDate).diff(moment(startDate), 'days') + 1;
  const days = `${numberOfDays} days`;
  return isLastDaysRange(dateRange) ? `Last ${days}` : days;
}

export function getMaxDate() {
  return new Date(today.getFullYear(), today.getMonth(), today.getDate());
}

export function isLastDaysRange(dateRange) {
  const dateRangeEnd = dateRange[1];
  const endDate = getLastSevenDaysRange()[1];

  return dateRangeEnd.getTime() === endDate.getTime();
}

export function getLastSevenDaysRangeOutput() {
  const [startDate, endDate] = getLastSevenDaysRange();

  return { startDate, endDate };
}

export function setDaysRangeInput(pickedDate) {
  const [startDate, endDate] = pickedDate;

  return {
    startDate: moment(startDate).format(DateFormats.MM_DD_YYYY),
    endDate: moment(endDate).format(DateFormats.MM_DD_YYYY)
  };
}

export function formatRange(dateRange) {
  if (!dateRange) return null;
  const { startDate, endDate } = dateRange;
  return `${moment(startDate).format(DateFormats.MMM_DD_YYYY)} - ${moment(endDate).format(DateFormats.MMM_DD_YYYY)}`;
}

export function parseDuration(ms) {
  if (!ms) return [0, 0];
  const duration = moment.duration(ms, 'milliseconds');
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return [minutes, seconds];
}

export function getDifferenceInHoursFromNow(date) {
  if (!date) return null;
  const sanitizedDate = moment(date).add(1, 'day');
  const now = moment();
  const duration = moment.duration(sanitizedDate.diff(now));
  return duration.asHours();
}

export function getDurationTimeFormatted(milliseconds) {
  const totalMilliseconds = milliseconds || 0;

  const duration = moment.duration(totalMilliseconds, 'milliseconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const times = [];
  if (hours > 0) times.push(i18n.t('hours', { ...i18nOpts, hours }));
  if (minutes > 0) times.push(i18n.t('minutes', { ...i18nOpts, minutes }));
  times.push(i18n.t('seconds', { ...i18nOpts, seconds }));
  return times.join(' ');
}

export function formatTimestampToDateInput(date) {
  return moment.utc(date).format('YYYY-MM-DD');
}

export function generateDatePickerSettings() {
  return {
    startDate: moment().subtract(6, 'days').toDate(),
    endDate: moment().toDate(),
    maxDate: getMaxDate(),
    locale: {
      firstDay: 1
    },
    ranges: {
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate()
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate()
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate()
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate()
      ],
      'This Year': [
        moment().startOf('year').toDate(),
        moment().toDate()
      ],
      'Last Year': [
        moment().subtract(1, 'year').startOf('year').toDate(),
        moment().subtract(1, 'year').endOf('year').toDate()
      ]
    },
  };
}

export function getDatePickerRangeLabel(dateRange) {
  const startDate = moment(dateRange[0]).format(DateFormats.MMM_DD_YYYY);
  const endDate = moment(dateRange[1]).format(DateFormats.MMM_DD_YYYY);
  return `${startDate} - ${endDate}`;
}

export function convertDateRangeToUtcRange(dateRange) {
  if (!dateRange) return null;

  const from = moment(dateRange.from, DateFormats.YYYY_MM_DD, true).startOf('day').utc().format();
  const to = moment(dateRange.to, DateFormats.YYYY_MM_DD, true).endOf('day').utc().format();

  return {
    from,
    to
  };
}
