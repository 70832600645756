import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import { getCustomerInterestId, getCustomerInterestColor } from '../../../../utils/customerUtils';
import ShowMoreContent from '../../../common/ShowMoreContent';

const CustomerInterests = ({ interests }) => {
  const ids = [];
  // Remove duplicated interests
  const filteredInterests = interests.filter((interest) => {
    const id = getCustomerInterestId(interest);
    if (ids.includes(id)) return false;
    ids.push(id);
    return true;
  });

  return (
    <div className="d-flex flex-wrap gap-1">
      <ShowMoreContent
        items={filteredInterests}
        renderItem={(item) => (
          <Badge
            key={getCustomerInterestId(item)}
            pill
            color={getCustomerInterestColor(item)}
          >
            {item.name}
          </Badge>
        )}
        initialItemsToShow={3}
      />
    </div>
  );
};

CustomerInterests.propTypes = {
  interests: PropTypes.arrayOf(PropTypes.any)
};

CustomerInterests.defaultProps = {
  interests: []
};

export default CustomerInterests;
