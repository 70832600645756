import React from 'react';
import { Button } from 'reactstrap';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import i18n from 'i18n-js';

import PropertyGroup from '../PropertyGroup';
import Columns from '../Columns';

const i18nOpts = { scope: 'components.common.designer.panels.editPanel.index' };

const EditPanel = ({
  canEdit, onEdit, onDelete, onCancel, onSave, isEditing
}) => (
  <PropertyGroup>
    <Columns label={i18n.t('actions', i18nOpts)}>
      <div className="d-flex justify-content-around">
        { canEdit && (
          <div
            className="w-100 text-center"
            role="button"
            aria-hidden
            onClick={onEdit}
            title={i18n.t('edit', i18nOpts)}
          >
            <FaEdit size="1.2rem" />
          </div>
        )}
        <div
          className="w-100 text-center text-danger"
          role="button"
          aria-hidden
          onClick={onDelete}
          title={i18n.t('delete', i18nOpts)}
        >
          <MdDelete size="1.2rem" />
        </div>
      </div>
      { isEditing && (
        <div className="ml-5 mt-3">
          <Button
            size="sm"
            color="primary"
            onClick={onSave}
          >
            {i18n.t('buttons.save')}
          </Button>
          <Button
            size="sm"
            className="ml-4"
            onClick={onCancel}
          >
            {i18n.t('buttons.cancel')}
          </Button>
        </div>
      )}
    </Columns>
  </PropertyGroup>
);

export default EditPanel;
