import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';

import TimeLineElement from '../../../../common/TimeLineElement';
import IdVerifiedIcon from '../../../../../assets/icons/customer-activities/IdVerifiedIcon';
import ActivityDate from '../components/ActivityDate';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.idVerified.index' };

const IdVerified = ({ activity, innerKey }) => {
  const { createdAt } = activity;

  return (
    <TimeLineElement
      showDate={activity.showDate}
      date={createdAt}
      innerKey={innerKey}
      Icon={<IdVerifiedIcon />}
    >
      <div className="d-flex align-items-start gap-3">
        <div className="activity-timeline-title">{i18n.t('title', i18nOpts)}</div>
        <ActivityDate date={createdAt} />
      </div>
      <div>{i18n.t('description', i18nOpts)}</div>
    </TimeLineElement>
  );
};

IdVerified.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired
};

IdVerified.defaultProps = {};

export default IdVerified;
