import React, { useEffect, useState } from 'react';
import {
  ModalHeader, Modal, ModalBody, Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import v from 'voca';

import { ModalNames } from '../../../../../constants';
import ModalCloseButton from '../../../../common/ModalCloseButton';
import { closeModal, Steps } from './utils';
import Form from './Form';
import BackLink from '../../../../global/SalesInvitationModal/components/BackLink';
import Confirm from './Confirm';
import useSaveQuickPossession
  from '../../../../global/QuickPossessionForm/hooks/useSaveQuickPossession';
import { setReloadQuickPossessions } from '../../../../../store/actions/quickPossessionActions';

const QuickPossessionFormModal = ({ opened, params, ...props }) => {
  const [currentStep, setCurrentStep] = useState(Steps.FORM);
  const [prevSteps, setPrevSteps] = useState([]);
  const [mounted, setMounted] = useState(false);

  const {
    form, setForm, submitting, error,
    onTextChange, saveQuickPossessionAsync,
    validateQuickPossessionAsync, isValidForm,
    loadQuickPossessionAsync
  } = useSaveQuickPossession();
  const { id } = params;

  const onBack = () => {
    const newPrevSteps = [...prevSteps];
    newPrevSteps.pop();
    const prevStep = newPrevSteps.pop();
    setPrevSteps(newPrevSteps);
    setCurrentStep(prevStep);
  };

  const onSave = () => {
    saveQuickPossessionAsync()
      .then(() => {
        props.setReloadQuickPossessions(true);
        closeModal();
      })
      .catch(() => {});
  };

  const onValidate = () => {
    validateQuickPossessionAsync()
      .then(() => setCurrentStep(Steps.CONFIRM))
      .catch(() => {});
  };

  const loadForm = () => {
    setMounted(false);
    const isNew = v.isBlank(id);

    setPrevSteps([Steps.FORM]);
    setCurrentStep(Steps.FORM);

    if (isNew) {
      setForm({});
      setMounted(true);
      return;
    }

    loadQuickPossessionAsync(id)
      .then(() => setMounted(true))
      .catch(() => {});
  };

  useEffect(() => {
    setPrevSteps((prevPrevSteps) => ([...prevPrevSteps, currentStep]));
  }, [currentStep]);

  useEffect(() => {
    if (!opened) return;

    loadForm();
  }, [opened]);

  return (
    <Modal isOpen={opened} size="lg" scrollable>
      <ModalHeader
        toggle={closeModal}
        close={<ModalCloseButton onClick={closeModal} />}
        tag="div"
      >
        {prevSteps.length > 1 && (
          <div className="float-left">
            <BackLink onClick={onBack} />
          </div>
        )}
      </ModalHeader>

      {mounted ? (
        <>
          {currentStep === Steps.FORM && (
            <Form
              form={form}
              setForm={setForm}
              error={error}
              onTextChange={onTextChange}
              isValidForm={isValidForm()}
              submitting={submitting}
              onContinue={onValidate}
              onSave={onSave}
            />
          )}

          {currentStep === Steps.CONFIRM && (
            <Confirm
              form={form}
              submitting={submitting}
              onBack={onBack}
              onSave={onSave}
            />
          )}
        </>
      ) : (
        <ModalBody>
          <div className="text-center my-5"><Spinner size="lg" /></div>
        </ModalBody>
      )}

    </Modal>
  );
};

QuickPossessionFormModal.propTypes = {};

QuickPossessionFormModal.defaultProps = {};

export default connect((store) => ({
  opened: store.modals[ModalNames.QUICK_POSSESSION_FORM]?.opened || false,
  params: store.modals[ModalNames.QUICK_POSSESSION_FORM]?.params || {}
}), {
  setReloadQuickPossessions
})(QuickPossessionFormModal);
