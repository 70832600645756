import React from 'react';
import { connect } from 'react-redux';

import ReservationDocumentManagement from '../../../../global/ReservationDocumentManagement';
import { DocumentTemplateAssignableEntities } from '../../../../../constants';

const ReservationDocument = ({ project }) => (
  <ReservationDocumentManagement
    entityId={project.id}
    entityType={DocumentTemplateAssignableEntities.PROJECT}
  />
);

ReservationDocument.propTypes = {};

ReservationDocument.defaultProps = {};

export default connect((store) => ({
  project: store.projects.project,
}))(ReservationDocument);
