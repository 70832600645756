import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import i18n from 'i18n-js';
import classNames from 'classnames';

import { Tabs } from '../utils';
import { isAdmin } from '../../../../../utils/authUtils';

const i18nOpts = { scope: 'components.admin.buildingModels.show.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <Nav tabs>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.DETAILS })}
        onClick={() => onChange(Tabs.DETAILS)}
      >
        {i18n.t('details', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.FLOORS })}
        onClick={() => onChange(Tabs.FLOORS)}
      >
        {i18n.t('floors', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.IMAGES })}
        onClick={() => onChange(Tabs.IMAGES)}
      >
        {i18n.t('images', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.PACKAGES })}
        onClick={() => onChange(Tabs.PACKAGES)}
      >
        {i18n.t('packages', i18nOpts)}
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classNames({ active: activeTab === Tabs.PALETTES })}
        onClick={() => onChange(Tabs.PALETTES)}
      >
        {i18n.t('palettes', i18nOpts)}
      </NavLink>
    </NavItem>
    {isAdmin() && (
      <NavItem>
        <NavLink
          className={classNames({ active: activeTab === Tabs.MODIFICATIONS })}
          onClick={() => onChange(Tabs.MODIFICATIONS)}
        >
          {i18n.t('modifications', i18nOpts)}
        </NavLink>
      </NavItem>
    )}
  </Nav>
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
