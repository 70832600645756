import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { TabContent, TabPane } from 'reactstrap';

import IntegrationsBreadcrumbs from '../../../global/IntegrationsBreadcrumbs';
import NavTabs from './NavTabs';
import { Tabs } from './utils';
import CreditCard from './CreditCard';

const i18nOpts = { scope: 'components.admin.integrations.paymentMethods.index' };

const PaymentMethods = () => {
  const [activeTab, setActiveTab] = useState(Tabs.CREDIT_CARD);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <IntegrationsBreadcrumbs items={[{ text: i18n.t('title', i18nOpts) }]} />

      <NavTabs activeTab={activeTab} onChange={onTabChange} />

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.CREDIT_CARD}>
          <CreditCard />
        </TabPane>
      </TabContent>
    </div>
  );
};

PaymentMethods.propTypes = {};

PaymentMethods.defaultProps = {};

export default PaymentMethods;
