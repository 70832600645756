import React from 'react';
import PropTypes from 'prop-types';

import { getCustomerColumnName } from '../../../../../../../utils/enumUtils';
import { CustomerColumns } from '../../../utils';
import SortableColumnHeader from '../../../../../../common/SortableColumnHeader';

const CustomColumnName = ({ column, sort, onSortChange }) => {
  const isSortableColumn = [
    CustomerColumns.HOME_VALUATION,
    CustomerColumns.INCOME,
    CustomerColumns.REGION,
    CustomerColumns.ASSIGNED_TO,
  ].includes(column);

  const name = getCustomerColumnName(column);

  if (isSortableColumn) {
    return (
      <SortableColumnHeader
        field={column}
        label={name}
        sort={sort}
        onPress={() => onSortChange(column)}
      />
    );
  }

  return (
    <th>
      {name}
    </th>
  );
};

CustomColumnName.propTypes = {
  column: PropTypes.oneOf(Object.values(CustomerColumns)).isRequired,
  onSortChange: PropTypes.func,
  sort: PropTypes.objectOf(PropTypes.any),
};

CustomColumnName.defaultProps = {
  onSortChange: () => {},
  sort: null
};

export default CustomColumnName;
