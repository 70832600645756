import { SortOrders } from '../constants';

export default function setSortFilter(field, sortFilter) {
  if (sortFilter?.field === field) {
    const direction = sortFilter.direction
    === SortOrders.ASC ? SortOrders.DESC : SortOrders.ASC;
    return { ...sortFilter, direction };
  }
  return { field, direction: SortOrders.DESC };
}
