import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from 'i18n-js';

const i18nOpts = { scope: 'components.common.showMoreContent.seeMoreButton.index' };

const ShowMoreButton = ({ showAll, onClick, extraClass }) => (
  <div
    role="button"
    aria-hidden
    onClick={onClick}
    className={classNames('text-primary font-weight-500 text-decoration-underline', extraClass)}
  >
    {showAll ? i18n.t('seeLess', i18nOpts) : i18n.t('seeMore', i18nOpts)}
  </div>
);

ShowMoreButton.propTypes = {
  showAll: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  extraClass: PropTypes.string
};

ShowMoreButton.defaultProps = {
  onClick: () => {},
  extraClass: ''
};

export default ShowMoreButton;
