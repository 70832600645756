import v from 'voca';

import { parseBoolean } from '../../../../../utils/parserUtils';

export function getMappedValue(mappedFields, mappedValues, mapped) {
  const mappedField = mappedFields.find((mp) => mp.mapped === mapped);
  if (!mappedField) return '';

  const mappedValue = mappedValues.find((mv) => mv.name === mappedField.name);
  return mappedValue?.value || '';
}

export function filterFormFields(mappedFields, mappedValues) {
  const excludedFieldNames = mappedFields
    .filter((mf) => parseBoolean(mf.excluded) || !v.isBlank(mf.mapped))
    .map((ef) => ef.name);

  return mappedValues.filter((mv) => !excludedFieldNames.includes(mv.name));
}

export function truncateMessage(message, truncate = false) {
  const truncated = message.length > 550;
  return [truncated, truncated && truncate ? `${message.slice(0, 500)}...` : message];
}
