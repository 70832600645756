import api from '../../../../../../../api';
import { getDealsPriceMaxQuery } from '../../../../../../../graphql';

export async function getDealsPriceMaxAsync(companyId) {
  const variables = {
    companyId
  };
  return api.graphql(getDealsPriceMaxQuery, variables)
    .then(({ data: { maxPrice } }) => maxPrice);
}
