import React, { useEffect, useState } from 'react';
import {
  ModalBody, Modal, ModalFooter, Button
} from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import moment from 'moment';
import v from 'voca';

import styles from './DatePickerModal.module.scss';
import { DateFormats, ModalNames } from '../../../../../../../constants';
import { CalendarPicker } from '../../../../../../ui';
import ModalService from '../../../../../../../services/ModalService';

function closeModal() {
  ModalService.close(ModalNames.CUSTOMER_FILTER_DATE_PICKER);
}

const DatePickerModal = ({ opened, params, onChange }) => {
  const [selectedDate, setSelectedDate] = useState({ from: null, to: null });

  const onApply = () => {
    const { name } = params;

    closeModal();

    const from = formatDate(selectedDate.from);
    const to = formatDate(selectedDate.to);
    onChange({ [name]: { from, to } });
  };

  const isValid = () => !!selectedDate.from && !!selectedDate.to;

  const getPickerDate = (date) => {
    if (v.isBlank(date)) return null;

    const dateObj = moment(date, DateFormats.YYYY_MM_DD, true);
    const day = parseInt(dateObj.format('D'), 10);
    const month = parseInt(dateObj.format('M'), 10);
    const year = parseInt(dateObj.format('YYYY'), 10);
    return { day, month, year };
  };

  const formatDate = (date) => moment({ ...date, month: date.month - 1 })
    .format(DateFormats.YYYY_MM_DD);

  useEffect(() => {
    if (!opened) return;

    const { value } = params;

    const initialDate = {
      from: getPickerDate(value?.from),
      to: getPickerDate(value?.to),
    };
    setSelectedDate(initialDate);
  }, [opened]);

  return (
    <Modal isOpen={opened} className={styles.modal}>
      <ModalBody className="px-0">
        <div className="d-flex flex-column align-items-center">
          <CalendarPicker value={selectedDate} onChange={setSelectedDate} />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between gap-2">
        <div className="flex-fill">
          <Button color="secondary" outline block onClick={closeModal}>
            {i18n.t('buttons.cancel')}
          </Button>
        </div>
        <div className="flex-fill">
          <Button color="primary" block onClick={onApply} disabled={!isValid()}>
            {i18n.t('buttons.apply')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DatePickerModal.propTypes = {
  onChange: PropTypes.func
};

DatePickerModal.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  opened: store.modals[ModalNames.CUSTOMER_FILTER_DATE_PICKER]?.opened || false,
  params: store.modals[ModalNames.CUSTOMER_FILTER_DATE_PICKER]?.params || {},
}))(DatePickerModal);
