import { isMobile } from '../../utils/windowUtils';
import SecureStore from '../../utils/secureStore';

export function setMiniSidebar(element) {
  element.classList.add('mini-sidebar');
  SecureStore.setSidebarExpanded(false);
}

export function setFullSidebar(element) {
  element.classList.remove('mini-sidebar');
  SecureStore.setSidebarExpanded(true);
}

export function toggleSidebarCollapse(isCollapse) {
  const element = document.body;
  if (!element) return;

  element.classList.toggle('sidebar-collapse', isCollapse);
  if (isCollapse) {
    element.classList.remove('sidebar-open');
    element.classList.add('sidebar-closed');
  }
}

export function toggleSidebar() {
  const element = document.body;
  if (!element) return;

  if (isMobile()) {
    const isOpen = element.classList.contains('sidebar-open');
    element.classList.toggle('sidebar-open', !isOpen);
    element.classList.toggle('sidebar-closed', isOpen);
  } else {
    const isMiniSidebar = element.classList.contains('mini-sidebar');
    if (isMiniSidebar) setFullSidebar(element);
    else setMiniSidebar(element);
  }
}
