import React from 'react';
import PropTypes from 'prop-types';

import FilterCheckbox from '../../../../../../../common/FilterCheckbox';

const StatusItem = ({
  reserveStep, selectedValues, onChange
}) => (
  <FilterCheckbox
    label={reserveStep.label}
    value={reserveStep.type}
    checked={selectedValues.includes(reserveStep.type)}
    onChange={onChange}
  />
);

StatusItem.propTypes = {
  reserveStep: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

StatusItem.defaultProps = {
  selectedValues: [],
  onChange: () => {}
};

export default StatusItem;
