import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import './styles.scss';
import { addDocumentFile, cleanDocumentFiles } from '../../../../store/actions/documentActions';
import { uuidv4 } from '../../../../utils/randomUtils';
import DocumentFile from '../../../common/DocumentUploader/DocumentFile';

const i18nOpts = { scope: 'components.admin.documents.uploader.index' };

class Uploader extends Component {
  constructor(props) {
    super(props);

    this.onImageChange = this.onImageChange.bind(this);
  }

  componentWillUnmount() {
    const { props } = this;
    props.cleanDocumentFiles();
  }

  onImageChange(event) {
    const files = Array.from(event.target.files || []);
    const { props } = this;

    files.forEach((file) => {
      const documentFile = {
        id: uuidv4(),
        file
      };

      props.addDocumentFile(documentFile);
    });
  }

  render() {
    const { documentFiles } = this.props;

    return (
      <div id="documents-uploader-container">
        <Input
          id="fileInput"
          type="file"
          multiple
          onChange={this.onImageChange}
          className="d-none"
        />

        {documentFiles.length > 0 && (
          <div className="uploading-files-container mb-4">
            <h3 className="mb-4">{i18n.t('title', i18nOpts)}</h3>

            {
              documentFiles.map((documentFile) => (
                <DocumentFile documentFile={documentFile} key={`document-file-${documentFile.id}`} />
              ))
            }
          </div>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  documentFiles: store.documents.documentFiles
}), {
  addDocumentFile,
  cleanDocumentFiles
})(Uploader);
