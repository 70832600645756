import React, { useState } from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import v from 'voca';
import i18n from 'i18n-js';
import { toast } from 'react-toastify';

import { ContactStages } from '../../../../../../../constants';
import { getContactStageName } from '../../../../../../../utils/enumUtils';
import { updateCompanyContactAsync } from './utils';
import { setCustomerAttributes } from '../../../../../../../store/actions/customerActions';

const StageDropdown = ({
  customer, currentCompany, ...props
}) => {
  const [selectedStage, setSelectedStage] = useState(customer.companyContact.stage);

  const onToggle = (stage) => {
    setSelectedStage(stage);

    updateCompanyContactAsync(customer, stage)
      .then((companyContact) => {
        props.setCustomerAttributes(customer.id, { companyContact });
      })
      .catch((e) => {
        setSelectedStage(customer.companyContact.stage);
        toast.error(e.message);
      });
  };

  const stages = currentCompany.contactStages || Object.keys(ContactStages);

  return (
    <UncontrolledDropdown>
      <DropdownToggle color="outline-secondary" size="sm" caret>
        {v.isBlank(selectedStage) ? (
          <span className="text-secondary">{i18n.t('select.select')}</span>
        ) : getContactStageName(selectedStage)}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem className="text-secondary" onClick={() => onToggle(null)}>
          {i18n.t('select.select')}
        </DropdownItem>

        {
          stages.map((s) => (
            <DropdownItem key={`contact-stage-${s}`} onClick={() => onToggle(s)} active={s === selectedStage}>
              {getContactStageName(s)}
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

StageDropdown.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired
};

StageDropdown.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  setCustomerAttributes
})(StageDropdown);
