import React from 'react';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';

const i18nOpts = { scope: 'components.common.listEmpty.index' };

const ListEmpty = ({ loading, items }) => {
  if (loading || items.length > 0) return null;

  return (
    <div>
      {i18n.t('noData', i18nOpts)}
    </div>
  );
};

ListEmpty.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object)
};

ListEmpty.defaultProps = {
  loading: false,
  items: []
};

export default ListEmpty;
