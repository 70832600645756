import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import OfferStep from './OfferStep';
import SignStep from './SignStep';
import DepositStep from './DepositStep';
import { ReserveStepTypes } from '../../../../constants';
import useReserveNowWorkflowSteps from '../../../../hooks/useReserveNowWorkflowSteps';

const Step = ({ step, home, clickableSteps }) => {
  const stepLabel = step.label;

  switch (step.type) {
    case ReserveStepTypes.OFFER:
      return <OfferStep home={home} clickable={clickableSteps} label={stepLabel} />;

    case ReserveStepTypes.SIGN:
      return <SignStep home={home} clickable={clickableSteps} label={stepLabel} />;

    case ReserveStepTypes.DEPOSIT:
      return <DepositStep home={home} clickable={clickableSteps} label={stepLabel} />;

    default:
      return null;
  }
};

const OfferSteps = ({
  home, clickableSteps
}) => {
  const journeySteps = useReserveNowWorkflowSteps();

  const modularSteps = [];
  journeySteps.forEach((step, index) => {
    modularSteps.push(
      <Step key={`${step.type}-step`} step={step} home={home} clickableSteps={clickableSteps} />
    );
    if (index < journeySteps.length - 1) {
      modularSteps.push(
        <li className="flex-grow-1" key={`${step.type}-separator-line`}>
          <div className="separator-line" />
        </li>
      );
    }
  });

  return (
    <ul className="offer-steps-container">
      {modularSteps}
    </ul>
  );
};

OfferSteps.propTypes = {
  home: PropTypes.objectOf(PropTypes.any).isRequired,
  clickableSteps: PropTypes.bool
};

OfferSteps.defaultProps = {
  clickableSteps: false
};

export default OfferSteps;
