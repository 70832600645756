import React, { useState } from 'react';
import i18n from 'i18n-js';
import { Button, Spinner } from 'reactstrap';
import { SiMicrosoftexcel } from 'react-icons/si';
import { IoMdDownload } from 'react-icons/io';
import v from 'voca';
import { connect } from 'react-redux';

import api from '../../../../../api';
import { createSpreadsheetQuery } from '../../../../../graphql/importExport';

const i18nOpts = { scope: 'components.admin.integrations.exportData.exportButton.index' };

function exportDataAsync(companyId) {
  const variables = { companyId };

  return api.graphql(createSpreadsheetQuery, variables)
    .then(({ data: { item: { downloadUrl } } }) => Promise.resolve(downloadUrl));
}

const ExportButton = ({ currentCompany }) => {
  const [loading, setLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const onExport = () => {
    setLoading(true);

    exportDataAsync(currentCompany.id)
      .then((url) => setDownloadUrl(url))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <div>
      {v.isBlank(downloadUrl) ? (
        <Button color="outline-secondary" onClick={onExport} disabled={loading}>
          {loading ? (
            <>
              <Spinner size="sm" />
              <span className="align-middle ml-3">{i18n.t('buttons.exporting', i18nOpts)}</span>
            </>
          ) : (
            <>
              <SiMicrosoftexcel size="1rem" />
              <span className="align-middle ml-3">{i18n.t('buttons.exportData', i18nOpts)}</span>
            </>
          )}
        </Button>
      ) : (
        <>
          <p>{i18n.t('fileReady', i18nOpts)}</p>
          <a href={downloadUrl} target="_blank" rel="noreferrer">
            <IoMdDownload size="1.5rem" />
            <span className="align-middle ml-2 lead">{i18n.t('buttons.download', i18nOpts)}</span>
          </a>
        </>
      )}
    </div>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(ExportButton);
