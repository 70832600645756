import React, { useState } from 'react';
import {
  Row, Col, TabContent, TabPane
} from 'reactstrap';

import NavTabs from './NavTabs';
import { Tabs } from './utils';
import DocumentTemplate from './DocumentTemplate';

const UserGuides = () => {
  const [activeTab, setActiveTab] = useState(Tabs.DOCUMENT_TEMPLATE);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Row>
      <Col xs={12} sm={4} md={3}>
        <NavTabs activeTab={activeTab} onChange={onTabChange} />
      </Col>
      <Col xs={12} sm={8} md={9}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={Tabs.DOCUMENT_TEMPLATE}>
            <DocumentTemplate />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

UserGuides.propTypes = {};

UserGuides.defaultProps = {};

export default UserGuides;
