import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Tabs } from '../utils';
import { SegmentedControl } from '../../../../ui';

const i18nOpts = { scope: 'components.global.home.dealModal.navTabs.index' };

const NavTabs = ({ activeTab, onChange }) => (
  <SegmentedControl
    selectedValue={activeTab}
    items={[{
      value: Tabs.OVERVIEW,
      label: i18n.t('overview', i18nOpts)
    }, {
      value: Tabs.TIMELINE,
      label: i18n.t('timeline', i18nOpts)
    }]}
    onChange={onChange}
  />
);

NavTabs.propTypes = {
  activeTab: PropTypes.oneOf(Object.values(Tabs)).isRequired,
  onChange: PropTypes.func,
};

NavTabs.defaultProps = {
  onChange: () => {}
};

export default NavTabs;
