import React from 'react';

import { sanitizeValue } from '../utils';

const ChangedValue = ({ oldValue, newValue }) => (
  <div>
    <span className="tex-decoration-line-through text-danger">
      {sanitizeValue(oldValue)}
    </span>
    <span className="ml-3 text-info">{sanitizeValue(newValue)}</span>
  </div>
);

ChangedValue.propTypes = {};

ChangedValue.defaultProps = {
  oldValue: null,
  newValue: null
};

export default ChangedValue;
