import React from 'react';
import i18n from 'i18n-js';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

import ActionsMenu from '../../../../global/ActionsMenu';
import { deleteProductType } from '../../../../../store/actions/productTypeActions';
import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../../utils/authUtils';
import CreatedByTableItem from '../../../../global/CreatedByTableItem';
import LastModifiedByTableItem from '../../../../global/LastModifiedByTableItem';

const i18nOpts = { scope: 'components.admin.productTypes.list.listView.index' };

const ListView = ({ productTypes, ...props }) => {
  const isAdmin = isMasterAdmin() || isCompanyAdmin() || isSalesLead();
  const onEdit = (productType) => {
    navigate(`/admin/productTypes/form?id=${productType.id}`);
  };

  const onDelete = (productTypeId) => {
    props.deleteProductType(productTypeId);
  };

  return (
    <Table responsive bordered id="productTypes-table">
      <thead>
        <tr>
          <th>{i18n.t('name', i18nOpts)}</th>
          {isAdmin && (
            <>
              <th>{i18n.t('createdBy', i18nOpts)}</th>
              <th>{i18n.t('lastModifiedBy', i18nOpts)}</th>
              <th>&nbsp;</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {
          productTypes.map((productType) => (
            <tr key={`product-type-${productType.id}`}>
              <td>{productType.name}</td>
              {isAdmin && (
                <>
                  <CreatedByTableItem instance={productType} />
                  <LastModifiedByTableItem instance={productType} />
                  <td className="text-right">
                    <ActionsMenu
                      item={productType}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  </td>
                </>
              )}
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

export default connect((store) => ({
  productTypes: store.productTypes.productTypes
}), {
  deleteProductType
})(ListView);
