import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import SortableColumnHeader from '../../../../../common/SortableColumnHeader';
import { DepositsSortFields } from '../../../../../../constants';
import useIsRefundsEnabled from '../../../../../../hooks/useIsRefundsEnabled';

const i18nOpts = { scope: 'components.admin.deposits.list.listView.listHeader.index' };

const ListHeader = ({ sort, onSortChange }) => {
  const isRefundsEnabled = useIsRefundsEnabled();

  return (
    <tr>
      <SortableColumnHeader
        field={DepositsSortFields.DATE}
        label={i18n.t('date', i18nOpts)}
        sort={sort}
        onPress={() => onSortChange(DepositsSortFields.DATE)}
      />
      <th>{i18n.t('customer', i18nOpts)}</th>
      <th>{i18n.t('communityProject', i18nOpts)}</th>
      <th>{i18n.t('property', i18nOpts)}</th>
      <th>{i18n.t('amount', i18nOpts)}</th>
      <th className="text-nowrap">{i18n.t('paymentMethod', i18nOpts)}</th>
      <th>{i18n.t('status', i18nOpts)}</th>
      {isRefundsEnabled && (
        <th>&nbsp;</th>
      )}
    </tr>
  );
};

ListHeader.propTypes = {
  sort: PropTypes.objectOf(PropTypes.any),
  onSortChange: PropTypes.func
};

ListHeader.defaultProps = {
  sort: {},
  onSortChange: () => {}
};

export default ListHeader;
