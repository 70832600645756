import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, CardHeader, Row, Col, ListGroup
} from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { ReactSortable } from 'react-sortablejs';

import './styles.scss';
import v from 'voca';
import { DEFAULT_IMAGE, FloorableTypes, ModalNames } from '../../../../constants';
import { deleteFloor, setFloorGroups, setFloorOptions } from '../../../../store/actions/floorActions';
import { sortFloorGroups } from '../../../../store/actions/floorGroupActions';
import ModalService from '../../../../services/ModalService';
import Beds from '../../../Beds';
import Baths from '../../../Baths';
import FloorGroup from './FloorGroup';
import FloorOption from './FloorOption';
import ActionsMenu from './ActionsMenu';
import { getFloorOptionIdsByFloorGroupId } from '../../../../utils/floorOptionUtils';
import api from '../../../../api';
import { sortFloorOptionsQuery } from '../../../../graphql';
import { isAdmin } from '../../../../utils/authUtils';

const i18nOpts = { scope: 'components.global.floorsManager.floor.index' };

const SORTABLE_FLOOR_GROUP_NAME = 'sortable-floor-group-';

function getFloorGroupIdFromHtmlId(element) {
  return parseInt(element.id.replace(SORTABLE_FLOOR_GROUP_NAME, ''), 10);
}

function sortFloorOptionsAsync(floorGroupId, floorOptionIds) {
  const variables = { floorGroupId, ids: floorOptionIds };
  return api.graphql(sortFloorOptionsQuery, variables);
}

class Floor extends Component {
  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.onAddFloorGroup = this.onAddFloorGroup.bind(this);
    this.onSetFloorGroups = this.onSetFloorGroups.bind(this);
    this.onSortFloorGroups = this.onSortFloorGroups.bind(this);
    this.onSetFloorOptions = this.onSetFloorOptions.bind(this);
    this.onSortFloorOptions = this.onSortFloorOptions.bind(this);
  }

  onDelete() {
    const { props } = this;
    const { floor } = this.props;

    props.deleteFloor(floor.id);
  }

  onAddFloorGroup(e) {
    e.preventDefault();

    const { floor } = this.props;
    ModalService.open(ModalNames.FLOOR_GROUP_FORM, { floor });
  }

  onSetFloorGroups(floorId, floorGroups) {
    const { props } = this;
    props.setFloorGroups(floorId, floorGroups);
  }

  onSortFloorGroups() {
    const { props } = this;
    const { floor } = this.props;

    const ids = floor.floorGroups.map((fg) => fg.id);

    const variables = { floorId: floor.id, ids };
    props.sortFloorGroups(variables);
  }

  onSetFloorOptions(floorGroupId, floorOptions) {
    const { props } = this;
    props.setFloorOptions(floorGroupId, floorOptions);
  }

  onSortFloorOptions(event) {
    const { floors } = this.props;
    let floorGroupIds = [event.from, event.to].map((el) => getFloorGroupIdFromHtmlId(el));
    // Get unique group ids
    floorGroupIds = [...new Set(floorGroupIds)];

    const promises = floorGroupIds.map((floorGroupId) => {
      const floorOptionIds = getFloorOptionIdsByFloorGroupId(floors, floorGroupId);
      return sortFloorOptionsAsync(floorGroupId, floorOptionIds);
    });

    Promise.all(promises)
      .then(() => {
        console.log('Floor options sorted.');
      });
  }

  render() {
    const { floor, index, floorableType } = this.props;

    if (!floor) return null;

    return (
      <Card>
        <CardHeader className="bg-light">
          <div className="d-flex">
            <div className="flex-grow-1">
              <strong className="text-uppercase text-dark">{floor.name}</strong>
            </div>
            {isAdmin() && (
              <div>
                <ActionsMenu floor={floor} onDelete={this.onDelete} index={index} />
              </div>
            )}
          </div>
        </CardHeader>

        <CardBody>
          <Row>
            <Col lg={3} md={4} sm={5} xs={12}>
              <div>
                <img src={floor.imageUrl || DEFAULT_IMAGE} alt="" className="floor-image" />
              </div>
              <div className="d-flex justify-content-around my-3">
                <div><Beds>{floor.bedrooms}</Beds></div>
                <div><Baths>{floor.bathrooms}</Baths></div>
                {!v.isBlank(floor.size) && (
                  <div className="text-uppercase">
                    {i18n.t('sizeSqft', { ...i18nOpts, size: floor.size })}
                  </div>
                )}
              </div>
              {floor.isBelowGrade && (
                <div className="text-center">{i18n.t('belowGrade', i18nOpts)}</div>
              )}
            </Col>
            <Col lg={9} md={8} sm={7} xs={12}>
              <ReactSortable
                list={floor.floorGroups}
                handle=".sortable-floor-group-handler"
                draggable=".sortable-floor-group-item"
                setList={(floorGroups) => this.onSetFloorGroups(floor.id, floorGroups)}
                onEnd={this.onSortFloorGroups}
              >
                {
                  floor.floorGroups.map((fg) => (
                    <FloorGroup key={`floor-group-${fg.id}`} floor={floor} floorGroup={fg} floorableType={floorableType}>
                      <ListGroup>
                        <ReactSortable
                          id={`${SORTABLE_FLOOR_GROUP_NAME}${fg.id}`}
                          list={fg.floorOptions}
                          group="sortable-floor-option-group"
                          handle=".sortable-floor-option-handler"
                          draggable=".sortable-floor-option-item"
                          setList={(floorOptions) => this.onSetFloorOptions(fg.id, floorOptions)}
                          onEnd={this.onSortFloorOptions}
                        >
                          {
                            fg.floorOptions.map((fo) => (
                              <FloorOption key={`floor-option-${fo.id}`} floorGroup={fg} floorOption={fo} floorableType={floorableType} />
                            ))
                          }
                        </ReactSortable>
                      </ListGroup>
                    </FloorGroup>
                  ))
                }
              </ReactSortable>

              {isAdmin() && (
                <div className="text-right">
                  <Link to="/" onClick={this.onAddFloorGroup}>
                    <i className="fas fa-plus-circle mr-2" />
                    {i18n.t('buttons.addGroup', i18nOpts)}
                  </Link>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

Floor.propTypes = {
  floor: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  floorableType: PropTypes.oneOf(Object.values(FloorableTypes)).isRequired
};

Floor.defaultProps = {};

export default connect((store) => ({
  floors: store.floors.floors
}), {
  deleteFloor,
  setFloorGroups,
  sortFloorGroups,
  setFloorOptions
})(Floor);
