import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import _ from 'lodash';

import CollapsibleFilterGroup from '../../../../../common/CollapsibleFilterGroup';
import ListItem from './ListItem';
import { CustomerVerificationStatuses } from '../utils';
import useIsVerifiedEnabled from '../../../../../../hooks/useIsVerifiedEnabled';

const i18nOpts = { scope: 'components.admin.customers.list.filtersModal.verificationProgressFilter.index' };

const VerificationProgressFilter = ({ filters, onChange }) => {
  const verificationStatuses = filters.verificationStatuses || [];
  const availableStatuses = Object.values(CustomerVerificationStatuses);
  const isVerifiedEnabled = useIsVerifiedEnabled();

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ verificationStatuses: _.xor(verificationStatuses, [value]) });
  };

  const filtersCount = useMemo(() => verificationStatuses.length, [filters]);

  if (!isVerifiedEnabled) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      {
        availableStatuses.map((status) => (
          <ListItem
            key={`verification-status-${status}`}
            value={status}
            selectedValues={verificationStatuses}
            onChange={onToggle}
          />
        ))
      }
    </CollapsibleFilterGroup>
  );
};

VerificationProgressFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

VerificationProgressFilter.defaultProps = {
  onChange: () => {}
};

export default VerificationProgressFilter;
