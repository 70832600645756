import React from 'react';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { getCustomerPreApprovalAmount } from '../../../../../../../utils/customerUtils';
import { formatCurrency } from '../../../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.show.overview.verificationSection.preApproval.index' };

const PreApproval = ({ customer }) => {
  const amount = getCustomerPreApprovalAmount(customer) || 0;

  return (
    <div>
      <div className="font-weight-500">
        {i18n.t('title', i18nOpts)}
      </div>
      <div className="d-flex align-items-center gap-2">
        <div className="col-gray-700 font-18 font-weight-600">
          {formatCurrency(amount)}
        </div>
      </div>
    </div>
  );
};

PreApproval.propTypes = {};

PreApproval.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
}))(PreApproval);
