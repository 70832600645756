import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import ReactTooltip from 'react-tooltip';

import {
  getModels, cleanModels, setModelsSearchFilters, setModelsViewMode
} from '../../../../store/actions/modelActions';
import ListEmpty from '../../../common/ListEmpty';
import ProductTypeFilter from './ProductTypeFilter';
import { isAdmin } from '../../../../utils/authUtils';
import SearchBar from '../../../common/SearchBar';
import { ViewModes } from '../../../../constants';
import GridView from './GridView';
import ListView from './ListView';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import ListPagination from '../../../common/ListPagination';
import Loading from '../../../ui/Loading';
import CommunityFilter from '../../../global/filters/CommunityFilter';

const i18nOpts = { scope: 'components.admin.models.list.index' };

class List extends Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };

    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
    this.onViewModeChange = this.onViewModeChange.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    const extraProps = {
      searchFilters: { query: undefined, community: undefined, productType: undefined }
    };
    props.cleanModels(extraProps)
      .then(() => this.loadModels());
    ReactTooltip.rebuild();
  }

  componentDidUpdate(prevProps) {
    ReactTooltip.rebuild();
    this.isFilterChanged(prevProps?.searchFilters || {});
  }

  onChangePage(page) {
    this.loadModels(page);
  }

  onChangeFilter(filter) {
    const { props } = this;
    props.setModelsSearchFilters(filter);
  }

  onViewModeChange(viewMode) {
    const { props } = this;
    props.setModelsViewMode(viewMode);
  }

  searchSubmit(e, q) {
    e.preventDefault();
    const { props } = this;

    props.setModelsSearchFilters({ query: q });
  }

  loadModels(page = 1) {
    const { props } = this;
    const { searchFilters, currentCompany } = props;

    const filter = { companyId: currentCompany.id };
    if (searchFilters?.community) filter.communityId = searchFilters.community.id;
    if (searchFilters?.productType) filter.productTypeIds = [searchFilters.productType.id];
    if (searchFilters?.query) filter.query = searchFilters.query;

    const variables = { filter, page };

    if (searchFilters?.sort) variables.sort = searchFilters.sort;
    props.getModels(variables)
      .finally(() => this.setState({ mounted: true }));
  }

  isFilterChanged(prevFilters = {}) {
    const { props } = this;
    const { searchFilters } = props;
    const { community, productType, sort } = searchFilters;
    const { community: prevCommunity, productType: prevProductType, sort: prevSort } = prevFilters;
    let changed = false;
    if (searchFilters?.query !== prevFilters?.query) changed = true;
    else if (community?.id !== prevCommunity?.id) changed = true;
    else if (productType?.id !== prevProductType?.id) changed = true;
    else if (JSON.stringify(sort) !== JSON.stringify(prevSort)) changed = true;

    if (changed) {
      props.cleanModels()
        .then(() => this.loadModels());
    }
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return <Loading loading fullScreen />;

    const {
      models, pagination, loading, viewMode
    } = this.props;

    return (
      <div>
        <Helmet title={i18n.t('title', i18nOpts)} />

        <div className="d-flex flex-column flex-md-row align-md-items-center mb-2">
          <div className="flex-grow-1">
            <h2>{i18n.t('title', i18nOpts)}</h2>
          </div>
        </div>

        <div className="d-flex mb-5 align-items-center flex-wrap gap-3">
          <div className="flex-grow-1">
            <SearchBar
              onSubmit={this.searchSubmit}
              inputProps={{ bsSize: 'md' }}
              searchFields="name or ID"
            />
          </div>
          <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
            <CommunityFilter onChange={(community) => this.onChangeFilter({ community })} />

            <ProductTypeFilter onChange={(productType) => this.onChangeFilter({ productType })} />

            <ViewModesDropdown viewMode={viewMode} onChange={this.onViewModeChange} />

            {isAdmin() && (
              <Link to="/admin/models/form" className="btn btn-primary text-nowrap">
                <i className="fas fa-plus mr-2" />
                {i18n.t('buttons.add', i18nOpts)}
              </Link>
            )}
          </div>
        </div>

        {loading ? (
          <Loading loading />
        ) : (
          <>
            <ListEmpty loading={loading} items={models} />

            {
              models.length > 0 && (
                <div className="mb-4">
                  { viewMode === ViewModes.GRID ? (
                    <GridView />
                  ) : (
                    <ListView />
                  ) }
                </div>
              )
            }

            <div className="text-right mt-4">
              <ListPagination pagination={pagination} onPress={this.onChangePage} />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect((store) => ({
  models: store.models.models,
  loading: store.models.getModels.loading,
  pagination: store.models.pagination,
  searchFilters: store.models.searchFilters,
  viewMode: store.models.viewMode,
  currentCompany: store.companies.currentCompany
}), {
  getModels,
  cleanModels,
  setModelsSearchFilters,
  setModelsViewMode
})(List);
