import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, Col, Collapse, FormGroup, Input, Label, Row, Spinner
} from 'reactstrap';
import { BiLinkExternal, BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';
import v from 'voca';

import { saveCompany } from '../../../../../store/actions/companyActions';
import { parseBoolean } from '../../../../../utils/parserUtils';
import { uuidv4 } from '../../../../../utils/randomUtils';
import DocumentFile from '../../../../common/DocumentUploader/DocumentFile';
import FileTypeIcon from '../../../../common/FileTypeIcon';
import { stripToEmpty } from '../../../../../utils/stringUtils';
import InputError, { isInputInvalid } from '../../../../ui/InputError';
import { goToIntegrations } from '../../utils';
import { getError } from '../../../../../utils/requestUtils';
import { DocuSignTemplateTypes } from '../../../../../constants';
import {
  createDocumentToSignTemplateAsync,
  createTemplateViewAsync,
  fetchDocuSignTemplatesAsync
} from '../utils';

const i18nOpts = { scope: 'components.admin.integrations.docuSign.settings.index' };

const DEFAULT_SETTINGS = Object.freeze({
  type: DocuSignTemplateTypes.UPLOAD_DOCUMENT,
  templateName: i18n.t('templateNameDefault', i18nOpts),
  emailSubject: i18n.t('emailSubjectDefault', i18nOpts)
});

const DocumentTemplate = ({ templateUrl }) => {
  if (v.isBlank(templateUrl)) return null;

  return (
    <a
      href={templateUrl}
      target="_blank"
      rel="noreferrer"
      className="d-flex align-items-center mb-2"
    >
      <span className="mr-1">{i18n.t('editDocumentTemplate', i18nOpts)}</span>
      <BiLinkExternal size="1.1rem" />
    </a>
  );
};

const Settings = ({ currentCompany, ...props }) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState(null);
  const [documentToSign, setDocumentToSign] = useState(currentCompany.documentToSign);
  const [newDocumentToSign, setNewDocumentToSign] = useState(null);
  const [documentTemplateUrl, setDocumentTemplateUrl] = useState(null);
  const [saving, setSaving] = useState(false);
  const [templates, setTemplates] = useState([]);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));

    if (name === 'type') onDeleteDocumentToSign();
  };

  const onNewDocumentToSignChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setNewDocumentToSign({
      id: uuidv4(),
      file
    });
  };

  const onDocumentToSignUploaded = (document) => {
    setDocumentToSign(document);
  };

  const onDeleteDocumentToSign = () => {
    setDocumentToSign(null);
    setNewDocumentToSign(null);
    setDocumentTemplateUrl(null);
    setForm((prevForm) => (
      { ...prevForm, documentToSignId: null, templateId: null }
    ));
  };

  const createTemplateView = (templateId) => {
    setDocumentTemplateUrl(null);
    if (v.isBlank(templateId)) return;

    const variables = { companyId: currentCompany.id, templateId };
    createTemplateViewAsync(variables)
      .then((templateUrl) => setDocumentTemplateUrl(templateUrl));
  };

  const saveDocuSignSettings = (docuSignSettings = {}) => {
    const variables = { input: { id: currentCompany.id, docuSignSettings } };
    props.saveCompany(variables)
      .then(() => toast.success(i18n.t('messages.saved', i18nOpts)))
      .catch(() => toast.error(i18n.t('messages.notSaved', i18nOpts)))
      .finally(() => {
        setError(null);
        setSaving(false);
        loadTemplates();
      });
  };

  const onSave = () => {
    setSaving(true);

    const templateId = stripToEmpty(form.templateId);
    const isEdit = !v.isBlank(templateId);

    const input = {
      enabled: parseBoolean(form.enableGenericTemplate),
      type: form.type,
      templateName: stripToEmpty(form.templateName),
      emailSubject: stripToEmpty(form.emailSubject),
      documentToSignId: stripToEmpty(documentToSign?.id),
      templateId
    };

    const variables = { id: currentCompany.id, input };
    createDocumentToSignTemplateAsync(variables)
      .then((newTemplateId) => {
        onTextChange({ target: { name: 'templateId', value: newTemplateId } });

        const docuSignSettings = {
          genericTemplate: {
            ...input,
            templateId: stripToEmpty(newTemplateId)
          }
        };
        saveDocuSignSettings(docuSignSettings);
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (formattedError === null) toast.error(i18n.t(`messages.${isEdit ? 'locked' : 'notSaved'}`, i18nOpts));
      })
      .finally(() => setSaving(false));
  };

  const loadTemplates = () => {
    const variables = { companyId: currentCompany.id };
    fetchDocuSignTemplatesAsync(variables)
      .then((fetchedTemplates) => setTemplates(fetchedTemplates))
      .catch(() => setTemplates([]));
  };

  const onCancel = () => goToIntegrations();

  useEffect(() => {
    const genericTemplate = currentCompany.docuSignSettings?.genericTemplate || {};
    const genericTemplateForm = {
      enableGenericTemplate: parseBoolean(genericTemplate.enabled),
      type: genericTemplate.type || DEFAULT_SETTINGS.type,
      templateName: genericTemplate.templateName || DEFAULT_SETTINGS.templateName,
      emailSubject: genericTemplate.emailSubject || DEFAULT_SETTINGS.emailSubject,
      templateId: genericTemplate.templateId
    };
    setForm(genericTemplateForm);
  }, []);

  useEffect(() => {
    createTemplateView(form.templateId);
  }, [form.templateId]);

  useEffect(() => {
    loadTemplates();
  }, []);

  return (
    <div>
      <Row className="mb-4">
        <Col xs="12" sm="10" md="8" lg="6">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="enableGenericTemplate"
                id="enableGenericTemplate"
                value={!parseBoolean(form.enableGenericTemplate)}
                checked={parseBoolean(form.enableGenericTemplate)}
                onChange={onTextChange}
              />
              {i18n.t('enableGenericTemplate', i18nOpts)}
            </Label>
          </FormGroup>
        </Col>
      </Row>

      <Collapse isOpen={parseBoolean(form.enableGenericTemplate)} className="mb-4 pl-4">
        <Row>
          <Col xs="12" sm="10" md="8" lg="6">
            <div className="d-flex mb-3">
              <FormGroup check>
                <Label for="uploadDocument">
                  <Input
                    type="radio"
                    name="type"
                    id="uploadDocument"
                    checked={form.type === DocuSignTemplateTypes.UPLOAD_DOCUMENT}
                    value={DocuSignTemplateTypes.UPLOAD_DOCUMENT}
                    onChange={onTextChange}
                  />
                  {i18n.t('uploadDocument', i18nOpts)}
                </Label>
              </FormGroup>

              <FormGroup check className="ml-3">
                <Label for="selectTemplate">
                  <Input
                    type="radio"
                    name="type"
                    id="selectTemplate"
                    checked={form.type === DocuSignTemplateTypes.SELECT_TEMPLATE}
                    value={DocuSignTemplateTypes.SELECT_TEMPLATE}
                    onChange={onTextChange}
                  />
                  {i18n.t('selectTemplate', i18nOpts)}
                </Label>
              </FormGroup>
            </div>

            {form.type === DocuSignTemplateTypes.UPLOAD_DOCUMENT && (
              <>
                <FormGroup>
                  <Label for="templateName">{i18n.t('templateName', i18nOpts)}</Label>
                  <Input
                    type="text"
                    name="templateName"
                    id="templateName"
                    value={form.templateName || ''}
                    onChange={onTextChange}
                    invalid={isInputInvalid(error, 'templateName')}
                  />
                  <InputError name="templateName" error={error} />
                </FormGroup>

                <FormGroup>
                  <Label for="emailSubject">{i18n.t('emailSubject', i18nOpts)}</Label>
                  <Input
                    type="text"
                    name="emailSubject"
                    id="emailSubject"
                    value={form.emailSubject || ''}
                    onChange={onTextChange}
                    invalid={isInputInvalid(error, 'emailSubject')}
                  />
                  <InputError name="emailSubject" error={error} />
                </FormGroup>

                <FormGroup>
                  <Label className="d-block">{i18n.t('documentToSign', i18nOpts)}</Label>
                  {documentToSign && (
                    <>
                      <div className="d-flex align-items-center mb-2">
                        <div className="d-flex align-items-center">
                          <FileTypeIcon filename={documentToSign.name} size="sm" />
                          <a href={documentToSign.fileUrl} target="_blank" rel="noreferrer">
                            {documentToSign.name}
                          </a>
                        </div>

                        <BiTrash
                          size="1.1rem"
                          role="button"
                          className="text-danger ml-1"
                          title={i18n.t('buttons.delete')}
                          onClick={onDeleteDocumentToSign}
                        />
                      </div>

                      <DocumentTemplate templateUrl={documentTemplateUrl} />
                    </>
                  )}

                  <Label for="documentToSignId" className="btn btn-sm btn-primary">
                    <i className="fas fa-plus mr-2" />
                    {i18n.t('buttons.uploadFile', i18nOpts)}
                  </Label>

                  <Input
                    type="file"
                    name="documentToSignId"
                    id="documentToSignId"
                    onChange={onNewDocumentToSignChange}
                    className="d-none"
                    invalid={isInputInvalid(error, 'documentToSignId')}
                  />
                  <InputError name="documentToSignId" error={error} />
                </FormGroup>

                {newDocumentToSign && (
                  <DocumentFile
                    documentFile={newDocumentToSign}
                    key={`document-file-${newDocumentToSign.id}`}
                    completeDelay={1000}
                    excluded
                    onUploaded={onDocumentToSignUploaded}
                  />
                )}
              </>
            )}

            {form.type === DocuSignTemplateTypes.SELECT_TEMPLATE && (
              <>
                <FormGroup>
                  <Label for="templateId">{i18n.t('templateId', i18nOpts)}</Label>
                  <Input
                    type="select"
                    name="templateId"
                    id="templateId"
                    value={form.templateId || ''}
                    onChange={onTextChange}
                    invalid={isInputInvalid(error, 'templateId')}
                  >
                    <option value="">{i18n.t('select.select')}</option>
                    {
                      templates.map((template) => (
                        <option key={`template-option-${template.templateId}`} value={template.templateId}>
                          {template.name}
                        </option>
                      ))
                    }
                  </Input>
                  <InputError name="templateId" error={error} />
                </FormGroup>

                <DocumentTemplate templateUrl={documentTemplateUrl} />
              </>
            )}
          </Col>
        </Row>
      </Collapse>

      <Button color="primary" onClick={onSave} disabled={saving} className="mr-3">
        {saving && (<Spinner size="sm" className="mr-2" />)}
        {i18n.t('buttons.save')}
      </Button>
      <Button outline color="secondary" onClick={onCancel} disabled={saving} className="mr-3">
        {i18n.t('buttons.cancel')}
      </Button>
    </div>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}), {
  saveCompany
})(Settings);
