import _ from 'lodash';

import api from '../../../../api';
import { deleteProjectBuildingQuery, listProjectBuildingsQuery, sortProjectBuildingsQuery } from '../../../../graphql';

export function fetchProjectBuildingsAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(listProjectBuildingsQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

export function sortProjectBuildingsAsync(variables) {
  return api.graphql(sortProjectBuildingsQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

export function groupAndSortProjectBuildings(items) {
  const groupedItems = _.groupBy(items, (projectBuilding) => projectBuilding.project.id);
  const projectsMap = {};

  items.forEach((projectBuilding) => {
    const projectId = projectBuilding.project.id;
    if (groupedItems[projectId]) projectsMap[projectId] = projectBuilding.project;
  });

  const projects = Object.values(projectsMap);

  // Sort
  const sortedProjects = _.sortBy(projects, ['name']);
  return sortedProjects.map((project) => {
    const projectBuildings = _.sortBy(groupedItems[project.id], ['displayOrder']);
    return { ...project, projectBuildings };
  });
}

export function deleteProjectBuildingAsync(projectBuildingId) {
  const variables = { id: projectBuildingId };

  return api.graphql(deleteProjectBuildingQuery, variables)
    .then(({ data: { item } }) => Promise.resolve(item));
}
