import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import TimeLineElement from '../../../../common/TimeLineElement';
import HomeSavedIcon from '../../../../../assets/icons/customer-activities/HomeSavedIcon';
import ActivityDate from '../components/ActivityDate';
import HomeInfo from './HomeInfo';

const i18nOpts = { scope: 'components.global.customer.customerTimeline.builtHome.index' };

const BuiltHome = ({
  activity, innerKey
}) => {
  const { home } = activity;

  return (
    <TimeLineElement
      innerKey={innerKey}
      showDate={activity.showDate}
      date={activity.createdAt}
      Icon={<HomeSavedIcon />}
    >
      <div className="d-flex align-items-start gap-3">
        <div className="activity-timeline-title">{i18n.t('title', i18nOpts)}</div>
        <ActivityDate date={activity.createdAt} />
      </div>
      <div>{i18n.t('description', i18nOpts)}</div>

      <HomeInfo home={home} />
    </TimeLineElement>
  );
};

BuiltHome.propTypes = {
  activity: PropTypes.objectOf(PropTypes.any).isRequired,
  innerKey: PropTypes.string.isRequired,
};

BuiltHome.defaultProps = {};

export default BuiltHome;
