import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import _ from 'lodash';

import { fetchQuickPossessionsBathsAsync } from './utils';
import CollapsibleFilterGroup from '../../../../../../common/CollapsibleFilterGroup';
import ShowMoreContent from '../../../../../../common/ShowMoreContent';
import ListItem from './ListItem';

const i18nOpts = { scope: 'components.admin.quickPossessions.list.components.quickPossessionFiltersModal.bathsFilter.index' };

const BathsFilter = ({ filters, onChange, currentCompany }) => {
  const baths = filters.baths || [];
  const [availableBaths, setAvailableBaths] = useState([]);
  const [loading, setLoading] = useState(false);

  const onToggle = (event) => {
    const { value } = event.target;
    onChange({ baths: _.xor(baths, [Number(value)]) });
  };

  const loadQuickPossessionsBaths = () => {
    fetchQuickPossessionsBathsAsync(currentCompany.id)
      .then((result) => setAvailableBaths(result))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadQuickPossessionsBaths();
  }, []);

  const filtersCount = useMemo(() => baths.length, [filters]);

  if (loading || !availableBaths.length) return null;

  return (
    <CollapsibleFilterGroup title={i18n.t('title', i18nOpts)} filtersCount={filtersCount}>
      <ShowMoreContent
        items={availableBaths}
        renderItem={(bathsItem) => (
          <ListItem
            key={`baths-${bathsItem}`}
            value={bathsItem}
            selectedValues={baths}
            onChange={onToggle}
          />
        )}
        showMoreButtonExtraClass="mt-2"
      />
    </CollapsibleFilterGroup>
  );
};

BathsFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func
};

BathsFilter.defaultProps = {
  onChange: () => {}
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
}))(BathsFilter);
