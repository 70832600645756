import v from 'voca';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import React from 'react';
import {
  getProjectAvailabilityName,
  getProjectStatusName
} from '../../../../../utils/projectUtils';
import StatusIndicator from '../../../../global/StatusIndicator';
import { getCreatorDisplayName, getLastModifierDisplayName } from '../../../../../utils/auditUtils';
import { isAdmin } from '../../../../../utils/authUtils';
import { getProjectBuildAndPriceStartingPointName } from '../../../../../utils/enumUtils';

const i18nOpts = { scope: 'components.admin.projects.show.overview.index' };

const Overview = ({ project }) => {
  if (!project) return null;

  const creatorName = getCreatorDisplayName(project);
  const lastModifierName = getLastModifierDisplayName(project);

  return (
    <div>
      {!v.isBlank(project.hashId) && (
        <div className="mb-4">
          <div className="label">{i18n.t('projectId', i18nOpts)}</div>
          <div><strong>{project.hashId}</strong></div>
        </div>
      )}

      {project.city && (
        <>
          <div className="mb-4">
            <div className="label">{i18n.t('city', i18nOpts)}</div>
            <div><strong>{project.city.name}</strong></div>
          </div>

          {!v.isBlank(project.city.state) && (
            <div className="mb-4">
              <div className="label">{i18n.t('province', i18nOpts)}</div>
              <div><strong>{project.city.state}</strong></div>
            </div>
          )}
        </>
      )}

      {!v.isBlank(project.subRegion) && (
        <div className="mb-4">
          <div className="label">{i18n.t('subRegion', i18nOpts)}</div>
          <div><strong>{project.subRegion}</strong></div>
        </div>
      )}

      {!v.isBlank(project.address) && (
        <div className="mb-4">
          <div className="label">{i18n.t('address', i18nOpts)}</div>
          <div className="white-space-pre-line"><strong>{project.address}</strong></div>
        </div>
      )}

      {!v.isBlank(project.buildingType) && (
        <div className="mb-4">
          <div className="label">{i18n.t('buildingType', i18nOpts)}</div>
          <div className="white-space-pre-line"><strong>{project.buildingType}</strong></div>
        </div>
      )}

      <div className="mb-4">
        <div className="label">{i18n.t('availability', i18nOpts)}</div>
        <div>
          <strong>{getProjectAvailabilityName(project.availability)}</strong>
        </div>
      </div>

      <div className="mb-4">
        <div className="label">{i18n.t('type', i18nOpts)}</div>
        <div>
          <strong>{i18n.t(`types.${project.type.toLowerCase()}`, i18nOpts)}</strong>
        </div>
      </div>

      {!v.isBlank(project.externalId) && (
        <div className="mb-4">
          <div className="label">{i18n.t('externalId', i18nOpts)}</div>
          <div><strong>{project.externalId}</strong></div>
        </div>
      )}

      {!v.isBlank(project.description) && (
        <div className="mb-4">
          <div className="label">{i18n.t('description', i18nOpts)}</div>
          <div className="white-space-pre-line">{project.description}</div>
        </div>
      )}

      {!v.isBlank(project.longDescription) && (
        <div className="mb-4">
          <div className="label">{i18n.t('longDescription', i18nOpts)}</div>
          <div dangerouslySetInnerHTML={{ __html: project.longDescription }} />
        </div>
      )}

      { isAdmin() && (
        <>
          {!v.isBlank(creatorName) && (
            <div className="mb-4">
              <div className="label">{i18n.t('createdBy', i18nOpts)}</div>
              <div className="white-space-pre-line">{creatorName}</div>
            </div>
          )}

          {!v.isBlank(lastModifierName) && (
            <div className="mb-4">
              <div className="label">{i18n.t('lastModifiedBy', i18nOpts)}</div>
              <div className="white-space-pre-line">{lastModifierName}</div>
            </div>
          )}
        </>
      )}

      <div className="mb-4">
        <div className="label">{i18n.t('status', i18nOpts)}</div>
        <div>
          <div className="d-inline-block mr-2">
            <StatusIndicator status={project.status} displayTooltip={false} />
          </div>
          <strong>{getProjectStatusName(project.status)}</strong>
        </div>
      </div>

      <div className="mb-4">
        <div className="label">{i18n.t('flowForBuildAndPrice', i18nOpts)}</div>
        <div className="white-space-pre-line">
          <strong>
            {getProjectBuildAndPriceStartingPointName(project.buildAndPriceStartingPoint)}
          </strong>
        </div>
      </div>
    </div>
  );
};

export default connect((store) => ({
  project: store.projects.project
}), {})(Overview);
