import { listCitiesQuery, listTaxRatesQuery, listProjectsQuery } from '../../../../graphql';
import api from '../../../../api';

export function fetchCitiesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(listCitiesQuery, variables)
    .then(({ data }) => Promise.resolve(data.items))
    .catch(() => Promise.reject());
}

export async function fetchTaxRatesAsync(variables) {
  return api.graphql(listTaxRatesQuery, variables)
    .then(({ data }) => Promise.resolve(data.items));
}

export function fetchCommunitiesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(`
    query ListCommunities($filter: CommunityFilterInput) {
      result: listCommunities(filter: $filter, pageSize: null) {
        items {
          id
          name
          taxRate {
            id
            name 
          }
        }
      }
    }
  `, variables)
    .then(({ data: { result: { items } } }) => Promise.resolve(items));
}

export function fetchProjectsAsync(companyId) {
  const variables = { filter: { companyId }, pageSize: null };

  return api.graphql(listProjectsQuery, variables)
    .then(({ data: { result: { items } } }) => Promise.resolve(items));
}
