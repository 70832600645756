import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import {
  Nav, NavItem, NavLink
} from 'reactstrap';

import Employers from './Employers';
import MonthlyAmountsChart from '../components/MonthlyAmountsChart';
import MonthlyAmountsTable from '../components/MonthlyAmountsTable';
import { Colors } from '../../../../../constants';
import AttributesTable from './AttributesTable';

const i18nOpts = { scope: 'components.admin.customers.financialReport.employersSection.index' };

const EmployersSection = ({ financialReport, customer }) => {
  const { companyContact } = customer;
  const { affordability } = companyContact;
  const { assessedAt: reportDate } = affordability;

  const {
    primaryEmployerIncome, secondaryEmployerIncome, monthlyIncomeTotalAmounts,
    monthlyIncomeTotalCounts
  } = financialReport;
  const primaryAmounts = primaryEmployerIncome.monthlyIncomeAmounts;

  const chartDatasets = [];
  chartDatasets.push({
    label: i18n.t('primary', i18nOpts),
    data: primaryAmounts,
    color: Colors.PRIMARY_700
  });

  if (secondaryEmployerIncome) {
    const secondaryAmounts = secondaryEmployerIncome.monthlyIncomeAmounts;

    chartDatasets.push({
      label: i18n.t('secondary', i18nOpts),
      data: secondaryAmounts,
      color: Colors.PRIMARY_300
    });
  }

  const monthlyTotalIncomes = monthlyIncomeTotalAmounts.map((amount, index) => ({
    amount,
    count: monthlyIncomeTotalCounts[index]
  }));

  return (
    <div>
      <Employers />

      <Nav tabs className="mb-4">
        <NavItem>
          <NavLink active className="font-16">
            {i18n.t('tabs.overallIncome', i18nOpts)}
          </NavLink>
        </NavItem>
      </Nav>

      <MonthlyAmountsTable monthlyTotals={monthlyTotalIncomes} />

      <div className="page-break-always" />

      <div style={{ height: '300px' }}>
        <MonthlyAmountsChart titleY={i18n.t('titleY', i18nOpts)} datasets={chartDatasets} reportDate={reportDate} />
      </div>

      <AttributesTable />
    </div>
  );
};

EmployersSection.propTypes = {};

EmployersSection.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
  customer: store.customers.customer
}))(EmployersSection);
