import React from 'react';
import { Card, CardBody } from 'reactstrap';
import i18n from 'i18n-js';
import * as PropTypes from 'prop-types';
import { RiHotelBedLine } from 'react-icons/ri';
import { BiBath, BiArea } from 'react-icons/bi';
import v from 'voca';
import { Link } from 'gatsby';

import './styles.scss';
import { DEFAULT_IMAGE } from '../../../../../../constants';

const i18nOpts = { scope: 'components.admin.buildingModels.list.gridView.listItem.index' };

const IconElement = ({ text, children }) => (
  <div className="building-model-icons">
    <span className="text-success mr-1">
      { children }
    </span>
      &nbsp;
    <span className="font-weight-light">{ text }</span>
  </div>
);

const ListItem = ({ buildingModel }) => {
  if (!buildingModel) return null;

  const imageUrl = buildingModel.primaryImageUrl || DEFAULT_IMAGE;

  return (
    <Card className="building-model-container">
      <CardBody>
        <div className="text-center font-18 font-weight-light">
          <Link to={`/admin/buildingModels/${buildingModel.id}`}>
            {buildingModel.name}
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-between my-2">
          <IconElement text={i18n.t('bed', { ...i18nOpts, beds: buildingModel.bedrooms || 0 })}>
            <RiHotelBedLine size="1.2rem" />
          </IconElement>
          <IconElement text={i18n.t('bath', { ...i18nOpts, baths: buildingModel.bathrooms || 0 })}><BiBath size="1.2rem" /></IconElement>
          { !v.isBlank(buildingModel.size) && (
          <IconElement text={i18n.t('sizeSqft', { ...i18nOpts, size: buildingModel.size })}>
            <BiArea size="1.2rem" />
          </IconElement>
          )}
        </div>
        <div className="d-flex">
          <div className="flex-grow-1">
            <img src={imageUrl} alt={buildingModel.name} className="building-model-image w-100" />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

ListItem.propTypes = {
  buildingModel: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
