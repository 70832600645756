import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ModalBody } from 'reactstrap';
import i18n from 'i18n-js';

import NewCustomerIcon from '../../../../../assets/icons/sales-invitation/NewCustomerIcon';
import ExistingCustomerIcon
  from '../../../../../assets/icons/sales-invitation/ExistingCustomerIcon';
import SelectionButton from '../../components/SelectionButton';
import { Steps } from '../constants';
import useHomeRental from '../../../../../hooks/useHomeRental';

const i18nOpts = { scope: 'components.global.salesInvitationModal.invitationWizard.customerSelectionType.index' };

const CustomerSelectionType = ({ onContinue }) => {
  const { typeI18nKey } = useHomeRental();

  return (
    <ModalBody className="p-0">
      <div className="pt-0 px-3 pb-3 border-bottom">
        <div className="font-18 font-weight-600 mb-1">{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</div>
        <div className="font-14">{i18n.t(`${typeI18nKey}.description`, i18nOpts)}</div>
      </div>

      <div className="p-3">
        <Row>
          <Col>
            <SelectionButton
              title={i18n.t(`${typeI18nKey}.buttons.newCustomer`, i18nOpts)}
              Icon={NewCustomerIcon}
              onClick={() => onContinue(Steps.NEW_CUSTOMER)}
            />
          </Col>
          <Col>
            <SelectionButton
              title={i18n.t(`${typeI18nKey}.buttons.existingCustomer`, i18nOpts)}
              Icon={ExistingCustomerIcon}
              onClick={() => onContinue(Steps.EXISTING_CUSTOMER)}
            />
          </Col>
        </Row>
      </div>
    </ModalBody>
  );
};

CustomerSelectionType.propTypes = {
  onContinue: PropTypes.func
};

CustomerSelectionType.defaultProps = {
  onContinue: () => {}
};

export default CustomerSelectionType;
