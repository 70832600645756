import React from 'react';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
import PropTypes from 'prop-types';

import { SortOrders } from '../../../constants';

const SortIcons = ({ field, sort }) => {
  const isSortField = sort?.field === field;

  if (!isSortField) return null;

  const isDesc = sort.direction === SortOrders.DESC;

  return (
    <span className="ml-1">
      {isDesc ? (<BiSortDown />) : (<BiSortUp />)}
    </span>
  );
};

SortIcons.propTypes = {
  field: PropTypes.string.isRequired,
  sort: PropTypes.objectOf(PropTypes.any)
};

SortIcons.defaultProps = {
  sort: {}
};

export default SortIcons;
