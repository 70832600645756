import React from 'react';

const ExistingCustomerImage = ({ ...props }) => (
  <svg width="292" height="206" viewBox="0 0 292 206" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M52.6668 119.872H35.4118C35.0366 119.872 34.7324 120.178 34.7324 120.556V137.912C34.7324 138.289 35.0366 138.595 35.4118 138.595H52.6668C53.042 138.595 53.3462 138.289 53.3462 137.912V120.556C53.3462 120.178 53.042 119.872 52.6668 119.872Z" fill="#DDF7F0" />
    <path d="M229.227 24.0972H177.851C177.476 24.0972 177.172 24.4031 177.172 24.7805V32.7878C177.172 33.1652 177.476 33.4711 177.851 33.4711H229.227C229.602 33.4711 229.906 33.1652 229.906 32.7878V24.7805C229.906 24.4031 229.602 24.0972 229.227 24.0972Z" fill="#DDF7F0" />
    <path d="M178.499 164.52H158.318C157.943 164.52 157.639 164.825 157.639 165.203V172.859C157.639 173.236 157.943 173.542 158.318 173.542H178.499C178.874 173.542 179.178 173.236 179.178 172.859V165.203C179.178 164.825 178.874 164.52 178.499 164.52Z" fill="white" />
    <path d="M249.773 40.1089H187.66C187.285 40.1089 186.98 40.4148 186.98 40.7922V165.377C186.98 165.755 187.285 166.061 187.66 166.061H249.773C250.148 166.061 250.452 165.755 250.452 165.377V40.7922C250.452 40.4148 250.148 40.1089 249.773 40.1089Z" fill="#DDF7F0" />
    <path d="M133.298 135.589H65.8297C65.4545 135.589 65.1504 135.895 65.1504 136.273V165.377C65.1504 165.754 65.4545 166.06 65.8297 166.06H133.298C133.673 166.06 133.977 165.754 133.977 165.377V136.273C133.977 135.895 133.673 135.589 133.298 135.589Z" fill="#DDF7F0" />
    <path d="M164.306 22.2689H28.0641C27.6889 22.2689 27.3848 22.5749 27.3848 22.9523V111.143C27.3848 111.52 27.6889 111.826 28.0641 111.826H164.306C164.682 111.826 164.986 111.52 164.986 111.143V22.9523C164.986 22.5749 164.682 22.2689 164.306 22.2689Z" fill="#DDF7F0" />
    <path d="M121.979 148.099V180.94H148.391V148.099H121.979ZM139.207 161.837C139.207 162.985 138.754 164.085 137.947 164.896C137.141 165.707 136.047 166.163 134.906 166.163C133.766 166.163 132.672 165.707 131.866 164.896C131.059 164.085 130.606 162.985 130.606 161.837V157.512H139.207V161.837Z" fill="#667085" />
    <path d="M121.979 162.432H130.652C130.623 162.235 130.608 162.036 130.606 161.837V157.512H139.207V161.837C139.205 162.036 139.19 162.235 139.161 162.432H148.391V148.099H121.979V162.432Z" fill="#D0D5DD" />
    <path d="M190.547 79.6001H79.8254C76.8239 79.6001 74.3906 82.0476 74.3906 85.0668V152.046C74.3906 155.065 76.8239 157.512 79.8254 157.512H190.547C193.548 157.512 195.982 155.065 195.982 152.046V85.0668C195.982 82.0476 193.548 79.6001 190.547 79.6001Z" fill="#667085" />
    <path d="M188.249 84.7335H82.1219C80.8087 84.7335 79.7441 85.8043 79.7441 87.1252V149.987C79.7441 151.308 80.8087 152.378 82.1219 152.378H188.249C189.563 152.378 190.627 151.308 190.627 149.987V87.1252C190.627 85.8043 189.563 84.7335 188.249 84.7335Z" fill="white" />
    <path d="M188.681 84.7336H81.69C81.174 84.7336 80.679 84.9399 80.3141 85.3069C79.9491 85.674 79.7441 86.1719 79.7441 86.691V89.9681H190.627V86.691C190.627 86.1719 190.422 85.674 190.057 85.3069C189.692 84.9399 189.197 84.7336 188.681 84.7336Z" fill="#D0D5DD" />
    <path d="M118.915 99.5205H93.861C93.4858 99.5205 93.1816 99.8264 93.1816 100.204V116.918C93.1816 117.296 93.4858 117.602 93.861 117.602H118.915C119.29 117.602 119.595 117.296 119.595 116.918V100.204C119.595 99.8264 119.29 99.5205 118.915 99.5205Z" fill="#00CEA6" />
    <path d="M154.996 101.716H128.094V102.741H154.996V101.716Z" fill="#00CEA6" />
    <path d="M168.583 108.219H128.094V109.244H168.583V108.219Z" fill="#00CEA6" />
    <path d="M161.789 114.721H128.094V115.746H161.789V114.721Z" fill="#00CEA6" />
    <path d="M111.089 132.972H93.861C93.4858 132.972 93.1816 133.278 93.1816 133.655V139.176C93.1816 139.554 93.4858 139.86 93.861 139.86H111.089C111.464 139.86 111.769 139.554 111.769 139.176V133.655C111.769 133.278 111.464 132.972 111.089 132.972Z" fill="#D0D5DD" />
    <path d="M136.035 132.972H118.806C118.431 132.972 118.127 133.278 118.127 133.655V139.176C118.127 139.554 118.431 139.86 118.806 139.86H136.035C136.41 139.86 136.714 139.554 136.714 139.176V133.655C136.714 133.278 136.41 132.972 136.035 132.972Z" fill="#D0D5DD" />
    <path d="M161.111 132.972H143.882C143.507 132.972 143.203 133.278 143.203 133.655V139.176C143.203 139.554 143.507 139.86 143.882 139.86H161.111C161.486 139.86 161.79 139.554 161.79 139.176V133.655C161.79 133.278 161.486 132.972 161.111 132.972Z" fill="#D0D5DD" />
    <path d="M210.636 158.776L207.874 161.711C207.69 161.906 207.468 162.06 207.222 162.165C206.977 162.27 206.712 162.324 206.445 162.322C206.178 162.32 205.915 162.263 205.67 162.155C205.426 162.047 205.206 161.889 205.025 161.692L195.968 152.016C195.812 151.847 195.726 151.626 195.727 151.395C195.728 151.165 195.816 150.944 195.974 150.777L199.096 147.451C199.252 147.283 199.466 147.182 199.694 147.168C199.922 147.154 200.147 147.228 200.322 147.374L210.459 155.909C210.666 156.08 210.835 156.292 210.957 156.532C211.079 156.772 211.151 157.035 211.167 157.304C211.184 157.573 211.146 157.842 211.054 158.095C210.963 158.349 210.821 158.581 210.636 158.776Z" fill="#344054" />
    <path d="M196.248 145.345L198.919 147.542L195.96 150.745L193.588 148.144L196.248 145.345Z" fill="#98A2B3" />
    <path d="M198.985 136.469C198.906 133.621 197.989 130.86 196.35 128.535C194.711 126.211 192.424 124.427 189.777 123.411C187.131 122.394 184.244 122.189 181.481 122.823C178.719 123.457 176.206 124.9 174.259 126.97C172.312 129.041 171.02 131.645 170.545 134.455C170.07 137.264 170.433 140.152 171.59 142.754C172.747 145.355 174.644 147.553 177.043 149.07C179.442 150.586 182.233 151.353 185.065 151.274C188.863 151.167 192.463 149.548 195.073 146.771C197.684 143.995 199.091 140.289 198.985 136.469ZM184.987 147.536C182.888 147.596 180.818 147.027 179.039 145.904C177.261 144.78 175.854 143.15 174.996 141.222C174.138 139.294 173.868 137.153 174.22 135.07C174.571 132.987 175.529 131.056 176.972 129.521C178.415 127.986 180.279 126.915 182.326 126.445C184.374 125.976 186.514 126.127 188.477 126.881C190.439 127.634 192.135 128.956 193.35 130.68C194.565 132.403 195.245 134.45 195.303 136.562C195.382 139.393 194.339 142.14 192.404 144.198C190.47 146.256 187.802 147.456 184.987 147.536Z" fill="#344054" />
    <path d="M180.04 144.556C181.748 146.185 183.949 147.193 186.293 147.419C184.803 147.653 183.282 147.565 181.829 147.161C180.376 146.757 179.026 146.046 177.867 145.076C176.709 144.107 175.769 142.9 175.11 141.536C174.451 140.173 174.089 138.683 174.047 137.168C174.005 135.652 174.285 134.145 174.867 132.747C175.449 131.349 176.321 130.091 177.424 129.058C178.527 128.025 179.836 127.24 181.264 126.755C182.692 126.27 184.206 126.097 185.706 126.247C183.743 126.552 181.905 127.405 180.4 128.709C178.895 130.013 177.784 131.715 177.194 133.623C176.603 135.531 176.557 137.567 177.06 139.499C177.563 141.432 178.596 143.184 180.04 144.556Z" fill="#98A2B3" />
    <path opacity="0.28" d="M233.856 65.5342H176.935C175.054 65.5342 173.529 67.0679 173.529 68.9597V103.962C173.529 105.854 175.054 107.387 176.935 107.387H233.856C235.737 107.387 237.262 105.854 237.262 103.962V68.9597C237.262 67.0679 235.737 65.5342 233.856 65.5342Z" fill="#6299E8" />
    <path d="M240.135 60.2544H183.214C181.333 60.2544 179.809 61.7881 179.809 63.6799V98.682C179.809 100.574 181.333 102.108 183.214 102.108H240.135C242.016 102.108 243.541 100.574 243.541 98.682V63.6799C243.541 61.7881 242.016 60.2544 240.135 60.2544Z" fill="white" />
    <path d="M240.133 102.62H183.212C182.174 102.619 181.179 102.204 180.445 101.465C179.711 100.727 179.298 99.7261 179.297 98.682V63.6804C179.298 62.6363 179.711 61.6353 180.445 60.897C181.179 60.1587 182.174 59.7434 183.212 59.7422H240.133C241.171 59.7434 242.166 60.1587 242.9 60.897C243.634 61.6353 244.047 62.6363 244.048 63.6804V98.682C244.047 99.7261 243.634 100.727 242.9 101.465C242.166 102.204 241.171 102.619 240.133 102.62ZM183.212 60.7672C182.444 60.768 181.708 61.0752 181.165 61.6214C180.622 62.1675 180.317 62.908 180.316 63.6804V98.682C180.317 99.4544 180.622 100.195 181.165 100.741C181.708 101.287 182.444 101.594 183.212 101.595H240.133C240.901 101.594 241.637 101.287 242.18 100.741C242.723 100.195 243.028 99.4544 243.029 98.682V63.6804C243.028 62.908 242.723 62.1675 242.18 61.6214C241.637 61.0752 240.901 60.768 240.133 60.7672H183.212Z" fill="#00CEA6" />
    <path d="M237.368 75.8608H216.732V76.8858H237.368V75.8608Z" fill="#00CEA6" />
    <path d="M237.368 80.9658H216.732V81.9908H237.368V80.9658Z" fill="#00CEA6" />
    <path d="M237.368 86.0703H216.732V87.0953H237.368V86.0703Z" fill="#00CEA6" />
    <path d="M237.368 91.3337H186.162V92.3587H237.368V91.3337Z" fill="#00CEA6" />
    <path d="M237.368 96.0078H186.162V97.0328H237.368V96.0078Z" fill="#00CEA6" />
    <path opacity="0.19" d="M208.959 74.5864H187.999C187.437 74.5864 186.98 75.0453 186.98 75.6114V88.5606C186.98 89.1267 187.437 89.5856 187.999 89.5856H208.959C209.521 89.5856 209.978 89.1267 209.978 88.5606V75.6114C209.978 75.0453 209.521 74.5864 208.959 74.5864Z" fill="#AAAAAA" />
    <path d="M243.541 68.4606H179.809V69.4856H243.541V68.4606Z" fill="#00CEA6" />
    <path d="M210.685 72.957H189.726C189.163 72.957 188.707 73.4159 188.707 73.982V86.9312C188.707 87.4973 189.163 87.9562 189.726 87.9562H210.685C211.248 87.9562 211.704 87.4973 211.704 86.9312V73.982C211.704 73.4159 211.248 72.957 210.685 72.957Z" fill="#00CEA6" />
    <path d="M278.104 180.656H13.8945V183.731H278.104V180.656Z" fill="#667085" />
    <path d="M148.392 172.218H121.979V177.843H148.392V172.218Z" fill="#D0D5DD" />
    <path d="M154.505 180.656H115.863V176.739C115.863 176.286 116.042 175.851 116.361 175.531C116.679 175.211 117.111 175.031 117.562 175.031H152.806C153.257 175.031 153.689 175.211 154.007 175.531C154.326 175.851 154.505 176.286 154.505 176.739V180.656Z" fill="#667085" />
    <path d="M137.031 52.4005C137.003 52.4005 136.974 52.3949 136.948 52.3839C136.922 52.3729 136.898 52.3568 136.878 52.3366C136.858 52.3163 136.842 52.2922 136.831 52.2657C136.82 52.2392 136.814 52.2108 136.814 52.1821C136.813 51.0373 136.36 49.9397 135.555 49.1301C134.75 48.3206 133.659 47.8651 132.521 47.8637C132.463 47.8635 132.408 47.8404 132.368 47.7995C132.327 47.7585 132.305 47.7031 132.305 47.6454C132.305 47.5876 132.327 47.5322 132.368 47.4913C132.408 47.4503 132.463 47.4272 132.521 47.427C133.774 47.4284 134.976 47.9298 135.862 48.8213C136.748 49.7127 137.247 50.9214 137.248 52.1821C137.248 52.2108 137.243 52.2392 137.232 52.2657C137.221 52.2922 137.205 52.3163 137.185 52.3365C137.165 52.3568 137.141 52.3729 137.114 52.3839C137.088 52.3948 137.06 52.4005 137.031 52.4005Z" fill="white" />
    <g clipPath="url(#clip0_2_33251)">
      <path d="M200.626 80.8786C202.134 80.8786 203.357 79.5513 203.357 77.9139C203.357 76.2766 202.134 74.9492 200.626 74.9492C199.117 74.9492 197.895 76.2766 197.895 77.9139C197.895 79.5513 199.117 80.8786 200.626 80.8786Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M200.626 81.9567C199.185 81.9995 197.772 82.4055 196.5 83.1425C196.172 83.3229 195.897 83.5982 195.705 83.9379C195.513 84.2776 195.412 84.6684 195.412 85.0669V85.4604C195.412 85.6748 195.491 85.8805 195.63 86.0321C195.699 86.1072 195.782 86.1668 195.872 86.2074C195.962 86.248 196.059 86.269 196.157 86.269H205.096C205.293 86.269 205.483 86.1838 205.623 86.0321C205.762 85.8805 205.841 85.6748 205.841 85.4604V85.0669C205.841 84.6684 205.739 84.2776 205.547 83.9379C205.356 83.5982 205.081 83.3229 204.753 83.1425C203.481 82.4055 202.068 81.9995 200.626 81.9567Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <g clipPath="url(#clip1_2_33251)">
      <path d="M106.27 108.743C107.779 108.743 109.002 107.416 109.002 105.778C109.002 104.141 107.779 102.813 106.27 102.813C104.762 102.813 103.539 104.141 103.539 105.778C103.539 107.416 104.762 108.743 106.27 108.743Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M106.271 109.821C104.829 109.864 103.417 110.27 102.144 111.007C101.817 111.187 101.542 111.462 101.35 111.802C101.158 112.142 101.056 112.533 101.057 112.931V113.325C101.057 113.539 101.135 113.745 101.275 113.896C101.344 113.971 101.426 114.031 101.516 114.072C101.607 114.112 101.704 114.133 101.802 114.133H110.74C110.938 114.133 111.127 114.048 111.267 113.896C111.407 113.745 111.485 113.539 111.485 113.325V112.931C111.485 112.533 111.384 112.142 111.192 111.802C111 111.462 110.725 111.187 110.398 111.007C109.125 110.27 107.713 109.864 106.271 109.821Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2_33251">
        <rect width="11.9184" height="12.9369" fill="white" transform="translate(194.668 74.1406)" />
      </clipPath>
      <clipPath id="clip1_2_33251">
        <rect width="11.9184" height="12.9369" fill="white" transform="translate(100.312 102.005)" />
      </clipPath>
    </defs>
  </svg>
);

ExistingCustomerImage.propTypes = {};

ExistingCustomerImage.defaultProps = {};

export default ExistingCustomerImage;
