import React from 'react';
import i18n from 'i18n-js';
import { MdOutlineFileDownload } from 'react-icons/md';
import v from 'voca';
import PropTypes from 'prop-types';

const PreApproval = ({ document }) => {
  const { downloadUrl } = document.data;
  if (v.isBlank(downloadUrl)) return null;

  return (
    <a href={downloadUrl} target="_blank" rel="noreferrer" title={i18n.t('buttons.download')}>
      <MdOutlineFileDownload size="18" />
    </a>
  );
};

PreApproval.propTypes = {
  document: PropTypes.objectOf(PropTypes.any).isRequired
};

PreApproval.defaultProps = {};

export default PreApproval;
