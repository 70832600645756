import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './PowerCard.module.scss';

const PowerChart = ({ formattedAmount, header, children }) => (
  <div className={classNames(styles.chartContainer, 'my-auto')}>
    <div>
      {header}

      <div className={styles.amountText}>
        {formattedAmount}
      </div>

      {children}
    </div>
  </div>
);

PowerChart.propTypes = {
  formattedAmount: PropTypes.string.isRequired,
  header: PropTypes.element
};

PowerChart.defaultProps = {
  header: null
};

export default PowerChart;
