import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { getPackage } from '../../../../store/actions/packageActions';
import { buildPackagesPath } from '../../../../utils/multiFamilyUtils';
import Overview from './Overview';
import Modifications from '../../../global/Modifications';
import './styles.scss';
import { isCompanyAdmin, isMasterAdmin, isSalesLead } from '../../../../utils/authUtils';
import NavigationBreadcrumbs from '../../../global/NavigationBreadcrumbs';
import NavigationService from '../../../../services/NavigationService';
import { NavigationMainNames } from '../../../../constants';
import Loading from '../../../ui/Loading';

const i18nOpts = { scope: 'components.admin.packages.show.index' };

const Tabs = Object.freeze({
  OVERVIEW: 'overview',
  MODIFICATIONS: 'modifications'
});

const Show = ({
  id, package: specPackage, loading, ...props
}) => {
  const [activeTab, setActiveTab] = useState(Tabs.OVERVIEW);
  const isAdmin = isCompanyAdmin() || isSalesLead() || isMasterAdmin();

  useEffect(() => {
    loadPackage();
  }, []);

  const loadPackage = () => {
    props.getPackage(parseInt(id, 10))
      .then()
      .catch(() => navigate(buildPackagesPath()));
  };

  useEffect(() => {
    if (specPackage) NavigationService.addItem(specPackage, NavigationMainNames.PACKAGES);
  }, [specPackage]);

  if (!specPackage || loading) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={specPackage.name} />

      <NavigationBreadcrumbs />

      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{specPackage.name}</h2>
        </div>
        <div>
          <Link to={buildPackagesPath(`/form?id=${specPackage.id}`)} className="btn btn-primary">
            <i className="far fa-edit mr-2" />
            {i18n.t('buttons.edit')}
          </Link>
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === Tabs.OVERVIEW })}
            onClick={() => setActiveTab(Tabs.OVERVIEW)}
          >
            {i18n.t('tabs.overview', i18nOpts)}
          </NavLink>
        </NavItem>
        {isAdmin && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === Tabs.MODIFICATIONS })}
              onClick={() => setActiveTab(Tabs.MODIFICATIONS)}
            >
              {i18n.t('tabs.modifications', i18nOpts)}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.OVERVIEW}>
          <Overview />
        </TabPane>
        {isAdmin && (
          <TabPane tabId={Tabs.MODIFICATIONS}>
            <Modifications instance={specPackage} />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

export default connect((store) => ({
  package: store.packages.package,
  loading: store.packages.getPackage.loading
}), {
  getPackage
})(Show);
