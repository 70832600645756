import React, { Component } from 'react';
import { navigate } from '@reach/router';
import {
  Col, Row, ListGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { isAdmin } from '../../../../../utils/authUtils';
import { getOptions, saveOption } from '../../../../../store/actions/optionActions';
import Option from './Option';
import OptionFormModal from './OptionFormModal';
import api from '../../../../../api';
import { listOptionTypesQuery } from '../../../../../graphql';
import './styles.scss';

const i18nOpts = { scope: 'components.admin.units.show.options.index' };

function getOptionTypesAsync(companyId) {
  const variables = { filter: { companyId } };

  return api.graphql(listOptionTypesQuery, variables)
    .then(({ data: { items } }) => Promise.resolve(items));
}

class Options extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownOpen: false,
      optionTypes: []
    };

    this.onToggleDropdown = this.onToggleDropdown.bind(this);
    this.getAvailableOptionTypes = this.getAvailableOptionTypes.bind(this);
    this.onAddOptionType = this.onAddOptionType.bind(this);
  }

  componentDidMount() {
    this.loadOptionTypes();
    this.loadOptions();
  }

  onToggleDropdown() {
    const { isDropdownOpen } = this.state;
    this.setState({ isDropdownOpen: !isDropdownOpen });
  }

  onAddOptionType(optionType) {
    const { props } = this;
    const { unit } = this.props;

    const variables = { input: { unitId: unit.id, optionTypeId: optionType.id } };
    props.saveOption(variables);
  }

  getAvailableOptionTypes() {
    const { optionTypes } = this.state;
    const { options } = this.props;
    const selectedOptionTypeIds = options.map((o) => o.optionType.id);
    return optionTypes.filter((ot) => !selectedOptionTypeIds.includes(ot.id));
  }

  loadOptionTypes() {
    const { currentCompany } = this.props;
    getOptionTypesAsync(currentCompany.id)
      .then((optionTypes) => {
        this.setState({ optionTypes });
      })
      .catch(() => navigate('/admin/units'));
  }

  loadOptions() {
    const { props } = this;
    const { unit } = this.props;
    if (unit) {
      const variables = { filter: { unitId: unit.id } };
      props.getOptions(variables)
        .catch(() => navigate('/admin/units'));
    }
  }

  render() {
    const { isDropdownOpen } = this.state;
    const { options } = this.props;
    const availableOptionTypes = this.getAvailableOptionTypes();

    return (
      <div>
        { isAdmin() && (
          <div className="text-right mb-3">
            <Dropdown isOpen={isDropdownOpen} toggle={this.onToggleDropdown}>
              <DropdownToggle color="outline-secondary" caret disabled={!availableOptionTypes.length}>
                {i18n.t('buttons.add', i18nOpts)}
              </DropdownToggle>
              <DropdownMenu right>
                {
                  availableOptionTypes.map((optionType) => (
                    <DropdownItem
                      key={`available-option-type-${optionType.id}`}
                      onClick={() => this.onAddOptionType(optionType)}
                    >
                      {optionType.name}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
        <Row>
          <ListGroup className="options-container w-100">
            {
              options.map((o) => (
                <Col key={`option-${o.id}`} xs="12">
                  <Option option={o} />
                </Col>
              ))
            }
          </ListGroup>
        </Row>
        <OptionFormModal />
      </div>
    );
  }
}

export default connect((store) => ({
  options: store.options.options,
  unit: store.units.unit,
  currentCompany: store.companies.currentCompany
}), {
  saveOption,
  getOptions
})(Options);
