import { CLOSE_MODAL, OPEN_MODAL } from './types';

export function openModal(modalName, params = {}) {
  return {
    type: OPEN_MODAL,
    payload: { modalName, opened: true, params }
  };
}

export function closeModal(modalName) {
  return {
    type: CLOSE_MODAL,
    payload: { modalName, opened: false }
  };
}
