import React from 'react';
import { Row } from 'reactstrap';

import styles from './Activity.module.scss';
import Timeline from './Timeline';
import LocationCard from './LocationCard';
import AnalyticsCard from './AnalyticsCard';

const Activity = () => (
  <div>
    <Row>
      <div className="order-1 order-lg-0 col-12 col-lg-6 col-xl">
        <Timeline />
      </div>
      <div className="order-0 order-lg-1 col-12 col-lg-6 col-xl-auto">
        <div className={styles.rightSidePanel}>
          <LocationCard />
          <AnalyticsCard />
        </div>
      </div>
    </Row>
  </div>
);

Activity.propTypes = {};

Activity.defaultProps = {};

export default Activity;
